import { Col, Form, Input, Radio, Row, Select } from 'antd'
import React from 'react'

const required = { required: true, message: 'Este dato es requerido' }

function AdditionalInformation ({ form, disabled = false, lodging }) {
  const haveAllergies = Form.useWatch('haveAllergies', form)
  const haveRestriction = Form.useWatch('haveRestriction', form)
  const havePets = Form.useWatch('havePets', form)

  return (
    <Row>
      <Col span={24}>
        <h2 className='subtitle-aplication fs-16'>Información Adicional</h2>
      </Col>
      {lodging && (
        <>
          <Col span={24}>
            <Row align='middle' gutter={[8, 8]}>
              <Col>
                <label className='label-aplication'>¿Cuento con alergias?</label>
              </Col>
              <Col>
                <Form.Item className='byp-m-0' name='haveAllergies'>
                  <Radio.Group name='radiogroup' defaultValue='no' disabled={disabled}>
                    <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                    <Radio value='no' className='radio_btn-quote'>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {haveAllergies === 'yes' && (
            <Col span={24}>
              <label className='label-aplication'>Me da alergia</label>
              <Form.Item
                name='allergies'
              >
                <Select
                  className='school-search__item aplication__input-multiselect byp-p-0'
                  mode='tags'
                  size='large'
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Row align='middle' gutter={[8, 8]}>
              <Col>
                <label className='label-aplication'>¿Tengo alguna restricción alimentaria?</label>
              </Col>
              <Col>
                <Form.Item className='byp-m-0' name='haveRestriction'>
                  <Radio.Group name='radiogroup' defaultValue='no' disabled={disabled}>
                    <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                    <Radio value='no' className='radio_btn-quote'>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {haveRestriction === 'yes' && (
            <Col span={24}>
              <label className='label-aplication'>No puedo comer</label>
              <Form.Item
                name='not_eat'
              >
                <Select
                  className='school-search__item aplication__input-multiselect byp-p-0'
                  mode='tags'
                  size='large'
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Row align='middle' gutter={[8, 8]}>
              <Col>
                <label className='label-aplication'>¿Puedo vivir con mascotas?</label>
              </Col>
              <Col>
                <Form.Item className='byp-m-0' name='havePets'>
                  <Radio.Group name='radiogroup' defaultValue='no' disabled={disabled}>
                    <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                    <Radio value='no' className='radio_btn-quote'>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {havePets === 'yes' && (
            <Col span={24}>
              <label className='label-aplication'>Tipo de mascotas</label>
              <Form.Item
                name='pets'
              >
                <Select
                  className='school-search__item aplication__input-multiselect byp-p-0'
                  mode='tags'
                  size='large'
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          )}
        </>
      )}
      <Col span={24}>
        <label className='label-aplication'>Situación médica que debamos estar enterados, o comentarios adicionales</label>
        <Form.Item
          name='medical_situation'
          rules={[required]}
        >
          <Input className='aplication__input' disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default AdditionalInformation
