import { Button, Col, DatePicker, Form, Row } from 'antd'
import { AddAndRemoveListButtons } from '../../../pages/dashboards/Institution/components/AddAndRemoveListButtons'
import { PlusOutlined } from '@ant-design/icons'
import { TextInsideButton } from '../TextInsideButton'

const required = { required: true, message: 'This element is required' }

const IntakeDatesListFormItem = ({ name, form }) => {
  const intakes = Form.useWatch(name, form)
  return (
    <Form.List
      name={name}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} gutter={16}>
              <Col span={18}>
                <Form.Item
                  rules={[required]}
                  name={[name, 'intake_date']}
                  label='Start date'
                >
                  <DatePicker picker='month' format='MM/YYYY' />
                </Form.Item>
              </Col>
              <AddAndRemoveListButtons
                fields={fields}
                index={index}
                add={add}
                remove={remove}
                name={name}
                notDelete={intakes?.length <= 1}
              />
            </Row>
          ))}
          {
            fields.length === 0 && (
              <Form.Item>
                <Button className='byp-btn-blue-100-outline float-right svg-boton-blue-fill' onClick={() => add()}>
                <TextInsideButton text='Add'/>
                </Button>
              </Form.Item>
            )
          }
        </>
      )}
    </Form.List>
  )
}

export { IntakeDatesListFormItem }
