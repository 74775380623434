import { Col, Input, Row, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { SelectCampusByProgramFormItem } from '../../../../../common/components/helper-form/SelectCampusByProgramFormItem'
const { Search } = Input
const { Option } = Select

const LodgingTransportTableFilters = ({ program = 'graduate', type, data, setFilters }) => {
  const [searchFilter, setSearchFilter] = useState({})
  const [types, setTypes] = useState([])

  // function updateData () {
  //   const nData = data?.filter((item) => {
  //     let ok = true
  //     if (searchFilter?.sede) {
  //       ok = !!item.sedes?.find((sede) => sede === searchFilter?.sede)
  //     }
  //     if (searchFilter?.status && ok) {
  //       ok = item.status === searchFilter?.status
  //     }
  //     if (searchFilter?.search && ok) {
  //       ok = !!item.name?.toLowerCase().includes(searchFilter?.search?.toLowerCase())
  //     }
  //     if (searchFilter?.type && ok) {
  //       ok = item.properties?.type === searchFilter?.type
  //     }
  //     return ok
  //   })
  //   setData(nData)
  // }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  useEffect(() => {
    // updateData()
    if (setFilters) {
      setFilters(searchFilter)
    }
  }, [searchFilter])

  useEffect(() => {
    if (type === 'transport') {
      const types = []
      data?.forEach((item) => {
        if (item.properties?.type && !types.includes(item.properties?.type)) {
          types.push(item.properties?.type)
        }
      })
      setTypes(types)
    } else {
      setTypes([
        'Home stay',
        'Residence',
        'Studio',
        'Hotel',
        'Other'
      ])
    }
  }, [type, data])

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  return (
    <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
      <Col xs={24} md={12} lg={6}>
        <Search
          className='byp-input-gray'
          placeholder='Search'
          onChange={(event) => debounceFilter('search', event.target.value)}
          allowClear
        />
      </Col>
      <Col span={6}>
        <SelectCampusByProgramFormItem
          program={program}
          onChange={(e) => {
            e
              ? handleAddFilter('sede', Number(e))
              : handleRemoveFilter('sede')
          }}
          showAll
          placeholder='All the schools'
          size='small'
        />
      </Col>
      <Col span={6}>
        <Select
          onChange={(e) => {
            e
              ? handleAddFilter('type_select', e)
              : handleRemoveFilter('type_select')
          }}
          placeholder='All Types'
          className='w-100'
          size='small'
        >
          <Option key={0} value={null}>All {type === 'transport' ? 'Transports' : 'Accommodations'}</Option>
          {types?.map((typ) => (
            <Option key={'type_' + typ} value={typ}>{typ}</Option>
          ))}
          {/* <Option key={0} value='Home stay'>Homestay</Option>
          <Option key={1} value='Residence'>Residence</Option>
          <Option key={2} value='Studio'>Studio</Option>
          <Option key={3} value='Hotel'>Hotel</Option>
          <Option key={4} value='Other'>Other</Option> */}
        </Select>
      </Col>
      <Col span={6}>
        <Select
          style={{ width: '70%', marginLeft: '5%' }}
          size='small'
          onChange={(e) => {
            e
              ? handleAddFilter('status', e)
              : handleRemoveFilter('status')
          }}
          placeholder='Status'
          className='w-100'
        >
          <Option key={0} value={null}>All status</Option>
          <Option key={1} value='Active'>Active</Option>
          <Option key={2} value='Created'>Inactive</Option>
        </Select>
      </Col>
    </Row>
  )
}

export { LodgingTransportTableFilters }
