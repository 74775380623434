export const programTypes = [
  {
    key: 'sub1',
    value: 'bachelor',
    label: 'Bachelor Degree'
  },
  {
    key: 'sub2',
    value: 'diploma',
    label: 'Diploma',
    subtypes: [
      {
        key: 'diploma',
        value: 'diploma',
        label: 'Diploma'
      },
      {
        key: 'advanced_diploma',
        value: 'advanced_diploma',
        label: 'Advanced Diploma'
      },
      {
        key: 'certificate',
        value: 'certificate',
        label: 'Certificate'
      }
    ]
  },
  {
    key: 'sub3',
    value: 'postgraduate',
    label: 'Postgraduate Degree'
  },
  {
    key: 'sub4',
    value: 'master',
    label: 'Master Degree'
  }
]
