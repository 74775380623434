import { Col, Divider, Form, Row } from 'antd'
import { useEffect, useState } from 'react'
import { SelectSchools } from '../../../../../common/components/helper-form/SelectInstitutions'
import { SelectProgramsFormItem } from '../../../../../common/components/helper-form/SelectProgramsFormItem'
import { TableTotalQuote } from '../../analitics/TableTotalQuote'
import TimeFilters from '../../analitics/TimeFilters'
import TotalQuotes from '../components/TotalQuotes'
// import { SelectsReportsUni } from '../components/SelectsReportsUni'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { AdvanceSearch } from './AdvanceSearch'
import { useLocation } from 'react-router-dom'

// import { RightOutlined, LeftOutlined } from '@ant-design/icons'

let isAgencyOffice = false

const ReportsUniInfo = () => {
  const location = useLocation()
  const category = location.state && location.state.category
  const user = location.state && location.state.user
  // console.log(user)

  const [activeCategory, setActiveCategory] = useState('created')
  const [time, setTime] = useState({})
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [offices, setOffices] = useState([])
  const [program, setprogram] = useState(undefined)
  const [institution, setinstitution] = useState(undefined)
  const [users, setUsers] = useState([])
  const [filters, setFilters] = useState({ cities, countries, offices, users, program_name: program, institution })
  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  const { user: _user, company } = useAuth()

  useEffect(() => {
    if (category) {
      setActiveCategory(category)
    }
  }, [category])

  useEffect(() => {
    if (user) {
      setUsers([user])
    }
  }, [user])

  useEffect(() => {
    // console.log('🚀 ~ file: ReportsUniInfo.js:46 ~ ReportsUniInfo ~ countries, cities, offices, program, institution, users, time:', countries, cities, offices, program, institution, users, time)
    const filteredValues = {}

    if (time && time.start && time.end) {
      filteredValues.start = time.start
      filteredValues.end = time.end
    } else if (time && time.time) {
      filteredValues.time = time.time
    }

    if (countries) { filteredValues.countries = countries }
    if (cities) { filteredValues.cities = cities }
    if (offices) { filteredValues.offices = offices }
    if (program) { filteredValues.program_name = program }
    if (institution) { filteredValues.institution = institution }
    if (users) { filteredValues.users = users }

    const roles = _user?.role_names ?? []
    if (roles.includes('agency-office-manager')) {
      filteredValues.countries = [_user?.sede_country_id]
      filteredValues.cities = [_user?.sede_city_id]
      filteredValues.offices = [_user?.sedes[0]]
    }

    setFilters(filteredValues)
  }, [countries, cities, offices, program, institution, users, time])

  useEffect(() => {
    const roles = _user?.role_names ?? []
    if (roles.includes('agency-office-manager')) {
      form.setFieldsValue({
        offices: [_user?.sede]
      })
      isAgencyOffice = true
    }
  }, [_user])

  return (
    <div>
      <Row className='byp-dashboard-content'>
        <Col md={24}>
          <Row className='byp-mb-1'>
            <Col md={24} className='d-flex align-center byp-mb-1'>
              <h3 className='byp-title'>Reports</h3>
            </Col>

            <Col md={16} style={{ display: 'flex' }}>
              <TimeFilters setTime={setTime} />
            </Col>
          </Row>

          <AdvanceSearch
            form={form}
            setCountries={setCountries}
            setCities={setCities}
            setOffices={setOffices}
            setUsers={setUsers}
            isAgencyOffice={isAgencyOffice}
            company={company}
            _user={_user.type}
            isLoading={isLoading}
            defaultuser={user}
          />

          <Row gutter={24}>

            <Col xs={24} md={24} className='mt-3'>
              <h4 className='title-reports-uni byp-fw-bold'>Total quotes <span>(percentages based on agency totals)</span></h4>
              <TotalQuotes time={time} setActiveCategory={setActiveCategory} activeCategory={activeCategory} filters={filters} setIsActiveTotalQuote={() => { console.log() }} setIsLoading={setIsLoading} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <Row>
                <Col md={24} className='reports-uni-selects-container'>
                  <SelectProgramsFormItem
                    className='reports-uni-selects byp-mb-1'
                    placeholder='All programs'
                    style={{ width: 170 }}
                    size='small'
                    onChange={(val) => setprogram(val)}
                    value={program}
                    isReports
                    disabled={isLoading}
                  />
                  <SelectSchools
                    className='reports-uni-selects byp-mb-1'
                    placeholder='All the schools'
                    style={{ width: 120 }}
                    size='small'
                    onChange={(val) => setinstitution(val)}
                    value={institution}
                    isReports
                    disabled={isLoading}
                  />
                </Col>
              </Row>

              <TableTotalQuote activeCategory={activeCategory} filters={filters} isReports />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export { ReportsUniInfo }
