import { URL_ABOUT_PAGE } from '../../about/AboutPage'

export function HomeSectionI () {
  return (
    <div className='d-flex justify-center'>
      <div
        class='input-group text-align-center byp-centered'
        style={{ maxWidth: '22rem', marginTop: '2rem' }}
      >
        {/* <input
          type='text'
          class='form-control'
          placeholder='Your email'
          aria-label='Recipient username'
          aria-describedby='button-addon2'
          style={{ border: '1px', solid: '#D9D9D9', borderRadius: '3px', marginRight: '0.5rem' }}
        /> */}
        {/* <button
          class='btn btn-outline-secondary'
          type='button'
          id='button-addon2'
          style={{ background: '#2D2F66', borderRadius: '6px', color: 'white' }}
        >
          Send
        </button> */}
        <a className='ant-btn byp-btn-blue-200' href={URL_ABOUT_PAGE}> <b>Contact us</b> </a>
      </div>
    </div>
  )
}
