import { Col, Row } from 'antd'

const QuoteOverviewHeader = ({ course, campus, arrivalDate, type }) => {
  const LanguageHeader = () => {
    return (
      <Row gutter={12}>
        <Col span={12} style={{ paddingTop: '10px' }}>
          <h5 className='txt-color byp-text-md' style={{ color: '#474979' }}>
            {course.name}
          </h5>
        </Col>
      </Row>
    )
  }

  const HEHeader = () => {
    return (
      <Row>
        <Col span={12} style={{ paddingTop: '10px' }}>
          <p style={{ color: '#474979' }}>{course.name}</p>
        </Col>
        <Col span={12} style={{ textAlign: 'end', alignSelf: 'flex-end' }}>
          <p>Type of Institution: <span className='byp-fw-bold'>{campus?.type}</span></p>
        </Col>
        <Col span={12}>
          {arrivalDate && <p>Date: <span className='byp-fw-bold'>{arrivalDate}</span></p>}
        </Col>
        {
          course?.code && (
            <Col span={12} style={{ textAlign: 'end', alignSelf: 'flex-end' }}>
              <p>Code: <span className='byp-fw-bold'>{course?.code}</span></p>
            </Col>
          )
        }
      </Row>
    )
  }

  const BSHeader = () => {
    return (
      <Row>
        <Col span={24} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          {course.name}
        </Col>
        <Col span={12}>
          <p>Grades: <span className='byp-fw-bold'>{campus?.grades?.join('-')}</span></p>
        </Col>
        <Col span={12} style={{ textAlign: 'end', alignSelf: 'flex-end' }}>
          {arrivalDate && (
            <p>Date: <span className='byp-fw-bold'>{arrivalDate}</span></p>
          )}
        </Col>
        <Col span={12}>
          {course?.type}
        </Col>
        <Col span={12} style={{ textAlign: 'end', alignSelf: 'flex-end' }}>
          <p>Type of Institution: <span className='byp-fw-bold'>{campus?.type}</span></p>
        </Col>
        <Col span={12} style={{ textAlign: 'end', alignSelf: 'flex-end' }}>
          {
            course?.code && (
              <p>Code: <span className='byp-fw-bold'>{course?.code}</span></p>
            )
          }
        </Col>
      </Row>
    )
  }

  const PackageHeader = () => {
    return (
      <Row gutter={24} style={{ width: '100%', marginBottom: '.5rem' }}>
        <Col span={12} style={{ paddingTop: '10px' }}>
          <h5 className='txt-color byp-text-md' style={{ color: '#474979' }}>
            {course.name}
          </h5>
        </Col>
        <Col span={12} style={{ paddingTop: '10px', justifyContent: 'end' }}>
          <h5 className='txt-color byp-text-md' style={{ color: '#474979', textAlign: 'end' }}>
            Language: {course.language?.name}
          </h5>
        </Col>
      </Row>
    )
  }

  if (type === 'package') {
    return <PackageHeader />
  }

  if (course?.program === 'language') {
    return <LanguageHeader />
  }

  if (course?.program === 'graduate') {
    return <HEHeader />
  }

  if (course?.program === 'academic_years' || course?.program === 'boarding_schools') {
    return <BSHeader />
  }
}

export { QuoteOverviewHeader }
