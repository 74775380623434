import { Checkbox, Col, Form, Row, Select } from 'antd'
import { useState, useEffect } from 'react'
import { useGetPricesCampus } from '../../../../bussines/hooks/school/useGetPricesCampus'
const { Option } = Select

function HeaderFormCreateCourse ({ name, rules, campusId, programId, form, filters }) {
  const [checkAll, setCheckAll] = useState(false)
  const _name = name ?? 'campus'
  const { isLoading, data } = useGetPricesCampus(campusId, programId, filters)

  const onCheckAllChange = (e) => {
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    if (data) {
      const ids = data.campus.map(c => (c.id))
      checkAll ? form.setFieldsValue({ [_name]: ids }) : form.setFieldsValue({ [_name]: [] })
    }
  }, [checkAll, data])

  return (
    <>
      <Row>
        <Col xs={24} md={12}>
          <h6 className='byp-title byp-mt-1'>
            Schools to which prices will apply
          </h6>
        </Col>
      </Row>
      <Row className='byp-dashboard-header byp-mb-1'>
        <Col xs={24} md={4} className='byp-justify-centered' style={{ borderRight: 'solid var(--gray-100) 1px' }}>
          <Form.Item className='byp-m-0 prefix' name='all' valuePropName='checked'>
            <Checkbox onChange={onCheckAllChange}>
              All
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={20} className='byp-justify-centered'>
          <Form.Item
            name={_name}
            rules={rules}
            className='byp-m-0'
          >
            <Select
              style={{ width: '100%' }}
              placeholder=''
              loading={isLoading}
              mode='multiple'
              className='byp-m-0'
            >
              {data?.campus?.map(item => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export { HeaderFormCreateCourse }
