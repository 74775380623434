import React from 'react'
import { Form, Select } from 'antd'

const { Option } = Select

export const SelectCourseByCampus = ({ showSearch, loading, isListField, fieldKey, showAll = false, showDefault = false, selectById = true, courses, haveSearch, setCourse, ...props }) => {
  const filterOption = (input, option) => {
    const customAttribute = option.props.customAttribute
    if (customAttribute && input) {
      return customAttribute.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return true
  }
  return (
    <Form.Item {...props}>
      <Select
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        // loading={isLoading}
        // mode={mode}
        size='small'
        className='byp-m-0'
        showSearch={haveSearch}
        filterOption={haveSearch ? filterOption : ''}
        onChange={setCourse}
      >
        <Option key='all' value='all' customAttribute='all'>All</Option>
        {courses?.map(course => <Option key={course.id} value={course.id} customAttribute={course.label}>{course.label}</Option>)}
      </Select>
    </Form.Item>
  )
}
