import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { useGetTypeOfInstitutions } from '../../../bussines/hooks/quoter/useGetTypeOfInstitutions'
const { Option } = Select

// Return institutions types that has offers
const SelectTypeOfInstitutionsFormItem = ({ name, filters = {}, className, noStyle, onChange, showAll, ...rest }) => {
  const { data } = useGetTypeOfInstitutions(filters)

  useEffect(() => {
    if (data) {
      console.log('🚀 ~ file: SelectTypeOfInstitutionsFormItem.js:10 ~ useEffect ~ data', data)
    }
  }, [data])

  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
    >
      <Select
        className={className}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        size={rest?.size}
        onChange={onChange}
        placeholder={rest.placeholder ?? ''}
        {...rest}
      >
        {data?.map((item) => (
          <Option key={item} value={item}>{item}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectTypeOfInstitutionsFormItem }
