import React from 'react'

function TextPolicy () {
  return (
    <div style={{ padding: '100px' }}>
      <h2>Our privacy policy at QYP</h2>
      <p>
        Please read this privacy statement (“Privacy Statement”) carefully as it describes our
        practice of collecting, using, disclosing, retaining and protecting your personal data.
        This Privacy Statement applies to any website, application or service related to
        Quoteyourprogram.com
      </p>
      <p>
        By using this platform, you accept that we collect, store and use your personal data
        in order to carry out our contractual obligations towards you and in our legitimate
        interest in being able to provide you with the requested information; as well as
        maintenance of automatic back-up systems and direct marketing.
      </p>
      <p>
        It is in our main interest to keep your personal data safe and to let you know its use
        while using the platform. We implement and maintain the maximum security
        measures to keep your data safe.
      </p>
      <p>
        <h2>Our company</h2>
      </p>
      <p>
        This Privacy Statement applies to all products, applications, and services offered by
        QYP Quote your program, a company incorporated in the city of Guadalajara,
        Jalisco, Mexico at Ave. Vallarta 3075-1 Col. Vallarta Pte.
      </p>
      <p>
        <h2>Recompilation of information</h2>
      </p>
      <p>
        To the extent permissible under applicable law, we collect data about you and any
        other party whose data you provide to us when:
      </p>
      <p>
        <ul>
          <li> You register with our website, applications or services (including free trials).
            This may include your name (including business name), email address, and
            phone number. It is also possible that we request additional information about
            your professional activity and your preferences.
          </li>
          <li> At the time of generating your interest in reserving a selected course through
            a quote from our QYP platform. Here your name, email address and contact
            telephone number might be included.
          </li>
          <li> When registering for our newsletters, surveys, blog posting, participation in
            any of our events, and presentations and publications. Here your name, email
            address and contact telephone number might be included.
          </li>
          <li>
            By using our applications, which may include the collection of metadata.
          </li>
          <li>
            By interacting with us through social media.
          </li>
          <li>
            When contacting us through non-online channels such as telephone, fax,
            SMS, email or regular mail.
          </li>
          <li>
            When you sign up for a payment plan, we collect your bank credit card
            information.
          </li>
        </ul>
      </p>
      <p>
        We also collect your data when you register as a user on our website and/or other
        online forms; We may use such data to contact you to remind you to complete the
        registration process or for marketing purposes.
      </p>
      <p>
        We also collect data from your devices (including mobile devices) and applications
        that you or your users use to access and use any of our websites, applications or
        services (for example, we may collect the number and type of device identification,
        location data and connection information such as statistics about your page views,
        traffic to and from websites, previous linking URLs, advertisement data, your IP
        address, your browsing history and your site access data Web). To do this, we may
        use cookies or similar technologies.
      </p>
      <p>
        <h2>What do we use your information for?</h2>
      </p>
      <p>
        To the extent permissible under applicable law, we use your data to:
      </p>
      <p>
        <ul>
          <li>
            Provide information and services that you have requested or applications or services
            that you have requested.
          </li>
          <li>
            Provide, maintain, protect and improve applications, products, services and
            information that you have requested from us.
          </li>
          <li>
            Manage our relationship with you (for example, customer service and support
            activities).
          </li>
          <li>
            Evaluate, measure, improve and protect our content, website, applications and
            services, as well as provide you with an improved and personal user experience.
          </li>
          <li>
            Conduct internal testing of our website, applications, systems and services to test
            and improve their security, provisioning and performance, in which case we will
            pseudonymize any information used for such purposes and ensure that it is only
            displayed at aggregate levels that cannot be linked to you or any other natural
            person individually.
          </li>
          <li>
            Contact you to see if there is a willingness to participate in our customer research
            (for example, your opinion about your use of our applications, products and
            services).
          </li>
          <li>
            Evaluate, carry out statistical analysis and benchmarking, provided that, in such
            circumstances, it is done in an aggregated form that cannot be linked to you or any
            other natural person individually, except as permitted by law.
          </li>
          <li>
            Provide you with interest-targeted advertising, information or marketing that you may
            find useful, based on your use of our applications and services.
          </li>
          <li>
            Offer you services linked to your location (for example, advertising and other
            personalized content), in which we collect geolocation data.
          </li>
          <li>
            Comply with any other of our regulatory or legal obligations.
          </li>
          <li>
            To be able to make payments for the use of the platform if you have requested said
            specific form of payment.
          </li>
        </ul>
        <p>
          Our website, applications (including mobile applications) and services may contain
          technology that allows us to:
        </p>
        <ul>
          <li>
            Obtain data regarding any technical errors and other problems with our website,
            applications and services.
          </li>
          <li>
            Comply with our legal or regulatory obligations.
          </li>
          <li>
            Collect data about how you and users use features of our website, apps and services.
          </li>
          <li>
            Collect statistical data about the operating system and the environment from which
            you access our applications or services.
          </li>
        </ul>
        <p>
          We may evaluate and record our communications with you, including emails and
          telephone conversations. The information we collect may then be used for training
          purposes, quality control, to record data about our website, the applications and
          services that you have requested or inquired about, as well as, in general, in order to
          comply with our legal and regulatory obligations.
        </p>

      </p>
      <p>
        <h2>Third party platforms</h2>
      </p>
      <p>
        We may collect information when you interact with our advertisements and other
        content on third party sites and platforms, such as social networking sites. This may
        include “likes” and profile information collected on social networks due to an
        interaction with us.
      </p>
      <p>
        <h2>Data analysis and benchmarking</h2>
      </p>
      <p>
        We may use the information that is generated and stored while you use our services
        for our legitimate business interests in order to provide you with the best services
        and solutions a well as an optimized experience. These purposes include the
        following points:
      </p>
      <p>
        Offer you advertising, information or marketing (such as messages on products),
        which you may find useful, based on your use of our services.
      </p>
      <p>
        Carry out research and development processes to improve our products and
        services and our applications.
      </p>
      <p>
        Offer new features and services and develop existing ones (including statistical
        analysis, benchmarking, insights and receipt acknowledgment).
      </p>
      <p>
        Services linked to your location (for example, relevant content based on where you
        are), for which we collect geolocation data in order to offer you a personalized
        experience.
      </p>
      <p>
        Please note that our use may include personal data of your individual customers,
        vendors, employees, and others whose information you provide on any of our
        websites, applications, or services.
      </p>
      <p>
        Anyone whose personal data we process has the right to object to such processing
        based on our legitimate interests and if you want to do so, just contact us at
        admin@quoteyourprogram.com.
      </p>
      <p>
        <h2>Marketing</h2>
      </p>
      <p>
        Occasionally, we may use your data to contact you with information about our
        platform or information to improve our services that we think might be of interest to
        you. You have the right at any time to stop us contacting you for marketing purposes
        and to cancel your subscription through the links that appear in the emails that we
        send you.
      </p>
      <p>
        <h2>Third Party Platform Advertising</h2>
      </p>
      <p>
        We may share your data with third party platform providers (such as LinkedIn,
        Instagram, Facebook, Google and Twitter) when you respond to communications, we
        post on such third-party platforms to serve you targeted advertising/content through
        the relevant third-party platform and depending on your profile or your interests. The
        third-party platform provider will use your data to identify your account and serve you
        advertising. You can control which advertisements you receive through the privacy
        settings of the respective provider's platform. For more information in this regard, you
        should contact the assistance/help center of said third party.
      </p>
      <p>
        Finally, we may also share your personal information to comply with applicable laws
        and regulations, to respond to a subpoena, search warrant, or other lawful request
        for information we receive, or to protect our rights.
      </p>
      <p>
        <h2>Your data and your rights</h2>
      </p>
      <p>
        If you reside within the EEA (European Economic Area) or another jurisdiction with
        similar data protection legislation, you have the right to access the personal
        information we hold about you and request that it be updated, deleted or corrected.
        On the other hand, if you are a European resident, please note that we are
        processing your information in order to carry out the contracts or service requests
        you made or to carry out our legitimate business interests expressed above. Please
        note that your information will be transferred outside of Europe including Canada and
        the United States.
      </p>
      <p>
        If you wish to exercise the right to update, delete or correct your personal data, we
        appreciate that you contact us through the contact admin@quoteyourprogram.com;
        If you wish to withdraw your consent to the use of your personal data for the
        purposes set out in our privacy statement, we may not be able to provide you with
        access to all or certain parts of our website, applications and services.
      </p>
      <p>
        <h2>Changes and updates to our privacy notices</h2>
      </p>
      <p>
        We may periodically change this privacy statement. However, we will not diminish
        your rights under this privacy statement. We will always update this privacy
        statement on our website; therefore, we suggest that you try to read it when you visit
        the website (the “last updated” indication shows you when this privacy statement
        was last updated).
      </p>
      <p>
        <h2>Data security and storage</h2>
      </p>
      <p>
        We will maintain your data secure by taking appropriate technical and organizational
        measures against unauthorized or unlawful processing and against accidental loss,
        destruction or damage. We will do our best to protect your personal data, but we
        cannot guarantee the security of data transmitted over the Internet or a similar
        connection to our website, applications or services or to another website, application
        or service. If we have provided you (or you have chosen) a password to access
        certain areas of our websites, applications or services, we ask that you please keep
        that password secure; we will not reveal this password to anyone.
      </p>
      <p>
        If you believe your account might be at risk, you can contact us at
        admin@quoteyourprogram.com.
      </p>
      <p>
        <h2>Other websites and social networks</h2>
      </p>
      <p>
        If you click on a link from our website, app or service to another website or service,
        this privacy statement no longer applies. We are not responsible for the data
        practices of third-party websites or services and we encourage you to read the
        privacy statements posted on such websites or services.
      </p>
      <p>
        Our websites, applications or services may allow you to share data with social
        networking websites or use such social networking websites to create your account
        or connect to your social network account. These social networking websites may
        automatically provide us with access to certain personal data that they have held
        about you (for example, content that you have viewed). You should be able to
        manage your privacy settings from within your account on the third party's social
        network, in order to manage what personal data you allow us to access from that
        account.
      </p>
      <p>
        <h2>Cookies, analysis and traffic data</h2>
      </p>
      <p>
        Cookies are small text files that are transferred from our websites, applications or
        services and stored on your device. We use cookies to help us provide you with a
        personalized service, and to help improve our websites, applications and services in
        your interest.
      </p>
      <p>
        Our cookies may be session cookies (temporary cookies that identify and track the
        user within our websites, applications or services and that are deleted as soon as
        you close the browser or leave the session in the application or service) or persistent
        cookies (cookies that allow our websites, apps or services to “remember” who you
        are and your preferences on our websites, apps or services, which will remain on
        your computer or device after you close your browser or log out of the application or
        service).
      </p>
      <p>
        We use the following different types of cookies:
      </p>
      <h3>
        Strictly necessary cookies
      </h3>
      <p>
        These cookies are necessary for our websites, applications or services to function
        properly; For example, these cookies allow you to access secure areas of our
        website or remember what you have added to your shopping cart.
        Performance Cookies and Analytics Technologies
      </p>
      <p>
        These cookies collect data about how visitors and users use our websites,
        applications and services; for example, what functionality visitors use most often,
        and if they get error messages in certain sections of the websites, applications or
        services. These cookies do not collect data that identifies a visitor or a user. All data
        collected by these cookies is in aggregate form and therefore anonymous. We only
        use these cookies to improve the way our website, apps and services work.
      </p>
      <h3>
        Functionality cookies
      </h3>
      <p>
        These cookies allow our websites, applications, and services to remember choices
        you have made (such as your username, language, or the region you are in) and
        offer enhanced and personalized features. These cookies can also be used to
        remember changes you have made to the size and font of text and other parts of
        web pages that you can customize. In addition, they may be used to provide services
        that you have requested. The data these cookies collect may be anonymized and
        cannot track your browsing activity on other websites.
      </p>
      <h3>
        Selective or advertising cookies
      </h3>
      <p>
        These cookies are used to deliver advertisements that are more relevant to you and
        your interests. They may also be used to limit the number of times you see an
        advertisement or quote, as well as to help measure the effectiveness of the
        advertising campaign. They are usually found on advertising networks with the
        permission of the website operator. Its function is to remember that you have visited
        a website and to share this information with other organizations, such as advertising
        companies. Often targeted or advertising cookies will be linked to site functionality
        provided by the other organization.
      </p>
      <h3>
        Web beacons and parameter tracking
      </h3>
      <p>
        These cookies allow our websites, applications, and services to remember choices
        you have made (such as your username, language, or the region you are in) and
        offer enhanced and personalized features. These cookies can also be used to
        remember changes you have made to the size and font of text and other parts of
        web pages that you can customize. In addition, they may be used to provide services
        that you have requested. The data these cookies collect may be anonymized and
        cannot track your browsing activity on other websites.
      </p>
      <h3>
        IP address and traffic data
      </h3>
      <p>
        We keep track of traffic data, which our servers automatically archive, such as your
        Internet Protocol (IP) address, device information, the website you visited before
        ours, and the website you visit after leaving ours. In addition, we collect certain
        website, application, and service statistics, such as access rate, page clicks, and
        page views. From the traffic data or website statistics we will not be able to identify
        any natural person.
      </p>
      <p>
        Further information regarding the individual cookies and analysis technologies we
        use.
      </p>
      <h2>
        Description
      </h2>
      <h3>
        Google Analytics
      </h3>
      <p>
        Google Analytics uses "cookies" to help the website analyze how users use the
        websites, applications or services. The information generated by the cookie about
        your use of the websites, applications or services (including your IP address) will be
        transmitted to and stored by Google on servers in the United States. Google will use
        this information for the purpose of evaluating your use of the websites, applications
        or services, compiling reports on website activity for website operators and providing
        other services relating to website activity and Internet usage. Google may also
        transfer this information to third parties where required by law, or where such third
        parties process the information on Google's behalf. Google will not associate your IP
        address with any other data Google has in its possession. You may reject the use of
        cookies by selecting the corresponding parameters in your browser or within the
        application; however, please note that if you do so you may not be able to fully use
        the functionality of this website. By using our websites, applications or services, you
        consent to the processing of your data by Google in the manner and for the
        purposes indicated above. For more information, see “How Google uses data when
        you use our partner websites or apps” (located at
        www.google.com/policies/privacy/partners/ or any other URL that Google indicates
        from time to time).
      </p>
      <p>
        To prevent Google Analytics from using your data across all websites, please visit
        http://tools.google.com/dlpage/gaoptout.
      </p>
      <h3>
        Cookie deactivation
      </h3>
      <p>
        You can configure your browser or your website, application or service to restrict
        cookies or block them completely if you wish. However, if you disable cookies, you
        may find that it affects your ability to use certain parts of our website, applications or
        services. For more information about cookies and instructions on how to adjust your
        browser settings to accept, delete or reject cookies, visit the website
        www.allaboutcookies.org/.
      </p>
      <h3>
        Acceptance of our use and privacy policies.
      </h3>
      <p>
        By using this website, you accept our privacy and data use policies. If you do not agree with
        these policies, we invite you not to use our site. Your continued use of the
        quoteyourprogram.com site and platform will be deemed your acceptance of the policies as
        well as any updates to those policies.
      </p>
      <h3>
        Do you wish to contact us? Contact us.
      </h3>
      <p>
        If you have any questions regarding how we process your data, about the content of
        this privacy statement, your rights under local law, how we update your records, or
        how to get a copy of the data we hold about you, you can write to our Director of
        Data Protection at the address admin@quoteyourprogram.com.
      </p>

    </div>
  )
}
export { TextPolicy }
