export const classStyleByStatus = {
  Created: 'byp-btn-sm byp-bg-gray byp-color-gray-100 byp-fw-bold',
  Pending: 'byp-btn-sm byp-bg-yelow-100 byp-color-white byp-fw-bold',
  Active: 'byp-btn-sm byp-bg-blue-100 byp-color-white byp-fw-bold',
  Aproved: 'byp-btn-sm byp-bg-blue-100 byp-color-white byp-fw-bold',
  Published: 'byp-btn-sm byp-bg-blue-100 byp-color-white byp-fw-bold',
  Inactive: 'byp-btn-sm byp-bg-gray50 byp-color-gray-100 byp-fw-bold',
  'In Process': 'byp-btn-sm byp-bg-blue-50 byp-color-white byp-fw-bold',
  Deleted: 'byp-btn-sm byp-bg-red byp-color-white byp-fw-bold'
}

// Just 3 styles for every posible status
const classBasicStyleByStatus = {
  Created: 'byp-btn-sm byp-bg-gray50 byp-color-gray-100 byp-fw-bold',
  Pending: 'byp-btn-sm byp-bg-gray byp-color-gray-100 byp-fw-bold',
  Active: 'byp-btn-sm byp-bg-gray50 byp-color-gray-100 byp-fw-bold',
  'In Process': 'byp-btn-sm byp-bg-gray50 byp-color-gray-100 byp-fw-bold',
  Inactive: 'byp-btn-sm byp-bg-gray50 byp-color-gray-100 byp-fw-bold',

  Aproved: 'byp-btn-sm byp-bg-blue-100 byp-color-white byp-fw-bold',
  Published: 'byp-btn-sm byp-bg-blue-100 byp-color-white byp-fw-bold',

  Deleted: 'byp-btn-sm byp-bg-red byp-color-white byp-fw-bold'
}

const classStyleByRoles = {
  'Agency Administrator': 'byp-btn-sm byp-bg-blue-200 byp-color-white byp-fw-bold',
  'Institution Administrator': 'byp-btn-sm byp-bg-blue-200 byp-color-white byp-fw-bold',
  'Education Group Admin': 'byp-btn-sm byp-bg-blue-200 byp-color-white byp-fw-bold',
  'Agency Office Manager': 'byp-btn-sm byp-bg-yelow-100 byp-color-white byp-fw-bold',
  'Agency Consultant': 'byp-btn-sm byp-bg-blue-10 byp-blue-100 byp-fw-bold',
  'Agency Editor': 'byp-btn-sm byp-bg-blue-100 byp-color-white byp-fw-bold',
  'Agency Commercial Manager': 'byp-btn-sm byp-bg-yellow-10 byp-blue-100 byp-fw-bold',
  'Institution Manager': 'byp-btn-sm byp-bg-yelow-100 byp-color-white byp-fw-bold',
  'Institution Marketer': 'byp-btn-sm byp-bg-gray byp-color-gray-100 byp-fw-bold'
}

const classStyleByStatusForSelects = {
  Aproved: 'select-blue-100 byp-fw-bold',
  Active: 'select-blue-100 byp-fw-bold',
  Deleted: 'select-danger byp-fw-bold',
  Force_delete: 'select-danger byp-fw-bold',
  Pending: 'select-yellow-100 byp-fw-bold'
}

const classStyleByStatusForOptions = {
  Aproved: 'byp-color-blue-100 byp-fw-bold',
  Active: 'byp-color-blue-100 byp-fw-bold',
  Deleted: 'byp-color-red byp-fw-bold',
  Force_delete: 'byp-color-red byp-fw-bold',
  Pending: 'byp-color-yellow-100 byp-fw-bold'
}

export function tagStatusClass (status) {
  return classStyleByStatus[status]
}

export function tagStatusBasicClass (status) {
  return classBasicStyleByStatus[status]
}

export function tagByRolClass (rol) {
  return classStyleByRoles[rol]
}

export function SelectClassByStatus (status) {
  return classStyleByStatusForSelects[status]
}

export function OptionsClassByStatus (status) {
  return classStyleByStatusForOptions[status]
}
