import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { useGetpopularDates } from '../../../../../bussines/hooks/agency/analytics/general/useGetpopularDates'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

const MostPopularDatesAndTimes = ({ time, office, region, subregion, countrie }) => {
  const { data, isLoading, error } = useGetpopularDates({ ...time, office, limit: 4, agency_region: region, agency_subregion: subregion, agency_country: countrie })

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        // console.log('🚀 ~ file: MostPopularDatesAndTimes.js:11 ~ useEffect ~ data', data)
      } else if (error) {
        // console.log('🚀 ~ file: MostPopularDatesAndTimes.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, data, error])

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]}>
      {
        data?.map((item) => (
          <Col
            xs={24}
            sm={12}
            xl={6}
            key={item.year + item.month + item.name + item.weeks}
          >
            <div className='quote-list-item-card' style={{ padding: '0.5rem 0.875rem' }}>
              <p className='margin-0 byp-color-gray-100 byp-text-sm'>{item.quotes_count} - Quotes created</p>
              <h5 className='byp-title- quote-overview--color-blue'>{item.month}</h5>
              <div className='d-flex align-center' style={{ justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
                <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>
                  {!item.duration && (item.weeks + ' Weeks')}
                  {item.duration}
                </p>
                <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>{item.label}</p>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  )
}

export default MostPopularDatesAndTimes
