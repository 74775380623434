import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_REGISTER_PAGE } from '../../..'
import { useCreateUser } from '../../../../../bussines/hooks/agency/useCreateUser'
import SessionService from '../../../../../services/SessionService'
import { URL_USER_REGISTER } from '../../../../register/UserRegister'
import { URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE } from '../../../SendEmailToPartner'
import { showErrorModal } from '../../../../../utils/errorModal'

function ButtonAcceptTermsAndConditions ({ successRegister }) {
  const navigate = useNavigate()

  const { state: locationState } = useLocation()
  const { register, currentState, state, isLoading, errorMessage } = useCreateUser()

  useEffect(() => {
    if (currentState === state.STATE_ERROR) {
      showErrorModal(errorMessage)
    }
    if (currentState === state.STATE_SUCCESS) { successRegister && successRegister(true) }
  }, [currentState])

  const handleRegister = () => {
    register(
      locationState.name,
      locationState.email,
      locationState.password,
      locationState.confirm,
      locationState.type,
      locationState.role
    )
  }

  const handleBack = () => {
    navigate(URL_REGISTER_PAGE)
  }
  useEffect(() => {
    if (SessionService.existSession()) {
      if (locationState.type !== 'User') {
        navigate(URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE)
      } else {
        navigate(URL_USER_REGISTER)
      }
    }
  }, [isLoading])

  if (currentState === state.STATE_SUCCESS) {
    return null
  }

  return (
    <div>
      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={handleBack}
        size='large'
        className='byp-btn-back'
      >
        {' '}
        Return
      </Button>
      <Button
        disabled={isLoading}
        loading={isLoading}
        onClick={handleRegister}
        size='large'
        type='primary'
      >
        {' '}
        Confirm
      </Button>
    </div>
  )
}

export { ButtonAcceptTermsAndConditions }
