import api from '../../services/api'

class CourseRepository {
  /**
   * createNewQuote
   * @param {Object} args
   * @returns {Promise<T>}
   */
  static async createNewQuote (args) {
    const response = await api.post('/quote/store', args)
    return response.data
  }

  /**
   * getCoursesByFilter
   * @param {String} filter
   * @returns {Promise<T>}
   */
  static async getCoursesByFilter (queryString) {
    const response = await api.get(`/search/courses?${queryString}`)
    return response.data
  }

  static async getCoursesByFilterAdvanceSearch (queryString) {
    const response = await api.get('/search/courses', {
      params: {
        program: queryString.program,
        country: queryString.countries,
        search: queryString.search,
        advanced: queryString.advanced,
        page: queryString.page,
        place: queryString.place,
        place_type: queryString.place_type,
        institutions: queryString.institutions
      }
    })
    return response.data
  }

  /**
   * getCoursesLocationsByFilter
   * @param {String} filter
   * @returns {Promise<T>}
   */
  static async getCoursesLocationsByFilter (queryString) {
    const response = await api.get(`/search/courses/locations?${queryString}`)
    return response.data
  }

  static async getCoursesLocationsByFilterAdvanceSearch (queryString) {
    const response = await api.get('/search/courses/locations', {
      params: {
        program: queryString.program,
        country: queryString.countries,
        search: queryString.search,
        advanced: queryString.advanced,
        place: queryString.place,
        place_type: queryString.place_type,
        institutions: queryString.institutions
      }
    })
    return response.data
  }

  static async findOffer (courseId, campusId, filters) {
    const response = await api.get(`/quote/${courseId}/${campusId}/offer`, { params: filters })
    return response.data
  }

  static async findOfferPackage (courseId, campusId, filters) {
    const response = await api.get(`/package/${courseId}/${campusId}/offer`, { params: filters })
    return response.data
  }

  static async findCourse (courseId) {
    if (courseId === undefined) return
    const response = await api.get(`/services/${courseId}`)
    return response.data
  }

  static async updateCourse ({ data, id }) {
    const response = await api.put(`/courses/${id}/update`, data)
    return response.data
  }

  static async updateExpire () {
    const response = await api.put('/school/services/update-expired')
    return response.data
  }

  static async updateCourseStatus ({ data, id }) {
    if (id === undefined || data === undefined) {
      return
    }
    // const response = await api.put(`/services/${id}/update`, data)
    const response = await api.put(`/school/courses/${id}/update-status`, data)
    return response.data
  }

  static async savePrices ({ data }) {
    // const response = await api.post('/services/costs/create', data)
    const response = await api.post('/school/courses/costs', data)
    return response.data
  }

  static async delete ({ id }) {
    if (id === undefined) {
      return
    }
    const response = await api.delete(`/services/${id}/delete`)
    return response.data
  }

  static async getCompletedCoursesByCampus (campus, program, year) {
    if (campus === undefined || program === undefined) {
      return
    }
    const response = await api.get(`/services/${campus}/${program}/approved?year=` + year)
    return response.data
  }

  static async getCourseForPublish (campus, course, year) {
    if (campus === undefined || course === undefined) {
      return
    }
    const response = await api.get(`/services/${campus}/${course}/publish?year=` + year)
    return response.data
  }

  static async getCourseCampusList (campus, course, queryString) {
    if (campus === undefined || course === undefined) {
      return
    }
    const response = await api.get(`/services/${campus}/${course}/list?${queryString}`)
    return response.data
  }

  static async getCoursesByProgram (program, params = {}) {
    const response = await api.get(`/services/${program}/list`, { params })
    return response.data
  }

  static async publishCourse ({ sede, course, year, user }) {
    const response = await api.post(`/services/${sede}/${course}/publish`, { year, user })
    return response.data
  }

  static async unPublishCourse ({ sede, course, year, user }) {
    const response = await api.post(`/services/${sede}/${course}/unpublish`, { year, user })
    return response.data
  }

  static async publishOffers (data) {
    const response = await api.post('/offers/publish', data)
    return response.data
  }

  static async getStudyAreas (queryString) {
    const response = await api.get(`/get/study_areas?${queryString}`)
    return response.data
  }

  static async getInstitutionTypes (queryString) {
    const response = await api.get(`/get/insitution_types?${queryString}`)
    return response.data
  }

  static async getCourseTypes (queryString) {
    const response = await api.get(`/get/course_types?${queryString}`)
    return response.data
  }

  static async getGrades (queryString) {
    const response = await api.get(`/get/grades?${queryString}`)
    return response.data
  }

  static async getReligions (queryString) {
    const response = await api.get(`/get/religions?${queryString}`)
    return response.data
  }

  static async getActivites (queryString) {
    const response = await api.get(`/get/activities?${queryString}`)
    return response.data
  }

  static async getSubjects (queryString) {
    const response = await api.get(`/get/subjects?${queryString}`)
    return response.data
  }

  static async getCourseDurations (queryString) {
    const response = await api.get(`/get/course-durations?${queryString}`)
    return response.data
  }

  static async saveExtraInfo ({ course, data }) {
    const response = await api.post(`/services/${course}/extras`, data)
    return response.data
  }
}

export default CourseRepository
