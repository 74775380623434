import { useState } from 'react'
import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import UserRepository from '../../repositories/UserRepository'
import SessionService from '../../../services/SessionService'

function useRegisterUserAB () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.registerApplyBoardUser, {
    onSuccess: (d) => {
      SessionService.createSession(d)
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const registerApplyBoardUser = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, registerApplyBoardUser }
}

function useRegisteABAgency () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.registerApplyBoardAgency, {
    onSuccess: (d) => {
      // SessionService.createSession(d)
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const registerApplyBoardUser = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, registerApplyBoardUser }
}

export { useRegisteABAgency, useRegisterUserAB }
