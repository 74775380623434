import api from '../../services/api'

class CrmRepository {
  async getConfigByCrm (crm) {
    const response = await api.get(`/crm/${crm}`)
    return response.data
  }

  async saveConfigByCrm (data) {
    const response = await api.post('/crm', data)
    return response.data
  }
}

export default new CrmRepository()
