import VimeoRepository from '../../repositories/VimeoRepository'
import { useQuery } from 'react-query'

function useGetEmbedVideo (video) {
  return useQuery(['useGetEmbedVideo', video],
    () => VimeoRepository.onEmbed(video), {
      retry: 1,
      enabled: !!video
    })
}

export { useGetEmbedVideo }
