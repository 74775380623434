import React from 'react'
import { useQuote } from '../../bussines/hooks/quote/useQuote'
import LottieCargando from '../../lotties/LottieCargando'
import Quote from './Components/Quote'

const QuoteSummaryContent = ({ quoteUuid }) => {
  const { isLoading, data: quote } = useQuote(quoteUuid)

  if (isLoading || !quote) {
    return <LottieCargando />
  }

  return (
    <Quote quote={quote} quoteUuid={quoteUuid} />
  )
}

export default QuoteSummaryContent
