import { Button, Col, Collapse, Form, InputNumber, Modal, Row, Input, notification, Tag } from 'antd'
import { useEffect, useState } from 'react'
import utilsState from '../../../../bussines/utils/state-control'
import { useGetFeesByCategoryAndSede } from '../../../../bussines/hooks/fee/useGetFeesByCategoryAndSede'
import { usePostSaveFeePrices } from '../../../../bussines/hooks/fee/usePostSaveFeePrices'
import { useGetCampus } from '../../../../bussines/hooks/school/useGetCampus'
import { HighSeasonFormList } from '../../../../common/components/helper-form/HighSeasonFormList'
import { PrefixLabelSelector } from '../components/PrefixLabelFormItem'
import { useLocation, useNavigate } from 'react-router-dom'
// import { useLocation, useNavigate } from 'react-router-dom'
import { SelectSedesOfFee } from '../../../../common/components/helper-form/SelectSedesOfFee'
import { URL_UPLOAD_TRANSPORTS_PAGE } from '../Transports/UploadTransportsPage'
import { showErrorModal } from '../../../../utils/propsForModal'
import { useFindProgram } from '../../../../bussines/hooks/program/useFindProgram'
import { SelectCampusAndYearByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusAndYearByProgramFormItem'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { LodgingExtraCostsFormItem } from '../components/LodgingExtraCostsFormItem'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ModalUserAlias } from '../../../../common/components/ModalUserAlias'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

const { Panel } = Collapse
const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const itemName = values[name][position].name ?? ''
  const itemStatus = values[name][position].status ?? ''
  return <span>{itemName} <Tag className='byp-ml-1'><small>{itemStatus}</small></Tag></span>
  // return itemName
}
const type = 'lodging'

function FormCreateLodgingPrices ({ program }) {
  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const [enableBtn, setEnableBtn] = useState('false')
  const [modal, contextHolder] = Modal.useModal()

  const { campusId, yearId: yearID } = location.state ?? { campusId: undefined, yearId: undefined }
  const [divisa, setDivisa] = useState()
  const [campusID, setCampusID] = useState(campusId)
  const [programID, setProgramID] = useState()
  const [yearId, setYearId] = useState(yearID)
  const [aprove, setAprove] = useState(false)

  const [openModalAlias, setOpenModalAlias] = useState(false)
  const [valiatedData, setValiatedData] = useState({})

  const { data: programData, isLoading: isLoadingProgram } = useFindProgram(program)
  const { data: FeeList, isLoading, refetch } = useGetFeesByCategoryAndSede(type, campusID, programID, yearId)
  const { data: campus, isLoading: isLoadingCampus } = useGetCampus(campusID)
  const { currentState, isLoading: savingPrices, savePrices, error: errorSaving } = usePostSaveFeePrices()
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />

  const [savinAll, setSavinAll] = useState(false)

  const onFinish = (values, toAprove = false, index) => {
    values.toAprove = toAprove
    if (!values.program_id) {
      values.program_id = programData?.id
    }
    setAprove(toAprove)
    if (index === undefined) {
      setSavinAll(true)
    } else {
      setSavinAll(false)
      const lodging = values.lodgings[index]
      values.lodgings = [lodging]
    }
    console.log('🚀 ~ onFinish ~ values:', values)
    setValiatedData(values)
    setOpenModalAlias(true)
  }

  const onUpdate = ({ campus_id: campusID, year_id: yearID }) => {
    if (campusID !== undefined) {
      setCampusID(campusID)
      setYearId()
      form.setFieldsValue({ year_id: undefined, lodgings: [] })
    }
    if (yearID !== undefined) setYearId(yearID)
  }

  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      // FeeList
      const nFeeList = FeeList?.map(fee => {
        const extraCosts = fee.extra_costs?.map(extra => {
          const esName = extra.translations?.find(translation => translation.to === lang)
          return {
            ...extra,
            name: esName?.translation ?? extra.fee
          }
        })
        return {
          id: fee.id,
          name: fee.name,
          status: fee.status,
          accomodation_fee: fee.accomodation_fee,
          cost: fee.cost,
          extra_cost: fee.extra_cost,
          frequency: fee.frequency,
          high_seasons: fee.high_seasons,
          extra_costs: extraCosts,
          minor_costs: fee.minor_costs
        }
      })
      console.log('🚀 ~ handleChangeTranslateLanguage ~ FeeList:', nFeeList)
      form.setFieldsValue({ lodgings: nFeeList })
    } else {
      form.setFieldsValue({ lodgings: FeeList })
      console.log('🚀 ~ handleChangeTranslateLanguage ~ FeeList:', FeeList)
    }
  }

  useEffect(() => {
    if (!isLoadingProgram && programData) {
      form.setFieldsValue({ program_id: programData?.id })
      setProgramID(programData?.id)
    }
  }, [programData, isLoadingProgram])

  useEffect(() => {
    if (!isLoadingCampus && campus) {
      form.setFieldsValue({ divisa_code: campus?.divisa_code })
      setDivisa(campus?.divisa_code)
    }
  }, [campus])

  useEffect(() => {
    if (!isLoading && FeeList) {
      form.setFieldsValue({ lodgings: FeeList, translation: null })
    }
  }, [isLoading, FeeList])

  useEffect(() => {
    if (!savingPrices && currentState === utilsState.STATE_SUCCESS) {
      modal.success({
        title: 'Success!',
        content: 'Prices saved',
        onOk () {
          if (aprove && savinAll) {
            navigate(
              URL_UPLOAD_TRANSPORTS_PAGE.replace(':program', program),
              {
                state: {
                  menuItemSelected: program + '_transports_create', openSub: program, openSub2: program + '_transports'
                }
              }
            )
          } else {
            refetch()
          }
        }
      })
      setOpenModalAlias(false)
    }
  }, [savingPrices, currentState])

  useEffect(() => {
    if (errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
  }, [errorSaving, modal])

  const list = Form.useWatch('lodgings', form) || []
  const listName = 'lodgings'

  if (isLoading) {
    return <LottieCargando />
  }

  const onContinue = (values) => {
    navigate(
      URL_UPLOAD_TRANSPORTS_PAGE.replace(':program', program),
      {
        state: {
          menuItemSelected: program + '_transports_create',
          openSub: program,
          openSub2: program + '_transports'
        }
      }
    )
  }

  return (
    <>
      <Form
        layout='horizontal'
        scrollToFirstError
        name='FormCreateLodgingPrices'
        onFinish={onFinish}
        form={form}
        onValuesChange={onUpdate}
        initialValues={{
          campus_id: campusID,
          program_id: programData?.id,
          year_id: yearId
        }}
      >
        {contextHolder}
        <div className='byp-dashboard-content'>
          <Row>
            <Col span={16}>
              <h4 className='byp-title'>
                Accommodations fees
              </h4>
            </Col>
            <Col span={8}>
              <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={4} className='byp-text-sm byp-fw-bold'>You're at:</Col>
                <Col xs={12} md={10}>
                  <Form.Item className='margin-0'>
                    <Input className='graduate_show_category--input' value={programData?.label ?? '__________'} readOnly size='small' />
                  </Form.Item>
                </Col>
                <Col xs={12} md={10}>
                  <SelectCampusByProgramFormItem
                    size='small'
                    program={program}
                    name='campus_id'
                    className='graduate_show_type school'
                    disabled
                    placeholder='School name'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* School, Year And Language */}
          <Row>
            <Col span={18} md={20}>
              <SelectCampusAndYearByProgramFormItem program={program} label={programData?.label} campusID={campusID} />
            </Col>
            <Col span={6} md={4}>
              <div className='d-flex justify-end w-100 mt-1'>
                <SelectAvailableTranslationsFormItem
                  name='translation'
                  formItemProps={{
                    noStyle: true
                  }}
                  selectProps={{
                    size: 'small',
                    style: { minWidth: '8rem' },
                    onChange: (lang) => handleChangeTranslateLanguage(lang),
                    allowClear: true
                  }}
                />
              </div>
            </Col>
          </Row>
          {
            isLoading && <LottieCargando />
          }
          {FeeList && FeeList.length > 0 && (
            <Form.List
              name={listName}
            >
              {(fields, { add, remove }, { errors }) => (
                <Collapse
                  defaultActiveKey={['0']}
                  className='course-prices'
                >
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Panel
                      header={
                        panelHeader(
                          restField.fieldKey,
                          form.getFieldValue(),
                          'lodgings'
                        )
                      }
                      key={key}
                      className='byp-p-0'
                    >
                      <Row className='byp-list-course-item-content' gutter={16}>
                        <Col xs={24} md={12}>
                          <h6 className='w-100 byp-mb-1'> Currency: <span className='byp-ml-1' style={{ color: 'black', fontWeight: 'bold' }}>{divisa}</span> </h6>
                          <Row gutter={16} className='byp-mt-1'>
                            <Col xs={12} md={12}><h6>Accommodation search fee</h6></Col>
                            <Col xs={12} md={12}>
                              <Form.Item
                                name={[name, 'accomodation_fee']}
                              >
                                <InputNumber min={0} addonBefore={prefixSelector} controls={false} />
                              </Form.Item>
                            </Col>
                          </Row>

                          {/* {program === 'graduate' && (
                              <Row gutter={16} className='byp-mt-1'>
                                <Col xs={12} md={12}>
                                  <Form.Item
                                    name={[name, 'cost']}
                                  >
                                    <InputNumber min={0} addonBefore={prefixSelector} />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={12}>
                                  <SelectTimeFrequencyFormItem
                                    frequencies={{ unic: true, semester: true, year: true }}
                                    name={[name, 'frequency']}
                                  />
                                </Col>
                              </Row>
                            )} */}

                          {(program !== 'academic_years') && (
                            <Row gutter={16} className='byp-mt-1'>
                              <Col span={24}><h6><b>Accommodation fee</b></h6></Col>
                              <Col xs={12} md={12}><h6>Per week</h6></Col>
                              <Col xs={12} md={12}>
                                <Form.Item
                                  name={[name, 'cost']}
                                >
                                  <InputNumber min={0} addonBefore={prefixSelector} controls={false} />
                                </Form.Item>
                              </Col>
                              <Col xs={12} md={12}><h6>Extra night</h6></Col>
                              <Col xs={12} md={12}>
                                <Form.Item
                                  name={[name, 'extra_cost']}
                                >
                                  <InputNumber min={0} addonBefore={prefixSelector} controls={false} />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}

                          <h6><b>Other costs</b></h6>
                          <LodgingExtraCostsFormItem
                            name={[name, 'extra_costs']}
                            cleanName='extra_costs'
                            childName={list[name]}
                            divisa={divisa}
                            program={program}
                            showMandatory
                          />
                        </Col>
                        <Col xs={24} md={12}>
                          {(program !== 'academic_years') && (
                            <HighSeasonFormList
                              name={[name, 'high_seasons']}
                              divisa={divisa}
                              isLodging
                              setEnableBtn={setEnableBtn}
                            />
                          )}
                          <h6 className='byp-title' style={{ color: 'black', fontWeight: 'bold' }}>Schools where accommodations prices are the same</h6>
                          <SelectSedesOfFee
                            name={[name, 'sede']}
                            campus={campusID}
                            filter={{ year: yearId }}
                            mode='multiple'
                            fee={list[key]?.id}
                          />
                        </Col>
                      </Row>
                      <div className='byp-list-course-item-footer'>
                        <Row className='w-100 byp-mb-1'>
                          <Col span={24}>
                            <Button
                              className='byp-ml-1 float-right byp-btn-blue-100'
                              onClick={() => {
                                onFinish(form.getFieldsValue(), true, index)
                              }}
                              loading={savingPrices && !savinAll}
                            // disabled={savingPrices}
                            >
                              Approve
                            </Button>
                            <Button
                              className='float-right byp-btn-blue-200'
                              onClick={() => {
                                if (enableBtn === 'false') {
                                  onFinish(form.getFieldsValue(), false, index)
                                } else {
                                  notification.error({
                                    message: 'You cannot enter an end date earlier than the start date.',
                                    placement: 'top',
                                    duration: '10'
                                  })
                                }
                              }}
                              loading={savingPrices && !savinAll}
                            // disabled={savingPrices}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Form.List>
          )}
          {FeeList && FeeList.length === 0 && (
            <h4 style={{ color: 'black', marginTop: '10px' }}>
              <b><i>There's nothing here. You need to create an accommodation first</i></b>
            </h4>
          )}
        </div>
        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              {
                FeeList?.length > 0 && (
                  <>
                    <Button
                      className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
                      htmlType='submit'
                      loading={savingPrices}
                      onClick={onContinue}
                    >
                      Continue
                    </Button>
                    <Button
                      className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
                      onClick={() => {
                        onFinish(form.getFieldsValue(), true)
                      }}
                      loading={savingPrices && savinAll}
                    >Approve all
                    </Button>
                    <Button
                      className='byp-btn-blue-200 float-right byp-mt-1 byp-ml-1'
                      onClick={() => {
                        onFinish(form.getFieldsValue(), false)
                      }}
                      loading={savingPrices && savinAll}
                    >Save all
                    </Button>
                  </>
                )
              }
            </Col>
          </Row>
        </div>
      </Form>
      <ModalUserAlias
        isModalOpen={openModalAlias}
        setIsModalOpen={setOpenModalAlias}
        onSuccess={(userAlias) => {
          valiatedData.user_alias = userAlias
          savePrices(type, valiatedData)
        }}
        isLoading={savingPrices}
      />
    </>
  )
}

export { FormCreateLodgingPrices }
