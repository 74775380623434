import { Button, Col, Form, Modal, notification, Row, Input } from 'antd'
import { useEffect, useState } from 'react'
import utilsState from '../..../../../../../bussines/utils/state-control'
import { SelectDiscountAndBlockFormItem } from '../../../../common/components/helper-form/SelectDiscountAndBlockFormItem'
import { FormBasePricesDiscountsContainer } from './FormBasePricesDiscounts'
import { useGetBlockPrices } from '../../../../bussines/hooks/school/discounts/useGetBlockPrices'
import { usePostSaveDiscountValues } from '../../../../bussines/hooks/school/discounts/usePostSaveDiscountValues'
import { FormCoursePricesDiscountsContainer } from './FormCoursePricesDiscountsContainer'
import { DiscountConceptFieldsContainer } from './DiscountConceptFieldsContainer'
import { FormKindDiscountContainer } from './FormKindDiscountContainer'
import { showErrorModal } from '../../../../utils/propsForModal'
import { useFindProgram } from '../../../../bussines/hooks/program/useFindProgram'
import { useLocation } from 'react-router-dom'
import { isSameMonth } from 'date-fns'
import { useGetBlocksAppliedByDiscount } from '../../../../bussines/hooks/school/discounts/useGetBlocksAppliedByDiscount'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ModalUserAlias } from '../../../../common/components/ModalUserAlias'

function FormCustomDiscounts ({ program }) {
  const location = useLocation()
  const { discountId, year: yearString } = location.state ?? { discountId: undefined, year: undefined }

  const [form] = Form.useForm()
  const [block, setBlock] = useState()
  const [campus, setCampus] = useState()
  const [course, setCourse] = useState()
  const [year, setYear] = useState(yearString)
  const [discount, setDiscount] = useState(discountId)
  const [discounts, setDiscounts] = useState()
  const [aprove, setAprove] = useState()
  const [appliedLabel, setAppliedLabel] = useState([])

  const [openModalAlias, setOpenModalAlias] = useState(false)
  const [valiatedData, setValiatedData] = useState({})

  const { data: programData } = useFindProgram(program)
  const { data: pricesBlock, refetch, isLoading } = useGetBlockPrices(block, discount, year, campus, course)
  const { data: discountBlocksApplied, isLoading: isLoadingLabel, refetch: refetchLabel } = useGetBlocksAppliedByDiscount(discount)
  const { saveDiscountValues, isLoading: isSaving, currentState, error, data: resp } = usePostSaveDiscountValues()

  const [modal, contextHolder] = Modal.useModal()

  const onFinish = (values, aprove = false) => {
    const campusWithChanges = form.getFieldValue('campusWithChanges') ?? []
    const coursesWithChanges = form.getFieldValue('coursesWithChanges') ?? []
    console.log('🚀 ~ file: FormCustomDiscounts.js:38 ~ onFinish ~ values:', values)
    values.aprove = aprove
    if (block === 'curso') {
      // Filter campus & courses with changes
      const campusFiltered = values.curso?.filter((campus) => {
        return campusWithChanges.includes(campus.campus_id)
      })
      // const campusFiltered = values.curso

      const campusMapped = campusFiltered?.map((campus) => {
        const coursesFromThisCampus = coursesWithChanges?.filter((item) => item.campus === campus.campus_id)
        const coursesFiltered = campus.courses?.filter((course) => {
          const hasChanges = coursesFromThisCampus?.find((item) => item.id === course.id)
          return !!hasChanges
        })
        campus.courses = coursesFiltered
        return campus
      })
      values.curso = campusMapped
      console.log('🚀 ~ file: FormCustomDiscounts.js:44 ~ onFinish ~ values.curso:', values.curso)
    }
    setAprove(aprove)
    // saveDiscountValues(values)
    setValiatedData(values)
    setOpenModalAlias(true)
  }

  const onUpdate = ({ block, discount, year, campus, course }) => {
    if (block !== undefined) setBlock(block)
    if (discount !== undefined) {
      setDiscount(discount)
    }
    if (year !== undefined) setYear(year)
    if (campus !== undefined) setCampus(campus)
    if (course !== undefined) setCourse(course)
  }

  useEffect(() => {
    if (pricesBlock) {
      console.log('🚀 ~ ', pricesBlock)
      if (block === 'base') {
        pricesBlock.data?.forEach((school) => {
          school.include_materials = school.materials_prices?.some((material) => material.discount)
        })
        form.setFieldsValue({ base: pricesBlock.data })
      }
      if (block === 'curso') {
        pricesBlock.data?.forEach((school) => {
          school.isChanged = false
          const discSelected = discounts?.find((d) => d.id === discount)
          if (program === 'graduate') {
            school.courses = school.courses?.filter((course) => {
              const courseSeason = course.properties?.season
              if (!courseSeason || !Array.isArray(courseSeason)) return false
              return course.properties?.season?.some((courseIntake) => {
                return !!discSelected.intake?.find((dIntake) => {
                  return dIntake === courseIntake
                })
              })
            })
          }
          if (program === 'academic_years' || program === 'boarding_schools') {
            school.courses = school.courses?.filter((course) => {
              return course.properties?.intake_date?.some((courseIntake) => {
                return !!discSelected.intake?.find((dIntake) => {
                  return isSameMonth(new Date(dIntake), new Date(courseIntake))
                })
              })
            })
          }

          school.courses?.forEach(course => {
            course?.concepts?.forEach(concept => {
              if (concept?.discount && typeof concept?.discount === 'string' && concept?.discount_type === 'amount') {
                const numeroSinComa = concept?.discount?.replace(/,/g, '')
                if (numeroSinComa) {
                  const numeroFloat = parseFloat(numeroSinComa)
                  concept.discount = numeroFloat
                }
              } else if (concept?.discount && concept?.discount_type === 'none') {
                concept.discount = null
              }
            })
          })
          // console.log('🚀 ~ file: FormCustomDiscounts.js:65 ~ school.ranges=school.ranges?.map ~ school.ranges:', school.ranges)
          school.courses = school.courses?.map((course) => {
            course.ranges = course.ranges?.map((range) => {
              return {
                min: range.from,
                max: range.to,
                min_frequency: range.frequency,
                max_frequency: range.frequency,
                id: range.id
              }
            })
            course.isChanged = false
            return course
          })
        })
        form.setFieldsValue({ curso: pricesBlock.data })
      }
      if (block === 'alojamiento') form.setFieldsValue({ alojamiento: pricesBlock.data })
      if (block === 'transporte') form.setFieldsValue({ transporte: pricesBlock.data })
      if (block === 'kind') form.setFieldsValue({ inkind: pricesBlock.data })
    }
  }, [pricesBlock])

  useEffect(() => {
    if (!isSaving && currentState === utilsState.STATE_SUCCESS) {
      const message = aprove ? 'Discount values aproved' : 'Discount values saved'
      notification.success({
        message,
        placement: 'top'
      })
      refetch()
      refetchLabel()
      setOpenModalAlias(false)
      console.log(resp)
    }
    if (!isSaving && currentState === utilsState.STATE_ERROR && error) {
      showErrorModal(modal, error.response.data.message)
    }
  }, [isSaving, currentState])

  useEffect(() => {
    if (program) {
      setYear()
      setBlock()
      setAppliedLabel()
      form.resetFields()
    }
    if (!discountId && program) {
      setDiscount()
    }
  }, [program])

  useEffect(() => {
    if (!isLoading && discountBlocksApplied) {
      const labels = Object.keys(discountBlocksApplied).map(
        (key) => {
          const label = getDiscountLabel(discountBlocksApplied[key])
          return key + ' - This discounts applies to: ' + label
        }
      )
      setAppliedLabel(labels)
    }
  }, [isLoading, discountBlocksApplied])

  // if (isLoading) {
  //   return <LottieCargando />
  // }

  const getDiscountLabel = (data) => {
    if (!data.base && !data.course && !data.accommodation && !data.transport) {
      return ' none'
    }
    let label = ''
    if (data.base) label += ' Base prices '
    if (data.course) label += ' Course prices '
    if (data.accommodation) label += ' Accommodation prices '
    if (data.transport) label += ' Transport prices '
    return label.replace('  ', ' + ')
  }

  return (
    <>
      <Form
        layout='horizontal'
        scrollToFirstError
        name='FormCreateQuote'
        onFinish={onFinish}
        form={form}
        onValuesChange={onUpdate}
        requiredMark={false}
        labelCol={{
          xs: 6,
          lg: 8
        }}
        initialValues={{
          discount,
          campusWithChanges: []
        }}
        // labelAlign='left'
      >
        <div className='byp-dashboard-content'>
          {contextHolder}
          {/* Header */}
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <h4 className='byp-title'>Discounts rules</h4>
            </Col>
            <Col span={6}>
              <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={6} className='byp-text-sm byp-fw-bold'>You're at:</Col>
                <Col xs={12} md={11}>
                  <Form.Item className='margin-0'>
                    <Input className='graduate_show_category--input' value={programData?.label ?? '__________'} readOnly size='small' />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Selector */}
          <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
            <Col span={24}>
              <SelectDiscountAndBlockFormItem discount={discount} setDiscount={setDiscount} program={program} setDiscounts={setDiscounts} />
            </Col>
          </Row>
          {appliedLabel && !isLoadingLabel && (
            <Row gutter={24}>
              <Col>
                {appliedLabel?.map((label, index) => (
                  <h6 key={'label_discount_' + label + index} className='byp-title'>
                    {label}
                  </h6>
                ))}

              </Col>
            </Row>
          )}

          {(isLoading) && <LottieCargando />}

          {
            !isLoading && (
              <>
                {
                  block === 'base' && (
                    <FormBasePricesDiscountsContainer
                      name='base'
                      form={form}
                      onFinish={onFinish}
                      program={program}
                    />
                  )
                }

                {
                  block === 'curso' && (
                    <FormCoursePricesDiscountsContainer
                      name='curso'
                      form={form}
                      onFinish={onFinish}
                      program={program}
                    />
                  )
                }

                {
                  block === 'alojamiento' && (
                    <DiscountConceptFieldsContainer
                      name='alojamiento'
                      subname='lodgings'
                      form={form}
                      onFinish={onFinish}
                    />
                  )
                }

                {
                  block === 'transporte' && (
                    <DiscountConceptFieldsContainer
                      name='transporte'
                      subname='transports'
                      form={form}
                      onFinish={onFinish}
                    />
                  )
                }

                {
                  block === 'kind' && (
                    <FormKindDiscountContainer
                      name='inkind'
                      subname='goods'
                      form={form}
                      onFinish={onFinish}
                    />
                  )
                }
              </>
            )
          }

        </div>
        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              <Button
                className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
                loading={isSaving}
                onClick={() => {
                  onFinish(form.getFieldsValue(), true)
                }}
              >Approve
              </Button>
              <Button
                type='primary'
                className='float-right byp-mt-1 byp-ml-1'
                onClick={() => {
                  onFinish(form.getFieldsValue())
                }}
                loading={isSaving}
              >Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <ModalUserAlias
        isModalOpen={openModalAlias}
        setIsModalOpen={setOpenModalAlias}
        onSuccess={(userAlias) => {
          valiatedData.user_alias = userAlias
          console.log('🚀 ~ file: FormCustomDiscounts.js:362 ~ FormCustomDiscounts ~ valiatedData:', valiatedData)
          saveDiscountValues(valiatedData)
        }}
        isLoading={isSaving}
      />
    </>
  )
}

export { FormCustomDiscounts }
