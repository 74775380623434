import { userRoles } from '../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { FormOffice } from './FormOffice'

const URL_CREATE_OFFICES_PAGE = '/dashboard/offices'

function CrudOfficePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <FormOffice />
      </MainTheme>
    </PageProtected>
  )
}

export { CrudOfficePage, URL_CREATE_OFFICES_PAGE }
