import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import { useState } from 'react'
import SchoolRepository from '../../repositories/SchoolRepository'

function usePostStepSchool () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(SchoolRepository.postSteps, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param {Number} step
   * @param {Object} args
   */
  const nextStep = (step, args) => {
    mutation.mutate({ step, data: args })
  }

  return { ...mutation, state, currentState, nextStep }
}

export { usePostStepSchool }
