import { Col, Row } from 'antd'

function RowQuoter ({ colA, colB, hr = true }) {
  return (
    <Row gutter={12} className='w-100'>
      <Col xs={24} md={18} lg={18}>{colA}</Col>
      <Col xs={24} md={6} lg={6} className='byp-border-left byp-centered'>{colB}</Col>
      {hr && <hr className='byp-hr' />}
    </Row>
  )
}

export { RowQuoter }
