import React, { useMemo } from 'react'
import { Form, Input } from 'antd'
import debounce from 'lodash/debounce'
import UserRepository from '../../../bussines/repositories/UserRepository'

function CompanyNameAsyncValidateFormItem ({ debounceTimeout = 400, ...props }) {
  const form = Form.useFormInstance()

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      form.setFields([{
        name: 'name',
        errors: [],
        validating: true
      }])

      UserRepository.validateCompanyName(value)
        .then((res) => {
          form.setFields([{
            name: 'name',
            errors: [],
            validating: false
          }])
        })
        .catch((err) => {
          form.setFields([{
            name: 'name',
            errors: [err.response.data?.message || 'Error'],
            validating: false
          }])
        })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [debounceTimeout])

  const handleChangecompanyName = (companyName) => {
    debounceFetcher(companyName.target.value)
  }

  return (
    <Form.Item
      {...props}
      name='name'
      rules={[{ required: true, message: 'This field is required' }]}
    >
      <Input size='large' placeholder='Company name' onChange={handleChangecompanyName} />
    </Form.Item>
  )
}

export { CompanyNameAsyncValidateFormItem }
