import { Button, Form, Modal } from 'antd'
import { TextShareEmailForCompleteRegister } from '../../texts/TextShareEmailForCompleteRegister'
import state from '../../../../../bussines/utils/state-control'
import { InputSenderAgencyTeam } from '../../forms/InputSenderAgencyTeam'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URL_STEP_BY_STEP_PAGE_AGENCY } from '../../../AgencyStepByStepRegister'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { URL_STEP_BY_STEP_PAGE_COLLEGE } from '../../../InstitutionStepByStepRegister'
import { usePostStepAgency } from '../../../../../bussines/hooks/agency/usePostStepAgency'
import { usePostStepSchool } from '../../../../../bussines/hooks/school/usePostStepSchool'
import ENTERPRISE_TYPES from '../../../../../bussines/utils/enterprise-types-control'
import LottieCargando from '../../../../../lotties/LottieCargando'
import { URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP } from '../../../EduactionGroupStepByStepRegister'
import { usePostStepEducationGroup } from '../../../../../bussines/hooks/education-group/usePostStepEducationGroup'

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,:\s@']+(\.[^<>()[\]\\.,:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const config = {
  title: 'Mail not valid!'
}

const invitationMailInfo = {
  title: 'Se ha enviado la invitación',
  width: 800,
  content: (
    <p>
      Hemos enviado la invitación al correo que nos compartiste. <br />
      Una vez registrada tu agencia podrás utliizar los beneficios de QYP
    </p>
  )
}

export const STEP_CONTINUE_REGISTRATION = -1

function FirstStep () {
  const { user, registrationStatus, logout } = useAuth()
  const [modal, contextHolder] = Modal.useModal()
  const navigate = useNavigate()
  const [stepRequest] = useState(() => {
    switch (user.type) {
      case ENTERPRISE_TYPES.INSTITUTION:
        return usePostStepSchool
      case ENTERPRISE_TYPES.AGENCY:
        return usePostStepAgency
      case ENTERPRISE_TYPES.EDUCATION_GROUP:
        return usePostStepEducationGroup
      default:
        throw new Error('User type no acepted')
    }
  })

  const { isLoading, nextStep, data, currentState } = stepRequest()

  useEffect(() => {
    if (registrationStatus > 0) {
      switch (user.type) {
        case ENTERPRISE_TYPES.INSTITUTION:
          navigate(`${URL_STEP_BY_STEP_PAGE_COLLEGE}`)
          break
        case ENTERPRISE_TYPES.AGENCY:
          navigate(`${URL_STEP_BY_STEP_PAGE_AGENCY}`)
          break
        case ENTERPRISE_TYPES.EDUCATION_GROUP:
          navigate(`${URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP}`)
          break
        default:
          navigate('/')
          break
      }
    }
  }, [registrationStatus])

  useEffect(() => {
    if (data) {
      if (data?.finish) {
        setTimeout(() => {
          logout()
        }, 5000)
      } else {
        switch (user.type) {
          case ENTERPRISE_TYPES.INSTITUTION:
            navigate(`${URL_STEP_BY_STEP_PAGE_COLLEGE}`)
            break
          case ENTERPRISE_TYPES.AGENCY:
            navigate(`${URL_STEP_BY_STEP_PAGE_AGENCY}`)
            break
          case ENTERPRISE_TYPES.EDUCATION_GROUP:
            navigate(`${URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP}`)
            break
          default:
            navigate('/')
            break
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (user.type === ENTERPRISE_TYPES.EDUCATION_GROUP) {
      nextStep(0, { email, role })
    }
  }, [])

  const [form] = Form.useForm()
  const email = Form.useWatch('email', form)
  const role = Form.useWatch('role', form)

  const handleSendEmail = () => {
    if (!email?.trim().length) {
      nextStep(0, {})
      return
    }

    if (!validateEmail(email)) {
      modal.error(config)
      return
    }

    modal.info(invitationMailInfo)
    nextStep(0, { email, role })
  }

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      align='middle'
      footer={
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={logout}
            className='byp-link'
          >
            Salir
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSendEmail}
            size='large'
            className='byp-btn-blue-200'
          >
            Iniciar
          </Button>
        </div>
      }
    >
      {contextHolder}
      <div style={{ margin: '18px 15%' }}>
        <TextShareEmailForCompleteRegister />
        {state.STATE_SUCCESS !== currentState && (
          <InputSenderAgencyTeam form={form} type={user.type} />
        )}
      </div>
    </StepBaseTemplate>
  )
}

export { FirstStep }
