import { Button, Modal } from 'antd'
import { useState } from 'react'
import { SelectsReportsUni } from '../components/SelectsReportsUni'

function IconMayorQue () {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.0' width='15px' height='15px' viewBox='0 0 1280.000000 1280.000000' preserveAspectRatio='xMidYMid meet'>
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g transform='translate(0.000000,1280.000000) scale(0.100000,-0.100000)' fill='white' stroke='none'>
        <path d='M1297 12438 c98 -110 5456 -5964 5490 -5999 l44 -45 -2737 -2990 c-1505 -1644 -2755 -3008 -2777 -3031 l-41 -43 2356 0 2357 0 2775 3031 2776 3031 -61 67 c-34 36 -1283 1401 -2777 3033 l-2717 2968 -2354 0 -2354 0 20 -22z' />
      </g>
    </svg>
  )
}

function AdvanceSearch ({ form, setCountries, setCities, setOffices, setUsers, isAgencyOffice, isLoading, _user, company, defaultuser }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <Button onClick={showModal} className='byp-bg-blue-200 byp-color-white' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        Advanced options
        <IconMayorQue />
      </Button>
      <Modal
        closable={false}
        visible={isModalOpen}
        footer={[
          <Button key={1} onClick={handleCancel} className='byp-bg-blue-200 byp-color-white'>
            Close
          </Button>
        ]}
      >
        <h4 className='byp-title byp-fw-bold byp-mb-1'>Advance search</h4>
        <SelectsReportsUni
          form={form}
          setCountries={setCountries}
          setCities={setCities}
          setOffices={setOffices}
          setUsers={setUsers}
          isAgencyOffice={isAgencyOffice}
          company={{
            id: company.id,
            type: _user.type
          }}
          isLoading={isLoading}
          defaultuser={defaultuser}
        />

      </Modal>
    </div>
  )
}

export { AdvanceSearch }
