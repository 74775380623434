import api from '../../services/api'

class PlaceRepository {
  /**
   * FIND
   * @param languageId
   * @returns {Promise<T>}
   */
  static async search (queryString) {
    const response = await api.get(`/get/places?${queryString}`)
    return response.data
  }

  /**
   * all
   * @returns {Promise<T>}
   */
  static async allCities (params) {
    const response = await api.get('/get/cities', { params })
    return response.data
  }

  /**
   * allCities
   * @returns {Promise<T>}
   */
  static async allCitiesByIso (iso, filters) {
    const response = await api.get(`/get/${iso}/cities`, { params: filters })
    return response.data
  }

  /**
   * getCampus
   * @param cityId
   * @returns {Promise<T>}
   */
  static async getCampus (cityId) {
    const response = await api.get(`/get/${cityId}/campus`)
    return response.data
  }
}

export { PlaceRepository }
