import { useState } from 'react'
import { useMutation } from 'react-query'
import AgencyRepository from '../../../repositories/AgencyRepository'
import state from '../../../utils/state-control'

function useActiveType () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.activeType, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    },
    throwOnError: true
  })

  /**

   * @param data
   */
  const activeType = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, state, currentState, activeType }
}

export { useActiveType }
