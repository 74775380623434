import { Rate } from 'antd'
import { useRateQuote } from '../../../bussines/hooks/quote/useRateQuote'

const RateQuoteComponent = ({ quote }) => {
  const { rateQuote } = useRateQuote()
  return (
    <Rate
      allowHalf
      defaultValue={quote?.rate ?? 0}
      onChange={(rate) => {
        if (rate !== quote?.rate) {
          rateQuote(quote?.id, rate)
          quote.rate = rate
        }
      }}
      allowClear={false}
    />
  )
}

export { RateQuoteComponent }
