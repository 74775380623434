import React from 'react'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { EditAgencyProfile } from '../register/components/forms/Agency/EditAgencyProfile'
import { ProfileEnterpriseProvider } from '../../providers/enterprise/profile-enterprise-provider'
import { useGetAgencyProfile } from '../../bussines/hooks/agency/useGetAgencyProfile'
import { useUpdateAgencyProfile } from '../../bussines/hooks/agency/useUpdateAgencyProfile'

const URL_EDIT_AGENCY = '/agency/edit'

function EditAgencyProfilePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <ProfileEnterpriseProvider useGetCompanyProfile={useGetAgencyProfile} usePostUpdateCompanyProfile={useUpdateAgencyProfile}>
        <EditAgencyProfile />
      </ProfileEnterpriseProvider>
    </PageProtected>
  )
}

export { EditAgencyProfilePage, URL_EDIT_AGENCY }
