import { PublicTheme } from '../../common/theme/PublicTheme'
import { HomeSectionJ } from '../home/components/homeSectionJ'
import { HomeSectionK } from '../home/components/homeSectionK'
import header from './../../common/assets/images/fotos-portada.png'
import { TextPolicy } from './components/TextPolicy'

const URL_ABOUT_POLICY_PAGE = '/about/policy'

function AboutPolicyPage () {
  return (
    <PublicTheme>
      <img src={header} style={{ width: '100%', height: '15rem', objectFit: 'cover', borderRadius: '.6rem' }} alt='' />
      <br />
      <TextPolicy />
      <HomeSectionJ />
      <HomeSectionK />
    </PublicTheme>
  )
}

export { AboutPolicyPage, URL_ABOUT_POLICY_PAGE }
