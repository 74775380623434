import { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, Modal, notification } from 'antd'
import { SelectCurrencyFormItem } from '../../../../common/components/helper-form/SelectCurrencyFormItem'
import TextArea from 'antd/lib/input/TextArea'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { TableServices } from '../Tables/TableServices'
import { usePostFee } from '../../../../bussines/hooks/fee/usePostFee'
import { useGetFeesByCategory } from '../../../../bussines/hooks/fee/useGetFeesByCategory'
import { useFindLodging } from '../../../../bussines/hooks/school/lodging/useFindLodging'
import { useDeleteFee } from '../../../../bussines/hooks/fee/useDeleteFee'
import stateControl from '../../../../bussines/utils/state-control'
import { showErrorModal } from '../../../../utils/propsForModal'
import { SelectCampusAll } from '../components/SelectCampusAll'
import { SelectBranchesAll } from '../components/SelectBranchesAll'
import { SelectInstitutionsAll } from '../components/SelectInstitutionsAll'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { useGetAllBranches } from '../../../../bussines/hooks/agency/useGetAllBranches'
import { useGetAllCampus } from '../../../../bussines/hooks/school/useGetAllCampus'

const rules = { required: true, message: 'This field is required' }

let readOnly = true

function FormCreateAdditionalServices () {
  const { data: branches } = useGetAllBranches()
  const { user: _user } = useAuth()
  const [modal, contextHolder] = Modal.useModal()
  const [form] = Form.useForm()

  const [serviceId, setServiceId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState()
  const [program, setProgram] = useState()

  const { saveFee, isLoading: isSaving, currentState, error: errorSaving } = usePostFee()
  const { data: serviceList, isLoading: isLoadingList, refetch, isFetching } = useGetFeesByCategory('service')
  const { data: service, isLoading: isSearching } = useFindLodging(serviceId)
  const { deleteFee, data: response, isLoading: isDeleting } = useDeleteFee()
  const { data: campus } = useGetAllCampus(program && { program })

  const onFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    if (isUpdateMode) {
      values.id = service.id
    }
    saveFee('service', values)
  }

  useEffect(() => {
    if (serviceList && !isLoadingList) {
      console.log('🚀 ~ file: FormCreateAdditionalServices.js:34 ~ useEffect ~ serviceList', serviceList)
    }
  }, [serviceList, isLoadingList])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      const createMessage = 'Data created succesfully'
      const updateMessage = 'Data updated succesfully'
      notification.success({
        message: isUpdateMode ? updateMessage : createMessage
      })
      refetch()
      form.resetFields()
      if (isUpdateMode) {
        setIsUpdateMode(false)
        setServiceId()
      }
    }
  }, [isSaving, currentState])

  useEffect(() => {
    if (!isSearching && service) {
      console.log('🚀 ~ file: Formservice.js:38 ~ useEffect ~ service', service)
      setIsUpdateMode(true)
      form.setFieldsValue({
        ...service,
        currency: service.currency?.id,
        offices: service.offices?.map((item) => item.id),
        officesall: (branches?.length === service?.offices.length),
        institutionsall: (service?.institutions.length === campus?.institutions.length),
        schools: service.schools?.map((item) => item.id),
        institutions: service.institutions?.map((item) => item.id),
        schoolsall: (service.schools.length === campus?.campus.length)
      })
    }
  }, [service, isSearching])

  useEffect(() => {
    if (!isDeleting && response) {
      // console.log('🚀 ~ file: FormInsurance.js:79 ~ useEffect ~ response', response)
      refetch()
      notification.info({
        message: 'Medical insurance deleted'
      })
    }
  }, [response, isDeleting])

  useEffect(() => {
    if (!isSaving && errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
  }, [errorSaving, modal, isSaving])

  useEffect(() => {
    const roles = _user?.role_names ?? []
    if (roles.includes('agency-admin') || roles.includes('agency-editor')) {
      readOnly = false
    }
  }, [_user])

  return (
    <>
      {contextHolder}
      <Row className='byp-dashboard-content' >
        <Col xs={24} md={12}>
          <Form
            onFinish={onFinish}
            layout='horizontal'
            scrollToFirstError
            name='FormCreateAdditionalServices'
            form={form}
            initialValues={{
            }}
            labelCol={{
              span: 6
            }}
            labelAlign='left'
            requiredMark={false}
          >
            <h3 className='byp-title'>Create your additional services</h3>
            <Col span={16} style={{ marginTop: '30px', padding: '0' }}>
              <SelectCurrencyFormItem
                name='currency' label='Currency'
                size='medium'
              />
            </Col>

            <h5 className='byp-title '>Name and description</h5>
            <Form.Item name='name' label='Service name' rules={[rules]}>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item name='description' label='Description'>
              <TextArea maxLength={600} showCount />
            </Form.Item>

            <h5 className='byp-title '>This package applies to</h5>
            <Row style={{ marginTop: '20px' }} gutter={10}>
              <Col xs={12} md={12}>Program type:</Col>
              <Col xs={12} md={12}>
                <SelectSchoolProgramsFormItem
                  rules={[rules]}
                  name='category'
                  setProgramName={setProgram}
                  form={form}
                  size='small'
                />
              </Col>
            </Row>

            <SelectBranchesAll
              rules={[rules]}
              mode='multiple'
              name='offices'
              label='Offices'
              form={form}
            />

            <SelectInstitutionsAll
              rules={[rules]}
              program={program}
              name='institutions'
              label='Institution'
              mode='multiple'
              form={form}
            />

            <SelectCampusAll
              rules={[rules]}
              name='schools'
              label='Schools'
              mode='multiple'
              form={form}
              program={program}
            />

            <h5 className='byp-title '>Additional comments</h5>
            <Form.Item name='comments' label='Comments'>
              <TextArea maxLength={600} showCount />
            </Form.Item>

            <Button
              type='primary'
              className='byp-btn-blue-200 float-right float-right byp-mt-1'
              htmlType='submit'
              loading={isSaving}
            >
              {isUpdateMode ? 'Update' : 'Save service'}
            </Button>

          </Form>
        </Col>
        <Col xs={24} md={12}>
          <TableServices
            services={serviceList?.data}
            setId={setServiceId}
            deleteFee={deleteFee}
            isLoading={isLoadingList || isFetching || isDeleting}
            canDelete={!readOnly}
          />
        </Col>
      </Row>

    </>
  )
}

export { FormCreateAdditionalServices }
