import { Col, DatePicker, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectCountriesFormItem } from '../../../common/components/helper-form/SelectCountriesFormItem'
import { UploadLogo } from '../../../common/components/UploadLogo'
import { API_URL } from '../../../config'
import { usePostDeleteLogo } from '../../../bussines/hooks/user/usePostDeleteLogo'
import calculateAge from '../../../utils/calculateAge'
const required = { required: true, message: 'Este dato es requerido' }

const GeneralInformation = ({ disabled = false }) => {
  const [, setIso3] = useState()
  const [newProfilePhoto, setNewProfilePhoto] = useState(false)
  const form = Form.useFormInstance()
  const profilePhotoUrl = form.getFieldValue('profile_photo_url')
  // const isMe = Form.useWatch('isMe', form)
  const DofB = Form.useWatch('birthday', form)

  const handleSavePicture = () => {
    // form.submit()
  }

  useEffect(() => {
    if (DofB) {
      const age = calculateAge(DofB)
      form.setFieldsValue({ age })
    }
  }, [DofB])

  return (
    <Row gutter={[16]}>
      <Col span={24}>
        <h2 className='subtitle-aplication'>Información General del Estudiante</h2>
      </Col>
      {/* Picture */}
      <Col span={24}>
        <UploadLogo
          action={`${API_URL}/user/images`}
          usePostDeleteLogo={usePostDeleteLogo}
          profileUrl={profilePhotoUrl}
          title='Subir foto'
          form={form}
          showButtons
          fileFieldName='image'
          btnUploadClassName='byp-btn-gray-200-outline br-05 fs-12'
          btnSaveClassName='byp-btn-gray-200 br-05 fs-12'
          showDeleteButton={false}
          onUploaded={(response) => {
            console.log('🚀 ~ file: ProfileAccountFields.js:25 ~ ProfileAccountFields ~ response:', response)
            setNewProfilePhoto(true)
            form.setFieldsValue({ profile_photo_path: response?.path })
          }}
          onSave={handleSavePicture}
          disabled={disabled}
        />
        {newProfilePhoto && (
          <Form.Item name='profile_photo_path' noStyle>
            <Input type='hidden' />
          </Form.Item>
        )}
      </Col>
      {/* {!isMe && (
      )} */}
      {/* Name */}
      <Col xs={24} md={12}>
        <label className='label-aplication'>Nombre del estudiante Como Aparece en el Pasaporte</label>
        <Form.Item
          name='name'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='Nombre' disabled={disabled} />
        </Form.Item>
      </Col>
      {/* Lastname */}
      <Col xs={24} md={12}>
        <label className='label-aplication'>Apellido(s)</label>
        <Form.Item
          name='last_name'
          rules={[required]}
        >
          <Input className='aplication__input' disabled={disabled} placeholder='Apellido(s)' />
        </Form.Item>
      </Col>

      <Col span={24}>
        <label className='label-aplication'>Email</label>
        <Form.Item
          name='email'
          rules={[required]}
        >
          <Input className='aplication__input' disabled={disabled} placeholder='susy.logu@gmail.com' />
        </Form.Item>
      </Col>

      <Col xs={24} md={8}>
        <label className='label-aplication'>Fecha de Nacimiento</label>
        <Form.Item
          name='birthday'
          rules={[required]}
        >
          <DatePicker className='aplication__input-date' format='DD [de] MMMM [de] YYYY' disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xs={24} md={8}>
        <label className='label-aplication'>Edad</label>
        <Form.Item
          name='age'
        >
          <Input className='aplication__input' readOnly disabled />
        </Form.Item>
      </Col>
      <Col xs={24} md={8}>
        <label className='label-aplication'>País de origen</label>
        <SelectCountriesFormItem
          name='nationality'
          size='large'
          disabled={disabled}
          className='byp-select transparent br-1 aplication__input byp-p-0'
          showSearch
          setCountry={(country) => {
            console.log('🚀 ~ file: ProfileAccountFields.js:46 ~ ProfileAccountFields ~ country:', country)
            setIso3(country.iso3)
          }}
          rules={[required]}
        />
      </Col>

      <Col span={24}>
        <label className='label-aplication'>Teléfono Celular</label>
        <Form.Item
          name='cel'
          rules={[required]}
        >
          <Input className='aplication__input' disabled={disabled} placeholder='+52 33 2631 4560' />
        </Form.Item>
      </Col>

      <Col span={24}>
        <label className='label-aplication'>Dirección completa</label>
        <Form.Item
          name='address'
          rules={[required]}
        >
          <Input className='aplication__input' disabled={disabled} placeholder='Avenida 8 de Julio 879' />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          name='colony'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='Colonia' disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          name='zip_code'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='Código Postal' disabled={disabled} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          name='city'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='Ciudad' disabled={disabled} />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          name='country'
          rules={[required]}
        >
          <Input className='aplication__input' placeholder='País' disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default GeneralInformation
