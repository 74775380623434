import React from 'react'
import { StepProvider } from '../../providers/stepbystep/step-service-provider'
import { PageProtected } from '../../common/components/PageProtected'
import { InstitutionStepRouter } from './components/IntitutionStepRouter'
import { useGetStepSchool } from '../../bussines/hooks/school/useGetStepSchool'
import { usePostStepSchool } from '../../bussines/hooks/school/usePostStepSchool'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ProfileEnterpriseProvider } from '../../providers/enterprise/profile-enterprise-provider'
import { useGetSchoolProfile } from '../../bussines/hooks/school/useGetSchoolProfile'
import { useUpdateSchoolProfile } from '../../bussines/hooks/school/useUpdateSchoolProfile'

const URL_STEP_BY_STEP_PAGE_COLLEGE = '/step-register/institution'

function InstitutionStepByStepRegister () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_INSTITUTION]}>
      <StepProvider useGetStep={useGetStepSchool} usePostSteps={usePostStepSchool} useGetCompanyProfile={useGetSchoolProfile}>
        <ProfileEnterpriseProvider useGetCompanyProfile={useGetSchoolProfile} usePostUpdateCompanyProfile={useUpdateSchoolProfile}>
          <InstitutionStepRouter />
        </ProfileEnterpriseProvider>
      </StepProvider>
    </PageProtected>
  )
}

export { InstitutionStepByStepRegister, URL_STEP_BY_STEP_PAGE_COLLEGE }
