import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetYearsByCampus } from '../../../bussines/hooks/school/useGetYearsByCampus'

const { Option } = Select

function SelectYearByCampus ({ soloTexto, showSearch, loading, isListField, fieldKey, showAll = false, showDefault = false, selectById = true, ...props }) {
  const { campus } = props
  const { isLoading, data: programs, error, isError } = useGetYearsByCampus(campus)

  useEffect(() => {
    if (isError) {
      console.log('🚀 ~ file: SelectYearByCampus.js:14 ~ useEffect ~ Error:', error?.response?.data?.message)
    }
  }, [isLoading])

  if (soloTexto) {
    return (
      <div style={{ fontSize: '12px', width: '37%', paddingTop: '7px' }}>
        <labels>Year:</labels>
        {programs?.map(item => (
          <text key={item.id} style={{ fontWeight: '700' }}>{item.id === campus ? item?.year : ''}</text>
        ))}
      </div>
    )
  } else {
    return (
      <Form.Item {...props}>
        <Select
          {...props}
          loading={isLoading}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={props.placeholder ?? ''}
        >
          {showAll && <Option key='all' value={null}>All</Option>}
          {!campus && showDefault && <Option key='2022' value='2022'>2022</Option>}
          {!campus && showDefault && <Option key='2023' value='2023'>2023</Option>}
          {!campus && showDefault && <Option key='2024' value='2024'>2024</Option>}
          {programs?.map(item => (
            <Option key={item.id} value={selectById ? item.id : item.year}>{`${item?.year}`}</Option>
          ))}
        </Select>
      </Form.Item>
    )
  }
}

export { SelectYearByCampus }
