import React, { useEffect, useState } from 'react'
import { Button, Card, Col, DatePicker, Divider, Form, Input, Row, Select, Statistic, Typography } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'
import { QuoteSchoolDocuments } from './QuoteSchoolDocuments'
import { QuoteSummary } from './QuoteSummary'
import { ModalAddToFavorites } from './ModalAddToFavorites'
import { SelectStudentLocation } from '../../../common/components/helper-form/SelectStudentLocation'
import moment from 'moment'
import { QuoteFormDetailsInstitutionGroups } from './QuoteFormDetailsInstitutionGroups'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { format } from 'date-fns'

const { Text } = Typography
const { Option } = Select

const clr = {
  color: 'var(--blue-200)'
}
const lineCol24 = {
  display: 'flex'
}

const eyeClosed = (
  <svg width='14' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg' className='eye-closed'>
    <path d='M4.67101 6.39093L3.70551 6.13193L4.09901 4.66243C3.51 4.4452 2.96257 4.12861 2.48051 3.72643L1.40401 4.80343L0.696513 4.09593L1.77351 3.01943C1.16555 2.29131 0.757057 1.41781 0.588013 0.484431L1.57201 0.304932C1.95151 2.40593 3.78951 3.99993 6.00001 3.99993C8.21002 3.99993 10.0485 2.40593 10.428 0.304932L11.412 0.483932C11.2432 1.41744 10.8349 2.29112 10.227 3.01943L11.3035 4.09593L10.596 4.80343L9.51952 3.72643C9.03746 4.12861 8.49003 4.4452 7.90102 4.66243L8.29452 6.13243L7.32902 6.39093L6.93502 4.92093C6.31619 5.02697 5.68384 5.02697 5.06501 4.92093L4.67101 6.39093Z' fill='#2460B7' />
  </svg>
)

const eyeOpened = (
  <svg width='14' height='14' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg' className='eye-opened' style={{ marginBottom: '5px' }}>
    <g clipPath='url(#clip0_916_940)'>
      <path d='M11.2067 5.84334C10.0834 3.76668 8.11337 2.51001 5.93336 2.51001C3.75336 2.51001 1.78003 3.76668 0.666698 5.84334L0.573364 6.00001L0.660031 6.16001C1.78336 8.23668 3.75336 9.49335 5.93336 9.49335C8.11337 9.49335 10.0867 8.25335 11.2067 6.16001L11.2934 6.00001L11.2067 5.84334ZM5.93336 8.81001C4.0567 8.81001 2.33336 7.76334 1.33336 6.00001C2.33336 4.23668 4.0567 3.19001 5.93336 3.19001C7.81003 3.19001 9.51337 4.24001 10.53 6.00001C9.51337 7.76334 7.8067 8.81001 5.93336 8.81001V8.81001Z' fill='#2460B7' />
      <path d='M6.02995 8.29672C7.29285 8.29672 8.31662 7.27295 8.31662 6.01006C8.31662 4.74716 7.29285 3.72339 6.02995 3.72339C4.76706 3.72339 3.74329 4.74716 3.74329 6.01006C3.74329 7.27295 4.76706 8.29672 6.02995 8.29672Z' fill='#2460B7' />
    </g>
    <defs>
      <clipPath id='clip0_916_940'>
        <rect width='12' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

const disabledDate = (current) => {
  // Can not select days before today, today and mondays only
  return current < moment().endOf('day')
}

function QuoteFormDetails () {
  const {
    course, service, formConfig, setFormConfig, dateFormat, dateFnsFormat,
    submit, setFavorite, isLoadingPost, form, isPreview
  } = useQuoter()

  const [expand, setExpand] = useState(false)
  const [countries, setCountries] = useState([])
  const [filters, setFilters] = useState({})
  const { isLoading: isLoadingNationalities, data: nationalities } = useGetAllNationalities(filters)

  const { user } = useAuth()

  const getFormatDates = (holidays) => {
    const groupDates = []
    holidays.forEach((holiday) => {
      groupDates.push(holiday.date_formated)
    })
    return groupDates.join(' - ')
  }

  useEffect(() => {
    if (service?.prices) {
      setFilters({
        regions: service?.prices?.regions,
        subregions: service?.prices?.subregions,
        excludecountries: service?.prices?.exclude_countries
      })
    } else if (service.course?.offer?.prices[0]) {
      setFilters({
        regions: service.course?.offer?.prices[0]?.regions,
        subregions: service.course?.offer?.prices[0]?.subregions,
        excludecountries: service.course?.offer?.prices[0]?.exclude_countries
      })
    }
  }, [service])

  useEffect(() => { // Put MX in first place
    if (nationalities && !isLoadingNationalities) {
      const mx = nationalities?.find((country) => country.iso2 === 'MX')
      const restCountries = nationalities?.filter((country) => country.iso2 !== 'MX')
      if (mx) {
        setCountries([mx, ...restCountries])
        form.setFieldsValue({
          student_nationality: mx.id
        })
        setFormConfig({
          ...formConfig,
          student_nationality: mx.id,
          student_region: mx?.region,
          student_subregion: mx?.subregion
        })
      } else {
        setCountries([...restCountries])
        form.setFieldsValue({
          student_nationality: restCountries[0].id
        })
      }
    }
  }, [nationalities, isLoadingNationalities])

  return (
    <Card>
      <Row>
        <Col span={4}>
          <h5 className='byp-title'>Total</h5>
        </Col>
        <Col xs={service.getTotalDiscounts() > 0 ? 12 : 15} lg={service.getTotalDiscounts() > 0 ? 10 : 14}>
          <h5 className='byp-title byp-fw-bold' style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', marginRight: '5px' }}>
            <Statistic
              className={service.getTotalDiscounts() > 0 ? 'price-before txt-sm' : null}
              prefix=''
              precision={2}
              value={`${service.getSubTotalQuoteAmount()}`}
              suffix={`${service.getCurrency()}`}
            />
          </h5>
        </Col>
        {
          service.getTotalDiscounts() > 0 && (
            <Col xs={3} lg={4}>
              <h5 className='byp-title byp-fw-bold' style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <Statistic
                  prefix=''
                  className='price-after txt-sm'
                  precision={2}
                  value={`${service.getTotalQuoteAmount()}`}
                  suffix={`${service.getCurrency()}`}
                />
              </h5>
            </Col>
          )
        }
        <Col offset={1} md={4} lg={5}>
          {
            expand && (
              <Button onClick={() => setExpand(false)} className='byp-btn-expanded'>
                {eyeOpened} Breakdown
              </Button>
            )
          }
          {
            !expand && (
              <Button onClick={() => setExpand(true)} className='byp-btn-not-expanded' style={{ position: 'relative', bottom: '10px' }}>
                {eyeClosed}
                {eyeOpened}
                Breakdown
              </Button>
            )
          }
        </Col>
        {expand && <QuoteSummary />}
        <Divider className='byp-m-0' />
        {/* course name and duration */}
        <Col span={24}>
          <h5 className='byp-title  byp-fw-bold byp-mb-0 byp-mt-1'>
            {service.getNameCourse()}
            {service.getTotalWeeks() > 0 && (' - ' + service.getTotalWeeks() + ' Semanas')}
          </h5>
          <h4 className='byp-title'>
            {service.getCourseProgram()?.label + ', ' + service.getSchoolName()}
          </h4>
        </Col>
      </Row>
      <Row className='ant-card ant-card-bordered byp-mb-1'>
        <Col span={12} style={{ borderRight: '1px solid #d4d4d4', padding: '8px' }}>
          <Text className='byp-blue-100 byp-text-sm'>
            {service.getCourseProgram()?.name !== 'language' && 'Intake'}
            {service.getCourseProgram()?.name === 'language' && 'Start Day'}
          </Text>
          <ErrorBoundary>
            {(service.getCourseProgram()?.name === 'academic_years' || service.getCourseProgram()?.name === 'boarding_schools') && (
              <h5 className='byp-m-0 byp-fw-bold byp-text-md'>
                {service.getStartString('dd LLLL yyyy')}
              </h5>
            )}
            {!(service.getCourseProgram()?.name === 'academic_years' || service.getCourseProgram()?.name === 'boarding_schools') && (
              <h5 className='byp-m-0 byp-fw-bold byp-text-md'>
                {service.getStartString('dd LLLL yyyy')}
              </h5>
            )}
          </ErrorBoundary>
        </Col>
        <Col span={12} style={{ padding: '8px' }}>
          <Text className='byp-blue-100 byp-text-sm'>
            {service.getCourseProgram()?.name !== 'language' && 'Program length'}
            {service.getCourseProgram()?.name === 'language' && 'Last day'}
          </Text>
          {
            (service.getCourseProgram()?.name === 'academic_years' || service.getCourseProgram()?.name === 'boarding_schools')
              ? (
                <h5 className='byp-m-0 byp-fw-bold byp-text-md'>{course.offer?.length + ' ' + course.offer?.time}</h5>
              )
              : (
                <h5 className='byp-m-0 byp-fw-bold byp-text-md'>{service.getEndString() && service.getEndString()}</h5>
              )
          }
        </Col>
      </Row>
      <Divider />
      {/* Student Nationality and Location */}
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <h5 className='byp-title' style={clr}> Student location </h5>
        </Col>
        <Col span={24} className='content-quoter'>
          <Form.Item
            label='Student nationality: '
            labelCol={{ span: 8 }}
            labelAlign='left'
            name='student_nationality'
            rules={[{
              required: true,
              message: 'This field is required!'
            }]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder='student_nationality'
              loading={isLoadingNationalities}
              disabled={!nationalities}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              size='small'
              onChange={(val) => {
                const found = countries?.find((c) => c.id === val)
                setFormConfig({
                  ...formConfig,
                  student_nationality: val,
                  student_region: found?.region,
                  student_subregion: found?.subregion
                })
              }}
            >
              {countries?.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} className='content-quoter'>
          <SelectStudentLocation
            label='Student location'
            name='student_location'
            placeholder='Student location:'
            labelCol={{ span: 8 }}
            labelAlign='left'
            rules={[{
              required: true,
              message: 'Field required'
            }]}
            size='small'
          />
        </Col>
      </Row>

      {/* {
        service?.length && (
          <Row>
            <h5 className='byp-title'>Incluye gratis</h5>
            <ul className='w-100'>
              {service.getIncluded().map((e) => (
                <li key={e.id}><b>{e.name}</b></li>
              ))}
            </ul>
            <Divider />
          </Row>
        )
      } */}

      {/* Expiration dte */}
      <Row>
        <Col span={24} style={lineCol24}>
          <Form.Item
            name='expiration'
            label='Quote valid until'
            labelCol={{ span: 8 }}
            labelAlign='left'
            rules={[{
              required: true,
              message: 'The expiration date is required!'
            }]}
            style={{ width: '100%' }}
          >
            <DatePicker
              placeholder='mm/dd/yyyy'
              size='large'
              disabledDate={disabledDate} style={{ width: '100%' }}
              format={dateFormat}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* Student ID */}
      {user.type !== 'Institution' &&
        <Form.Item
          label='Student ID' name='student_id'
          labelCol={{ span: 8 }}
          labelAlign='left'
          rules={[{
            required: true,
            message: 'The student id is required!'
          }]}
        >
          <Input placeholder='0000000' size='small' style={{ padding: '.4rem', borderRadius: '.5rem' }} />
        </Form.Item>}
      {/* Buttons Favorite And See Quote */}
      {!isPreview && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ModalAddToFavorites setFavorite={setFavorite} submit={submit} />
          <Button
            className='byp-btn-blue-100'
            onClick={submit}
            loading={isLoadingPost}
          >
            See quote
          </Button>
        </div>
      )}
      {isPreview && (
        <div className='byp-centered'>
          <Typography.Title level={3}>You´re on Preview mode</Typography.Title>
          <Typography.Text>In this mode you can see the course with prices in Aproved or Published status</Typography.Text>
        </div>
      )}
      <Divider style={{ margin: '10px 0' }} />
      {service.user.type === 'Agency' && course?.offer?.institution_groups?.length > 0 && (
        <QuoteFormDetailsInstitutionGroups groups={course?.offer?.institution_groups} />
      )}
      <h6 className='byp-title byp-text-lg' style={clr}>Advertisement</h6>
      {course?.offer?.program?.name !== 'language' && (
        <p
          style={{ color: '#2D2F66', fontSize: '11px', fontWeight: '700', lineHeight: '6px' }}
        >NOTE: these prices and start dates are only for reference
        </p>
      )}
      {service.getComments() && (
        <>
          <p>
            {
              service.getComments()?.split('\n')?.map((value, index) => (
                <span key={'comment_span_' + index}>{value} <br /></span>
              ))
            }
          </p>
        </>
      )}
      <h6 className='byp-title byp-text-lg' style={clr}>Additional information</h6>

      {service.getIncluded()?.length > 0 && (
        service.getIncluded()?.map((item) =>
          <p key={item.id}>{item.name}</p>
        )
      )}

      {service.getPrices()?.holidays?.length > 0 && (
        <>
          <h6 className='byp-title byp-text-lg' style={clr}>Holidays</h6>
          <p>
            {
              getFormatDates(service.getPrices()?.holidays)
            }
          </p>
        </>
      )}

      {course.offer.link && (
        <a
          className='ant-btn byp-btn-blue-100 w-100'
          href={'https://' + course.offer.link}
          target='_blank'
          rel='noreferrer'
        >Program link
        </a>
      )}

      {(service.getCourseProgram().name === 'academic_years' || service.getCourseProgram().name === 'boarding_schools') && (
        <Row style={{ marginTop: 10 }}>
          <Divider />
          <Col span={24}>
            <h5 className='byp-title' style={clr}> School’s caracteristics </h5>
          </Col>

          {course.offer.campus?.sports?.length > 0 && (
            <>
              <Col span={24} className='byp-fw-bold'>
                Sports
              </Col>
              {course.offer.campus?.sports?.map((sport, index) => (
                <Col span={24} key={sport + index}>
                  {'- ' + sport}
                </Col>
              ))}
            </>
          )}

          {course.offer.campus?.extracurriculars?.length > 0 && (
            <>
              <Col span={24} style={{ paddingTop: '10px' }} className='byp-fw-bold'>
                Extra curricular courses
              </Col>
              {course.offer.campus?.extracurriculars?.map((extracurricular, index) => (
                <Col span={24} key={extracurricular + index}>
                  {'- ' + extracurricular}
                </Col>
              ))}
            </>
          )}

          {course.offer.campus?.gender && (
            <>
              <Col span={24} style={{ paddingTop: '10px' }} className='byp-fw-bold'>
                Gender
              </Col>
              <Col span={24}>
                {'- ' + course.offer.campus.gender}
              </Col>
            </>
          )}

          {course.offer.campus?.religion && (
            <>
              <Col span={24} style={{ paddingTop: '10px' }} className='byp-fw-bold'>
                Religion
              </Col>
              <Col span={24}>
                {'- ' + course.offer.campus.religion}
              </Col>
            </>
          )}
        </Row>
      )}

      <QuoteSchoolDocuments filesSchool={service.getDocuments()} isAgency={service.user.type === 'Agency'} filesAgency={course.agency_documents} />
    </Card>
  )
}

export { QuoteFormDetails }
