import { Form, Select } from 'antd'
const { Option } = Select

const SelectStudyAreasFormItem = ({ name, className, noStyle, onChange, showAll, ...rest }) => {
  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
    >
      <Select
        className={className}
        // disabled={rest?.disabled}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        // size={rest?.size}
        onChange={onChange}
        placeholder={rest.placeholder ?? ''}
        // mode={rest?.mode}
        {...rest}
      >
        {showAll && <Option key={0} value={null}>All areas</Option>}
        <Option key='b' value='Business'>Business</Option>
        <Option key='a' value='Arts'>Arts</Option>
        <Option key='s' value='Sciences'>Sciences(STEM)</Option>
        <Option key='h' value='Humanities'>Humanities</Option>
        <Option key='e' value='Health & Community Studies'>Health & Community Studies</Option>
        <Option key='o' value='Other'>Other</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectStudyAreasFormItem }
