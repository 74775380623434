import React from 'react'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { TableCountryCities } from './components/TableCountryCities'
import { Col, Row } from 'antd'

const URL_TABLE_COUNTRY_CITIES_PAGE = '/table/countries-cities'

function TableCountryCitiesPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_SUPER_ADMIN
      ]}
    >
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              <TableCountryCities />
            </Col>
          </Row>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { TableCountryCitiesPage, URL_TABLE_COUNTRY_CITIES_PAGE }
