import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useGetDiscounts (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetDiscounts', filtersQuery], () => DiscountRepository.getDiscounts(filtersQuery), {
  })
}

export { useGetDiscounts }
