import axios from 'axios'

export const headers = {
  // 'Content-Type': 'application/json',
  // Accept: 'application/vnd.vimeo.*+json;version=3.4',
  // Authorization: `Bearer ${VIMEO_TOKEN}`
}

const axiosInstance = axios.create({
  baseURL: 'https://vimeo.com',
  headers
})

axiosInstance.interceptors.request.use(function (config) {
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default axiosInstance
