import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
// import { useParams, useSearchParams } from 'react-router-dom'
import { TopBarMenuCustom } from '../components/TopBarMenuCustom'
import { FormCreateAdditionalServices } from './FormCreateAdditionalServices'

const URL_UPLOAD_ADD_SERVICES_PAGE = '/dashboard/agency/create/additional_services'

function CreateAdditionalServices () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >

        <TopBarMenuCustom />

        <FormCreateAdditionalServices />

      </MainTheme>
    </PageProtected>
  )
}

export { CreateAdditionalServices, URL_UPLOAD_ADD_SERVICES_PAGE }
