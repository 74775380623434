import { Button, Col, Form, Input, Modal, Row, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useDeleteDocument } from '../../../../bussines/hooks/school/documents/useDeleteDocument'
import { useGetDocuments } from '../../../../bussines/hooks/school/documents/useGetDocuments'
import { useFindDocuments } from '../../../../bussines/hooks/school/documents/useGetFindDocument'
import { usePostSaveDocuments } from '../../../../bussines/hooks/school/documents/usePostSaveDocuments'
import { SelectBranchesFormItem } from '../../../../common/components/helper-form/SelectBranchesFormItem'
import { SelectCampusFormItem } from '../../../../common/components/helper-form/SelectCampusFormItem'
import { API_URL } from '../../../../config'
import LottieCargando from '../../../../lotties/LottieCargando'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { showErrorModal } from '../../../../utils/propsForModal'
import { TableDocuments } from './TableDocuments'
import { UploadDocumentsFormItem } from './UploadDocumentsFormItem'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'

let readOnly = true

function FormUploadDocuments (props) {
  const { user } = useAuth()
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()

  const [documentId, setDocumentId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)

  const { saveDocuments, isLoading: isSaving, data: resp, error: errorSaving } = usePostSaveDocuments()
  const { deleteDocument, error: errorDeleting, isLoading: isDeleting, data: deleteResp } = useDeleteDocument()
  const { data, isLoading, refetch } = useGetDocuments()
  const { data: document, isLoading: isLoadingDoc, isFetching } = useFindDocuments(documentId)

  const onFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    if (isUpdateMode && readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    const documents = values.documents?.filter(doc => doc.response?.id).map(doc => doc.response.id)
    if (!documents?.length) {
      return notification.info({
        message: 'Please, upload at least one file'
      })
    }
    values.documents = documents
    // console.log('🚀 ~ file: FormUploadDocuments.js ~ line 31 ~ onFinish ~ values', values)
    saveDocuments(values)
  }

  useEffect(() => {
    if (!isSaving && resp?.saved) {
      form.resetFields()
      refetch()
      notification.success({
        message: 'Data saved succesfully'
      })
      if (isUpdateMode) {
        setDocumentId()
        setIsUpdateMode(false)
      }
    }
  }, [isSaving, resp])

  useEffect(() => {
    if (!isDeleting && deleteResp?.deleted) {
      form.resetFields()
      refetch()
      // modal.success({
      //   title: 'Succes'
      // })
    }
  }, [isDeleting, deleteResp])

  useEffect(() => {
    if (!isLoadingDoc && document?.id) {
      console.log('🚀 ~ file: FormUploadDocuments.js:66 ~ useEffect ~ document:', document)
      const docs = [{
        uid: document.id,
        name: <a href={document.url} target='_blank' rel='noreferrer'>{document.name}</a>,
        status: 'done',
        url: document.profile_photo_url,
        response: {
          id: document.id,
          url: document.profile_photo_url,
          name: document.name
        }
      }]
      form.setFieldsValue({
        sedes: document.sedes ?? [],
        documents: docs,
        expire_at: document.expirate_at
      })
      setIsUpdateMode(true)
    }
  }, [isLoadingDoc, document])

  useEffect(() => {
    if (errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
    if (errorDeleting?.response.data) {
      showErrorModal(modal, errorDeleting.response.data.message)
    }
  }, [errorSaving, errorDeleting, modal])

  useEffect(() => {
    const roles = user?.role_names ?? []
    console.log('🚀 ~ file: FormUploadDocuments.js:104 ~ useEffect ~ user:', user)
    // const hasPermisions = ['agency-admin', 'agency-editor', 'agency-manager']
    if (user.type === 'Agency') {
      if (roles.includes('agency-admin') || roles.includes('agency-editor') || roles.includes('agency-manager')) {
        readOnly = false
      }
      if (roles.includes('agency-office-manager')) {
        readOnly = true
      }
    } else {
      readOnly = false
    }
  }, [user])

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <Row gutter={12}>
      <Col xs={24} md={12}>
        <Form
          form={form}
          onFinish={onFinish}
        >
          {contextHolder}
          <h5 className='byp-title-mkt-mtl'>{user?.type === 'Agency' ? 'Upload your offices’ documents' : 'Upload your schools’ documents'}</h5>
          <p className='byp-txt-mkt-mtl'>
            Upload the documents you need. you’ll see these document in the final view of the quote.
          </p>
          <h6 className='byp-sbtl-mkt-mtl'>Upload PDF or Word files.</h6>
          {
            !isFetching && !isLoadingDoc && (
              <UploadDocumentsFormItem
                form={form} name='documents'
                action={`${API_URL}/campus/upload/documents`}
                isUpdateMode={isUpdateMode}
                canUpload={!readOnly}
              />
            )
          }
          {(isFetching || isLoadingDoc) && <BasicSpinner />}
          <Row className='byp-mt-1'>
            <Col>
              <Form.Item name='expire_at' label='Expiration date of the documents'>
                <Input type='date' size='large' />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {user?.type === 'Agency' ? 'Offices where this applies to' : 'Schools where this applies to'}
            <Col span={24}>
              {user?.type === 'Institution' && (
                <SelectCampusFormItem
                  rules={[{ required: true, message: 'This field is required' }]}
                  name='sedes' placeholder={user?.type === 'Agency' ? 'Offices where this applies to' : 'Schools where this applies to'}
                  haveOptionAll
                />
              )}
              {user?.type === 'Agency' && (
                <SelectBranchesFormItem
                  rules={[{ required: true, message: 'This field is required' }]}
                  name='sedes' placeholder='Sedes en donde se aplicará la misma configuración'
                  mode='multiple'
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                htmlType='submit'
                loading={isSaving}
              >
                {isUpdateMode ? 'Update' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col xs={24} md={12}>
        <TableDocuments
          data={data}
          isLoading={isLoading || isDeleting}
          refetch={refetch}
          setDocumentId={setDocumentId}
          deleteDocument={deleteDocument}
          type={user?.type}
          canDelete={!readOnly}
        />
      </Col>
    </Row>
  )
}

export { FormUploadDocuments }
