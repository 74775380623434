import { Col, Form, Row, Switch, Typography } from 'antd'
import { format } from 'date-fns'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { useEffect } from 'react'
import { formatStringDate } from '../../../utils/formatStringDate'
const { Text } = Typography

function ItemCost ({ item }) {
  const { service, formConfig, setFormConfig, isLoadingOffer, course } = useQuoter()

  const handleChange = (value, id, cost, frequency, name) => {
    let courseCosts = []
    if (!formConfig.courseCosts) {
      setFormConfig({
        ...formConfig,
        courseCosts: []
      })
    }
    if (value) {
      courseCosts = formConfig.courseCosts ?? []
      courseCosts?.push({ id, cost, frequency, name })
    } else {
      formConfig.courseCosts?.map((service) => (
        service.id !== id ? courseCosts.push(service) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      courseCosts
    })
  }

  return (
    <Row key={item.id} gutter={12} className='w-100'>
      <Col xs={24} md={18} lg={18}>
        <Text>{item.name}</Text>
        {!item?.mandatory && (
          <Form.Item noStyle name={['costs', item.name]}>
            <Switch
              onChange={(value) => { handleChange(value, item.id, item.cost ?? null, item.frequency, item.name) }}
              className='byp-ml-1'
              checked={service.isCostChecked(item.id)}
            />
          </Form.Item>
        )}
        <br />
        {service.courseCostDiscounts(item.id)?.map((d) => (
          <>
            <Text key={'disc_course_cost_' + d.id}>
              <b>
                {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
              </b>
            </Text>
            <br />
          </>
        ))}
      </Col>
      <Col xs={24} md={6} lg={6} className='byp-border-left byp-centered'>
        <StatisticWithDiscounts
          discounts={service.courseCostDiscounts(item.id)}
          amount={service.courseCost(item.id)?.toFixed(2)}
          currency={service.getCurrency()}
          subtotal={service.courseCostSubtotal(item.id)}
          active={service.isCostChecked(item.id)}
        />
      </Col>
      <hr className='byp-hr' />
    </Row>
  )
}

const FieldCourseCosts = () => {
  const { service } = useQuoter()
  return (
    <>
      {service.courseCosts()?.map((costItem) => (
        <ItemCost key={costItem.id} item={costItem} />
      ))}
    </>
  )
}

export default FieldCourseCosts
