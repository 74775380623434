import { HeaderStep } from '../../HeaderStep'
import { FormTitle } from '../../texts/FormTitle'
import React from 'react'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import { InstitutionFormStepProfile } from '../../forms/InstitutionFormStepProfile'
import FooterStep from '../../../../../common/components/steps/FooterStep'

export const STEP_INSTITUTION_PROFILE = 1

function InstitutionProfileStep () {
  return (
    <StepBaseTemplate
      justify='space-around'
      footer={<FooterStep />}
    >
      <div style={{ margin: '18px 5%' }}>
        <HeaderStep />
        <FormTitle
          title='Edit your school profile'
          text="Let's get started,enter your institution's data so that you have the opportunity to offer the right
           information to your agencies, as well as customize quotes and searches."
        />
        <InstitutionFormStepProfile currentStep={STEP_INSTITUTION_PROFILE} />
      </div>
    </StepBaseTemplate>
  )
}

export { InstitutionProfileStep }
