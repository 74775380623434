import { Col, Row } from 'antd'
import React from 'react'
import { UpIcon } from '../../../quote-preview/assets/imgs/Icons'
import upImage from '../../../../common/assets/images/up-chart.png'
import downImage from '../../../../common/assets/images/down-chart.png'

export const SummarizedResults = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} sm={24} md={12} lg={6}>
        <div className='summarized-results'>
          <p className='summarized-results__country'>Mexico</p>
          <div className='summarized-results__group-first'>
            <div>
              <div className='summarized-results__group-second'>
                <p>19,817</p>
                <div className='summarized-results__up'><UpIcon /> 50.1%</div>
              </div>
              <p className='summarized-results__type-time'>Compared to all time</p>
            </div>
            <div className='summarized-results__img-container'>
              <img src={upImage} alt='up chart' className='summarized-results__img' />
            </div>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6}>
        <div className='summarized-results'>
          <p className='summarized-results__country'>Mexico</p>
          <div className='summarized-results__group-first'>
            <div>
              <div className='summarized-results__group-second'>
                <p>19,817</p>
                <div className='summarized-results__down'><UpIcon /> 50.1%</div>
              </div>
              <p className='summarized-results__type-time'>Compared to all time</p>
            </div>
            <div className='summarized-results__img-container'>
              <img src={downImage} alt='down chart' className='summarized-results__img' />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}
