import { Col, Divider, Form, Row } from 'antd'
import React, { useState } from 'react'
import TimeFilters from '../../agency/analitics/TimeFilters'
import { TopAgencies } from '../components/TopAgencies'
import TotalQuotes from '../../agency/analitics/general/TotalQuotes'
import MostPopularDatesAndTimes from '../../agency/analitics/general/MostPopularDatesAndTimes'
import MostPopularSchools from '../../agency/analitics/components/MostPopularSchools'
import TopUsers from '../../agency/analitics/components/TopUsers'
import MostPopularCourses from '../../agency/analitics/components/MostPopularCourses'
import { SelectsAgenciesAnalitics } from '../components/SelectsAgenciesAnalitics'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { SelectAgenciesByLocation } from '../components/SelectAgenciesByLocation'

const AgenciesAnaliticsInfo = () => {
  const [form] = Form.useForm()
  const [time, setTime] = useState({})

  const region = Form.useWatch('region', form)
  const subRegion = Form.useWatch('subregions', form)
  const countrie = Form.useWatch('countrie', form)
  const agency = Form.useWatch('agency', form)

  const { user } = useAuth()

  return (
    <Row className='byp-dashboard-content'>
      <Col span={24}>
        <Row>
          <Col className='two-thirds-responsive' >
            <h3 className='byp-title'>All agencies analysis</h3>
            <span
              className='byp-title byp-text-lg'
              style={{ marginLeft: '0.5rem' }}
            >
              {/* (Program Languages) */}
            </span>
          </Col>
        </Row>

        <Row gutter={16} className='byp-mb-1'>
          <Col className='two-thirds-responsive' >
            {user?.role_names?.includes('super-admin') && (
              <SelectAgenciesByLocation form={form} />
            )}
            {!user?.role_names?.includes('super-admin') && (
              <SelectsAgenciesAnalitics form={form} />
            )}
          </Col>

          <Col className='time-filter'>
            <TimeFilters setTime={setTime} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className='two-thirds-responsive'>

            <h4 className='byp-title-4 byp-mb-1 byp-black'>Total quotes</h4>

            {region && (
              <TotalQuotes time={{ ...time, agency, onlyAgencies: true }} region={region} subregion={subRegion} countrie={countrie} isAgency />
            )}
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most popular courses</h4>

            {region && (
              <MostPopularCourses time={{ ...time, onlyAgencies: true }} agency={agency} region={region} subregion={subRegion} countrie={countrie} />
            )}
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

            <h4 className='byp-title-4 byp-mb-1 byp-black'>
              Most popular schools
            </h4>

            {region && (
              <MostPopularSchools time={{ ...time, onlyAgencies: true }} agency={agency} region={region} subregion={subRegion} countrie={countrie} />
            )}
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

            <h4 className='byp-title-4 byp-mb-1 byp-black'>
              Most popular dates and times
            </h4>

            {region && (
              <MostPopularDatesAndTimes time={{ ...time, agency, onlyAgencies: true }} region={region} subregion={subRegion} countrie={countrie} />
            )}
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
          </Col>

          <Col className='one-third-responsive'>
            <TopAgencies
              filters={{
                ...time,
                onlyAgencies: true,
                agency,
                agency_region: region,
                agency_subregion: subRegion,
                agency_country: countrie,
                limit: 5
              }}
            />
          </Col>
        </Row>

        <Col span={24}>
          <h4 className='byp-title-4 quote-overview--color-blue byp-mb-1'>
            Top users
          </h4>
          {region && (
            <TopUsers
              time={{ ...time, agency, onlyAgencies: true }}
              region={region}
              subregion={subRegion}
              countrie={countrie}
              showButton={false}
            />
          )}
        </Col>
      </Col>
    </Row>
  )
}

export { AgenciesAnaliticsInfo }
