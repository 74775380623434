import { useStepByStep } from '../../../providers/stepbystep/step-service-provider'
import { STEP_PROFILE } from './steps/agency-profile/AgencyProfileStep'
import { AgencyFinishStep, STEP_FINISH } from './steps/finish-step/AgencyFinishStep'
import React from 'react'
import { ExceptionStepController } from './ExceptionStepController'
import { PageNotFound } from '../../../pages/errors/PageNotFound'
import { FirstStep, STEP_CONTINUE_REGISTRATION } from './steps/first-step/FirstStep'
import LottieCargando from '../../../lotties/LottieCargando'
import { EducationGroupProfileStep } from './steps/education-group-profile/EducationGroupProfileStep'

function EducationGroupStepRouter () {
  const { step, isLoading } = useStepByStep()

  if (isLoading) {
    return <LottieCargando />
  }

  let template = null

  switch (step) {
    case STEP_CONTINUE_REGISTRATION:
      template = (<FirstStep />)
      break
    case STEP_PROFILE:
      template = (<EducationGroupProfileStep />)
      break
    case STEP_FINISH:
      template = <AgencyFinishStep />
      break
    default:
      template = <PageNotFound />
      break
  }
  return <ExceptionStepController usePostMethod={useStepByStep}>{template}</ExceptionStepController>
}

export { EducationGroupStepRouter }
