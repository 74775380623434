import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Modal, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetCountriesAuth } from '../../../bussines/hooks/places/useGetCountriesByRegion'
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { SelectInstitutionsAdvance } from '../../../common/components/helper-form/SelectInstitutions'

const { Option } = Select

const AdvanceSearchModal = ({ isActive, setIsActive, collection, params }) => {
  const [options, setOptions] = useState([])
  const [checkedValues, setCheckedValues] = useState(
    params?.program ?? ['all']
  )
  const [countries, setCountries] = useState(params?.countries ?? [])
  const [idCountries, setIdCountries] = useState(params?.countries ?? [])
  const [search, setSearch] = useState(params?.search ?? '')
  const [programs, setPrograms] = useState(params?.program ?? [])
  const [institutions, setInstitutions] = useState(params?.keyInstituions ?? [])
  const [idInstitutions, setIdInstitutions] = useState([])
  const { isLoading, data } = useGetCountriesAuth(idInstitutions)
  const [countriesByProgram, setCountriesByProgram] = useState([])

  const navigate = useNavigate()

  const onChange = (event) => {
    const { value } = event.target

    if (value === 'all') {
      if (checkedValues.includes('all')) {
        setCheckedValues([])
        setPrograms([])
      } else {
        setCheckedValues(['all'])
        setPrograms(
          options
            .filter((option) => option.value !== 'all')
            .map((option) => option.value)
        )
      }
    } else {
      if (checkedValues.includes('all')) {
        setCheckedValues([value])
        setPrograms([value])
      } else {
        if (checkedValues.includes(value)) {
          setCheckedValues(checkedValues.filter((val) => val !== value))
          setPrograms(programs.filter((val) => val !== value))
        } else {
          setCheckedValues([...checkedValues, value])
          setPrograms([...programs, value])
        }
      }
    }
  }

  const handleChange = (value) => {
    // Retrieve the id
    const ids = value.map((country) => {
      const parts = country.split('-')
      return parts[0]
    })
    console.log('🚀 ~ handleChange ~ value:', value, ids)
    setIdCountries(ids)
    setCountries(value)
  }

  const handleSearch = (value) => {
    setSearch(value.target.value)
  }

  const clearFilters = () => {
    setCheckedValues(['all'])
    setCountries([])
    setPrograms([])
    setInstitutions([])
    setSearch('')

    setIsActive(false)

    navigate('/quote')
  }

  const submit = () => {
    const newInstitutions = []
    if (institutions?.length > 0) {
      institutions.forEach((institution) => {
        newInstitutions.push(institution.split('@')[0])
      })
    }
    navigate('/quote2', {
      state: {
        program: programs,
        countries,
        idCountries,
        search,
        advanced: true,
        institutions: newInstitutions,
        keyInstituions: institutions
      }
    })

    setIsActive(false)
  }

  useEffect(() => {
    const newOptions = []

    newOptions.push({ label: 'ALL', value: 'all' })

    collection.forEach((element) => {
      if (element.key === 'graduate') {
        newOptions.push({ label: 'Diploma', value: 'diploma' })
        newOptions.push({
          label: 'Advanced diploma',
          value: 'advanced_diploma'
        })
        newOptions.push({ label: 'Certificate', value: 'certificate' })
        newOptions.push({ label: 'Bachelor Degree', value: 'bachelor' })
        newOptions.push({
          label: 'Postgraduate Degree',
          value: 'postgraduate'
        })
        newOptions.push({ label: 'Master Degree', value: 'master' })
      } else {
        newOptions.push({ label: element.label, value: element.key })
      }
    })

    setOptions(newOptions)
  }, [collection])

  useEffect(() => {
    const res = data?.filter((item) =>
      programs.some((program) => item.programs.includes(program))
    )
    setCountriesByProgram(res)
  }, [data, programs])

  useEffect(() => {
    const newInstitutions = []
    if (institutions?.length > 0) {
      institutions.forEach((institution) => {
        newInstitutions.push(institution.split('@')[0])
      })
    }
    setIdInstitutions(newInstitutions)
  }, [institutions])

  const clearSearch = () => {
    setSearch('')
  }

  return (
    <Modal
      title='Advance search'
      visible={isActive}
      onCancel={() => setIsActive(false)}
      centered
      footer={null}
      className='advance-search'
    >
      <Row>
        <Col span={24}>
          <p className='advance-title'>Search</p>
        </Col>
        <Col span={24}>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Input
              size='small'
              value={search}
              onChange={handleSearch}
              style={{
                borderColor: search?.length > 1 && search?.length < 3 ? 'red' : null,
                height: '36px'
              }}
              suffix={
                <Button style={{ display: search ? 'block' : 'none' }} type='link' onMouseDown={clearSearch}>
                  <CloseOutlined />
                </Button>
              }
            />
            <div className='advance_info'>
              <InfoCircleOutlined />
              <span className='advance_tooltip-box'>You con search by name of the course, description of the course, name of the institution or name of the campus or something specific you're looking for, i.e. Veterinary</span>
            </div>
          </div>
          {
          search?.length > 1 && search?.length < 3
            ? (<p style={{ color: 'red', fontSize: '10px', margin: '0px' }}>Write at least three words</p>)
            : null
          }
        </Col>
        <Col span={24}>
          <p className='advance-title'>Select the program</p>
        </Col>
        <Col span={24} className='advance-checkbox'>
          {options.map((option) => (
            <div key={option.value}>
              <input
                type='checkbox'
                className='advance-check'
                checked={
                  option.value === 'all'
                    ? checkedValues.includes('all')
                    : checkedValues.includes(option.value)
                }
                id={option.value}
                name={option.value}
                value={option.value}
                onChange={onChange}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          ))}
        </Col>
        <Col span={24}>
          <p className='advance-title'>Select country</p>
        </Col>
        <Col style={{ display: 'flex' }} span={24}>
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            onChange={handleChange}
            value={countries}
            loading={isLoading}
          >
            {countriesByProgram?.length > 0 &&
              countriesByProgram?.map((country) => (
                <Option
                  key={country.iso3}
                  value={country.id + '-' + country.name}
                  label={country.name}
                >
                  {country.name}
                </Option>
              ))}
            {countriesByProgram?.length <= 0 &&
              data?.map((country) => (
                <Option
                  key={country.iso3 + '_'}
                  value={country.id + '-' + country.name}
                  label={country.name}
                >
                  {country.name}
                </Option>
              ))}
          </Select>
          {countries.length > 0 && (
            <Button style={{ backgroundColor: 'white', color: '#2470B7', height: '100%', borderRadius: '80%', marginLeft: '5px' }} onClick={() => setCountries([])}>X</Button>
          )}
        </Col>
        <Col span={24}>
          <p className='advance-title'>Select institution</p>
        </Col>
        <Col span={24} className='advance-select'>
          <SelectInstitutionsAdvance
            className='select-institutions__adv'
            mode='multiple'
            countries={idCountries}
            onChange={setInstitutions}
            isAdvance
            value={institutions}
          />
        </Col>
        <Col span={24} className='advance-btn'>
          <Button onClick={clearFilters}>Clear Filters</Button>
          <Button onClick={submit}>
            Show Results
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export { AdvanceSearchModal }
