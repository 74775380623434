import React from 'react'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { EditInstitutionProfile } from '../register/components/forms/Institution/EditInstitutionProfile'
import { ProfileEnterpriseProvider } from '../../providers/enterprise/profile-enterprise-provider'
import { useGetSchoolProfile } from '../../bussines/hooks/school/useGetSchoolProfile'
import { useUpdateSchoolProfile } from '../../bussines/hooks/school/useUpdateSchoolProfile'

const URL_EDIT_INSTITUTION = '/institution/edit'

function EditInstitutionProfilePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <ProfileEnterpriseProvider useGetCompanyProfile={useGetSchoolProfile} usePostUpdateCompanyProfile={useUpdateSchoolProfile}>
        <EditInstitutionProfile />
      </ProfileEnterpriseProvider>
    </PageProtected>
  )
}

export { EditInstitutionProfilePage, URL_EDIT_INSTITUTION }
