import React, { useEffect } from 'react'
import { useAuth } from './auth-context-provider'
import { URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE } from '../../pages/register/SendEmailToPartner'
import { useNavigate } from 'react-router-dom'
import STATUS from '../../bussines/utils/status-control'

const EnterpriseStatusContext = React.createContext()

function EnterpriseStatusProvider (props) {
  const navigate = useNavigate()
  const { companyStatus, registrationStatus, user } = useAuth()

  useEffect(() => {
    const roles = user?.role_names ?? []
    if (companyStatus === STATUS.PENDING && !roles?.includes('super-admin')) {
      navigate(URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE)
    }
  }, [companyStatus])
  return <EnterpriseStatusContext.Provider value={{ companyStatus, registrationStatus }} {...props} />
}

function useEnterpriseStatus () {
  const context = React.useContext(EnterpriseStatusContext)
  if (context === undefined) {
    throw new Error('useEnterpriseStatus must be used within a EnterpriseStatusProvider')
  }
  return context
}

export { EnterpriseStatusProvider, useEnterpriseStatus }
