import { useMutation } from 'react-query'
import { useState } from 'react'
import state from '../../utils/state-control'
import CampusRespository from '../../repositories/CampusRespository'

function useCreateCampus () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)

  const mutation = useMutation(CampusRespository.createCampus, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const createCampus = (args) => {
    mutation.mutate({ data: args })
  }

  return { ...mutation, state, currentState, createCampus }
}

export { useCreateCampus }
