import { ConfigProvider } from 'antd';
import React, { useEffect } from 'react'
import { BackTop, Col, List, Row } from 'antd'
import { useSearch } from '../../../providers/search/search-context-provider'
import { useGetMyEstimates } from '../../../bussines/hooks/quote/useGetMyEstimates'
import { LoadingCourses } from '../../quoter/components/LoadingCourses'
import { CardItemQuote } from './CardItemQuote'
import { usePostAddToFavorites } from '../../../bussines/hooks/quote/usePostAddToFavorites'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('en');

const ListMyQuoteResults = () => {
  const { isSearch, search } = useSearch()
  const { isLoading, data, refetch } = useGetMyEstimates(search)
  const { markAsfavorite, isLoading: isMarkingAsfavorite, data: resp } = usePostAddToFavorites()

  useEffect(() => {
    if (!isMarkingAsfavorite && resp?.marked) {
      refetch()
    }
  }, [resp, isMarkingAsfavorite])

  if (isSearch) {
    return ''
  }

  if (isLoading) {
    return <LoadingCourses message='Cargando' />
  }

  return (
    <div style={{ margin: 16 }}>
      <p className='remember-text'><span>Remember:</span> This quotations would be available in “My quotes”, for 7 days.</p>
      <BackTop />
      <Row gutter={16} className='hidden-scroll'>
        <Col span={24} className=''>
          <List
            itemLayout='horizontal'
            grid={{
              gutter: 24,
              xxl: 4,
              xl: 4,
              lg: 4,
              md: 3,
              sm: 2,
              xs: 1
            }}
            pagination={{
              onChange: page => {
                console.log(page)
              },
              pageSize: 8,
              hideOnSinglePage: true
            }}
            dataSource={data?.data || []}
            renderItem={(item, key) => <CardItemQuote key={key} item={item} markAsfavorite={markAsfavorite} />}
          />
        </Col>
      </Row>
    </div>
  )
}

export { ListMyQuoteResults }
