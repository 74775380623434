import React from 'react'
import { Col, Form, Input, Row, Switch } from 'antd'
import PropTypes from 'prop-types'
import { ToggleFormItem } from './ToggleFormItem'
import FloatLabel from './FloatLabel'

const CheckboxMessage = ({ position, form }) => {
  const arrayNetwork = Form.useWatch('social_networks', form)
  if (!Array.isArray(arrayNetwork)) return null
  if (arrayNetwork.length <= 0) return null
  return (
    <p>
      {arrayNetwork[position].show
        ? 'Show in quote'
        : 'Hide in quote'}
    </p>
  )
}

function SocialNetworksFormItem ({ name, form, readOnly }) {
  const arrayNetwork = Form.useWatch('social_networks', form) || []
  const arrayName = name
  return (
    <div>
      <Form.List name={arrayName}>
        {(fields) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} gutter={16}>
                <Col span={16}>
                  <FloatLabel
                    label={arrayNetwork[name]?.name}
                    value={arrayNetwork[name]?.url}
                  // className='float-social'
                  >
                    <Form.Item {...restField} name={[name, 'url']}>
                      <Input />
                    </Form.Item>
                  </FloatLabel>
                </Col>
                <Col span={8} style={{ display: 'flex', alignContent: 'center', whiteSpace: 'nowrap' }}>
                  <Row>
                    <Col span={18} style={{ marginTop: '0.5rem' }}>
                      <CheckboxMessage position={restField.fieldKey} />
                    </Col>
                    <Col span={1}>
                      <Form.Item
                        {...restField}
                        name={[name, 'show']}
                        valuePropName='checked'
                      >
                        <Switch
                          checkedChildren='YES'
                          unCheckedChildren='OFF'
                          style={{ display: 'flex' }}
                          disabled={readOnly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>
      <ToggleFormItem
        name='show_all'
        disabled={!arrayNetwork.filter(item => item.url !== '').length}
        checkedtext='Show all the social to qoute'
        uncheckedtext='Hide all the social to qoute'
      />
    </div>
  )
}

SocialNetworksFormItem.propTypes = {
  name: PropTypes.string.isRequired
}

export { SocialNetworksFormItem }
