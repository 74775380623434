import '../../common/assets/css/home.css'
import { HomeSectionA } from './components/homeSectionA'
import { HomeSectionB } from './components/homeSectionB'
import { HomeTopBar } from './components/homeTopBar'
import { HomeSectionC } from './components/homeSectionC'
import { HomeSectionD } from './components/homeSectionD'
import { HomeSectionE } from './components/homeSectionE'
import { HomeSectionF } from './components/homeSectionF'
import { HomeSectionG } from './components/homeSectionG'
import { HomeSectionH } from './components/homeSectionH'
import { HomeSectionI } from './components/homeSectionI'
import { HomeSectionJ } from './components/homeSectionJ'
import { HomeSectionK } from './components/homeSectionK'
import { MainThemeFooter } from '../../common/theme/partials/MainThemeFooter'
import { useState } from 'react'

const URL_HOME_PAGE = '/'

const HomePage = () => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  return (
    <>
      <HomeTopBar handleClick={handleClick} isActive={isActive} />
      <div div className={`app ${isActive ? 'no-scroll' : ''}`}>
        <HomeSectionA />
        <HomeSectionB />
        <HomeSectionC />
        <HomeSectionD />
        <HomeSectionE />
        <HomeSectionF />
        <HomeSectionG />
        <HomeSectionH />
        <HomeSectionI />
        <HomeSectionJ />
        <HomeSectionK />
        {/* <FooterSection/> */}
        <MainThemeFooter />
      </div>
    </>
  )
}

export { HomePage, URL_HOME_PAGE }
