import { Button, Input, notification, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useUpdatePassword } from '../../../../bussines/hooks/user/useUpdatePassword'

const { Title, Text } = Typography
const myRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!#$%\-_=+<>])([a-zA-Z0-9!#$%\-_=+<>]+){8,}$/
const ChangePassword = () => {
  const [oldpassword, setOldpassword] = useState()
  const [newpassword, setNewpassword] = useState()
  const [newpasswordConfirm, setNewpasswordConfirm] = useState()
  const [isEqual, setIsEqual] = useState(true)
  const [showMessage, setShowMessage] = useState(false)
  const { updatePassword, isLoading: isUpdating, data: updateResp, status } = useUpdatePassword()

  useEffect(() => {
    if (status === 'success') {
      notification.success({
        message: 'Your password has been updated succesfully'
      })
      setOldpassword()
      setNewpassword()
      setNewpasswordConfirm()
      setIsEqual(true)
      setShowMessage(false)
    }

    if (status === 'error') {
      notification.error({
        message: 'Old password does not matched.'
      })
    }
  }, [status])

  const handleNewPassword = () => {
    const data = {
      'oldpassword': oldpassword,
      'newpassword': newpassword
    }
    updatePassword(data)
  }

  useEffect(() => {
    if (newpassword === newpasswordConfirm) {
      setIsEqual(true)
    } else {
      setIsEqual(false)
    }
  }, [newpasswordConfirm])

  useEffect(() => {
    if (newpassword) {
      if (!myRegex.test(newpassword)){
        setShowMessage(true)
      } else {
        setShowMessage(false)
      }
    } else {
      setShowMessage(false)
    }
  }, [newpassword])

  return (
    <div className='byp-mr-1 byp-ml-1'>
      <Title level={5}>Change password</Title>
      <Text>Old password</Text>
      <Input value={oldpassword} onChange={(e) => setOldpassword(e.target.value)} className='byp-mb-1' />
      <Text>New password</Text>
      <Input value={newpassword} onChange={(e) => setNewpassword(e.target.value)} />
      {showMessage && (<span className='error-password'>The password must include a minimum of 8 characters and must include at least: 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character.</span>)}
      <div className='byp-mt-1'></div>
      <Text>Confirm new password</Text>
      <Input value={newpasswordConfirm} onChange={(e) => setNewpasswordConfirm(e.target.value)}
      className={ isEqual ? 'byp-mb-1' : 'byp-mb-1 input-confirm-error' } />
      <Button disabled={!isEqual} loading={isUpdating} className='byp-btn-blue-200' onClick={handleNewPassword}>Change</Button>
    </div>
  )
}

export { ChangePassword }