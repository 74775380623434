import { Col, Row, Tag } from 'antd'

function RowInfo ({ label, data, azul }) {
  return (
    <Row className='w-100'>
      <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title byp-fw-bold'>{label}</p></Col>
      <Col style={{ color: 'steelblue' }} xs={12} md={8} lg={12}>{data}</Col>
    </Row>
  )
}

function RowInfoTags ({ label, tags = [] }) {
  return (
    <Row className='w-100'>
      <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title byp-fw-bold'>{label}</p></Col>
      <Col xs={12} md={8} lg={12}>
        {tags.map((tag) => (
          <Tag key={'tag_' + tag.name} className='byp-tag' style={{ color: '#000' }}>{tag.name}</Tag>
        ))}
      </Col>
    </Row>
  )
}

function RowCostInfo ({ label, cost, frequency, discount }) {
  return (
    <Row className='w-100'>
      <Col xs={12} md={6} lg={5} xxl={4}><p>{label}</p></Col>
      <Col xs={12} md={6} lg={6}><b>$ {cost}</b></Col>
      <Col xs={12} md={6} lg={6}>
        Charging for: <b style={{ textTransform: 'capitalize' }}>{frequency === 'unic' ? 'Single payment' : frequency}</b>
      </Col>
      <Col
        xs={12} md={6} lg={6}
        style={{ color: 'steelblue' }}
      >
        {discount}
      </Col>
    </Row>
  )
}

export { RowInfo, RowInfoTags, RowCostInfo }
