import React, { useEffect, useState } from 'react'
// import { LanguageForm } from './forms-search/LanguageForm'
import { ButtonCategorySelector } from './../../quoter/components/ButtonsCategorySelector'
import { useGetMyServiceCategories } from '../../../bussines/hooks/school/useGetMyServiceCategories'
import { useSearch } from '../../../providers/search/search-context-provider'

function getCollection (programs) {
  const menuItems = programs?.map((program) => (
    {
      label: program.label,
      key: program.name,
      form: <p>form</p>
    }
  ))
  return menuItems ?? []
}

const FilterProgram = () => {
  const [collection, setCollection] = useState([])
  const [program, setProgram] = useState('')
  const { isLoading, data } = useGetMyServiceCategories()
  const { changeSearch } = useSearch()

  const changeProgram = (program) => {
    setProgram(program)
    changeSearch('MY_QUOTES', {
      all: true,
      program: getProgram(program),
      type: getType(program),
      subtype: getSubType(program)
    })
  }

  useEffect(() => {
    if (data && !isLoading) {
      const items = getCollection(data)
      const firstItem = items[0] ?? null
      sortCollections(items)
      // Here makes the search at the home page by default
      changeSearch('MY_QUOTES', {
        all: true,
        program: firstItem?.key
      })
    }
  }, [isLoading, data])

  const sortCollections = (data) => {
    const orden = ['language', 'summer_camps', 'academic_years', 'boarding_schools', 'graduate']
    let items = orden.map(orden => {
      return data.find(x => x.key === orden)
    })
    items = items.filter(item => item)

    setCollection(items)
  }

  const types = ['bachelor', 'postgraduate', 'master', 'diploma']
  const subtypes = ['diploma', 'advanced_diploma', 'certificate']
  const getProgram = (program) => {
    if (types.includes(program) || subtypes.includes(program)) {
      return 'graduate'
    }
    return program
  }

  const getType = (program) => {
    if (types.includes(program)) {
      return types[types.indexOf(program)]
    }
    if (subtypes.includes(program)) {
      return 'diploma'
    }
    return null
  }
  const getSubType = (program) => {
    if (subtypes.includes(program)) {
      return subtypes[subtypes.indexOf(program)]
    }
    return null
  }

  return (
    <div>
      <ButtonCategorySelector collection={collection} onChange={changeProgram} selected='LANGUAGE' isVisible={false} />
    </div>
  )
}

export { FilterProgram }
