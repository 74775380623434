import { Button, Form, Input, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import background from '../login/assets/images/byp_login_poster.png'
import background2 from '../login/assets/images/luca-bravo-uk.jpg'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useResetPassword } from '../../bussines/hooks/user/useResetPassword'
import SessionService from '../../services/SessionService'
import { URL_QUOTER_PAGE } from '../quoter'

const URL_RESET_PASSWORD_PAGE = '/reset-password'

const ResetPasswordPage = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const token = params.get('token')
  const email = params.get('email')
  const [random, setRandom] = useState(1)
  const { isLoading, onSubmit, currentState, data, error } = useResetPassword()

  const onFinish = (values) => {
    values.token = token
    onSubmit(values)
  }

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10) + 1)
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: Reset.js:21 ~ useEffect ~ data', data)
    }
    if (!isLoading && error) {
      console.log('🚀 ~ file: Reset.js:21 ~ useEffect ~ data', error)
      let message = error.response.data.status
      if (Array.isArray(message)) {
        message = message[0]
      } else {
        message = 'Something went wrong'
      }
      notification.error({
        message
      })
    }
  }, [isLoading, currentState, data, error])

  React.useEffect(() => {
    if (SessionService.existSession()) {
      // if (searchParams.get('redirect')) {
      //   navigate(searchParams.get('redirect'))
      // } else {
      // }
      navigate(URL_QUOTER_PAGE)
    }
  }, [isLoading])

  return (
    <div>
      <div className='desktop-screen'>
        <div className='form-container'>
          <div className='img-logo-container'>
            <AppLogoNameHeader
              style={{
                width: '100%',
                objectFit: 'contain',
                height: '100%',
                paddingBottom: '18px'
              }}
            />
          </div>
          <Form
            name='forgot_password'
            className='login-form'
            style={{ width: '50%' }}
            onFinish={onFinish}
          >
            <h4 style={{ color: 'black' }}>Create new password</h4>
            <p>We'll ask for this password whenever you Sign-In.</p>
            <Form.Item
              name='email'
              initialValue={email}
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!'
                }
              ]}
              style={{
                marginBottom: 24
              }}
            >
              <Input
                size='large'
                value={email}
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Email'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                },
                {
                  pattern:
                  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
                  message:
                    'The password must include a minimum of 8 characters and include at least: 1 number, one uppercase, 1 lowercase, and one special character.'
                },
              ]}
              style={{
                marginBottom: 24
              }}
            >
              <Input
                size='large'
                prefix={<LockOutlined className='site-form-item-icon' />}
                placeholder='Password'
                type='password'
              />
            </Form.Item>
            <Form.Item
              name='confirmation'
              dependencies={['password']}
              hasFeedback
                
              rules={[
                {
                  required: true,
                  message: 'Please insert your password!'
                },
                ({ getFieldValue }) => ({
                  validator (_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('The two passwords that you entered do not match!')
                    )
                  }
                })
              ]}
            >
              <Input.Password
                size='large'
                placeholder='Password confirmation'
                prefix={<LockOutlined className='site-form-item-icon' />}
              />
            </Form.Item>
            <Button
              loading={isLoading}
              className='byp-btn-blue-200'
              block
              htmlType='submit'
            >
              Save changes and sign-in
            </Button>
          </Form>
        </div>
        <div className='img-container-login'>
          <img
            src={random <= 5 ? background : background2}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export { ResetPasswordPage, URL_RESET_PASSWORD_PAGE }
