

export function HomeSectionH() {
  return (
    <div className="home-responsive-font-size">
      <div className='col align-items-center' style={{ textAlign: 'center' }}>
        <h4 style={{ color: '#2460b7', fontSize: '2.5rem', textAlign: 'center' }}>
          <b>Join to</b>
        </h4>
        <p></p>
        <h3 style={{ color: '#2460b7', fontSize: '3.75rem' }}>
          <b>Quote Your Program</b>
        </h3>
        <h4 className='byp-mb-1' style={{ color: '#2460b7', fontSize: '2.5rem' }}>
          <b>and increase your sells</b>
        </h4>
        <div className='d-flex justify-center'>
          <h5
            className='w-75 p-1'
            style={{ color: '#1A171E', fontSize: '1.438rem', textAlign: 'center' }}
          >
            If you would like to know more about QYP, leave us your contact
            information and we'll be happy to schedule an appointment to answer
            all of your questions.
          </h5>
        </div>
      </div>
    </div>
  );
}
