import { Button, Col, DatePicker, Form, Row } from 'antd'
import { AddAndRemoveListButtons } from '../../../pages/dashboards/Institution/components/AddAndRemoveListButtons'
import { TextInsideButton } from '../TextInsideButton'

function DatesFormItem ({ name }) {
  const arrayName = name
  return (
    <div>
      <Form.List
        name={arrayName}
        initialValue={[
          {}
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={16}>
                <Col span={18}>
                  <Form.Item
                    {...restField}
                    name={[name, 'date']}
                  >
                    <DatePicker format='DD-MM-YYYY' className='w-100' />
                  </Form.Item>
                </Col>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Row>
            ))}
            {
              fields.length === 0 && (
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right svg-boton-blue-fill' onClick={() => add()}>
                    <TextInsideButton text='Add' />
                  </Button>
                </Form.Item>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { DatesFormItem }
