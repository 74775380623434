import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { Col, Input, Row, Select } from 'antd'
import { SelectCampusByProgramFormItem } from '../../../../../common/components/helper-form/SelectCampusByProgramFormItem'

const { Search } = Input
const { Option } = Select

const DiscountTableFilters = ({ program = 'graduate', data, setData }) => {
  const [searchFilter, setSearchFilter] = useState({})

  function updateData () {
    if (data?.data?.length > 0) {
      const nData = data?.data?.filter((item) => {
        let ok = true
        if (searchFilter?.sede) {
          ok = !!item.sedes?.find((sede) => sede.id === searchFilter?.sede)
        }
        if (searchFilter?.status && ok) {
          ok = item.status === searchFilter?.status
        }
        if (searchFilter?.search && ok) {
          ok = !!item.name?.toLowerCase().includes(searchFilter?.search?.toLowerCase())
        }
        return ok
      })
      setData(nData)
    }
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  useEffect(() => {
    updateData()
  }, [searchFilter])

  return (
    <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
      <Col xs={24} md={12} lg={6}>
        <Search
          className='byp-input-gray'
          placeholder='Search'
          onChange={(event) => debounceFilter('search', event.target.value)}
          allowClear
        />
      </Col>
      <Col span={9}>
        <SelectCampusByProgramFormItem
          program={program}
          onChange={(e) => {
            e
              ? handleAddFilter('sede', Number(e))
              : handleRemoveFilter('sede')
          }}
          showAll
          placeholder='All the schools'
        />
      </Col>
      <Col span={9}>
        <Select
          style={{ width: '70%', marginLeft: '5%' }}
          size='small'
          onChange={(e) => {
            e
              ? handleAddFilter('status', e)
              : handleRemoveFilter('status')
          }}
          placeholder='Status'
          className='w-100'
        >
          <Option key={0} value={null}>All status</Option>
          <Option key={1} value='Active'>Active</Option>
          <Option key={2} value='Inactive'>Inactive</Option>
        </Select>
      </Col>

    </Row>
  )
}

export { DiscountTableFilters }
