import { Button, Col, Collapse, Row, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { URL_COURSEPRICES_CREATE_PAGE } from '../CoursePrices/CreateCoursePricesPage'
import { URL_CUSTOMDISCOUNT_PAGE } from '../Discounts/CustomDiscountsPage'
import { URL_LODGINGPRICES_CREATE_PAGE } from '../LodgingPrices/CreateLodgingPricesPage'
import { URL_UPLOAD_PRICES_PAGE } from '../Prices/UploadPricesPage'
import { URL_TRANSPORTGPRICES_CREATE_PAGE } from '../TransportPrices/CreateTransportPricesPage'
import { format } from 'date-fns'
import { formatStringDate } from '../../../../utils/formatStringDate'

const { Panel } = Collapse

const dayLabel = {
  Saturday: 'Sábado',
  Sunday: 'Domingo',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes'
}

function getDayLabel (day) {
  return dayLabel[day]
}

function convertidorFechas(fecha) {
  let año, dia, mes
  año = fecha.substring(0, 4)
  if (fecha.charAt(7) === '-') {
    mes = fecha.substring(5, 7)
    dia = fecha.substring(8)
  }
  else {
    mes = fecha.substring(5, 6)
    dia = fecha.substring(7)
  }

  switch (mes) {
    case '01':
      mes = 'January'
      break
    case '02':
      mes = 'February'
      break
    case '03':
      mes = 'March'
      break
    case '04':
      mes = 'April'
      break
    case '05':
      mes = 'May'
      break
    case '06':
      mes = 'June'
      break
    case '07':
      mes = 'July'
      break
    case '08':
      mes = 'August'
      break
    case '09':
      mes = 'September'
      break
    case '10':
      mes = 'October'
      break
    case '11':
      mes = 'November'
      break
    case '12':
      mes = 'December'
      break
    default:
      mes = 'Error en el mes'
  }

  return mes + ' ' + dia + ', ' + año

}

const frequencyLabel = (frequency) => {
  switch (frequency) {
    case 'unic':
      return 'Only Time'
    case 'week':
      return 'Weeks'
    case 'weeks':
      return 'Weeks'
    case 'monthly':
      return 'Monthly'
    case 'moth':
      return 'Monthly'
    case 'every 4 months':
      return 'Every 4 months'
    case 'semester':
      return 'semester'
    case 'anual':
      return 'Anual'
    default:
      return ''
  }
}

const NameFrecuency = (frecuencia) => {
  switch (frecuencia) {
    case 'year':
      return 'One year payment'
    case 'weeks':
      return 'Weekly'
    case 'semester':
      return 'One semester'
    case 'unic':
      return 'One single payment'
    default:
      return 'no hay nada'
  }
}

const costItemNombreNegritasEspecial = (item) => {
  return (
    <Row key={item.id} className='w-100'>
      <Col xs={9} md={8} lg={7}>
        <ul>
          <li> <b>{item.name}</b> </li>
        </ul>
      </Col>
      <Col xs={12} md={11} lg={10}>
        <b>${item.cost}.00</b>
      </Col>
      <Col xs={24} md={4} lg={8} ><i>{item.description}</i></Col>
    </Row>
  )
}

const costItemNombreNegritas = (item) => {
  return (
    <Row key={item.id} className='w-100' >
      <Col xs={9} md={8} lg={8}>
        <ul>
          <li> <b>{item.name}</b> </li>
        </ul>
      </Col>
      <Col xs={12} md={11} lg={3}>
        <b>${item.cost}.00 </b>
      </Col>
      <Col xs={24} md={4} lg={10} ><i>{item.description}</i></Col>
      <Col xs={24} md={4} lg={3} >{item.mandatory==true?'Mandatory':null}</Col>
    </Row>
  )
}

const costItem = (item) => {
  return (
    <Row key={item.id} className='w-100'>
      <Col span={5}>{item.name}</Col>
      <Col span={4}>
        <b>${item.cost}.00</b>
      </Col>
      <Col span={5}>Charging for:</Col>
      <Col span={4}><b>{frequencyLabel(item.frequency)}</b></Col>
      <Col span={3}>{item.description}</Col>
      <Col span={3} >{item.mandatory==true?'Mandatory':null}</Col>
    </Row>
  )
}

const costRangeItem = (item, divisa) => {
  return (
    <Row key={item.id}>
      <Col span={24}>
        <ul>
          <li>{item.name}</li>
        </ul>
      </Col>
      <Col xs={8} md={6} lg={4}>Materials fee:</Col>
      <Col xs={8} md={6} lg={4}>
        {item.cost_range_type && (
          item.min + ' to ' + item.max + ' ' + frequencyLabel(item.frequency) + ':'
        )}
      </Col>
      <Col xs={8} md={6} lg={4}>
        <small className='byp-mr-1'>{divisa}</small>
        {item.cost}
      </Col>
      <Col span={24} />
      {
        item.cost_range_type && (
          <>
            <Col xs={{ offset: 8, span: 8 }} md={{ offset: 8, span: 8 }} lg={{ offset: 4, span: 4 }}>Additional week:</Col>
            <Col xs={8} md={6} lg={4}>
              <small className='byp-mr-1'>{divisa}</small>
              {item.extra_cost}
            </Col>
            <Col span={24} />
            <Col xs={{ offset: 8, span: 8 }} md={{ offset: 8, span: 8 }} lg={{ offset: 4, span: 4 }}>limit fee:</Col>
            <Col xs={8} md={6} lg={4}>
              <small className='byp-mr-1'>{divisa}</small>
              {item.cost_max}
            </Col>
          </>
        )
      }
    </Row>
  )
}

function PricesColumns({ programa, course }) {
  if (programa === 'Languages') {
    return (
      course?.costs?.map((cost) => (
        <li key={cost.id}>
          <RowInfoPrices periodo={`${cost.min}` + ' - ' + `${cost.max}`}
            tiempo_periodo={`${frequencyLabel(cost.frequency)}`}
            precio={`${cost.cost}` + '.00'}
            tiempo={'Per week'} > Saludos </RowInfoPrices>
        </li>))
    )
  }
  if (programa === 'High Education') {
    console.log(course)
    return (
      
      course?.costs?.map((cost) => (
        <li key={cost.id}>
          <RowInfoPrices periodo={(cost.name)}
            precio={`${cost.cost}` + '.00'}
            tiempo={NameFrecuency(cost.frequency)} mandatory={cost.mandatory}> Saludos </RowInfoPrices>
        </li>))
    )
  }

  if (programa === 'High Schools') {
    return (
      course?.costs?.map((cost) => (
        <li key={cost.id}>
          <RowInfoPrices periodo={`${cost.name}`}
            precio={`${cost.cost}` + '.00'}
            tiempo={NameFrecuency(cost.frequency)} mandatory={cost.mandatory} > Saludos </RowInfoPrices>
        </li>))
    )
  }

  if (programa === 'Boarding Schools') {
    return (
      course?.costs?.map((cost) => (
        <li key={cost.id}>
          <RowInfoPrices periodo={`${cost.name}`}
            precio={`${cost.cost}` + '.00'}
            tiempo={NameFrecuency(cost.frequency)} > Saludos </RowInfoPrices>
        </li>))
    )
  }
}

const panelFooter = (onClick, url, state) => {
  return (
    <div style={{  display: 'flex', justifyContent: 'end' }}>
      <Button
        className='float-right byp-btn-blue-100'
        onClick={() => { onClick(url, state) }}
      >
        Edit block
      </Button>
    </div>
  )
}
function RowInfoCurren ({ label, data }) {
  return (
    <Row className='w-100'>
      <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title '>{label}</p></Col>
      <Col xs={12} md={8} lg={5} style={{ marginLeft: '-8.8rem', color: '#1A171E', fontWeight: '700' }}>{data}</Col>
    </Row>
  )
}

function RowInforCurrentShort ({ label, data }) {
  return (
    <Row className='w-100'>
      <Col><p className='byp-title '>{label}</p></Col>
      <Col style={{ color: '#1A171E', fontWeight: '700', marginLeft: '.5rem' }}>{data}</Col>
    </Row>
  )
}

function RowInfo ({ label, data, azul }) {
  if (azul) {
    return (
      <Row className='w-100'>
        <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title byp-fw-bold'>{label}</p></Col>
        <Col style={{ color: 'steelblue' }} xs={12} md={8} lg={12}>{data}</Col>
      </Row>
    )
  }
  else {
    return (
      <Row className='w-100'>
        <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title byp-fw-bold'>{label}</p></Col>
        <Col xs={12} md={8} lg={12}>{data}</Col>
      </Row>
    )
  }
}

function RowInfoPrices ({ periodo, tiempo_periodo, precio, tiempo, mandatory }) {
  if (!tiempo_periodo) {
    return (
      <Row>
        <Col className='byp-title byp-fw-bold' xs={12} md={8} lg={5} xxl={4}>
          {periodo}
        </Col>
        <Col className='byp-mr-1' xs={12} md={8} lg={5} xxl={4}>
          {precio}
        </Col>
        <Col className='byp-mr-1' xs={12} md={8} lg={5} xxl={4}>
          <small>{tiempo}</small>
        </Col>
        <Col className='byp-mr-1' xs={12} md={8} lg={5} xxl={4}>
        {mandatory==1?'Mandatory':null}
        </Col>
      </Row>
    )
  }
  return (
    <Row>
      <Col className='byp-title byp-fw-bold' flex='50px' style={{ textAlign: 'right' }}>
        {periodo}
      </Col>
      <Col xs={12} md={8} lg={5} xxl={4} className='byp-title byp-fw-bold' style={{ textAlign: 'left' }}>
        &nbsp {tiempo_periodo}
      </Col>
      <Col className='byp-mr-1' xs={12} md={8} lg={5} xxl={4}>
        {precio}
      </Col>
      <Col className='byp-mr-1' xs={12} md={8} lg={5} xxl={4}>
        <small>{tiempo}</small>
      </Col>
    </Row>
  )
}

function RowInfoNoBold ({ label, data }) {
  if (label === 'Divisa:') {
    return (
      <Row className='w-100'>
        <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title '>{label}</p></Col>
        <Col xs={12} md={8} lg={12} style={{ fontWeight: '700' }}>{data}</Col>
      </Row>
    )
  }
  else {
    return (
      <Row className='w-100'>
        <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title '>{label}</p></Col>
        <Col xs={12} md={8} lg={12} style={{ fontWeight: '700' }}>${data}.00</Col>
      </Row>
    )
  }
}

function RowSubInfo ({ label, data }) {
  return (
    <Row className='w-100'>
      <Col xs={12} md={8} lg={5} xxl={4}><p>{label}</p></Col>
      <Col xs={12} md={8} lg={6}><b>{data}</b></Col>
    </Row>
  )
}

function QuoteInfo ({ course, divisa, activeKeys, setActiveKeys, onClick, category }) {
  const [discountId, setDiscountId] = useState()

  const onChange = (values) => {
    setActiveKeys(values)
  }

  useEffect(() => {
    course?.discounts?.some((item) => {
      setDiscountId(item.id)
      return !!item.id
    })
  }, [course])

  return (
    <Collapse
      defaultActiveKey={activeKeys}
      expandIconPosition='left'
      onChange={onChange}
      activeKey={activeKeys}
    >
      <Panel header='Discounts and promotions' key='discounts'>
        {course?.discounts?.map((discount) => (
          <React.Fragment key={discount.id}>
            <h6 className='byp-title' style={{ textTransform: 'uppercase' }}>{discount.name}</h6>
            <Row>
              <Col xs={12} md={8} lg={4}><b>Promotions start on</b></Col>
              <Col xs={12} md={8} lg={4}>{discount.start}</Col>
              <Col xs={12} md={8} lg={4}><b>Limit date for aplication</b></Col>
              <Col xs={12} md={8} lg={4}>{discount.end}</Col>
              <Col xs={12} md={8} lg={4}><b>Course needs to start on</b></Col>
              <Col xs={12} md={8} lg={4}>{discount.service_start} - {discount.service_start_limit}</Col>
            </Row>
          </React.Fragment>
        ))}
        {panelFooter(
          onClick,
          URL_CUSTOMDISCOUNT_PAGE.replace(':program', category),
          {
            menuItemSelected: category + '_discounts_prices',
            openSub: category,
            openSub2: category + '_discounts',
            // year: '2022',
            discountId
          }
        )}
      </Panel>
      <Panel header='Course prices' key='course_prices'>
        <RowInfo label='Course name' data={course?.name} />
        <RowInfo label='Course type' data={course?.type} />
        <RowInfo label='Description' data={course?.description} />
        {
          !Array.isArray(course?.language) && (
            <RowInfo label='Course language' data={course?.language} />
          )
        }
        <RowInfo label='Minimum age' data={course?.min_age + ' years'} />
        <RowInfo label='Length' data={(course?.length ?? '') + ' ' + (course?.time ?? '')} />
        <RowInfo label='Program' data={course?.program.label} />
        {Array.isArray(course.intake_date) && (
          <RowInfo
            label='Program intake dates'
            data={
              course.intake_date.map((intake) => (
                <p key={'course_intake_' + intake}>{formatStringDate(intake, 'MMMM/yyyy')}</p>
              ))
            }
          />
        )}
        {/* <RowInfo label='Programa intake dates' data={course?.intake_date} /> */}
        {/* <RowInfo label='Student per class' data={(course?.max_capacity && course?.min_capacity) ? course?.min_capacity + ' a ' + course?.max_capacity : ''} /> */}
        {/* <RowInfo label='Class schedule' data={course?.hourly} /> */}
        <RowInfo label='Specific start day' data={getDayLabel(course?.start_day)} />
        <RowInfo label='Course Link' data={course?.link} />
        {/* <RowInfo
          label='Number of lessons'
          data={(course?.lessons_duration && course?.lessons && course?.lessons_frequency) ? course?.lessons + ' por ' + frequencyLabel(course?.lessons_frequency) + ', ' + course?.lessons_duration + ' minutos por lección' : ''}
        /> */}

        <RowInfo label='Modality' data={course?.modality} />
        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Prices</h6>
        <RowInforCurrentShort label='Currency:' data={divisa}></RowInforCurrentShort>

        <PricesColumns programa={course?.program.label} course={course}></PricesColumns>

        <hr className='byp-hr' />

        <h6 className='byp-title'>Extra fee for under age student</h6>
        {course?.minor_costs?.map((item) => (

          costItem(item, divisa)
        ))}

        {panelFooter(onClick, URL_COURSEPRICES_CREATE_PAGE.replace(':program', category), { menuItemSelected: category + '_courses_prices', openSub: category, openSub2: category + '_courses',course_name: course?.name,type:course?.progra_type,subtype:course?.progra_subtype})}
      </Panel>
      <Panel header='General prices' key='base_prices'>
        <RowInfo
          label='Nationalities:'
          data={(
            course?.countries?.map((country) => (
              <Tag key={country.id} className='byp-tag'>{country.name}</Tag>
            ))
          )}
        />
        <RowInfo label='Student location:' data={course?.student_location} azul='Si' />



        <hr className='byp-hr' />

        <h6 className='byp-title' style={{ fontWeight: '700' }}>General prices</h6>
        {course?.base_prices?.map((item) => (
          costItem(item, divisa)
        ))}

        <Row>
          <Col span={1}>
            Divisa:
          </Col>
          <Col>
            <b>{divisa}</b>
          </Col>
        </Row>

        <hr className='byp-hr' />

        <h6 className='byp-title' style={{ fontWeight: '700' }}>Material pack name</h6>
        {course?.materials?.map((item) => (
          costRangeItem(item, divisa)
        ))}
        <hr className='byp-hr' />

        <h6 className='byp-title' style={{ fontWeight: '700' }}>Peak season fees</h6>
        {course?.high_seasons?.map((item) => (
          <Row key={item.id}>
            <Col xs={6} md={5} lg={4}><b>Starts:</b></Col>
            <Col xs={6} md={5} lg={4}>{convertidorFechas(item.hs_start)}</Col>
            <Col xs={6} md={5} lg={4}><b>Ends:</b></Col>
            <Col xs={6} md={5} lg={4}>{convertidorFechas(item.hs_end)}</Col>
            <Col span={24}>
              {item.fees?.map((fee) => (
                costItem(fee)
              ))}
            </Col>
          </Row>
        ))}
        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Insurance fee</h6>
        {course?.insurances?.map((item) => (
          costItemNombreNegritas(item)
        ))}
        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Extra fees</h6>
        {course?.extras?.map((item) => (
          costItemNombreNegritasEspecial(item, divisa)
        ))}
        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Additional concepts</h6>
        {course?.includes?.map((item) => (
          <p key={item.id}> {item.name} </p>
        ))}
        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Holidays</h6>
        {course?.holidays?.map((item) => (
          <p key={item.id}> {item.date_formated ?? item.date} </p>
        ))}
        <hr className='byp-hr' />

        <h6 className='byp-title' style={{ fontWeight: '700' }}>Additional comments</h6>
        <p>{course?.comments}</p>
        {panelFooter(onClick, URL_UPLOAD_PRICES_PAGE.replace(':program', category), { menuItemSelected: category + '_general', openSub: category })}
      </Panel>
      {category !== 'boarding_schools' && course?.lodgings?.map((item) => (
        <Panel key={item.id} header={'Accomodation prices: ' + item.name}>
          <RowInfo label='Accommodation type' data={item.type} />
          <RowInfo label='Start day' data={item.arrival_day} />
          <RowInfo label='End day' data={item.departure_day} />
          <RowInfo label='Room' data={item.name} />
          <RowInfo label='Travel time' data={item.distance_to_school} />
          <RowInfo label='Bathroom' data={item.bathroom} />
          {/* <RowInfo label='Minimo de alumnos:' data={item.min_capacity} /> */}

          <hr className='byp-hr' />

          <h6 className='byp-title' style={{ fontWeight: '700' }}>Prices</h6>
          <RowInfoNoBold label='Divisa:' data={divisa} />
          <RowInfoNoBold label='Accomodation search' data={item.accomodation_fee ? item.accomodation_fee : ''} />
          <RowInfoNoBold label='Cost per week' data={item.cost ? item.cost : ''} />
          <RowInfoNoBold label='Extra night fee' data={item.extra_cost ? item.extra_cost : ''} />
          <hr className='byp-hr' />

          <h6 className='byp-title' style={{ fontWeight: '700' }}>Extra fees</h6>
          {item?.extra_costs?.map((item) => (
            costItem(item, divisa)
          ))}
          <hr className='byp-hr' />

          <h6 className='byp-title' style={{ fontWeight: '700' }}>Additional fees for peak season</h6>
          {item?.high_seasons?.map((item) => (
            <div>
              <Row key={item.id} className='w-100'>
                <Col xs={6} md={5} lg={4}><b>Start:</b></Col>
                <Col xs={6} md={5} lg={4}>{convertidorFechas(item.hs_start)}</Col>
                <Col xs={6} md={5} lg={4}><b>End:</b></Col>
                <Col xs={6} md={5} lg={4}>{convertidorFechas(item.hs_end)}</Col>
                {console.log('esto es item')}
                {console.log(course)}
                <Col xs={6} md={5} lg={4}>{course.lodgings[0].mandatory==true?'Mandatory':null}</Col>
              </Row>
              <Row>
                <Col span={6}>
                  Weekly fee:
                </Col>
                <Col span={6}>
                  <b>${item.hs_cost}.00</b>
                </Col>
                <Col span={12}>
                  Charging for: <b>${item.hs_extra_cost}.00</b>
                </Col>
              </Row>
            </div>
          ))}
          <hr className='byp-hr' />

          {/* <h6 className='byp-title'>Costos extra por menor de edad</h6>
          {item?.minor_costs?.map((item) => (
            costItem(item, divisa)
          ))} */}
          {panelFooter(
            onClick, URL_LODGINGPRICES_CREATE_PAGE.replace(':program', category),
            {
              menuItemSelected: category + '_lodgings_prices', openSub: category, openSub2: category + '_lodgings'
            }
          )}
        </Panel>
      ))}
      {category !== 'boarding_schools' && course?.transports?.map((item) => (
        <Panel key={item.id} header={'Transport prices: ' + item.name}>
          <RowInfo label='Transport type' data={item.type} />
          <RowInfo label='Name:' data={item.name} />
          <hr className='byp-hr' />


          <h6 className='byp-title byp-fw-bold' >Prices</h6>
          <RowSubInfo label='Divisa:' data='' />
          <RowSubInfo label='One-Way' data={'$' + `${item.one_way_cost}` + '.00'} />
          <RowSubInfo label='Round-Trip' data={'$' + `${item.two_ways_cost}` + '.00'} />
          <hr className='byp-hr' />

          <h6 className='byp-title' style={{ fontWeight: '700' }}>Additional fees for peak season</h6>
          {item?.high_seasons?.map((item) => (
            <Row key={item.id} className='w-100'>
              <Col xs={6} md={5} lg={4}><b>Starts:</b></Col>
              <Col xs={6} md={5} lg={4}>{convertidorFechas(item.hs_start)}</Col>
              <Col xs={6} md={5} lg={4}><b>Ends:</b></Col>
              <Col xs={6} md={5} lg={4}>{convertidorFechas(item.hs_end)}</Col>
              <Col span={24}>
                Extra fee: {item.hs_cost}
              </Col>
            </Row>
          ))}
          <hr className='byp-hr' />

          <h6 className='byp-title' style={{ fontWeight: '700' }}>Extra fee for under age student</h6>
          {item?.minor_costs?.map((item) => (
            costItem(item, divisa)
          ))}
          {panelFooter(
            onClick, URL_TRANSPORTGPRICES_CREATE_PAGE.replace(':program', category),
            {
              menuItemSelected: category + '_transports_prices', openSub: category, openSub2: category + '_transports'
            }
          )}
        </Panel>
      ))}
    </Collapse>
  )
}

export { QuoteInfo }
