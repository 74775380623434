import { useQuery } from 'react-query'
import FeeRepository from '../../repositories/FeeRepository'

function useGetAgencyFeesStatus (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetAgencyFeesStatus', filtersQuery],
    () => FeeRepository.feesStatus(filtersQuery),
    { retry: 2 }
  )
}

export { useGetAgencyFeesStatus }
