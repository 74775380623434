import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { SelectAsyncNationalitiesFormItem } from './SelectAsyncNationalitiesFormItem'
import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'
import { SelectCityByIsoFormItem } from './SelectCityByIsoFormItem'
import FloatLabel from './FloatLabel'

function SelectCountryAndCityFormItem ({ placeholderCountry, placeholderCity, restField, name, office, span = 12, className = '', id_country: country = '', countryAgency }) {
  const { isLoading, data: cities } = useGetAllNationalities()
  const [iso3, setIso3] = useState()

  async function findIso (id) {
    console.log('🚀 ~ file: SelectCountryAndCityFormItem.js:13 ~ findIso ~ id:', id)
    const found = cities?.find((city) => {
      return city.id === id
    })
    if (found) {
      return setIso3(found?.iso3)
    }
  }

  useEffect(() => {
    if (country.school?.country) findIso(country.school?.country)
  }, [country])

  useEffect(() => {
    if (countryAgency) findIso(countryAgency)
  }, [countryAgency, cities])

  if (placeholderCountry && placeholderCity) {
    return (
      <div style={{ minWidth: '100%' }}>
        <Row gutter={16}>
          <Col span={span} className={className}>
            <SelectAsyncNationalitiesFormItem
              {...restField}
              name={[name, 'country']}
              onChange={findIso}
              showSearch
              rules={[{ required: true, message: 'This field is required' }]}
              placeholder={placeholderCountry}
            />
          </Col>

          <Col span={span} className={className}>
            <SelectCityByIsoFormItem
              {...restField}
              name={[name, 'city']}
              loading={isLoading}
              showSearch
              rules={[{ required: true, message: 'This field is required' }]}
              iso={iso3}
              placeholder={placeholderCity}
            />
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div style={{ minWidth: '100%' }}>
      <Row gutter={16}>
        <Col span={span} className={className}>
          <FloatLabel label='Country' value={office?.country}>
            <SelectAsyncNationalitiesFormItem
              {...restField}
              name={[name, 'country']}
              onChange={findIso}
              showSearch
              rules={[{ required: true, message: 'This field is required' }]}
              placeholder={placeholderCountry}
            />
          </FloatLabel>
        </Col>

        <Col span={span} className={className}>
          <FloatLabel label='City' value={office?.city}>
            <SelectCityByIsoFormItem
              {...restField}
              name={[name, 'city']}
              loading={isLoading}
              showSearch
              rules={[{ required: true, message: 'This field is required' }]}
              iso={iso3}
              placeholderCity
            />
          </FloatLabel>
        </Col>
      </Row>
    </div>
  )
}

export { SelectCountryAndCityFormItem }
