import { Link } from 'react-router-dom'
import { API_URL } from '../../../../config'
import { URL_INSTITUTION_SCHOOLS_PAGE } from '../Schools/CreateSchoolsPage'
import { UploadSchoolMediaForm } from '../Schools/UploadSchoolMediaForm'
import { MenuSavePicture } from '../../home-slider/components/MenuSavePictures'
import { Form, Input } from 'antd'

function WebQuoteFieldsContainer ({
  form, setGallery, gallery, numberPage, activeTemplate,
  id, setId, url, setUrl, formRef, isLoadingVimeo, sedeId,
  videoId, setThereIsVideo
}) {
  return (
    <div className={numberPage !== activeTemplate ? 'opacity' : ''}>
      {numberPage === 1 && (
        <MenuSavePicture
          setGallery={setGallery}
          gallery={gallery}
          form={form}
          name='sliders'
          setId={setId}
          id={id}
          setUrl={setUrl}
          url={url}
        />
      )}
      {
        numberPage === 2 && (
          <>
            <h6 className='byp-title byp-fw-bold byp-sbtl-mkt-mtl'>
              Header pictures
              <small className='byp-sbtl-mkt-mtl-small'>
                (Arrange the images in the order you want them to be displayed)
              </small>
            </h6>

            <UploadSchoolMediaForm
              form={form} name='header' setGallery={setGallery}
              action={`${API_URL}/campus/upload/files`}
              enable={numberPage === activeTemplate}
              maxCount={4}
              gallery={gallery}
            />

            <h6 className='byp-title byp-fw-bold byp-mt-1 byp-sbtl-mkt-mtl'>Video</h6>
            <Form.Item
              name='video_link'
              rules={[
                {
                  required: true,
                  message: 'This field is required'
                },
                {
                  validator: async (_, value) => {
                    if (!value || value.length < 20 || !value.includes('https://vimeo.com/')) {
                      return Promise.reject(new Error('Please, insert a valid Vimeo link'))
                    }
                  }
                }
              ]}
            >
              <Input placeholder='https://vimeo.com/...' />
            </Form.Item>

            <h6 className='byp-title byp-fw-bold byp-mt-1 byp-sbtl-mkt-mtl'>School pictures</h6>
            <p className='byp-txt-mkt-mtl-small'>
              Remember that you've uploaded these pictures when uploading your school, if you want to edit them, please click
              <Link to={URL_INSTITUTION_SCHOOLS_PAGE} state={{ menuItemSelected: 4, openSub: 'escuelas' }}> here</Link>
            </p>

          </>
        )
      }
    </div>
  )
}

export { WebQuoteFieldsContainer }
