import { userRoles } from "../../../bussines/settings/validate-user-access"
import { PageProtected } from "../../../common/components/PageProtected"
import { MainTheme } from "../../../common/theme/MainTheme"
import { TopBarMenuCustom } from "../agency/components/TopBarMenuCustom"
import { FormUploadPromotions } from "./FormUploadPromotions"


const URL_PACKAGES_PROMOTIONS_PAGE = '/dashboard/packages/upload-promotions'


function UploadPromotionsPage(){
return(
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_SUPER_ADMIN,
        userRoles.TYPE_SUPER_EDITOR,
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_MANAGER,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
        userRoles.TYPE_AGENCY_EDITOR,
        userRoles.TYPE_INSTITUTION,
        userRoles.TYPE_EG_ADMIN
      ]}
    >
      <MainTheme
        showMenu
      >
        <TopBarMenuCustom customizable='packages' />
        <FormUploadPromotions/>
      </MainTheme>
    </PageProtected>
)
}

export {UploadPromotionsPage,URL_PACKAGES_PROMOTIONS_PAGE}