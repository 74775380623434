import { Form, Input, Typography } from 'antd'
import React from 'react'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'

const { Title } = Typography

function AgencyPaymentsFieldContainer ({ form, readOnly }) {
  const arrayBankData = Form.useWatch('bank_data', form) || []
  // const arrayPayments = Form.useWatch('payment_methods', form) || []
  return (
    <>
      <Form.List name='bank_data'>
        {(fields, { add, remove }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>

                <Title level={5}>bank details</Title>
                <FloatLabel
                  label='Name of the holder'
                  value={arrayBankData[name]?.name}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                  >
                    <Input readOnly={readOnly} />
                  </Form.Item>
                </FloatLabel>

                <FloatLabel
                  label='Bank'
                  value={arrayBankData[name]?.bank}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'bank']}
                  >
                    <Input readOnly={readOnly} />
                  </Form.Item>
                </FloatLabel>

                <FloatLabel
                  label='Key Account Number'
                  value={arrayBankData[name]?.account}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'account']}
                  >
                    <Input readOnly={readOnly} />
                  </Form.Item>
                </FloatLabel>

                <FloatLabel
                  label='Checking account'
                  value={arrayBankData[name]?.checking}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'checking']}
                  >
                    <Input readOnly={readOnly} />
                  </Form.Item>
                </FloatLabel>

              </div>
            ))}
          </div>
        )}
      </Form.List>
    </>
  )
}

export { AgencyPaymentsFieldContainer }
