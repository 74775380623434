import { Button, Form, Modal, notification } from 'antd'
import { useEffect, useState } from 'react'
import { usePostResetPassword } from '../../../../bussines/hooks/admin/usePostResetPassword'
import { PasswordFormItem } from '../../../../common/components/helper-form/PasswordFormItem'
import { showErrorModal } from '../../../../utils/propsForModal'

const ResetPasswordModal = ({ email, label, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modal, context] = Modal.useModal()
  const [form] = Form.useForm()
  const { resetPassword, isLoading, error, currentState, stateControl, data } = usePostResetPassword()

  const showModal = () => {
    if (email === undefined) return
    setIsModalVisible(true)
  }

  const handleOk = () => {
    const values = form.getFieldsValue()
    const { password, confirm: confirmation } = values
    setIsModalVisible(false)
    resetPassword({ email, password, confirmation })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      notification.success({ message: 'Password has been reseted' })
      console.log(data)
    } else if (!isLoading && currentState === stateControl.STATE_ERROR) {
      showErrorModal(modal, error?.response?.data?.message)
    }
  }, [isLoading, currentState, error])

  return (
    <>
      {context}
      <Button
        loading={isLoading}
        onClick={showModal}
        {...props}
      >{label}
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        okText='Confirm'
        onCancel={handleCancel}
        className='byp-confirm-modal'
        centered
        width={400}
      >
        <Form form={form}>
          <h5 className='byp-title'>Reset password</h5>
          <p className='byp-title'>Insert the new password</p>
          <PasswordFormItem />
        </Form>
      </Modal>
    </>
  )
}

export default ResetPasswordModal
