import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PrefixLabelSelector } from './PrefixLabelFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'

const { Option } = Select

function ConceptCostListFormItem({ name, divisa }) {
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />
  return (
    <div className='w-100'>
      <Form.List
        name={name}
        initialValue={[
          {}
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={8}>
                <Col span={8}>
                  <Form.Item
                    name={[name, 'name']}
                  >
                    <Input
                      className=''
                      placeholder='Concept'
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Col>
                    <Form.Item
                      name={[name, 'cost']}
                    >
                      <InputNumber placeholder='00.00' addonBefore={prefixSelector} min={0} controls={false} />
                    </Form.Item>
                  </Col>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={[name, 'frequency']}
                  >
                    <Select
                      className='w-100'
                      placeholder='Select how many times'
                      size='small'
                    >
                      <Option key='unic'>Only time</Option>
                      <Option key='weeks'>Weekly</Option>
                      <Option key='monthly'>Monthly</Option>
                      <Option key='every 4 months'>Every 4 months</Option>
                      <Option key='semester'>Semester</Option>
                      <Option key='anual'>Anual</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Row>
            ))}
            {
              fields.length === 0 && (
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right svg-boton-blue-fill' onClick={() => add()}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" width="10" height="10" stroke-width='2' stroke='var(--blue-100)'>
                        <path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                        ></path>
                      </svg>
                      Add
                    </div>
                  </Button>
                </Form.Item>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { ConceptCostListFormItem }
