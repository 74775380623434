import { Col, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Doughnut, getElementAtEvent } from 'react-chartjs-2'
import { UpIcon } from '../../../quote-preview/assets/imgs/Icons'

export const ChartDoughnut = ({ data, setTypeCompany, isTrends = false }) => {
  const [doughnuData, setDoughnuData] = useState()
  const [summary, setSummary] = useState()

  function getColor(program) {
    switch (program) {
      case 'Agency':
        return '#2460B7'
      case 'Educational groups':
        return '#6495ED'
      case 'Institution':
        return '#FFCC00'
      case 'Services providers':
        return '#AC85DE'
      case 'Others':
        return '#AC85DE'
    }
  }

  useEffect(() => {
    if (data) {
      let sum = 0
      const labels = data?.map((itemData) => itemData.enterprise_type)
      const dataSet = data?.map((itemData) => {
        sum += itemData.quotes
        return itemData.quotes
      })
      setSummary(sum)
      setDoughnuData({
        labels,
        datasets: [{
          data: dataSet,
          backgroundColor: data.map((object) => getColor(object.enterprise_type)),
          borderWidth: 0
        }]
      })
    }
  }, [data])

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: 'true',
          padding: 30,
          font: {
            family: 'Nunito'
          }
        }
      }
    },
    cutout: 67
  }

  const handleOnClick = (element) => {
    if (!element.length) return
    const { index } = element[0]
    setTypeCompany(doughnuData.labels[index])
  }

  const chartRef = useRef(null)

  return (
    <Row>
      <div className='chart-doughnut__container'>
        <Col span={24} className='chart-doughnut__header'>
          <h4 style={{ paddingBottom: '10px' }}>Total Quotes </h4>
          <div className='d-flex' style={{ justifyContent: 'space-between' }}>
            <p>{summary}</p>
            {isTrends && <div className='summarized-results__up'><UpIcon /> 50.1%</div>}
          </div>
        </Col>
        <Col span={24} className='chart-doughnut__chart' style={{ margin: '20px 0' }}>
          {doughnuData && (
            <Doughnut
              data={doughnuData}
              ref={chartRef}
              options={options}
              onClick={(event) => {
                const { current: chart } = chartRef
                if (!chart) {
                  console.log('NO charts')
                  return
                }
                handleOnClick(getElementAtEvent(chart, event))
              }}
            />
          )}
        </Col>
        {/* <Col span={24} className='chart-doughnut__types'>
          <div>
            <div className='chart-doughnut__type-color' style={{ background: '#FFCC00' }} />
            <p className='chart-doughnut__type-txt'>Institutions</p>
          </div>
        </Col> */}
      </div>
    </Row>
  )
}
