import { Button, Table } from 'antd'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { InsuranceTableFilter } from '../Tables/Filters/InsuranceTableFilter'
import { useEffect, useState } from 'react'
import LottieCargando from '../../../../lotties/LottieCargando'


const { Column } = Table

const TableInsurance = ({ insurances, setId, isLoading, deleteFee, canDelete }) => {

  const [insurance, setInsurances] = useState(insurances)

  useEffect(() => {
    if (insurances) {
      setInsurances(insurances)
    }
  }, [insurances])

  if(isLoading){
    return <LottieCargando></LottieCargando>
  }

  return (
    <>
    <InsuranceTableFilter  insurances={insurances} setInsurances={setInsurances} />
      <Table
        loading={isLoading}
        dataSource={insurance ?? []}
        className='byp-table-dashboard'
        title={() => 'Added medical insurance'}
        rowKey='id'
      >
        <Column
          title='Name' dataIndex='name' key='name'
          render={(_, record) => (
            <a>{record.name}</a>
          )}
        />
        <Column
          title='Program' dataIndex='program' key='program'
        />
        <Column
          title='Office'
          dataIndex='offices'
          key='offices'
          responsive={['lg']}
          render={(_, record) => (
            record.offices?.map((item) => (
              <li key={item.id}>{item.name}</li>
            ))
          )}
        />
        <Column
          title='Actions'
          key='action'
          render={(_, record) => (
            <>
              <ConfirmModal onOk={deleteFee} isLoading={isLoading} id={record.id} hasPermision={canDelete} />
              <Button
                className='byp-btn-edit'
                // loading={isLoading}
                size='small'
                onClick={
                  () => {
                    setId(record.id)
                  }
                }
                icon={<EditIcon />}
              />
            </>
          )}
        />
      </Table>
    </>
  )
}

export { TableInsurance }
