import { useQuery } from 'react-query'
import LogRepository from '../../repositories/LogRepository'

function useGetLogs (filters = {}) {
  return useQuery(['useGetLogs', filters],
    () => LogRepository.logs(filters), {
      retry: 1
    })
}

export { useGetLogs }
