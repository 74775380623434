import { Button, Row, Col } from 'antd'
import { AgencyInfo } from './AgencyInfo'
import TimeFilters from '../../TimeFilters'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { decrypt } from '../../../../../../bussines/utils/basicEncript'
import { useFindUserByAgency } from '../../../../../../bussines/hooks/user/useFindUserByAgency'
// import { useGetAgencies } from '../../../../../../bussines/hooks/agency/useGetAgencies'

function OneAgencyInfo () {
  const { agencyId: cryptAgencyId } = useParams()
  const [agencyId, setAgencyId] = useState()
  const [time, setTime] = useState({})

  const { data: userData, refetch } = useFindUserByAgency(agencyId)

  useEffect(() => {
    if (cryptAgencyId) {
      setAgencyId(decrypt('qyp2023', cryptAgencyId))
    }
  }, [cryptAgencyId])

  useEffect(() => {
    const handleNavigation = () => {
      if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
        refetch()
      }
    }

    window.addEventListener('popstate', handleNavigation)

    return () => {
      window.removeEventListener('popstate', handleNavigation)
    }
  }, [])

  const goback = () => {
    window.history.back()
  }

  const handleRefetch = () => {
    refetch()
  }

  return (
    <div className='byp-dashboard-content'>
      <Row className='d-flex align-center'>
        <h3 className='byp-title'>Agency analysis</h3>
        <span className='byp-title byp-text-lg' style={{ marginLeft: '0.5rem' }}>({userData?.name})</span>
      </Row>
      <Row>
        <Col span={16}>
          <Button className='byp-bg-blue-200 byp-color-white' style={{ marginTop: '20px' }} onClick={goback}>
            Go back
          </Button>
        </Col>

        <Col span={8}>
          <TimeFilters setTime={setTime} />
        </Col>
      </Row>
      <AgencyInfo agencyId={agencyId} userData={userData} time={time} handleRefetch={handleRefetch} />

    </div>
  )
}

export { OneAgencyInfo }
