// import { Button } from 'antd'
import logo from './../../common/assets/images/logo.png'

const PageNotAvailableInBetaVersion = () => {
  return (
    <div className='byp-dashboard-content byp-align-centered'>
      <img src={logo} className='dashboard-main-logo byp-mt-1' />
      <h4 className='byp-title'>This feature is not available in beta version</h4>
      <p>But dont't worry, we are working to make these features could become available in the future.</p>
      {/* <p><b>Your participation and feedback will help us make the app better.</b></p> */}
      {/* <p><b>To submit feedback:</b></p> */}
      {/* <Button
        type='primary'
      >
        <Link to={URL_INSTITUTION_SCHOOLS_PAGE} state={{ menuItemSelected: 4, openSub: 'escuelas' }}>Let's get start to upload your prices</Link>
        Send feedback
      </Button> */}
    </div>
  )
}

export { PageNotAvailableInBetaVersion }
