import api from '../../services/api'

class PartnersRepository {
  async index (params = {}) {
    const response = await api.get('/partners', { params })
    return response.data
  }
}

export default new PartnersRepository()
