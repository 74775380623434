import { Button, Col, Form, Input, Row, Typography, notification } from 'antd'
import { BasicFormListItem } from '../../../../../common/components/BasicFormListItem'
import { useEffect, useState } from 'react'
import stateControl from '../../../../../bussines/utils/state-control'
import { SelectTagsFormItem } from './SelectTagsFormItem'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { useGetAllCampus } from '../../../../../bussines/hooks/school/useGetAllCampus'
import { useGetCampus } from '../../../../../bussines/hooks/school/useGetCampus'
import { useSaveCampusExtraInfo } from '../../../../../bussines/hooks/school/useSaveCampusExtraInfo'
import { SchoolsTable } from '../../Schools/SchoolsTable'
import { SelectCampus } from '../../../agency/components/SelectCampusAll'
import { showErrorModal } from '../../../../../utils/errorModal'
import { SelectAvailableTranslationsFormItem } from '../../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

const { Title } = Typography
const { TextArea } = Input

const FormCampusExtraInfo = ({ program, type }) => {
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState({})
  const [campusID, setCampusID] = useState()
  const { isLoading, data: allCampus, refetch } = useGetAllCampus(program && { program, page, ...searchFilter })
  const { data: campus, isLoading: isLoadingFee } = useGetCampus(campusID)
  const { save, isLoading: isSaving, currentState } = useSaveCampusExtraInfo()

  const handleFinish = (values) => {
    console.log('🚀 ~ file: FormCampusExtraInfo.js:8 ~ handleFinish ~ values:', values)
    save(campusID, values)
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const tags = campus.tags ?? []
      const skills = tags.filter((tag) => tag.type === 'skill').map(skill => {
        const translationFound = skill.translationables?.find(translation => translation.to === lang)
        return {
          ...skill,
          name: translationFound?.translation ?? skill.name
        }
      })
      const included = tags.filter((tag) => tag.type === 'included').map(skill => {
        const translationFound = skill.translationables?.find(translation => translation.to === lang)
        return {
          ...skill,
          name: translationFound?.translation ?? skill.name
        }
      })
      const placesOfInterest = tags.filter((tag) => tag.type === 'places_of_interest').map(skill => {
        const translationFound = skill.translationables?.find(translation => translation.to === lang)
        return {
          ...skill,
          name: translationFound?.translation ?? skill.name
        }
      })
      const requirements = campus.requirements?.map(requirement => {
        const translationFound = requirement.translationables?.find(translation => translation.to === lang)
        return {
          ...requirement,
          value: translationFound?.translation ?? requirement.value
        }
      })
      const targets = campus.targets?.map(target => {
        const translationFound = target.translationables?.find(translation => translation.to === lang)
        return {
          ...target,
          value: translationFound?.translation ?? target.value
        }
      })
      const description = campus.description_extra?.translationables?.find(translation => translation.to === lang)
      // const description = campus.extra_description
      form.setFieldsValue({
        requirements: requirements?.length > 0 ? requirements : [{ value: '' }],
        targets: targets?.length > 0 ? targets : [{ value: '' }],
        skills: skills.map((skill) => skill.name),
        included: included.map((skill) => skill.name),
        places_of_interest: placesOfInterest.map((skill) => skill.name),
        description: description?.translation,
        translation: lang
      })
    } else {
      setFormData()
    }
  }

  const setFormData = () => {
    const tags = campus.tags ?? []
    const skills = tags.filter((tag) => tag.type === 'skill')
    const included = tags.filter((tag) => tag.type === 'included')
    const placesOfInterest = tags.filter((tag) => tag.type === 'places_of_interest')
    const requirements = campus.requirements
    const targets = campus.targets
    const description = campus.description_extra?.value
    form.setFieldsValue({
      requirements: requirements?.length > 0 ? requirements : [{ value: '' }],
      targets: targets?.length > 0 ? targets : [{ value: '' }],
      skills: skills.map((skill) => skill.name),
      included: included.map((skill) => skill.name),
      places_of_interest: placesOfInterest.map((place) => place.name),
      description,
      translation: null
    })
  }

  useEffect(() => {
    if (!isLoadingFee && campus) {
      setFormData()
    }
  }, [isLoadingFee, campus])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      form.resetFields()
      setCampusID()
      notification.success({
        message: 'Data saved succesfully'
      })
    } else if (!isSaving && currentState === stateControl.STATE_ERROR) {
      showErrorModal()
    }
  }, [isSaving, currentState])

  return (
    <Row
      gutter={[16, 16]}
    >
      <Col xs={24} md={12} lg={14}>
        <Form
          form={form}
          onFinish={handleFinish}
          requiredMark={false}
          style={{ marginTop: '1rem' }}
          className='extra-info-form'
        >
          <div className='w-100 byp-mb-1'>
            <Title level={4} className='byp-color-blue-200 byp-fw-bold'>Extra Info</Title>
            <SelectCampus
              onChange={(val) => {
                setCampusID(val)
              }}
              selectProps={{
                className: 'byp-m-0 select-campus',
                size: 'small'
              }}
            />
          </div>
          {isLoadingFee && <BasicSpinner />}
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '.5rem' }}>
            <SelectAvailableTranslationsFormItem
              name='translation'
              formItemProps={{
                noStyle: true
              }}
              selectProps={{
                size: 'small',
                style: { minWidth: '8rem' },
                onChange: (lang) => handleChangeTranslateLanguage(lang),
                allowClear: true
              }}
            />
          </div>
          {!!campus && !!campusID && (
            <>
              <Form.Item name='description' label='Description'>
                <TextArea rows={6} maxLength={600} showCount />
              </Form.Item>
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Amenities</Title>
              <SelectTagsFormItem name='included' type='included' />
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Lugares de interés</Title>
              <SelectTagsFormItem name='places_of_interest' type='places_of_interest' />
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Requisitos</Title>
              <BasicFormListItem name='requirements' />
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                htmlType='submit'
                loading={isSaving}
              >
                {/* {isUpdateMode ? 'Update' : 'Save campus'} */}
                Save
              </Button>
            </>
          )}
        </Form>
      </Col>
      <Col xs={24} md={12} lg={10}>
        <SchoolsTable
          data={allCampus ?? []}
          program={program}
          isLoading={isLoading}
          refetch={refetch}
          setPage={setPage}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          setCampusId={setCampusID}
          showDelete={false}
          showStatus={false}
          type={type}
        />
      </Col>
    </Row>
  )
}

export { FormCampusExtraInfo }
