import { Col, DatePicker, Form, InputNumber, Row, Select } from 'antd'
import moment from 'moment'
import { format, subDays } from 'date-fns'

import 'moment/locale/es-mx'
// import locale from 'antd/es/date-picker/locale/es_ES'
import { usePackage } from '../../../providers/package/package-course-context'
import { useEffect, useState } from 'react'

const { Option } = Select

function SelectStartAndDuration () {
  const { service, formConfig, setFormConfig, form, course } = usePackage()

  // const disabledDate = (current) => {
  //   // Can not select days before today, today and mondays only
  //   let filteredDates
  //   const day = moment(current).isoWeekday()
  //   const datString = format(moment(current).toDate(), 'Y-LL-dd')
  //   const isHoliday = service.isHoliday(datString)
  //   let disabled
  //   disabled = day > service.getDayStartCourse() || day < service.getDayStartCourse()
  //   if (!disabled && isHoliday) {
  //     const nextDay = service.enableNextDay(datString)
  //     addEnabledDate(nextDay)
  //   }
  //   disabled = (current && disabled) || current < moment().endOf('day')

  //   const program = course?.offer?.program?.name
  //   const startDates = course?.offer?.start_dates
  //   if (startDates && (program === 'language' || program === 'summer_camps') && startDates?.length > 0) {
  //     const today = new Date()
  //     filteredDates = startDates?.filter(d => new Date(d.date) >= today)
  //   }

  //   if (filteredDates && (program === 'language' || program === 'summer_camps') && filteredDates?.length > 0) {
  //     const specificDates = filteredDates?.map(dateObj => dateObj?.date)
  //     const isSpecificDate = specificDates?.some(date => moment(date).isSame(current, 'day'))
  //     if (isSpecificDate) {
  //       return false
  //     }
  //     if (day === 1) {
  //       disabled = true
  //     }
  //   }
  //   return (disabled || isHoliday) && !service.isEnabledDate(datString)
  // }
  const disabledDate = (current) => {
    // Can not select days before today, today and mondays only
    let filteredDates
    const day = moment(current).isoWeekday()
    const datString = format(moment(current).toDate(), 'Y-LL-dd')
    const isHoliday = service.isHoliday(datString)
    let disabled
    disabled = day > service.getDayStartCourse() || day < service.getDayStartCourse()
    if (!disabled && isHoliday) {
      const nextDay = service.enableNextDay(datString)
      addEnabledDate(nextDay)
    }
    disabled = (current && disabled) || current < moment().endOf('day')

    // const program = course?.offer?.program?.name
    const startDates = course?.offer?.start_dates
    if (startDates && startDates?.length > 0) {
      const today = new Date()
      filteredDates = startDates?.filter(d => new Date(d.date) >= today)
    } else {
      // Si no hay fechas de inicio, todas las fechas están habilitadas a partir de hoy.
      // const today = moment(course?.offer?.start_at).startOf('day')
      // return current.isBefore(today)

      const startDate = new Date(course?.offer?.start_at)
      const endDate = new Date(course?.offer?.end_at)
      endDate.setDate(endDate.getDate() + 1)
      // Si current es una fecha válida y está fuera del rango, deshabilitarla
      if (current && (current.isAfter(startDate) && current.isBefore(endDate))) {
        return false
      }
    }

    if (filteredDates && filteredDates?.length > 0) {
      const specificDates = filteredDates?.map(dateObj => dateObj?.date)
      const isSpecificDate = specificDates?.some(date => moment(date).isSame(current, 'day'))
      if (isSpecificDate) {
        return false
      }
      if (day === 1) {
        disabled = true
      }
    }
    return (disabled || isHoliday) && !service.isEnabledDate(datString)
  }

  const addEnabledDate = (date) => {
    const enabledDates = formConfig?.enabledDates ?? []
    const found = enabledDates.find((d) => d === date)
    if (found) {
      // console.log('La fecha ' + date + '  ya se había agregado')
      return true
    }
    enabledDates.push(date)
    setFormConfig({
      ...formConfig,
      enabledDates
    })
  }

  const onChange = (date) => {
    // TO DO: Check if there is a way of go to the date without select the date
    const lodgingDate = subDays(new Date(date), 2)
    form.setFieldsValue({ lodgingStart: moment(lodgingDate) })
    setFormConfig({
      ...formConfig,
      courseStart: date,
      lodgingStart: moment(lodgingDate)
    })
    service?.searchPrices(date)
    setStartCourse(date)
  }

  const [startCourse, setStartCourse] = useState(null)
  useEffect(() => {
    if (formConfig?.courseStart !== startCourse) {
      const startDates = course?.offer?.start_dates
      if (startDates && startDates?.length > 0) {
        form.setFieldsValue({
          startEvent: moment(startDates[0]?.date)
        })
        setStartCourse(startDates[0]?.date)
        onChange(startDates[0]?.date)
      }
    }
  }, [course, service])

  return (
    <Row gutter={12} className='w-100' style={{ marginTop: '30px' }}>
      <Col xs={8} md={10} className='antd-date' style={{ alignItems: 'baseline' }}>
        <Form.Item
          name='startEvent'
          rules={[{
            required: true,
            message: 'This field is required'
          }]}
          label='Start'
        >
          <DatePicker
            disabledDate={disabledDate}
            onChange={onChange}
            // format='YYYY/MM/DD'
            format='MMMM DD, YYYY'
          />
        </Form.Item>
        <div>
          {
            service.getEndString() &&
              <i>Ends: {service.getEndString()}</i>
          }
        </div>
      </Col>
      <Col xs={8} md={8} className='antd-date'>
        <Form.Item
          name='weeks'
          rules={[{
            required: true,
            message: 'This field is required'
          }]}
          label='Duration'
          style={{ width: '100%' }}
        >
          <Select
            size='small'
            onChange={(nWeeks) => {
              setFormConfig({
                ...formConfig,
                weeks: nWeeks
              })
            }}
          >
            {service.getOptionsWeeks()?.map((week, index) => (
              <Option key={index} value={week.value}>{week.value} week</Option>
            ))}
          </Select>
          {/* <InputNumber
            style={{ width: '100%' }}
            placeholder='1'
            onChange={(nWeeks) => {
              setFormConfig({
                ...formConfig,
                weeks: nWeeks
              })
            }}
            min={service.getMinWeeks()}
            max={service.getMaxWeeks()}
            addonAfter='Weeks'
          /> */}
        </Form.Item>
      </Col>
      <Col className='byp-centered byp-border-left'>
        {service.getIsHighSeason() && <b>High Season</b>}
      </Col>
      <hr className='byp-hr' />
    </Row>
  )
}

export { SelectStartAndDuration }
