import React from 'react'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import { StepBaseTemplate } from './../register/components/StepBaseTemplate'
import { RegisterUserABForm } from './components/RegisterUserABForm'
import logo from './../login-ab/assets/ab_logo.png'

const URL_REGISTER_AB_PAGE = '/registerAB/$2y$10$DwiblQ'

function RegisterABPage () {
  return (
    <StepBaseTemplate
      justify='space-around'
      align='middle'
    >
      <div style={{ margin: '18px 25%' }}>
        <AppLogoNameHeader
          style={{
            paddingBottom: 42,
            maxWidth: 250,
            padding: 18
          }}
          img={logo}
        />
        <RegisterUserABForm />
      </div>
    </StepBaseTemplate>
  )
}

export { RegisterABPage, URL_REGISTER_AB_PAGE }
