import { Button, Col, Form, Modal, Row, notification } from 'antd'
import { useEffect, useState, useRef } from 'react'
import { useGetCustomQuoteView } from '../../../../bussines/hooks/quote/useGetCustomQuoteView'
import { usePostCustomQuoteView } from '../../../../bussines/hooks/quote/usePostCustomQuoteView'
import { BasicRadioGroupFormItem } from '../../../../common/components/helper-form/BasicRadioGroupFormItem'
import { SelectBranchesFormItem } from '../../../../common/components/helper-form/SelectBranchesFormItem'
import { SelectCampusFormItem } from '../../../../common/components/helper-form/SelectCampusFormItem'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { PDFQuoteFieldsContainer } from './PDFQuoteFieldsContainer'
import { PDFQuotePreview } from './PDFQuotePreview'
import { WebQuoteFieldsContainer } from './WebQuoteFieldsContainer'
import { WebQuotePreview } from './WebQuotePreview'
import stateControl from '../../../../bussines/utils/state-control'
import { showErrorModal } from '../../../../utils/propsForModal'
import { useSearchParams } from 'react-router-dom'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'

function FormCustomQuote () {
  const [modal, context] = Modal.useModal()
  const [params] = useSearchParams()
  const video = params.get('video_uri')
  const sede = params.get('sedeId') ?? undefined
  const { user } = useAuth()
  const [form] = Form.useForm()
  const [gallery, setGallery] = useState([])
  const [campusGallery, setCampusGallery] = useState([])
  const [footGallery, setFootGallery] = useState([])
  const [pdfView, setPdfView] = useState([])
  const [cityGallery, setCityGallery] = useState([])
  const [headerFooter, setHeaderFooter] = useState([])
  const [campus, setCampus] = useState()
  const [videoUrl, setVideoUrl] = useState()

  const [id, setId] = useState([])
  const [url, setUrl] = useState([])
  const [, setThereIsVideo] = useState(false)

  const [sedeId, setSedeId] = useState(sede ? Number(sede) : sede)
  const [type, setType] = useState('web')

  const { saveCustom, isLoading: isSaving, error, currentState } = usePostCustomQuoteView()
  const { data: campusData, isLoading: loadingCampus, isFetching, refetch } = useGetCustomQuoteView(sedeId, type)

  const web = Form.useWatch('web', form)
  const formRef = useRef(null)
  const [vimeoSave, setvimeoSave] = useState(false)

  const [numberPage, changePage] = useState(1)
  const [activeTemplate, changeActiveTemplate] = useState(1)

  const onFinish = (values, saving = false) => {
    if (id.length > 0 && values.sliders?.length > 0) {
      for (const i in id) {
        if (values?.sliders[i]) {
          values.sliders[i].file = id[i]
        }
      }
    }
    values.template = numberPage
    if (web) {
      if (numberPage === 2) {
        if (values.header?.length === 4) {
          const header = values.header?.filter(img => img.response?.id).map(img => img.response.id)
          values.header = header
        } else {
          showErrorModal(modal, 'Please, upload 4 images')
          return
        }
      } else {
        const header = values.header?.filter(img => img.response?.id).map(img => img.response.id)
        values.header = header
      }
    } else {
      const cover = values.cover?.filter(img => img.response?.id).map(img => img.response.id)
      values.cover = cover
      const city = values.city?.filter(img => img.response?.id).map(img => img.response.id)
      values.city = city
    }

    const footer = values.footer?.filter(img => img.response?.id).map(img => img.response.id)
    values.footer = footer
    console.log('🚀 ~ onFinish ~ values:', values)
    saveCustom(values)
  }

  const onChange = ({ sede_id: sedeID, web }) => {
    if (sedeID !== undefined) {
      setSedeId(sedeID)
    }
    if (web !== undefined) {
      if (web) setType('web')
      if (!web) setType('pdf')
    }
  }

  function MessageTemplateSelected () {
    if (activeTemplate === 1) {
      return (
        <p>
          <b>
            You activated template 1.This template shows a picture carrousel
          </b>
        </p>
      )
    }
    if (activeTemplate === 2) {
      return (
        <p>
          <b>
            You activated template 2.This template shows a collage picture with a video
          </b>
        </p>
      )
    }
  }

  const formatImages = (images) => {
    const formated = images?.map(img => ({
      uid: img.id,
      name: img.url,
      status: 'done',
      url: img.profile_photo_url,
      response: {
        id: img.id,
        url: img.profile_photo_url
      }
    }))
    return formated
  }

  useEffect(() => {
    if (!isSaving) {
      refetch()
    }
  }, [isSaving])

  useEffect(() => {
    setUrl([])
    if (!loadingCampus && campusData && !isFetching) {
      console.log(campusData)
      const actualValue = form.getFieldValue('web')
      const headerFormated = formatImages(campusData.header?.sort((a, b) => a.id - b.id))
      const footerFormated = formatImages(campusData.footer)

      const coverFormated = formatImages(campusData.cover)
      const cityFormated = formatImages(campusData.city)

      const campusFormated = formatImages(campusData.campus)
      setVideoUrl(campusData.video)

      const newGallery = []
      const newInitualValues = []
      if (campusData?.sliders) {
        setUrl([])
        campusData?.sliders?.forEach(image => {
          newGallery.push({
            image: image?.src,
            text_en: image?.description
          })

          newInitualValues.push({ description: image?.description })
          setUrl(currentUrl => {
            return [...currentUrl, image?.src]
          })
          setId(currentUrl => {
            return [...currentUrl, image?.id]
          })
        })
      }

      if (type === 'web') {
        form.setFieldsValue({
          header: headerFormated,
          footer: footerFormated,
          web: actualValue,
          sede_id: sedeId,
          video_link: campusData.video,
          sliders: newInitualValues // clean sliders (template 1)
        })
      } else {
        form.setFieldsValue({
          header: headerFormated,
          footer: footerFormated,
          cover: coverFormated,
          city: cityFormated,
          web: actualValue,
          sede_id: sedeId,
          sliders: newInitualValues // clean sliders (template 1)
        })
      }
      if (parseInt(campusData.template) === 2) {
        const formatedList = headerFormated?.filter(img => img.response?.id).map(img => ({ url: img.url }))
        setGallery(formatedList)
        setCampusGallery(campusFormated)
      } else if (parseInt(campusData.template) === 1) {
        setGallery(newGallery)
      } else {
        setGallery([])
        setCampusGallery([])
      }

      changePage(Number(campusData.template ?? '1'))
      changeActiveTemplate(Number(campusData.template ?? '1'))
      if (video && !vimeoSave) {
        setvimeoSave(true)
      } else if (campusData.video) {
        setvimeoSave(true)
        setThereIsVideo(true)
      }
    }
  }, [loadingCampus, campusData, isFetching, campus])

  useEffect(() => {
    if (parseInt(numberPage) === 2) {
      const headerFormated = formatImages(campusData?.header?.sort((a, b) => a.id - b.id))
      const campusFormated = formatImages(campusData?.campus)
      const formatedList = headerFormated?.filter(img => img.response?.id).map(img => ({ url: img.url }))
      setGallery(formatedList)
      setCampusGallery(campusFormated)
    } else if (parseInt(numberPage) === 1) {
      const newGallery = []
      const newInitualValues = []
      if (campusData?.sliders) {
        setUrl([])
        campusData?.sliders?.forEach(image => {
          newGallery.push({
            image: image?.src,
            text_en: image?.description
          })

          newInitualValues.push({ description: image?.description })
          setUrl(currentUrl => {
            return [...currentUrl, image?.src]
          })
          setId(currentUrl => {
            return [...currentUrl, image?.id]
          })
        })
      }
      setGallery(newGallery)
    } else {
      setGallery([])
      setCampusGallery([])
    }
  }, [numberPage])

  useEffect(() => {
    if (currentState === stateControl.STATE_SUCCESS && !isSaving) {
      const message = 'Data saved succesfully'
      notification.success({
        message
      })
    }
    if (currentState === stateControl.STATE_ERROR && !isSaving && numberPage !== 2) {
      showErrorModal(modal, error?.response?.data?.message ?? 'Something went wrong')
    }
  }, [isSaving])

  return (
    <Form
      layout='horizontal'
      scrollToFirstError
      name='SchoolForm'
      onFinish={onFinish}
      form={form}
      initialValues={{
        web: true,
        sliders: [{}],
        sede_id: sedeId
      }}
      onValuesChange={onChange}
    >
      <Row gutter={12}>
        {/* LEFT SIDE */}
        <Col xs={24} md={10}>
          <h3 className='byp-title byp-title-mkt-mtl'>Customize your quote</h3>
          <MessageTemplateSelected />
          <p className='byp-txt-mkt-mtl'>
            Upload the pictures that you want your students to see, on your quote preview.
          </p>

          {user?.type === 'Institution' && (
            <SelectCampusFormItem
              rules={[{ required: true, message: 'This field is required' }]}
              name='sede_id' placeholder='School:' single
              showSearch onChange={setCampus}
            />
          )}
          {user?.type === 'Agency' && (
            <SelectBranchesFormItem
              rules={[{ required: true, message: 'This field is required' }]}
              name='sede_id' placeholder='School:'
            />
          )}

          {web && !loadingCampus && (
            <WebQuoteFieldsContainer
              form={form}
              setGallery={setGallery}
              gallery={gallery}
              setFootGallery={setFootGallery}
              numberPage={numberPage}
              activeTemplate={activeTemplate}
              id={id}
              setId={setId}
              url={url}
              setUrl={setUrl}
              formRef={formRef}
              sedeId={sedeId}
              videoId={videoUrl}
              campus={campus}
              setThereIsVideo={setThereIsVideo}
            />
          )}
          {!web && (
            <PDFQuoteFieldsContainer
              form={form}
              setGallery={setPdfView}
              setCitylGallery={setCityGallery}
              setHeaderFooter={setHeaderFooter}
            />
          )}

          <Row>
            <h6 className='byp-title byp-fw-bold byp-mt-1 byp-sbtl-mkt-mtl'>Schools where this applies to</h6>
            <Col span={24}>
              {user?.type === 'Institution' && (
                <SelectCampusFormItem
                  // rules={[{ required: true, message: 'This field is required' }]}
                  name='sedes' placeholder='Schools name'
                  haveOptionAll
                />
              )}
              {user?.type === 'Agency' && (
                <SelectBranchesFormItem
                  // rules={[{ required: true, message: 'This field is required' }]}
                  name='sedes' placeholder='Schools name'
                  showOptionAll
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                htmlType='submit'
                loading={isSaving}
              >
                Save pictures
              </Button>
            </Col>
          </Row>
        </Col>
        {/* RIGTH SIDE */}
        <Col xs={0} md={14}>
          <BasicRadioGroupFormItem
            name='web'
            trueText='Web qoute'
            falseText='PDF quote'
            label=''
          />
          {web && !loadingCampus && (
            <WebQuotePreview
              gallery={gallery}
              footGallery={footGallery}
              campusGallery={campusGallery}
              numberPage={numberPage}
              changePage={changePage}
              activeTemplate={activeTemplate}
              changeActiveTemplate={changeActiveTemplate}
              videoUrl={videoUrl}
            />
          )}
          {!web && !loadingCampus && (
            <PDFQuotePreview
              gallery={pdfView}
              cityGallery={cityGallery}
              headerFooter={headerFooter}
            />
          )}
          {loadingCampus && <BasicSpinner />}
        </Col>
      </Row>
      {context}
    </Form>
  )
}

export { FormCustomQuote }
