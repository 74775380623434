import { Form, notification } from 'antd'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import stateControl from '../../../bussines/utils/state-control'
import { URL_QUOTER_PAGE } from '../../quoter'
import { AgencyPaymentsFieldContainer } from '../../register/components/steps/agency-payments/AgencyPaymentsFieldContainer'
import { AgencyProfileFieldsContainer } from '../../register/components/steps/agency-profile/AgencyProfileFieldsContainer'

const RegisterABForm = ({ registerApplyBoardUser, isLoading, error, currentState, form }) => {
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  console.log('🚀 ~ file: RegisterABAgencyPage.js:16 ~ RegisterABAgencyPage ~ locationState:', locationState)
  const handleOnFinish = (values) => {
    // setRedirect(false)
    values.agency_email = values.email
    values.email = locationState.email
    values.user = locationState.user
    values.password = locationState.password
    values.confirm = locationState.confirm
    registerApplyBoardUser(values)
  }

  useEffect(() => {
    if (currentState === stateControl.STATE_ERROR && !isLoading) {
      const errormesg = error?.response?.data?.message
      notification.error({
        message: errormesg ?? 'Something went wrong'
      })
    }
    if (currentState === stateControl.STATE_SUCCESS && !isLoading) {
      navigate(URL_QUOTER_PAGE)
    }
  }, [isLoading, currentState, error])

  return (
    <div style={{ margin: '18px 5%' }}>
      <div style={{ margin: '50px 0px' }}>
        <h3>Register your agency profile</h3>
      </div>
      <Form
        form={form}
        layout='vertical'
        scrollToFirstError
        name='UpdateAgency'
        onFinish={handleOnFinish}
        initialValues={{
          social_networks: [{
            name: 'Facebook',
            url: '',
            show: false
          }, {
            name: 'Twitter',
            url: '',
            show: false
          }, {
            name: 'Linkedin',
            url: '',
            show: false
          }, {
            name: 'Instagram',
            url: '',
            show: false
          }, {
            name: 'Youtube',
            url: '',
            show: false
          }, {
            name: 'Tik Tok',
            url: '',
            show: false
          }]
        }}
        requiredMark={false}
      >
        <AgencyProfileFieldsContainer
          form={form}
          useMainOffice={false}
          // displayPassword
        />
        <AgencyPaymentsFieldContainer
          form={form}
        />
      </Form>
    </div>
  )
}

export { RegisterABForm }
