import { useQuery } from 'react-query'
import UserRepository from '../../../../repositories/UserRepository'

function useGetQuotesStatisticsByProgram (program_name, campus, filters = {}) {
  const allFilters = { program_name, campus, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(['useGetQuotesStatisticsByProgram', program_name, filtersQuery], () => UserRepository.quotesStatisticsByProgram(filtersQuery), {
    enabled: !!program_name,
    retry: 1
  })
}

export { useGetQuotesStatisticsByProgram }
