import React from 'react'
import { LoginForm } from './../login/components/LoginForm'
import './../login/assets/css/LoginPage.less'
import background from './assets/img.png'
import logo from './assets/ab_logo.png'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'

const URL_LOGIN_AB_PAGE = '/loginAB'

const LoginABPage = () => {
  return (
    <div>
      <div className='desktop-screen'>
        <div className='form-container'>
          <div className='img-logo-container'>
            <AppLogoNameHeader
              style={{
                width: '100%',
                objectFit: 'contain',
                height: '100%',
                paddingBottom: '18px'
              }}
              img={logo}
            />
          </div>
          <LoginForm isForAB />
        </div>
        <div className='img-container-login'>
          <img
            src={background}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export { LoginABPage, URL_LOGIN_AB_PAGE }
