import { useQuery } from 'react-query'
import InsuranceRepository from '../../../repositories/InsuranceRepository'

function useGetFeeCosts (id) {
  return useQuery(
    ['useGetFeeCosts', id],
    () => InsuranceRepository.getCosts(id),
    { retry: 1, enabled: !!id }
  )
}

function useGetFeeCostsByYear (id, year) {
  return useQuery(
    ['useGetFeeCostsByYear', id, year],
    () => InsuranceRepository.getCosts(id, year),
    { retry: 1, enabled: !!id && !!year }
  )
}

export { useGetFeeCosts, useGetFeeCostsByYear }
