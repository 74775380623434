import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useGetDiscountsByProgram (program) {
  return useQuery(
    ['useGetDiscountsByProgram'],
    () => DiscountRepository.getDiscountsByProgram(program),
    {
      enabled: !!program
    }
  )
}

export { useGetDiscountsByProgram }
