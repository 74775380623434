import React from 'react'
import SessionService from '../../services/SessionService'

const MagicLinkContext = React.createContext()

function MagicLinkProvider (props) {
  const params = new URLSearchParams(document.location.search)

  if (params.get('token')) {
    const token = String(params.get('token'))
    SessionService.createSession({ token })
  }

  return (<MagicLinkContext.Provider value={{}} {...props} />)
}

export { MagicLinkProvider }
