import { Statistic, Table } from 'antd'
import { useEffect, useState } from 'react'
const { Column } = Table

function getStyle (type) {
  if (type === 'discount') {
    return {
      color: '#686868',
      fontWeight: 'bold'
    }
  }
  return {}
}

function QuoteOverviewCosts ({ isLoading, data, divisa, exchangeVal, type, agentDivisa }) {
  const [costs, setCosts] = useState([])
  const getValue = (value) => {
    if (value === null) return ''
    return !isNaN(value) ? `$${Number(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : value
  }

  const CostCell = ({ record }) => {
    if (record.cost === 0) {
      return <span style={{ color: '#686868', fontWeight: 'bold' }}><b>INCLUDED</b></span>
    }
    return (
      <Statistic
        value={getValue(record.cost)} precision={2}
        className={(record.cost < 0 || record.type === 'discount') ? 'discount' : null}
      />
    )
  }
  useEffect(() => {
    const res = data.filter(cost => {
      return cost?.isAgency !== 'Agency'
    })
    setCosts(res)
  }, [])

  return (
    <Table
      loading={isLoading}
      dataSource={costs ?? []}
      className='byp-table-clear quote-overview-table'
      rowKey='id'
      showHeader={false}
      pagination={{
        hideOnSinglePage: true,
        pageSize: 50
      }}
    >
      <Column
        title='Concept' dataIndex='name' key='name'
        render={(_, record) => (
          <>
            <span style={getStyle(record.type)}>{record.name}</span>
            {record.description && (
              <p className='description-cost' style={getStyle(record.type)}>{record.description}</p>
            )}
          </>
        )}
      />
      <Column
        title={'Total ' + divisa} dataIndex='cost' key='cost'
        render={(_, record) => (
          <>
            <span className='data-label'>Total {divisa}</span>
            <CostCell record={record} />
          </>
        )}
      />
    </Table>
  )
}

export { QuoteOverviewCosts }
