import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
// import { FormVimeo } from './components/FormVimeo'
import '../../common/assets/css/form-vimeo.less'

import React from 'react'
// THIS SHULD GO IN THE APP COMPONENT
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  CategoryScale
} from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

const URL_TESTS_PAGE = '/admin/tests'

function Dona() {
  const data = {
    labels: ['Cosa 1', 'Cosa 2', 'Cosa 3', 'Cosa 4',],
    datasets: [
      {
        data: [12, 19, 3, 5],
        backgroundColor: [
          'rgba(45, 47, 102)',
          'rgba(255, 204, 0)',
          'rgba(100, 149, 237)',
          'rgba(172, 133, 222)'
        ],
        borderWidth: 0,
      },
    ],
  };
  return (
    <Doughnut data={data} />
  )
}

function HorizontalBar() {
  const data = {
    datasets: [{
      label: 'cosa 1',
      data: [10, 20, 30, 40],
      backgroundColor: [
        'rgba(45, 47, 102)',
      ],
      order: 1
    },
    {
      label: 'cosa 2',
      data: [20, 40, 60, 80],
      backgroundColor: [
        'rgba(255, 204, 0)',
      ],
      order: 2

    },
    {
      label: 'cosa 3',
      data: [20, 40, 60, 80],
      backgroundColor: [
        'rgba(100, 149, 237)',
      ],
      order: 3

    },
    {
      label: 'cosa 4',
      data: [20, null, 60, 80],
      backgroundColor: [
        'rgba(172, 133, 222)'
      ],
      order: 4

    }],
    labels: ['a', 'b', 'c', 'd'],
  }

  const config = {
    type: 'bar',
    data,
    responsive: true,
    indexAxis: 'y'
  }

  return (
    <div style={{ width: '500px', height: '500px' }}>
      <Bar options={config} data={data} />;
    </div>
  )
}

function LineChart({ }) {

  const data = {
    labels: ['a', 'b', 'c', 'd'],
    datasets: [{
      label: 'Cosa 1',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      backgroundColor: 'rgba(45, 47, 102)',
      borderColor: 'rgba(45, 47, 102)',
      tension: 0.1,
    },
    {
      label: 'Cosa 2',
      data: [10, 20, 30, 41, 56, 55, 60],
      fill: false,
      backgroundColor: 'rgba(255, 204, 0)',
      borderColor: 'rgba(255, 204, 0)',
      tension: 0.1,
    },
    {
      label: 'Cosa 3',
      data: [165, 59, 180, 81, 56, 55, 40],
      backgroundColor: 'rgba(100, 149, 237)',
      borderColor: 'rgba(100, 149, 237)',
      tension: 0.1,
    }
    ]
  };

  const config = {
    type: 'line',
    data: data,
  }

  return (
    <div style={{ width: '500px', height: '500px' }}>
      <Line options={config} data={data} />
    </div>
  )
}

const TestPage = () => {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div style={{ width: '500px', height: '500px' }}>
          <Dona />
        </div>
        <HorizontalBar></HorizontalBar>
        <LineChart />
      </MainTheme>
    </PageProtected>
  )
}

export { TestPage, URL_TESTS_PAGE }

