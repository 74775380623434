import { Statistic, Switch, Typography } from 'antd'
import React from 'react'
import { RowQuoter } from './RowQuoter'
import { usePackage } from '../../../providers/package/package-course-context'

const { Text } = Typography

function Additionals () {
  const { service, formConfig, setFormConfig } = usePackage()
  console.log('🚀 ~ file: Additionals.js:10 ~ Additionals ~ formConfig:', formConfig)

  const handleChange = (value, item) => {
    let agencyServices = []
    if (!formConfig.additionals) {
      setFormConfig({
        ...formConfig,
        additionals: []
      })
    }
    if (value) {
      agencyServices = formConfig.additionals ?? []
      agencyServices?.push(item)
      // console.log('🚀 ~ file: Additionals.js:21 ~ handleChange ~ agencyServices:', agencyServices)
    } else {
      agencyServices = formConfig.additionals?.filter((service) => (
        service.id !== item.id
      ))
      // console.log('🚀 ~ file: false:', agencyServices)
      // formConfig.additionals?.map((service) => (
      //   service.id !== item.id ? agencyServices.push(service) : null
      // ))
    }
    setFormConfig({
      ...formConfig,
      additionals: agencyServices
    })
  }

  return (
    <>
      {
        service.getAdditions()?.length > 0 && (
          service.getAdditions()?.map((d) => (
            <RowQuoter
              key={d.id}
              colA={
                // <>
                //   {
                //     service.getAdditions()?.length > 0 && (
                //       service.getAdditions()?.map((d) => (
                //         <div key={d.id}>
                //           <Text>{d.concept}</Text>
                //         </div>
                //       ))
                //     )
                //   }
                // </>
                <div key={d.id} style={{ display: 'flex', gap: '1rem' }}>
                  <Text>{d.concept}</Text>
                  {!d.mandatory && (
                    <Switch
                      // onChange={() => service.addAdditionalsActives(d.id)}
                      onChange={(value) => { handleChange(value, d) }}
                      checked={service.includeAdditions(d.id)}
                      checkedChildren='YES'
                      unCheckedChildren='NO'
                    />
                  )}
                </div>
              }
              colB={
                // <>
                //   {
                //     service.getAdditions()?.length > 0 && (
                //       service.getAdditions()?.map((d) => (
                //         <div key={d.id}>
                //           <Statistic
                //             prefix=''
                //             precision={2}
                //             value={d.cost}
                //             suffix={`${service.getCurrency()}`}
                //           />
                //         </div>
                //         // <Text key={d.id}>{d.cost}</Text>
                //       ))
                //     )
                //   }
                // </>
                <div key={d.id}>
                  <Statistic
                    prefix=''
                    precision={2}
                    value={service.getAdditional(d.id)?.cost}
                    suffix={`${service.getCurrency()}`}
                  />
                </div>
              }
            />
          ))
        )
      }
    </>
    // <RowQuoter
    //   colA={
    //     <>
    //       {
    //         service.getAdditions()?.length > 0 && (
    //           service.getAdditions()?.map((d) => (
    //             <div key={d.id}>
    //               <Text>{d.concept}</Text>
    //             </div>
    //           ))
    //         )
    //       }
    //     </>
    //   }
    //   colB={
    //     <>
    //       {
    //         service.getAdditions()?.length > 0 && (
    //           service.getAdditions()?.map((d) => (
    //             <div key={d.id}>
    //               <Statistic
    //                 prefix=''
    //                 precision={2}
    //                 value={d.cost}
    //                 suffix={`${service.getCurrency()}`}
    //               />
    //             </div>
    //             // <Text key={d.id}>{d.cost}</Text>
    //           ))
    //         )
    //       }
    //     </>
    //   }
    //   colC={
    //     <>
    //       {!d.mandatory && (
    //         <Switch
    //           // onChange={() => service.addAdditionalsActives(d.id)}
    //           onChange={(value) => { handleChange(value, d) }}
    //           checked={service.includeAdditions(d.id)}
    //           // checkedChildren='YES'
    //           // unCheckedChildren='NO'
    //         />
    //       )}
    //     </>
    //   }
    // />
  )
}

export { Additionals }
