import { Form, Select } from 'antd'
const { Option } = Select
const SelectCourseTypeFormItem = ({ name, label, showAll, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label={label}
    >
      <Select
        className='w-100'
        {...rest}
      >
        {showAll && <Option key={0} value={null}>All types</Option>}
        <Option value='Academic Year'>Academic Year</Option>
        <Option value='Semester Program'>Semester Program</Option>
        <Option value='Short term Program'>Short term Program</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectCourseTypeFormItem }
