import React from 'react'
import { MainTheme } from '../../../common/theme/MainTheme'
import { PageProtected } from '../../../common/components/PageProtected'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { HomeSliderInterfaz } from './components/HomeSliderInterfaz'

const URL_HOME_LANDER_PAGE = '/dashboard/custom/quote/home-slider'

function HomeSliderPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          <HomeSliderInterfaz />
        </div>

      </MainTheme>
    </PageProtected>
  )
}

export { HomeSliderPage, URL_HOME_LANDER_PAGE }
