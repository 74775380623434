import { Form, Select, Statistic, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { FieldHighSeason } from './FieldHighSeason'
import { RowQuoter } from './RowQuoter'
import { SelectExtraNightStartAndDuration } from './SelectExtraNightStartAndDuration'
import { SelectLodgingStartAndDuration } from './SelectLodgingStartAndDuration'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

const { Option } = Select

function LodgingSection () {
  const [selected, setSelected] = useState()
  const { service, formConfig, setFormConfig } = useQuoter()
  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      lodging: included
    })
  }

  const handleChangeExSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      extra_nigth: included
    })
  }

  const handleAddextra = (value, { id, cost, frequency }) => {
    let lodgingExtras = formConfig.lodgingExtras || []
    const nExtras = []
    if (value) {
      lodgingExtras.push({ id, cost, frequency })
    } else {
      formConfig.lodgingExtras?.map((extra) => (
        extra.id !== id ? nExtras.push(extra) : null
      ))
      lodgingExtras = nExtras
    }
    setFormConfig({
      ...formConfig,
      lodgingExtras
    })
  }

  useEffect(() => {
    const lodgings = service.getArrayLodging() || []
    const found = lodgings.find((item) => item.id === selected)
    const extras = found?.extra_costs || []
    const mandatoryExtras = extras.filter((extra) => extra.mandatory)
    const lodgingExtras = mandatoryExtras.map((extra) => ({
      id: extra.id,
      cost: extra.cost,
      frequency: extra.frequency
    }))
    setFormConfig({
      ...formConfig,
      lodgingId: selected,
      lodgingExtras
    })
  }, [selected])

  function Price () {
    return (
      <>
        {service.getAmountsLodging().ls_cost > 0 && (
          <Statistic
            prefix=''
            className={
              service.getLodgingDiscounts().length > 0 ? 'price-before' : null
            }
            precision={2}
            value={`${service.getAmountsLodging().ls_cost}`}
            suffix={`${service.getCurrency()}`}
          />
        )}
      </>
    )
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <RowQuoter
        colA={
          <p className='w-100 byp-mb-0'>
            Add accommodation <Switch onChange={handleChangeSwitch} checked={formConfig.lodging} checkedChildren='YES' unCheckedChildren='NO' />
          </p>
        }
      />
      {service.includeLodging() && (
        <RowQuoter // Accomodation search
          colA={
            <>
              Accomodation search
              {
                // Discounts
                service.getAcommodationFeeDiscounts().length > 0 && (
                  service.getAcommodationFeeDiscounts()?.map((d) => (
                    <p key={d.id} className='byp-fw-bold'>
                      {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                    </p>
                  ))
                )
              }
            </>
          }
          colB={
            selected && (
              <StatisticWithDiscounts
                discounts={service.getAcommodationFeeDiscounts()}
                amount={service.getLodgingAcommodationFee()}
                currency={service.getCurrency()}
                subtotal={service.AcommodationFeeTotal()}
                active={formConfig.lodging && selected}
              />
            )
          }
        />
      )}
      {service.includeLodging() && (
        <RowQuoter // Select Accomodation
          hr={service.getAmountsLodging().hs_cost <= 0}
          colA={
            <>
              <Form.Item
                label='Accommodation'
                name='lodging'
                rules={[{
                  required: true,
                  message: 'Field required'
                }]}
                className='byp-mb-0'
              >
                <Select
                  style={{ width: '95%' }}
                  allowClear
                  placeholder='No seleccionado'
                  onChange={setSelected}
                  size='small'
                >
                  {service.getArrayLodging().map((itm) => {
                    return <Option key={itm.id} value={itm.id}>{itm.name}</Option>
                  })}
                </Select>
              </Form.Item>
            </>
          }
          colB={
            <div>
              <Price />
              <p>
                Normal season
              </p>
            </div>
          }
        />
      )}
      {(service.getCourseProgram()?.name !== 'academic_years') && (
        <SelectLodgingStartAndDuration program={service.getCourseProgram()?.name} />
      )}
      {service.getLodgingHighSeasonValues()?.details?.map(// Peak Seasons
        (hs) => (
          <RowQuoter
            key={'hs_label' + hs.id}
            hr={false}
            colA={
              <p>
                high Season cost
                From {hs.start_formated ?? hs.start} to {hs.end_formated ?? hs.end}, {hs.weeks} Weeks
                {/* {hs.daysOff > 0 && (
                  ' plus ' + hs.daysOff + ' days'
                )} */}
              </p>
            }
            colB={// High Season Costs
              service.getAmountsLodging().hs_cost > 0 && (
                <Statistic
                  prefix=''
                  className={
                    service.getLodgingDiscounts().length > 0 ? 'price-before' : null
                  }
                  precision={2}
                  // value={`${service.getAmountsLodging().hs_cost}`}
                  // value={`${(hs.costs.standard_cost * hs.weeks) + (hs.daysOff * (hs.costs.standard_cost / 7))}`}
                  value={`${(hs.costs.standard_cost * hs.weeks)}`}
                  suffix={`${service.getCurrency()}`}
                />
              )
            }
          />
        )
      )}
      {// Discounts
        service.getLodgingDiscounts().length > 0 && service.getHighSeasonAmountLodging() > 0 && (
          service.getLodgingDiscounts()?.map((d) => (
            <RowQuoter
              key={'row_lodging_section_a' + d.name}
              colA={
                <p key={d.id} className='byp-fw-bold'>
                  {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                </p>
              }
              colB={
                <Statistic
                  prefix=''
                  className='price-after'
                  precision={2}
                  value={`${service.getHighSeasonAmountLodging()?.toFixed(2) - service.getLodgingDiscountsAmount()}`}
                  suffix={`${service.getCurrency()}`}
                />
              }
            />
          ))
        )
      }
      {service.getLodgingIsHighSeason() && <FieldHighSeason forLodging />}
      {// Lodging Extra Costs
        service.getLodgingExtraCosts().length > 0 && (
          service.getLodgingExtraCosts().map((extra) => (
            <RowQuoter
              key={extra.id}
              colA={
                <>
                  <p>
                    <span style={{ marginRight: '.5rem' }}>{extra.name}</span>
                    {!extra.mandatory && (
                      <Switch
                        onChange={(val) => handleAddextra(val, extra)}
                        checked={service.isLodgingExtraSelected(extra.id)}
                        checkedChildren='YES'
                        unCheckedChildren='NO'
                      />
                    )}
                  </p>
                  {service.getLodgingExtraCostsDiscounts(extra.id)?.map((d) => (
                    <p key={'accom_fee_disc_' + d.id} className='byp-fw-bold'>
                      {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                    </p>
                  ))}
                </>
              }
              colB={
                <StatisticWithDiscounts
                  discounts={service.getLodgingExtraCostsDiscounts(extra.id)}
                  amount={service.calculateLodgingExtraCosts(extra.id)?.toFixed(2)}
                  currency={service.getCurrency()}
                  subtotal={service.calculateLodgingExtraSubtotal(extra.id)?.toFixed(2)}
                  active={service.isLodgingExtraSelected(extra.id)}
                />
              }
            />
          ))
        )
      }
      {
        service.includeExtraNight() && (
          <RowQuoter
            colA={
              <p className='w-100'>
                Add extra night: <Switch onChange={handleChangeExSwitch} checked={formConfig.extra_nigth} checkedChildren='YES' unCheckedChildren='NO' />
              </p>
            }
          />
        )
      }
      {formConfig.extra_nigth && (
        <SelectExtraNightStartAndDuration />
      )}
    </div>
  )
}

export { LodgingSection }
