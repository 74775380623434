import { Tag, Typography } from 'antd'
import { useEffect } from 'react'
import { getFrequencyLabel } from '../../../../../utils/frequencyUtils'
const { Title } = Typography

const GeneralPricesView = ({ data, campus, showJson, ...props }) => {
  useEffect(() => {
    // console.log('🚀 ~ file: GeneralPricesView.js:7 ~ useEffect ~ data:', data)
  }, [data])

  if (showJson) {
    return (
      <pre style={{ maxHeight: '40vh', overflow: 'auto' }}>
        {JSON.stringify(data ?? {}, null, 2)}
      </pre>
    )
  }

  return (
    <div {...props}>
      {/* Campus - Año */}
      <Title level={5}>{campus?.name} {data?.year}</Title>
      <Title level={5}>Regions</Title>
      {data?.region?.map((region) => <Tag key={region}>{region}</Tag>)}
      {/* Tarifas iniciales */}
      <Title level={5}>Tarifas iniciales</Title>
      {data?.fees?.map((fee) => (
        <Tag key={fee.id}>
          <span className='byp-fw-bold'>{fee.name}: </span>
          <span> {fee.cost} {data.divisa} </span> <span>{getFrequencyLabel(fee.frequency)}</span>
        </Tag>
      ))}
      {
        /* Materiales */
        data.materials && (
          <>
            <Title level={5}>Materiales</Title>
            {data?.materials_prices?.map((fee) => (
              <Tag key={fee.id}>
                <span className='byp-fw-bold'>{fee.name}: </span>
                <span> {fee.cost} {data.divisa} </span> <span>{getFrequencyLabel(fee.frequency)}</span>
              </Tag>
            ))}
          </>
        )
      }
      {/* Peak seasons */}
      {data?.high_seasons?.length > 0 && (
        <>
          <Title level={5}>Peak Seasons</Title>
          {data?.high_seasons?.map((highSeason) => (
            <Tag key={highSeason.id}>{highSeason.hs_start_formated} to {highSeason.hs_end_formated}</Tag>
            // HS fees
          ))}
        </>
      )}
      {/* Medicals Insurance */}
      {data?.insurances?.length > 0 && (
        <>
          <Title level={5}>Medicals Insurance</Title>
          {data?.insurances?.map((insurance) => (
            <Tag key={insurance.id}>
              <span className='byp-fw-bold'>{insurance.name}: </span>
              <span> {insurance.cost} {data.divisa} </span> <span>{getFrequencyLabel(insurance.frequency)}</span>
            </Tag>
            // HS fees
          ))}
        </>
      )}
      {/* Aditional costs */}
      {data?.extras?.length > 0 && (
        <>
          <Title level={5}>Aditional costs</Title>
          {data?.extras?.map((fee) => (
            <Tag key={fee.id}>
              <span className='byp-fw-bold'>{fee.name}: </span>
              <span> {fee.cost} {data.divisa} </span> <span>{getFrequencyLabel(fee.frequency)}</span>
            </Tag>
            // HS fees
          ))}
        </>
      )}
      {
        /* Aditional information */
        data?.includes?.length > 0 && (
          <>
            <Title level={5}>Aditional information</Title>
            {data?.includes?.map((include) => (
              <Tag key={include.id}>{include.name}</Tag>
              // HS fees
            ))}
          </>
        )
      }
      {
        /* Holidays */
        data?.holidays?.length > 0 && (
          <>
            <Title level={5}>Holidays</Title>
            {data?.holidays?.map((holiday) => (
              <Tag key={holiday.id}>{holiday.date}</Tag>
              // HS fees
            ))}
          </>
        )
      }
      {/* Additional comments */}
    </div>
  )
}

export { GeneralPricesView }
