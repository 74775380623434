import { Form } from 'antd'
import React from 'react'
import { useRegisteABAgency } from '../../bussines/hooks/user/useRegisteABAgency'
import stateControl from '../../bussines/utils/state-control'
import { StepBaseTemplate } from './../register/components/StepBaseTemplate'
import { FormFooter, SuccessFooter } from './components/RegisterABFooter'
import { RegisterABForm } from './components/RegisterABForm'
// import { SuccesRegistrationComponent } from './components/SuccesRegistrationComponent'

const URL_REGISTER_AGENCY_AB_PAGE = '/registerAB/$2y$10$DwiblQ/agency'

function RegisterABAgencyPage () {
  const [form] = Form.useForm()
  const { registerApplyBoardUser, isLoading, error, currentState } = useRegisteABAgency()

  return (
    <StepBaseTemplate
      justify='space-around'
      footer={
        stateControl.STATE_SUCCESS !== currentState
          ? <FormFooter form={form} isLoading={isLoading} />
          : <SuccessFooter form={form} isLoading={isLoading} />
      }
    >
      <RegisterABForm
        registerApplyBoardUser={registerApplyBoardUser}
        isLoading={isLoading}
        error={error}
        currentState={currentState}
        form={form}
      />
      {/* {
        stateControl.STATE_SUCCESS !== currentState && (
        )
      }

      {stateControl.STATE_SUCCESS === currentState && (
        <SuccesRegistrationComponent />
      )} */}
    </StepBaseTemplate>
  )
}

export { RegisterABAgencyPage, URL_REGISTER_AGENCY_AB_PAGE }
