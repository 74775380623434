import { Button, Select, Table, Tag, Switch } from 'antd'
import { useGetCampusDataTable } from '../../../../bussines/hooks/school/useGetCampusDataTable'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PUBLISH_QUOTES_STATES } from '../../../../bussines/utils/url-page-states'
import { useSearch } from '../../../../providers/search/search-context-provider'
import { SelectServiceByCategory } from '../../../../common/components/helper-form/SelectServiceByCategory'
import { SelectYearByCampus } from '../../../../common/components/helper-form/SelectYearByCampus'
import { classStyleByStatus } from '../../../../utils/tagStatusClass'
import { ModalPublishQuote } from '../../../../common/components/ModalPublishQuote'
import { SelectServicesCategoriesFormItem } from '../../../../common/components/helper-form/SelectServicesCategoriesFormItem'
import { SelectInstitutions } from '../../../../common/components/helper-form/SelectInstitutions'
import { URL_APPROVE_PACKAGE_PAGE } from '../aprove/package/AprovePackage'
import { useAuth } from '../../../../providers/auth/auth-context-provider'

const { Column } = Table
const { Option } = Select

function TablePackageCategoryAgency (props) {
  const { user: _user } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sede, setSede] = useState('')
  const [course, setcourse] = useState('')
  const [year, setyear] = useState('')
  const [category, setCategory] = useState('language')
  const [program, setProgram] = useState('all')
  const [isAgency, setIsAgency] = useState(false)

  const { search, changeSearch } = useSearch()
  const [searchFilter, setSearchFilter] = useState({})
  const { isLoading, data: resp, refetch } = useGetCampusDataTable(program, search)

  const [campusId, setCampusId] = useState()

  useEffect(() => {
    localStorage.removeItem('campus')
  }, [])

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (countFilter) {
      changeSearch('QUOTE_FORM', {
        ...searchFilter
      })
    }
    setProgram(category)
  }

  const handleAddFilter = (key, value) => {
    if ((key === 'course' || key === 'sede' || key === 'year') && (value !== null)) {
      value = Number(value)
    }
    const nFilter = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilter)
    if (key === 'sede') {
      setCampusId(value)
    }
  }

  const sortByYear = (data) => {
    return data?.sort(function (a, b) {
      return b.year?.year - a.year?.year
    })
  }

  const setData = (campusId, courseId, yearId) => {
    localStorage.setItem('campus', JSON.stringify({ ...PUBLISH_QUOTES_STATES[category], campusId, courseId, yearId }))
  }

  const getPublishTag = (record, campusId, courseId, category = false, yearId, expired) => {
    let published = false
    if (isAgency) {
      published = record?.status === 'Published' ?? false
    } else {
      published = record?.publish?.status === 'Published' ?? false
    }
    const url = category ? URL_APPROVE_PACKAGE_PAGE + '?program=' + category : URL_APPROVE_PACKAGE_PAGE
    return (
      <Tag className={expired ? classStyleByStatus.Inactive : published ? classStyleByStatus.Aproved : classStyleByStatus.Created}>
        <Link
          target='_blank'
          onClick={() => setData(campusId, courseId, yearId)}
          style={{ color: expired ? 'red' : published ? 'var(--full-white)' : 'var(--gray-100)' }}
          to={url}
          state={{ ...PUBLISH_QUOTES_STATES[category], campusId, courseId, yearId }}
        > {expired ? 'Expired' : published ? 'Published' : 'Unpublished'}
        </Link>
      </Tag>
    )
  }

  const PublishSwitchButton = (record) => {
    let status = null
    if (isAgency) {
      status = record?.status
    } else {
      status = record?.publish?.status
    }
    const handleSwitchChange = () => {
      if (isAgency) {
        setSede(record.sede?.id)
        setcourse(record.service_id)
        setyear(record.year?.id)
        setIsModalOpen(true)
      } else {
        setSede(record?.publish?.enterpriseable_id)
        setcourse(record?.id)
        setyear(record.year?.id)
        setIsModalOpen(true)
      }
    }
    return (
      <Switch
        checked={status === 'Published'}
        onClick={handleSwitchChange}
        checkedChildren='PUBLISH' unCheckedChildren='UNPUBLISH'
      />
    )
  }

  const handleReset = () => {
    setSearchFilter({})
    changeSearch('QUOTE_FORM', {})
    setCampusId()
    setProgram('all')
  }

  useEffect(() => {
    if (_user.type === 'Institution') {
      setIsAgency(false)
      setProgram('short_term')
    } else if (_user.type === 'Agency') {
      setIsAgency(true)
    }
  }, [_user])
  console.log('que es esto')
  console.log(resp)

  return (
    <div style={{ width: '100%' }}>
      <h3 className='byp-title'>Package Courses</h3>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px', paddingTop: '30px', paddingBotton: '20px' }}>
        <div style={{ width: '15%' }}>
          <SelectServiceByCategory
            program={program}
            onChange={(e) => {
              handleAddFilter('course', e)
            }}
            showAll
            placeholder='Quote type'
            filters={{
              service_type: 'package'
            }}
            size='small'
            value={searchFilter?.course}
          />
        </div>
        {isAgency && (
          <div style={{ width: '15%' }}>
            <SelectServicesCategoriesFormItem
              setProgramName={setCategory}
              placeholder='Program'
              size='small'
            />
          </div>
        )}
        {isAgency && (
          <div style={{ width: '25%' }}>
            <SelectInstitutions
              size='small'
              onChange={(e) => {
                handleAddFilter('institution', e)
              }}
              placeholder='Institution'
              value={searchFilter?.institution}
            />
          </div>
        )}
        <div style={{ width: '15%' }}>
          <Select
            style={{ width: '70%', marginLeft: '5%' }}
            size='small'
            onChange={(e) => {
              handleAddFilter('status', e)
            }}
            placeholder='Status'
            value={searchFilter?.status}
          >
            <Option key={0} value={null}>All</Option>
            <Option key={1} value='Published'>Published</Option>
            <Option key={2} value='Unpublished'>Unpublished</Option>
          </Select>
        </div>
        <div style={{ width: '15%' }}>
          <SelectYearByCampus
            placeholder='Year'
            campus={campusId}
            showAll
            selectById={false}
            showDefault
            onChange={(e) => {
              handleAddFilter('year', e)
            }}
            value={searchFilter?.year}
            size='small'
          />
        </div>
        <div style={{ width: '15%', display: 'flex', gap: '20px', justifyContent: 'end' }}>
          <Button style={{ backgroundColor: 'lightgray', color: 'black' }} onClick={handleReset}>
            Clear
          </Button>

          <Button
            onClick={handleOnSubmitSearch}
            style={{ backgroundColor: 'var(--blue-100)', color: 'white' }}
          >Search
          </Button>
        </div>
      </div>
      <Table
        dataSource={sortByYear(resp?.data)}
        className='byp-table-dashboard'
        loading={isLoading}
        rowKey='key'
      >
        <Column
          title='Quote'
          dataIndex='name'
          key='name'
          className='byp-fw-bold'
        />
        {isAgency && (
          <Column
            title='Program'
            dataIndex='program'
            key='program'
            responsive={['md']}
          />
        )}
        {
          isAgency && (
            <Column
              title='School'
              dataIndex='school'
              key='school'
              responsive={['md']}
              render={(_, record) => (record.school?.name)}
            />
          )
        }
        {
          !isAgency && (
            <Column
              title='School'
              dataIndex='school'
              key='school'
              responsive={['md']}
              render={(_, record) => (record.school)}
            />
          )
        }
        <Column
          title='Campus'
          dataIndex='campus'
          key='campus'
          responsive={['md']}
          render={(_, record) => (record.campus?.name)}
        />
        {
          isAgency && (
            <Column
              title='Offices'
              key='offices'
              render={(_, record) => {

                return <div style={{ maxHeight:'6rem', overflow: 'auto' }}>
                <ul>
                  {
                  record.officesName?.map((office) => (
                    <li key={office.id}>{office.name}</li>
                  ))}
                </ul>
                </div>
                 
              }}
            />
          )
        }
        <Column
          title='Status'
          dataIndex='status'
          key='status'
          render={(_, record) => getPublishTag(
            record,
            record.sede?.id,
            record.service_id,
            category,
            record.year?.id,
            record.expired
          )}
        />
        <Column
          title='Publish/Unpublish'
          dataIndex='publish'
          key='publish'
          render={
            (_, record) => PublishSwitchButton(record)
          }
        />
        <Column
          title='Published By'
          dataIndex='publishedBy' key='publishedBy'
        />
        <Column
          title='Expiration'
          dataIndex='year'
          key='year'
          render={(_, { year }) => (
            <p>{year?.end}</p>
          )}
        />
      </Table>
      <ModalPublishQuote
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        sede={sede}
        course={course}
        year={year}
        onSuccess={refetch}
      />
    </div>
  )
}

export { TablePackageCategoryAgency }
