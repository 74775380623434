import { Button, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { CardTopAgencie } from './CardTopAgencies'
import { useGetMostPopularAgencies } from '../../../../bussines/hooks/agency/analytics/general/useGetMostPopularAgencies'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'
import { URL_AGENCYS_LIST_PAGE } from '../../../institution/AgencysListPage'
import { Link } from 'react-router-dom'

const TopAgencies = ({ filters }) => {
  const { data, isLoading } = useGetMostPopularAgencies(filters)

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: TopAgencies.js:11 ~ useEffect ~ data:', data)
    }
  }, [isLoading, data])

  return (
    <>
      <Row className='top-agencies-right'>
        <Col span={18}>
          <h3 className='top-agencies-num'>Top 5 agencies</h3>
        </Col>
      </Row>
      {isLoading && (
        <BasicSpinner />
      )}
      {!isLoading && (
        <Row>
          {data?.data?.map((agencyItem) => (
            <CardTopAgencie key={agencyItem.id} agency={agencyItem} />
          ))}
          <Button
            className='w-100 top-agencies-btn byp-btn-back'
            style={{ background: '#F4F4F4' }}
          >
            <Link
              to={URL_AGENCYS_LIST_PAGE}
            >Others
            </Link>
          </Button>
        </Row>
      )}
    </>
  )
}

export { TopAgencies }
