import { Button, Col, Form, Input, InputNumber, Row, Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PrefixLabelSelector } from './PrefixLabelFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { useEffect, useState } from 'react'
import { SelectTimeFrequencyFormItem } from '../../../../common/components/helper-form/SelectTimeFrequencyFormItem'

function ConceptCostFormItem({ name, divisa, labelAdd, labelDelete, withOutDelete, program, isPackage, form, handleMandatory, switchMandatories, setSwitchMandatories, isNewData, setisNewData }) {
  const arrayName = name
  const arrayInsurance = Form.useWatch(arrayName, form) || []
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />

  const [frequencies, setFrequencies] = useState({ unic: true, day: true, week: true, month: true })
  const [isFirtsTime, setIsFirtsTime] = useState(true)

  useEffect(() => {
    if (program === 'graduate') {
      setFrequencies({ unic: true, semester: true, week: true, year: true })
    } else if (program === 'academic_years' || program === 'boarding_schools') {
      setFrequencies({ unic: true, semester: true, year: true })
    } else {
      setFrequencies({ unic: true, day: true, week: true, month: true })
    }
  }, [program])

  useEffect(() => {
    if (arrayInsurance && arrayInsurance?.length > 0 && (isFirtsTime || isNewData)) {
      const lengthArray = arrayInsurance?.length ?? 0
      const array = Array(lengthArray).fill(false)
      if (setSwitchMandatories) {
        setSwitchMandatories(array)
      }

      arrayInsurance?.forEach((item, index) => {
        if (handleMandatory) {
          handleMandatory(item?.mandatory, index, lengthArray)
        }
      })
      setIsFirtsTime(false)
      if (setisNewData) {
        setisNewData(false)
      }
    }
  }, [arrayInsurance, isNewData])

  return (
    <div>
      <Form.List
        name={arrayName}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, isListField, fieldKey, ...restField }, index) => (
              <Row key={key} gutter={30}>
                <Col span={7}>
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                  >
                    <Input
                      className='byp-input'
                      placeholder='Name'
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...restField}
                    name={[name, 'cost']}
                  >
                    <InputNumber addonBefore={prefixSelector} min={0} controls={false} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  {program !== 'short' && (
                    <SelectTimeFrequencyFormItem name={[name, 'frequency']} frequencies={frequencies} />
                  )}
                </Col>
                <Col span={4} style={{ paddingTop: '5px' }}>
                  <Row style={{ columnGap: '20px' }}>
                    Mandatory
                    <Form.Item
                      name={[name, 'mandatory']}
                    >
                      <Switch
                        checkedChildren='YES'
                        unCheckedChildren='NO'
                        checked={switchMandatories && switchMandatories[name]}
                        onChange={(e) => handleMandatory(e, index, arrayInsurance?.length)}
                      />
                    </Form.Item>
                  </Row>
                </Col>
                {
                  !isPackage ||
                  <Col span={24}>
                    <Form.Item
                      name={[name, 'description']}
                    >
                      <Input type='text' maxLength={50} placeholder='Description' />
                    </Form.Item>
                  </Col>
                }
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                  labelAdd={labelAdd}
                  labelDelete={labelDelete}
                  withOutDelete={withOutDelete}
                />
              </Row>
            ))}
            {fields.length === 0 && (
              <Form.Item>
                <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </div>
  )
}

export { ConceptCostFormItem }
