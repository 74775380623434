import React from 'react'
import { Row, Col } from 'antd'

function CompletedCampusCourseYear ({ course, campus, program }) {
  return (
    <Row style={{ display: 'flex', paddingTop: '10px', paddingBottom: '30px' }}>
      <Col span={6}>
        <small><b>{program} program </b></small>
      </Col>
      <Col span={6}>
        <small> School:</small> <b> {campus} </b>
      </Col>
      {course?.type === 'package' && (
        <Col span={6}>
          <small>Package name: </small><b> {course?.name}</b>
        </Col>
      )}
      {course?.type !== 'package' && (
        <Col span={6}>
          <small>Course:</small><b> {course?.name}</b>
        </Col>
      )}
      <Col span={6}>
        <small> Year:</small><b> {course?.year} </b>
      </Col>
    </Row>
  )
}

export { CompletedCampusCourseYear }
