import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useGetCampusOfCourse (campusId, courseId, filter) {
  const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetCampusOfCourse', campusId, courseId, queryString],
    () => CourseRepository.getCourseCampusList(campusId, courseId, queryString), {
      retry: 2,
      enabled: !!campusId
    })
}

export { useGetCampusOfCourse }
