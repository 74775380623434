import { Col, Form, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetDiscountYears } from '../../../bussines/hooks/school/discounts/useGetDiscountYears'
import { SelectDiscountFormItem } from './SelectDiscountFormItem'
import { useGetCampusCourses } from '../../../bussines/hooks/school/course/useGetCampusCourses'

const { Option } = Select

function SelectDiscountAndBlockFormItem ({ discount, setDiscount, campusID, program, setDiscounts, ...props }) {
  const [arrayYears, setArrayYears] = useState()
  const [arrayCampus, setArrayCampus] = useState([])
  const [campus, setCampus] = useState()
  const form = Form.useFormInstance()
  const block = Form.useWatch('block', form)
  const { data, isLoading } = useGetDiscountYears(discount)
  const { data: courses, isLoading: isLoadingCourses } = useGetCampusCourses(campus, { whereHasCosts: true })

  const handleChange = (val) => {
    setDiscount(val)
  }

  useEffect(() => {
    if (data?.years && !Array.isArray(data?.years)) {
      const years = data?.years ?? []
      const nYears = []
      Object.keys(years).forEach((key) => nYears.push(years[key]))
      setArrayYears(nYears)
    } else if (data?.years && Array.isArray(data?.years)) {
      const years = data?.years ?? []
      setArrayYears(years)
    }
    if (!isLoading && data) {
      setArrayCampus(data?.campus ?? [])
    }
  }, [data, isLoading])

  return (
    <Row gutter={16}>
      <Col xs={20} md={8} lg={5}>
        <SelectDiscountFormItem
          name='discount'
          label='Discount'
          program={program}
          onChange={handleChange}
          setDiscounts={setDiscounts}
          allowClear
          size='small'
        />
      </Col>
      <Col xs={20} md={12} lg={4}>
        <Form.Item name='block' label='Step'>
          <Select size='small'>
            <Option key={1} value='base'>Base prices</Option>
            <Option key={2} value='curso'>Course prices</Option>
            {program !== 'boarding_schools' && <Option key={3} value='alojamiento'>Accommodations prices</Option>}
            {program !== 'boarding_schools' && <Option key={4} value='transporte'>Transport prices</Option>}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={20} md={8} lg={4}>
        <Form.Item name='year' label='Year'>
          <Select size='small'>
            {arrayYears?.map((year) => (
              <Option key={year} value={year}>{year}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={20} md={8} lg={5}>
        <Form.Item name='campus' label='Campus'>
          <Select size='small' onChange={setCampus}>
            {arrayCampus?.map((campus) => (
              <Option key={'campus' + campus.id} value={campus.id}>{campus.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {block === 'curso' && (
        <Col xs={20} md={8} lg={5}>
          <Form.Item name='course' label='Course'>
            <Select size='small' loading={isLoadingCourses}>
              {courses?.map((course) => (
                <Option key={'course' + course.id} value={course.id}>{course.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}

export { SelectDiscountAndBlockFormItem }
