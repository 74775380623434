import { useMutation } from 'react-query'
import UserService from '../../../services/UserService'
import { useState } from 'react'
import stateControl from '../../utils/state-control'
import SessionService from '../../../services/SessionService'

function useResetPassword () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)

  const { mutate, isLoading, data, error } = useMutation(UserService.resetPassword, {
    onSuccess: d => {
      SessionService.createSession(d)
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: () => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  const onSubmit = ({ email, password, confirmation, token }) => {
    mutate({ email, password, confirmation, token })
  }

  return { mutate, isLoading, onSubmit, stateControl, currentState, data, error }
}

export { useResetPassword }
