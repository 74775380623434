import api from '../../services/apiVimeo'
import axiosVimeo from '../../services/axiosVimeo'

class VimeoRepository {
  async upload (data) {
    const response = await api.post('/me/videos', data)
    return response.data
  }

  async update (data) {
    const response = await api.post('/videos/{video_id}/versions', data)
    return response.data
  }

  async onEmbed (video) {
    const response = await axiosVimeo.get('/api/oembed.json', {
      params: {
        url: video,
        responsive: true,
        // width: 160,
        // height: 200,
        // maxheight: 200,

        byline: false, // Hide owners name,
        autoplay: false,
        controls: true, // Wheter to display the video controls
        loop: true,
        portrait: false, // Whether to display the video owner's portrait.
        title: false // Whether to display the video's title.
      }
    })
    return response.data
  }
}
export default new VimeoRepository()
