import React, { useMemo } from 'react'
import { Form, Input } from 'antd'
import debounce from 'lodash/debounce'
import UserRepository from '../../../bussines/repositories/UserRepository'

function EmailAsyncValidateFormItem ({ debounceTimeout = 400, ...props }) {
  const form = Form.useFormInstance()

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      form.setFields([{
        name: 'email',
        errors: [],
        validating: true
      }])

      UserRepository.validateEmailServer(value)
        .then((res) => {
          form.setFields([{
            name: 'email',
            errors: [],
            validating: false
          }])
        })
        .catch((err) => {
          form.setFields([{
            name: 'email',
            errors: [err.response.data?.message || 'Error'],
            validating: false
          }])
        })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [debounceTimeout])

  const handleChangeEmail = (email) => {
    debounceFetcher(email.target.value)
  }

  return (
    <Form.Item
      {...props}
      name='email'
      rules={[{ type: 'email', required: true, message: 'A valid email is required' }]}
    >
      <Input size='large' placeholder='Email' onChange={handleChangeEmail} />
    </Form.Item>
  )
}

export { EmailAsyncValidateFormItem }
