import { Table, Switch, Button } from 'antd'
import { usePostActivateCampus } from '../../../../bussines/hooks/school/usePostActivateCampus'
import { usePostDeleteCampus } from '../../../../bussines/hooks/school/usePostDeleteCampus'
import { useEffect, useState } from 'react'
import utilsState from '../../../../bussines/utils/state-control'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { SchoolsTableFilters } from './SchoolsTableFilters'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ErrorBoundary } from '../../../../providers/validation/ErrorBoundary'

const { Column } = Table

function SchoolsTable (props) {
  const {
    isLoading, loadingCampus, data, refetch, setCampusId, form, setIsUpdateMode, program,
    setPage, showDelete = true, showStatus = true
  } = props
  const { activateCampus, isLoading: activating, currentState: activated } = usePostActivateCampus()
  const { deleteCampus, isLoading: deleting, currentState } = usePostDeleteCampus()

  const [schools, setSchools] = useState(data?.campus?.data)

  useEffect(() => {
    if (!deleting && currentState === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [deleting])

  useEffect(() => {
    if (!activating && activated === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [activating])

  useEffect(() => {
    if (data?.campus) {
      setSchools(data?.campus?.data)
    }
  }, [data])

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <ErrorBoundary>
      <SchoolsTableFilters schools={data?.campus?.data ?? []} setSchools={setSchools} program={program} />
      <Table
        loading={isLoading}
        dataSource={schools}
        className='byp-table-dashboard'
        title={() => 'Schools'}
        rowKey='id'
        pagination={{
          current: data.campus?.current_page,
          size: data.campus?.last_page,
          total: data?.campus?.total,
          pageSize: data?.campus?.per_page,
          onChange: (val) => setPage(val)
        }}
      >
        <Column
          title='Name'
          dataIndex='name' key='name'
          render={(_, record) => (
            <p>{record.name}</p>
          )}
        />
        <Column
          title='Countries'
          dataIndex='country'
          key='country'
          responsive={['lg']}
          render={(_, record) => (
            (
              record?.country?.name ?? record.address?.split(',').at(-1) ?? null
            )
          )}
        />
        <Column
          title='City'
          dataIndex='city'
          key='city'
          responsive={['md']}
          render={(_, record) => (
            (
              record?.city?.name ?? record.address?.split(',').at(-3) ?? record.address?.split(',').at(-2) ?? null
            )
          )}
        />
        {showStatus && (
          <Column
            title='Status'
            dataIndex='status'
            key='status'
            responsive={['sm']}
            render={(_, record) => (
              (
                <div>
                  <Switch
                    style={{ alignItems: 'center', display: 'flex' }}
                    checkedChildren='ACTIVE'
                    unCheckedChildren='INACTIVE'
                    loading={activating}
                    onClick={
                      () => {
                        activateCampus({ id: record.id })
                      }
                    }
                    checked={record.status === 'Active'}
                  />
                </div>
              )
            )}
          />
        )}
        <Column
          title='Actions'
          dataIndex='status'
          key='action'
          render={(_, record) => (
            <>
              {showDelete && (
                <ConfirmModal onOk={deleteCampus} isLoading={deleting} id={{ id: record.id }} setCampusId={setCampusId} form={form} setIsUpdateMode={setIsUpdateMode} />
              )}
              <Button
                className='btn-icon  byp-btn-edit'
                loading={loadingCampus}
                onClick={() => setCampusId(record.id)}
                icon={<EditIcon />}
              />
            </>
          )}
        />
      </Table>
    </ErrorBoundary>
  )
}

export { SchoolsTable }
