import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { PrefixLabelSelector } from './PrefixLabelFormItem'
import { AddAndRemoveListTextButtons } from './AddAndRemoveListTextButtons'
import { TextInsideButton } from '../../../../common/components/TextInsideButton'

const { Option } = Select
const rules = [
  { required: true, message: 'This field is required' }
]

function CoursePriceRangeFormList ({ name, divisa, onlyRanks, onChangeSomeValue }) {
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />

  return (
    <div className='w-100'>
      <Form.List
        name={name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={'CoursePriceRangeFormList_' + key} gutter={16} className='byp-list-item'>
                <Form.Item
                  name={[name, 'id']}
                >
                  <Input type='hidden' />
                </Form.Item>
                <Col xs={24}>
                  <Row gutter={12} className='w-100'>
                    <Col span={4}>Starting at </Col>
                    <Col span={3}>
                      <Form.Item name={[name, 'min']} rules={rules}>
                        <Input type='number' min={1} placeholder='0' onChange={onChangeSomeValue} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={[name, 'min_frequency']} rules={rules} initialValue='weeks'>
                        <Select value='weeks' className='w-100' defaultValue='weeks' size='small'>
                          <Option key='weeks'>weeks</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={3}>to</Col>
                    <Col span={3}>
                      <Form.Item
                        name={[name, 'max']}
                      >
                        <Input type='number' min={1} placeholder='0' onChange={onChangeSomeValue} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name={[name, 'max_frequency']} initialValue='weeks'>
                        <Select value='weeks' className='w-100' defaultValue='weeks' size='small'>
                          <Option key='weeks'>weeks</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {!onlyRanks && (
                    <Row gutter={6} className='byp-mt-1'>
                      <Col span={4}>Rank cost:</Col>
                      <Col span={8}>
                        <Form.Item name={[name, 'cost']} rules={rules}>
                          <InputNumber min={0} addonBefore={prefixSelector} placeholder='00.00' onChange={onChangeSomeValue} controls={false} />
                        </Form.Item>
                      </Col>
                      <Col span={4}>Charging for</Col>
                      <Col span={8}>
                        <Form.Item name={[name, 'frequency']} initialValue='weeks'>
                          <Select className='w-100' placeholder='Select how many times' defaultValue='weeks' size='small'>
                            <Option key='weeks'>week</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Row className='w-100'>
                  <AddAndRemoveListTextButtons
                    fields={fields}
                    index={index}
                    add={add}
                    remove={remove}
                    name={name}
                    addTextAñadir='Add rank'
                  />
                </Row>
              </Row>
            ))}
            {
              fields.length === 0 && (
                <>
                  Add ranges of times?
                  <Button className='byp-btn-blue-100-outline float-right svg-boton-blue-fill' style={{ gap: '10px' }} onClick={() => add()}>
                    <TextInsideButton text='Add' />
                  </Button>
                </>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { CoursePriceRangeFormList }
