import React from 'react'
import { Row, Col, Button } from 'antd'
import { useGetTopUsers } from '../../../../../../bussines/hooks/agency/analytics/general/useGetTopUsers'
import { Link } from 'react-router-dom'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'
import { URL_USER_ANALYSIS_PAGE } from '../../user/UserAnalysisPage'

const limit = 5

const ProgramPopularUsers = ({ time, program, campus, institution }) => {
  const { data: topUsers, isLoading } = useGetTopUsers(limit, { ...time, program, campus, institution })

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row className='quote-top-users' gutter={[8, 8]}>
      {
        topUsers?.map(user => (
          <Col
            key={user.id}
          >
            <div className='quote-list-item-card byp-align-centered' style={{ padding: '0.625rem 1rem', justifyContent: 'space-between' }}>
              <img className='img-rounded byp-mb-1' style={{ width: '6.875rem', height: '6.875rem' }} src={user.profile_photo_url} alt={user.name} />
              <p className='byp-blue-100 byp-fw-bold byp-footer-center'>{user.name}</p>

              <div className='d-flex w-100' style={{ marginBottom: '0.5rem' }}>
                <div className='w-100' style={{ padding: '0 0.5rem' }}>
                  <p className='margin-0 byp-color-gray-100 byp-text-sm'>Quotes created</p>
                  <p className='margin-0 byp-text-sm byp-fw-bold'>{user.quotes_count}</p>
                </div>
                <div className='w-100 byp-border-left' style={{ padding: '0 0.45rem' }}>
                  <p className='margin-0 byp-color-gray-100 byp-text-sm'>Quotes sended</p>
                  <p className='margin-0 byp-text-sm byp-fw-bold'>{user.quotes_send}</p>
                </div>
              </div>
              <Button className='w-100 margin-0 byp-btn-back' style={{ background: '#F4F4F4' }}>
                <Link to={URL_USER_ANALYSIS_PAGE} 
                  state={{
                    IDUSER: user.id,
                    NAMEROLE: user.name_role,
                    IDSEDE: user.id_sede,
                    SEDE: user.sede
                  }}>
                  See more
                </Link>
              </Button>
            </div>
          </Col>
        ))
      }
    </Row>
  )
}

export default ProgramPopularUsers
