import { useParams } from 'react-router-dom'
import { useFindFeeDetails } from '../../../../../bussines/hooks/fee/useFindFeeDetails'
import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import LottieCargando from '../../../../../lotties/LottieCargando'
import { ServiceInfo } from './ServiceInfo'

const URL_APROVE_SERVICE_PAGE = '/dashboard/aprove/additional-service/:id'

const AproveServicePage = () => {
  const { id } = useParams()
  const { data, isLoading, refetch } = useFindFeeDetails(id)
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_AGENCY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          {isLoading && <LottieCargando />}
          {
            !isLoading && <ServiceInfo data={data} refetch={refetch} />
          }
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { AproveServicePage, URL_APROVE_SERVICE_PAGE }
