import { useEffect, useState } from 'react'
import { Col, Form, Input, InputNumber, Row, Radio, notification, Popover } from 'antd'
import { PrefixLabelSelector } from '../../../pages/dashboards/Institution/components/PrefixLabelFormItem'
import { ConceptCostFrequencyListFormItem } from '../../../pages/dashboards/Institution/components/ConceptCostFrequencyListFormItem'
import { AddAndRemoveSeasonBtn } from '../../../pages/dashboards/Institution/components/AddAndRemoveSeasonBtn'
import { InfoCircleOutlined } from '@ant-design/icons'

function HighSeasonFormList({ name, divisa, isLodging = false, isTransport = false, setEnableBtn }) {
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />
  const [dateFinal, setDateFinal] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [msjInput, setMsjInput] = useState('');

  useEffect(() => {
    let value = 'false'
    let msj = ''
    if (dateFinal < dateStart) {
      value = 'true'
      msj = 'error'
      validatorDateEnd(value)
      setMsjInput(msj)
      if (setEnableBtn) setEnableBtn(value)
    }
    if (setEnableBtn) setEnableBtn(value)
    value = 'false'
    setMsjInput(msj)
  }, [dateFinal])

  const validatorDateEnd = (val) => {
    if (val === 'true') {
      notification.error({
        message: 'You cannot enter an end date earlier than the start date.',
        placement: 'topRight',
        duration: '10'
      })

    }
  }

  return (
    <div className='w-100'>
      <div style={{ display: 'flex', gap:'10px' }}>
        <h6 style={{ color: 'black', fontWeight: 'bold' }}>Peak season cost</h6>
      </div>
      <a style={{fontStyle:"italic",fontWeight:"lighter",color:"gray"}} >This cost refers to the total per week. It must be cost per week + the supplement</a>
      <Form.List
        name={name}

      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key} className='byp-list-item' >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'hs_start']}
                      label='Start'
                    >
                      <Input type='date' onChange={(e) => setDateStart(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'hs_end']}
                      label='End'
                    >
                      <Input type='date' onChange={(e) => setDateFinal(e.target.value)} status={msjInput} min={dateStart} />
                    </Form.Item>
                  </Col>
                  {
                    !isTransport && (
                      <Col xs={12} md={12}>
                        <h6>
                          Cost per week
                        </h6>
                      </Col>
                    )
                  }
                  {
                    isTransport && (
                      <Col className='d-flex align-center byp-mb-1_5'>
                        <h6 className='byp-m-0'>
                          Extra cost per route
                        </h6>
                      </Col>
                    )
                  }
                  <Col xs={12} md={12} className='d-flex align-center byp-mb-1_5'>
                    <Form.Item
                      {...restField}
                      name={[name, 'hs_cost']}
                      className='byp-m-0'
                      rules={[{ required: true, message: 'The cost is required' }]}
                    >
                      <InputNumber min={0} addonBefore={prefixSelector} controls={false} />
                    </Form.Item>
                  </Col>
                  {
                    isLodging && (
                      <>
                        <Col xs={12} md={12}><h6>Cost per extra night</h6></Col>
                        <Col xs={12} md={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'hs_extra_cost']}
                          >
                            <InputNumber min={0} addonBefore={prefixSelector} controls={false } />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <h6><b>Other costs</b></h6>
                          <ConceptCostFrequencyListFormItem
                            name={[name, 'fees']}
                            divisa={divisa}
                          />
                        </Col>
                      </>
                    )
                  }
                </Row>
                <Row>
                  <AddAndRemoveSeasonBtn
                    fields={fields}
                    index={index}
                    add={add}
                    remove={remove}
                    name={name}
                  />
                </Row>
              </div>
            ))}
            {
              fields.length === 0 && (
                <Form.Item>
                  {/*<Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
              </Button>*/}
                  <a>Do you have additional costs in certain season?</a>
                  <Radio.Group defaultValue={2}>
                    <Radio value={1} style={{ marginLeft: '1rem' }} onClick={() => add()}>Yes</Radio>
                    <Radio value={2}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { HighSeasonFormList }
