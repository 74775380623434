import { Form } from 'antd'

function PrefixLabelSelector ({ divisa = '$USD' }) {
  return (
    <Form.Item name='prefix' noStyle>
      <small>{divisa}</small>
    </Form.Item>
  )
}

export { PrefixLabelSelector }
