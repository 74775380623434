import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetBasePricesByProgram (campus, category, year) {
  return useQuery(
    ['useGetBasePricesByProgram', campus, category, year],
    () => CampusRespository.getBasePrices(campus, category, year), {
      retry: 2,
      enabled: !!campus && !!category && !!year
    }
  )
}

export { useGetBasePricesByProgram }
