import { useState } from 'react'
import stateControl from '../../utils/state-control'
import { useMutation } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function usePutValidateEmail () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(AdminRepository.validateEmail, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const validateEmail = ({ company, type, status, user }) => {
    mutation.mutate({ company, company_type: type, status, user })
  }

  return { ...mutation, validateEmail, currentState, stateControl }
}

export { usePutValidateEmail }
