import { useQuery } from 'react-query'
import PlanRepository from '../../repositories/PlanRepository'

function useGetPlans (params = {}) {
  return useQuery(
    ['useGetPlans', params],
    () => PlanRepository.getPlans(params),
    { retry: 1 }
  )
}

export { useGetPlans }
