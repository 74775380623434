import { Button, Form, Input, Select } from 'antd'
import React from 'react'
import ENTERPRISE_TYPES from '../../../../../bussines/utils/enterprise-types-control'
import { EmailAsyncValidateFormItem } from '../../../../../common/components/helper-form/EmailAsyncValidateFormItem'
import { CompanyNameAsyncValidateFormItem } from '../../../../../common/components/helper-form/CompanyNameAsyncValidateFormItem'
const { Option } = Select

function MainRegisterFieldsContainer ({ type, invitation, displayNameField, displayCompaneNameField, loading }) {
  const form = Form.useFormInstance()
  return (
    <>
      {displayNameField && (
        <Form.Item
          name='user'
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          <Input size='large' placeholder='user name' />
        </Form.Item>
      )}
      {displayCompaneNameField && type !== 'User' && (
        <CompanyNameAsyncValidateFormItem name='name' />
      )}
      <EmailAsyncValidateFormItem name='email' />
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Please insert your password!'
          },
          {
            pattern:
              '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
            message:
              'The password must include a minimum of 8 characters and include at least: 1 number, one uppercase, 1 lowercase, and one special character.'
          }
        ]}
        hasFeedback
      >
        <Input.Password size='large' placeholder='Password' />
      </Form.Item>

      <Form.Item
        name='confirm'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!'
          },
          ({ getFieldValue }) => ({
            validator (_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              )
            }
          })
        ]}
      >
        <Input.Password size='large' placeholder='Confirm password' />
      </Form.Item>

      {(type !== 'User') && (
        <Form.Item
          name='type'
          label="You'll register as"
          rules={[
            {
              required: true,
              message: 'Please select a type of register!'
            }
          ]}
        >
          <Select placeholder='Select an option' size='large'>
            <Option value={ENTERPRISE_TYPES.AGENCY} disabled={invitation && type !== ENTERPRISE_TYPES.AGENCY}>Agency</Option>
            <Option value={ENTERPRISE_TYPES.INSTITUTION} disabled={invitation && type !== ENTERPRISE_TYPES.INSTITUTION}>Institution</Option>
            <Option value={ENTERPRISE_TYPES.SERVICE} disabled>Service Provider</Option>
            <Option value={ENTERPRISE_TYPES.EDUCATION_GROUP}>{ENTERPRISE_TYPES.EDUCATION_GROUP}</Option>
          </Select>
        </Form.Item>
      )}

      <Form.Item shouldUpdate style={{ marginTop: 36 }}>
        {() => (
          <Button
            size='large'
            type='primary'
            htmlType='submit'
            loading={loading}
            block
            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
          >
            Create Account
          </Button>
        )}
      </Form.Item>
    </>
  )
}

export { MainRegisterFieldsContainer }
