import api from '../../services/api'

class CurrencyRepository {
  static async all () {
    const response = await api.get('/currencies')
    return response.data
  }
}

export { CurrencyRepository }
