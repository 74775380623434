import React, { useState } from 'react'

const FloatLabel = props => {
  const [focus, setFocus] = useState(false)
  const { children, label, value, className='' } = props

  const labelClass = focus || (value?.toString().length > 0) ? 'label label-float' : 'label'

  return (
    <div
      className={'float-label ' + className}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  )
}

export default FloatLabel
