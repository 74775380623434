import React from 'react'
import { Avatar, Button, Col, Dropdown, Menu, Row } from 'antd'
import Icon, { BellFilled, UserOutlined, SendOutlined } from '@ant-design/icons'
import { MainThemeCarrousel } from '../../common/theme/partials/MainThemeCarrousel'
import { MainThemeFooter } from '../../common/theme/partials/MainThemeFooter'
import { TopUsersMyReports } from '../dashboards/agency/analitics/components/TopUsersMyReports'

const URL_NEW_PAGE = '/nueva-pagina'

const iconoCompanySVG = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-building' viewBox='0 0 16 16'>
        <path d='M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z' />
        <path d='M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z' />
    </svg>
)

const IconoCompany = (props) => <Icon component={iconoCompanySVG} {...props} />


function ButtonsDouble({ text1, text2, textInside1, textInside2 }) {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ height: '60px', width: '150px', borderColor: 'lightgrey', borderStyle: 'solid', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding: '.5vh 1vw' }}>
                <p style={{ textAlign: 'center', color: 'var(--blue-200)', fontSize: '1rem' }}>
                    {text1}
                </p>
                <p style={{ marginTop: '-15px', textAlign: 'center', fontSize: '1rem' }}>
                    <b>{textInside1}</b>
                </p>
            </div>
            <div style={{ height: '60px', width: '150px', borderColor: 'lightgrey', borderStyle: 'solid', borderTopRightRadius: '25px', borderBottomRightRadius: '25px', padding: '.5vh 1vw' }}>
                <p style={{ textAlign: 'center', color: 'var(--blue-200)', fontSize: '1rem' }}>
                    {text2}
                </p>
                <p style={{ marginTop: '-15px', textAlign: 'center', fontSize: '1rem' }}>
                    <b>{textInside2}</b>
                </p>
            </div>
        </div>
    )
}

function TextBlue({ text }) {
    return (
        <p style={{ borderColor: 'var(--blue-200)', borderWidth: '1px', border: 'solid', padding: '5px', backgroundColor: 'var(--blue-100)', color: 'white', borderRadius: '5px', textAlign: 'center' }}>{text}</p>
    )
}

function CourseDisplayed({ text1,text2, description, price }) {
    return (
        <div style={{ borderRadius: '50px', boxShadow: 'rgb(0 0 0 / 0%) 0px 1px 2px -2px, rgb(0 0 0 / 0%) 0px 3px 6px 0px, rgb(0 0 0 / 11%) -4px 6px 7px 0px' }}>
            <div style={{ backgroundColor: 'white', borderTopLeftRadius: '50px', borderTopRightRadius: '50px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingRight: '20px', paddingTop: '20px' }}>
                    <TextBlue text={'17 jun-17 ago'}></TextBlue>
                    <TextBlue text={'ACTIVE'}></TextBlue>
                </div>
                Imagen
            </div>
            <div style={{ margin: '0 20px', padding: '30px 0' }}>

                <p style={{ fontSize: '1.5rem', color: 'var(--blue-200)' }}>{text1}</p>

                <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}>{text2}</p>

                <p> <small>{description}</small></p>

                <p style={{ fontSize: '1rem' }}><b>{price} <small>TOTAL</small></b></p>

                <Button style={{ backgroundColor: 'var(--blue-100)', color: 'white', height: '40px', width: '100%', fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={<SendOutlined />}> See</Button>
                
            </div>

        </div>
    )
}

const NewPage = () => {
    return (
        <div >
            <Row style={{ width: '100%' }}>
                <Col style={{ width: '50%', paddingLeft: '4vw' }}>
                    <Button style={{ display: 'flex', alignItems: 'center', background: '#f7f7f7', borderColor: '#f7f7f7' }}>
                        <IconoCompany></IconoCompany>
                        [Name agency]
                    </Button>

                </Col>
                <Col style={{ width: '50%', paddingRight: '4vw', display: 'flex', justifyContent: 'end' }}>
                    <Button style={{ display: 'flex', alignItems: 'center', background: '#f7f7f7', borderColor: '#f7f7f7' }}>
                        <IconoCompany></IconoCompany>
                        Your quotes
                    </Button>
                </Col>
            </Row>
            <div style={{ backgroundColor: 'purple', height: '3px', paddingBottom: '5px' }}>
            </div>
            <MainThemeCarrousel></MainThemeCarrousel>

            {/*Ahora viene la sección de 1:4 */}

            <div style={{ display: 'flex', width: '100%', paddingTop:'20px' }}>
                {/* Sección 1/4 */}
                <div style={{ width: '20%', textAlign: 'center', marginLeft: '6vw', marginRight: '20px' }}>
                    <div style={{ backgroundColor: '#f7f7f7' }}>
                        <p>
                            Logo Edulinks
                        </p>
                        <p style={{ paddingTop: '30px', fontSize: '1.5rem', color: 'var(--blue-200)' }}>Pay in advance!</p>

                        <p style={{ color: 'var(--blue-200)', fontSize: '1.5rem' }}>don't loose the opportunity</p>

                        <p style={{ color: 'var(--blue-200)' }}><small>Contact your agent and get ready for your next trip</small></p>

                    </div>

                    {/* Sección del avatar */}
                    <div style={{ backgroundColor: 'white', padding: '25px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Avatar size={64} icon={<UserOutlined />} />

                        <small style={{ paddingTop: '10px' }}>Attended by</small>

                        <p style={{ fontSize: '2rem', paddingTop: '10px' }}>Agent Name</p>

                        <div>

                        </div>

                        <Button style={{ color: 'var(--blue-200)', borderColor: 'var(--blue-200)' }}>Schedule and appoinment</Button>

                    </div>

                </div>

                {/* Sección 3/4 */}
                <div style={{ width: '80%', paddingBottom:'40px' }}>
                    {/* Columna grandotototoa*/}
                    <div style={{ borderRadius: '50px', display: 'flex', boxShadow: 'rgb(0 0 0 / 0%) 0px 1px 2px -2px, rgb(0 0 0 / 0%) 0px 3px 6px 0px, rgb(0 0 0 / 11%) -4px 6px 7px 0px' }}>
                        <div style={{ width: '33%', backgroundColor: 'white', borderTopLeftRadius: '50px', borderBottomLeftRadius: '50px' }}>
                            Imagen
                        </div>
                        <div style={{ width: 'auto', marginLeft: '30px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '10px', gap: '370px' }}>
                                <p style={{ fontSize: '1.5rem', color: 'var(--blue-200)' }}> EC paris</p>
                                <div style={{ fontSize: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                    <TextBlue text={'17 jun -12 ago'}></TextBlue>
                                    <TextBlue text={'ACTIVE'}></TextBlue>
                                </div>
                            </div>

                            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}>Intensive course EF</p>

                            <p style={{ fontSize: '1.125rem', color: 'var(--blue-100)' }}>24 weeks</p>

                            <p> <small>Palabras muy interesantosas y chiquitas</small></p>

                            <p style={{ fontSize: '1.25rem', color: 'var(--blue-100)' }}>$15900 <small>TOTAL</small></p>

                            <div style={{ display: 'flex', gap: '1vw', margin: '0 1vw', paddingBottom: '20px' }}>
                                <ButtonsDouble text1={'Arrive'} text2={'Departure'} textInside1={'Jun 17,2023'} textInside2={'Sept 25,2023'} > </ButtonsDouble>
                                <ButtonsDouble text1={'Start classes'} text2={'End classes'} textInside1={'Jun 17,2023'} textInside2={'Sept 25,2023'} > </ButtonsDouble>
                            </div>
                            <div style={{ height: '3px', backgroundColor: 'lightgray' }}>
                            </div>
                            <div style={{ padding: '15px 0', display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
                                <Button style={{ backgroundColor: '#FFCC00', height: '50px', width: '200px', fontSize: '1.25rem', color: 'var(--blue-200)' }}>Apply</Button>
                                <Button style={{ backgroundColor: 'var(--blue-100)', color: 'white', height: '50px', width: '200px', fontSize: '1.25rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} icon={<SendOutlined />}> See</Button>

                            </div>
                        </div>
                    </div>

                    {/* Sección de las Columnitas */}
                    <div style={{ width: '100%', display: 'flex', paddingTop: '20px' }}>
                        <div style={{ width: '33%' }}>
                            <CourseDisplayed text1={'EC Paris'} text2={'Intensive Course EF'} description={'Small words very interesting'} price={'15800'}></CourseDisplayed>
                        </div>
                        <div style={{ width: '33%' }}>
                        <CourseDisplayed text1={'EC Paris'} text2={'Intensive Course EF'} description={'Small words very interesting'} price={'15800'}></CourseDisplayed>
                        </div>
                        <div style={{ width: '33%' }}>
                        <CourseDisplayed text1={'EC Paris'} text2={'Intensive Course EF'} description={'Small words very interesting'} price={'15800'}></CourseDisplayed>
                        </div>
                    </div>

                </div>
                <div>

                </div>                
            </div>

            <MainThemeFooter></MainThemeFooter>
            <TopUsersMyReports></TopUsersMyReports>
        </div>

    )
}

export { NewPage, URL_NEW_PAGE }
