import React from 'react'
import { TitleSectionQuote } from '../../../common/components/TitleSectionQuote'
import { PublicThemeNone } from '../../../common/theme/PublicThemeNone'
import { Col, Row } from 'antd'
import { QuoteOverview } from './QuoteOverview'
import { QuoteCampus } from './QuoteCampus'
import { QuoteLocation } from './QuoteLocation'
import '../../../common/assets/css/main-theme.less'
import '../../../common/assets/css/quote-gallery.less'
import { useNavigate } from 'react-router-dom'
import SessionService from '../../../services/SessionService'
import { URL_QUOTER_PAGE } from '../../quoter'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'
import GoogleTranslateButton from '../../home/components/GoogleTranslateButton'
import { QuoteGalleryTemplateA } from './QuoteGalleryTemplateA'
import { MainThemeCarrousel, ThemeCarrousel } from '../../../common/theme/partials/MainThemeCarrousel'
import { QuoteAgent } from './QuoteAgent'
import { QuoteAgency } from './QuoteAgency'
import { QuoteInstitution } from './QuoteInstitution'

const Quote = ({ quote, preview = false }) => {
  const navegadorInfo = navigator.userAgent
  const navegador = navegadorInfo.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  const navigate = useNavigate()

  return (
    <PublicThemeNone>
      <div className='quote-previe-main' style={{}}>

        {/* ============ IMAGES SECTION ============ */}
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <TitleSectionQuote
              label={'Quote valid until ' + quote?.expiration_date}
              icon={
                <div className='quote-agency-img'>
                  <img
                    src={quote?.agency?.profilePhotoUrl ?? quote.provider.campus.logo}
                    style={{ width: '100%', height: '100%' }}
                    alt={quote.custom_name}
                  />
                </div>
              }
              extra2={
                (SessionService.existSession())
                  ? (
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <button className='quote-main-btn' style={{ marginRight: '10px' }} onClick={() => navigate(URL_QUOTER_PAGE)}>Go back</button>
                      {/* <MenuLanguages i18n={i18n} language={language} setLanguage={setLanguage} /> */}
                      {navegador[1] !== 'Safari' && <GoogleTranslateButton className='google_translate_container-quote' />}
                    </div>
                    )
                  : (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <button className='quote-main-btn' style={{ display: 'none', marginRight: '10px' }} onClick={() => navigate(-1)}>Go back</button>
                      {/* <MenuLanguages i18n={i18n} language={language} setLanguage={setLanguage} /> */}
                      {navegador[1] !== 'Safari' && <GoogleTranslateButton className='google_translate_container-quote' />}
                    </div>
                    )
              }
            />
            {quote.template === '1' && (
              <ThemeCarrousel slides={quote.sliders} urlProperty='profile_photo_url' />
            )}
            {quote.template === '2' && (
              <QuoteGalleryTemplateA gallery={quote.gallery?.sort((a, b) => a.id - b.id)} videoUrl={quote.video?.url} />
            )}
            {(!quote.template || quote.template === '3') && (
              <div style={{ borderRadius: '16px', overflow: 'hidden', marginBottom: '16px' }}>
                <MainThemeCarrousel />
              </div>
            )}
            {(quote.custom_name || quote.custom_message) && (
              <div className='quote-regards'>
                <h4 className='byp-title-4'>{quote.custom_name}</h4>
                <p className='txt-color' style={{ fontSize: '0.875rem' }}>{quote.custom_message}</p>
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16, order: 0 }}>
            <ErrorBoundary>
              <QuoteOverview
                course={quote.course} campus={quote.provider.campus} cost={quote.cost}
                campusCosts={quote.campus_costs} intake={quote.intake}
                expirationDate={quote?.expiration_date} returnDate={quote.return_date}
                arrivalDate={quote.arrival_date} quoteId={quote.uuid} preview={preview}
                agency={quote?.agency} lodging={quote.lodging} agent={quote.agent}
                discounts={quote.discounts} logo={quote.provider.logo}
                type={quote.type}
              />
            </ErrorBoundary>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8, order: 1 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <QuoteLocation campus={quote.provider.campus} />
              </Col>
              <Col span={24}>
                <QuoteCampus provider={quote.provider} />
              </Col>
            </Row>
          </Col>
        </Row>

        <br />
        <Row gutter={12}>
          <Col xs={24} md={8}>
            <QuoteAgent agent={quote.agent} className='h-100 byp-centered' />
          </Col>
          <Col xs={24} md={16}>
            {quote.agent.sede.parent?.type === 'Institution' && (
              <QuoteInstitution quote={quote} className='byp-centered h-100' />
            )}
            {quote.agent.sede.parent?.type !== 'Institution' && (
              <QuoteAgency quote={quote} className='byp-centered h-100' />
            )}
          </Col>
        </Row>
        <br />
      </div>
    </PublicThemeNone>
  )
}

export { Quote }
