import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetpopularDates (filters) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetpopularDates', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularDates(filtersQuery),
    {
      retry: 1,
      staleTime: 5000
    }
  )
}

function useGetpopularDatesByUser ({ user, office, ...filters }) {
  const allFilters = { user, office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetpopularDatesByUser', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularDates(filtersQuery),
    {
      enabled: !!user || !!office
    }
  )
}

export { useGetpopularDates, useGetpopularDatesByUser }
