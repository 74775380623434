import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetMostPopularCities ({ office, ...filters }) {
  const allFilters = { office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetMostPopularCities', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularCities(allFilters),
    {
      staleTime: 5000
    }
  )
}

function useGetMostPopularCitiesByUser ({ user, ...filters }) {
  const allFilters = { user, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetMostPopularCitiesByUser', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularCities(allFilters),
    {
      enabled: !!user
    }
  )
}

function useGetMostPopularCitiesByOffice ({ office, ...filters }) {
  const allFilters = { office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetMostPopularCitiesByOffice', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularCities(allFilters),
    {
      enabled: !!office
    }
  )
}

export { useGetMostPopularCities, useGetMostPopularCitiesByUser, useGetMostPopularCitiesByOffice }
