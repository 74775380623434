import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { AgenciesComponents } from './AgenciesComponents'
import { userRoles } from '../../bussines/settings/validate-user-access'

const URL_AGENCYS_LIST_PAGE = '/agencys-list-for-institutions'

function AgencysListPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel>
        <AgenciesComponents />
      </MainTheme>
    </PageProtected>
  )
}

export { AgencysListPage, URL_AGENCYS_LIST_PAGE }
