import { Switch } from 'antd'
import React from 'react'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'

function MinorCostsSection () {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let minorCosts = []
    if (!formConfig.minorCosts) {
      setFormConfig({
        ...formConfig,
        minorCosts: []
      })
    }
    if (value) {
      minorCosts = formConfig.minorCosts ?? []
      minorCosts?.push({ id, cost, frequency })
    } else {
      formConfig.minorCosts?.map((fee) => (
        fee.id !== id ? minorCosts.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      minorCosts
    })
  }

  return (
    <>
      {service?.getPrices()?.minor_costs?.map((item) => (
        <RowQuoter
          key={item.id}
          colA={
            <>
              <p>
                {item.name}
                <Switch
                  onChange={(value) => { handleChange(value, item.id, item.cost, item.frequency) }}
                  className='byp-ml-1'
                  checked={service.isMinorCostChecked(item.id)}
                  checkedChildren='YES'
                  unCheckedChildren='NO'
                />
              </p>
              {
                // service.getFeeDiscounts(item.id).length > 0 && (
                //   service.getFeeDiscounts(item.id)?.map((d) => (
                //     <p key={d.id} className='byp-fw-bold'>{d.name}</p>
                //   ))
                // )
              }
            </>
          }
          colB={
            <StatisticWithDiscounts
              discounts={0}
              amount={service.isMinorCostChecked(item.id)?.id ? service.calculateCost(item.cost, item.frequency) : 0}
              currency={service.getCurrency()}
              subtotal={service.calculateCost(item.cost, item.frequency)}
            />
          }
        />
      ))}
    </>
  )
}

export { MinorCostsSection }
