import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd'
// import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import FarCastle from '../../../../common/assets/images/far-castle.png'
import BlueCastle from '../../../../common/assets/images/blue-castle.png'
import AlleyFestival from '../../../../common/assets/images/alley-festival.png'
import { useGetPage, usePostSavePageCountry, usePutSavePage } from '../../../../bussines/hooks/tables-page/useGetDataTablePage'
import { API_URL } from '../../../../config'
import { getBase64, headersForUpload } from '../../../../utils/uploadImageUtils'
import { Link } from 'react-router-dom'
import { URL_TABLE_COUNTRY_CITIES_PAGE } from '../../tables-pages/TableCountryCitiesPage'
import { EditIcon } from '../../../quote-preview/assets/imgs/Icons'
// import { IconSchool } from '../../../../common/assets/svg/icons'
// import { CardWithImage } from '../../../home/components/CardWithImage'
// import { RecordVoiceOverIcon } from '../../../quote-preview/assets/imgs/Icons'
// import { LanguageCard } from '../../../home/components/languageCard'

const { TextArea } = Input

function EditPageCountryInfo ({ code, city }) {
  const [statusPage, setStatusPage] = useState('Deleted')
  const [pageId, setPageId] = useState(null)
  const [modal, contextHolder] = Modal.useModal()
  const { savePageCountry, isLoading: isLoadingSave, error, data } = usePostSavePageCountry()
  const { updatePage, isLoading: isLoadingUpdate, error: errorUpdate, data: dataUpdate } = usePutSavePage()
  const { data: pageData, refetch } = useGetPage({ code, city_id: city })
  const [image01, setImage01] = useState(null)
  const [image02, setImage02] = useState(null)
  const [image03, setImage03] = useState(null)
  const [image01Url, setImage01Url] = useState(null)
  const [image02Url, setImage02Url] = useState(null)
  const [image03Url, setImage03Url] = useState(null)

  const [form] = Form.useForm()
  const onFinish = (values) => {
    const images = []
    if (image01Url) {
      images.push(image01Url)
    }
    if (image02Url) {
      images.push(image02Url)
    }
    if (image03Url) {
      images.push(image03Url)
    }

    if (!pageId) {
      savePageCountry({
        body: values.description,
        name: values.title,
        city_id: city,
        code,
        status: statusPage,
        images
      })
    } else if (pageId) {
      updatePage({
        body: values.description,
        name: values.title,
        city_id: city,
        code,
        status: statusPage,
        page: pageId,
        images
      })
    }
    refetch()
  }

  useEffect(() => {
    if (!isLoadingSave && data) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
      refetch()
    }
    if (!isLoadingSave && error) {
      modal.error({
        title: 'Something went wrong',
        content: error.response?.data?.message
      })
    }
  }, [isLoadingSave, data, error])

  useEffect(() => {
    if (!isLoadingUpdate && dataUpdate) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
      refetch()
    }
    if (!isLoadingUpdate && errorUpdate) {
      modal.error({
        title: 'Something went wrong',
        content: errorUpdate.response?.data?.message
      })
    }
  }, [isLoadingUpdate, dataUpdate, errorUpdate])

  useEffect(() => {
    if (pageData?.page) {
      form.setFieldsValue({
        title: pageData?.page?.title ? pageData?.page?.title : 'Lorem Ipsum',
        description: pageData?.page?.body ? pageData?.page?.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at tellus et massa rutrum hendrerit. Quisque ut venenatis dolor, interdum facilisis massa. Quisque eros ipsum, varius eget turpis id, elementum tincidunt ipsum. Integer vehicula pellentesque sagittis. Praesent dictum viverra nibh et suscipit. Fusce commodo aliquam dui eget placerat. Nam a ligula id lacus sagittis pellentesque. Pellentesque quis facilisis turpis, vel tincidunt elit. Morbi nec lectus at leo volutpat ultrices. Praesent vehicula diam at lorem egestas pharetra. In congue dolor sed diam hendrerit suscipit. Curabitur vitae ipsum iaculis dolor eleifend dapibus.'
      })
      if (pageData?.page?.images) {
        const images = pageData?.page?.images.reverse()
        if (images[0]) {
          setImage01(images[0].profile_photo_url)
          setImage01Url(images[0].url)
        }
        if (images[1]) {
          setImage02(images[1].profile_photo_url)
          setImage02Url(images[1].url)
        }
        if (images[2]) {
          setImage03(images[2].profile_photo_url)
          setImage03Url(images[2].url)
        }
      }
      setPageId(pageData?.page?.id)
    } else {
      form.setFieldsValue({
        title: 'Lorem Ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at tellus et massa rutrum hendrerit. Quisque ut venenatis dolor, interdum facilisis massa. Quisque eros ipsum, varius eget turpis id, elementum tincidunt ipsum. Integer vehicula pellentesque sagittis. Praesent dictum viverra nibh et suscipit. Fusce commodo aliquam dui eget placerat. Nam a ligula id lacus sagittis pellentesque. Pellentesque quis facilisis turpis, vel tincidunt elit. Morbi nec lectus at leo volutpat ultrices. Praesent vehicula diam at lorem egestas pharetra. In congue dolor sed diam hendrerit suscipit. Curabitur vitae ipsum iaculis dolor eleifend dapibus.'
      })
      setPageId(null)
      setImage01(null)
      setImage01Url(null)
      setImage02(null)
      setImage02Url(null)
      setImage03(null)
      setImage03Url(null)
    }
  }, [pageData])

  const handleChange = (event, index) => {
    if (event.file.status === 'done') {
      getBase64(event.file.originFileObj, (imageUrl) => {
        if (index === 1) {
          setImage01(imageUrl)
          setImage01Url(event.file.response.url)
        }
        if (index === 2) {
          setImage02(imageUrl)
          setImage02Url(event.file.response.url)
        }
        if (index === 3) {
          setImage03(imageUrl)
          setImage03Url(event.file.response.url)
        }
      })
    } else if (event.file.status === 'error') {
      console.log('🚀 ~ file error:', event.file.response)
    }
  }

  return (
    <Form
      style={{ width: '100%' }}
      onFinish={onFinish}
      form={form}
    >
      {contextHolder}
      <div className='byp-mb-1'>
        <Link to={URL_TABLE_COUNTRY_CITIES_PAGE}>
          <Button>Regresar</Button>
        </Link>
      </div>
      <Row gutter={[0, 37]}>
        <Col span={24}>
          <Row justify='space-between'>
            <Col><h3 className='byp-title'>Editar País</h3></Col>
            <Col className='edit__buttons'>
              <Button onClick={() => setStatusPage('Deleted')} htmlType='submit'>Guardar</Button>
              <Button onClick={() => setStatusPage('Published')} htmlType='submit'>Publicar</Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row>
            <Col className='edit-page__info'>Subir información y fotos</Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row justify='center'>
            <Col span={18} className='edit-page__form'>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <label>Titulo:</label>
                  <Form.Item
                    name='title'
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <label>Contenido:</label>
                  <Form.Item name='description'>
                    <TextArea rows={15} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <label htmlFor='image01__page' className='icon-edit__page' style={{ width: '100%', height: 'auto', borderRadius: '12px' }}>
                    {image01 ? <img src={image01} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={BlueCastle} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />}
                    <div className='icon'>
                      <EditIcon />
                    </div>
                  </label>
                  <div style={{ display: 'none' }}>
                    <Upload
                      name='logo'
                      listType='picture-card'
                      className='upload-page__img'
                      showUploadList={false}
                      action={`${API_URL}/pages/images`}
                      id='image01__page'
                      onChange={event => handleChange(event, 1)}
                      headers={headersForUpload()}
                    >
                      {image01 ? <img src={image01} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={BlueCastle} alt='avatar' style={{ width: '100%' }} />}
                    </Upload>
                  </div>

                  <Row style={{ justifyContent: 'space-between', gap: '0.5rem' }}>
                    <label htmlFor='image02__page' className='icon-edit__page' style={{ width: '49%', height: '12rem', borderRadius: '12px', overflow: 'hidden' }}>
                      {image02 ? <img src={image02} alt='avatar' style={{ height: '12rem', borderRadius: '12px' }} /> : <img src={FarCastle} style={{ height: '12rem', borderRadius: '12px' }} />}
                      <div className='icon'>
                        <EditIcon />
                      </div>
                    </label>
                    <div style={{ display: 'none' }}>
                      <Upload
                        name='logo'
                        listType='picture-card'
                        className='upload-page__img'
                        showUploadList={false}
                        action={`${API_URL}/pages/images`}
                        id='image02__page'
                        onChange={event => handleChange(event, 2)}
                        headers={headersForUpload()}
                      >
                        {image02 ? <img src={image02} alt='avatar' style={{ height: '12rem', borderRadius: '12px' }} /> : <img src={FarCastle} style={{ width: '50%', height: '12rem', borderRadius: '12px' }} />}
                      </Upload>
                    </div>

                    <label htmlFor='image03__page' className='icon-edit__page' style={{ width: '49%', height: '12rem', borderRadius: '12px', overflow: 'hidden' }}>
                      {image03 ? <img src={image03} alt='avatar' style={{ height: '12rem', borderRadius: '12px' }} /> : <img src={AlleyFestival} style={{ height: '12rem', borderRadius: '12px' }} />}
                      <div className='icon'>
                        <EditIcon />
                      </div>
                    </label>
                    <div style={{ display: 'none' }}>
                      <Upload
                        name='logo'
                        listType='picture-card'
                        className='upload-page__img'
                        showUploadList={false}
                        action={`${API_URL}/pages/images`}
                        id='image03__page'
                        onChange={event => handleChange(event, 3)}
                        headers={headersForUpload()}
                      >
                        {image03 ? <img src={image03} alt='avatar' style={{ width: '100%', height: 'auto', borderRadius: '12px' }} /> : <img src={FarCastle} style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />}
                      </Upload>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* <Col span={24}>
          <Row justify='center'>
            <Col span={23}>
              <h4 style={{ color: '#373737', marginTop: '10px' }} className='title-with-icon'>
                <IconSchool color='#373737' width='20px' height='20px' />Nuestra recomendación de cursos
              </h4>
              <p style={{ color: '#797979' }}>Descubre el mundo estudiando</p>
              <Carousel
                autoplay={false}
                effect='fade'
                dots={false}
                slidesPerRow={4}
                nextArrow={
                  <Button className='carrousel-bottoms'>
                    <RightCircleFilled style={{ fontSize: '35px', color: ' #373737' }} />
                  </Button>
                }
                prevArrow={
                  <Button className='carrousel-bottoms'>
                    <LeftCircleFilled style={{ fontSize: '35px', color: ' #373737' }} />
                  </Button>
                }
                arrows
              >
                <div>
                  <div style={{ padding: '1rem', cursor: 'pointer' }}>
                    <CardWithImage />
                  </div>
                </div>
                <div>
                  <div style={{ padding: '1rem', cursor: 'pointer' }}>
                    <CardWithImage />
                  </div>
                </div>
                <div>
                  <div style={{ padding: '1rem', cursor: 'pointer' }}>
                    <CardWithImage />
                  </div>
                </div>
                <div>
                  <div style={{ padding: '1rem', cursor: 'pointer' }}>
                    <CardWithImage />
                  </div>
                </div>
                <div>
                  <div style={{ padding: '1rem', cursor: 'pointer' }}>
                    <CardWithImage />
                  </div>
                </div>
              </Carousel>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row justify='center'>
            <Col span={23}>
              <h4 style={{ color: '#373737' }} className='title-with-icon'><RecordVoiceOverIcon style={{ fontSize: '20px' }} />Blog</h4>
              <p style={{ color: '#797979' }}>Conoce más del mundo</p>

              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
                <LanguageCard />
                <LanguageCard />
                <LanguageCard />
              </div>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Form>
  )
}

export { EditPageCountryInfo }
