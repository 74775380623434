import { useQuery } from 'react-query'
import { PlaceRepository } from '../../repositories/PlaceRepository'

function useGetAllCities (params) {
  return useQuery(['useGetCampusByPlaceId', params],
    () => PlaceRepository.allCities(params), {
      retry: 2
    })
}

export { useGetAllCities }
