import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import Form from 'antd/lib/form/Form'
import stateControl from '../../../../../bussines/utils/state-control'
import { FullPageSpinner } from '../../../../../common/components/FullPageSpinner'
import { useProfileEnterprise } from '../../../../../providers/enterprise/profile-enterprise-provider'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { EducationGroupProfileFieldsContainer } from '../../steps/education-group-profile/EducationGroupProfileFieldsContainer'
import LottieCargando from '../../../../../lotties/LottieCargando'

let readOnly = true

function EditGroupEducationProfile () {
  const [modal, context] = Modal.useModal()
  const {
    form,
    loadingProfile,
    initValues,
    isLoadingUpdateEnterprise,
    currentStateUpdateEnterprise,
    openNotification,
    updateEnterprise,
    handleHomePage,
    refetch,
    errorUpdateCompanyProfile
  } = useProfileEnterprise()

  useEffect(() => {
    if (!isLoadingUpdateEnterprise && currentStateUpdateEnterprise === stateControl.STATE_SUCCESS) {
      openNotification('The profile has been updated')
      refetch()
    } else if (!isLoadingUpdateEnterprise && errorUpdateCompanyProfile) {
      modal.error({
        title: 'Ups!',
        content: errorUpdateCompanyProfile.response?.data?.message
      })
    }
  }, [isLoadingUpdateEnterprise])

  useEffect(() => {
    if (initValues) {
      form.setFieldsValue(initValues)
    }
  }, [initValues])

  const onFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    updateEnterprise(values)
  }

  const { user } = useAuth()
  useEffect(() => {
    // const roles = user?.role_names ?? []
    // if (roles.includes('agency-admin') || roles?.includes('agency-editor')) {
    readOnly = false
    // }
    // if (roles.includes('agency-office-manager')) {
    //   readOnly = true
    // }
  }, [user])

  if (!initValues) {
    return <LottieCargando />
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      footer={
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button
            onClick={handleHomePage}
            className='byp-link'
          >
            Back
          </Button>
          <Button
            className='byp-btn-blue-200'
            size='large'
            onClick={() => form.submit()}
            loading={loadingProfile || isLoadingUpdateEnterprise}
            disabled={loadingProfile}
          > Update
          </Button>
        </div>
      }
    >
      <div style={{ margin: '18px 5%' }}>
        {context}
        <div style={{ margin: '50px 0px' }}>
          <h3>Edit your group profile</h3>
        </div>
        <p style={{ marginBottom: '50px' }}>
          Let's get started...
        </p>
        <Form
          form={form}
          layout='vertical'
          scrollToFirstError
          name='UpdateAgency'
          onFinish={onFinish}
          initialValues={initValues}
          requiredMark={false}
        >
          <EducationGroupProfileFieldsContainer form={form} />
        </Form>
      </div>
    </StepBaseTemplate>
  )
}

export { EditGroupEducationProfile }
