import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'

const ButtonCategorySelector = ({ collection = [], onChange = null, selected, setIsActive, isVisible = true }) => {
  const [select, setSelect] = React.useState('')
  const handleOnSelect = (k) => {
    setSelect(k)
    if (onChange) {
      onChange(k)
    }
  }

  const getButtons = (item, key) => {
    if (item.key === 'graduate') {
      return (
        <React.Fragment key={key}>
          {/* <Dropdown
            overlay={dilpomaItems}
            placement='bottomLeft'
            className='byp-btn-category byp-btn-category-m'
            arrow
          >
            <Button
              ghost={!types.includes(select) && ((select === '' && !types.includes(selected)) || select !== '')}
            >
              Certificate
              <DownOutlined />
            </Button>
          </Dropdown> */}
          <Button
            onClick={() => {
              handleOnSelect('graduate_diploma')
            }}
            className='byp-btn-category'
            ghost={select !== 'graduate_diploma' && ((select === '' && selected !== 'graduate_diploma') || select !== '')}
            style={{ marginLeft: 8, marginBottom: 8 }}
          >
            Diploma
          </Button>
          <Button
            onClick={() => {
              handleOnSelect('bachelor')
            }}
            className='byp-btn-category'
            ghost={select !== 'bachelor' && ((select === '' && selected !== 'bachelor') || select !== '')}
            style={{ marginLeft: 8, marginBottom: 8 }}
          >
            Bachelor Degree
          </Button>
          <Button
            onClick={() => {
              handleOnSelect('postgraduate')
            }}
            className='byp-btn-category'
            ghost={select !== 'postgraduate' && ((select === '' && selected !== 'postgraduate') || select !== '')}
            style={{ marginLeft: 8, marginBottom: 8 }}
          >
            Postgraduate Degree
          </Button>
          <Button
            onClick={() => {
              handleOnSelect('master')
            }}
            className='byp-btn-category'
            ghost={select !== 'master' && ((select === '' && selected !== 'master') || select !== '')}
            style={{ marginLeft: 8, marginBottom: 8 }}
          >
            Master Degree
          </Button>
        </React.Fragment>
      )
    }
    return (
      <Button
        onClick={() => {
          handleOnSelect(item.key)
        }}
        className='byp-btn-category'
        ghost={select !== item.key && ((select === '' && selected !== item.key) || select !== '')}
        key={key}
        style={{ marginLeft: 8, marginBottom: 8 }}
      >
        {item.key === 'graduate' ? 'Diploma' : item.label}
      </Button>
    )
  }

  return (
    <>
      {collection.map((item, key) => { return getButtons(item, key) })}
      {isVisible &&
        <Button
          onClick={() => {
            setIsActive(true)
            setSelect('search')
          }}
          className='byp-btn-category'
          ghost={select !== 'search' && ((select === '' && selected !== 'search') || select !== '')}
          style={{ marginLeft: 8, marginBottom: 8 }}
          icon={<SearchOutlined />}
        />}
    </>
  )
}

export { ButtonCategorySelector }
