import { Button, Col, Form, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetPage, usePostSavePageProgram, usePutSavePage } from '../../../../bussines/hooks/tables-page/useGetDataTablePage'
import { Link } from 'react-router-dom'
import { URL_TABLE_PROGRAMS_PAGE } from '../../tables-pages/TableProgramsPage'

const { TextArea } = Input

function EditPageProgramInfo ({ program }) {
  const [statusPage, setStatusPage] = useState('Deleted')
  const [pageId, setPageId] = useState(null)
  const [modal, contextHolder] = Modal.useModal()
  const { savePageProgram, isLoading: isLoadingSave, error, data } = usePostSavePageProgram()
  const { updatePage, isLoading: isLoadingUpdate, error: errorUpdate, data: dataUpdate } = usePutSavePage()
  const { data: pageData, refetch } = useGetPage({ program })

  const [form] = Form.useForm()
  const onFinish = (values) => {
    if (!pageId) {
      savePageProgram({
        body: values.description,
        name: values.title,
        program,
        status: statusPage
      })
    } else if (pageId) {
      updatePage({
        body: values.description,
        name: values.title,
        program,
        status: statusPage,
        page: pageId
      })
    }
    refetch()
  }

  useEffect(() => {
    if (!isLoadingSave && data) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
    }
    if (!isLoadingSave && error) {
      modal.error({
        title: 'Something went wrong',
        content: error.response?.data?.message
      })
    }
  }, [isLoadingSave, data, error])

  useEffect(() => {
    if (!isLoadingUpdate && dataUpdate) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
    }
    if (!isLoadingUpdate && errorUpdate) {
      modal.error({
        title: 'Something went wrong',
        content: errorUpdate.response?.data?.message
      })
    }
  }, [isLoadingUpdate, dataUpdate, errorUpdate])

  useEffect(() => {
    if (pageData?.page) {
      form.setFieldsValue({
        title: pageData?.page?.title ? pageData?.page?.title : 'Lorem Ipsum',
        description: pageData?.page?.body ? pageData?.page?.body : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at tellus et massa rutrum hendrerit. Quisque ut venenatis dolor, interdum facilisis massa. Quisque eros ipsum, varius eget turpis id, elementum tincidunt ipsum. Integer vehicula pellentesque sagittis. Praesent dictum viverra nibh et suscipit. Fusce commodo aliquam dui eget placerat. Nam a ligula id lacus sagittis pellentesque. Pellentesque quis facilisis turpis, vel tincidunt elit. Morbi nec lectus at leo volutpat ultrices. Praesent vehicula diam at lorem egestas pharetra. In congue dolor sed diam hendrerit suscipit. Curabitur vitae ipsum iaculis dolor eleifend dapibus.'
      })
      setPageId(pageData?.page?.id)
    } else {
      form.setFieldsValue({
        title: 'Lorem Ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at tellus et massa rutrum hendrerit. Quisque ut venenatis dolor, interdum facilisis massa. Quisque eros ipsum, varius eget turpis id, elementum tincidunt ipsum. Integer vehicula pellentesque sagittis. Praesent dictum viverra nibh et suscipit. Fusce commodo aliquam dui eget placerat. Nam a ligula id lacus sagittis pellentesque. Pellentesque quis facilisis turpis, vel tincidunt elit. Morbi nec lectus at leo volutpat ultrices. Praesent vehicula diam at lorem egestas pharetra. In congue dolor sed diam hendrerit suscipit. Curabitur vitae ipsum iaculis dolor eleifend dapibus.'
      })
      setPageId(null)
    }
  }, [pageData])

  return (
    <Form
      style={{ width: '100%' }}
      onFinish={onFinish}
      form={form}
    >
      {contextHolder}
      <div className='byp-mb-1'>
        <Link to={URL_TABLE_PROGRAMS_PAGE}>
          <Button>Regresar</Button>
        </Link>
      </div>
      <Row gutter={[0, 37]}>
        <Col span={24}>
          <Row justify='space-between'>
            <Col><h3 className='byp-title'>Editar Programa</h3></Col>
            <Col className='edit__buttons'>
              <Button onClick={() => setStatusPage('Deleted')} htmlType='submit'>Guardar</Button>
              <Button onClick={() => setStatusPage('Published')} htmlType='submit'>Publicar</Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row>
            <Col className='edit-page__info'>Subir información</Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row justify='center'>
            <Col span={18} className='edit-page__form'>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label>Titulo:</label>
                  <Form.Item
                    name='title'
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <label>Contenido:</label>
                  <Form.Item name='description'>
                    <TextArea rows={15} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export { EditPageProgramInfo }
