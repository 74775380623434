import { useState } from 'react'
import state from '../../utils/state-control'
import { useMutation } from 'react-query'
import EducationGroupRepository from '../../repositories/EducationGroupRepository'

function useUpdateEducationGroupProfile () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(EducationGroupRepository.update, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const update = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, state, currentState, update }
}

export { useUpdateEducationGroupProfile }
