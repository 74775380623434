import React from 'react'
// import { useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { SearchProvider } from '../../../../providers/search/search-context-provider'
import { TableShortCategory } from '../Tables/TableShortCategoryAgency'

const URL_ACTIVATE_SHORT_AGENCY_PAGE = '/dashboard/institution/short_term_course'

function ActivateQuotesShortPage () {
  // const [params] = useSearchParams()
  // const category = params.get('program') ?? 'language'
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <SearchProvider>
            <TableShortCategory />
            {/* // category={category} */}
            {/* // /> */}
          </SearchProvider>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { ActivateQuotesShortPage, URL_ACTIVATE_SHORT_AGENCY_PAGE }
