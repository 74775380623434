import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { PageProtected } from '../../../../common/components/PageProtected'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { AgenciesAnaliticsDisplay } from './AgenciesAnaliticsDisplay'

const URL_USER_AGENCIES_PAGE = '/dashboard/analitics'

function AgenciesAnaliticsPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
      isInBetaState
    >
      <MainTheme
        showMenu
      >
        <AgenciesAnaliticsDisplay />
      </MainTheme>
    </PageProtected>
  )
}

export { AgenciesAnaliticsPage, URL_USER_AGENCIES_PAGE }
