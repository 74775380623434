import { Button, Col, Row } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { TextInsideButton } from '../../../../common/components/TextInsideButton'

function AddAndRemoveListTextButtons({ fields, index, add, remove, name, addTextAñadir }) {
    if (fields.length > 1 && index < (fields.length - 1)) {
      return (
        <div>
        <Col span={2}>
          <Button
            className='byp-btn-gray-100'
            onClick={() => remove(name)}
          >

            <MinusOutlined /> Delete rank
          </Button>
        </Col>
        </div>
      )
    }
    else {
      return (
        <div>
          <Row gutter={160}>
          <Col span={2}>
            <Button
              className='byp-btn-gray-100'
              onClick={() => remove(name)}
            >
              <MinusOutlined /> Delete rank
            </Button>
          </Col>
          <Col span={2}>
            <Button
              className='byp-btn-blue-100'
              onClick={() => add()}
            >
              <PlusOutlined /> {addTextAñadir}
            </Button>
          </Col>
          </Row>
        </div>
      )
    }
  }

export { AddAndRemoveListTextButtons }
