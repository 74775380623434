import { useMutation } from 'react-query'
import { useState } from 'react'
import state from '../../../utils/state-control'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useUpdateDiscount () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)

  const mutation = useMutation(DiscountRepository.update, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const updateDiscount = (args, id) => {
    args.type = 'update'
    mutation.mutate({ data: args, id })
  }

  return { ...mutation, state, currentState, updateDiscount }
}

function useUpdateStatusDiscount () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)

  const mutation = useMutation(DiscountRepository.updateStatus, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const updateDiscount = (args, id) => {
    mutation.mutate({ data: args, id })
  }

  return { ...mutation, state, currentState, updateDiscount }
}

export { useUpdateDiscount, useUpdateStatusDiscount }
