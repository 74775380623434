import { useQuery } from 'react-query'
import ProgramRepository from '../../repositories/ProgramRepository'

function useFindProgram (program) {
  return useQuery(['useFindProgram', program], () => ProgramRepository.find(program), {
    retry: 2,
    enabled: !!program
  })
}

export { useFindProgram }
