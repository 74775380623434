import { useQuery } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function useGetAllCompanies (filters = {}) {
  return useQuery(
    ['useGetAllCompanies', filters],
    () => AdminRepository.allCompanies(filters),
    {
      retry: 1
    }
  )
}

function useGetAllCompaniesNames (filters = {}) {
  return useQuery(
    ['useGetAllCompaniesNames', filters],
    () => AdminRepository.allCompaniesNames(filters),
    {
      retry: 1
    }
  )
}

export { useGetAllCompanies, useGetAllCompaniesNames }
