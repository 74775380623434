import React from 'react'
import { ErrorBoundary } from '../../../../providers/validation/ErrorBoundary'
import { ApplicationsQuotesDataContainer } from './ApplicationsQuotesDataContainer'
import { ApplicationsQuotesDataContainerReports } from './ApplicationsQuotesDataContainerReports'
import { CreatedQuotesDataContainer } from './CreatedQuotesDataContainer'
import { CreatedQuotesDataContainerReports } from './CreatedQuotesDataContainerReports'
import { SendsQuotesDataContainer } from './SendsQuotesDataContainer'
import { SendsQuotesDataContainerReports } from './SendsQuotesDataContainerReports'
import { SeenQuotesDataContainerReports } from './SeenQuotesDataContainerReports'
import { NotSeenQuotesDataContainerReports } from './NotSeenQuotesDataContainerReports'

const TableTotalQuote = ({ activeCategory, user, office, time, setOrderby, orderby, isReports, countries, cities, program, campus, filters }) => {
  return (
    <ErrorBoundary>
      <div className='table-total-quote-responsive'>
        <table className='table-total-quote'>
          <thead>
            <tr>
              {getColumns(activeCategory, isReports)?.map(category =>
                (category.key === 'created_at' || category.key === 'date_of_send' || category.key === 'date_of_application')
                  ? (<th key={category.key}><span>{category.label}</span></th>)
                  : (<th key={category.key} onClick={() => { if (setOrderby) setOrderby(category.key) }} style={{ width: category?.widthColumn }}><span style={{ cursor: 'pointer' }}>{category.label}</span></th>))}
            </tr>
          </thead>
          <tbody>
            {getDataSource(activeCategory, user, office, time, orderby, countries, cities, program, campus, isReports, filters)}
          </tbody>
        </table>
      </div>
    </ErrorBoundary>
  )
}

const getColumns = (category, isReports) => {
  let columns = []
  if (category === 'created' || category === 'seen' || category === 'notseen') {
    columns = [
      { label: 'Date of creation', key: 'created_at' },
      { label: 'Program', key: 'program' },
      { label: 'School', key: 'school' },
      { label: 'Course', key: 'course' },
      { label: 'ID client', key: 'student_id' },
      { label: 'View', key: 'view' },
      { label: 'Client email', key: 'client_email' }
    ]
  }
  if (category === 'sended') {
    columns = [
      { label: 'Date of send', key: 'date_of_send' },
      { label: 'Program', key: 'program' },
      { label: 'School', key: 'school' },
      { label: 'Course', key: 'course' },
      { label: 'ID client', key: 'student_id' },
      { label: 'Client email', key: 'client_email' }
    ]
  }
  if (category === 'applications') {
    columns = [
      { label: 'Date of application', key: 'date_of_application' },
      { label: 'Program', key: 'program' },
      { label: 'School', key: 'school' },
      { label: 'Course', key: 'course' },
      { label: 'Client', key: 'student_id', widthColumn: '7.5%' },
      { label: 'Client email', key: 'client_email' },
      { label: 'Client Phone', key: 'client_phone' }
    ]
  }
  if (isReports && (category === 'created' || category === 'sended' || category === 'applications' || category === 'seen' || category === 'notseen')) {
    columns.splice(2, 1)
    const newColumns = [
      { label: 'Office', key: 'office' },
      { label: 'User', key: 'user' },
      { label: 'Institution', key: 'institution' },
      { label: 'School', key: 'school' }
    ]
    columns.splice(1, 0, ...newColumns)
  }
  return columns
}

const getDataSource = (category, user, office, time, orderby, countries, cities, program, campus, isReports, filters) => {
  if (category === 'created' && !isReports) {
    return <CreatedQuotesDataContainer user={user} office={office} time={time} orderby={orderby} countries={countries} cities={cities} program={program} campus={campus} isReports={isReports} />
  }
  if (category === 'sended' && !isReports) {
    return <SendsQuotesDataContainer user={user} office={office} time={time} orderby={orderby} countries={countries} cities={cities} program={program} campus={campus} isReports={isReports} />
  }
  if (category === 'applications' && !isReports) {
    return <ApplicationsQuotesDataContainer user={user} office={office} time={time} orderby={orderby} countries={countries} cities={cities} program={program} campus={campus} isReports={isReports} />
  }
  if (category === 'created' && isReports) {
    return <CreatedQuotesDataContainerReports filters={filters} isReports />
  }
  if (category === 'seen' && isReports) {
    return <SeenQuotesDataContainerReports filters={filters} isReports />
  }
  if (category === 'notseen' && isReports) {
    return <NotSeenQuotesDataContainerReports filters={filters} isReports={isReports} />
  }
  if (category === 'sended' && isReports) {
    return <SendsQuotesDataContainerReports filters={filters} isReports />
  }
  if (category === 'applications' && isReports) {
    return <ApplicationsQuotesDataContainerReports filters={filters} isReports />
  }
  return []
}

export { TableTotalQuote }
