import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { QuoteInfoContainer } from './QuoteInfoContainer'

const URL_APPROVE_QUOTES_PAGE = '/dashboard/institution/quotes/approve'

function ApproveQuotesPage () {
  const [params] = useSearchParams()
  const category = params.get('program') ?? 'language'
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <QuoteInfoContainer
            category={category}
          />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { ApproveQuotesPage, URL_APPROVE_QUOTES_PAGE }
