import { useQuery } from 'react-query'
import CampusRespository from '../../../repositories/CampusRespository'

function useGetCampusCourses (campus, filters = {}) {
  return useQuery(['useGetCampusCourses', campus, filters],
    () => CampusRespository.courses(campus, filters), {
      retry: 2,
      enabled: !!campus
    })
}

export { useGetCampusCourses }
