import { Button, Col, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react'
import utilsState from '../../../../bussines/utils/state-control'
import { useFindLodging } from '../../../../bussines/hooks/school/lodging/useFindLodging'
import { usePostSaveTransport } from '../../../../bussines/hooks/school/transport/usePostSaveTransport'
import { useGetTransports } from '../../../../bussines/hooks/school/transport/useGetTransports'
import { TableTransports } from '../Tables/TableTransports'
import { usePostUpdateLodging } from '../../../../bussines/hooks/school/lodging/usePostUpdateLodging'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { useDeleteFee } from '../../../../bussines/hooks/fee/useDeleteFee'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { URL_TRANSPORTGPRICES_CREATE_PAGE } from '../TransportPrices/CreateTransportPricesPage'
import { useNavigate } from 'react-router-dom'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

function FormCreateTransport ({ program }) {
  const [form] = Form.useForm()
  const [transportID, setTransportID] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [page, setPage] = useState(1)

  const { data: transport, isLoading: isLoadingTransport } = useFindLodging(transportID)
  const { saveTransport, isLoading: isSaving, currentState: saveStatus } = usePostSaveTransport()
  const { isLoading, data: transportList, refetch, isFetching } = useGetTransports(program && { program, page })
  const { updateLodging: updateTransport, isLoading: isUpdating, currentState } = usePostUpdateLodging()
  const { deleteFee, isLoading: isDeleting, currentState: deleteState } = useDeleteFee()

  const navigate = useNavigate()

  const onContinue = (values) => {
    navigate(URL_TRANSPORTGPRICES_CREATE_PAGE.replace(':program', program), { state: { menuItemSelected: program + '_transports_prices', openSub: program, openSub2: program + '_transports' } })
  }

  const onFinish = (values) => {
    if (isUpdateMode) {
      updateTransport(transportID, values)
    } else {
      saveTransport(values)
    }
    onContinue();
  }

  const onDelete = (id) => {
    deleteFee(id)
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const translations = transport?.translations ?? []
      const langTranslations = translations.filter(translation => translation.to === lang)
      // Set the new values
      const nameTraslated = langTranslations.find(translation => translation.attribute === 'name')
      // const type = langTranslations.find(translation => translation.attribute === 'transport_type')
      // const descTraslated = langTranslations.find(translation => translation.attribute === 'description')
      form.setFieldsValue({
        name: nameTraslated?.translation
        // type: type?.translation
      })
    } else {
      transport.translation = null
      form.setFieldsValue(transport)
    }
  }

  useEffect(() => {
    if (!isSaving && saveStatus === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [isSaving])

  useEffect(() => {
    if (!isLoadingTransport && transport) {
      transport.translation = null
      form.setFieldsValue(transport)
      setIsUpdateMode(true)
    }
  }, [isLoadingTransport, transport])

  useEffect(() => {
    if (!isUpdating && currentState === utilsState.STATE_SUCCESS) {
      form.resetFields()
      refetch()
      setIsUpdateMode(false)
      setTransportID()
    }
  }, [isUpdating])

  useEffect(() => {
    if (!isDeleting && deleteState === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [isDeleting])

  return (
    <Form
      layout='horizontal'
      scrollToFirstError
      name='FormCreateTransport'
      onFinish={onFinish}
      form={form}
    >
      <div className='byp-dashboard-content'>
        <Row gutter={16} className='byp-mt-1'>
          <Col xs={24} md={12}>
            <Row gutter={24}>
              <Col span={8}>
                <h4 className='byp-title'>
                  Create your transports
                </h4>
              </Col>
              <Col span={16}>
                <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center' }}>
                  <Col span={8} className='byp-text-sm byp-fw-bold'>You're at:</Col>
                  <Col xs={8}>
                    <SelectSchoolProgramsFormItem
                      name='category'
                      showAll
                      rules={[{ required: true, message: 'This field is required' }]}
                      program={program}
                      form={form}
                      size='small'
                      disabled
                      className='graduate_show_category'
                    />
                  </Col>
                  <Col xs={8}>
                    <SelectAvailableTranslationsFormItem
                      name='translation'
                      formItemProps={{
                        noStyle: true,
                        className: 'margin-0'
                      }}
                      selectProps={{
                        size: 'small',
                        className: 'w-100 margin-0',
                        onChange: (lang) => handleChangeTranslateLanguage(lang),
                        allowClear: true
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='byp-mt-1'>
              <Col xs={12} md={12}>Program type</Col>
              <Col xs={12} md={12}>
                <SelectSchoolProgramsFormItem
                  name='category'
                  showAll
                  rules={[{ required: true, message: 'This field is required' }]}
                  program={program}
                  form={form}
                  size='small'
                />
              </Col>
              <Col xs={12} md={12}>School</Col>
              <Col xs={12} md={12}>
                <SelectCampusByProgramFormItem
                  program={program}
                  name='sedes' rules={[{ required: true, message: 'This field is required' }]}
                  placeholder='Campus where the transport is offered'
                  mode={
                    (program === 'graduate' || program === 'academic_years')
                      ? 'multiple'
                      : undefined
                  }
                  size='small'
                />
              </Col>
              <Col xs={24} md={12}>Transport type</Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name='type'
                >
                  <Input size='small' />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>Name:</Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name='name'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <div className='byp-mt-1'>
              <Button
                type='primary'
                className='float-right'
                htmlType='submit'
                loading={isSaving || isUpdating}
              >
                {isUpdateMode ? 'Update transport' : 'Save transport'}
              </Button>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <TableTransports
              data={transportList}
              isLoading={isLoading || isFetching}
              isDeleting={isDeleting}
              onDelete={onDelete}
              refetch={refetch}
              setTransportID={setTransportID}
              program={program}
              setPage={setPage}
            />
          </Col>
        </Row>
      </div>
      <div className='byp-dashboard-footer'>
        <Row
          gutter={12}
          className='byp-form-footer'
        >
          <Col span={24}>
            <Button
              className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
              onClick={onFinish}
              loading={isSaving || isUpdating}
            >
              Continue
            </Button>
            <Button
              className='byp-btn-blue-200 float-right float-right byp-mt-1'
              htmlType='submit'
              loading={isSaving || isUpdating}
            >{isUpdateMode ? 'Update' : 'Save'}
            </Button>
            {/* <Button
              className='byp-btn-blue-200 float-right float-right byp-mt-1'
              onClick={onBack}
            >Volver
            </Button> */}
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export { FormCreateTransport }
