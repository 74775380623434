import { useMutation } from 'react-query'
import UserService from '../../../services/UserService'
import { useState } from 'react'
import SessionService from '../../../services/SessionService'

const state = {
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_INITIAL: 'LOGIN_INITIAL'
}

function useLoginUser () {
  const [currentState, setCurrentState] = useState(state.LOGIN_INITIAL)

  const { mutate, isLoading } = useMutation(UserService.login, {
    onSuccess: d => {
      SessionService.createSession(d)
      setCurrentState(state.LOGIN_SUCCESS)
    },
    onError: () => {
      setCurrentState(state.LOGIN_ERROR)
    }
  })

  const onSubmit = (email, password, remember = false) => {
    mutate({ email, password, remember })
  }

  return { mutate, isLoading, onSubmit, state, currentState }
}

export { useLoginUser }
