import { Button, DatePicker, Row } from 'antd'
import React, { useState } from 'react'
import { DatesIcon } from '../../../quote-preview/assets/imgs/Icons'
import { IconCalendar } from '../../../../common/assets/svg/icons'
import moment from 'moment'

const { RangePicker } = DatePicker

const TimeFilters = ({ setTime, hasDataRange = true, isActive = false }) => {
  const [dayActive, setDayActive] = useState(false)
  const [weekActive, setWeekActive] = useState(false)
  const [monthActive, setMonthActive] = useState(false)
  const [yearActive, setYearActive] = useState(false)
  const [datesActive, setDatesActive] = useState(false)
  const [allActive, setAllActive] = useState('all')
  const [range, setRange] = useState([null, null])
  const [rangeString, setRangeString] = useState([null, null])
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const handleClick = (btn) => {
    setDayActive(false)
    setWeekActive(false)
    setMonthActive(false)
    setYearActive(false)
    setDatesActive(false)
    setAllActive(false)
    setOpen(false)

    if (btn !== 'dates') setRange([null, null])
    if (btn !== 'dates') setRangeString([null, null])
    if (btn === 'day') setDayActive(true)
    if (btn === 'week') setWeekActive(true)
    if (btn === 'month') setMonthActive(true)
    if (btn === 'year') setYearActive(true)
    if (btn === 'dates') {
      setDatesActive(true)
      setOpen(true)
    }

    if (btn === 'all') {
      setAllActive(true)
      setTime({})
    } else if (btn !== 'dates') {
      setTime({
        time: btn
      })
    }
  }

  const handleDatesChange = (date, dateString) => {
    if (index === 1) {
      setRange([date[0], date[1]])
      setRangeString([dateString[0], dateString[1]])
      setTime({
        start: dateString[0],
        end: dateString[1]
      })
      setOpen(false)
      setIndex(0)
    } else {
      setIndex(1)
    }
  }

  const handleChange = (date, dateString) => {
    console.log('esta es la fecha? en handleChange')
    console.log(date)
    if (index === 0) {
      setRange([date[0], null])
      setRangeString([dateString[0], null])
    }
    setIndex(1)
  }

  const handleDate = () => {
    console.log('primer rango')
      console.log(rangeString[0])
      console.log('segundo rango')
      console.log(rangeString[1])
    if (rangeString[0] === rangeString[1]) {
      return rangeString[0].split('-').reverse().join('/')
    }

    return `${rangeString[0]?.split('-').reverse().join('/')} - ${rangeString[1]?.split('-').reverse().join('/')}`
  }

  const disabledDate = (current) => {
    return current > moment().endOf('day')
  }

  return (
    <Row gutter={[8, 8]} className='total-quote-user-buttons'>
      <Button className={`byp-btn-back ${allActive ? 'byp-bg-blue-200 byp-color-white' : ''} ${isActive ? 'byp-disabled' : ''}`} disabled={isActive} onClick={() => handleClick('all')}>All</Button>
      <Button className={`byp-btn-back ${dayActive ? 'byp-bg-blue-200 byp-color-white' : ''} ${isActive ? 'byp-disabled' : ''}`} disabled={isActive} onClick={() => handleClick('day')}>Day</Button>
      <Button className={`byp-btn-back ${weekActive ? 'byp-bg-blue-200 byp-color-white' : ''} ${isActive ? 'byp-disabled' : ''}`} disabled={isActive} onClick={() => handleClick('week')}>Week</Button>
      <Button className={`byp-btn-back ${monthActive ? 'byp-bg-blue-200 byp-color-white' : ''} ${isActive ? 'byp-disabled' : ''}`} disabled={isActive} onClick={() => handleClick('month')}>Month</Button>
      <Button className={`byp-btn-back ${yearActive ? 'byp-bg-blue-200 byp-color-white' : ''} ${isActive ? 'byp-disabled' : ''}`} disabled={isActive} onClick={() => handleClick('year')}>Year</Button>
      {hasDataRange && (
        <div className='btns-calendar'>
          <Button className={`byp-btn-back d-flex ${datesActive ? 'byp-bg-blue-200 byp-color-white' : ''}`} onClick={() => handleClick('dates')}>
            {!(range[0] && range[1]) && <DatesIcon />}
            {(range[0] && range[1]) && (
              <>
                <IconCalendar />
                {handleDate()}
              </>
            )}
          </Button>
          <RangePicker
            className='hide-input calendar-input'
            value={range} open={open} onChange={handleDatesChange}
            activePickerIndex={index} onCalendarChange={handleChange} disabledDate={disabledDate}
          />
        </div>
      )}
    </Row>
  )
}

export default TimeFilters
