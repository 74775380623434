import { useQuery } from 'react-query'
import CrmRepository from '../../repositories/CrmRepository'

function useGetConfigByCRM (crm) {
  return useQuery(['useGetConfigByCRM', crm],
    () => CrmRepository.getConfigByCrm(crm), {
      retry: 1,
      enabled: !!crm
    })
}

export { useGetConfigByCRM }
