import { userRoles } from "../../../bussines/settings/validate-user-access"
import { PageProtected } from "../../../common/components/PageProtected"
import { MainTheme } from "../../../common/theme/MainTheme"
import { TopBarMenuCustom } from "../agency/components/TopBarMenuCustom"
import { FiltersDiscountsPackages } from "./FiltersDiscountsPackages"
import { TableDiscountsPackages } from "./TableDiscountsPackages"

const URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE = '/dashboard/packages/discounts'

function DiscountsPackagesPage() {
    return (
        <div>
            <PageProtected
                permissionTypes={[
                    userRoles.TYPE_SUPER_ADMIN,
                    userRoles.TYPE_SUPER_EDITOR,
                    userRoles.TYPE_AGENCY,
                    userRoles.TYPE_AGENCY_OFFICE_MANAGER,
                    userRoles.TYPE_AGENCY_MANAGER,
                    userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
                    userRoles.TYPE_AGENCY_EDITOR,
                    userRoles.TYPE_INSTITUTION,
                    userRoles.TYPE_EG_ADMIN
                ]}
            >
                <MainTheme
                    showMenu
                >
                    <TopBarMenuCustom customizable='packages' />
                    <div className='byp-dashboard-content'>
                        <h4 style={{ paddingBottom: '30px' }}>Create your packages </h4>
                        <FiltersDiscountsPackages></FiltersDiscountsPackages>
                    </div>

                </MainTheme>
            </PageProtected>
        </div>
    )
}

export { DiscountsPackagesPage, URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE }