import { Col, Menu, Row } from 'antd'
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { URL_UPLOAD_PRICES_PAGE } from '../Prices/UploadPricesPage'
import { URL_COURSE_CREATE_PAGE } from '../Courses/CreateCoursePage'
import { URL_COURSEPRICES_CREATE_PAGE } from '../CoursePrices/CreateCoursePricesPage'
import { URL_LODGING_CREATE_PAGE } from '../Lodging/CreateLodgingsPage'
import { URL_LODGINGPRICES_CREATE_PAGE } from '../LodgingPrices/CreateLodgingPricesPage'
import { URL_UPLOAD_TRANSPORTS_PAGE } from '../Transports/UploadTransportsPage'
import { URL_TRANSPORTGPRICES_CREATE_PAGE } from '../TransportPrices/CreateTransportPricesPage'
import { URL_DISCOUNTS_CREATE_PAGE } from '../Discounts/CreateDiscountsPage'
import '../../../../common/assets/css/dashboard-topbar-theme.less'
import { URL_CUSTOMDISCOUNT_PAGE } from '../Discounts/CustomDiscountsPage'
import { useEffect } from 'react'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}

function getMenuItems (program, type) {
  const createCourse = type ? program + '_courses_create' + type : program + '_courses_create'
  const createCoursePrices = type ? program + '_courses_prices' + type : program + '_courses_prices'
  if (program === 'boarding_schools') {
    return [
      getItem(
        <Link to={URL_UPLOAD_PRICES_PAGE.replace(':program', program)} state={{ menuItemSelected: program + '_general', openSub: program }}>
          1. BASE PRICES
        </Link>,
        program + '_general'
      ),
      getItem('2. COURSE PRICES', program + '_courses', null, [
        getItem(
          <Link
            to={URL_COURSE_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: createCourse, openSub: program, openSub2: program + '_courses' }}
          > CREATE COURSES
          </Link>,
          createCourse
        ),
        getItem(
          <Link
            to={URL_COURSEPRICES_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: createCoursePrices, openSub: program, openSub2: program + '_courses' }}
          > UPLOAD PRICES
          </Link>,
          createCoursePrices
        )
      ]),
      getItem('5. DISCOUNTS AND PROMOS', program + '_discounts', null, [
        getItem(
          <Link
            to={URL_DISCOUNTS_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_discounts_create', openSub: program, openSub2: program + '_discounts' }}
          >CREATE PROMOS
          </Link>,
          program + '_discounts_create'
        ),
        getItem(
          <Link
            to={URL_CUSTOMDISCOUNT_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_discounts_prices', openSub: program, openSub2: program + '_discounts' }}
          >CUSTOMIZE YOUR PROMOS
          </Link>,
          program + '_discounts_prices'
        )
      ])
    ]
  }
  return [
    getItem(
      <Link to={URL_UPLOAD_PRICES_PAGE.replace(':program', program)} state={{ menuItemSelected: program + '_general', openSub: program }}>
        1. BASE PRICES
      </Link>,
      program + '_general'
    ),
    getItem('2. COURSE PRICES', program + '_courses', null, [
      getItem(
        <Link
          to={URL_COURSE_CREATE_PAGE.replace(':program', program)}
          state={{ menuItemSelected: createCourse, openSub: program, openSub2: program + '_courses' }}
        > CREATE COURSES
        </Link>,
        createCourse
      ),
      getItem(
        <Link
          to={URL_COURSEPRICES_CREATE_PAGE.replace(':program', program)}
          state={{ menuItemSelected: createCoursePrices, openSub: program, openSub2: program + '_courses' }}
        > UPLOAD PRICES
        </Link>,
        createCoursePrices
      )
    ]),
    getItem('3. ACCOMMODATION PRICES', program + '_lodgings', null, [
      getItem(
        <Link
          to={URL_LODGING_CREATE_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_lodgings_create', openSub: program, openSub2: program + '_lodgings' }}
        > CREATE ACCOMMODATION
        </Link>,
        program + '_lodgings_create'
      ),
      getItem(
        <Link
          to={URL_LODGINGPRICES_CREATE_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_lodgings_prices', openSub: program, openSub2: program + '_lodgings' }}
        > UPLOAD PRICES
        </Link>,
        program + '_lodgings_prices'
      )
    ]),
    getItem('4. TRANSPORT PRICES', program + '_transports', null, [
      getItem(
        <Link
          to={URL_UPLOAD_TRANSPORTS_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_transports_create', openSub: program, openSub2: program + '_transports' }}
        > CREATE TRANSPORT
        </Link>,
        program + '_transports_create'
      ),
      getItem(
        <Link
          to={URL_TRANSPORTGPRICES_CREATE_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_transports_prices', openSub: program, openSub2: program + '_transports' }}
        >UPLOAD PRICES
        </Link>,
        program + '_transports_prices'
      )
    ]),
    getItem('5. DISCOUNTS AND PROMOS', program + '_discounts', null, [
      getItem(
        <Link
          to={URL_DISCOUNTS_CREATE_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_discounts_create', openSub: program, openSub2: program + '_discounts' }}
        >CREATE PROMOS
        </Link>,
        program + '_discounts_create'
      ),
      getItem(
        <Link
          to={URL_CUSTOMDISCOUNT_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_discounts_prices', openSub: program, openSub2: program + '_discounts' }}
        >CUSTOMIZE YOUR PROMOS
        </Link>,
        program + '_discounts_prices'
      )
    ])
  ]
}

function TopBarMenu ({ program, type }) {
  const location = useLocation()
  const { menuItemSelected, openSub,discountId} = location.state ?? '1'
  //console.log(discountId);

  useEffect(() => {
    // console.log('type', type)
  }, [type])

  const menuItems = getMenuItems(program, type)
  return (
    <Row className='byp-mb-1'>
      <Col xs={0} md={4} className='byp-justify-centered'>
        <h5 className='byp-title byp-fw-bold' style={{ fontSize: '14px', marginBottom: '0', textAlign: 'center' }}>We walk you step-by-step</h5>
      </Col>
      <Col xs={24} md={20}>
        <Menu
          mode='horizontal'
          defaultSelectedKeys={[menuItemSelected ? String(menuItemSelected) : '1']}
          selectedKeys={[menuItemSelected]}
          defaultOpenKeys={[openSub]}
          className='byp-dashboard-top-bar'
          items={menuItems}
        />
      </Col>
    </Row>
  )
}

export default TopBarMenu
