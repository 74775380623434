import axios from 'axios'
import { API_EXCHANGES_KEY, API_EXCHANGES_URL } from '../config'

const axiosInstance = axios.create({
  baseURL: API_EXCHANGES_URL + '/' + API_EXCHANGES_KEY
})

axiosInstance.interceptors.request.use(function (config) {
  // if (SessionService.existSession()) {
  //   config.headers = {
  //     ...headersWithToken,
  //     Authorization: `Bearer ${SessionService.getAccessToken()}`
  //   }
  // }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default axiosInstance
