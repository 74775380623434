import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Typography,
  Select
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React from 'react'
import { SelectBranchesFormItem } from '../../../../../common/components/helper-form/SelectBranchesFormItem'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'

const { Option } = Select
const { Title } = Typography

function AgencyUsersFieldContainer ({ form }) {
  const arrayInvitations = Form.useWatch('invitations', form) || []
  return (
    <>
      <Title level={5}>Users</Title>
      <Form.List name='invitations'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Divider />
                <Space
                  style={{ display: 'flex', marginBottom: 8 }}
                  align='baseline'
                >
                  <Row style={{ width: '95%' }}>
                    <Col span={24}>
                      <FloatLabel
                        label='Nombre'
                        value={arrayInvitations[name]?.name}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            { required: true, message: 'The name is required' }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col span={24}>
                      <FloatLabel
                        label='Email'
                        value={arrayInvitations[name]?.email}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[
                            { required: true, message: 'El email es requerida' }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col span={24}>
                      <FloatLabel
                        label='Sucursal'
                        value={arrayInvitations[name]?.branch}
                      >
                        <SelectBranchesFormItem
                          {...restField}
                          name={[name, 'branch']}
                          rules={[
                            {
                              required: true,
                              message: 'La Sucursal es requerida'
                            }
                          ]}
                        />
                      </FloatLabel>
                    </Col>
                    <Col span={24}>
                      <FloatLabel
                        label='Rol'
                        value={arrayInvitations[name]?.role}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'role']}
                          rules={[
                            { required: true, message: 'This field is required' }
                          ]}
                        >
                          <Select>
                            <Option value='Agency Administrator'>Agency Administrator</Option>
                            <Option value='Agency Editor'>Agency Editor</Option>
                            {/* <Option value='Gerente Comercial'>Gerente Comercial</Option> */}
                            <Option value='Agency Consultant'>Agency Consultant</Option>
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      Send invitation
                      <Form.Item {...restField} name={[name, 'send_email']} valuePropName='checked'>
                        <Switch defaultChecked size='small' />
                      </Form.Item>
                      &nbsp;&nbsp;
                    </Col>
                  </Row>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              </div>
            ))}
            <Form.Item style={{ width: '90%' }}>
              <Button
                className='byp-btn-blue-100-outline float-right'
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  )
}

export { AgencyUsersFieldContainer }
