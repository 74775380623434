import { Col, Collapse, Form, Input, InputNumber, Row, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import { CoursePriceRangeFormList } from '../../components/CoursePriceRangeFormList'
const { Panel } = Collapse
const { Option } = Select

const readOnly = (field) => {
  if (!field) return false
  return (field.discount_type === undefined || field.discount_type === null || field.discount_type === 'none')
}

const isRequired = (field, name) => {
  if (!field) return []
  if (field[name] !== undefined && field[name] !== null && field.discount_type !== 'none') {
    return [{ required: true, message: 'This field is required' }]
  } else {
    return []
  }
}

function CourseCostsDiscounts ({ name, cleanName = 'courses', showInKind = false, form, list = [], hidden = false, ranges = false }) {
  const array = list[cleanName] || []

  return (
    <div className='w-100'>
      <Form.List
        name={name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const onChangeSomeValue = () => {
                const course = array[name]
                const campusWithChanges = form.getFieldValue('campusWithChanges') ?? []
                const coursesWithChanges = form.getFieldValue('coursesWithChanges') ?? []
                if (campusWithChanges.includes(list.campus_id)) {
                  const isAlreadyInluded = coursesWithChanges?.find(
                    (item) => item.id === course.id && item.campus === course.campus_id
                  )
                  if (!isAlreadyInluded) {
                    coursesWithChanges.push({ id: course.id, campus: course.campus_id })
                    // console.log('🚀 ~ file: ConceptDiscountFormItem.js:43 ~ onChangeSomeValue ~ coursesWithChanges:', coursesWithChanges)
                    form.setFieldsValue({
                      coursesWithChanges
                    })
                  }
                } else {
                  campusWithChanges.push(list.campus_id)
                  coursesWithChanges.push({ id: course.id, campus: course.campus_id })
                  // console.log('🚀 ~ file: ConceptDiscountFormItem.js:51 ~ onChangeSomeValue ~ coursesWithChanges:', coursesWithChanges)
                  form.setFieldsValue({
                    campusWithChanges,
                    coursesWithChanges
                  })
                }
              }
              return (
                <Collapse
                  key={'collapese_' + key}
                  defaultActiveKey={['1']}
                >
                  <Panel header={array[name]?.label ?? array[name]?.name} key='1'>
                    <Form.List
                      name={[name, 'concepts']}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name: conceptIndex, ...restField }, index) => {
                            const listData = array[name]
                            const conceptData = listData?.concepts[conceptIndex]
                            return (
                              <Row key={key} gutter={16}>
                                <Col xs={24} md={10}>
                                  <Form.Item
                                    name={[conceptIndex, 'name']}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[conceptIndex, 'discount_type']}
                                  >
                                    <Select
                                      allowClear
                                      size='small'
                                      onChange={onChangeSomeValue}
                                    >
                                      <Option key={0} value='none'>None</Option>
                                      <Option key={1} value='amount'>Quantity</Option>
                                      <Option key={2} value='percentage'>Percentage</Option>
                                      {showInKind && <Option key={4} value='inkind'>Other</Option>}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                {
                                  conceptData?.discount_type === 'inkind' && (
                                    <Col xs={12} md={8}>
                                      <FormItem
                                        name={[conceptIndex, 'discount']}
                                        rules={isRequired(conceptData, 'discount_type')}
                                      >
                                        <Input placeholder='Good Name' onChange={onChangeSomeValue} />
                                      </FormItem>
                                      <FormItem
                                        name={[conceptIndex, 'discount_description']}
                                      >
                                        <TextArea placeholder='Good(s) description' onChange={onChangeSomeValue} />
                                      </FormItem>
                                    </Col>
                                  )
                                }
                                {
                                  (conceptData?.discount_type !== 'inkind' && conceptData?.discount_type !== 'none') && (
                                    <Col xs={12} md={8}>
                                      <Form.Item
                                        name={[conceptIndex, 'discount']}
                                        className='byp-input-gray'
                                        rules={isRequired(conceptData, 'discount_type')}
                                      >
                                        <InputNumber
                                          min={0}
                                          addonAfter={conceptData?.discount_type === 'percentage' && '%'}
                                          readOnly={readOnly(conceptData)}
                                          onChange={onChangeSomeValue}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )
                                }
                                {ranges && (
                                  <Col xs={24}>
                                    <CoursePriceRangeFormList
                                      name={[conceptIndex, 'ranges']}
                                      onlyRanks
                                    />
                                  </Col>
                                )}
                              </Row>
                            )
                          })}
                        </>
                      )}
                    </Form.List>
                  </Panel>
                </Collapse>
              )
            })}
          </>
        )}
      </Form.List>
    </div>
  )
}

export { CourseCostsDiscounts }
