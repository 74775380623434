import { Modal } from 'antd'
import { useEffect } from 'react'
import { showErrorModal } from '../../../utils/propsForModal'

function ExceptionStepController ({ children, usePostMethod }) {
  const [modal, contextHolder] = Modal.useModal()
  const { error } = usePostMethod()
  useEffect(() => {
    if (error?.response) {
      showErrorModal(modal, error.response.data.message)
    }
  }, [error, modal])

  return (
    <div> {/* `contextHolder` should always under the context you want to access */}
      {contextHolder}
      {children}
    </div>
  )
}

export { ExceptionStepController }
