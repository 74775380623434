import { Steps } from 'antd'
import { useStepByStep } from '../../../providers/stepbystep/step-service-provider'

const { Step } = Steps

function HeaderStep () {
  const { step, totalStep, isLoading } = useStepByStep()
  if (isLoading) {
    return 'cargando...'
  }

  const cSteps = []
  for (let n = 1; n <= totalStep; n++) {
    cSteps.push(n)
  }

  return (
    <div className='step-header'>
      <Steps size='small' current={step - 1}>
        {cSteps.map((i) => (
          <Step key={i} />
        ))}
      </Steps>
    </div>
  )
}

export { HeaderStep }
