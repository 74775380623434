import { Button, Card, Col, Form, Row, Typography } from 'antd'
import React, { useEffect } from 'react'

function ButtonPaypal ({ isLoading, isTest = false }) {
  const form = Form.useFormInstance()
  const phone = Form.useWatch('phone', form)
  useEffect(() => {
    if (phone?.length === 10) {
      console.log('the phone is valid')
    }
  }, [phone])
  return (
    <Card className='quote_paypal mobile-margin_card txt-center'>
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>Empieza tu proceso de inscripción <b className='fw-7'>GRATIS</b></Typography.Title>
        </Col>
        <Col span={24}>
          <Button
            className='btn__paypal byp-btn-red-50 br-05 fw-5'
            style={{ fontSize: '1rem' }}
            htmlType='submit'
            // disabled={!isTest}
            loading={isLoading}
          >
            Inicia Ahora
          </Button>
          <div className='info-quote__terminos byp-mt-1'>
            <p className='terminos-mobile info-quote__terminos-span fw-4 text-black'>Al continuar tu proceso de admisión das por hecho que has aceptado los
              <span className='info-quote__terminos-span'> términos y condiciones </span>
              así como las <span className='info-quote__terminos-span'> políticas de privacidad</span>
            </p>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default ButtonPaypal
