import React, { useState } from 'react'
import { Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { URL_POLICY_PAGE } from '../../PolicyPage'
import { MainRegisterFieldsContainer } from '../steps/main-register/MainRegisterFieldsContainer'
import SessionService from '../../../../services/SessionService'
import { URL_QUOTER_PAGE } from '../../../quoter'

function RegisterForm ({ email, type, role, invitation = false }) {
  const navigate = useNavigate()
  const [temp, setTemp] = useState({
    password: process.env.REACT_APP_PASSWORD || '',
    confirm: process.env.REACT_APP_PASSWORD || '',
    email,
    type,
    role
  })

  React.useEffect(() => {
    if (SessionService.existSession()) {
      navigate(URL_QUOTER_PAGE)
    }
  })

  const onFinish = (values) => {
    const state = {
      ...values,
      invitation,
      role,
      type: temp.type || values.type
    }
    setTemp(state)
    navigate(URL_POLICY_PAGE, { state })
  }

  return (
    <Form layout='vertical' onFinish={onFinish} initialValues={temp}>
      <MainRegisterFieldsContainer
        type={type}
        invitation={invitation}
        displayCompaneNameField
      />
    </Form>
  )
}

export { RegisterForm }
