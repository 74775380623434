import { IconBussiness } from '../../../common/assets/svg/icons'
import { Link } from 'react-router-dom'
// import { URL_USER_ANALYSIS_PAGE } from '../../dashboards/agency/analitics/user/UserAnalysisPage'
import { Button } from 'antd'

function Section1HeaderClientQuotes ({ agencys, t, i18n }) {
  return (
    <div>
      <div className='header-section1'>
        <label class='qyp-label-main-small' style={{ marginTop: '8px' }}>BETA</label>
        <div style={{ display: 'flex', color: 'var(--blue-200)', fontWeight: 'bold', alignItems: 'center', gap: '5px', flexDirection: 'row', whiteSpace: 'nowrap' }}>
          <IconBussiness />
          {agencys?.data.name}
        </div>
        <Button style={{ borderColor: 'var(--blue-100)', borderWidth: '1px', color: 'var(--blue-100)' }}>
          <Link
            to='/quote'
            state={{ menuItemSelected: 'user-analysis', openSub: 'analitic' }}
          >Go back
          </Link>
        </Button>
      </div>
    </div>
  )
}
export { Section1HeaderClientQuotes }
