import { Pagination } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetQuoteActionsDetailsReports } from '../../../../bussines/hooks/agency/analytics/users/useGetQuoteActionsDetails'
import { crypt } from '../../../../bussines/utils/basicEncript'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'

const SendsQuotesDataContainerReports = ({ orderby, isReports, filters }) => {
  const [quotes, setQuotes] = useState()
  const [page, setPage] = useState(1)
  const { data, isLoading } = useGetQuoteActionsDetailsReports({ type: 'send', ...filters, page })

  useEffect(() => {
    if (data && !isLoading) {
      // console.log('🚀 ~ file: SendsQuotesDataContainer.js:10 ~ useEffect ~ data', data)
      setQuotes(data?.data)
    }
  }, [data, isLoading])

  useEffect(() => {
    if (orderby && quotes?.length > 0) {
      setQuotes(
        [...quotes].sort((a, b) => {
          return (typeof a[orderby] === 'number' || !isNaN(a[orderby]))
            ? a[orderby] - b[orderby]
            : a[orderby]?.localeCompare(b[orderby])
        })
      )
    }
  }, [orderby])

  return (
    <>
      {isLoading && <tr><td colSpan={10}><BasicSpinner /></td></tr>}
      {quotes?.map((item) => (
        <tr key={item.uuid + item.client_email}>
          <td>{item.date_of_send}</td>
          {isReports && (
            <>
              <td>{item.office_name}</td>
              <td>{item.user_name}</td>
              <td>{item.school}</td>
              <td>{item.campus_name}</td>
            </>
          )}
          <td>{item.program}</td>
          {!isReports && <td>{item.school}</td>}
          <td><Link to={`/quote-preview/${item.quote_uuid}`} target='_blank'>{item.course}</Link></td>
          <td><Link to={`/client_quotes/${crypt('qyp2023', item?.student_id)}/${crypt('qyp2023', '' + item.agent?.id)}`} target='_blank'>{item.student_id}</Link></td>
          <td>{item.client_email}</td>
        </tr>
      ))}
      <tr>
        <td colSpan={10}>
          <Pagination
            className='byp-w-100'
            defaultCurrent={1}
            current={data?.meta?.current_page ?? 1}
            pageSize={data?.meta?.per_page ?? 20}
            total={data?.meta?.total ?? 0}
            onChange={setPage}
            hideOnSinglePage
            showQuickJumper={false}
            showSizeChanger={false}
          />
        </td>
      </tr>
    </>
  )
}

export { SendsQuotesDataContainerReports }
