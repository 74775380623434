import { Col, Collapse, Form, Row, Tag } from 'antd'
import { ListConceptDescriptionFormItem } from '../../../../common/components/helper-form/ListConceptDescriptionFormItem'

const { Panel } = Collapse

const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const header = (
    <>
      {values[name][position].campus} <Tag className='byp-ml-1'>{values[name][position].status}</Tag>
    </>
  )
  return header
}

const getDivisa = (position, values, name) => {
  if (position === undefined || values === undefined) return 'Sin divisa'
  return values[name][position].divisa ?? 'Divisa inválida'
}

function FormKindDiscountContainer ({ form, name, subname }) {
  const listName = name
  return (
    <Form.List
      name={listName}
    >
      {(fields, { add, remove }, { errors }) => (
        <Collapse
          defaultActiveKey={['0']}
          expandIconPosition='right'
        >
          {fields.map(({ key, name, ...restField }, index) => (
            <Panel
              header={
                panelHeader(
                  restField.fieldKey,
                  form.getFieldValue(),
                  listName
                )
              }
              key={key}
            >
              <Collapse
                defaultActiveKey={['1']}
              >
                <Panel header='Bonus Goods' key='1'>
                  <Row>
                    <Col span={6} className='byp-pt-1'> Divisa: </Col>
                    <Col span={18}>
                      {getDivisa(
                        restField.fieldKey,
                        form.getFieldValue(),
                        listName
                      )}
                    </Col>
                    <Col span={24}>
                      <ListConceptDescriptionFormItem
                        name={[name, subname]}
                        form={form}
                      />
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Panel>
          ))}
        </Collapse>
      )}
    </Form.List>
  )
}

export { FormKindDiscountContainer }
