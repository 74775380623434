import React from 'react'
import { Button, Result, Space } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import icon from '../../../../../common/assets/images/byp_logo_div.png'
import { useNavigate } from 'react-router-dom'
import { URL_USER_REGISTER } from '../../../../../pages/register/UserRegister'
import { URL_DASHBOARD_PAGE } from '../../../../dashboards'

export const STEP_INSTITUTION_FINISH = 3

function InstitutionFinishStep () {
  const navigate = useNavigate()
  const handleHomePage = () => {
    navigate(URL_DASHBOARD_PAGE)
  }

  // redirect to user page
  const handleUserPage = () => {
    navigate(URL_USER_REGISTER)
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      align='middle'
      footer={
        <div>
          <Space>
            <Button
              onClick={handleHomePage}
              type='primary'
              size='large'
            > Go to dashboard
            </Button>
          </Space>
        </div>
      }
    >
      <div style={{ margin: '18px 5%' }}>
        <Result
          icon={<img src={icon} alt='byp-icon' />}
          title='Congratulations!'
          subTitle='You have completed the registration of your school,you can go and do the configurations on the Dashboard'
        />
      </div>
    </StepBaseTemplate>
  )
}

export { InstitutionFinishStep }
