import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetTotalQuotes (time, idOffice, region, subregion, countrie,agency) {
  return useQuery(['useGetTotalQuotes', time, idOffice, region, subregion, countrie,agency],
    () => GeneralAnalysisRepository.getTotalQuotes(time, idOffice, region, subregion, countrie,agency),
    {
      staleTime: 5000
    }
  )
}

export { useGetTotalQuotes }
