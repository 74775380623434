import { useQuery } from 'react-query'
import PartnersRepository from '../../repositories/PartnersRepository'
function useGetMyPartners (filters = {}) {
  return useQuery(['useGetMyPartners', filters],
    () => PartnersRepository.index(filters),
    { retry: 1 }
  )
}

export { useGetMyPartners }
