import { useQuery } from 'react-query'
import { NationalityRepository } from '../../repositories/NationalityRepository'

function useGetCountriesByRegion (region) {
  return useQuery(['useGetCountriesByRegion', region],
    () => NationalityRepository.countriesByRegion(region), {
      retry: 2,
      enabled: !!region
    })
}

function useGetCountriesByRegions (region) {
  return useQuery(['useGetCountriesByRegions', region],
    () => NationalityRepository.countriesByRegions(region), {
      retry: 2,
      enabled: !!region
    })
}

function useGetCountries (idInstitutions = []) {
  return useQuery(['getCountries', idInstitutions],
    () => NationalityRepository.getCountries(idInstitutions), {
      retry: 2,
      enabled: !!idInstitutions
    })
}

function useGetCountriesAuth (idInstitutions = []) {
  return useQuery(['getCountries', idInstitutions],
    () => NationalityRepository.getCountriesAuth(idInstitutions), {
      retry: 2,
      enabled: !!idInstitutions
    })
}

export { useGetCountriesByRegion, useGetCountriesByRegions, useGetCountries, useGetCountriesAuth }
