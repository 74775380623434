import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, Button } from 'antd'
import { TableMaterialsStatus } from './TableMaterialsStatus'
import { useGetMarketingStatus } from '../../../../bussines/hooks/agency/useGetMarketingStatus'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { SelectCampusFormItem } from '../../../../common/components/helper-form/SelectCampusFormItem'
import { SelectBranchesFormItem } from '../../../../common/components/helper-form/SelectBranchesFormItem'
const { Option } = Select

function FormMaterialsStatus () {
  const { user } = useAuth()
  const [filters, setFilters] = useState({})
  const { data, isLoading, error } = useGetMarketingStatus(filters)

  const handleAddFilter = (key, val) => {
    setFilters({
      ...filters,
      [key]: val
    })
  }

  const handleRemoveFilter = (key) => {
    setFilters(nFilter => {
      const copy = { ...nFilter }
      delete copy[key]
      return copy
    })
  }

  useEffect(() => {
    if (!isLoading && error) {
      console.log('🚀 ~ file: FormMaterialsStatus.js ~ line 20 ~ useEffect ~ error', error)
    }
  }, [isLoading, error])
  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={8}>
          <h3 className='byp-title'>Materials Status</h3>
        </Col>
      </Row>
      <Form>
        <Row gutter={24}>
          <Col xs={7} md={7} lg={6}>
            {user?.type === 'Institution' && (
              <SelectCampusFormItem
                name='sedes' placeholder='All' label='School:'
                single
                allowClear
                onChange={(val) => val ? handleAddFilter('sede', val) : handleRemoveFilter('sede')}
              />
            )}
            {user?.type === 'Agency' && (
              <SelectBranchesFormItem
                name='sedes' placeholder='Sedes'
                label='Sede'
                onChange={(val) => handleAddFilter('sede', val)}
              />
            )}
          </Col>
          <Col xs={5} md={5} lg={4}>
            {/* <Select
              size='small' placeholder='All'
              onChange={(val) => handleAddFilter('status', val)}
            >
              <Option value={null}>All</Option>
            </Select> */}
            <SelectCampusFormItem
                name='sedes' placeholder='All' label='Status'
                single
                allowClear
              />
          </Col>
          <Col xs={8} md={4} lg={2}>
            <Form.Item label=' ' colon={false}>
              <Button type='primary' htmlType='submit' className='form-materials__btn'>
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24}>
          <TableMaterialsStatus data={data} isLoading={isLoading} />
        </Col>
      </Row>
    </>
  )
}

export { FormMaterialsStatus }
