import { Form, Select } from 'antd'
import { aprovalStatusItems, emailValidationtatusItems, statusItems } from '../../../utils/statusArray'
import { OptionsClassByStatus, SelectClassByStatus } from '../../../utils/tagStatusClass'
const { Option } = Select

const SelectStatusFormItem = ({ showAll = false, ...props }) => {
  return (
    <Form.Item
      {...props}
    >
      <Select
        {...props}
      >
        {showAll && <Option key={0} value={null}>All status</Option>}
        {statusItems?.map((item) => (
          <Option key={item.key} value={item.key}>{item.icon} {item.label}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const SelectAprovalStatusFormItem = ({ ...props }) => {
  return (
    <Form.Item
      {...props}
    >
      <Select
        {...props}
        className={props.value ? SelectClassByStatus(props.value) : 'w-100'}
      >
        {aprovalStatusItems?.map((item) => {
          if (props.value !== 'Aproved' && item.key === 'Force_delete') {
            return null
          }
          if (props.value === 'Aproved' && item.key !== 'Deleted' && item.key !== 'Force_delete') {
            return null
          }
          if (props.value === 'Deleted' && (item.key === 'Pending' || item.key === 'Aproved')) {
            return null
          }
          return (
            <Option
              key={item.key}
              value={item.key}
              className={OptionsClassByStatus(item.key)}
            >
              <span
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >{item.icon}
              </span> {item.label === 'Approved' ? 'Approved mail' : item.label}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

const SelectValidationEmailStatus = ({ ...props }) => {
  return (
    <Form.Item
      {...props}
    >
      <Select
        {...props}
        className={props.value ? SelectClassByStatus(props.value) : 'w-100'}
      >
        {emailValidationtatusItems?.map((item) => (
          <Option
            key={item.key}
            value={item.key}
            className={OptionsClassByStatus(item.key)}
          >
            <span
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >{item.icon}
            </span> {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectStatusFormItem, SelectValidationEmailStatus, SelectAprovalStatusFormItem }
