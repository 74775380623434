import React from 'react'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { FirstStep } from './components/steps/first-step/FirstStep'

const URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE = '/step-register'

function SendEmailToPartner () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_INSTITUTION, userRoles.TYPE_AGENCY, userRoles.TYPE_ANY]}>
      <FirstStep />
    </PageProtected>
  )
}

export { SendEmailToPartner, URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE }
