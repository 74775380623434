import { Button, Switch, Table } from 'antd'
import { useEffect, useState } from 'react'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ErrorBoundary } from '../../../../providers/validation/ErrorBoundary'
import { CourseTableFilter } from './Filters/CourseTableFilter'

const { Column } = Table

function TableCourses ({ setSearchFilter, searchFilter, setPage, updateStatusCourse, isUpdatingStatus, showStatus = true, showDelete = true, ...props }) {
  const { setCourseID, isLoadingCourse, isDeleting, isLoading, data: allCourses, deleteService, type, program } = props
  const [courses, setCourses] = useState(allCourses?.data ?? [])
  const [metaData, setMetaData] = useState({})

  const capitalizeFirstLetterEachWord = (text) => {
    const words = text?.split('_')
    for (let i = 0; i < words?.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }
    return words?.join(' ')
  }

  useEffect(() => {
    if (!isLoading && allCourses) {
      console.log('🚀 ~ file: TableCourses.js:26 ~ useEffect ~ allCourses:', allCourses)
      setCourses(allCourses.data ?? [])
      setMetaData({
        current: allCourses?.meta?.current_page,
        size: allCourses?.meta?.last_page,
        total: allCourses?.meta?.total,
        pageSize: allCourses?.meta?.per_page,
        onChange: (val) => setPage(val)
      })
    }
  }, [isLoading, allCourses])

  // if (isLoading) {
  //   return <LottieCargando />
  // }

  const handleChangeStatus = (e, id, schoolId) => {
    updateStatusCourse(id, { status: e, schoolId })
  }

  return (
    <ErrorBoundary>
      <CourseTableFilter
        courses={allCourses?.data}
        setCourses={setCourses}
        program={program}
        type={type}
        setSearchFilter={setSearchFilter}
        searchFilter={searchFilter}
      />
      {isLoading && <LottieCargando />}
      {!isLoading && (
        <Table
          loading={isLoading}
          dataSource={courses}
          className='byp-table-dashboard'
          title={() => 'Added courses'}
          rowKey='id'
          /* pagination={{ defaultPageSize: 2, showSizeChanger: false, pageSizeOptions: ['10', '20', '30'] }} */
          pagination={metaData}
        >
          <Column
            title='Name' dataIndex='name' key='name' className='byp-columna'
            style={{ alignContent: 'start' }}
            render={(_, record) => (
              <a><b>{record.label}</b></a>
            )}
          />
          <Column
            title={type !== 'diploma' ? 'Program' : 'Program Sub-type'}
            dataIndex='category'
            key='category'
            render={(_, record) => (
              type !== 'diploma' ? record.category?.label : capitalizeFirstLetterEachWord(record.properties.sub_type)
            )}
          />
          {program === 'graduate' && (
            <Column
              title='Study Area'
              dataIndex='area'
              key='area'
              render={(_, record) => (
                <ul>
                  {Array.isArray(record.area) && record.area?.map((item) => (
                    <li key={'item_area_' + item}>{item}</li>
                  ))}
                </ul>
              )}
            />
          )}
          <Column
            title='Campus'
            dataIndex='sedes'
            key='sede'
            render={(_, record) => (
              <div className='' style={{ maxHeight: '10rem', overflow: 'auto' }}>
                {record.sedes?.map((sede) => (
                  <p key={sede.id} className='byp-m-0'>{sede.name}</p>
                ))}
              </div>
            )}
          />
          {showStatus && (
            <Column
              title='Status'
              dataIndex='status'
              key='status'
              responsive={['sm']}
              render={(_, record) => (
                (
                  <div>
                    <Switch
                      style={{ alignItems: 'center', display: 'flex' }}
                      checkedChildren='ACTIVE'
                      unCheckedChildren='INACTIVE'
                      onChange={(e) => handleChangeStatus(e, record?.id, record?.school_id)}
                      loading={isUpdatingStatus}
                      checked={record.status === 'Active'}
                    />
                  </div>
                )
              )}
            />
          )}
          <Column
            title='Actions'
            dataIndex='actions'
            key='actions'
            render={(_, record) => (
              <div style={{ display: 'flex' }}>
                {showDelete && (
                  <ConfirmModal onOk={deleteService} isLoading={isDeleting} id={record.id} />

                )}
                <Button
                  size='small'
                  className='byp-btn-edit'
                  loading={isLoadingCourse}
                  onClick={
                    () => {
                      setCourseID(record.id)
                    }
                  }
                  icon={<EditIcon />}
                />
              </div>
            )}
          />
        </Table>
      )}
    </ErrorBoundary>
  )
}

export { TableCourses }
