import { Col, Form, Input, InputNumber, Row, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect } from 'react'
const { Option } = Select

// const readOnly = (field) => {
//   if (!field) return false
//   return (field.discount_type === undefined || field.discount_type === null || field.discount_type === 'none')
// }

// const isRequired = (field, name) => {
//   if (!field) return []
//   if (field[name] !== undefined && field[name] !== null && field.discount_type !== 'none') {
//     return [{ required: true, message: 'This field is required' }]
//   } else {
//     return []
//   }
// }

function PackageCostDiscountFormList ({ name, parentName = '', childIndex = 0, showInKind = false, list = [] }) {
  const form = Form.useFormInstance()
  const thisList = Form.useWatch([parentName, childIndex], form)

  useEffect(() => {
    // console.log('🚀 ~ file: PackageCostDiscountFormList.js:28 ~ useEffect ~ thisList:', thisList)
  }, [thisList])
  return (
    <div className='Panel-inside'>
      <Form.List
        name={name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name: conceptIndex, ...restField }, index) => {
              const costData = thisList?.costs[conceptIndex] ?? []
              const type = costData.discount_type
              return (
                <Row key={key} gutter={16}>
                  <Col xs={24} md={10}>
                    {costData.week && (
                      <>
                        <Form.Item
                          name={[conceptIndex, 'week']}
                          noStyle
                        >
                          <Input disabled className='byp-input-label' type='hidden' />
                        </Form.Item>
                        <Input disabled className='byp-input-label' value={costData.week + ' Weeks'} />
                      </>
                    )}
                    {!costData.week && (
                      <Input disabled value='Whole cost' className='byp-input-label' />
                    )}
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={[conceptIndex, 'discount_type']}
                    >
                      <Select allowClear size='small'>
                        <Option key={0} value='none'>None</Option>
                        <Option key={1} value='amount'>Quantity</Option>
                        <Option key={2} value='percentage'>Percentage</Option>
                        {showInKind && <Option key={4} value='inkind'>Other</Option>}
                      </Select>
                    </Form.Item>
                  </Col>
                  {
                    type === 'inkind' && (
                      <Col xs={12} md={8}>
                        <FormItem
                          name={[conceptIndex, 'discount']}
                          // rules={isRequired(conceptData, 'discount_type')}
                        >
                          <Input placeholder='Good Name' />
                        </FormItem>
                        <FormItem
                          name={[conceptIndex, 'discount_description']}
                        >
                          <TextArea placeholder='Good(s) description' />
                        </FormItem>
                      </Col>
                    )
                  }
                  {
                    type !== 'inkind' && (
                      <Col xs={12} md={8}>
                        <Form.Item
                          name={[conceptIndex, 'discount']}
                          className='byp-input-gray'
                          // rules={isRequired(conceptData, 'discount_type')}
                        >
                          <InputNumber
                            min={0}
                            addonAfter={type === 'percentage' && '%'}
                            // readOnly={readOnly(conceptData)}
                          />
                        </Form.Item>
                      </Col>
                    )
                  }
                </Row>
              )
            })}
          </>
        )}
      </Form.List>
    </div>
  )
}

export { PackageCostDiscountFormList }
