import { Button, Switch, Table } from 'antd'
import { useChangeStatusFee } from '../../../../bussines/hooks/fee/useChangeStatusFee'
import { useEffect, useState } from 'react'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { LodgingTransportTableFilters } from './Filters/LodgingTransportTableFilters'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ErrorBoundary } from '../../../../providers/validation/ErrorBoundary'

const { Column } = Table

function TableTransports (props) {
  const { isLoading, isDeleting, data, onDelete, setTransportID, refetch, program, setPage } = props
  const { changeStatus, state, currentState, isLoading: isUpdating } = useChangeStatusFee()
  const [transports, setTransports] = useState()

  useEffect(() => {
    if (!isUpdating && currentState === state.STATE_SUCCESS) {
      refetch()
    }
  }, [currentState, isUpdating])

  useEffect(() => {
    if (!isLoading && data?.data) {
      setTransports(data.data)
    }
  }, [isLoading, data])

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <ErrorBoundary>
      <LodgingTransportTableFilters program={program} setData={setTransports} data={data?.data} type='transport' />
      <Table
        loading={isLoading}
        dataSource={transports ?? []}
        className='byp-table-dashboard'
        title={() => 'Available transports'}
        rowKey='id'
        pagination={{
          current: data?.meta?.current_page,
          size: data?.meta?.last_page,
          total: data?.meta?.total,
          pageSize: data?.meta?.per_page,
          onChange: (val) => setPage(val)
        }}
      >
        <Column
          title='Name' dataIndex='name' key='name'
          render={(_, record) => (
            <a>{record.name}</a>
          )}
        />
        <Column
          title='Type'
          dataIndex='type'
          key='type'
          responsive={['lg']}
          render={(_, record) => (
            record.properties?.type
          )}
        />
        <Column
          title='Status'
          render={(_, record) => (
            <Switch
              checked={record?.status === 'Active'}
              checkedChildren='ACTIVE'
              unCheckedChildren='INACTIVE'
              style={{ alignItems: 'center', display: 'flex' }}
              loading={isUpdating}
              onClick={
                (value) => (changeStatus(record.id))
              }
            />
          )}
        />
        <Column
          title='Actions'
          key='action'
          render={(_, record) => (
            <div style={{ display: 'flex', gap: '10px' }}>
              <ConfirmModal onOk={onDelete} isLoading={isDeleting} id={record.id} />
              <Button
                size='small'
                className='byp-btn-edit'
                onClick={
                  () => {
                    setTransportID(record.id)
                  }
                }
                icon={<EditIcon />}
              />
            </div>
          )}
        />
      </Table>
    </ErrorBoundary>
  )
}

export { TableTransports }
