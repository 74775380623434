import { useQuery } from 'react-query'
import AgencyRepository from '../../../repositories/AgencyRepository'

function useGetMiscellaneous (filters = {}) {
  return useQuery(
    ['useGetMiscellaneous', filters],
    () => AgencyRepository.getMiscellaneous(filters), {
      retry: 2,
      disabled: !!filters
    }
  )
}

export { useGetMiscellaneous }
