import React, { useState, useEffect } from 'react'
import { Switch, Row, Col, Divider, Modal } from 'antd'
import TotalQuotes from '../../../../agency/analitics/general/TotalQuotes'
import MostPopularSchools from '../../components/MostPopularSchools'
import MostPopularCourses from '../../components/MostPopularCourses'
import { PopularIntake } from './PopularIntake'
import { TopConsultanst } from './TopConsultanst'
import { useActiveType } from '../../../../../../bussines/hooks/agency/agency_institutions/useActiveAgency'
import { useGetAgencies } from '../../../../../../bussines/hooks/agency/useGetAgencies'
import { useAuth } from '../../../../../../providers/auth/auth-context-provider'

function AgencyInfo ({ agencyId, time, userData, handleRefetch }) {
  const { activeType, isLoading } = useActiveType()
  const [isActive, setIsActive] = useState(false)
  const [isConsultant, setIsConsultant] = useState(false)
  const [isActiveText, setIsActiveText] = useState('')
  const { data, refetch: refetchAgencies } = useGetAgencies({ agency: agencyId })
  const { user: _user } = useAuth()
  const [modal, context] = Modal.useModal()

  useEffect(() => {
    if (data) {
      setIsActive(data.data['0'].isactive)
      setIsActiveText(data.data['0'].isactive === true ? 'AGREEMENT' : 'DISAGREEMENT')
    }
  }, [userData, data])

  useEffect(() => {
    const role = _user?.roles
    if (role?.includes('Education Group Consultant')) {
      setIsConsultant(true)
    }
  }, [_user])

  const handleStatus = (value) => {
    if (isConsultant) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }

    activeType({ type: 'disagreement', is_active: value, agency: agencyId })
    setTimeout(() => {
      handleRefetch()
      refetchAgencies()
    }, 100)
  }

  return (
    <div style={{ paddingTop: '20px' }}>
      {context}
      <Row>
        <Col span={16} style={{ paddingRight: '10px' }}>
          <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'> Agency info </h4>
          <div className='display-card'>
            <div className='display-card-bottom'>
              <div style={{ display: 'flex', flexDirection: 'column' }} className='card-user__item d-flex align-center card-first-item'>
                <div className='display-image'>
                  <img
                    alt=''
                    src={userData?.profile_photo_url}
                  />
                </div>
                <div style={{ marginTop: '5px' }}>
                  <p className='byp-text-md byp-title'>{userData?.name}</p>
                </div>
              </div>
              <div className='display-information'>
                <Row style={{ gap: '20px' }}>
                  <h4 className='byp-text-sm byp-title byp-fw-bold'>INFORMATION</h4>
                  <label style={{ fontSize: '13px', color: 'var(--blue-200)', backgroundColor: ' #F2A706', padding: '3px 5px', borderRadius: '8px', fontWeight: 'bold' }}>{userData?.consultants} consultants</label>
                </Row>

                <div className='d-flex user-information'>
                  <div>
                    <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
                      Founded since
                    </p>
                    <p className='byp-text-md byp-title'>{userData?.foundation}</p>
                    <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
                      Joined
                    </p>
                    <p className='byp-text-md byp-title'>{userData?.created_at}</p>
                  </div>
                  <div>
                    <p className='byp-text-sm byp-color-gray-100 byp-m-0'>Email</p>
                    <p className='byp-text-md byp-title'>{userData?.email}</p>
                  </div>
                </div>
              </div>
              <div className='card-user__item'>
                <h4 className='byp-text-sm byp-title byp-fw-bold'>ALL TIME</h4>
                <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
                  Created quotes
                </p>
                <p className='byp-text-md byp-title'>{userData?.created}</p>
                <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
                  Sended quotes
                </p>
                <p className='byp-text-md byp-title'>{userData?.sended}</p>
              </div>
              <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', rowGap: '15px', paddingTop: '20px' }} className='card-user__item--last'>
                <label style={{ color: 'white', backgroundColor: 'var(--blue-100)', borderRadius: '10px', padding: '2px 10px' }}>{isActiveText}</label>
                <Switch
                  style={{ alignItems: 'center', display: 'flex' }}
                  checkedChildren='ACTIVE' unCheckedChildren='INACTIVE'
                  onChange={(e) => handleStatus(e)}
                  checked={isActive}
                  loading={isLoading}
                />
              </div>
            </div>

          </div>

          <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

          <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
            Total quotes <small><i>(percentages based on institution totals)</i></small>
          </h4>
          <TotalQuotes agency={agencyId} time={time} />
          <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

          <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
            Most popular schools/educational groups
          </h4>
          <MostPopularSchools agency={agencyId} time={time} />
          <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
          <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
            Most popular courses
          </h4>
          <MostPopularCourses agency={agencyId} time={time} />
          <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
          <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
            Populars intakes
          </h4>

          <PopularIntake agency={agencyId} time={time} />

          <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
        </Col>

        <Col span={8}>
          <TopConsultanst filters={{ agency: agencyId, ...time }} />
        </Col>
      </Row>
    </div>

  )
}

export { AgencyInfo }
