import React, { useEffect } from 'react'
import { Button, Result, Space } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import SessionService from '../../../../../services/SessionService'
import { URL_USER_REGISTER } from '../../../../../pages/register/UserRegister'
import { URL_DASHBOARD_PAGE } from '../../../../dashboards'
import icon from '../../../../../common/assets/images/byp_logo_div.png'

export const STEP_FINISH = 2

function AgencyFinishStep () {
  const { logout } = useAuth()
  const navigate = useNavigate()

  // useeffect to check if message property exists on session service
  useEffect(() => {
    if (SessionService.getCurrentSession().message === 'Invitaion found') {
      logout()
    }
  }
  , [])

  const handleHomePage = () => {
    navigate(URL_DASHBOARD_PAGE)
  }

  const handleUserPage = () => {
    navigate(URL_USER_REGISTER)
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      align='middle'
      footer={
        <div>
          <Space>
            <Button
              onClick={handleHomePage}
              type='primary'
              size='large'
            > Go to Dashboard
            </Button>
          </Space>
        </div>
      }
    >
      <div style={{ margin: '18px 5%' }}>
        <Result
          icon={<img src={icon} alt='byp-icon' />}
          title='Congratulations!'
          subTitle='You have completed the registration of your agency, you can go and do the configurations on the Dashboard'
        />

      </div>
    </StepBaseTemplate>
  )
}

export { AgencyFinishStep }
