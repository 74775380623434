import { Form, Col, message, Row, Upload, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { headersForUpload, isPdfOrDoc } from '../../../../utils/uploadImageUtils'
import { showErrorModal } from '../../../../utils/errorModal'

function beforeUpload (file) {
  const _isDocument = isPdfOrDoc(file)
  if (!_isDocument) {
    showErrorModal(null, null, 'The file must be a PDF or a word document')
  }
  if (file.size > 5000000) {
    showErrorModal(null, null, 'File size must be 5MB or less')
    return false
  }
  return _isDocument
}

function UploadDocumentsFormItem ({ name, maxCount, action, form, setGallery, isUpdateMode, canUpload, data = {} }) {
  const _name = Form.useWatch([name], form) || ''
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, contextHolder] = Modal.useModal()

  const handleChange = ({ file, fileList }) => {
    if (!canUpload) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    setFileList(fileList)
    if (file.status === 'uploading') {
      setLoading(true)
    } else if (file.status === 'done') {
      form.setFieldsValue({ [name]: fileList })
      message.success(`${file.name} file uploaded successfully`)
      setLoading(false)
      if (setGallery) {
        const formatedList = fileList.filter(img => img.response?.id).map(img => ({ url: img.response.url }))
        setGallery(formatedList)
      }
    } else if (file.status === 'removed') {
      form.setFieldsValue({ [name]: fileList })
      if (setGallery) {
        const formatedList = fileList.filter(img => img.response?.id).map(img => ({ url: img.response.url }))
        setGallery(formatedList)
      }
    } else {
      message.error(`${file.response?.message ?? 'file upload failed.'}`)
      setLoading(false)
    }
  }

  useEffect(() => {
    const defaultImgs = form.getFieldValue(name) || []
    setFileList(defaultImgs)
    console.log('🚀 ~ file: UploadDocumentsFormItem.js:53 ~ useEffect ~ defaultImgs:', defaultImgs)
  }, [_name])

  return (
    <Row>
      {contextHolder}
      <Col span={24}>
        <Form.Item name={name} style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Upload
          name='document'
          listType='picture'
          className='avatar-uploader dashboard-media-uploader w-100'
          action={action}
          data={data}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          headers={headersForUpload()}
          maxCount={maxCount ?? 5}
          fileList={fileList}
        >
          <div className='byp-btn-media-upload'>
            <div className='btn-media-img byp-centered'>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
            </div>
            <div className='btn-media-text byp-justify-centered'>Upload file</div>
          </div>
          {/* {!isUpdateMode && (
          )} */}
        </Upload>
      </Col>
    </Row>
  )
}

export { UploadDocumentsFormItem }
