import api from '../../services/api'

class LogRepository {
  async logs (filters) {
    const response = await api.get('/logs', { params: filters })
    return response.data
  }
}

export default new LogRepository()
