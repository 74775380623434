import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const generatePdf = (element, fileName) => {
  html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    ignoreElements: (element) => {
      return element.classList.contains('ignore-pdf')
    }
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/png')
    const pdf = new jsPDF('p', 'mm', 'a4')
    const imgWidth = 210
    const pageHeight = 295
    const imgHeight = (canvas.height * imgWidth) / canvas.width
    let heightLeft = imgHeight

    let position = 0

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
    heightLeft -= pageHeight

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight
      pdf.addPage()
      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
    }
    pdf.save(fileName)
  })
}

export default generatePdf
