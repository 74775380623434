import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { Button, Col, Form, Row, Typography, notification } from 'antd'
import { SelectCRMFormItem } from './components/SelectCRMFormItem.js.js'
import { BitrixFormFields } from './components/BitrixFormFields'
import { useGetConfigByCRM } from '../../bussines/hooks/crm/useGetConfigByCRM'
import { useEffect } from 'react'
import { useSaveCrmConfiguration } from '../../bussines/hooks/crm/useSaveCrmConfiguration'
import stateControl from '../../bussines/utils/state-control'
const { Title } = Typography

const URL_INTEGRATIONS_PAGE = '/integrations'

function IntegrationsPage () {
  const [form] = Form.useForm()
  const crm = Form.useWatch('crm', form)
  const { data, isLoading, isError, error } = useGetConfigByCRM(crm)
  const { save, isLoading: isSaving, currentState, error: errorAtSaving } = useSaveCrmConfiguration()

  useEffect(() => {
    if (!isLoading && data) {
      const getContact = data.find((config) => config.configuration?.key === 'ENTERPRISE_BITRIX_GET_CONTACT')
      const addQuote = data.find((config) => config.configuration?.key === 'ENTERPRISE_BITRIX_ADD_QUOTE')
      form.setFieldsValue({
        add_quote_webhook: addQuote?.value,
        get_contact_webhook: getContact?.value
      })
    }
    if (!isLoading && isError) {
      console.log('🚀 ~ file: IntegrationsPage.js:20 ~ useEffect ~ error:', error)
    }
  }, [isLoading])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'CRM data succesfully saved'
      })
    }
    if (!isSaving && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: errorAtSaving?.response?.data?.message ?? 'Somethind went wrong'
      })
    }
  }, [isSaving])

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_AGENCY, userRoles.TYPE_AGENCY_MANAGER]}>
      <MainTheme activeCarousel>
        <Title level={2} type='warning'>Integrations</Title>
        <Title level={5}>Connect to a CRM</Title>
        <Form
          form={form}
          onFinish={(values) => save(values)}
          labelWrap
          layout='horizontal'
          labelCol={{
            md: 12,
            lg: 8
          }}
          labelAlign='left'
        >
          <Row>
            <Col xs={24} md={12} lg={8}>
              <SelectCRMFormItem name='crm' />
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12} lg={8}>
              {crm === 'bitrix' && (
                <BitrixFormFields />
              )}
              {crm && (
                <Button
                  loading={isSaving}
                  htmlType='submit'
                >
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </MainTheme>
    </PageProtected>
  )
}

export { IntegrationsPage, URL_INTEGRATIONS_PAGE }
