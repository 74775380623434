import { Col, Divider, Row } from 'antd'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { usePackage } from '../../../providers/package/package-course-context'
import { formatStringDate } from '../../../utils/formatStringDate'

const discountTextStyle = {
  marginBottom: '.5rem',
  backgroundColor: '#6495ED',
  color: '#FFFFFF',
  padding: '.3rem'
}

const dividerHorizontal = {
  display: 'flex',
  clear: 'both',
  width: '100%',
  minWidth: '100%',
  margin: '3px 0'
}

function QuoteSummary () {
  const [total, setTotal] = useState(0)
  const { service, course } = usePackage()
  const RowItem = (label, total, discount = null) => (
    <Row className='w-100' key={label + Math.random()} style={{ display: 'flex', alignItems: 'center' }}>
      <Col span={16}>{label}</Col>
      <Col style={{ textAlign: 'end' }} span={8}>{total}</Col>
    </Row>
  )
  // console.log('service', service)

  function numberWithCommas (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const getSymbol = (value, type) => {
    if (type === 'percentage') {
      return value + '%'
    }
    return value + ' ' + service.getCurrency()
  }

  const handleDiscount = (discount) => {
    return <Col span={24} key={Math.random()} style={discountTextStyle}>Displaying total with discount, {getSymbol(discount.value, discount.type)} {discount.name} discount , until {formatStringDate(discount.end, 'LLL dd yyyy')}.</Col>
  }

  const getTotalAgency = () => {
    let result = 0
    service.getQuoteAgencyServices()?.forEach((item) => (
      result += service.calculateExchangeValue(
        service.getAgencyServiceSelectedCost(item.id)?.toFixed(2),
        service.getExchangeValue(service.getAgencyServiceCurrency(item.id))
      )
    ))
    return result
  }

  const [additionalsServices, setAdditionalsServices] = useState({})
  const [totalService, setTotalService] = useState({})
  const [showMedical, setShowMedical] = useState(true)
  const [showTotalAgency, setShowTotalAgency] = useState(true)
  useEffect(() => {
    const totalSum = {}
    const sums = {}

    if ((service.subTotalCourseExtra() > 0 || service.getAmountHealthInsurance() > 0)) {
      const total = (service.subTotalCourseExtra() + service.getAmountHealthInsurance() - service.getInsuranceDiscountAmount())?.toFixed(2) + service.getCurrency()
      setTotal(total)
    }

    if (service.getTotalQuoteAmount() > 0) {
      if (!totalSum[service.getCurrency()]) {
        totalSum[service.getCurrency()] = 0
      }
      totalSum[service.getCurrency()] += service.getTotalQuoteAmount()
    }

    if (service.isAgency() && service.getCurrency() !== service.getAgencyCurrency()) {
      if (!totalSum[service.getAgencyCurrency()]) {
        totalSum[service.getAgencyCurrency()] = 0
      }

      totalSum[service.getAgencyCurrency()] += service.calculateAgencyMiscellaneousTotal()
      setShowTotalAgency(false)
    }

    const additionals = service.getSelectedServices()
    Object.keys(additionals)?.forEach(currency => {
      additionals[currency].forEach(item => {
        if (!sums[currency]) {
          sums[currency] = 0
        }
        sums[currency] += item.cost
        if (!totalSum[currency]) {
          totalSum[currency] = 0
        }
        totalSum[currency] += item.cost
      })
    })
    setAdditionalsServices(sums)

    if (totalSum[service?.getAgencyMedicalCurrency()]) {
      totalSum[service.getAgencyMedicalCurrency()] += service.getAmountMedical()
    }

    setTotalService(totalSum)
  }, [service])

  return (
    <>
      {/* Scholarship (Course + materials)  */}
      {service.subTotalCourse() > 0 && RowItem(
        <p className='byp-fw-bold' style={{ marginBottom: '10px', marginTop: '10px', color: '#2D2F66' }}>Program fee total</p>,
        <span className='byp-fw-bold byp-title'>
          {
            numberWithCommas(
              (Number(service.subTotalCourse()) - ((Number(service.getCourseDiscountAmount() + service.getTuitionFeeDiscountsAmount())) +
              Number(service.getMaterialDiscountsAmount())))?.toFixed(2))
          } {service.getCurrency()}
        </span>
      )}

      {/*  */}
      {service.includeLodging() && RowItem(
        <p className='byp-fw-bold' style={{ marginBottom: '10px', color: '#2D2F66' }}>Accommodation fee total</p>,
        <span className='byp-fw-bold byp-title'>
          {numberWithCommas((service.subTotalLodging() - service.getLodgingDiscountsAmount())?.toFixed(2))} {service.getCurrency()}
        </span>
      )}

      {service.includeTransport() && RowItem(
        <p className='byp-fw-bold' style={{ marginBottom: '10px', color: '#2D2F66' }}>Airport fee total</p>,
        <span className='byp-fw-bold byp-title'>
          {numberWithCommas((service.getTransportTotal())?.toFixed(2))} {service.getCurrency()}
        </span>
      )}

      {(service.subTotalCourseExtra() > 0 || service.getAmountHealthInsurance() > 0) && RowItem(
        <p className='byp-fw-bold' style={{ marginBottom: '10px', color: '#2D2F66' }}>Extras total</p>,
        <span className='byp-fw-bold byp-title'>
          {numberWithCommas(total)}
        </span>
      )}

      {(service.isAgency() || getTotalAgency() > 0) && RowItem(
        <p className='byp-fw-bold' style={{ marginBottom: '10px', color: '#2D2F66' }}>Agency fees total</p>,
        <span className='byp-fw-bold byp-title'>
          {numberWithCommas(getTotalAgency()?.toFixed(2))} {service.getAgencyCurrency()}
        </span>
      )}

      {(Object.entries(additionalsServices)?.length > 0) && service.includeService() &&
        Object.entries(additionalsServices)?.map(([key, costAdditional]) => (
          RowItem(
            <p className='byp-fw-bold' style={{ marginBottom: '10px', color: '#2D2F66' }}>Additional Services total</p>,
            <span className='byp-fw-bold byp-title'>
              {numberWithCommas(costAdditional.toFixed(2))} {key}
            </span>
          )
        )
        )}

      {(service.isAgency() || service.getAmountMedical() > 0) && service.includeMedical() && RowItem(
        <p className='byp-fw-bold' style={{ marginBottom: '10px', color: '#2D2F66' }}>Medical Insurance total</p>,
        <span className='byp-fw-bold byp-title'>
          {numberWithCommas(service.getAmountMedical()?.toFixed(2))} {service.getAgencyMedicalCurrency()}
        </span>
      )}

      {/* {service.getCourseProgram()?.name !== 'language' && (
        service.courseCosts()?.map((costItem) => (
          service.courseCostDiscounts(costItem.id)?.map(discount => (
            handleDiscount(discount)
          ))
        ))
      )} */}

      {service.getCourseDiscounts() && (
        service.getCourseDiscounts()?.map(discount => (
          handleDiscount(discount)
        ))
      )}

      {service.getTuitionFeeDiscounts() && (
        service.getTuitionFeeDiscounts()?.map(discount => (
          handleDiscount(discount)
        ))
      )}

      {
        service.getMaterialDiscounts().length > 0 && (
          service.getMaterialDiscounts()?.map((discount) => (
            handleDiscount(discount)
          ))
        )
      }

      {
        service.getBasesPrices()?.map(item => (
          service.getFeeDiscounts(item.id).length > 0 && (
            service.getFeeDiscounts(item.id)?.map((discount) => (
              handleDiscount(discount)
            ))
          )
        ))
      }

      {
        service.getAcommodationFeeDiscounts().length > 0 && (
          service.getAcommodationFeeDiscounts()?.map((discount) => (
            handleDiscount(discount)
          ))
        )
      }

      {
        service.getLodgingDiscounts().length > 0 && (
          service.getLodgingDiscounts()?.map((discount) => (
            handleDiscount(discount)
          ))
        )
      }

      {
        service.getTransportDiscounts().length > 0 && (
          service.getTransportDiscounts()?.map((discount) => (
            handleDiscount(discount)
          ))
        )
      }

      {
        service.getInsuranceDiscounts().length > 0 && (
          service.getInsuranceDiscounts()?.map((discount) => (
            handleDiscount(discount)
          ))
        )
      }

      {course?.offer?.extras?.map((item) => {
        return item.name !== 'Registration Fee' && service.getFeeDiscounts(item.id) && (
          service.getFeeDiscounts(item.id)?.map((discount) => (
            handleDiscount(discount)
          ))
        )
      })}

      <Divider style={dividerHorizontal} />
      {/* <Row className='w-100'>
        <Col offset={-1} span={17} style={{ textAlign: 'center', color: '#2D2F66' }} className='byp-fw-bold'>TOTAL {service.getCurrency()}</Col>
        <Col style={{ textAlign: 'end' }} className='byp-fw-bold byp-title' span={6}>{`${numberWithCommas(service.getTotalQuoteAmount()?.toFixed(2))}`}</Col>
      </Row> */}
      {/* {
        service.isAgency() && service.getCurrency() !== service.getAgencyCurrency() && showTotalAgency && (
          <Row className='w-100'>
            <Col offset={-1} span={17} style={{ textAlign: 'center', color: '#2D2F66' }} className='byp-fw-bold'>TOTAL {service.getAgencyCurrency()}</Col>
            <Col style={{ textAlign: 'end' }} className='byp-fw-bold byp-title' span={6}>{`${numberWithCommas(service.calculateAgencyMiscellaneousTotal()?.toFixed(2))}`}</Col>
          </Row>
        )
      } */}
      {
        Object.entries(totalService).length > 0 &&
        Object.entries(totalService)?.map(([key, costAdditional]) => (
          <Row className='w-100' key={key}>
            <Col offset={-1} span={17} style={{ textAlign: 'center', color: '#2D2F66' }} className='byp-fw-bold'>TOTAL {key}</Col>
            <Col style={{ textAlign: 'end' }} className='byp-fw-bold byp-title' span={6}>{`${numberWithCommas(costAdditional.toFixed(2))}`}</Col>
          </Row>
        ))
      }
      {/* {
        service.isAgency() && service.getAgencyMedicalCurrency() !== service.getAgencyCurrency() && service.includeMedical() && service?.getAgencyMedicalCurrency() && showMedical && (
          <Row className='w-100'>
            <Col offset={-1} span={17} style={{ textAlign: 'center', color: '#2D2F66' }} className='byp-fw-bold'>TOTAL {service.getAgencyMedicalCurrency()}</Col>
            <Col style={{ textAlign: 'end' }} className='byp-fw-bold byp-title' span={6}>{`${numberWithCommas(service.getAmountMedical()?.toFixed(2))}`}</Col>
          </Row>
        )
      } */}
    </>
  )
}

export { QuoteSummary }
