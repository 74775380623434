import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import CampusRespository from '../../repositories/CampusRespository'

function useSaveCampusExtraInfo () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(CampusRespository.saveExtraInfo, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const save = (campus, data) => {
    mutation.mutate({ campus, data })
  }

  return { ...mutation, state, currentState, save }
}

export { useSaveCampusExtraInfo }
