import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React from 'react'
import { FullAddressFormItem } from '../../../../../common/components/helper-form/FullAddressFormItem'
import { SelectCurrencyFormItem } from '../../../../../common/components/helper-form/SelectCurrencyFormItem'
import { SelectPhoneCodeFormItem } from '../../../../../common/components/helper-form/SelectPhoneCodeFormItem'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'

const { Title, Paragraph } = Typography

function AgencyBranchesFieldContainer ({ form }) {
  const arrayBranches = Form.useWatch('branches', form) || []
  return (
    <><Title level={5}>Branches</Title>
      <Form.List name='branches'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, isListField, fieldKey, ...restField }) => (
              <Row key={key} gutter={8}>
                <Col span={24}>
                  <FloatLabel label='Name' value={arrayBranches[name]?.name}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'The name is required' }]}
                    >
                      <Input />
                    </Form.Item>
                  </FloatLabel>
                </Col>
                <Col span={24}>
                  <Paragraph style={{ marginBottom: 0 }} level={5}>Default currency</Paragraph>
                  <div id='currency-select-3'>
                    <FloatLabel label='Default Currency' value={arrayBranches[name]?.divisa}>
                      <SelectCurrencyFormItem
                        {...restField}
                        name={[name, 'divisa']}
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'This fieldis required' }]}
                      />
                    </FloatLabel>
                  </div>
                </Col>
                <Col span={24}>
                  <FullAddressFormItem
                    restField={restField} name={name} branch={arrayBranches[name]}
                  />
                </Col>
                <Col span={6}>
                  <FloatLabel label='Phone Code' value={arrayBranches[name]?.phone_code}>
                    <SelectPhoneCodeFormItem
                      {...restField}
                      name={[name, 'phone_code']}
                    />
                  </FloatLabel>
                </Col>
                <Col span={9}>
                  <FloatLabel label='Phone' value={arrayBranches[name]?.tel}>
                    <Form.Item
                      {...restField}
                      name={[name, 'tel']}
                      rules={[{
                        pattern: /[0-9]{10}/,
                        message: 'The length must be 10 digits'
                      }]}
                    >
                      <Input maxLength={10} />
                    </Form.Item>
                  </FloatLabel>
                </Col>
                <Col span={9}>
                  <FloatLabel label='WhatsApp' value={arrayBranches[name]?.whatsapp}>
                    <Form.Item
                      {...restField}
                      name={[name, 'whatsapp']}
                      rules={[{
                        pattern: /[0-9]{10}/,
                        message: 'The length must be 10 digits'
                      }]}
                    >
                      <Input maxLength={10} />
                    </Form.Item>
                  </FloatLabel>
                </Col>
                <Col span={24}>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

    </>
  )
}

export { AgencyBranchesFieldContainer }
