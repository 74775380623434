import { Button, Col, Modal, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetMyExchangeValues } from '../../bussines/hooks/currencies/useGetMyExcahngeValues'
import { URL_CURRENCIES_PAGE } from '../../pages/dashboards/currencies'
import { showErrorModal } from '../../utils/propsForModal'
import { ExchangeIcon } from '../assets/images/icons/ExchangeIcon'
import { useAuth } from '../../providers/auth/auth-context-provider'

const ExchangeRatesModal = () => {
  const { user } = useAuth()
  const [mdl, setMdl] = useState()
  const [currency, setCurrency] = useState()
  const [modal, contextHolder] = Modal.useModal()
  const [exchangesList, setExchangesList] = useState([])

  const { data, error, isLoading, isError } = useGetMyExchangeValues()

  const opnMdl = () => {
    setMdl(true)
  }

  const clsMdl = () => {
    setMdl(false)
  }

  useEffect(() => {
    if (!isLoading && error && isError) {
      showErrorModal(modal, error.response.data.message)
    }
  }, [isLoading, error])

  useEffect(() => {
    if (data) {
      const firsExchanges = data.exchanges?.slice(0, 12)
      setExchangesList(firsExchanges)
      setCurrency(data.currency)
    }
  }, [data])

  return (
    <>
      {contextHolder}
      <a>
        <span onClick={opnMdl} className='align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold' }}>
          <ExchangeIcon /> Exchanges rate
        </span>
      </a>
      <Modal
        visible={mdl}
        onCancel={clsMdl}
        className='modal-without-border-radius'
        footer=''
        width='60vw'
      >
        <h3 className='byp-title'>Exchange rates: {data?.date}</h3>
        <h5 className='byp-title byp-fw-bold'>Base currency: ({currency?.code}) {currency?.name} </h5>
        <Row gutter={24} style={{ border: 'solid 1px var(--blue-100)' }}>
          {exchangesList?.map((exchange) => (
            <React.Fragment key={'exch' + exchange.code}>
              <Col span={6} style={{ border: 'solid 1px var(--blue-100)' }}>
                <p style={{ textAlign: 'right', width: '100%', marginBottom: 0 }}>
                  <ExchangeIcon fill='var(--black)' />
                  <small>
                    {exchange.exchange_value && (
                      <b>
                        {/* 1 {exchange.code} = {(1 / exchange.exchange_value)?.toFixed(2)} {currency?.code} */}
                        1 {currency?.code} = {exchange.exchange_value} {exchange.code}
                      </b>
                    )}
                    {!exchange.exchange_value && (
                      <b>
                        1 {exchange.code} = Not {currency?.code} value registered
                      </b>
                    )}
                  </small>
                  <br /><small><b><i style={{ color: 'gray' }}>*{exchange.name}</i></b></small>
                </p>
              </Col>
            </React.Fragment>
          ))}
        </Row>
        <Row style={{ justifyContent: 'end', marginTop:'5px' }}>
          <Button type='primary'  onClick={
            () => {
              if (user.role_name === 'agency-consultant') {
                return (
                  notification.error({
                    message: 'Ups, you do not have permission'
                  }))
              }
            }
          }>
            {user.role_name !== 'agency-consultant' ? (
              <Link
                to={URL_CURRENCIES_PAGE}
                state={{}}
              // className='ant-btn byp-btn-blue-100 byp-mt-1'
              >
                See all
              </Link>
            ) : (
              'See all'
            )}
          </Button>
        </Row>
      </Modal>
    </>
  )
}

export { ExchangeRatesModal }
