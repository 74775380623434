import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { SearchProvider } from '../../../../providers/search/search-context-provider'
import { TableQuotesCategory } from '../Tables/TableQuotesCategory'

const URL_ACTIVATE_QUOTES_PAGE = '/dashboard/institution/quotes'

function ActivateQuotesPage () {
  const [params] = useSearchParams()
  const category = params.get('program') ?? 'language'
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <SearchProvider>
            <TableQuotesCategory
              category={category}
            />
          </SearchProvider>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { ActivateQuotesPage, URL_ACTIVATE_QUOTES_PAGE }
