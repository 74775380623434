import React from 'react'
import { Form, Select } from 'antd'
import { useGetCourses } from '../../../bussines/hooks/school/useGetCourses'

const { Option } = Select

export const SelectCoursesFormItem = ({ filters, onChange, ...props }) => {
  const { data: courses } = useGetCourses(filters)
  const filterOption = (input, option) => {
    const customAttribute = option.props.customAttribute
    if (customAttribute && input) {
      return customAttribute.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return true
  }

  const handleOnChange = (val) => {
    if (onChange) onChange(val)
  }
  return (
    <Form.Item {...props}>
      <Select
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        // loading={isLoading}
        // mode={mode}
        size='small'
        className='byp-m-0'
        showSearch
        filterOption={filterOption}
        onChange={handleOnChange}
      >
        {/* <Option key='all' value='all' customAttribute='all'>All</Option> */}
        {courses?.data?.map(course => <Option key={course.id} value={course.id} customAttribute={course.label}>{course.label}</Option>)}
      </Select>
    </Form.Item>
  )
}
