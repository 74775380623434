import { Typography } from 'antd'
import React from 'react'
const { Paragraph, Title } = Typography

const DiscountCourseValuesView = ({ data, showJson, ...props }) => {
  if (showJson) {
    return (
      <pre style={{ maxHeight: '40vh', overflow: 'auto' }}>
        {JSON.stringify(data ?? {}, null, 2)}
      </pre>
    )
  }
  return (
    <div {...props}>
      <Title level={5}>Course: {data?.name}</Title>
      <span> {data?.discount} </span>
      <Paragraph ellipsis={{ rows: 3, expandable: false }}>
        {data?.discount_description}
      </Paragraph>
      {data?.goods?.ranges?.map((range) => (
        <span key={range.id}>
          {range.from} to {range.to} Weeks
        </span>
      ))}
    </div>
  )
}

export { DiscountCourseValuesView }
