import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { GroupEducationPartnersContainier } from './components/GroupEducationPartnersContainier'

const URL_PARTNERS_PAGE = '/partners'

function PartnersPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_EG_ADMIN, userRoles.TYPE_EG_CONSULTANT]}>
      <MainTheme activeCarousel>
        <GroupEducationPartnersContainier />
      </MainTheme>
    </PageProtected>
  )
}

export { PartnersPage, URL_PARTNERS_PAGE }
