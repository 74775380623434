import { useState } from 'react'
import state from '../../utils/state-control'
import { useMutation } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function usePostQuote () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(CourseRepository.createNewQuote, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * create
   * @param {Object} args
   */
  const create = (args) => {
    mutation.mutate(args)
  }

  return { ...mutation, state, currentState, create }
}
export { usePostQuote }
