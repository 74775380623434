import { Switch } from 'antd'
import React from 'react'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { format } from 'date-fns'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

function CampusPricesSection () {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let fees = []
    if (!formConfig.fees) {
      setFormConfig({
        ...formConfig,
        fees: []
      })
    }
    if (value) {
      fees = formConfig.fees ?? []
      fees?.push({ id, cost, frequency })
    } else {
      formConfig.fees?.map((fee) => (
        fee.id !== id ? fees.push(fee) : null
      ))
    }

    setFormConfig({
      ...formConfig,
      fees
    })
  }

  return (
    <>
      {service.getPrices()?.base_prices?.map((item) => {
        return item.name !== 'Registration Fee'
          ? (
            <RowQuoter
              key={item.id}
              colA={
                <>
                  <p style={{ marginBottom: 0 }}>
                    {item.name}
                    {!item?.mandatory && (
                      <Switch
                        onChange={(value) => { handleChange(value, item.id, item.cost, item.frequency) }}
                        className='byp-ml-1'
                        checked={service.isChecked(item.id)}
                        checkedChildren='YES'
                        unCheckedChildren='NO'
                      />
                    )}
                    {item?.mandatory ?? handleChange(true, item.id, item.cost, item.frequency)}
                  </p>
                  {
                    service.getFeeDiscounts(item.id).length > 0 && (
                      service.getFeeDiscounts(item.id)?.map((d) => (
                        <p key={d.id} className='byp-fw-bold'>
                          {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                        </p>
                      ))
                    )
                  }
                </>
              }
              colB={
                <StatisticWithDiscounts
                  discounts={service.getFeeDiscounts(item.id)}
                  amount={service.getFeeCost(item.id)?.toFixed(2)}
                  currency={service.getCurrency()}
                  subtotal={service.getFeeSubtotal(item.id)}
                  active={service.isChecked(item.id)}
                />
              }
            />
            )
          : null
      })}
    </>
  )
}

export { CampusPricesSection }
