import React, { useEffect } from 'react'
import { PublicThemeNone } from '../../../common/theme/PublicThemeNone'
import { Col, Form, Row, message, notification } from 'antd'
import { QuoteOverview } from './QuoteOverview'
import '../../../common/assets/css/main-theme.less'
import '../../../common/assets/css/quote-gallery.less'
import { useNavigate } from 'react-router-dom'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'
import InfoQuote from './InfoQuote'
import ButtonPaypal from './ButtonPaypal'
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import stateControl from '../../../bussines/utils/state-control'
import { useGetPaymentPage } from '../../../bussines/hooks/quote/useGetPaymentPage'
import { useAuth } from '../../../providers/auth/auth-context-provider'

import { URL_QUOTE_COURSE_PAGE } from '../../quote-course/QuoterCoursePage'

const QuoteApplyInfo = ({ quote, preview = false }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { data: paymentPage, isLoading: isCreatingPaymentPage, currentState: paymentPageState, getPaymetPage, error } = useGetPaymentPage()

  const handleUrl = () => {
    return URL_QUOTE_COURSE_PAGE.replace(':courseId', quote.course.id).replace(':campusId', quote.provider.campus.id)
  }

  const handleOnFinish = (values) => {
    values.amount = 0
    values.currency = 'USD'

    getPaymetPage(quote.uuid, { ...values })
  }

  const handleOnFinishFailed = () => {
    message.error('Te hace falta un dato')
  }

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        phone: user.profile?.phone
      })
    }
  }, [user])

  useEffect(() => {
    if (!isCreatingPaymentPage && paymentPageState === stateControl.STATE_SUCCESS) {
      window.location.href = paymentPage.url
    } else if (!isCreatingPaymentPage && error) {
      notification.error({
        message: error.response?.data?.message ?? 'Something went wrong, please try later'
      })
    }
  }, [isCreatingPaymentPage, paymentPageState])

  return (
    <PublicThemeNone>
      <Row style={{ marginLeft: '3.13rem', marginRight: '30px' }} className='mobile-margin'>
        <Col span={24} xs={4}>
          <button className='quote-apply__btn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px', marginBlockEnd: '10px' }} onClick={() => navigate(handleUrl())}>
            {/* <ArrowRightAltIcon style={{ transform: 'rotate(180deg)' }} /> */}
            <span className='component_none'>Volver</span>
          </button>
        </Col>
        <Col span={24}>
          <Form onFinish={handleOnFinish} form={form} layout='vertical' requiredMark={false} onFinishFailed={handleOnFinishFailed}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={12}>
                <InfoQuote quote={quote} showPhoneInput={!user?.profile?.phone} />
              </Col>
              <Col md={16} lg={12}>
                <ErrorBoundary>
                  <Row gutter={[16, 16]} justify='center'>
                    <Col span={23}>
                      <QuoteOverview
                        course={quote.course} campus={quote.provider.campus} cost={quote.cost}
                        campusCosts={quote.campus_costs} intake={quote.intake}
                        expirationDate={quote?.expiration_date} returnDate={quote.return_date}
                        arrivalDate={quote.arrival_date} quoteId={quote.uuid} preview={preview}
                        agency={quote?.agency} lodging={quote.lodging} agent={quote.agent}
                        discounts={quote.discounts} logo={quote.provider.logo}
                      />
                    </Col>

                    <Col span={23}>
                      <ButtonPaypal isLoading={isCreatingPaymentPage} />
                    </Col>
                  </Row>
                </ErrorBoundary>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </PublicThemeNone>
  )
}

export { QuoteApplyInfo }
