import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { AgencyIcon, EducationIcon, InstituionIcon, ServicesIcon } from '../../../quote-preview/assets/imgs/Icons'

const CompanyIcon = (type) => {
  switch (type) {
    case 'Agencie':
      return <AgencyIcon />
    case 'Institution':
      return <InstituionIcon />
    case 'Education group':
      return <EducationIcon />
    case 'Services':
      return <ServicesIcon />
    default:
      return <AgencyIcon />
  }
}


function plural(type) {
  switch (type) {
    case 'Agency':
      return 'Agencies'
    case 'Institution':
      return 'Institutions'
    case 'Education group':
      return 'Educational groups'
    case 'Services':
      return 'Services'
    default:
      return 'Others'
  }
}

export const TotalsGeneral = ({ data }) => {
  const [cards, setCards] = useState([])
  useEffect(() => {
    if (data) {
      const types = ['Institution', 'Agency', 'Education group', 'Others']
      const nCadrs = types.map((type) => {
        const found = data.find((itemData) => itemData.enterprise_type === type)
        if (found) return found
        return {
          quotes: 0,
          enterprise_type: type
        }
      })

      setCards(nCadrs)


    }
  }, [data])

  function Partir(number) {
    if (number.length !== 3) {
      var ladoA = number.substring(0, 3)
      var ladoB = Partir(number.substring(3, number.length))
      return (ladoA + ',' + ladoB)
    }
    else {
      return (number)
    }
  }

  function Coma(number) {
    if (number.length > 3) {
      if (number.length % 3 === 0) {
        return Partir((number.substring(number.length % 3, number.length)))
      }
      else {
        var ladoA = number.substring(0, number.length % 3)
        var ladoB = Partir((number.substring(number.length % 3, number.length)))
        return (ladoA + ',' + ladoB)
      }

    }
    else {
      return number
    }
  }

  return (
    <Row gutter={[12, 12]}>
      {cards?.map((itemData) => (
        <Col xs={24} sm={24} md={12} lg={6} key={itemData.enterprise_type}>
          <div className='totals-general__card'>
            {CompanyIcon(itemData.enterprise_type)}

            <p className='totals-general__quotes'>{Coma(itemData.quotes)}</p>
            <p className='totals-general__type'>{plural(itemData.enterprise_type)}</p>
          </div>
        </Col>
      ))}
    </Row>
  )
}
