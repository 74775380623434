import { TitleSection } from '../../../../common/components/TitleSection'
import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

function FormTitle ({ title, text }) {
  return (
    <>
      <TitleSection label={title} />
      <Text>
        {text}
      </Text><br /><br />
    </>
  )
}

export { FormTitle }
