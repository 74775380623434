import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
// import { TopBarMenuCustom } from '../../agency/components/TopBarMenuCustom'
import { FormShortTerm } from './FormShortTerm'

const URL_SHORT_TERM_PAGE = '/dashboard/upload_short_term_course'

function ShortTermPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_INSTITUTION
      ]}
    >
      <MainTheme
        showMenu
      >
        <FormShortTerm />
      </MainTheme>
    </PageProtected>
  )
}

export { ShortTermPage, URL_SHORT_TERM_PAGE }
