import { SchoolsCarrousel } from './SchoolsCarrousel'
import { IconPusOutlined } from '../../../../common/assets/svg/icons'
import { Row, Button, Form, Col } from 'antd'

/*
  sliders: [
    slide: { file, description },
    slide: { file, description },
    slide: { file, description },
  ]
  name = sliders
*/
function MenuSavePicture ({ name, form, setId, id, setGallery, gallery, setUrl, url }) {
  const thisList = Form.useWatch(name, form)
  const _name = name
  return (
    <div>
      <h4 className='byp-title' style={{ fontWeight: 'bold', paddingTop: '20px' }}>Carrousel images and text</h4>
      <p>{url?.length} out of 8 added</p>
      <Form.List
        name={_name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row gutter={12} key={key}>
                <Col span={24}>
                  <SchoolsCarrousel
                    key={index}
                    gallery={gallery}
                    list={thisList}
                    listName={_name}
                    name={name}
                    form={form}
                    remove={remove}
                    setId={setId}
                    id={id}
                    setGallery={setGallery}
                    setUrl={setUrl}
                    url={url}
                  />
                </Col>
              </Row>
            ))}
            <Button
              onClick={() => add()}
              style={{
                display: 'flex',
                width: '100%',
                backgroundColor: 'var(--blue-200)',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1.5rem 0',
                gap: '20px'
              }}
            >
              <IconPusOutlined color='white' />
              Upload slide
            </Button>
          </>
        )}
      </Form.List>
    </div>
  )
}
export { MenuSavePicture }
