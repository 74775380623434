import { Col, DatePicker, Form, Row } from 'antd'
import { addDays } from 'date-fns'
import moment from 'moment'
import { useEffect, useState } from 'react'

const SelectStartAndEndDatesFormItem = ({ handleChangeDate, startName, endName, format = 'MMMM DD, YYYY', startValue }) => {
  const [startDate, setStartDate] = useState(startValue)
  const [endDate, setEndDate] = useState()
  const form = Form.useFormInstance()

  const disabledStartDates = (current) => {
    let disabled = current < moment().startOf('day')
    if (endDate) {
      disabled = disabled || current > endDate
    }
    return disabled
  }

  const disabledEndDates = (current) => {
    let disabled = current < moment().endOf('day')
    if (startDate) {
      disabled = current < startDate
    }
    return disabled
  }

  useEffect(() => {
    if (startValue) {
      const nextDate = addDays(new Date(startValue), 1)
      const nexDay = moment(nextDate)
      setStartDate(nexDay)
      // console.log('Name ', startName) // [2, 'prices_start']
      const currentVals = form.getFieldValue('years')
      const thisIndex = startName[0]
      const newValues = currentVals?.map((year, index) => {
        if (index !== thisIndex) {
          return {
            ...year
          }
        }
        return {
          ...year,
          prices_start: nexDay
        }
      })
      // console.log('🚀 ~ file: SelectStartAndEndDatesFormItem.js:34 ~ useEffect ~ currentVals:', currentVals)
      form.setFieldsValue({
        years: newValues
      })
    }
  }, [startValue])

  return (
    <Row gutter={12} >
      <Col span={12} >
        <Form.Item
          name={startName}
          // value={startValue}
          label='Start'
          rules={[{
            required: true,
            message: 'Start date is required'
          }]}
          labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
        >
          <DatePicker
            disabledDate={disabledStartDates}
            format={format}
            onChange={(val) => {
              setStartDate(val)
              handleChangeDate(val, startName)
            }}
            disabled={!!startValue}
            style={{ width:'100%' }}

          // value={startValue}
          />
        </Form.Item>
      </Col>
      <Col span={12} >
        <Form.Item
          name={endName}
          label='End'
          rules={[{
            required: true,
            message: 'End date is required'
          }]}
          labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
        >
          <DatePicker
            disabledDate={disabledEndDates}
            format={format}
            onChange={(val) => {
              setEndDate(val)
              handleChangeDate(val, endName)
            }}
            style={{ width:'100%' }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

export { SelectStartAndEndDatesFormItem }
