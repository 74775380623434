import { Form, Select } from 'antd'
const { Option } = Select

const SelectFamilyRelationshipFormItem = ({ name, formItemProps = {}, ...selectProps }) => {
  return (
    <Form.Item
      name={name}
      {...formItemProps}
    >
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        // onChange={handleOnChange}
        {...selectProps}
      >
        <Option value='Abuelo(a)'>Abuelo(a)</Option>
        <Option value='Padre'>Padre</Option>
        <Option value='Madre'>Madre</Option>
        <Option value='Hermano(a)'>Hermano(a)</Option>
        <Option value='Tio(a)'>Tio(a)</Option>
        <Option value='Otro familiar'>Otro familiar</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectFamilyRelationshipFormItem }
