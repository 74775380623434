import api from '../../services/api'

class ShortTermRepository {
  static async short (params) {
    const response = await api.get('/short', { params })
    return response.data
  }

  static async getShort (id) {
    const response = await api.get(`/short/${id}`)
    return response.data
  }

  static async getShortYears (id) {
    const response = await api.get(`/school/campus/${id}/years`)
    return response.data
  }

  static async deletePackage ({ id }) {
    const response = await api.delete(`/packages/${id}`)
    return response.data
  }

  static async getPrices (id, params) {
    const response = await api.get(`/short/${id}/prices`, { params })
    return response.data
  }

  static async savePrices ({ id, data }) {
    const response = await api.post(`/short/${id}/prices`, data)
    return response.data
  }

  static async shortCampus () {
    const response = await api.get('/short/campus')
    return response.data
  }
}
export default ShortTermRepository
