import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useFindUserByAgency (id) {
  return useQuery(['useFindUserByAgency', id],
    () => UserRepository.findUserByAgency(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useFindUserByAgency }
