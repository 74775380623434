import { useMutation } from 'react-query'
import UserService from '../../../services/UserService'
import { useState } from 'react'
import stateControl from '../../utils/state-control'

function useForgotPassword () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)

  const { mutate, isLoading, data } = useMutation(UserService.forgotPassword, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: () => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  const onSubmit = (email) => {
    mutate({ email })
  }

  return { mutate, isLoading, onSubmit, stateControl, currentState, data }
}

export { useForgotPassword }
