import React from 'react'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { userRoles } from '../../bussines/settings/validate-user-access'
import ConfirmationQuoteInfo from './ConfirmationQuoteInfo'
import { useSearchParams } from 'react-router-dom'

const URL_CONFIRMATION_QUOTE_PAGE = '/confirmacion/quote'

function ConfirmationQuotePage () {
  const [params] = useSearchParams()
  const id = params.get('id')

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <ConfirmationQuoteInfo id={id} />
    </ValidateEnterpriseProfile>
  )
}

export { URL_CONFIRMATION_QUOTE_PAGE, ConfirmationQuotePage }
