import { Form, Select } from 'antd'
import { useGetAllUsers } from '../../../bussines/hooks/admin/useGetAllUsers'
const { Option } = Select

export function SelectAllUsersFormItem ({ name, showAll, filters, ...props }) {
  const { data: users } = useGetAllUsers(filters)

  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select {...props} defaultActiveFirstOption>
        {showAll && <Option value={null}>All</Option>}
        {users?.map((item) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}
