import React, { useEffect, useState } from 'react'
import { LoginForm } from './components/LoginForm'
import './assets/css/LoginPage.less'
import background from './assets/images/byp_login_poster.png'
import background2 from './assets/images/luca-bravo-uk.jpg'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'

const URL_LOGIN_PAGE = '/login'

const LoginPage = () => {
  const [random, setRandom] = useState(1)

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10) + 1)
  }, [])

  return (
    <div>
      <div className='desktop-screen'>
        <div className='form-container'>
          <div className='img-logo-container'>
            <AppLogoNameHeader
              style={{
                width: '100%',
                objectFit: 'contain',
                height: '100%',
                paddingBottom: '18px'
              }}
            />
          </div>
          <LoginForm />
        </div>
        <div className='img-container-login'>
          <img
            src={random <= 5 ? background : background2}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export { LoginPage, URL_LOGIN_PAGE }
