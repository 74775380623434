import { UploadLogo } from '../../../../../common/components/UploadLogo'
import { API_URL } from '../../../../../config'
import { Divider, Form, Input, Typography } from 'antd'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'
import { usePostDeleteLogo } from '../../../../../bussines/hooks/education-group/usePostDeleteLogo'
import { SelectServicesCategoriesFormItem } from '../../../../../common/components/helper-form/SelectServicesCategoriesFormItem'
import { OfficesContainer } from '../intitution-profile/components/OfficesContainer'
import { SelectAllCitiesFormItem } from '../../../../../common/components/helper-form/SelectAllCitiesFormItem'

const { TextArea } = Input
const { Title } = Typography

function EducationGroupProfileFieldsContainer ({ form, readOnly }) {
  const profilePhotoUrl = form.getFieldValue('profile_photo_url')
  const servicesCategories = Form.useWatch('service_category', form)
  // const onChange = (newValue) => {
  //   setInputValue(newValue)
  // }

  return (
    <>
      <Title level={5}><b>Basic information</b></Title>
      <br />
      <UploadLogo
        // action={`${API_URL}/upload/images`}
        action={`${API_URL}/education-group/upload/logo`}
        usePostDeleteLogo={usePostDeleteLogo}
        profileUrl={profilePhotoUrl}
      />
      <Divider />
      <FloatLabel label='Group name' value={Form.useWatch('name', form)}>
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label='Group email' value={Form.useWatch('name', form)}>
        <Form.Item
          name='email'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label='Group about' value={Form.useWatch('description', form)}>
        <Form.Item
          name='description'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <TextArea rows={4} maxLength={600} readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      <Title level={5}>Types of programs you offer</Title>

      <FloatLabel label='Select the programs you offer' value={servicesCategories}>
        <SelectServicesCategoriesFormItem
          mode='multiple'
          name='programs'
          rules={[{ required: true, message: 'This field is required' }]}
        />
      </FloatLabel>

      <Title level={5}>
        Share us where your head office is located
      </Title>
      <OfficesContainer
        name='offices'
        form={form}
      />

      <Title level={5}>Countries to where the Group sends students</Title>
      <FloatLabel label='Add countries' value={Form.useWatch('countries', form)}>
        <SelectAllCitiesFormItem
          disabled={readOnly}
          name='countries'
          mode='multiple'
          style={{ width: '100%' }}
        />
      </FloatLabel>
    </>
  )
}

export { EducationGroupProfileFieldsContainer }
