import CrmRepository from '../../repositories/CrmRepository'
import { useState } from 'react'
import stateControl from '../../utils/state-control'
import { useMutation } from 'react-query'

function useSaveCrmConfiguration () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(CrmRepository.saveConfigByCrm, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * save
   * @param data
   */
  const save = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, currentState, save }
}

export { useSaveCrmConfiguration }
