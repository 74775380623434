import React from 'react'
import { useQuote } from '../../bussines/hooks/quote/useQuote'
import { QuoteApplyInfo } from './components/QuoteApplyInfo'
import LottieCargando from '../../lotties/LottieCargando'

const QuoteApplyContent = ({ quoteUuid }) => {
  const { isLoading, data: quote } = useQuote(quoteUuid)

  if (isLoading || !quote) {
    return <LottieCargando />
  }

  return (
    <QuoteApplyInfo quote={quote} />
  )
}

export { QuoteApplyContent }
