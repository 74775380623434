import { Col, Form, Input, Row, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

const { Search } = Input
const { Option } = Select

const SchoolsTableFilters = ({ program = 'graduate', type, schools, setSchools }) => {
  const [searchFilter, setSearchFilter] = useState({})
  const [form] = Form.useForm()
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])

  function updateCouses () {
    const nSchools = schools?.filter((school) => {
      let ok = true
      if (searchFilter?.status && ok) {
        if (searchFilter?.status === 'Active') {
          ok = school.status === searchFilter?.status
        } else {
          ok = school.status !== 'Active'
        }
      }
      if (searchFilter?.search && ok) {
        ok = !!school.name?.toLowerCase().includes(searchFilter?.search?.toLowerCase())
      }
      if (searchFilter?.type && ok) {
        ok = school.properties?.sub_type === searchFilter?.type
      }
      if (searchFilter?.country && ok) {
        ok = school.country?.name === searchFilter?.country
      }
      if (searchFilter?.city && ok) {
        ok = school.city?.name === searchFilter?.city
      }
      return ok
    })
    setSchools(nSchools)
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  useEffect(() => {
    updateCouses()
  }, [searchFilter])

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  useEffect(() => {
    form.resetFields()
  }, [program])

  useEffect(() => {
    // const countries = schools?.map((school) => school.country?.name)
    // const cities = schools?.map((school) => school.city?.name)
    // setCountries([...new Set(countries)])
    // setCities([...new Set(cities)])
    const countries = []
    const cities = []
    schools?.forEach((school) => {
      if (!countries.includes(school.country?.name) && school.country?.name) {
        countries.push(school.country?.name)
      }
      if (!cities.includes(school.city?.name) && school.city?.name) {
        cities.push(school.city?.name)
      }
    })
    setCountries(countries)
    setCities(cities)
  }, [schools])

  return (
    <Form form={form}>
      <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
        <Col xs={24} md={12} lg={6}>
          <Form.Item name='search'>
            <Search
              className='byp-input-gray'
              placeholder='Search'
              onChange={(event) => debounceFilter('search', event.target.value)}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='cities'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('city', e)
                  : handleRemoveFilter('city')
              }}
              placeholder='All cities'
              className='w-100'
            >
              <Option key={0} value={null}>All cities</Option>
              {cities?.map((item) => (
                <Option key={'city_filter_' + item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='country'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('country', e)
                  : handleRemoveFilter('country')
              }}
              placeholder='All countries'
              className='w-100'
            >
              <Option key={0} value={null}>All countries</Option>
              {countries?.map((item) => (
                <Option key={'country_filter_' + item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='type'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('status', e)
                  : handleRemoveFilter('status')
              }}
              placeholder='Status'
              className='w-100'
            >
              <Option key={0} value={null}>All status</Option>
              <Option key={1} value='Active'>Active</Option>
              <Option key={2} value='Created'>Inactive</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export { SchoolsTableFilters }
