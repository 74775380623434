import { Col, Row } from 'antd'
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
// import { EditApplicationIcon } from '../../quote-preview/assets/imgs/Icons'
import { URL_APLICACION_PAGE } from '../../aplicacion'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { ValidateEnterpriseProfile } from '../../../common/components/ValidateEnterpriseProfile'
import { API_URL } from '../../../config'
// import { URL_CONFIRMATION_QUOTE_PAGE } from '../../confirmation-quote'
import RandomImage from '../../../utils/RandomImage'
import { URL_CONFIRMATION_QUOTE_PAGE } from '../../confirmation-quote'

const URL_CONFIRMATION_PAGE = '/confirmacion'

function ApplyConfirmationPage () {
  const navigate = useNavigate()

  const [params] = useSearchParams()
  const id = params.get('id')
  const course = params.get('course')
  const applicationId = params.get('application_id')

  const [downloading, setDownloading] = useState(false)

  const handleDownloadConfirm = () => {
    setDownloading(true)
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent)
    if (isMobileDevice) {
      window.location.href = `${API_URL}/preview-pdf-confirmation/${id}`
      setTimeout(() => {
        setDownloading(false)
      }, 2000)
    } else {
      navigate(URL_CONFIRMATION_QUOTE_PAGE + '?id=' + id)
    }
  }

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <RandomImage />
          </Col>

          <Col md={12} className='form-container'>
            <Row justify='center' style={{ width: '60%' }}>
              <Col xs={20} span={14} className='confirmation confirmation2'>
                <h2 style={{ fontWeight: 500, fontFamily: 'Poppins, sans-serif', fontSize: '25.55px' }}>
                  ¡Felicidades tu lugar en <br />
                  {course} ha sido apartado!
                </h2>
                <p style={{ fontWeight: 300, fontFamily: 'Poppins, sans-serif', fontSize: '14px', marginBottom: '2.5rem' }}>
                  En un periodo de 24hrs un consultor educativo,
                  te estará contactando para llenar tu formato de
                  aplicación y juntar todos los documentos
                  necesarios para tu vuaje de estudios al
                  extranjero.
                </p>
                <Col>
                  <Link to={URL_APLICACION_PAGE.replace(':id', id).replace(':applicationId', applicationId)} className='btn-application byp-mb-1' style={{ marginBottom: '10px' }}>
                    {/* <EditApplicationIcon color='white' />  */}
                    Llena tu formato de aplicación
                  </Link>
                  <Link
                    className='btn__pinkConfirm byp-mb-1'
                    disabled={downloading} onClick={(e) => {
                      e.preventDefault()
                      handleDownloadConfirm()
                    }}
                    to='#'
                  >
                    {downloading ? 'Descargando...' : 'Ver Confirmación'}
                  </Link>
                </Col>
                <div className='confirmation__go-home' style={{ fontWeight: 600, fontFamily: 'Poppins, sans-serif', fontSize: '12px' }}>
                  <Link to='/'>
                    Ir a Inicio
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </div>
    </ValidateEnterpriseProfile>
  )
}

export { ApplyConfirmationPage, URL_CONFIRMATION_PAGE }
