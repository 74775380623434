import React from 'react'
import { StepProvider } from '../../providers/stepbystep/step-service-provider'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { UserProfileStep } from './components/steps/user-profile/UserProfileStep'
import { useGetStep } from '../../bussines/hooks/user/useGetStep'
import { useUpdateUserProfile } from '../../bussines/hooks/user/useUpdateUserProfile'

const URL_USER_REGISTER = '/step-register/user'

function UserRegister () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <StepProvider useGetStep={useGetStep} usePostSteps={useUpdateUserProfile}>
        <UserProfileStep />
      </StepProvider>
    </PageProtected>
  )
}

export { UserRegister, URL_USER_REGISTER }
