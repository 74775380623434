import React, { useEffect, useState } from 'react'
import { GoogleMap, OverlayView, useLoadScript } from '@react-google-maps/api'
import mapStyles from '../../../utils/mapStyles'
import { BasicSpinner } from '../../../common/components/BasicSpinner'

const libraries = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
  libraries: ['places']
}
const ListSearchQuoteMap = ({ campusList, onClickPopup }) => {
  const [loaded, setLoaded] = useState(false)
  const [coursesFiltered, setCoursesFiltered] = useState([])
  const [zoom, setZoom] = useState(5)
  const [mapCenter, setMapCenter] = useState({
    lat: 26.631923670289932,
    lng: -104.59871392915743
  })
  const { isLoaded } = useLoadScript(libraries)

  const calculateMapCenter = (positions) => {
    if (positions.length === 0) {
      return null
    }

    let sumLat = 0
    let sumLng = 0

    for (let i = 0; i < positions?.length; i++) {
      sumLat += positions[i].lat
      sumLng += positions[i].lng
    }

    const centerLat = sumLat / positions?.length
    const centerLng = sumLng / positions?.length

    return { lat: centerLat, lng: centerLng }
  }

  const calculateZoom = (positions) => {
    if (positions?.length === 0) {
      return 12
    }

    const bounds = new window.google.maps.LatLngBounds()
    positions.forEach((position) => {
      if (position?.coords?.lat && position?.coords?.lng) {
        bounds.extend(position)
      }
    })

    const NE = bounds.getNorthEast()
    const SW = bounds.getSouthWest()

    const latDiff = Math.abs(NE.lat() - SW.lat())
    const lngDiff = Math.abs(NE.lng() - SW.lng())

    const zoomLat = Math.log2(360 / latDiff)
    const zoomLng = Math.log2(180 / lngDiff)
    let zoom = Math.min(zoomLat, zoomLng)
    zoom = Math.min(zoom, 5)
    zoom = Math.max(zoom, 3)

    return Math.round(zoom) - 1
  }

  // For no repeat popups
  useEffect(() => {
    // setCoursesFiltered(getCoursesFiltered(courses))
    const filterCampus = campusList?.filter(campus => {
      return (campus?.coords?.lat && campus?.coords?.lng)
    })
    const fileteredCampus = filterCampus?.filter((campus) => {
      const latIsValid = !isNaN(+campus?.coords?.lat)
      const lngIsValid = !isNaN(+campus?.coords?.lng)
      return (latIsValid && lngIsValid)
    })
    const mappedCampus = fileteredCampus.map((campus) => {
      return {
        ...campus,
        coords: {
          lat: Number(campus.coords.lat),
          lng: Number(campus.coords.lng)
        }
      }
    })
    const center = calculateMapCenter(mappedCampus)
    setCoursesFiltered(mappedCampus)
    setMapCenter(center)

    if (mappedCampus?.length > 0 && isLoaded) {
      const zoom = calculateZoom(mappedCampus)
      setZoom(zoom)
    }
  }, [campusList])

  // Prevent map load before popups
  useEffect(() => {
    if (isLoaded) {
      setLoaded(true)
    }
  }, [isLoaded])

  useEffect(() => {
    if (coursesFiltered?.length > 0 && isLoaded) {
      const zoom = calculateZoom(campusList)
      setZoom(zoom)
    }
  }, [coursesFiltered, isLoaded])

  if (!isLoaded) return <BasicSpinner />

  return (
    <div style={{ height: '100vh', width: '100%', paddingBottom: '10%' }}>
      <GoogleMap
        id='show-place'
        options={{
          styles: mapStyles
        }}
        mapContainerStyle={{
          width: '100%',
          height: '100%',
          borderRadius: '1rem'
        }}
        mapElement={<div style={{ height: '100%' }} />}
        center={mapCenter}
        zoom={zoom}
        // zoom={5}
      >
        {
          (loaded && coursesFiltered.length) && coursesFiltered.map((info, index) => (
            <div key={index}>
              <OverlayView
                key={index}
                position={info?.coords}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                  x: -(width / 2),
                  y: -(height / 2)
                })}
              >
                <div className='popup-container'>
                  <div className='popup-bubble-anchor'>
                    <div id='content' className='popup-bubble' onClick={() => onClickPopup(info?.id)}>
                      {info?.name}
                    </div>
                  </div>
                </div>
              </OverlayView>
            </div>
          ))
        }
      </GoogleMap>
    </div>
  )
}

export { ListSearchQuoteMap }
