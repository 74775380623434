import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { crypt } from '../../bussines/utils/basicEncript'
import { URL_CLIENT_PAGE } from './ClientPage'
import LottieCargando from '../../lotties/LottieCargando'

const URL_CLIENT_REDIRECT_PAGE = '/redirect/:studentId/:agentId'

function RedirectToClientPage () {
  const navigate = useNavigate()
  const { studentId: cryptStudentId, agentId: cryptAgentId } = useParams()
  const [studentId, setStudentId] = useState()
  const [agentId, setAgentId] = useState()

  useEffect(() => {
    if (cryptStudentId) {
      setStudentId(crypt('qyp2023', cryptStudentId))
    }
    if (cryptAgentId) {
      setAgentId(crypt('qyp2023', cryptAgentId))
    }
  }, [cryptStudentId, cryptAgentId])

  useEffect(() => {
    if (agentId && studentId) {
      let url = URL_CLIENT_PAGE.replace(':id', studentId)
      url = url.replace(':agent', agentId)
      navigate(url)
    }
  }, [studentId, agentId])

  return (
    <LottieCargando />
  )
}

export { RedirectToClientPage, URL_CLIENT_REDIRECT_PAGE }
