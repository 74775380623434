import { useMutation } from 'react-query'
import state from '../../../utils/state-control'
import { useState } from 'react'
import DiscountRepository from '../../../repositories/DiscountRepository'

function usePostSaveDiscountValues () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(DiscountRepository.saveValues, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const saveDiscountValues = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, saveDiscountValues }
}

export { usePostSaveDiscountValues }
