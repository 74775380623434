import { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ConceptCostFrequencyListFormItem } from './ConceptCostFrequencyListFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'

function HighSeasonsFormItem ({ name, divisa, setEnableBtn }) {
  const [dateFinal, setDateFinal] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [msjInput, setMsjInput] = useState('')
  const listName = name

  useEffect(() => {
    let value = 'false'
    let msj = ''
    if (dateFinal < dateStart) {
      value = 'true'
      msj = 'error'
      validatorDateEnd(value)
      setMsjInput(msj)
      setEnableBtn(value)
    }
    setEnableBtn(value)
    value = 'false'
    setMsjInput(msj)
  }, [dateFinal])

  const validatorDateEnd = (val) => {
    if (val === 'true') {
      notification.error({
        message: 'You cannot enter an end date earlier than the start date.',
        placement: 'topRight',
        duration: '10'
      })
    }
  }

  return (
    <div className='w-100'>
      <Form.List
        name={name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key}>
                <Row gutter={16}>
                  <Col span={24}>
                    <p className='byp-fw-bold'>Exact dates of the peak season</p>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'hs_start']}
                      label='Start'
                    >
                      <Input type='date' onChange={(e) => setDateStart(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, 'hs_end']}
                      label='End'
                    >
                      <Input type='date' onChange={(e) => setDateFinal(e.target.value)} status={msjInput} min={dateStart} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <p className='byp-fw-bold'>Determine the costs of the peak season</p>
                  </Col>
                  <Col span={24}>
                    <ConceptCostFrequencyListFormItem
                      name={[name, 'fees']}
                      divisa={divisa}
                      parentName={listName}
                      parentIndex={name}
                    />
                  </Col>
                </Row>
                <Row>
                  <AddAndRemoveListButtons
                    fields={fields}
                    index={index}
                    add={add}
                    remove={remove}
                    name={name}
                    labelAdd='Add peak season'
                    labelDelete='Delete peak season'
                  />
                </Row>
              </div>
            ))}
            {
              fields.length === 0 && (
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { HighSeasonsFormItem }
