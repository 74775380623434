import { Button, Col, Form, Input, InputNumber, Row } from 'antd'
import { AddAndRemoveListButtons } from '../../Institution/components/AddAndRemoveListButtons'
import { PrefixLabelSelector } from '../../Institution/components/PrefixLabelFormItem'
import { PlusOutlined } from '@ant-design/icons'

const WeeklyCostComponent = ({ name = 'weeks', currency = 'CAD', rules, showListButtons = true }) => {
  const prefixSelector = <PrefixLabelSelector divisa={currency} />
  console.log('🚀 ~ file: PrefixLabelSelector ~ currency:', currency)

  return (
    <>
      Number of weeks
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} style={{ width: '100%' }} gutter={24}>
                <Col span={7}>
                  <Form.Item {...restField} name={[name, 'week']} rules={rules}>
                    <Input className='byp-input-border' />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item {...restField} name={[name, 'cost']}>
                    <InputNumber
                      addonBefore={prefixSelector}
                      min={0}
                      rules={rules}
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                {showListButtons && (
                  <AddAndRemoveListButtons
                    fields={fields}
                    index={index}
                    add={add}
                    remove={remove}
                    name={name}
                  />
                )}
              </Row>
            ))}
            {fields.length === 0 && showListButtons && (
              <div className='w-100'>
                <Form.Item>
                  <Button
                    className='byp-btn-blue-100-outline float-right'
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Form.Item>
              </div>
            )}
          </>
        )}
      </Form.List>
    </>
  )
}

export { WeeklyCostComponent }
