import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { URL_DASHBOARD_PAGE } from '..'
import { useDeleteInvitation } from '../../../bussines/hooks/invitation/useDeleteInvitation'
import { useFindInvitation } from '../../../bussines/hooks/invitation/useFindInvitation'
import { usePostUpdateInvitation, usePostUpdateInvitationAndSend } from '../../../bussines/hooks/invitation/useUpdateInvitation'
import { usePostUpdateUser } from '../../../bussines/hooks/school/usePostUpdateUser'
import { useDeleteUser } from '../../../bussines/hooks/user/useDeleteUser'
import { useFindUser } from '../../../bussines/hooks/user/useFindUser'
import { useGetUserAndInvitationPaginated } from '../../../bussines/hooks/user/useGetUserAndInvitation'
import { usePostUserInvitation } from '../../../bussines/hooks/user/usePostUserInvitation'
import { SelectBranchesFormItem } from '../../../common/components/helper-form/SelectBranchesFormItem'
import { SelectCampusFormItem } from '../../../common/components/helper-form/SelectCampusFormItem'
import { SelectRolFormItem } from '../../../common/components/helper-form/SelectRolFormItem'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { TableUsers } from './TableUsers'
import { decrypt } from '../../../bussines/utils/basicEncript'

const { Search } = Input
const { Option } = Select

const required = { required: true, message: 'This element is required' }
const email = { type: 'email', message: 'This should be an email' }

let readOnly = true
let canDelete = true

function FormUser () {
  const [form] = Form.useForm()
  const { user: _user } = useAuth()
  const [modal, contextHolder] = Modal.useModal()
  const { id } = useParams()
  const [userId, setUserId] = useState(id ? decrypt('qyp2023', id + '') : undefined)

  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [paginationData, setPaginationData] = useState({})
  const [invitationId, setInvitationId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [filters, setFilters] = useState({})

  // const { data: invitationList, isLoading: isLoadingInvitations, refetch } = useGetUserAndInvitation(filters)
  const { data: invitationList, isLoading: isLoadingInvitations, refetch } = useGetUserAndInvitationPaginated({ page, ...filters })
  const { data: invitation, isLoading: isLoadingInvitation } = useFindInvitation(invitationId)
  const { data: user, isLoading: isLoadingUser } = useFindUser(userId)
  const { saveInvitation, isLoading: isSaving, data: saveResp, error: errorAtSaving } = usePostUserInvitation()
  const { updateInvitation, isLoading: isUpdating, data: updateResp } = usePostUpdateInvitation()
  const { updateAndSendInvitation, isLoading: isUpdatingAndSend, data: updateAndSendResp } = usePostUpdateInvitationAndSend()
  const { updateUser, isLoading: isUpdatingUser, data: updateUserResp } = usePostUpdateUser()
  const { deleteInvitation, isLoading: isDeletingInvitation, data: deleteInvitationResp } = useDeleteInvitation()
  const { deleteUser, isLoading: isDeletingUser, data: deleteUserResp } = useDeleteUser()

  const onFinish = (values) => {
    console.log('🚀 ~ file: FormUser.js:55 ~ onFinish ~ values:', values)
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    if (_user?.type === 'Education group') {
      values.is_education = true
    }
    if (invitationId) {
      values.id = invitationId
      updateInvitation(invitationId, values)
      return
    }
    if (userId) {
      values.id = userId
      updateUser(userId, values)
      return
    }
    saveInvitation(values)
  }

  const handleSendEmail = () => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    const values = form.getFieldsValue()
    if (invitationId) {
      values.id = invitationId
      updateAndSendInvitation(invitationId, values)
      return true
    }
  }

  const find = (id, type) => {
    if (type === 'invitation') {
      setInvitationId(id)
      setUserId()
    } else {
      setUserId(id)
      setInvitationId()
    }
    setIsUpdateMode(true)
  }

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...filters,
      [key]: value
    }
    setFilters(nFilters)
  }

  const handleRemoveFilter = (key) => {
    setFilters(filters => {
      const copy = { ...filters }
      delete copy[key]
      return copy
    })
  }

  useEffect(() => {
    if (!isLoadingInvitations && invitationList) {
      console.log('🚀 ~ file: FormUser.js:119 ~ useEffect ~ invitationList:', invitationList)
      setData(invitationList.data)
      setPaginationData({
        page: invitationList.meta?.currentPage,
        total: invitationList.meta?.total,
        pageSize: invitationList.meta?.per_page,
        size: invitationList?.meta?.last_page,
        current: data?.meta?.current_page,
        onChange: (val) => setPage(val)
      })
    }
  }, [invitationList, isLoadingInvitations])

  useEffect(() => {
    if (!isLoadingInvitation && invitation) {
      form.setFieldsValue(invitation)
    }
  }, [isLoadingInvitation, invitation])

  useEffect(() => {
    if (!isLoadingUser && user) {
      setInvitationId()
      if (user?.sede === _user.sede && _user.type !== 'Agency') {
        user.sede = 'main'
      }
      form.setFieldsValue(user)
    }
  }, [isLoadingUser, user])

  useEffect(() => {
    if (!isUpdatingUser && updateUserResp?.updated) {
      form.resetFields()
      setIsUpdateMode(false)
      setInvitationId()
      setUserId()
      refetch()
      modal.success({
        title: 'Succes',
        content: 'Data saved successfully'
      })
    }
  }, [isUpdatingUser, updateUserResp])

  useEffect(() => {
    if (!isUpdating && updateResp?.updated) {
      form.resetFields()
      setIsUpdateMode(false)
      setInvitationId()
      refetch()
      modal.success({
        title: 'Succes',
        content: 'Data saved successfully'
      })
    }
  }, [isUpdating, updateResp])

  useEffect(() => {
    if (!isUpdatingAndSend && updateAndSendResp?.updated) {
      form.resetFields()
      setIsUpdateMode(false)
      setInvitationId()
      refetch()
      modal.success({
        title: 'Succes',
        content: 'Email sent'
      })
    }
  }, [isUpdatingAndSend, updateAndSendResp])

  useEffect(() => {
    if (!isSaving && saveResp?.created) {
      form.resetFields()
      refetch()
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
    }
    if (!isSaving && errorAtSaving) {
      // form.resetFields()
      // refetch()
      modal.error({
        title: 'Something went wrong',
        content: errorAtSaving.response?.data?.message
      })
    }
  }, [isSaving, saveResp, errorAtSaving])

  useEffect(() => {
    if (!isDeletingUser && deleteUserResp) {
      form.resetFields()
      refetch()
      modal.success({
        title: 'Succes',
        content: 'User deleted'
      })
    }
  }, [isDeletingUser, deleteUserResp])

  useEffect(() => {
    if (!isDeletingInvitation && deleteInvitationResp) {
      form.resetFields()
      refetch()
      modal.success({
        title: 'Succes',
        content: 'Invitation deleted'
      })
    }
  }, [isDeletingInvitation, deleteInvitationResp])

  useEffect(() => {
    const roles = _user?.role_names ?? []
    if (user?.type === 'Agency') {
      if (roles.includes('agency-admin') || roles?.includes('agency-editor')) {
        readOnly = false
      }
      if (roles.includes('agency-office-manager')) {
        readOnly = true
      }
      if (roles.includes('agency-editor')) {
        canDelete = false
        readOnly = false
      }
    } else if (_user?.type === 'Super Admin') {
      if (roles.includes('super-editor')) {
        canDelete = false
        readOnly = false
      } else {
        canDelete = true
        readOnly = false
      }
    } else {
      canDelete = true
      readOnly = false
    }
  }, [_user])

  return (
    <>
      {contextHolder}
      <Row className='byp-dashboard-content' gutter={12}>
        <Col xs={24} md={10}>
          <Form
            layout='horizontal'
            scrollToFirstError
            name='UserForm'
            onFinish={onFinish}
            form={form}
            initialValues={{
            }}
            labelCol={{
              span: 6
            }}
            labelAlign='left'
            requiredMark={false}
          >
            <h3 className='byp-title'>Register your users </h3>
            <p>
              Invite your staff so they can start quoting through QYP
            </p>
            <h6 className='byp-title byp-fw-bold'>Information </h6>

            <Form.Item name='name' label='Name' rules={[required]}>
              <Input placeholder='Name' readOnly={readOnly} />
            </Form.Item>

            <Form.Item name='email' label='Email' rules={[required, email]}>
              <Input placeholder='user@email.com' readOnly={readOnly} />
            </Form.Item>

            {
              _user?.type === 'Institution' && (
                <SelectCampusFormItem
                  name='sede'
                  label='Campus'
                  single
                  disabled={readOnly}
                  showInstitution
                />
              )
            }

            {
              _user?.type === 'Agency' && (
                <SelectBranchesFormItem
                  name='sede'
                  label='Office'
                  disabled={readOnly}
                />
              )
            }

            <SelectRolFormItem
              name='role'
              type={_user?.type}
              label='Role'
              disabled={readOnly}
            />

            <Button
              className='byp-btn-blue-200  float-right'
              htmlType='submit'
              loading={isSaving || isUpdating || isUpdatingAndSend || isUpdatingUser}
            >
              {isUpdateMode ? 'Save' : 'Send Invitation'}
            </Button>
            {
              isUpdateMode && invitationId && 'In Process' && (
                <Button
                  className='byp-btn-blue-200  float-right'
                  onClick={handleSendEmail}
                  loading={isUpdatingAndSend}
                >
                  Resend Invitation {user?.status}
                </Button>
              )
            }
          </Form>
        </Col>
        <Col xs={0} md={14}>
          <Row gutter={6}>
            <Col span={6}>
              <Search
                className='byp-input-gray'
                placeholder='Search'
                onChange={(value) => handleAddFilter('search', value.target.value)}
              />
            </Col>
            <Col span={6}>
              <Select
                className='w-100' size='small'
                onChange={(value) => {
                  value ? handleAddFilter('status', value) : handleRemoveFilter('status')
                }}
                allowClear
                placeholder='All the status'
              >
                <Option value={null}>All Status</Option>
                <Option value='Active'>Active</Option>
                <Option value='In Process'>In Process</Option>
                <Option value='Inactive'>Inactive</Option>
              </Select>
            </Col>
            <Col span={6}>
              {
                _user?.type === 'Institution' && (
                  <SelectCampusFormItem
                    single
                    size='small'
                    onChange={(value) => {
                      value ? handleAddFilter('sede', value) : handleRemoveFilter('sede')
                    }}
                    haveOptionAllCampus
                    placeholder='All the campus'
                  />
                )
              }

              {
                _user?.type === 'Agency' && (
                  <SelectBranchesFormItem
                    noStyle
                    size='small'
                    onChange={(value) => {
                      value ? handleAddFilter('sede', value) : handleRemoveFilter('sede')
                    }}
                    showOptionAll
                    placeholder='All the offices'
                  />
                )
              }
            </Col>
            <Col span={6}>
              <SelectRolFormItem
                type={_user?.type}
                size='small'
                className='w-100'
                showAll
                noStyle
                onChange={(value) => {
                  value ? handleAddFilter('role', value) : handleRemoveFilter('role')
                }}
                placeholder='All the rols'
              />
            </Col>
          </Row>
          <br />
          <TableUsers
            sedeLabel={_user?.type === 'Institution' ? 'Campus' : 'Office'}
            data={data ?? []} setId={find}
            setPage={setPage}
            paginationData={paginationData}
            deleteUser={deleteUser}
            deleteInvitation={deleteInvitation}
            isDeleting={isDeletingInvitation || isDeletingUser}
            setFilters={setFilters} filters={filters}
            refetch={refetch}
            readOnly={readOnly}
            canDelete={canDelete}
            isLoading={isLoadingInvitations}
          />
        </Col>
      </Row>
      <Row className='byp-dashboard-footer' gutter={12}>
        <Col span={24} className='byp-form-footer' style={{ paddingTop: '.5rem' }}>
          <Link
            to={URL_DASHBOARD_PAGE}
            state={{}}
            className='ant-btn byp-btn-blue-100 float-right'
          > Next
          </Link>
        </Col>
      </Row>
    </>
  )
}

export { FormUser }
