import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import { useState } from 'react'
import FeeRepository from '../../repositories/FeeRepository'

function useSaveFeeExtraInfo () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(FeeRepository.saveExtraInfo, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const save = (fee, data) => {
    mutation.mutate({ fee, data })
  }

  return { ...mutation, state, currentState, save }
}

export { useSaveFeeExtraInfo }
