import { useQuery } from 'react-query'
import PackagesRepository from '../../repositories/PackagesRepository'
import ShortTermRepository from '../../repositories/ShortTermRepository'

function useFindPackage (id) {
  return useQuery(['useFindPackage', id],
    () => PackagesRepository.getPackage(id), {
      retry: 1,
      enabled: !!id
    })
}

function useFindShort (id) {
  return useQuery(['useFindShort', id],
    () => ShortTermRepository.getShort(id), {
      retry: 1,
      enabled: !!id
    })
}

function useGetShortYears (id) {
  return useQuery(['useGetShortYears', id],
    () => ShortTermRepository.getShortYears(id), {
      retry: 1,
      enabled: !!id
    })
}

export { useFindPackage, useFindShort, useGetShortYears }
