import { Row, Col, Button, Collapse, Tag, notification } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { usePostPublishFee } from '../../../../../bussines/hooks/fee/usePostPublishFee'
import stateControl from '../../../../../bussines/utils/state-control'
import { URL_CREATE_INSURANCE_PRICES_PAGE } from '../../insurancePrices/InsurancePricesPage'
import { RowCostInfo, RowInfoTags } from './RowInfo'
const { Panel } = Collapse

const InsuranceInfo = ({ insurance, refetch, year }) => {
  useEffect(() => {
    console.log('🚀 ~ file: InsuranceInfo.js:10 ~ useEffect ~ insurance', insurance)
  }, [insurance])

  const { publishFee, isLoading, currentState } = usePostPublishFee()

  const handlePublish = () => {
    publishFee(insurance.id, { year })
  }

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Status updated'
      })
    }
  }, [currentState, isLoading])

  return (
    <Row>
      <Col span={24}>
        <p>
          {insurance?.name}
          <Tag className='byp-ml-1'>
            <small>{insurance?.status} {year}</small>
          </Tag>
        </p>
        <h6><b>Confirm that every block is correct before publishing your quote</b></h6>
      </Col>
      <Col span={24}>
        <Collapse
          defaultActiveKey={['discounts', 'info']}
          expandIconPosition='left'
        >
          <Panel header='Discounts and promotions' key='discounts'>
            <></>
          </Panel>
          <Panel header='Medical Insurance information' key='info'>
            <RowInfoTags label='Program type' tags={[{ name: insurance?.program }]} />
            <RowInfoTags label='Offices' tags={insurance?.offices} />
            <RowInfoTags label='Schools' tags={insurance?.schools} />
            <hr />

            <h5 className='byp-title byp-fw-bold'>Medical insurance prices</h5>
            <span>Currency: <b>{insurance?.currency?.code}</b></span>

            {
              insurance?.costs?.map(
                (cost) => <RowCostInfo key={'cost_service_' + cost.id} label={cost.concept} cost={cost.cost} frequency={cost.frequency} />)
            }
            <hr />

            <h5 className='byp-title byp-fw-bold'>Additional concepts</h5>
            {
              insurance?.concepts?.map(
                (conscept) => conscept.name
              )
            }
            <hr />

            <h5 className='byp-title byp-fw-bold'>Additional comments</h5>
            <p>{insurance.comments}</p>

            <div className='w-100'>
              <Link
                to={URL_CREATE_INSURANCE_PRICES_PAGE}
                state={{ feeId: insurance?.id }}
                className='ant-btn byp-btn-blue-100 float-right'
              > Edit block
              </Link>
            </div>
            <br />
          </Panel>
        </Collapse>
        <br />
      </Col>
      <Col span={24}>
        <Button
          onClick={handlePublish}
          loading={isLoading}
          className='byp-btn-blue-100 float-right'
        > {insurance?.status === 'Published' ? 'Unpublish' : 'Publish'}
        </Button>
      </Col>
    </Row>
  )
}

export { InsuranceInfo }
