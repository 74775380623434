import { Button, Col, Form, Row, Select } from 'antd'
import { SelectCampusFormItem } from '../../../common/components/helper-form/SelectCampusFormItem'
import { SelectCountriesFormItem } from '../../../common/components/helper-form/SelectCountriesFormItem'
import { SelectProgramsFormItem } from '../../../common/components/helper-form/SelectProgramsFormItem'

function EducationGroupInstitutionFilters ({ filters, setFilters, onSearch }) {
  const { Option } = Select
  const [form] = Form.useForm()

  const clearFilters = () => {
    form.resetFields()
    onSearch('')
  }

  const handleSearch = (values) => {
    setFilters({
      ...filters,
      ...values
    })
  }

  return (
    <Form form={form} onFinish={handleSearch}>
      <Row gutter={24}>
        <Col style={{ color: 'var(--blue-100)', marginTop: '3px' }}>
          <small>
            <b>Search by</b>
          </small>
        </Col>
        <Col span={6}>
          <SelectProgramsFormItem
            label='Program' name='program'
            onChange={(program) => {
              // setFilters({ ...filters, program })
              // form.setFieldsValue({
              //   school: undefined
              // })
            }}
            isReports
          />
        </Col>
        <Col span={6}>
          <SelectCampusFormItem
            name='school'
            label='School'
            showSearch
            // onChange={(campus) => {
            // }}
            single
            allowClear
          />
        </Col>
        <Col span={5}>
          <Form.Item label='Status' name='status'>
            <Select
              placeholder='Status'
              onChange={(status) => {
                setFilters({ ...filters, status })
              }}
            >
              <Option key='Active' value='Active'>
                Active
              </Option>
              <Option key='Inactive' value='Inactive'>
                Inactive
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <SelectCountriesFormItem
            label='Country'
            name='country'
            onChange={(country) => {
              // do not delete this :3
            }}
            showSearch
            global
          />
        </Col>
        <Col style={{ gap: '10px', display: 'flex', marginBottom: '30px' }}>
          <Button
            style={{
              backgroundColor: 'lightgray',
              color: 'black',
              fontWeight: 'bold'
            }}
            onClick={clearFilters}
          >
            Clear
          </Button>
          <Button
            style={{
              backgroundColor: 'var(--blue-100)',
              color: 'white',
              fontWeight: 'bold'
            }}
            htmlType='submit'
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { EducationGroupInstitutionFilters }
