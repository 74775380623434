import React, { useEffect, useState } from 'react'
import { Col, Row, Statistic, notification } from 'antd'
import { useGetCurrencyToCurrency } from '../../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { useAuth } from '../../../providers/auth/auth-context-provider'

export function QuoteOverviewTotals ({ totals, course }) {
  const [currency, setCurrency] = useState('MXN')
  const { data: newdata, getCurrencyToCurrency, isLoading } = useGetCurrencyToCurrency()
  const { userLocalCurrency } = useAuth()

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, currency)
  }, [currency])

  useEffect(() => {
    if (totals) {
      totals?.forEach(total => {
        if (total.total !== '-') {
          setCurrency(total?.divisa)
          console.log('🚀 ~ file: QuoteOverviewTotals.js:20 ~ useEffect ~ total?.divisa:', total?.divisa)
        }
      })
    }
  }, [totals])

  useEffect(() => {
    if (!isLoading && newdata) {
      console.log('🚀 ~ file: QuoterForm.js:144 ~ useEffect ~ newdata:', newdata)
      if (!newdata.data) {
        notification.error({
          message: 'Exchange Value Not found'
        })
      }
    }
  }, [isLoading, newdata])

  return (
    <Row justify={{ xs: 'center', lg: 'space-between' }}>
      <Col className='quote-overview__totals' md={12}>
        <p className='txt-center'>Costo Total</p>
        {course?.length
          ? (
            <p>Curso de {course?.length}</p>
            )
          : (
            <p>Curso de {course?.weeks} semanas</p>
            )}
      </Col>
      <Col md={12} className='mobile-margin'>
        <Row justify={{ xs: 'center', lg: 'end' }}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={24} className='mobile-center' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {totals?.map((total, index) => (
              <React.Fragment key={index}>
                {total?.total !== '-' && (
                  <div className='quote-overview__prices price_1'>
                    <Statistic className='custom-statistic' prefix='$' value={`${total.total}`} precision={2} /> {total?.divisa}
                  </div>
                )}
              </React.Fragment>
            ))}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={24} className='mobile-center' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {totals?.map((total, index) => (
              <React.Fragment key={index}>
                {total?.total !== '-' && (
                  <div className='quote-overview__prices price_2'>
                    <span style={{ fontSize: '0.7rem', fontWeight: '500' }}>Aprox.</span> <Statistic className='custom-statistic2' prefix='$' value={`${newdata ? (total.total * newdata.data?.value) : total.total}`} precision={2} suffix={userLocalCurrency} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </Col>
          <Col xs={{ span: 24, order: 3 }} className='referencia_mobile txt-center'>
            <p style={{ fontSize: '0.6875rem', fontStyle: 'italic' }}>El precio aprox. local es como referencia</p>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
