import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

function useGetPaidQuotes (filters = {}) {
  return useQuery(
    ['useGetPaidQuotes', filters],
    () => QuoteRepository.paidQuotes(filters),
    {
      retry: 2
    }
  )
}

export { useGetPaidQuotes }
