import React from 'react'
import { Col, Form, Row, Input, Button } from 'antd'
import { AddAndRemoveListButtons } from '../../Institution/components/AddAndRemoveListButtons'

export const FormAdditional = ({ name, divisa }) => {
  return (
    <Form.List
      name={name}
      initialValue={[
        {}
      ]}

    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} span={24} gutter={12}>
              <Col span={21}>
                <Form.Item
                label='Concept'
                  name={[name, 'aditional_information']}
                >
                  <Input placeholder='Concept' />
                </Form.Item>
              </Col>

              <Col span={3} style={{ display: 'flex', gap: '20px' }}>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Col>
            </Row>
          )
          )}

          {
          fields.length === 0 && (
            <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()}>
              Add
            </Button>
          )
        }

        </>
      )}
    </Form.List>
  )
}
