import { Col, Form, Input, Row, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

const { Search } = Input
const { Option } = Select

const InsuranceTableFilter = ({  insurances, setInsurances }) => {
  const [searchFilter, setSearchFilter] = useState({})
  const [form] = Form.useForm()
  const [offices, setOffices] = useState([])
  const [programs, setPrograms] = useState([])


  function updateCouses () {
    const ninsurances = insurances?.filter((insurance) => {
      let ok = true

      if (searchFilter?.search && ok) {
        ok = !!insurance.name?.toLowerCase().includes(searchFilter?.search?.toLowerCase())
      }
      if (searchFilter?.programs && ok) {
        ok = insurance.program === searchFilter?.programs
      }
      if (searchFilter?.offices && ok) {
        const current_offices = []
        insurance.offices?.forEach((office) => {
          current_offices.push(office.name)
        })
        current_offices.find(element => element == searchFilter?.offices) ? ok = true : ok = false
      }
      return ok
    })
    setInsurances(ninsurances)
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  useEffect(() => {
    updateCouses()
  }, [searchFilter])

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }



  useEffect(() => {

    const offices_arr = []
    const programs_arr = []
    insurances?.forEach((insurance) => {

      insurance.offices?.forEach((office) => {
        if (!offices_arr.includes(office.name) && office.name) {
        offices_arr.push(office.name)
        }
      })
      
      if (!programs_arr.includes(insurance.program) && insurance.program) {
        programs_arr.push(insurance.program)
      }
    })
    setOffices(offices_arr)
    setPrograms(programs_arr)
  }, [insurances])

  return (
    <Form form={form}>
      <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
        <Col xs={24} md={12} lg={8}>
          <Form.Item name='search'>
            <Search
              className='byp-input-gray'
              placeholder='Search'
              onChange={(event) => debounceFilter('search', event.target.value)}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={8} >
          <Form.Item name='offices'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('offices', e)
                  : handleRemoveFilter('offices')
              }}
              placeholder='All offices'
              className='w-100'
            >
              <Option key={0} value={null}>All offices</Option>
              {offices?.map((item) => (
                <Option key={'office_filter_' + item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={8}>
          <Form.Item name='programs'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('programs', e)
                  : handleRemoveFilter('programs')
              }}
              placeholder='All programs'
              className='w-100'
            >
              <Option key={0} value={null}>All programs</Option>
              {programs?.map((item) => (
                <Option key={'program_filter_' + item} value={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>
    </Form>
  )
}

export { InsuranceTableFilter }
