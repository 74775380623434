import React, { useEffect, useState } from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { Row, Col, Form } from 'antd'
import { SchoolForm } from './SchoolForm'
import { SchoolsTable } from './SchoolsTable'
import { useGetAllCampus } from '../../../../bussines/hooks/school/useGetAllCampus'
import { Link, useParams } from 'react-router-dom'
import { URL_UPLOAD_PRICES_PAGE } from '../Prices/UploadPricesPage'
import { useGetCampus } from '../../../../bussines/hooks/school/useGetCampus'
import moment from 'moment'

const URL_INSTITUTION_SCHOOLS_PAGE = '/dashboard/shools/:program'

function CreateSchoolsPage () {
  const { program } = useParams()
  const [form] = Form.useForm()
  const [campusId, setCampusId] = useState()
  const [address, setAddress] = useState()
  const [coords, setCoords] = useState([])
  const [page, setPage] = useState(1)

  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const { isLoading, data: resp, refetch } = useGetAllCampus(program && { program, page })
  const { data: campusData, isFetching: loadingCampus } = useGetCampus(campusId)

  const translationSelected = Form.useWatch('translation', form)

  const handleSetCampusId = (id) => {
    setCampusId(id)
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const translations = campusData?.translations ?? []
      const langTranslations = translations.filter(translation => translation.to === lang)
      // const nameTraslated = langTranslations.find(translation => translation.attribute === 'name')
      const descTraslated = langTranslations.find(translation => translation.attribute === 'description')
      form.setFieldsValue({
        // name: nameTraslated?.translation,
        description: descTraslated?.translation,
        translation: lang
      })
    } else {
      setFormData()
    }
  }

  const setFormData = () => {
    const coords = { lat: campusData.lat, lng: campusData.lng }
    setCoords(coords)
    console.log('🚀 ~ file: CreateSchoolsPage.js ~ line 31 ~ useEffect ~ campusData', campusData)
    const years = campusData.years?.map(yrs => ({
      id: yrs.id,
      year: moment(yrs.year),
      start: moment(yrs.start),
      end: moment(yrs.end),
      prices_start: moment(yrs.prices_start),
      prices_end: moment(yrs.prices_end)
    }))
    years.length ? campusData.years = years : campusData.years = [{}]

    const newImages = campusData.images.map(img => ({
      uid: img.id,
      name: img.name ?? img.url,
      status: 'done',
      url: img.profile_photo_url,
      response: {
        id: img.id
      }
    }))

    campusData.isGroup = !!campusData.group_name
    campusData.school = {
      city: campusData.city_id,
      country: campusData.country_id
    }

    form.setFieldsValue(campusData)
    form.setFieldsValue({
      images: newImages,
      country: campusData.data,
      translation: null
    })
    setIsUpdateMode(true)
    setAddress(campusData.address)
  }

  useEffect(() => {
    if (!loadingCampus && campusData) {
      setFormData()
    }
  }, [loadingCampus, campusData])

  useEffect(() => {
    if (campusData) {
      handleChangeTranslateLanguage(translationSelected)
    }
  }, [translationSelected])

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <Row gutter={16}>
            <Col xs={24} md={12} lg={12} style={{ paddingRight: '20px', paddingLeft: '0px' }}>
              <SchoolForm
                program={program}
                refetch={refetch}
                campusId={campusId}
                form={form}
                address={address}
                setAddress={setAddress}
                isUpdateMode={isUpdateMode}
                setIsUpdateMode={setIsUpdateMode}
                setCampusId={handleSetCampusId}
                campusCoords={coords}
                campusCountry={campusData?.country}
                campusCountryCode={campusData?.country_code}
                logo={campusData?.logo}
              />
            </Col>
            <Col xs={24} md={12} lg={12} className='byp-bg-gray50 byp-border-top-radius p-0'>
              <SchoolsTable
                isLoading={isLoading}
                loadingCampus={loadingCampus}
                data={resp}
                refetch={refetch}
                setCampusId={handleSetCampusId}
                form={form}
                setIsUpdateMode={setIsUpdateMode}
                campus={campusData}
                program={program}
                setPage={setPage}
              />
            </Col>
          </Row>
        </div>
        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              <Link
                to={URL_UPLOAD_PRICES_PAGE}
                state={{ menuItemSelected: 5, openSub: 'escuelas' }}
                className='byp-btn byp-btn-blue-100 float-right'
              > Next
              </Link>
            </Col>
          </Row>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { CreateSchoolsPage, URL_INSTITUTION_SCHOOLS_PAGE }
