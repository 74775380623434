import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetCampusByProgram (program) {
  return useQuery(['useGetCampusByProgram', program],
    () => CampusRespository.getCampusByProgram(program), {
      retry: 2,
      disabled: !!program
    })
}

export { useGetCampusByProgram }
