import { Form, Select } from 'antd'
import { useGetFeesByCategory } from '../../../bussines/hooks/fee/useGetFeesByCategory'
const { Option } = Select

export function SelectFeeByCategory ({ name, type, noStyle, showAll, filters, ...props }) {
  const { data: fees } = useGetFeesByCategory(type, filters)

  return (
    <Form.Item
      name={name}
      {...props}
      noStyle={noStyle}
    >
      <Select {...props} defaultActiveFirstOption>
        {showAll && <Option value={null}>All Roles</Option>}
        {fees?.data?.map((item) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}
