import { Button, Col, Form, Row, Typography, notification } from 'antd'
import { BasicFormListItem } from '../../../../../common/components/BasicFormListItem'
import { TableCourses } from '../../Tables/TableCourses'
import { useGetCourses } from '../../../../../bussines/hooks/school/useGetCourses'
import { useEffect, useState } from 'react'
import { useFindCourse } from '../../../../../bussines/hooks/school/useFindCourse'
import { useSaveCourseExtraInfo } from '../../../../../bussines/hooks/course/useSaveCourseExtraInfo'
import stateControl from '../../../../../bussines/utils/state-control'
import { SelectTagsFormItem } from './SelectTagsFormItem'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { SelectCoursesFormItem } from '../../../../../common/components/helper-form/SelectCoursesFormItem'
import TextArea from 'antd/lib/input/TextArea'
import { SelectAvailableTranslationsFormItem } from '../../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'
import { showErrorModal } from '../../../../../utils/errorModal'

const { Title } = Typography
const FormExtraInfo = ({ program, type }) => {
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState({})
  const [courseID, setCourseID] = useState()
  const { isLoading, data: courses, refetch } = useGetCourses(program && { program, page, type: type ?? null, ...searchFilter })
  const { data: course, isLoading: isLoadingCourse } = useFindCourse(courseID)
  const { save, isLoading: isSaving, currentState } = useSaveCourseExtraInfo()

  const handleFinish = (values) => {
    console.log('🚀 ~ file: FormExtraInfo.js:8 ~ handleFinish ~ values:', values)
    save(courseID, values)
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const tags = course.tags ?? []
      const skills = tags.filter((tag) => tag.type === 'skill').map(skill => {
        const translationFound = skill.translationables?.find(translation => translation.to === lang)
        return {
          ...skill,
          name: translationFound?.translation ?? skill.name
        }
      })
      const requirements = course.requirements?.map(requirement => {
        const translationFound = requirement.translationables?.find(translation => translation.to === lang)
        return {
          ...requirement,
          value: translationFound?.translation ?? requirement.value
        }
      })
      const targets = course.targets?.map(target => {
        const translationFound = target.translationables?.find(translation => translation.to === lang)
        return {
          ...target,
          value: translationFound?.translation ?? target.value
        }
      })
      const characteristics = course?.characteristics?.map(characteristic => {
        const translationFound = characteristic.translationables?.find(translation => translation.to === lang)
        return {
          ...characteristic,
          value: translationFound?.translation ?? characteristic.value
        }
      })
      const description = course.description_extra?.translationables?.find(translation => translation.to === lang)
      form.setFieldsValue({
        requirements: requirements?.length > 0 ? requirements : [{ value: '' }],
        targets: targets?.length > 0 ? targets : [{ value: '' }],
        characteristics: characteristics?.length > 0 ? characteristics : [{ value: '' }],
        skills: skills.map((skill) => skill.name),
        description: description?.translation
      })
    } else {
      setFormData()
    }
  }

  const setFormData = () => {
    const tags = course.tags ?? []
    const skills = tags.filter((tag) => tag.type === 'skill')
    const requirements = course.requirements
    const targets = course.targets
    const characteristics = course?.characteristics
    form.setFieldsValue({
      requirements: requirements?.length > 0 ? requirements : [{ value: '' }],
      targets: targets?.length > 0 ? targets : [{ value: '' }],
      characteristics: characteristics?.length > 0 ? characteristics : [{ value: '' }],
      skills: skills.map((skill) => skill.name),
      description: course?.description_extra?.value,
      translation: null
    })
  }

  useEffect(() => {
    form.resetFields()
    setCourseID()
  }, [program])

  useEffect(() => {
    if (!isLoadingCourse && course) {
      setFormData()
    }
  }, [isLoadingCourse, course])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      form.resetFields()
      setCourseID()
      notification.success({
        message: 'Data saved succesfully'
      })
    } else if (!isSaving && currentState === stateControl.STATE_ERROR) {
      showErrorModal(undefined)
    }
  }, [isSaving, currentState])

  return (
    <Row
      gutter={[16, 16]}
    >
      <Col xs={24} md={12} lg={14}>
        <Form
          form={form}
          onFinish={handleFinish}
          requiredMark={false}
          style={{ marginTop: '1rem' }}
        >
          {isLoadingCourse && <BasicSpinner />}
          {!course && !courseID && !isLoadingCourse && (
            <div className='w-100' style={{ minHeight: '78vh' }}>
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Course</Title>
              <SelectCoursesFormItem
                filters={{
                  program,
                  type
                }}
                onChange={(val) => {
                  setCourseID(val)
                }}
              />
            </div>
          )}
          {!!course && !!courseID && (
            <>
              <Title level={3} className='byp-color-blue-200 byp-fw-bold'>{course?.name} extra info</Title>
              <SelectAvailableTranslationsFormItem
                name='translation'
                formItemProps={{
                  noStyle: true
                }}
                selectProps={{
                  size: 'small',
                  className: 'w-100',
                  onChange: (lang) => handleChangeTranslateLanguage(lang),
                  allowClear: true
                }}
              />
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Skills the student will gain</Title>
              <SelectTagsFormItem name='skills' type='skill' />
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Student requirements</Title>
              <BasicFormListItem name='requirements' />
              {/* <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Who can take this course</Title>
              <BasicFormListItem name='targets' /> */}
              <Form.Item name='description' label='Description'>
                <TextArea autoSize maxLength={600} />
              </Form.Item>

              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Course Characteristics</Title>
              <BasicFormListItem name='characteristics' />
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                htmlType='submit'
                loading={isSaving}
              >
                {/* {isUpdateMode ? 'Update' : 'Save course'} */}
                Save
              </Button>
            </>
          )}
        </Form>
      </Col>
      <Col xs={24} md={12} lg={10}>
        <TableCourses
          program={program}
          isLoading={isLoading}
          data={courses}
          refetch={refetch}
          setPage={setPage}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          setCourseID={setCourseID}
          showDelete={false}
          showStatus={false}
          type={type}
        // isLoadingCourse={isLoadingCourse}
        // updateStatusCourse={updateStatusCourse}
        // isUpdatingStatus={isUpdatingStatus}
        />
      </Col>
    </Row>
  )
}

export { FormExtraInfo }
