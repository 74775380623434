import { useQuery } from 'react-query'
import SchoolRepository from '../../repositories/SchoolRepository'

function useGetPricesCampus (campusId, programId, filter) {
  const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetPricesCampus', campusId, programId, queryString],
    () => SchoolRepository.getCampusForUploadPrices(campusId, programId, queryString), {
      retry: 1
    })
}

export { useGetPricesCampus }
