import { Col, Row } from 'antd'
import { PublicTheme } from '../../common/theme/PublicTheme'
import { HomeSectionJ } from '../home/components/homeSectionJ'
import { HomeSectionK } from '../home/components/homeSectionK'
import header from './../../common/assets/images/banner-hero.png'
import { ConstactUsForm } from './components/ContactUsForm'

const URL_ABOUT_PAGE = '/about'

function AboutPage () {
  return (
    <>
      <PublicTheme>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '0 40px' }}>
          <img src={header} style={{ width: '90vw', height: 'auto', objectFit: 'cover', borderRadius: '.6rem' }} alt='' />
        </div>
        <div className='byp-centered text-about-us-responsive'>
          <h2 className='byp-yellow-100'><b>About us!</b></h2>
          <div>
            <p>
              Our purpose at QyP is to integrate the educational industry into a single platform in which every individual can perform quotations in an easy and fast way, with up-to-date rates.
            </p>
            <p>

              We are firm believers that studying abroad is one of the best personal and professional experiences that anyone can have, and counselors have a big responsibility in offering the right program to their clients. Having all programs and options integrated into a single platform makes it easier and faster to find the right program for each unique student.
            </p>
            <p>
              The QyP platform creates value and makes a difference when you quote a program, when you quote your school's programs, or when you offer any other service which enhances the experience for every student.
            </p>
          </div>
          <Row className='w-100 byp-mt-1' >
            <Col xs={24} md={12}  className='center-responsive'>
              <h2 className='byp-yellow-100'><b>Contact us!</b></h2>
              <p>
                If you want to contact us for any questions or have a meeting to learn more about our platform, please feel free to contact us.
              </p>
            </Col>
            <Col xs={24} md={12} >
              <ConstactUsForm />
            </Col>
          </Row>
        </div>
        <HomeSectionJ />
        <HomeSectionK />
      </PublicTheme>
    </>
  )
}

export { AboutPage, URL_ABOUT_PAGE }
