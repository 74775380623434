import './App.less'
import React from 'react'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { PageRoutes } from './router/PageRoutes'
import { ReactQueryDevtools } from 'react-query/devtools'
import '../src/languageConfiguration/languageConfiguration'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <PageRoutes />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
