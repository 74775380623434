import { Col, Image, Row } from 'antd'
import { FooterGallery } from '../../../../quote-preview/components/FooterGallery'
import greeting from '../../../../../common/assets/images/quote-pictures-custom-1.png'
import costs from '../../../../../common/assets/images/quote-pictures-custom-2.png'
import map from '../../../../../common/assets/images/quote-pictures-custom-3.png'
import campus from '../../../../../common/assets/images/quote-pictures-custom-4.png'
import footer from '../../../../../common/assets/images/quote-pictures-custom-5.png'
import { QuoteGalleryTemplateA } from '../../../../quote-preview/components/QuoteGalleryTemplateA'
import imagePreview from '../../../../../common/assets/images/preview-template-2.png'

const TemplateA = ({ gallery = [], footGallery = [], campusGallery, campusG, videoUrl }) => {
  return (
    <div>
      <div className='quote-web-preview-container'>
        <div className='greeting'>
          <Image src={greeting} />
        </div>
        <div className='gallery'>
          {(gallery?.length > 0 || videoUrl) ? <QuoteGalleryTemplateA gallery={gallery} videoUrl={videoUrl} /> : <img src={imagePreview} style={{ width: '100%' }} />}
        </div>
        <Row>
          <Col span={15}><Image src={costs} /></Col>
          <Col span={9}>
            <Image src={map} />
            {gallery?.length > 0 && campusG(campusGallery)}
            <Image src={campus} />
          </Col>
        </Row>
        <div className='footer'>
          <Image src={footer} />
        </div>
      </div>
      {footGallery[0] && <FooterGallery footGallery={footGallery} />}
    </div>
  )
}

export { TemplateA }
