import api from '../../services/api'

class NationalityRepository {
  static async all (params) {
    const response = await api.get('/get/nationalities', { params })
    return response.data
  }

  static async regions () {
    const response = await api.get('/get/regions')
    return response.data
  }

  static async subregions () {
    const response = await api.get('/get/subregions')
    return response.data
  }

  static async subregionsByRegion (region) {
    if (region === undefined) return
    const response = await api.get(`/get/${region}/subregions`)
    return response.data
  }

  static async subregionsByRegions (regions) {
    if (regions === undefined || regions === null) return
    if (Array.isArray(regions)) {
      if (regions.length <= 0) return
    }
    const response = await api.get('/get/group_subregions', {
      params: {
        regions
      }
    })
    return response.data
  }

  static async countriesByRegion (region) {
    const response = await api.get(`/get/${region}/countries`)
    return response.data
  }

  static async countriesByRegions (regions) {
    if (Array.isArray(regions)) {
      if (regions.length <= 0) return
    }
    const response = await api.get('/get/group_countries', {
      params: {
        regions
      }
    })
    return response.data
  }

  static async countriesBySubRegions (subregions) {
    if (Array.isArray(subregions)) {
      if (subregions.length <= 0) return
    }
    const response = await api.get('/get/group_countries_subregions', {
      params: {
        subregions
      }
    })
    return response.data
  }

  static async getCountries (idInstitutions) {
    const response = await api.get('/get/countries', {
      params: {
        institutions: idInstitutions
      }
    })

    return response.data
  }

  static async getCountriesAuth (idInstitutions) {
    const response = await api.get('/get/countries/auth', {
      params: {
        institutions: idInstitutions
      }
    })

    return response.data
  }
}
export { NationalityRepository }
