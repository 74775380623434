import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import UserRepository from '../../repositories/UserRepository'
import { useState } from 'react'
import SessionService from '../../../services/SessionService'

function usePostValidateInvitation () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.continueRegistration, {
    onSuccess: (d) => {
      SessionService.createSession(d)
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: () => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const validateInvitation = (token) => {
    mutation.mutate({ token })
  }

  return { ...mutation, state, currentState, validateInvitation }
}

export { usePostValidateInvitation }
