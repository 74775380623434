import { Button, Col, Form, Input, Row } from 'antd'
import { BasicRadioGroupFormItem } from './BasicRadioGroupFormItem'
import { AddAndRemoveListButtons } from '../../../pages/dashboards/Institution/components/AddAndRemoveListButtons'
import { PlusOutlined } from '@ant-design/icons'

const GroupEducationFormItemList = ({ name, displayEmail = false }) => {
  const form = Form.useFormInstance()
  const intakes = Form.useWatch(name, form)
  const isGroup = Form.useWatch('isGroup', form)

  return (
    <>
      <Col xs={24} md={8}>Are you part of an educational group?</Col>
      <Col xs={24} md={16}><BasicRadioGroupFormItem name='isGroup' /></Col>
      {isGroup && (
        <>
          <Col xs={24}>
            <Form.List
              name={name}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row key={key} gutter={16}>
                      <Col span={displayEmail ? 24 : 18}>
                        <Form.Item
                          name={[name, 'name']}
                          label='Group name'
                          rules={[{ required: true, message: 'This field is required', whitespace: true }]}
                        >
                          <Input placeholder='Group name' />
                        </Form.Item>
                      </Col>
                      {displayEmail && (
                        <Col span={24}>
                          <Form.Item
                            name={[name, 'email']}
                            label='Email'
                            rules={[{ required: true, message: 'This field is required', whitespace: true }]}
                          >
                            <Input placeholder='Group email' />
                          </Form.Item>
                        </Col>
                      )}
                      <AddAndRemoveListButtons
                        fields={fields}
                        index={index}
                        add={add}
                        remove={remove}
                        name={name}
                        notDelete={intakes?.length <= 1}
                      />
                    </Row>
                  ))}
                  {
                    fields.length === 0 && (
                      <Form.Item>
                        <Button className='byp-btn-blue-100-outline float-right svg-boton-blue-fill' onClick={() => add()}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" width="10" height="10" stroke-width='2' stroke='var(--blue-100)'>
                              <path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                              ></path>
                            </svg>
                            Add
                          </div>
                        </Button>
                      </Form.Item>
                    )
                  }
                </>
              )}
            </Form.List>
          </Col>
        </>
      )}
    </>
  )
}

export { GroupEducationFormItemList }
