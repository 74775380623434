import { Form, Select } from 'antd'
const { Option } = Select
const SelectSportsFormItem = ({ name, label, ...rest }) => {
  // const list={
  const sport = ['Alpine Ski',
    'Ice Hockey',
    'Fives',
    'Kickboxing',
    'Mountain Biking',
    'Martial Arts',
    'Snowboarding',
    'Archery',
    'Parkour',
    'SnowShoeing',
    'Floorball',
    'Weight training',
    'Trampoline ',
    'Athletics',
    'Badminton',
    'Ballet',
    'Baseball',
    'Basketball',
    'Bowling',
    'Box',
    'Cheerleaders', 
    'Chess', 
    'Climbing', 
    'Cricket', 
    'Cross Country', 
    'Curling', 
    'Cycling',
    'Dance Competition',
    'Dance Performance',
    'Diving',
    'Fencing',
    'Fishing',
    'Fitness',
    'Field Hockey',
    'Football',
    'Golf',
    'Gymnastics',
    'Hiking',
    'Hockey',
    'Horse Riding',
    'Jogging',
    'Judo',
    'Karate',
    'Lacrosse',
    'Netball',
    'Padel',
    'Rowing',
    'Rugby',
    'Sailing',
    'Skate',
    'Ski',
    'Soccer',
    'Softball',
    'Surf',
    'Swimming',
    'Table tennis (Ping pong)',
    'Taewkondo',
    'Tennis',
    'Track and Field',
    'Ultimate Frisbee',
    'Volleyball',
    'Water Polo',
    'Wrestling',
    'Yoga',
    'Varsity football',
    'Varsity soccer',
    'JV Soccer',
    'Mixed Martial Arts',
    'Varsity basketball',
    'JV basketball',
    'Squire basketball',
    'Strength & Conditioning',
    'Indoor soccer',
    'Varsity lacrosse',
    'JV lacrosse',
    'Varsity baseball',
    'Varsity track & field',
    'Varsity golf',
    'JV golf',
    'Varsity tennis',
    'JV tennis',
    'Outdoors Adventures',
    'Outdoorsmen',
    'Bacon Holler Hustle',
    'Brokenback Challenge',
    'The Great Blue Ridge School Campout',
    "Girls' Varsity Volleyball",
    "Girls' Junior Varsity Volleyball",
    "Girls' 7/8 Grade Volleyball",
    "Girls' Varsity Soccer",
    "Boys' Varsity Soccer",
    "Boys' Junior Varsity Soccer",
    "Boys' 7/8 Grade Soccer",
    'Coed Varsity Cross Country',
    "Girls' Varsity Basketball",
    "Girls' Junior Varsity Basketball",
    "Girls' 7/8 Grade Basketball",
    "Girls' Varsity Swimming",
    "Boys' Varsity Basketball",
    "Boys' Junior Varsity Basketball",
    "Boys' 7/8 Grade Basketball",
    "Boys' Varsity Swimming",
    'Coed 7/8 Grade Swimming',
    'Coed Varsity Squash',
    'Coed Varsity Table Tennis',
    'Coed Varsity Indoor Track',
    "Girl's Varsity Softball",
    "Girl's Varsity Tennis",
    "Boy's Varsity Baseball",
    "Boy's Varsity Tennis",
    "Boy's Varsity Volleyball",
    'Coed Varsity Track',
    'Coed 7/8 Grade Track',
    'Coed Varsity Golf',
    'Flag Football (Rio Lindo)',
    'Squash (St Timothys)',
    'All mountaing Skiiing',
    'All mountain snowboarding',
    'Freestyle skiig',
    'Freestyle snowboarding',
    'Ice clymbing',
    'Mountain biking',
    'Outdor climbing',
    'Indoor climbing',
    'Whitewater kayaking',
    'Kayaking',
    'Farm & Forest',
    'Beach games',
    'Dodgeball',
    'Handball',
    'Minigolf',
    'Pedalo',
    'Rounders',
    'Unihockey',
  ]
  // }

  return (
    <Form.Item
      name={name}
      label={label}
      {...rest}
    >
      <Select
        className='w-100'
        {...rest}
      >

        {sport.sort().map((item) =>
          <Option key={`${item}`}>{item} </Option>)}

      </Select>
    </Form.Item>
  )
}

export { SelectSportsFormItem }
