import { useParams, useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormAccommodationExtraInfo } from './components/FormAccommodationExtraInfo'

const URL_ACCOMODATION_EXTRA_INFO_PAGE = '/dashboard/:program/accomodation/extra-info'

const AccomodationExtraInfoPage = () => {
  const { program } = useParams()
  const [params] = useSearchParams()
  const type = params.get('type')
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          <FormAccommodationExtraInfo program={program} type={type} />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { AccomodationExtraInfoPage, URL_ACCOMODATION_EXTRA_INFO_PAGE }
