import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import UserRepository from '../../repositories/UserRepository'
import { useState } from 'react'

function usePostUserInvitation () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.saveInvitation, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const saveInvitation = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, saveInvitation }
}

export { usePostUserInvitation }
