import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetAgencies (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetAgencies', filtersQuery],
    () => AgencyRepository.getAllAgencies(filtersQuery),
    { retry: 2 }
  )
}

function useGetAgenciesNames () {
  return useQuery(['useGetAgenciesNames'],
    () => AgencyRepository.getAllAgenciesNames(),
    { retry: 2 }
  )
}

export { useGetAgencies, useGetAgenciesNames }
