import { Col, Image, Row } from 'antd'
import React from 'react'
import { useGetInfoOffice } from '../../../../bussines/hooks/admin/useGetInfoOffice'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const plans = ['Free Version', 'Trial Version', 'Monthly Version', 'Semestral Version', 'Yearly Version']
function Tarjet ({ type, company, searchFilter }) {
  const { data, isLoading } = useGetInfoOffice(type, company, searchFilter)

  const handleDateOfWorkingSince = (time) => {
    const now = new Date(time)
    return `${months[now.getMonth()]} ${now.getDay()}, ${now.getFullYear()}`
  }

  const handlePlans = (plan) => {
    if (plan?.length > 0) {
      return plans[plan[0]?.plan_id - 1]
    } else {
      return plans[0]
    }
  }

  if (isLoading) {
    return <BasicSpinner />
  }
  return (
    <Row className='byp-color-white-full d-flex align-center tarjet'>
      <Col flex='0 1 170px' className='tarjet-img'>
        <Image
          width={130}
          src={data?.profile_photo_url}
        />
      </Col>
      <Col flex='1 1 660px' className='tarjet-container'>
        <Row>
          <Col span={24}>
            <p className='tarjet-info'>INFORMATION</p>
          </Col>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={3}>
                <span>Agency name</span><br />
                <p>{data?.name}</p>
              </Col>
              <Col span={3}>
                <span>Working since</span><br />
                <p>{handleDateOfWorkingSince(data?.created_at)}</p>
              </Col>
              <Col span={3}>
                <span>Email</span><br />
                <p>{data?.email}</p>
              </Col>
              <Col span={3}>
                <span>Main office</span><br />
                {/* <p>{data?.offices ? data?.offices[0]?.name : ''}</p> */}
              </Col>
              <Col span={3}>
                <span>Total users</span><br />
                <p>{data?.users_count}</p>
              </Col>
              <Col span={3}>
                <span>Plan</span><br />
                <p>{handlePlans(data?.plan)}</p>
              </Col>
              <Col span={3}>
                <span>Payment date</span><br />
                {/* <p>mm/dd/yyyy</p> */}
              </Col>
              <Col span={3}>
                <span>Status</span><br />
                <p className='tarjet-status'>ACTIVE</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { Tarjet }
