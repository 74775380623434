import React from 'react'
import { Form, Select } from 'antd'
import { programTypes } from '../../../utils/programTypes'

const { Option } = Select

export const SelectProgramSubTypeByCampus = ({ showSearch, loading, isListField, fieldKey, showAll = false, showDefault = false, selectById = true, getSubType, ...props }) => {
  return (
    <Form.Item {...props}>
      <Select
        placeholder={props.placeholder ?? ''}
        size={props.size}
        className='byp-m-0'
        onChange={getSubType}
      >
        {programTypes.map((type) => {
          if (type.value === 'diploma') {
            return (
              <>
                <Option key='diploma' value='diploma'>Diploma</Option>
                <Option key='advanced_diploma' value='advanced_diploma'>Advanced Diploma</Option>
                <Option key='certificate' value='certificate'>Certificate</Option>
              </>
            )
          }
          return <Option key={type.key} value={type.value}>{type.label}</Option>
        })}
      </Select>
    </Form.Item>
  )
}
