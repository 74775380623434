import { Table, Button } from 'antd'
import { EditFilled } from '@ant-design/icons'
import ConfirmModal from '../../../common/components/ConfirmModal'
import { EditIcon } from '../../../common/theme/icons/EditIcon'

function TableOffices({ data, sedeLabel, setOffice, deleteOffice, isDeleting, canDelete = true }) {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%'
    },
    {
      title: 'Address',
      dataIndex: 'address'
    },
    {
      title: 'Phone',
      dataIndex: 'tel',
      width: '15%'
    },
    {
      title: 'Actions',
      width: '15%',
      render: ({ id }) => (
        <>
          <ConfirmModal onOk={deleteOffice} isLoading={isDeleting} id={id} hasPermision={canDelete} />
          <Button
            size='small'
            className='byp-btn-edit'
            onClick={() => { setOffice(id) }}
            icon = {<EditIcon />}
          >
          </Button>
        </>
      )
    }
  ]
  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data}
      className='byp-table-dashboard'
    />
  )
}

export { TableOffices }
