import api from '../../services/api'

class LanguageRepository {
  async all () {
    const response = await api.get('/get/languages/all')
    return response.data
  }

  async languages () {
    const response = await api.get('/get/languages')
    return response.data
  }

  async languagesShort () {
    const response = await api.get('/get/languages/short')
    return response.data
  }
}

export default new LanguageRepository()
