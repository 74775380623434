import { Col, Divider, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectBranchesFormItem } from '../../../../../common/components/helper-form/SelectBranchesFormItem'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import MostPopularSchools from '../components/MostPopularSchools'
import PopularRangeTime from '../components/PopularRangeTime'
import ProgramAverage from '../components/ProgramAverage'
import SchoolAverage from '../components/SchoolAverage'
import TopUsers from '../components/TopUsers'
import MostPopularPrograms from '../general/MostPopularPrograms'
import { TableTotalQuote } from '../TableTotalQuote'
import TimeFilters from '../TimeFilters'
import OfficePopularCities from './components/OfficePopularCities'
import OfficeTotalQuotes from './components/OfficeTotalQuotes'

const OfficeAnaliticsInfo = () => {
  const { user: _user } = useAuth()

  const [time, setTime] = useState({})
  const [office, setOffice] = useState()
  const [nameSede, setNameSede] = useState()
  const [isActiveTotalQuote, setIsActiveTotalQuote] = useState(false)
  const [activeCategory, setActiveCategory] = useState('')
  // const { company } = useAuth()

  useEffect(() => {
    if (_user?.role_names?.includes('agency-office-manager')) {
      setOffice(_user?.sede)
      setNameSede(_user?.sedes[0])
    }
  }, [_user])

  const handleSede = (val) => {
    const sedeVal = val.split('-')
    setOffice(sedeVal[0])
    setNameSede(sedeVal[1])
  }

  return (
    <Row className='byp-dashboard-content'>
      <Col span={24}>
        <Row>
          <Col span={20} className='d-flex align-center'>
            <h3 className='byp-title'>Office analysis</h3>
            {nameSede && <span className='byp-title byp-text-lg' style={{ marginLeft: '0.5rem' }}>({nameSede})</span>}
          </Col>
        </Row>

        <Row gutter={16} className='byp-mb-1'>
          <Col span={16}>
            <SelectBranchesFormItem
              className='user-analysis__select'
              placeholder=''
              style={{ width: 200 }}
              size='small'
              value={(office && nameSede) ? office + '-' + nameSede : ''}
              isDisabled={_user?.role_names?.includes('agency-office-manager')}
              onChange={(val) => handleSede(val)}
              showNameSede
              firstOption
            />
          </Col>

          <Col className='time-filter'>
            <TimeFilters setTime={setTime} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className={!isActiveTotalQuote ? 'two-thirds-responsive' : 'width-100'}>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Total quotes</h4>
            <OfficeTotalQuotes
              time={time}
              office={office}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              setIsActiveTotalQuote={setIsActiveTotalQuote}
            />
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
            {isActiveTotalQuote && (
              <TableTotalQuote
                activeCategory={activeCategory}
                office={office}
                time={time}
              />
            )}

            {!isActiveTotalQuote && (
              <>
                <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most Popular Programs</h4>
                {office && <MostPopularPrograms time={time} office={office} />}
                <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              </>
            )}

            {!isActiveTotalQuote && (
              <>
                <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most Popular Schools</h4>
                {office && <MostPopularSchools time={time} office={office} />}
                <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              </>
            )}

            {!isActiveTotalQuote && (
              <>
                <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Popular range of time</h4>
                <PopularRangeTime time={time} office={office} />
                <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              </>
            )}

            {!isActiveTotalQuote && (
              <>
                <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most popular cities</h4>
                <OfficePopularCities time={time} office={office} />
                <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              </>
            )}

          </Col>

          {!isActiveTotalQuote && (
            <Col className='one-third-responsive'>
              <div className='user-average'>
                <h4 className='byp-title-4 byp-mb-1 byp-title byp-fw-bold'>Program Average</h4>
                <ProgramAverage time={time} office={office} firstOption />

                <h4 className='byp-title-4 byp-mb-1 byp-mt-1 byp-title byp-fw-bold'>School Average</h4>
                <SchoolAverage time={time} office={office} firstOption />
              </div>
            </Col>
          )}

          <Col span={24}>
            <h4 className='byp-title-4 quote-overview--color-blue byp-mb-1'>Top users</h4>
            {office && <TopUsers time={time} office={office} />}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { OfficeAnaliticsInfo }
