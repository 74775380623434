import React from 'react'
import { PageProtected } from '../../../../common/components/PageProtected'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { GeneralChartInfo } from './GeneralChartInfo'

const URL_GENERAL_MASTER_PAGE = '/dashboard/charts/general'

const General = () => {
  return (
    <PageProtected permissionTypes={[
      userRoles.TYPE_SUPER_ADMIN
    ]}
    >
      <MainTheme
        showMenu
      >
        <GeneralChartInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { General, URL_GENERAL_MASTER_PAGE }
