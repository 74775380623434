import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { PageNotAvailableInBetaVersion } from '../../../errors/PageNotAvailableInBeta'

const URL_ADD_SERVICE_PROMOTIONS_PAGE = '/dashboard/agency/service/promotions'

function AdionalServicePromotions () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_AGENCY]}>
      <MainTheme
        showMenu
      >
        <PageNotAvailableInBetaVersion />
      </MainTheme>
    </PageProtected>
  )
}

export { AdionalServicePromotions, URL_ADD_SERVICE_PROMOTIONS_PAGE }
