import { useQuery } from 'react-query'
import SchoolRepository from '../../repositories/SchoolRepository'

function useGetServiceByCategoryAndSede (category, sede, year, subType) {
  return useQuery(
    ['useGetServiceByCategoryAndSede', category, sede, year, subType],
    () => SchoolRepository.getServicesByCategoryAndSede(category, sede, year, subType), {
      retry: 2,
      enabled: !!category && !!sede && !!year
    }
  )
}

export { useGetServiceByCategoryAndSede }
