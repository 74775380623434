import { Row, Col, Divider, Tag } from 'antd'
import TimeFilters from '../TimeFilters'
import MostPopularPrograms from '../general/MostPopularPrograms'
import MostPopularCourses from '../components/MostPopularCourses'
import MostPopularCities from '../general/MostPopularCities'
import MostPopularDatesAndTimes from '../general/MostPopularDatesAndTimes'
import ProgramAverage from '../components/ProgramAverage'
import TopUsers from '../components/TopUsers'
import { SelectSchools } from '../../../../../common/components/helper-form/SelectInstitutions'
import { useEffect, useState } from 'react'
import TotalQuotes from '../../reports/components/TotalQuotes'
import { SelectCampusFormItem } from '../../../../../common/components/helper-form/SelectCampusFormItem'
import { useGetInstitutions } from '../../../../../bussines/hooks/agency/useGetInstitutions'

function GeneralContent () {
  const [time, setTime] = useState({})
  const [campus, setCampus] = useState('all')
  const [institution, setInstitution] = useState('all')
  const [isDeleted, setIsDeleted] = useState(false)
  const { data: institutions } = useGetInstitutions()

  useEffect(() => {
    if (institution && institution !== 'all') {
      const ins = institutions.find(item => {
        return item.institution_id === parseInt(institution)
      })
      if (ins?.institution?.deleted_at) {
        setIsDeleted(true)
      } else {
        setIsDeleted(false)
      }
    } else {
      setIsDeleted(false)
    }
  }, [institution, campus])

  return (
    <div>
      <Row className='byp-dashboard-content'>
        <Col span={24}>
          <Row>
            <Col span={20} className='d-flex align-center'>
              <h3 className='byp-title'>Institution analysis</h3>
              {/* {nameSede && <span className='byp-title byp-text-lg' style={{ marginLeft: '0.5rem' }}>({nameSede})</span>}  */}
            </Col>
          </Row>

          <Row gutter={16} className='byp-mb-1'>
            <Col span={16}>
              <Row>
                <Col span={5}>
                  <SelectSchools
                    className='user-analysis__select'
                    size='small'
                    onChange={setInstitution}
                    filters={{ is_group: true }}
                    haveOptionAll
                    value={institution}
                  />
                </Col>
                <Col span={5}>
                  <SelectCampusFormItem
                    className='user-analysis__select'
                    size='small'
                    onChange={setCampus}
                    filters={{ is_group: true, institutions: institution }}
                    single
                    placeholder='All'
                    haveOptionAll
                  />
                </Col>
                <Col>
                  {isDeleted && (
                    <Tag
                      style={{ float: 'right', fontSize: '10px', marginLeft: '0.5rem' }}
                      className='byp-bg-red byp-color-white byp-fw-bold'
                    >
                      Institution Deleted
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TimeFilters
                setTime={setTime}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col
              span={16}
            >
              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Total quotes</h4>
              <TotalQuotes
                filters={{
                  ...time,
                  campus,
                  institution,
                  is_school: true
                }}
              />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most Popular Programs</h4>
              <MostPopularPrograms
                time={{ ...time, institution, is_school: true }}
                campus={campus}
              />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
                Most popular courses
              </h4>
              <MostPopularCourses
                time={{ ...time, campus, institution, is_school: true }}
              />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most popular cities</h4>
              <MostPopularCities
                time={{ ...time, campus, institution, is_school: true }}
              />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Popular dates and times</h4>
              <MostPopularDatesAndTimes
                time={{ ...time, campus, institution, is_school: true }}
              />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
            </Col>

            <Col span={8}>
              <div className='user-average'>
                <h4 className='byp-title-4 byp-mb-1 byp-title byp-fw-bold'>Program Average</h4>
                <ProgramAverage
                  time={{ ...time, institution, is_school: true }} campus={campus} firstOption
                />
              </div>
            </Col>

            <Col span={24}>
              <h4 className='byp-title-4 quote-overview--color-blue byp-mb-1'>Top users</h4>
              <TopUsers
                time={{ ...time, institution, is_school: true }} campus={campus}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export { GeneralContent }
