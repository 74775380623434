import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

function useGetBaseQuote (quoteId) {
  return useQuery(['useGetBaseQuote', quoteId], () => QuoteRepository.getBaseQuote(quoteId), {
    retry: 2,
    enabled: !!quoteId
  })
}

export { useGetBaseQuote }
