import React from 'react'
import { useParams } from 'react-router-dom'
import { usePostPrices } from '../../../../bussines/hooks/school/usePostPrices'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { ValidationsProvider } from '../../../../providers/validation/validations-provider'
import TopBarMenu from '../components/TopBarMenu'
import { FormCreatePrices } from './FormCreatePrices'

const URL_UPLOAD_PRICES_PAGE = '/dashboard/upload/prices/:program'

function UploadPricesPage () {
  const { program } = useParams()
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <ValidationsProvider usePost={usePostPrices}>
          <TopBarMenu program={program} key={'top_menu_' + program} />
          <FormCreatePrices program={program} />
        </ValidationsProvider>
      </MainTheme>
    </PageProtected>
  )
}

export { UploadPricesPage, URL_UPLOAD_PRICES_PAGE }
