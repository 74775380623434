import React, { useEffect, useState } from 'react'
import { Col, List, Row, Statistic, Typography, Modal } from 'antd'
import {
  SendOutlined,
  // HeartOutlined,
  // HeartFilled,
  // DeleteFilled
  EditOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import icon from '../../../common/assets/images/icon.png'

const { Paragraph } = Typography

function CardItemQuote ({ item, markAsfavorite }) {
  // console.log('🚀 ~ file: CardItemQuote.js:16 ~ CardItemQuote ~ item', item)
  const [open, setOpen] = useState(false)
  const [isActive, setIsActive] = useState(true)

  function EditButton ({ courseId, campusId, year, quoteId }) {
    return (
      <Link
        key={item.title}
        target='_blank'
        className='ant-btn byp-br-4 byp-btn-blue-100-outline w-100 d-flex justify-center align-center'
        disabled={!isActive}
        to={`/quote-course/${courseId}/${campusId}/${year}?quote=${quoteId}`}
        state={{ quoteId }}
      >
        <EditOutlined /> Edit
      </Link>
    )
  }

  function handleFavorite (id) {
    if (item.favorites) {
      item.favorites = null
    } else {
      item.favorites = true
    }
    markAsfavorite(id)
    setOpen(false)
  }

  function handleOpenModal () {
    setOpen(true)
  }

  function getDate (itemDate) {
    const date = new Date(itemDate)
    const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
    return `${date.getUTCDate()} ${months[date.getMonth()]}`
  }

  function getIsActive (endDate, startDate) {
    const currentDate = new Date()
    const start = new Date(startDate)
    const end = new Date(endDate)

    return currentDate >= start && currentDate <= end
  }

  useEffect(() => {
    setIsActive(getIsActive(item?.expiration, item?.pivot?.created_at))
  }, [])

  return (
    <>
      <List.Item
        className='quote-list-item-card w-100'
        key={item?.title}
        // onClick={() => handleShow(item.id)}
      >
        <div className='card-quoter-image-header'>
          <img
            alt={item?.label}
            src={item.offer?.enterpriseable?.parent?.profile_photo_url || icon}
            style={{ objectFit: 'cover' }}
          />
          <div className='quote-campus__items'>
            <div
              className='fav-button2'
              onClick={handleOpenModal}
            >
              <DeleteIcon />
            </div>

            <Row className={isActive ? 'quote-campus-dates' : 'quote-campus-dates active'}>
              <Col>
                {getDate(item?.pivot?.created_at)} - {getDate(item?.expiration)}
              </Col>
              <Col className='quote-campus__status'>
                {
                  isActive ? 'Active' : 'Inactive'
                }
              </Col>
            </Row>
          </div>
        </div>

        <div className='quote-campus__container'>
          <h5>{item.offer?.enterpriseable?.name}</h5>
          <List.Item.Meta
            title={ <Paragraph ellipsis={{ rows: 2, expandable: false }}>
                {item.favorite_name ?? item.offer?.service?.label}
              </Paragraph>}
          /> 
          <Paragraph ellipsis={{ rows: 2 }}>
            {item.offer?.service?.description}
          </Paragraph>
          <Statistic
            prefix={`${item.divisa?.code ?? ''}`}
            precision={2}
            value={`${item.cost}`}
            suffix={`${'/ TOTAL'}`}
          />
          <div className='quote-campus__btns'>
            {/* <Button
              key={item.title}
              className='byp-br-4 byp-btn-blue-100-outline w-100 d-flex justify-center align-center'
              style={{ marginLeft: '0' }}
              onClick={() => handleEdit(item.offer.service?.id, item.offer?.enterpriseable?.id, item.offer.year_id, item.id)}
            >
              <EditOutlined /> Edit
            </Button> */}
            <EditButton
              courseId={item?.offer?.service?.id}
              campusId={item?.offer?.enterpriseable?.id}
              year={item?.offer?.year_id}
              quoteId={item?.uuid}
            />
            <Link
              key={item?.title}
              target='_blank'
              className='ant-btn byp-br-4 byp-btn-blue-100 w-100 d-flex justify-center align-center'
              disabled={!isActive}
              to={`/quote-preview/${item?.uuid}`}
            >
              <SendOutlined /> Send
            </Link>
          </div>
        </div>
      </List.Item>
      <Modal
        title='Delete quote'
        visible={open}
        centered
        onOk={() => handleFavorite(item?.id)}
        onCancel={() => setOpen(false)}
        cancelText='Go back'
        okText='Delete'
        className='quote-campus__modal'
      >
        <h3 className='byp-text-lg byp-mb-1'>Are you sure you want to delete this quote?</h3>
        <p className='byp-m-0'>When you click the "Delete" button, this quote will be removed from your "My Quotes" list.
          <br />
          However, even after deleting it, this quote will still be visible and functional to your customers.
        </p>
      </Modal>
    </>
  )
}

const DeleteIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13'
      height='14'
      fill='none'
      viewBox='0 0 13 14'
    >
      <path
        fill='#686868'
        d='M5.196 2.429h2.858a1.429 1.429 0 00-2.858 0zm-.857 0a2.286 2.286 0 014.572 0h3.571a.429.429 0 010 .857h-.748l-.696 8.344a2.429 2.429 0 01-2.42 2.227H4.632a2.429 2.429 0 01-2.42-2.227l-.696-8.344H.768a.429.429 0 110-.857h3.571zm1.429 3.285a.429.429 0 00-.857 0v4.857a.429.429 0 00.857 0V5.714zm2.143-.428a.429.429 0 00-.429.428v4.857a.429.429 0 00.857 0V5.714a.429.429 0 00-.428-.428z'
      />
    </svg>
  )
}

export { CardItemQuote }
