import { addWeeks } from 'date-fns'
import { isSameOrAfter } from '../../../utils/isSameOrAfter'
import { isSameOrBefore } from '../../../utils/isSameOrBefore'

/**
 * getAllHighSeasonBetweenDates
 * @param {array} weeks
 * @param {string} startDate
 * @param {array} highSeasons
 * @returns {[]|*[]}
 */
function getAllHighSeasonBetweenDates (weeks, startDate, highSeasons) {
  if (!weeks || !startDate || highSeasons.length === 0) {
    return []
  }

  const seasons = []
  const endDate = addWeeks(new Date(startDate), weeks)
  for (const sns of highSeasons) {
    if (isSameOrAfter(new Date(sns.start), new Date(startDate)) &&
      isSameOrBefore(new Date(sns.end), new Date(endDate))
    ) {
      seasons.push(sns)
      continue
    }

    if (isSameOrAfter(new Date(sns.start), new Date(startDate)) &&
      isSameOrBefore(new Date(sns.start), new Date(endDate))
    ) {
      seasons.push(sns)
      continue
    }

    if (isSameOrAfter(new Date(sns.end), new Date(startDate)) &&
      isSameOrBefore(new Date(sns.end), new Date(endDate))
    ) {
      seasons.push(sns)
    }
  }

  return seasons
}

export { getAllHighSeasonBetweenDates }
