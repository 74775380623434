import { Button, Col, Form, Row, Typography, notification } from 'antd'
import { BasicFormListItem } from '../../../../../common/components/BasicFormListItem'
import { useEffect, useState } from 'react'
import stateControl from '../../../../../bussines/utils/state-control'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { useGetLodgings } from '../../../../../bussines/hooks/school/lodging/useGetLodgings'
import { TableLodgins } from '../../Tables/TableLodgins'
import { useFindLodging } from '../../../../../bussines/hooks/school/lodging/useFindLodging'
import { useSaveFeeExtraInfo } from '../../../../../bussines/hooks/fee/useSaveFeeExtraInfo'
import { SelectFeeByCategory } from '../../../../../common/components/helper-form/SelectFeeByCategory'
import TextArea from 'antd/lib/input/TextArea'
import { SelectAvailableTranslationsFormItem } from '../../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'
const { Title } = Typography

const FormAccommodationExtraInfo = ({ program, type }) => {
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState({})
  const [accommodationID, setAccomodationID] = useState()
  const { isLoading, data: accomodations, refetch } = useGetLodgings(program && { program, page, type: type ?? null, ...searchFilter })
  const { data: accomodation, isLoading: isLoadingFee } = useFindLodging(accommodationID)
  const { save, isLoading: isSaving, currentState } = useSaveFeeExtraInfo()

  const handleFinish = (values) => {
    console.log('🚀 ~ file: FormAccommodationExtraInfo.js:8 ~ handleFinish ~ values:', values)
    save(accommodationID, values)
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const tags = accomodation.tags ?? []
      const skills = tags.filter((tag) => tag.type === 'skill').map(skill => {
        const translationFound = skill.translationables?.find(translation => translation.to === lang)
        return {
          ...skill,
          name: translationFound?.translation ?? skill.name
        }
      })
      const included = tags.filter((tag) => tag.type === 'included').map(skill => {
        const translationFound = skill.translationables?.find(translation => translation.to === lang)
        return {
          ...skill,
          name: translationFound?.translation ?? skill.name
        }
      })
      const requirements = accomodation.requirements?.map(requirement => {
        const translationFound = requirement.translationables?.find(translation => translation.to === lang)
        return {
          ...requirement,
          value: translationFound?.translation ?? requirement.value
        }
      })
      const targets = accomodation.targets?.map(target => {
        const translationFound = target.translationables?.find(translation => translation.to === lang)
        return {
          ...target,
          value: translationFound?.translation ?? target.value
        }
      })
      const characteristics = accomodation.characteristics?.map(target => {
        const translationFound = target.translationables?.find(translation => translation.to === lang)
        return {
          ...target,
          value: translationFound?.translation ?? target.value
        }
      })
      const description = accomodation.description_extra?.translationables?.find(translation => translation.to === lang)
      // const description = campus.extra_description
      form.setFieldsValue({
        requirements: requirements?.length > 0 ? requirements : [{ value: '' }],
        targets: targets?.length > 0 ? targets : [{ value: '' }],
        characteristics: characteristics?.length > 0 ? characteristics : [{ value: '' }],
        skills: skills.map((skill) => skill.name),
        included: included.map((skill) => skill.name),
        description: description?.translation,
        translation: lang
      })
    } else {
      setFormData()
    }
  }

  const setFormData = () => {
    const tags = accomodation.tags ?? []
    const skills = tags.filter((tag) => tag.type === 'skill')
    const included = tags.filter((tag) => tag.type === 'included')
    const requirements = accomodation.requirements
    const characteristics = accomodation.characteristics
    const targets = accomodation.targets
    form.setFieldsValue({
      requirements: requirements?.length > 0 ? requirements : [{ value: '' }],
      characteristics: characteristics?.length > 0 ? characteristics : [{ value: '' }],
      targets: targets?.length > 0 ? targets : [{ value: '' }],
      skills: skills.map((skill) => skill.name),
      included: included.map((skill) => skill.name),
      description: accomodation?.description,
      translation: null
    })
  }

  useEffect(() => {
    if (!isLoadingFee && accomodation) {
      setFormData()
    }
  }, [isLoadingFee, accomodation])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      form.resetFields()
      setAccomodationID()
      notification.success({
        message: 'Data saved succesfully'
      })
    }
  }, [isSaving, currentState])

  return (
    <Row
      gutter={[16, 16]}
    >
      <Col xs={24} md={12} lg={14}>
        <Form
          form={form}
          onFinish={handleFinish}
          requiredMark={false}
          style={{ marginTop: '1rem' }}
        >
          {isLoadingFee && <BasicSpinner />}
          {!accomodation && !accommodationID && !isLoadingFee && (
            <div className='w-100' style={{ minHeight: '78vh' }}>
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Select accomodation</Title>
              <SelectFeeByCategory
                type='lodging'
                filters={{
                  program
                }}
                onChange={(val) => {
                  setAccomodationID(val)
                }}
              />
            </div>
          )}
          {!!accomodation && !!accommodationID && (
            <>
              <Title level={3} className='byp-color-blue-200 byp-fw-bold'>{accomodation?.name} extra info</Title>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '.5rem' }}>
                <SelectAvailableTranslationsFormItem
                  name='translation'
                  formItemProps={{
                    noStyle: true
                  }}
                  selectProps={{
                    size: 'small',
                    style: { minWidth: '8rem' },
                    onChange: (lang) => handleChangeTranslateLanguage(lang),
                    allowClear: true
                  }}
                />
              </div>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name='description'
                    label='Description'
                  >
                    <TextArea autoSize />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Title level={5} className='byp-color-blue-200 byp-fw-bold'>This accomodation includes</Title>
              <SelectTagsFormItem name='included' type='included' /> */}
              {/* <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Skills the student will gain</Title>
              <SelectSkillsTagsFormItem name='skills' /> */}
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Requirements</Title>
              <BasicFormListItem name='requirements' />
              <Title level={5} className='byp-color-blue-200 byp-fw-bold'>Lodging Characteristics</Title>
              <BasicFormListItem name='characteristics' />
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                htmlType='submit'
                loading={isSaving}
              >
                {/* {isUpdateMode ? 'Update' : 'Save accomodation'} */}
                Save
              </Button>
            </>
          )}
        </Form>
      </Col>
      <Col xs={24} md={12} lg={10}>
        <TableLodgins
          program={program}
          isLoading={isLoading}
          data={accomodations?.data ?? []}
          metaData={accomodations?.meta}
          refetch={refetch}
          setPage={setPage}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          setLodgingID={setAccomodationID}
          showDelete={false}
          showStatus={false}
          type={type}
        />
      </Col>
    </Row>
  )
}

export { FormAccommodationExtraInfo }
