import { Row, Col } from 'antd'
import Progress from 'antd/es/progress'
import React from 'react'
import { useGetIntake } from '../../../../../../bussines/hooks/agency/analytics/useGetIntake'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'

function PopularIntake ({ agency, time }) {
  const { data, isLoading } = useGetIntake({ limit: 4, agency, ...time })

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]}>
      {data?.map((intake, index) => (
        <Col
          xs={24}
          sm={12}
          xl={6}
          key={index}
        >
          <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem' }}>
            <p className='margin-0 byp-color-gray-100 byp-text-sm'>{intake?.quotes_count}  - Quotes created</p>
            <Row>
              <Col span={15} className='byp-centered'>
                <h5 className='byp-title- quote-overview--color-blue'>
                  {intake?.month}
                </h5>
              </Col>
              <Col span={9}>
                <Progress
                  type='circle'
                  percent={intake?.quotes_percentage}
                  className='ant-progress-inner-small'
                  strokeColor='#2D2F66'
                />
              </Col>
            </Row>
            <div className='d-flex align-center' style={{ justifyContent: 'space-between' }}>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>
                {!intake.duration && (intake.weeks + ' Weeks')}
                {intake.duration}
              </p>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>{intake?.label}</p>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export { PopularIntake }
