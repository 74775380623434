// import * as React from 'react'

export function ExchangeIcon ({ fill, ...rest }) {
  return (
    <svg
      width={15}
      height={14}
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_637_8996)'>
        <path
          d='M10.781 4.709v-.115c0-.799-.59-1.504-1.668-1.99-.908-.411-2.105-.635-3.363-.635s-2.455.224-3.363.634C1.309 3.09.719 3.795.719 4.593v2.188c0 .799.59 1.504 1.668 1.99a7.274 7.274 0 001.832.52v.115c0 .799.59 1.504 1.668 1.99.908.411 2.105.635 3.363.635s2.456-.224 3.363-.634c1.078-.487 1.668-1.192 1.668-1.99V7.218c0-1.209-1.383-2.182-3.5-2.51zm2.188 2.51c0 .426-1.192 1.23-3.38 1.307.771-.465 1.192-1.072 1.192-1.745v-.744c1.417.258 2.188.826 2.188 1.182zm-8.313.81v-.87c.364.04.729.06 1.094.06.365 0 .73-.02 1.094-.06v.87a8.462 8.462 0 01-1.094.065 8.462 8.462 0 01-1.094-.066zM9.47 6.403v.377c0 .263-.454.667-1.313.957v-.82a5.78 5.78 0 00.957-.334c.123-.052.241-.112.356-.18zM5.75 3.28c2.406 0 3.719.87 3.719 1.313 0 .443-1.313 1.312-3.719 1.312-2.406 0-3.719-.87-3.719-1.312 0-.443 1.313-1.313 3.719-1.313zm-3.719 3.5v-.377c.115.068.233.128.356.18.308.14.629.251.957.334v.82c-.859-.29-1.313-.694-1.313-.957zm3.5 2.625h.219c.197 0 .394-.005.59-.016.16.055.329.11.504.153v.82c-.859-.29-1.313-.694-1.313-.957zm2.625 1.247v-.87c.364.04.729.06 1.094.06.365 0 .73-.02 1.094-.06v.87a9.148 9.148 0 01-2.188 0zm3.5-.29v-.82a5.78 5.78 0 00.957-.334c.123-.052.241-.112.356-.18v.377c0 .263-.454.667-1.313.957z'
          fill={fill ?? '#2460B7'}
        />
      </g>
      <defs>
        <clipPath id='clip0_637_8996'>
          <path fill='#fff' transform='translate(.5)' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
