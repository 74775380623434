import { useQuery } from 'react-query'
import TagRepository from '../../repositories/TagRepository'

function useGetTags (filters = {}) {
  return useQuery(
    ['useGetTags', filters],
    () => TagRepository.tags(filters),
    {
      retry: 1
    }
  )
}

export { useGetTags }
