import { MainTheme } from '../../../../../common/theme/MainTheme'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { GeneralContent } from './general-content'

const URL_SCHOOL_ANALITCIS_GROUP_EDUCATIVE_PAGE = '/analitics/my-schools'

function SchoolEducativeGroupPage () {
  return (
    <>
      <PageProtected permissionTypes={[
        userRoles.TYPE_ANY
      ]}
      >
        <MainTheme
          showMenu
        >
          <GeneralContent />
        </MainTheme>
      </PageProtected>
    </>
  )
}

export { SchoolEducativeGroupPage, URL_SCHOOL_ANALITCIS_GROUP_EDUCATIVE_PAGE }
