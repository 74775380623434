import React from 'react'
import { Row, Col } from 'antd'
import { useGetTotalQuotes } from '../../../../../bussines/hooks/agency/analytics/general/useGetTotalQuote'
import {
  CreatedIcon,
  VisitedIcon,
  ApplicationsIcon
} from '../../../../quote-preview/assets/imgs/Icons'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { IconJamGPS } from '../../../../../common/assets/svg/icons'

const TotalQuotes = ({ time, idOffice, region, subregion, countrie, agency, isAgency }) => {
  const { data: totalquotes, isLoading } = useGetTotalQuotes(time, idOffice, region, subregion, countrie, agency)

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]} className='total-quotes-visualizer'>
      <Col className='total-quote'>
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%'}}>
          <CreatedIcon style={{margin:'5px 0'}}/>
          <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{totalquotes?.created}</h5>
          <p className='margin-0 byp-text-md byp-color-gray-100' 
          style={{ height: '35px',margin:'5px 0', display: 'flex',alignItems: 'center' }}>Created</p>
        </div>
      </Col>
      <Col className='total-quote' >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <IconJamGPS style={{margin:'5px 0'}}/>
          <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{totalquotes?.sended}</h5>
          <p className='margin-0 byp-text-md byp-color-gray-100' 
          style={{ height: '35px',margin:'5px 0',display: 'flex',alignItems: 'center' }}>Sent by email </p>
        </div>
      </Col>

      <Col className='total-quote' >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.0rem', height: '100%', }}>
          <VisitedIcon style={{margin:'5px 0'}}/>
          <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{totalquotes?.visited}</h5>
          <Col style={{ lineHeight: '15px', height: '35px', margin:'5px 0' }}>
            <p className='margin-0 byp-text-md byp-color-gray-100'>Seen </p>
            <i className='byp-text-sm'>(mail and link)</i>
          </Col>
        </div>
      </Col>
      <Col className='total-quote' >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1rem', height: '100%' }}>
          <VisitedIcon style={{margin:'5px 0'}}/>
          <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{totalquotes?.notseen}</h5>
          <Col style={{ lineHeight: '15px', height: '35px', margin:'5px 0' }}>
            <p className='margin-0 byp-text-md byp-color-gray-100' >Not Seen</p>
            <i className='byp-text-sm'>(mail and link)</i>
          </Col>
        </div>
      </Col>
      <Col className='total-quote' >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <ApplicationsIcon style={{margin:'5px 0'}}/>
          <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{totalquotes?.applications}</h5>
          <p className='margin-0 byp-text-md byp-color-gray-100' 
          style={{ height: '35px',margin:'5px 0',display: 'flex',alignItems: 'center' }}>Applications</p>
        </div>
      </Col>
    </Row>
  )
}

export default TotalQuotes
