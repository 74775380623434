import api from '../../services/api'

class ProgramRepository {
  async find (program) {
    const response = await api.get(`/programs/${program}`)
    return response.data
  }
}

export default new ProgramRepository()
