import { Col, Divider, Row } from 'antd'
import React, { useState } from 'react'
import TimeFilters from '../TimeFilters'
import { ProgramSelects } from './components/ProgramSelects'
import ProgramTotalQuotes from './components/ProgramTotalQuotes'
import ProgramPopularCities from './components/ProgramPopularCities'
import ProgramPopularUsers from './components/ProgramPopularUsers'
import MostPopularCourses from '../components/MostPopularCourses'
import ProgramPopularTimeRanges from './components/ProgramPopularTimeRanges'
import ProgramOfficeAverage from './components/ProgramOfficeAverage'
import ProgramProgramAverage from './components/ProgramProgramAverage'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'

const ProgramAnaliticsInfo = () => {
  const { user } = useAuth()
  const [time, setTime] = useState({})
  const [program, setProgram] = useState()
  const [institution, setInstitution] = useState()
  const [campus, setCampus] = useState()

  const [isActiveTotalQuote, setIsActiveTotalQuote] = useState(false)
  // const [activeCategory, setActiveCategory] = useState('')

  return (
    <Row className='byp-dashboard-content'>
      <Col span={24}>
        <Row>
          <Col span={20} className='d-flex align-center'>
            <h3 className='byp-title'>Program analysis</h3>
            <span
              className='byp-title byp-text-lg'
              style={{ marginLeft: '0.5rem' }}
            >
              {/* (Program Languages) */}
            </span>
          </Col>
        </Row>

        <Row gutter={16} className='byp-mb-1'>
          <Col span={16}>
            <ProgramSelects
              setProgramName={setProgram}
              programName={program}
              setInstitution={setInstitution}
              institution={institution}
              setCampus={setCampus}
              campus={campus}
              firstOption
            />
          </Col>

          <Col className='time-filter'>
            <TimeFilters setTime={setTime} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className='two-thirds-responsive'>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
              Total quotes
            </h4>
            <ProgramTotalQuotes time={time} program={program} campus={campus} institution={institution} />
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

            {!isActiveTotalQuote && (
              <>
                <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
                  Popular range of time
                </h4>
                {program && (
                  <ProgramPopularTimeRanges
                    filters={{ ...time, program, campus, limit: 4, institution }}
                  />
                )}
                <Divider
                  style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
                />
              </>
            )}

            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
              Most popular cities
            </h4>
            {program && (
              <ProgramPopularCities
                time={time}
                campus={campus}
                program={program}
                institution={institution}
              />
            )}
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
              Most popular courses
            </h4>
            <MostPopularCourses time={time} campus={campus} program={program} institution={institution} />
            <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />
          </Col>

          {!isActiveTotalQuote && (
            <Col className='one-third-responsive'>
              <div className='user-average'>
                <h4 className='byp-title-4 byp-mb-1 byp-title byp-fw-bold'>
                  Program Average
                </h4>
                <ProgramProgramAverage
                  filters={{ ...time, limit: 4, campus, institution }}
                  firstOption
                />
                {user?.type === 'Agency' && (
                  <>
                    <h4 className='byp-title-4 byp-mb-1 byp-title byp-fw-bold'>
                      Office Average
                    </h4>
                    <ProgramOfficeAverage
                      filters={{ ...time, limit: 4, program, campus, institution }}
                      firstOption
                    />
                  </>
                )}
              </div>
            </Col>
          )}

          <Col span={24}>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
              Top Users
            </h4>
            {program && (
              <ProgramPopularUsers
                time={time}
                campus={campus}
                program={program}
                institution={institution}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { ProgramAnaliticsInfo }
