import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useGetActivities (filters = {}) {
  const queryString = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetActivities', queryString],
    () => CourseRepository.getActivites(queryString), {
      retry: 2
    })
}

export { useGetActivities }
