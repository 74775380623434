import { Button, Col, Collapse, Form, InputNumber, Modal, Row, Input } from 'antd'
import { useEffect, useState } from 'react'
import utilsState from '../../../../bussines/utils/state-control'
import { useGetFeesByCategoryAndSede } from '../../../../bussines/hooks/fee/useGetFeesByCategoryAndSede'
import { usePostSaveFeePrices } from '../../../../bussines/hooks/fee/usePostSaveFeePrices'
import { useGetCampus } from '../../../../bussines/hooks/school/useGetCampus'
import { PrefixLabelSelector } from '../components/PrefixLabelFormItem'
import { HighSeasonFormList } from '../../../../common/components/helper-form/HighSeasonFormList'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_DISCOUNTS_CREATE_PAGE } from '../Discounts/CreateDiscountsPage'
import { showErrorModal } from '../../../../utils/propsForModal'
import { useFindProgram } from '../../../../bussines/hooks/program/useFindProgram'
import { SelectCampusAndYearByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusAndYearByProgramFormItem'
import { ConceptCostListFormItem } from '../components/ConceptCostListFormItem'
// import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ModalUserAlias } from '../../../../common/components/ModalUserAlias'

const { Panel } = Collapse
const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  return values[name][position].name ?? ''
}
const type = 'transport'

function FormCreateTransportPrices ({ program }) {
  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()

  const { campusId, yearId } = location.state ?? { campusId: undefined, yearId: undefined }
  const [divisa, setDivisa] = useState()
  const [campusID, setCampusID] = useState(campusId)
  const [programID, setProgramID] = useState()
  const [yearID, setYearId] = useState(yearId)
  const [aprove, setAprove] = useState(false)

  const [openModalAlias, setOpenModalAlias] = useState(false)
  const [valiatedData, setValiatedData] = useState({})

  const { data: programData, isLoading: isLoadingProgram } = useFindProgram(program)
  const { data: campus, isLoading: isLoadingCampus } = useGetCampus(campusID)
  const { data: TransportList, isLoading, refetch } = useGetFeesByCategoryAndSede(type, campusID, programID, yearID)
  const { currentState, isLoading: savingPrices, savePrices, error: errorSaving } = usePostSaveFeePrices()

  const prefixSelector = <PrefixLabelSelector divisa={divisa} />
  const [modal, contextHolder] = Modal.useModal()
  const [savinAll, setSavinAll] = useState(false)

  const onFinish = (values, toAprove, index) => {
    values.toAprove = toAprove
    if (!values.program_id) {
      values.program_id = programData?.id
    }
    setAprove(toAprove)
    if (index === undefined) {
      setSavinAll(true)
      // savePrices(type, values)
    } else {
      setSavinAll(false)
      const transpor = values.transports[index]
      values.transports = [transpor]
      // savePrices(type, values, index)
    }
    setValiatedData(values)
    setOpenModalAlias(true)
  }

  const onUpdate = ({ campus_id: campusID, year_id: yearID }) => {
    if (campusID !== undefined) {
      setCampusID(campusID)
      setYearId(undefined)
      form.setFieldsValue({ year_id: undefined, transports: [] })
    }
    if (yearID !== undefined) setYearId(yearID)
  }

  useEffect(() => {
    if (!isLoadingProgram && programData) {
      form.setFieldsValue({ program_id: programData?.id })
      setProgramID(programData?.id)
    }
  }, [programData, isLoadingProgram])

  useEffect(() => {
    if (!isLoadingCampus && campus) {
      form.setFieldsValue({ divisa_code: campus?.divisa_code })
      setDivisa(campus?.divisa_code)
    }
  }, [campus])

  useEffect(() => {
    if (!isLoading && TransportList) {
      console.log('🚀 ~ file: FormCreateCoursePrices.js ~ line 35 ~ useEffect ~ coursesData', TransportList)
      form.setFieldsValue({ transports: TransportList })
    }
  }, [isLoading, TransportList])

  useEffect(() => {
    if (!savingPrices && currentState === utilsState.STATE_SUCCESS) {
      modal.success({
        title: 'Success!',
        content: 'Información guardada',
        onOk () {
          if (aprove && savinAll) {
            navigate(
              URL_DISCOUNTS_CREATE_PAGE.replace(':program', program),
              {
                state: {
                  menuItemSelected: program + '_discounts_create',
                  openSub: program,
                  openSub2: program + '_discounts'
                }
              }
            )
          } else {
            refetch()
            setOpenModalAlias(false)
          }
        }
      })
    }
  }, [savingPrices, currentState])

  useEffect(() => {
    if (errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
  }, [errorSaving, modal])

  if (isLoading) {
    return <LottieCargando />
  }

  const onContinue = (values) => {
    navigate(URL_DISCOUNTS_CREATE_PAGE.replace(':program', program), { state: { menuItemSelected: program + '_discounts_create', openSub: program, openSub2: program + '_discounts' } })
  }

  const getSpanInput = (name) => {
    if (name === 'graduate') {
      return 6
    } else if (name === 'academic_years') {
      return 5
    } else if (name === 'language') {
      return 4
    }
  }

  function FormPanel () {
    return (
      <Form.List
        name='transports'
      >
        {(fields, { add, remove }, { errors }) => (
          <Collapse
            defaultActiveKey={['0']}
            className='course-prices'
          >
            {fields.map(({ key, name, ...restField }, index) => (
              <Panel
                header={
                  panelHeader(
                    restField.fieldKey,
                    form.getFieldValue(),
                    'transports'
                  )
                }
                key={key}
                className='byp-p-0'
              >
                <Row className='byp-list-course-item-content' gutter={16}>
                  <Col xs={24} md={12}>
                    <h6 className='w-100 byp-mb-1'> Currency: <span className='byp-ml-1 byp-fw-bold'>{divisa}</span> </h6>
                    <h6><b>Transport fee</b></h6>
                    <Row gutter={16} className='byp-mt-1'>
                      <Col xs={12} md={6}><h6>One-Way</h6></Col>
                      <Col xs={12} md={18}>
                        <Form.Item
                          name={[name, 'one_way_cost']}
                        >
                          <InputNumber min={0} addonBefore={prefixSelector} controls={false} />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}><h6>Roundway</h6></Col>
                      <Col xs={12} md={18}>
                        <Form.Item
                          name={[name, 'two_ways_cost']}
                        >
                          <InputNumber min={0} addonBefore={prefixSelector} controls={false} />
                        </Form.Item>
                      </Col>
                    </Row>

                    {
                      program !== 'graduate' && (
                        <>
                          {program === 'academic_years'
                            ? (<h6><b>Other cost</b></h6>)
                            : (<h6><b>Minor student fee</b></h6>)}
                          <Row className='w-100'>
                            <ConceptCostListFormItem
                              name={[name, 'minor_costs']}
                              divisa={divisa}
                            />
                          </Row>
                        </>
                      )
                    }

                  </Col>
                  <Col xs={24} md={12}>
                    {(program !== 'graduate' && program !== 'academic_years') && (
                      <HighSeasonFormList
                        name={[name, 'high_seasons']}
                        divisa={divisa}
                        isTransport
                      />
                    )}
                  </Col>
                </Row>
                <div className='byp-list-course-item-footer'>
                  <Row className='w-100 byp-mb-1'>
                    <Col span={24}>
                      <Button
                        className='byp-ml-1 float-right byp-btn-blue-100'
                        onClick={() => {
                          onFinish(form.getFieldsValue(), true, index)
                        }}
                        loading={savingPrices && !savinAll}
                      >
                        Approve
                      </Button>
                      <Button
                        className='float-right byp-btn-blue-200'
                        onClick={() => {
                          onFinish(form.getFieldsValue(), false, index)
                        }}
                        loading={savingPrices && !savinAll}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Panel>
            ))}
          </Collapse>
        )}
      </Form.List>
    )
  }

  return (
    <>
      <Form
        layout='horizontal'
        scrollToFirstError
        name='FormCreateQuote'
        onFinish={onFinish}
        form={form}
        onValuesChange={onUpdate}
        initialValues={{
          campus_id: campusID,
          program_id: programID,
          year_id: yearId
        }}
      >
        {contextHolder}
        <div className='byp-dashboard-content'>
          <Row>
            <Col sm={24} md={16}>
              <h4 className='byp-title'>
                Transport fees
              </h4>
            </Col>
            <Col sm={24} md={8}>
              <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Col className='byp-text-sm byp-fw-bold'>You're at:</Col>
                <Col sm={8} md={12} xl={8} xxl={getSpanInput(programData?.name)}>
                  <Form.Item className='margin-0'>
                    <Input className='graduate_show_category--input' value={programData?.label ?? '__________'} readOnly size='small' />
                  </Form.Item>
                </Col>
                {/* <Col xs={12} md={10}>
                  <SelectCampusByProgramFormItem
                    size='small'
                    program={program}
                    name='campus_id'
                    className='graduate_show_type school'
                    disabled
                    placeholder='School name'
                  />
                </Col> */}
              </Row>
            </Col>
          </Row>
          {
            /*
              TO DO: Check the correct behavior of the "ALL PROGRAMS" option
              <SelectCampusAndProgramFormItem campusID={campusID} showAll />
            */
          }
          <SelectCampusAndYearByProgramFormItem program={program} label={programData?.label} campusID={campusID} />

          {TransportList?.length > 0 && <FormPanel />}
          {TransportList?.length === 0 && (
            <h4 style={{ color: 'black', marginTop: '10px' }}>
              <b><i>There's nothing here. You need to create a transport first</i></b>
            </h4>
          )}
        </div>
        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              {
                TransportList?.length > 0 && (
                  <>
                    <Button
                      className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
                      htmlType='submit'
                      loading={savingPrices}
                      onClick={onContinue}
                    >
                      Continue
                    </Button>
                    <Button
                      className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
                      onClick={() => {
                        onFinish(form.getFieldsValue(), true)
                      }}
                      loading={savingPrices && savinAll}
                    >Approve all
                    </Button>
                    <Button
                      className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
                      onClick={() => {
                        onFinish(form.getFieldsValue())
                      }}
                      loading={savingPrices && savinAll}
                    > Save all
                    </Button>
                  </>
                )
              }
            </Col>
          </Row>
        </div>
      </Form>
      <ModalUserAlias
        isModalOpen={openModalAlias}
        setIsModalOpen={setOpenModalAlias}
        onSuccess={(userAlias) => {
          valiatedData.user_alias = userAlias
          savePrices(type, valiatedData)
        }}
        isLoading={savingPrices}
      />
    </>
  )
}

export { FormCreateTransportPrices }
