import React from 'react'
import { Col, Layout, Row } from 'antd'
import { AppLogoNameHeader } from '../components/AppLogoNameHeader'
import './../assets/css/main-theme.less'
import { MainThemeCarrousel } from './partials/MainThemeCarrousel'
import { MainThemeFooter } from './partials/MainThemeFooter'
import { UserMenuSwitcher } from './partials/UserMenuSwitcher'
import { LogoSchool } from '../assets/images/icons/LogoSchool'


const { Header, Footer, Content, Sider } = Layout

const PublicThemeNone = ({ children, activeCarousel = false, showMenu = false }) => {
  
  return (
    <Layout className='layout'>
      
      <Layout>
        <Row style={{ width: '100%' }}>
          {
            showMenu && (
              <Col md={4} lg={4} style={{ zIndex: 999, textAlign: 'center' }}>
                <Sider
                  width='100%'
                  className=''
                  breakpoint='lg'
                  collapsedWidth='0'
                  onBreakpoint={(broken) => {
                  }}
                  onCollapse={(collapsed, type) => {
                  }}
                >
                  <UserMenuSwitcher />
                </Sider>
              </Col>
            )
            }
          <Col xs={24} md={24} lg={showMenu ? 20 : 24} className='main-menu-container'>
            <Content>
              {activeCarousel && <MainThemeCarrousel />}
              <Content className='site-layout-content'>{children}</Content>
            </Content>
          </Col>
        </Row>
      </Layout>
      {
        !showMenu && (
          <Footer className='site-layout-footer'><MainThemeFooter/></Footer>
        )
      }
    </Layout>
  )
}

export { PublicThemeNone }
