import React, { useState } from 'react'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { FilterCategory } from './components/FilterCategory'
// import { TitleSection } from '../../common/components/TitleSection'
// import { StarOutlined, EyeOutlined } from '@ant-design/icons'
// import { ListAdvicesInspire } from './components/ListAdvicesInspire'
// import { ListBestLanguages } from './components/ListBestLanguages'
import { ListSearchQuoteResult } from './components/ListSearchQuoteResult'
import { SearchProvider } from '../../providers/search/search-context-provider'
// import { HideActiveSearch } from './components/HideActiveSearch'
import { userRoles } from '../../bussines/settings/validate-user-access'

const URL_QUOTER_PAGE = '/quote'

const QuoterPage = () => {
  const [subType, setSubType] = useState()

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false}>
        <div className='layout-index'>
          <SearchProvider>
            <FilterCategory subType={subType} />
            <ListSearchQuoteResult setSubType={setSubType} />
            {/* <HideActiveSearch>
              <TitleSection
                label='Inspirate con estos destinos'
                icon={<StarOutlined />}
              />
              <ListAdvicesInspire />
              <TitleSection
                label='Descubre los idiomas más populares'
                icon={<EyeOutlined />}
              />
              <ListBestLanguages />
            </HideActiveSearch> */}
          </SearchProvider>
        </div>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}
export { QuoterPage, URL_QUOTER_PAGE }
