import { AuthProvider } from '../../providers/auth/auth-context-provider'
import { MagicLinkProvider } from '../../providers/magic-link/magic-link-provider'
import { UserProvider } from '../../providers/auth/user-context-provider'
import { EnterpriseStatusProvider } from '../../providers/auth/enterprise-status-context-provider'

/**
 *
 * @param children
 * @param {Array} permissionTypes - validateUserAccess/userRoles
 * @returns {*}
 * @constructor
 */
const ValidateEnterpriseProfile = ({ children, permissionTypes }) => {
  return (
    <MagicLinkProvider>
      <AuthProvider permissionTypes={permissionTypes}>
        <EnterpriseStatusProvider permissionTypes={permissionTypes}>
          <UserProvider>
            {children}
          </UserProvider>
        </EnterpriseStatusProvider>
      </AuthProvider>
    </MagicLinkProvider>
  )
}

export { ValidateEnterpriseProfile }
