// const lengths = ['months', 'week']
const esLengths = {
  months: 'Meses',
  week: 'Semanas',
  semester: 'Semestres',
  year: 'Años',
  years: 'Años'
}

const esLength = (timeLength) => {
  const es = esLengths[timeLength]
  return es ?? timeLength
}

export { esLength }
