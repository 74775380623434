import React from 'react'
import { Avatar, Badge, Button, Col, Dropdown, Menu, Row } from 'antd'
import Icon, { UserOutlined } from '@ant-design/icons'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { Link, useLocation } from 'react-router-dom'
import { URL_EDIT_USER } from '../../../pages/user/EditUserProfilePage'
import { URL_MYQUOTES_PAGE } from '../../../pages/my-quotes'
import { ExchangeRatesModal } from '../../components/ExchangeRatesModal'
import { IconBookBookmark, IconBell, IconAnalyticsSharp } from '../../assets/svg/icons'
import { URL_REPORTS_PAGE } from '../../../pages/dashboards/agency/reports/ReportsPage'
import { URL_INSTITUTIONS_FOR_AGENCY } from '../../../pages/agency/InstitutionsForAgencyPage'
import { URL_AGENCYS_LIST_PAGE } from '../../../pages/institution/AgencysListPage'
import { SchoolsIcon } from '../icons/SchoolsIcon'
import { URL_EDIT_GROUP_EDUCATION } from '../../../pages/group-education/EditGroupEducationProfilePage'
import { URL_EDIT_AGENCY } from '../../../pages/agency/EditAgencyProfilePage'
import { URL_EDIT_INSTITUTION } from '../../../pages/institution/EditInstitutionProfilePage'
import { URL_PARTNERS_PAGE } from '../../../pages/partners'
import { LogoSchool } from '../../assets/images/icons/LogoSchool'
import { URL_INTEGRATIONS_PAGE } from '../../../pages/integrations/IntegrationsPage'
// import GoogleTranslateButton from '../../../pages/home/components/GoogleTranslateButton'
import { IconDashobard } from '../icons/IconDashobard'
import { iconHome } from '../icons/IconHome'
import { iconCompany } from '../icons/IconCompany'
import { IconLogout } from '../icons/IconLogout'
import { allRoles, educationGroupRoles, superAdminRoles } from '../../../utils/UsersAndRoles'
import { URL_USER_COURSES } from '../../../pages/user/MyCoursesPage'

const menu = (user, handleCloseSession, pathname, company) => {
  const role = user.role_name
  // console.log('🚀 ~ file: MainThemeMenu.js:29 ~ menu ~ role:', role)
  const Icono = (props) => <Icon component={IconLogout} {...props} />
  const IconoDashboar = (props) => <Icon component={IconDashobard} {...props} />
  const IconoCompany = (props) => <Icon component={iconCompany} {...props} />
  const IconoHome = (props) => <Icon component={iconHome} {...props} />

  function getEditProfileLink (type) {
    switch (type) {
      case 'Agency':
        return URL_EDIT_AGENCY
      case 'Institution':
        return URL_EDIT_INSTITUTION
      case 'EducationGroup':
        return URL_EDIT_GROUP_EDUCATION
      default:
        return URL_EDIT_GROUP_EDUCATION
    }
  }

  const items = [
    { // user name
      name: 'user',
      key: 'user',
      label: <Link to={URL_EDIT_USER} className='byp-user-menu-item'> {user.name} </Link>,
      roles: allRoles
    },
    {
      name: 'divider',
      key: 'divider',
      type: 'divider',
      roles: allRoles
    },
    { // Profile
      name: 'profile',
      key: 'profile',
      label: <Link to={URL_EDIT_USER} className='byp-user-menu-item'>Show profile</Link>,
      icon: <UserOutlined className='byp-user-menu-item' />,
      roles: allRoles
    },
    { // Company profile
      name: 'company',
      key: 'company',
      label: <Link to={getEditProfileLink(user?.type)} className='byp-user-menu-item'>Company profile</Link>,
      icon: <IconoCompany className='byp-user-menu-item' />,
      roles: allRoles
    },
    { // Courses
      name: 'courses',
      key: 'courses',
      label: <Link to={URL_USER_COURSES} className='byp-user-menu-item'>Courses</Link>,
      icon: <UserOutlined className='byp-user-menu-item' />,
      roles: allRoles
    },
    { // Integrations
      name: 'integrations',
      key: 'integrations',
      label: <Link to={URL_INTEGRATIONS_PAGE} className='byp-user-menu-item'>Integrations</Link>,
      icon: <IconoCompany className='byp-user-menu-item' />,
      roles: ['agency-admin', 'institution-admin']
    },
    { // dashboard
      name: 'dashboard',
      key: 'dashboard',
      label: <Link className='byp-user-menu-item' to='/dashboard'>Dashboard</Link>,
      icon: <IconoDashboar className='byp-user-menu-item' />,
      roles: [
        'agency-admin',
        'agency-office-manager',
        'agency-manager',
        'agency-editor',
        'institution-admin', 'institution-manager',
        ...superAdminRoles,
        ...educationGroupRoles
      ]
    },
    { // Home
      name: 'home',
      key: 'home',
      label: <Link to='/quote' className='byp-user-menu-item'>Home</Link>,
      icon: <IconoHome className='byp-user-menu-item' />,
      roles: allRoles
    },
    { // Logout
      name: 'logout',
      key: 'logout',
      label: (
        <a href='/#' style={{ color: 'red' }} onClick={handleCloseSession}>
          Logout
        </a>
      ),
      icon: <Icono style={{ color: 'red' }} />,
      roles: allRoles
    }
  ]

  const menuItems = items.filter((menuItem) => {
    if (menuItem.name === 'dashboard' && pathname.split('/').includes('dashboard')) {
      // console.log('HIDE DASHBOARD')
      return false
    }
    if (menuItem.name === 'home' && (pathname === '/' || pathname === '/quote')) {
      // console.log('HIDE HOME')
      return false
    }
    return menuItem.roles.includes(role)
  })

  return (
    <Menu
      items={menuItems}
      className='byp-user-menu'
    />
  )
}

const notifications = (user, pathname) => {
  const notItems = [
    {
      key: '1',
      label: <Link to='#' className='byp-user-menu-item'>There are not notifications yet</Link>
    }
  ]
  return (
    <Menu
      items={notItems}
      className='byp-user-menu'
      style={{ width: 256 }}
    />
  )
}

const MainThemeMenu = ({ handleClick, isActive }) => {
  const { user, company, logout } = useAuth()
  const { pathname } = useLocation()

  return (
    <div>
      <Row gutter={12}>
        {user.type === 'Agency' &&
          (
            <Col style={{ left: '2rem' }}>
              <Link
                to={URL_REPORTS_PAGE}
                state={{}}
              >
                <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><IconAnalyticsSharp /> My Reports</span>
              </Link>
            </Col>
          )}

        <Col style={{ left: '2rem' }}>
          {user.type === 'Agency' && <ExchangeRatesModal />}
        </Col>
        <Col style={{ left: '2rem' }} className={user.type === 'Institution' ? '' : 'byp-d-none'}>
          <Link
            to={URL_AGENCYS_LIST_PAGE}
            state={{}}
          >
            <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><SchoolsIcon /> Active agencies</span>
          </Link>
        </Col>
        <Col style={{ left: '2rem' }}>
          <Link
            to={URL_MYQUOTES_PAGE}
            state={{}}
          >
            <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><IconBookBookmark /> My Quotes</span>
          </Link>
        </Col>
        <Col style={{ left: '2rem' }} className={user.type === 'Education group' ? '' : 'byp-d-none'}>
          <Link
            to={URL_AGENCYS_LIST_PAGE}
            state={{}}
          >
            <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><LogoSchool color='var(--blue-100)' /> Agencies</span>
          </Link>
        </Col>

        {(user.roles?.includes('Agency Editor') || user.roles?.includes('Agency Administrator')) &&
          <Col style={{ left: '2rem' }} className={user.type === 'Agency' ? '' : 'byp-d-none'}>
            <Link
              to={URL_INSTITUTIONS_FOR_AGENCY}
              state={{}}
            >
              <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><SchoolsIcon /> Schools</span>
            </Link>
          </Col>}
        <Col style={{ left: '2rem' }} className={user.type === 'Education group' ? '' : 'byp-d-none'}>
          <Link
            to={URL_PARTNERS_PAGE}
            state={{}}
          >
            <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><SchoolsIcon /> Schools</span>
          </Link>
        </Col>
        <Col style={{ left: '2rem' }}>
          <Dropdown overlay={notifications} placement='bottomLeft'>
            <a>
              <span className='byp-btn-span align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><IconBell />Notifications</span>
            </a>
          </Dropdown>
        </Col>
        {/* <Col style={{ left: '2rem' }}>
          <GoogleTranslateButton className='google_translate_container' />
        </Col> */}
        <Col style={{ left: '2rem' }}>
          <Dropdown size='40px' overlay={menu(user, logout, pathname, company)} placement='bottomRight' arrow>
            <Avatar
              src={pathname === '/' || pathname === '/quote' ? user?.profile_photo_url : company?.profile_photo_url}
            />
          </Dropdown>
        </Col>

        <div className={`nav__menu_list ${isActive ? 'active' : ''}`}>
          <div>
            {user.type === 'Agency' &&
              (
                <Col>
                  <Link
                    to={URL_REPORTS_PAGE}
                    state={{}}
                  >
                    <span className='align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold', display: 'flex' }}><IconAnalyticsSharp /> My Reports</span>
                  </Link>
                </Col>
              )}

            <Link
              to={URL_MYQUOTES_PAGE}
              state={{}}
            >
              <span className='align-icon' style={{ color: '#2460B7', fontSize: '12px', fontWeight: 'bold' }}><IconBookBookmark color='#123456' /> My Quotes</span>
            </Link>

            <Dropdown overlay={notifications} placement='bottomLeft'>
              <Button
                className='byp-btn-other bottomLeft-responsive'
                type='link'
                icon={<Badge dot count={0}><IconBell /></Badge>}
              >
                <span className='align-icon'>Notifications</span>
              </Button>
            </Dropdown>

            {user.type === 'Agency' && <ExchangeRatesModal />}
          </div>
        </div>
      </Row>
    </div>
  )
}

export { MainThemeMenu }
