import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import Form from 'antd/lib/form/Form'
import { AgencyProfileFieldsContainer } from '../../steps/agency-profile/AgencyProfileFieldsContainer'
import { AgencyPaymentsFieldContainer } from '../../steps/agency-payments/AgencyPaymentsFieldContainer'
import stateControl from '../../../../../bussines/utils/state-control'
import { FullPageSpinner } from '../../../../../common/components/FullPageSpinner'
import { useProfileEnterprise } from '../../../../../providers/enterprise/profile-enterprise-provider'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'

let readOnly = true

function EditAgencyProfile () {
  const [modal, context] = Modal.useModal()
  const {
    form,
    loadingProfile,
    initValues,
    isLoadingUpdateEnterprise,
    currentStateUpdateEnterprise,
    openNotification,
    updateEnterprise,
    handleHomePage,
    refetch,
    errorUpdateCompanyProfile
  } = useProfileEnterprise()

  useEffect(() => {
    if (!isLoadingUpdateEnterprise && currentStateUpdateEnterprise === stateControl.STATE_SUCCESS) {
      openNotification('The profile has been updated')
      refetch()
    } else if (!isLoadingUpdateEnterprise && errorUpdateCompanyProfile) {
      errorUpdateCompanyProfile.response?.data?.message_debug.includes('Integrity constraint violation: 1062')?
      modal.error({
        title: 'Ups!',
        content: "The Company's name already exist, try with other one"
      })
      :
      modal.error({
        title: 'Ups!',
        content: errorUpdateCompanyProfile.response?.data?.message
      })            
    }
  }, [isLoadingUpdateEnterprise])

  useEffect(() => {
    if (initValues) {
      form.setFieldsValue(initValues)
    }
  }, [initValues])

  const onFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    updateEnterprise(values)
  }

  const { user } = useAuth()
  useEffect(() => {
    // const roles = user?.roles ?? []
    const roles = user?.role_names ?? []
    if (roles.includes('agency-admin') || roles?.includes('agency-editor')) {
      readOnly = false
    }
    if (roles.includes('agency-office-manager')) {
      readOnly = true
    }
  }, [user])

  if (!initValues) {
    return <FullPageSpinner title='Cargando datos' />
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      footer={
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button
            onClick={handleHomePage}
            className='byp-link'
          >
            Back
          </Button>
          <Button
            className='byp-btn-blue-200'
            size='large'
            onClick={() => form.submit()}
            loading={loadingProfile || isLoadingUpdateEnterprise}
            disabled={loadingProfile}
          > Update
          </Button>
        </div>
      }
    >
      <div style={{ margin: '18px 5%' }}>
        {context}
        <div style={{ margin: '50px 0px' }}>
          <h3>Edit your agency profile </h3>
        </div>
        <p style={{ marginBottom: '50px' }}>
          Let's get started, enter your agency's details so that you have the opportunuty to offer Additional costs to your
          clients, as well as customizes quotes and searches.
        </p>
        <Form
          form={form}
          layout='vertical'
          scrollToFirstError
          name='UpdateAgency'
          onFinish={onFinish}
          initialValues={initValues}
          requiredMark={false}
        >
          <AgencyProfileFieldsContainer form={form} readOnly={readOnly} data={initValues} />
          {/* <AgencyBranchesFieldContainer form={form} readOnly={readOnly} /> */}
          <AgencyPaymentsFieldContainer form={form} readOnly={readOnly} />
        </Form>
      </div>
    </StepBaseTemplate>
  )
}

export { EditAgencyProfile }
