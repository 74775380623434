import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { TopBarMenuCustom } from '../components/TopBarMenuCustom'
import { FormPricesServices } from './FormPricesServices'
const URL_UPLOAD_PRICES_ADD_SERVICES = '/dashboard/institution/create/additional_service/price'

function CreatePricesAdditionalServices () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >

        <TopBarMenuCustom />

        <FormPricesServices />

      </MainTheme>
    </PageProtected>
  )
}

export { CreatePricesAdditionalServices, URL_UPLOAD_PRICES_ADD_SERVICES }
