import { Button, Table } from 'antd'
// import { ServicesTableFilter } from './Filters/ServicesTableFilter'
import { useEffect, useState } from 'react'
import LottieCargando from '../../../../../lotties/LottieCargando'
import ConfirmModal from '../../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../../common/theme/icons/EditIcon'

const { Column } = Table
function MiscellaneousTable ({ services, setId, isLoading, deleteItem }) {
  const [service, setServices] = useState(services)

  useEffect(() => {
    if (services) {
      setServices(services)
    }
  }, [services])

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <div>
      {/* <ServicesTableFilter services={services} setServices={setServices} /> */}
      <Table
        loading={isLoading}
        dataSource={service}
        className='byp-table-dashboard'
        title={() => 'Added miscellaneous'}
        rowKey='id'
      >
        <Column
          title='Name' dataIndex='name' key='name'
          render={(_, record) => (
            <a>{record.name}</a>
          )}
        />
        <Column
          title='Currency' dataIndex='currency' key='currency'
        />
        <Column
          title='Cost' dataIndex='cost' key='cost'
        />
        <Column
          title='Actions'
          key='action'
          render={(_, record) => (
            <>
              <ConfirmModal onOk={deleteItem} isLoading={isLoading} id={record.id} />
              <Button
                className='byp-btn-edit'
                loading={isLoading}
                size='small'
                onClick={
                  () => {
                    setId(record.id)
                  }
                }
                icon={<EditIcon />}
              />
            </>
          )}
        />
      </Table>
    </div>
  )
}

export { MiscellaneousTable }
