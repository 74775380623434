import React from 'react'
import icon_services_blue from '../../../common/assets/images/icon_services_blue.png'
import icon_agencies_blue from '../../../common/assets/images/icon_agencies_blue.png'
import icon_schools_blue from '../../../common/assets/images/icon_schools_blue.png'
import logo from '../../../common/assets/images/logo.png'


export function HomeSectionF() {
  return (
    <div className='home-responsive-font-size' style={{ marginTop: '7rem' }}>
      <div className='d-flex justify-center align-center' style={{textAlign: 'center', top:'7rem', flexDirection: 'column'}}>
        <h4 className='byp-fw-bold byp-mb-3' style={{color: '#2460b7', fontSize: '3.125rem'}}>Four easy steps to be part of</h4>
        <img className='byp-topbar-logo byp-mb-3' src={logo} style={{ maxWidth:'28.125rem' }} alt=''></img>
        <div className='d-flex byp-mb-3' style={{ justifyContent: 'space-between', gap: '2rem' }}>
          <div>
            <img src={icon_schools_blue} style={{width:'4.688rem',height:'4.688rem', marginBottom: '1.625rem'}} alt=''/>
            <h5><b>Schools</b></h5>
          </div>
          <div>
            <img src={icon_agencies_blue} style={{width:'4.688rem',height:'4.688rem', marginBottom: '1.625rem'}} alt=''/>
            <h5><b>Agencies</b></h5>
          </div>
          <div>
            <img src={icon_services_blue}  style={{width:'4.688rem',height:'4.688rem', marginBottom: '1.625rem'}} alt=''/>
            <h5><b>Services</b></h5>
          </div>
        </div>
      </div>
    </div>
  );
}