import { Col, Form, Input, Radio, Row } from 'antd'
import React from 'react'

function AccommodationForm ({ form }) {
  const haveAccommodation = Form.useWatch('haveAccommodation', form)

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <h2 className='subtitle-aplication byp-m-0'>Hospedaje</h2>
      </Col>
      <Col span={24} className='byp-mb-1'>
        <Row align='middle' gutter={[8, 8]}>
          <Col>
            <label className='label-aplication'>¿Necesitas hospedaje?</label>
          </Col>
          <Col>
            <Form.Item className='byp-m-0' name='haveAccommodation'>
              <Radio.Group name='radiogroup' defaultValue='no' disabled>
                <Radio value='yes' className='radio_btn-quote'>Sí</Radio>
                <Radio value='no' className='radio_btn-quote'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      {haveAccommodation === 'yes' && (
        <>
          <Col xs={24} md={12}>
            <label className='label-aplication'>Tipo de Hospedaje</label>
            <Form.Item
              name='accommodation'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <label className='label-aplication'>Duración</label>
            <Form.Item
              name='duration_accomodation'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <label className='label-aplication'>Fecha de Inicio</label>
            <Form.Item
              name='start_date_accommodation'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <label className='label-aplication'>Fecha de salida</label>
            <Form.Item
              name='end_date_accommodation'
            >
              <Input className='aplication__input' disabled />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  )
}

export default AccommodationForm
