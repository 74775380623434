import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useGetMostPopularCitiesByUser } from '../../../../../bussines/hooks/agency/analytics/general/useGetMostPopularCities'
import axios from 'axios'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

const MostPopularCities = ({ time, user }) => {
  const [mostPopularCities, setMostPopularCities] = useState([])
  const { data: popularCities, isLoading } = useGetMostPopularCitiesByUser({ user, limit: 5, ...time })

  useEffect(() => {
    setMostPopularCities(popularCities?.slice(0, 5))
    // console.log('🚀 ~ file: MostPopularCities.js:13 ~ useEffect ~ popularCities', popularCities)
  }, [popularCities])

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]}>
      {
        mostPopularCities?.map(city => (
          <Col
            xs={24}
            sm={12}
            xl={6}
            key={city.id}
          >
            <div className='quote-list-item-card' style={{ padding: '0.5rem 0.75rem', height: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem', justifyContent: 'space-between' }}>
              <div>
                <p className='margin-0 byp-color-gray-100 byp-text-sm'>{city.country_code}</p>

                <div style={{ textAlign: 'center' }}>
                  <ImageFlag code={city.country_code} name={city.name} />
                  <p className='margin-0 byp-color-gray-100'>{city.name}</p>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className='margin-0 byp-color-gray-100 byp-text-sm'>Quotes</p>
                <p className='margin-0 byp-color-gray-100 byp-text-sm'>{city.quotes}</p>
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  )
}

const ImageFlag = ({ code, name }) => {
  const [flag, setFlag] = useState()

  useEffect(() => {
    getFlag(code)
  }, [code])

  const getFlag = async (code) => {
    try {
      const response = await axios.get(`https://restcountries.com/v3.1/alpha/${code}`)
      setFlag(response.data[0].flags.svg)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <img alt={name} className='img-rounded byp-mb-1' style={{ width: '3.75rem', height: '3.75rem' }} src={flag} />
  )
}

export default MostPopularCities
