import { useQuery } from 'react-query'
import AgencyRepository from '../../../repositories/AgencyRepository'

function useGetOffices (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetOffices', filtersQuery],
    () => AgencyRepository.getOffices(filtersQuery),
    { retry: 2 }
  )
}

export { useGetOffices }
