import { Collapse, Form, Tag } from 'antd'
import { ConceptDiscountFormItem } from '../components/ConceptDiscountFormItem'
import { CourseCostsDiscounts } from './components/CourseCostsDiscounts'

const { Panel } = Collapse

const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const header = (
    <>
      {values[name][position].campus} <Tag className='byp-ml-1'>{values[name][position].status}</Tag>
    </>
  )
  return header
}

const useCourseCostDiscount = ['boarding_schools', 'graduate', 'academic_years']

function FormCoursePricesDiscountsContainer ({ form, onFinish, name, program }) {
  const listName = name
  const array = Form.useWatch(listName, form) || []
  return (
    <Form.List
      name={listName}
    >
      {(fields, { add, remove }, { errors }) => (
        <Collapse
          defaultActiveKey={['0']}
        >
          {fields.map(({ key, name, ...restField }, index) => (
            <Panel
              header={
                panelHeader(
                  restField.fieldKey,
                  form.getFieldValue(),
                  listName
                )
              }
              key={key}
            >
              {useCourseCostDiscount.includes(program) && (
                <CourseCostsDiscounts
                  name={[name, 'courses']}
                  cleanName='courses'
                  rootName={listName}
                  list={array[name]}
                  form={form}
                  showInKind
                  hidden
                  program={program}
                  ranges
                />
              )}
              {!useCourseCostDiscount.includes(program) && (
                <ConceptDiscountFormItem
                  name={[name, 'courses']}
                  cleanName='courses'
                  list={array[name]}
                  form={form}
                  showInKind
                  hidden
                  program={program}
                  ranges
                />
              )}
            </Panel>
          ))}
        </Collapse>
      )}
    </Form.List>
  )
}

export { FormCoursePricesDiscountsContainer }
