import React from 'react'
import { Col, Form, Row, Input, Select, InputNumber, Button } from 'antd'
import { PrefixLabelSelector } from '../../Institution/components/PrefixLabelFormItem'
import { AddAndRemoveListButtons } from '../../Institution/components/AddAndRemoveListButtons'

const { Option } = Select
const required = { required: true, message: 'This field is required' }

export const FormConceptPack = ({ name, divisa, haveDayOption = false }) => {
  // console.log("🚀 ~ file: FormConceptPack.js:9 ~ FormConceptPack ~ haveDayOption", haveDayOption)
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />

  return (
    <Form.List
      name={name}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} span={24} gutter={12}>
              <Col span={8}>
                <Form.Item
                  name={[name, 'name']}
                  label='Concept'
                  rules={[required]}
                >
                  <Input placeholder='Concept' />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name={[name, 'cost']}
                  label='Cost'
                  rules={[required]}
                >
                  <InputNumber addonBefore={prefixSelector} placeholder='00.00' min={0} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='Charging for'
                  name={[name, 'frequency']}
                >
                  <Select placeholder='' size='small'>
                    <Option key='unic'>Single payment</Option>
                    {haveDayOption && <Option key='day'>Daily</Option>}
                    <Option key='weeks'>Weekly</Option>
                    <Option key='semester'>One semester</Option>
                    <Option key='year'>One year payment</Option>

                  </Select>
                </Form.Item>
              </Col>
              <Col span={3} style={{ display: 'flex', gap: '20px' }}>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Col>
            </Row>
          ))}

          {
          fields.length === 0 && (
            <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()}>
              Add
            </Button>
          )
        }

        </>
      )}
    </Form.List>
  )
}
