import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { SearchProvider } from '../../providers/search/search-context-provider'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { FilterProgram } from './components/FilterProgram'
import { ListMyQuoteResults } from './components/ListMyQuoteResults'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Searcher } from './components/Searcher'

const URL_MYQUOTES_PAGE = '/my-estimates'

const MyQuotesPage = () => {
  const [collection, setCollection] = useState([])
  const [isActive, setIsActive] = useState(false)
  const location = useLocation()

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel>
        <SearchProvider>
          <div className='layout-index' style={{ paddingTop:'20px' }}>
            <FilterProgram />
            <Searcher />
            <ListMyQuoteResults />
          </div>
        </SearchProvider>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}
export { MyQuotesPage, URL_MYQUOTES_PAGE }
