import { Col, Row } from 'antd'
import { userRoles } from '../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { AccountsTable } from './components/AccountsTable'
import { useLocation } from 'react-router-dom'

const URL_USERS_PAGE = '/dashboard/admin/users'

function AllUsersPage () {
  const { state } = useLocation()
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_SUPER_ADMIN,
        userRoles.TYPE_SUPER_EDITOR
      ]}
    >
      <MainTheme
        showMenu
      >
        <Row className='byp-dashboard-content'>
          <Col span={24}>
            <AccountsTable type={state?.type} />
          </Col>
        </Row>
      </MainTheme>
    </PageProtected>
  )
}

export { AllUsersPage, URL_USERS_PAGE }
