import { useQuery } from 'react-query'
import PackagesRepository from '../../repositories/PackagesRepository'

function useGetPackagePrices (packageId, year) {
  const filters = { year }
  return useQuery(['useGetPackagePrices', filters, packageId],
    () => PackagesRepository.getPrices(packageId, filters), {
      retry: 1,
      enabled: !!packageId && !!year
    })
}

export { useGetPackagePrices }
