import { Form, Radio } from 'antd'

function BasicRadioGroupFormItem ({ name, label, trueText = 'Yes', falseText = 'No', ...props }) {
  return (
    <Form.Item
      name={name}
      label={label}
      labelCol={12}
      initialValue={false}
    >
      <Radio.Group {...props}>
        <Radio value> {trueText} </Radio>
        <Radio value={false}> {falseText} </Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export { BasicRadioGroupFormItem }
