import { Col, Row } from 'antd'
import { useState } from 'react'
import { SelectCampusByProgramFormItem } from './SelectCampusByProgramFormItem'
import { SelectYearByCampus } from './SelectYearByCampus'

export function SelectCampusAndYearByProgramFormItem ({ program, campusID, label }) {
  const [campusId, setCampusId] = useState(campusID)
  const getcategories = (id) => {
    setCampusId(id)
  }
  return (
    <Row gutter={12}>
      {/* <Col> <h5 className='byp-title byp-ml-1 byp-mr-1'>{label ?? '__________'}</h5> </Col> */}
      <Col xs={9} md={6}>
        <SelectCampusByProgramFormItem
          program={program} name='campus_id'
          onChange={getcategories} label='School'
          size='small'
          placeholder='Choose the school'
        />
      </Col>
      <Col xs={9} md={6}>
        <SelectYearByCampus
          campus={campusId} name='year_id' label='Year' size='small'
          placeholder='Choose the year'
        />
      </Col>
    </Row>
  )
}
