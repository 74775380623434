import React, { useEffect, useState } from 'react'
import { notification, List, Button } from 'antd'
import { useGetPaidQuotes } from '../../../bussines/hooks/quote/useGetPaidQuotes'
import { CardMyQuote } from './CardMyQuote'

const MyTravelsInfiniteList = () => {
  const [filters, setFilters] = useState({ page: 1, limit: 4 })
  const [courses, setCourses] = useState([])
  const { data: quotes, isLoading, error } = useGetPaidQuotes(filters)

  const onLoadMore = () => {
    const limit = filters.limit + 4
    setFilters({
      ...filters,
      limit
    })
  }

  const loadMore =
    !isLoading && filters.limit === courses.length
      ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px'
          }}
        >
          <Button onClick={onLoadMore} className='byp-btn-red-50 br-6'>Cargar más</Button>
        </div>
        )
      : null

  useEffect(() => {
    if (!isLoading && quotes) {
      console.log('🚀 ~ file: MyTravelsInfiniteList.js:7 ~ useEffect ~ quotes:', quotes)
    }
    if (!isLoading && error) {
      notification.error({
        message: error?.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [])

  useEffect(() => {
    if (!isLoading && quotes) {
      // years
      const data = quotes.data ?? []
      const lists = []
      data.forEach(element => {
        const createdAt = new Date(element.created_at)
        const year = createdAt.getFullYear()
        const listYear = lists.find(list => list.year === year)
        if (listYear) {
          listYear.courses.push(element)
        } else {
          lists.push({
            year, courses: [element]
          })
        }
      })
      setCourses(quotes.data)
    } else if (!isLoading && error) {
      notification.error({
        message: error?.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [isLoading, quotes])

  return (
    <List
      dataSource={quotes?.data ?? []}
      loading={isLoading}
      itemLayout='vertical'
      renderItem={(item, index) => (
        <List.Item>
          <CardMyQuote item={item} key={item.id} />
        </List.Item>
      )}
      loadMore={loadMore}
    />
  )
}

export { MyTravelsInfiniteList }
