import { Col, Row, Tooltip } from 'antd'
import React from 'react'
import { InfoIcon } from '../../../quote-preview/assets/imgs/Icons'
import { Bar } from 'react-chartjs-2'

const text = <span>You can see here the grow for Languages, for each country in a special range of time</span>

export const ChartTotalQuotesTrends = () => {
  return (
    <div className='chart__total-quotes'>
      <Row justify='space-between'>
        <Col span={12}>
          <p>TOTAL QUOTES</p>
          <p>32,801</p>
          <p>SPECIAL TIMELINE</p>
        </Col>
        <Col span={12}>
          <Row justify='end'>
            <Tooltip placement='bottomRight' title={text} color='#2D2F66' style={{ padding: '20px' }}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Row>
        </Col>
        <Col span={24}>
          <div className='d-flex justify-center byp-mt-1 byp-mb-1'>
            <div style={{ height: '2px', background: '#F4F4F4', width: '100%', margin: '0 50px' }} />
          </div>
        </Col>
        <Col span={24}>
          <HorizontalBar />
        </Col>
      </Row>
    </div>
  )
}

function HorizontalBar () {
  const data = {
    datasets: [{
      label: 'cosa 1',
      data: [10, 20, 30, 40],
      backgroundColor: [
        'rgba(45, 47, 102)'
      ],
      order: 1
    },
    {
      label: 'cosa 2',
      data: [20, 40, 60, 80],
      backgroundColor: [
        'rgba(255, 204, 0)'
      ],
      order: 2

    },
    {
      label: 'cosa 3',
      data: [20, 40, 60, 80],
      backgroundColor: [
        'rgba(100, 149, 237)'
      ],
      order: 3

    },
    {
      label: 'cosa 4',
      data: [20, null, 60, 80],
      backgroundColor: [
        'rgba(172, 133, 222)'
      ],
      order: 4

    }],
    labels: ['a', 'b', 'c', 'd']
  }

  const config = {
    type: 'bar',
    data,
    responsive: true,
    indexAxis: 'y'
  }

  return (
    <div style={{ width: '100%' }}>
      <Bar options={config} data={data} />
    </div>
  )
}
