import { Col, Form, Input, Row } from 'antd'
import { BasicRadioGroupFormItem } from '../../../common/components/helper-form/BasicRadioGroupFormItem'
import LanguagesFormItemList from './LanguagesFormItemList'
import { SelectTagsFormItem } from '../../../common/components/helper-form/SelectTagsFormItem'

const ApplicationAcademicFields = ({ disabled }) => {
  const playInstruments = Form.useWatch('playInstruments')
  const playSports = Form.useWatch('playSports')
  const playReligions = Form.useWatch('playReligions')
  const hasTravels = Form.useWatch('hasTravels')
  const playSportsImportant = Form.useWatch('playSportsImportant')
  const hasLanguages = Form.useWatch('hasLanguages')
  return (
    <Row gutter={[16]}>
      {/* subjects */}
      <Col span={24}>
        <label className='label-aplication'>Materias favoritas</label>
        <SelectTagsFormItem
          name='subjects'
          filters={{
            type: 'subject'
          }}
          className='byp-select transparent br-1 aplication__input byp-p-0'
          size='large'
          disabled={disabled}
        />
      </Col>
      {/* Instruments */}
      <Col className='radio_span' span={24}>
        <BasicRadioGroupFormItem
          name='playInstruments'
          label='¿Tocas algún instrumento?'
          trueText='Sí'
          falseText='No'
          className='byp-mb-0'
          radioGroupProps={{
            className: 'byp-ant-radio',
            disabled
          }}
        />
        {playInstruments && (
          <>
            <label className='label-aplication'>Cual(es)</label>
            <SelectTagsFormItem
              name='instruments'
              filters={{
                type: 'instrument'
              }}
              className='byp-select transparent br-1 aplication__input byp-p-0'
              size='large'
              disabled={disabled}
            />
          </>
        )}
      </Col>
      {/* Hobbies */}
      <Col span={24}>
        <label className='label-aplication'>Hobbies e intereses</label>
        <SelectTagsFormItem
          name='hobbies'
          filters={{
            type: 'hobbie'
          }}
          className='byp-select transparent br-1 aplication__input byp-p-0'
          size='large'
          disabled={disabled}
        />
      </Col>
      {/* Sports */}
      <Col className='radio_span' span={24}>
        <BasicRadioGroupFormItem
          name='playSports'
          label='¿Practicas algún deporte?'
          trueText='Sí'
          falseText='No'
          className='byp-mb-0'
          radioGroupProps={{
            className: 'byp-ant-radio',
            disabled
          }}
        />
        {playSports && (
          <>
            <label className='label-aplication'>Cual(es)</label>
            <SelectTagsFormItem
              name='sports'
              filters={{
                type: 'sport'
              }}
              className='byp-select transparent br-1 aplication__input byp-p-0'
              size='large'
              disabled={disabled}
            />
          </>
        )}
      </Col>
      {/* Religions */}
      <Col className='radio_span' span={24}>
        <BasicRadioGroupFormItem
          name='playReligions'
          label='¿Practicas alguna religión?'
          trueText='Sí'
          falseText='No'
          className='byp-mb-0'
          radioGroupProps={{
            className: 'byp-ant-radio',
            disabled
          }}
        />
        {playReligions && (
          <>
            <label className='label-aplication'>Cual(es)</label>
            <SelectTagsFormItem
              name='religions'
              filters={{
                type: 'religion'
              }}
              className='byp-select transparent br-1 aplication__input byp-p-0'
              size='large'
              disabled={disabled}
            />
          </>
        )}
      </Col>
      {/* Travels */}
      <Col className='radio_span' span={24}>
        <BasicRadioGroupFormItem
          name='hasTravels'
          label='¿Haz viajado al extranjero?'
          trueText='Sí'
          falseText='No'
          className='byp-mb-0'
          radioGroupProps={{
            className: 'byp-ant-radio',
            disabled
          }}
        />
        {hasTravels && (
          <>
            <label className='label-aplication'>¿A donde?</label>
            <SelectTagsFormItem
              name='locations'
              filters={{
                type: 'location'
              }}
              className='byp-select transparent br-1 aplication__input byp-p-0'
              size='large'
              disabled={disabled}
            />
          </>
        )}
      </Col>
      {/* Sports Importance */}
      <Col className='radio_span' span={24}>
        <BasicRadioGroupFormItem
          name='playSportsImportant'
          label='¿Es para ti muy importante practicar algún deporte o clase extracurricular?'
          trueText='Sí'
          falseText='No'
          className='byp-mb-0'
          radioGroupProps={{
            className: 'byp-ant-radio',
            disabled
          }}
        />
        {playSportsImportant && (
          <>
            <label className='label-aplication'>Cual(es)</label>
            <SelectTagsFormItem
              name='important_sports'
              filters={{
                type: 'sport'
              }}
              className='byp-select transparent br-1 aplication__input byp-p-0'
              size='large'
              disabled={disabled}
            />
            <Form.Item
              name='sport_why'
              label='¿Por qué es importante?'
              // rules={[required]}
            >
              <Input className='aplication__input' placeholder='' disabled={disabled} />
            </Form.Item>
          </>
        )}
      </Col>
      {/* Languages */}
      <Col className='radio_span' span={24}>
        <BasicRadioGroupFormItem
          name='hasLanguages'
          label='Hablas otro idioma?'
          trueText='Sí'
          falseText='No'
          className='byp-mb-0'
          radioGroupProps={{
            className: 'byp-ant-radio',
            disabled
          }}
        />
        {hasLanguages && (
          <>
            <LanguagesFormItemList listName='aditional_languages' disabled={disabled} />
          </>
        )}
      </Col>
    </Row>
  )
}

export { ApplicationAcademicFields }
