import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useGetReligions (filters = {}) {
  const queryString = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetReligions', queryString],
    () => CourseRepository.getReligions(queryString), {
      retry: 2
    })
}

export { useGetReligions }
