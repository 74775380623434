import { useQuery } from 'react-query'
import UserRepository from '../../../../repositories/UserRepository'

function useGetQuotesInfo (user, filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetQuotesInfo', user, filtersQuery], () => UserRepository.quotesInfo(user, filtersQuery), {
    enabled: !!user,
    retry: 1
  })
}

function useGetTotalQuotes (filters = {}) {
  return useQuery(['useGetTotalQuotes', filters], () => UserRepository.getTotalQuotes(filters), {
    retry: 1
  })
}

export { useGetQuotesInfo, useGetTotalQuotes }
