import { Button, Col, Form, Input, Row, notification } from 'antd'
import React from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'

function ClientInfoSection() {
  const { isLoadingPost } = useQuoter()
  return (
    <Row style={{ marginTop: '20px' }}>
      <Col span={24}>
        <Form.Item name='client_name'>
          <Input
            style={{ width: '100%' }}
            placeholder='Client name'
            string='Nombre de cliente'
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name='message'>
          <Input.TextArea
            placeholder='Custom message' rows={4} maxLength={150}
          />
        </Form.Item>
        <p style={{ textAlign: 'right', color: 'rgba(0, 0, 0, 0.25)' }}>0/150</p>
      </Col>
      <Col span={16}>
        <Form.Item
          name='client_email'
          rules={[
            { type: 'email', message: 'No es un email válido' }
          ]}
        >
          <Input
            type='email'
            placeholder='example@mail.com'
          />
        </Form.Item>
      </Col>
      <Col md={1} lg={0}>
        <></>
      </Col>
      <Col md={7} lg={8}>
        <Button htmlType='submit' className='byp-btn-blue-100 float-right btn-send-email-responsive' loading={isLoadingPost}
          onClick={
            () => {
              notification.info({
                message: 'Email sended',
                description: '',
                placement: 'top'
              })
            }
          }
        >
          Send email
        </Button>
      </Col>
    </Row>
  )
}

export { ClientInfoSection }
