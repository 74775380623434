import React from 'react'
import { PageProtected } from '../../../../common/components/PageProtected'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { TrendsChartInfo } from './TrendsChartInfo'

const URL_TRENDS_MASTER_PAGE = '/dashboard/charts/trends'

const Trends = () => {
  return (
    <PageProtected permissionTypes={[
      userRoles.TYPE_SUPER_ADMIN
    ]}
    >
      <MainTheme
        showMenu
      >
        <TrendsChartInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { Trends, URL_TRENDS_MASTER_PAGE }
