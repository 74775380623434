import { Col, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetOfficeAverage } from '../../../../../../bussines/hooks/agency/analytics/users/useGetAverages'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'
import { SelectBranchesFormItem } from '../../../../../../common/components/helper-form/SelectBranchesFormItem'
import { LanguageIcon, ArrowUpIcons, ArrowDownIcons, LocationIcon, CalendarIcon } from '../../../../../quote-preview/assets/imgs/Icons'

const ProgramOfficeAverage = ({ filters, firstOption = false }) => {
  const [office, setoffice] = useState()
  const { data, isLoading, error } = useGetOfficeAverage({ ...filters, office })

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        // console.log('🚀 ~ file: ProgramOfficeAverage.js:11 ~ useEffect ~ data', data)
      } else if (error) {
        notification.error({
          message: 'Something went wrong'
        })
        // console.log('🚀 ~ file: ProgramOfficeAverage.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, data, error])

  function TextDisplayer ({ text, direccion, range }) {
    if (text) {
      if (range) {
        let i = 0
        while (text.charAt(i) !== ' ') {
          i++
        }
        if (i > 0 && text.substring(0, i) !== 'undefined') {
          return (
            <div className='d-flex justify-center align-center byp-my-1-vh' style={{ gap: '.3vw', whiteSpace: 'nowrap' }}>
              <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '2vw' }}> {text.substring(0, i)} <span className='byp-text-sm quote-overview--color-blue'>{text.substring(i + 1)}</span> </p>
              {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
            </div>
          )
        }
      } else {
        if (text.length < 8) {
          return (
            <div className='d-flex justify-center align-center byp-my-0-5-vh' style={{ gap: '.5vw' }}>
              <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '2vw' }}>{text}</p>
              {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
            </div>
          )
        }
        if (text.length >= 8 && text.length <= 10) {
          return (
            <div className='d-flex justify-center align-center byp-my-1-vh' style={{ gap: '.5vw' }}>
              <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '1.5vw' }}>{text}</p>
              {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
            </div>
          )
        }
        if (text.length >= 10) {
          return (
            <div className='d-flex justify-center align-center byp-my-1-vh' style={{ gap: '.5vw' }}>
              <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '1vw' }}>{text}</p>
              {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
            </div>
          )
        }
      }
    }
  }

  return (
    <>
      <SelectBranchesFormItem
        className='user-analysis__select byp-mb-1'
        placeholder=''
        style={{ width: 120 }}
        size='small'
        onChange={(val) => setoffice(val)}
        firstOption={firstOption}
        value={office}
      />
      {isLoading && <BasicSpinner />}
      {!isLoading && (
        <div className='program-average'>
          <h3 className='byp-color-gray-100 byp-text-0-8 byp-m-0'>Language</h3>
          <Row>
            <Col span={12}>
              <div className='program-average-card active' style={{ textAlign: 'center' }}>
                <LanguageIcon />
                <TextDisplayer text={data?.language?.major?.name} direccion='up' />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.language?.major?.count} quotes</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='program-average-card' style={{ textAlign: 'center' }}>
                <LanguageIcon />
                <TextDisplayer text={data?.language?.minor?.name} direccion='down' />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.language?.minor?.count} quotes</p>
              </div>
            </Col>
          </Row>

          <h3 className='byp-color-gray-100 byp-text-0-8 byp-m-0'>Range time</h3>
          <Row>
            <Col span={12}>
              <div className='program-average-card active' style={{ textAlign: 'center' }}>
                <CalendarIcon />
                <TextDisplayer
                  text={data?.time?.major?.duration ? data?.time?.major?.duration : data?.time?.major?.weeks + ' weeks'}
                  direccion='up'
                  range
                />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.time?.major?.quotes_count} quotes</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='program-average-card' style={{ textAlign: 'center' }}>
                <CalendarIcon />
                <TextDisplayer
                  text={data?.time?.minor?.duration ? data?.time?.minor?.duration : data?.time?.minor?.weeks + ' weeks'}
                  direccion='down'
                  range
                />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.time?.minor?.quotes_count} quotes</p>
              </div>
            </Col>
          </Row>

          <h3 className='byp-color-gray-100 byp-text-0-8 byp-m-0'>City</h3>
          <Row>
            <Col span={12}>
              <div className='program-average-card active' style={{ textAlign: 'center' }}>
                <LocationIcon className='.byp-my-0-5-vh' />
                <TextDisplayer text={data?.city?.major?.name} direccion='up' />
                <p className='byp-m-0 byp-text-sm-vh byp-color-gray-100'>{data?.city?.major?.quotes_count} quotes</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='program-average-card' style={{ textAlign: 'center' }}>
                <LocationIcon />
                <TextDisplayer text={data?.city?.minor?.name} direccion='down' />
                <p className='byp-m-0 byp-text-sm-vh byp-color-gray-100'>{data?.city?.minor?.quotes_count} quotes</p>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default ProgramOfficeAverage
