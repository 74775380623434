const KEY_SESSION = '___KEY_SESSION___'

class SessionService {
  /**
   * CreateSession
   * @param data
   */
  createSession (data) {
    if (data?.token) {
      window.localStorage.setItem(KEY_SESSION, JSON.stringify(data))
    } else {
      this.destroySession()
    }
  }

  /**
   * GetCurrentSession
   * @returns {object}
   */
  getCurrentSession () {
    const retrievedObject = window.localStorage.getItem(KEY_SESSION)
    return retrievedObject !== 'undefined' && JSON.parse(retrievedObject) ? JSON.parse(retrievedObject) : null
  }

  /**
   * ExistSession
   * @returns {boolean}
   */
  existSession () {
    return this.getCurrentSession() !== null
  }

  /**
   * GetAccessToken
   * @returns {string | CancelToken}
   */
  getAccessToken () {
    return this.getCurrentSession()?.token
  }

  /**
   * DestroySession
   */
  destroySession () {
    window.localStorage.removeItem(KEY_SESSION)
  }
}

export default new SessionService()
