import React, { useEffect, useState } from 'react'
import { Col, Row, Statistic, notification } from 'antd'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useGetCurrencyToCurrency } from '../../../bussines/hooks/currencies/useGetCurrencyToCurrency'
// import { useAuth } from '../../../providers/auth/auth-context-provider'

export function QuoteOverviewTotals ({ totals, discounts }) {
  const [currency, setCurrency] = useState('CAD')
  const { userLocalCurrency } = useAuth()
  const { data: newdata, getCurrencyToCurrency, isLoading } = useGetCurrencyToCurrency()

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, currency)
  }, [currency])

  useEffect(() => {
    if (totals) {
      totals?.forEach(total => {
        if (total.total !== '-') {
          setCurrency(total?.divisa)
        }
      })
    }
  }, [totals])

  useEffect(() => {
    if (!isLoading && newdata) {
      console.log('🚀 ~ file: QuoterForm.js:144 ~ useEffect ~ newdata:', newdata)
      if (!newdata.data) {
        notification.error({
          message: 'Exchange Value Not found'
        })
      }
    }
  }, [isLoading, newdata])

  return (
    <>
      <Row justify='end'>
        <Col>
          {totals?.map((total, index) => (
            <React.Fragment key={index}>
              {total.total !== '-' && (
                <div className='quote-overview__prices'>
                  <span className='quote__text-aprox'>Aprox.</span>
                  <Statistic prefix='$' value={`${newdata ? total.total * newdata.data?.value : total.total}`} precision={2} suffix={userLocalCurrency} />
                </div>
              )}
            </React.Fragment>
          ))}
        </Col>
      </Row>

      <Row justify='end' gutter={[8, 8]}>
        <Col>
          {discounts?.map((discount, index) => (
            <React.Fragment key={index}>
              {discount.total !== '-' && (
                <div className='discount__confirmation'>
                  <Statistic prefix='$' value={`${discount.total !== '-' ? discount.total : discount.aprox}`} precision={2} suffix={discount?.divisa} />
                </div>
              )}
            </React.Fragment>
          ))}
        </Col>
        <Col>
          {totals?.map((total, index) => (
            <React.Fragment key={index}>
              {total.total !== '-' && (
                <div className='price__confirmation'>
                  <Statistic prefix='$' value={`${total.total !== '-' ? total.total : total.aprox}`} precision={2} suffix={total?.divisa} />
                </div>
              )}
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </>
  )
}
