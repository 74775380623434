import { Button, Form, notification } from 'antd'
import { useEffect } from 'react'
import stateControl from '../../../../bussines/utils/state-control'
import { useGetExchangeValuesByCurrency } from '../../../../bussines/hooks/currencies/useGetExchangeValuesByCurrency'

const curreciesForAutoExchange = [
  'USD', 'CAD', 'EUR', 'GBP', 'AUD',
  'CHF', 'JPY', 'KRW', 'COP',
  'BRL', 'MXN', 'GTQ', 'PEN'
]

export const ClientSideAutoExhcangeButton = ({ currency, currencyList, setIsLoading, setShowAll }) => {
  const { getExchangeValuesByCurrency, isLoading, data, currentState, error } = useGetExchangeValuesByCurrency()
  const form = Form.useFormInstance()

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: ClientSideAutoExhcangeButton.js:18 ~ useEffect ~ data:', data)
      const valuesData = []
      data.forEach(resp => {
        if (resp.data.result === 'success') {
          valuesData.push({ value: resp.data.conversion_rate, currency: resp.data.base_code })
        }
      })
      console.log('🚀 ~ file: ClientSideAutoExhcangeButton.js:20 ~ useEffect ~ valuesData:', valuesData)
      const listValues = currencyList?.map((listVal) => {
        const found = valuesData.find((val) => {
          console.log('currency: ', val.currency, ' Value: ', val.value)
          return val.currency === listVal.code
        })
        if (found) {
          listVal.exchange_value = found.value?.toFixed(2)
        }
        return listVal
      })
      form.setFieldsValue({
        currencies: listValues
      })
      setShowAll(true)
      notification.success({
        message: 'Exchanges values updated'
      })
    }
    if (!isLoading && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message ?? 'Something went wrong, please try again'
      })
    }
    if (setIsLoading) {
      setIsLoading(isLoading)
    }
  }, [isLoading, data])

  const handleAutoExchange = () => {
    getExchangeValuesByCurrency(currency, curreciesForAutoExchange)
  }
  return (
    <Button
      className='byp-btn-blue-100'
      style={{ float: 'right' }}
      onClick={handleAutoExchange}
      loading={isLoading}
    >
      {isLoading ? 'Please wait...' : 'Auto-Exchange'}
    </Button>
  )
}
