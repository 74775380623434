import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useGetGrades (filters = {}) {
  const queryString = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetGrades', queryString],
    () => CourseRepository.getGrades(queryString), {
      retry: 2
    })
}

export { useGetGrades }
