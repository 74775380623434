import UserRepository from '../../repositories/UserRepository'
import { useQuery } from 'react-query'

function useFetchUser () {
  return useQuery('useFetchUser',
    () => UserRepository.about(), {
      retry: 1
    })
}

export { useFetchUser }
