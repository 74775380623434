import { Form, Select, Statistic, Switch } from 'antd'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { MinorCostsSection } from './MinorCostsSection'
import { RowQuoter } from './RowQuoter'
import { calculateHsCost } from '../utils/calculateCost'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { useEffect } from 'react'
import { formatStringDate } from '../../../utils/formatStringDate'
const { Option } = Select

function FieldCourse () {
  const { course, service, setFormConfig, formConfig, isLoadingOffer, prices } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let courseHSFees = []
    if (!formConfig.courseHSFees) {
      setFormConfig({
        ...formConfig,
        courseHSFees: []
      })
    }
    if (value) {
      courseHSFees = formConfig.courseHSFees ?? []
      courseHSFees?.push({
        id,
        cost,
        frequency
      })
    } else {
      formConfig.courseHSFees?.map((fee) => (
        fee.id !== id ? courseHSFees.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      courseHSFees
    })
  }

  useEffect(() => {
    if (course && !isLoadingOffer) {
      const loadInitialData = async () => {
        if (!formConfig.courseHSFees) {
          setFormConfig({
            ...formConfig,
            courseHSFees: []
          })
        }
        const courseCosts = formConfig.courseHSFees ?? []
        try {
          const hs = service.getHighSeasonFees()
          if (hs) {
            for (const item of hs) {
              for (const fee of item.fees) {
                if (fee.mandatory) {
                  courseCosts.push({
                    id: fee.id,
                    cost: undefined,
                    frequency: undefined
                  })
                }
              }
            }
            setFormConfig(prevFormConfig => ({
              ...prevFormConfig,
              courseHSFees: courseCosts
            }))
          }
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }
      loadInitialData()
    }
  }, [course, isLoadingOffer, prices])

  return (
    <>
      <RowQuoter
        colA={
          <>
            <Form.Item
              label='Course:'
              style={{ marginBottom: 0 }}
            >
              <Select defaultValue={course?.offer?.id} name='course' size='small'>
                <Option key={course?.offer?.id} value={course?.offer?.id}>{course?.offer?.name}</Option>
              </Select>
            </Form.Item>
            {service.courseHasDiscount() && service.getCourseDiscounts()?.map(
              d => (
                <p key={d.id} className='byp-fw-bold'>
                  {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                </p>
              )
            )}
            {service.getDiscountsInKindGoods()?.map( // Discounts pf type Other (In kind)
              d => (
                <p key={d.id} className='byp-fw-bold'>
                  {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                </p>
              )
            )}
          </>
        }
        colB={
          <>
            <Statistic
              prefix=''
              precision={2}
              value={`${service.getCourseAmount()}`}
              suffix={`${service.getCurrency()}`}
              className={service.courseHasDiscount() ? 'price-before' : null}
            />
            {service.courseHasDiscount() && (// Discounts
              <Statistic
                prefix=''
                className='price-after'
                precision={2}
                value={`${service.getCourseAmountWithDiscount()}`}
                suffix={`${service.getCurrency()}`}
              />
            )}
          </>
        }
      />
      {service.getHighSeasonFees().map((hs) => ( // HS Fees
        hs.fees.map((fee) => (
          <RowQuoter
            key={fee.id}
            colA={
              <p>
                {fee.name + ' ' + hs.weeks + ' Weeks'} {hs.daysOff > 0 && (' plus ' + hs.daysOff + ' days')}
                {!fee?.mandatory && (
                  <Switch
                    onChange={(value) => { handleChange(value, fee.id) }}
                    className='byp-ml-1'
                    checked={fee.checked}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                  />
                )}
              </p>
            }
            colB={
              <StatisticWithDiscounts
                discounts={[]}
                amount={
                  service.isHighSeasonFeeChecked(fee.id)
                    ? calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)
                    : 0
                }
                currency={service.getCurrency()}
                subtotal={
                  service.isHighSeasonFeeChecked(fee.id)
                    ? calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)
                    : 0
                }
              />
            }
          />
        ))
      ))}
      <MinorCostsSection />
    </>
  )
}

export { FieldCourse }
