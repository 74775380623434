import { useQuery } from 'react-query'
import { PlaceRepository } from '../../repositories/PlaceRepository'

function useGetAllCitiesByIso (iso, filters = {}) {
  return useQuery(['useGetAllCitiesByIso', iso, filters],
    () => PlaceRepository.allCitiesByIso(iso, filters), {
      retry: 2,
      disabled: !!iso || iso === null
    })
}

export { useGetAllCitiesByIso }
