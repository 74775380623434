import { useQuery } from 'react-query'
import UserRepository from '../../../../repositories/UserRepository'

function useGetInstitutionByProgram (program) {
  return useQuery(['useGetInstitutionByProgram', program], () => UserRepository.getInstitutionByProgram(program), {
    enabled: !!program,
    retry: 1
  })
}

export { useGetInstitutionByProgram }
