import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { OfficeAnaliticsInfo } from './OfficeAnaliticsInfo'

const URL_OFFICE_ANALITCIS_PAGE = '/dashboard/agency/analitics/office'

function OfficeAnaliticsPage () {
  return (
    <PageProtected permissionTypes={[
      userRoles.TYPE_AGENCY,
      userRoles.TYPE_AGENCY_OFFICE_MANAGER,
      userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
      userRoles.TYPE_AGENCY_EDITOR
      // userRoles.TYPE_INSTITUTION
    ]}
    >
      <MainTheme
        showMenu
      >
        <OfficeAnaliticsInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { OfficeAnaliticsPage, URL_OFFICE_ANALITCIS_PAGE }
