import React, { useEffect, useState } from 'react'
import { Avatar, Col, Form, Input, Layout, PageHeader, Row, Typography } from 'antd'
import { QuoterForm } from './components/QuoterForm'
import { usePackage } from '../../providers/package/package-course-context'
import '../../common/assets/css/switch-theme.less'
// import { QuoterHighEducationForm } from './components/QuoterHighEducationForm'
// import { ProgramIntake } from './components/ProgramIntake'
import moment from 'moment'

const { Content } = Layout
const { Paragraph } = Typography
// const { Option } = Select

export default function FormContent ({ setHasPermision }) {
  const { course, offerError, isOfferError, form } = usePackage()
  const [, setShowLangQuoter] = useState()
  const [lenght, setLenght] = useState(0)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (offerError && isOfferError) {
      console.log('🚀 ~ file: index.js ~ line 94 ~ useEffect ~ offerError', offerError)
    }
  }, [offerError, isOfferError])

  useEffect(() => {
    const program = course?.offer?.program?.name
    if (program === 'language' || program === 'summer_camps') {
      setShowLangQuoter('yes')
    } else {
      setShowLangQuoter('no')
    }

    if (!course?.is_active) {
      setHasPermision(false)
    }

    if (course?.offer?.start_at && course?.offer?.end_at) {
      const lenghtDate = new Date(course?.offer?.end_at) - new Date(course?.offer?.start_at)
      if (!course?.offer?.min_weeks && !course?.offer?.max_weeks) {
        const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
        setLenght(differenceWeeks + ' weeks')
        form.setFieldsValue({
          start_at: handleFormatDate(course?.offer?.start_at),
          end_at: handleFormatDate(course?.offer?.end_at),
          weeks: differenceWeeks
        })
        // setFormConfig({
        //   ...formConfig,
        //   weeks: differenceWeeks,
        //   courseStart: course?.offer?.start_at,
        //   courseEnd: course?.offer?.end_at
        // })
        // console.log('🚀 ~ file: FormContent.js:52 ~ useEffect ~ course?.offer?.start_at:', course?.offer?.start_at)
      }
    }

    if (!course?.offer?.min_weeks && !course?.offer?.max_weeks) {
      setIsOpen(false)
    }
  }, [course])

  if (offerError && isOfferError) {
    return 'ups'
  }

  const handleFormatDate = (date) => {
    if (date) {
      const inputDate = moment(date, 'YYYY-MM-DD')?.locale('en')
      const outputDateStr = inputDate?.format('ddd, D MMM YYYY')

      return outputDateStr
    }
  }

  return (
    <PageHeader
      className='antd-card content-quoter'
      ghost={false}
      title={
        <Row>
          <Col className='byp-justify-centered'>
            <Avatar
              style={{ maxWidth: '4.5rem', maxHeight: '4.5rem' }}
              src={course?.offer?.campus?.profilePhotoUrl}
              icon={<img src={course?.offer?.campus?.parent?.profile_photo_url} alt='' />}
            />
          </Col>
          <Col className='byp-justify-centered'>
            <h4 className='byp-title byp-m-0 quote-course--title__responsive'>{course?.offer?.campus?.name}</h4>
            {course?.offer?.type_course === 'short' && (
              <p className='byp-m-0 byp-text-sm' style={{ lineHeight: '12px' }}>by {course?.offer?.campus?.parent?.name}</p>
            )}
            {course?.offer?.type_course === 'package' && (
              <p className='byp-m-0 byp-text-sm' style={{ lineHeight: '12px' }}>by {course?.offer?.agency?.name}</p>
            )}
          </Col>
        </Row>
      }
      extra={[
        <small key='3'>{course?.offer?.campus?.address}</small>
      ]}
      footer={
        <>
          {course?.offer?.program?.name !== 'language' && (
            <Paragraph style={{ paddingBottom: '2rem' }}>
              <i className='byp-fw-bold'>
                Note: These prices and start dates are only for refererence. The final price is when you get the letter of acceptance.<br />
                To apply and be accepted you must have all the requirements from the institution.<br />
                Places are subject to change to college availability. The cost is only for the first year or first semester program<br />
                *The official hosting date is a reference and the definitive date will be when the start date is known.
              </i>
            </Paragraph>
          )}
        </>
      }
    >
      <h3 className='byp-title byp-text-md'>{course?.offer?.campus?.group_name}</h3>
      <h3 className='byp-title'>{course?.offer?.name}</h3>
      {
      !isOpen && (
        <>
          <Row justify='space-between'>
            <Col>
              <p>Program type: <span style={{ fontWeight: '700' }}>{course?.offer?.program?.label}</span></p>
            </Col>
            <Col>
              <p><span>Length: </span><b>{lenght}</b></p>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col lg={11} xl={8}>
              <Form.Item
                label='Start Date'
                labelAlign='left'
                labelCol={{ span: 7 }}
                name='start_at'
              >
                <Input size='small' className='package-date' style={{ width: '100%' }} disabled />
              </Form.Item>
            </Col>

            <Col lg={11} xl={8}>
              <Form.Item
                label='End Date'
                labelAlign='left'
                labelCol={{ span: 7 }}
                name='end_at'
              >
                <Input size='small' className='package-date' style={{ width: '100%' }} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <i>Closed dates for special group</i>
          </Row>
        </>
      )
      }

      {/* {course?.offer?.program?.name === 'graduate' && (
        <Row className='high-info-quote'>
          <Col span={12}>
            {course?.offer?.code && (<span>Code: <b>{course?.offer?.code}</b></span>)}
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p style={{ marginBottom: '.4rem' }}>Length: <b>{service?.courseDuration()}</b></p>
            <p>Type of Institution: <b>{course?.offer?.campus.type}</b></p>
          </Col>
        </Row>
      )}
      {course?.offer?.program?.name === 'graduate' && (
        <Row className='high-info-quote-tablet'>
          <Col span={12}>
            {course?.offer?.code && (<span>Code: <b>{course?.offer?.code}</b></span>)}
            <ProgramIntake />
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p>Type of Institution: <b>{course?.offer?.campus.type}</b></p>
            <p style={{ marginBottom: '.4rem' }}>Program Length: <b>{service?.courseDuration()}</b></p>
          </Col>
        </Row>
      )}
      {(course?.offer?.program?.name === 'academic_years' || course?.offer?.program?.name === 'boarding_schools') && (
        <Row>
          <Col span={12}>
            <p>Grades: <span className='byp-fw-bold'>{course?.offer?.campus?.grades?.join('-')}</span></p>
            <p>Course type: <span className='byp-fw-bold'>{course?.offer?.type}</span></p>
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p>Lenght: <span className='byp-fw-bold'>{course?.offer?.length} {course?.offer?.time}</span></p>
            <p>Type of Institution: <span className='byp-fw-bold'>{course?.offer?.campus?.type}</span></p>
          </Col>
        </Row>
      )} */}
      <br />
      <Content>
        <QuoterForm isOpen={isOpen} />
        {/* {!isLoadingOffer && showLangQuoter === 'yes' && <QuoterForm isOpen={isOpen} />} */}
        {/* {!isLoadingOffer && showLangQuoter === 'no' && <QuoterHighEducationForm />} */}
      </Content>
    </PageHeader>
  )
}
