import { Button, Col, Form, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
// import { SelectCountriesFormItem } from '../../../../common/components/helper-form/SelectCountriesFormItem'
import { SelectCityByIsoFormItem } from '../../../../common/components/helper-form/SelectCityByIsoFormItem'
import { useGetAllCompaniesNames } from '../../../../bussines/hooks/admin/useGetAllCompanies'
import { useGetAllNationalities } from '../../../../bussines/hooks/quoter/useGetAllNationalities'

const { Option } = Select

const AllUsersFilters = ({ searchFilter, setSearchFilter, setPage, type, company, setFormSearch, setSearchActive, searchActive }) => {
  const [form] = Form.useForm()
  const [nSearchFilter, setNSearchFilter] = useState(searchFilter)
  const [optionsType, setOptionsType] = useState([])
  const [countries, setCountries] = useState([])
  const [mainEmails, setMianEmails] = useState([])
  const { data: names, isLoading } = useGetAllCompaniesNames({ ...searchFilter })

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...nSearchFilter,
      [key]: value
    }
    setNSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...nSearchFilter }
    delete nFilters[key]
    setNSearchFilter(nFilters)
  }

  useEffect(() => {
    setFormSearch(form)
  }, [form])

  useEffect(() => {
    form.resetFields()
  }, [company])

  useEffect(() => {
    form.resetFields()
    setSearchActive(false)
  }, [type])

  const handleSubmit = (data) => {
    if (searchFilter?.company) {
      setSearchFilter({ page: 1, company_type: type, company: searchFilter?.company, ...data })
    } else {
      setSearchFilter({ page: 1, company_type: type, ...data })
    }

    setSearchActive(true)
  }

  const handleClear = () => {
    setNSearchFilter({})
    // setSearchFilter({})
    form.resetFields()

    if (searchFilter?.company) {
      setSearchFilter({ page: 1, company_type: type, company: searchFilter?.company })
    } else {
      setSearchFilter({ page: 1, company_type: type })
    }
    // setPage(1)
    setSearchActive(false)
  }

  useEffect(() => {
    setOptionsType(names?.data)

    let countriesData = []
    names?.data?.forEach(item => {
      countriesData.push(...item?.country)
    })
    countriesData = countriesData.filter((element) => element !== null)
    const uniqueArray = countriesData.reduce((accumulator, current) => {
      const existingItem = accumulator.find(item => item?.id === current?.id)
      if (!existingItem) {
        accumulator.push(current)
      }
      return accumulator
    }, [])
    setCountries(uniqueArray)
    if (!searchFilter?.company) {
      const filteredObjects = names?.data?.filter(obj => obj?.main_contact)
      setMianEmails(filteredObjects)
    } else {
      const filteredObjects = names?.data?.filter(obj => obj?.email)
      setMianEmails(filteredObjects)
    }
  }, [names])

  const { data: cities } = useGetAllNationalities()
  const [iso3, setIso3] = useState(null)

  async function findIso (id) {
    const found = cities?.find((city) => {
      return city.id === id
    })
    if (found) {
      return setIso3(found?.iso3)
    }
  }

  useEffect(() => {
    if (nSearchFilter?.country) findIso(nSearchFilter?.country)
  }, [nSearchFilter, cities])

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={6} className='w-100 content-quoter'>
        {/* <Col xs={24} md={6} lg={4}>
          <Form.Item
            name='company_type'
            label='Type'
          >
            <Select
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('company_type', e)
                  : handleRemoveFilter('company_type')
              }}
              placeholder='All'
              className='w-100'
            >
              <Option key={0} value={null}>All</Option>
              <Option key={1} value='Institution'>Institution</Option>
              <Option key={2} value='Agency'>Agency</Option>
              <Option key={3} value='Education group'>Education group</Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Col xs={24} md={6} lg={3}>
          <Form.Item
            name='country'
            label='Country'
          >
            <Select
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('country', e)
                  : handleRemoveFilter('country')
              }}
              placeholder='All'
              className='w-100'
            >
              <Option key={0} value={null}>All</Option>
              {countries.map(country => <Option key={country.id} value={country.id}>{country.name}</Option>)}
            </Select>
          </Form.Item>
          {/* <SelectCountriesFormItem
            name='country'
            size='small'
            label='Country'
            placeholder='All'
            all
            onChange={(e) => {
              e
                ? handleAddFilter('country', e)
                : handleRemoveFilter('country')
            }}
          /> */}
        </Col>
        <Col xs={24} md={6} lg={3}>
          <SelectCityByIsoFormItem
            name='city'
            size='small'
            label='City'
            placeholder='All'
            showAll
            onChange={(e) => {
              e
                ? handleAddFilter('city', e)
                : handleRemoveFilter('city')
            }}
            iso={iso3}
            showSearch={false}
          />
        </Col>
        <Col xs={24} md={6} lg={6}>
          <Form.Item
            name='name'
            label={`${type}`}
          >
            <Select
              showSearch
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('name', e)
                  : handleRemoveFilter('name')

                console.log(e)
              }}
              placeholder='All'
              className='w-100'
              loading={isLoading}
            >
              <Option key={0} value={null}>All</Option>
              {optionsType?.length > 0 && optionsType?.map(name => <Option key={name?.id + name?.id} value={name?.name}>{name?.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={6}>
          <Form.Item
            name='email'
            label='email'
          >
            <Select
              showSearch
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('email', e)
                  : handleRemoveFilter('email')

                console.log(e)
              }}
              placeholder='All'
              className='w-100'
              loading={isLoading}
            >
              <Option key={0} value={null}>All</Option>
              {(mainEmails?.length > 0 && !searchFilter?.company) && mainEmails?.map(name => <Option key={name?.id + name?.id} value={name?.main_contact}>{name?.main_contact}</Option>)}
              {(mainEmails?.length > 0 && searchFilter?.company) && mainEmails?.map(name => <Option key={name?.id + name?.id} value={name?.email}>{name?.email}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={3}>
          <Form.Item
            name='status_type'
            label='Status'
          >
            <Select
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('status_type', e)
                  : handleRemoveFilter('status_type')
              }}
              placeholder='All'
              className='w-100'
            >
              <Option key={0} value={null}>All</Option>
              <Option key={1} value='Active'>Validated</Option>
              <Option key={2} value='Pending'>Pending</Option>
              <Option key={3} value='Suspended'>Suspended</Option>
              {/* <Option key={3} value='Deleted'>Deleted</Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={3} className='d-flex' style={{ gap: '0.5rem' }}>
          <Button
            htmlType='submit'
            className='byp-btn-blue-100'
          >
            Search
          </Button>
          {searchActive &&
            <Button
              onClick={handleClear}
              className='byp-bg-gray50 byp-color-gray-100 byp-fw-bold'
            >
              Clear
            </Button>}
        </Col>
      </Row>
    </Form>
  )
}

export { AllUsersFilters }
