import { useQuery } from 'react-query'
import UserRepository from '../../../../repositories/UserRepository'

function useGetQuotesStatisticsByOffice (office, filters = {}) {
  const allFilters = { office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(['useGetQuotesStatisticsByOffice', office, filtersQuery], () => UserRepository.quotesStatistics(filtersQuery), {
    enabled: !!office,
    retry: 1
  })
}

export { useGetQuotesStatisticsByOffice }
