import { Statistic, Table } from 'antd'
const { Column } = Table
function QuoteOverviewMedicalAgencyCosts ({ isLoading, data, divisa, userData, exchangeVal, getExchangeVal, agentData }) {
  const getValue = (value) => {
    if (value === null) return ''
    return !isNaN(value) ? `$${value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : value
  }

  return (
    <Table
      loading={isLoading}
      dataSource={data ?? []}
      className='byp-table-clear quote-overview-table'
      showHeader={false}
      rowKey='name'
      pagination={{
        hideOnSinglePage: true
      }}
    >
      <Column
        title='Concept' dataIndex='name' key='name'
        render={(_, record) => (
          record.cost < 0 ? <span style={{ color: '#686868', fontWeight: 'bold' }}>{record.name}</span> : <span>{record.name}</span>
        )}
      />
      <Column
        title={`Total ${data[0].divisaAgency}`} dataindex='' key=''
        render={({ cost, divisa }) => (
          <>
            <span className='data-label'>Total {data[0].divisaAgency}</span>
            <Statistic value={getValue(cost)} precision={2} />
          </>
        )}
      />
    </Table>
  )
}

export { QuoteOverviewMedicalAgencyCosts }
