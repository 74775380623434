import * as React from "react"

export const SchoolProfileIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.813 4.813H8.75V1.75A1.312 1.312 0 007.437.437h-5.25A1.312 1.312 0 00.875 1.75v11.375a.438.438 0 00.438.438h2.843a.218.218 0 00.219-.22v-1.956c0-.235.181-.437.416-.449a.438.438 0 01.459.437v1.969a.219.219 0 00.219.219h7.218a.438.438 0 00.438-.438v-7a1.313 1.313 0 00-1.313-1.313zm-9.131 6.996a.437.437 0 11-.114-.866.437.437 0 01.114.866zm0-2.188a.437.437 0 11-.114-.867.437.437 0 01.114.867zm0-2.187a.437.437 0 11-.114-.868.437.437 0 01.114.868zm0-2.188a.437.437 0 11-.114-.867.437.437 0 01.114.867zm0-2.187a.437.437 0 11-.114-.868.437.437 0 01.114.868zm2.187 6.562a.437.437 0 11-.113-.867.437.437 0 01.113.867zm0-2.187a.437.437 0 11-.113-.868.437.437 0 01.113.868zm0-2.188a.437.437 0 11-.113-.867.437.437 0 01.113.867zm0-2.187a.437.437 0 11-.113-.868.437.437 0 01.113.868zm2.188 8.75a.437.437 0 11-.114-.866.437.437 0 01.114.866zm0-2.188a.437.437 0 11-.114-.867.437.437 0 01.114.867zm0-2.187a.437.437 0 11-.114-.868.437.437 0 01.114.868zm0-2.188a.437.437 0 11-.114-.867.437.437 0 01.114.867zm0-2.187a.437.437 0 11-.114-.868.437.437 0 01.114.868zm5.084 9.629H8.75v-7h3.063a.438.438 0 01.437.437v6.453a.11.11 0 01-.11.11z"
        fill="currentColor"
      />
      <path
        d="M10.938 10.938a.438.438 0 100 .876.438.438 0 000-.877zm0-2.188a.438.438 0 100 .876.438.438 0 000-.876zm0-2.188a.438.438 0 100 .876.438.438 0 000-.875zm-1.75 4.375a.438.438 0 100 .877.438.438 0 000-.877zm0-2.187a.437.437 0 100 .875.437.437 0 000-.875zm0-2.188a.437.437 0 100 .875.437.437 0 000-.875z"
        fill="currentColor"
      />
    </svg>
  )
}