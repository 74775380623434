import SessionService from '../services/SessionService'

const headersForUpload = () => {
  const btoken = SessionService.getAccessToken()
  return {
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${btoken}`
  }
}

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

function isJpgOrPng (file) {
  return file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
}

function isPdfOrDoc (file) {
  return (
    file.type === 'application/pdf' ||
    file.type === 'application/msword' ||
    file.type === 'application/vnd.openxmlformatsofficedocument.wordprocessingml.document' ||
    file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  )
}

function isLt2M (file) {
  return file.size / 1024 / 1024 < 2
}

export {
  getBase64,
  isJpgOrPng,
  isPdfOrDoc,
  isLt2M,
  headersForUpload
}
