import * as React from "react"

export const DeleteIcon = (props) => {
  return (
    <svg
      width={18}
      height={20}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.036 3.714h3.928a1.964 1.964 0 10-3.928 0zm-1.179 0a3.143 3.143 0 116.286 0h4.91a.59.59 0 110 1.179h-1.029l-.956 11.474a3.34 3.34 0 01-3.327 3.062H6.259a3.34 3.34 0 01-3.327-3.062L1.976 4.893H.946a.59.59 0 010-1.179h4.911zm1.964 4.518a.59.59 0 10-1.178 0v6.679a.59.59 0 101.178 0V8.232zm2.947-.59a.59.59 0 00-.59.59v6.679a.59.59 0 001.18 0V8.232a.59.59 0 00-.59-.59z"
        fill="#E42A36"
      />
    </svg>
  )
}
