import { Row, Form, Col, Button, Image } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { MinusCircleTwoTone } from '@ant-design/icons'
import { useEffect } from 'react'
import { UploadSliderFormItem } from './UploadSliderFormItem'
import { API_URL } from '../../../../config'

/*
  slider: {file, descritopn}

  listName: sliders
  name: x
*/
const imgContainer = {
  backgroundColor: 'white',
  height: '100px',
  margin: '5px',
  overflow: 'hidden',
  borderRadious: '.25rem'
}

function SchoolsCarrousel ({ listName, name, form, remove, setId, setGallery, gallery, setUrl, url }) {
  const thisList = Form.useWatch(listName, form)

  const handleRemove = () => {
    remove(name)
    setGallery(gallery.splice(name, 1))
    setUrl(prevUrl => {
      const newUrl = [...prevUrl]
      newUrl.splice(name, 1)
      return newUrl
    })
  }

  useEffect(() => {
    if (url[name]) {
      const updatedGallery = [...gallery]
      updatedGallery[name] = {
        image: url && url[name] ? url[name] : '',
        text_en: thisList && thisList[name]?.description ? thisList[name]?.description : ''
      }
      setGallery(prevGallery => {
        return [
          ...prevGallery.slice(0, name),
          {
            image: url && url[name] ? url[name] : '',
            text_en: thisList && thisList[name]?.description ? thisList[name]?.description : ''
          },
          ...prevGallery.slice(name + 1)
        ]
      })
    }
  }, [url, thisList])

  return (
    <div style={{ padding: '10px 0' }}>
      <div style={{ border: 'solid', borderColor: 'gray', borderWidth: '2px', borderRadius: '10px' }}>
        <Row style={imgContainer}>
          <Image
            src={url[name]}
          />
        </Row>
        <div style={{ border: 'solid', borderColor: '#D4D4D4', borderWidth: '3px', borderRadius: '10px', padding: '0 10px', paddingTop: '10px', margin: '5px' }}>
          <Col span={24} style={{ padding: 0 }}>
            <UploadSliderFormItem
              action={`${API_URL}/campus/upload/files`}
              maxCount={10}
              name={name}
              setUrl={setUrl}
              setId={setId}
              url={url}
            />
          </Col>

          <Row>
            <Col span={22}>
              <Form.Item
                label='Slide content'
                name={[name, 'description']}
              >
                <TextArea rows={4} maxLength={100} showCount />
              </Form.Item>
            </Col>

            <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} span={2}>
              <Button
                style={{ backgroundColor: '#F7F7F7', borderColor: '#F7F7F7' }}
                icon={<MinusCircleTwoTone twoToneColor='gray' />}
                size='large'
                onClick={handleRemove}
              />
            </Col>
          </Row>

        </div>
      </div>
    </div>
  )
}

export { SchoolsCarrousel }
