import { Button, Col, Form, Row, Select } from "antd"
import { useAuth } from "../../../providers/auth/auth-context-provider"
import { useEffect, useState } from "react"
import { useGetDiscounts } from "../../../bussines/hooks/school/discounts/useGetDiscounts"
import { Option } from "antd/lib/mentions"
import { TableDiscountsPackages } from "./TableDiscountsPackages"


function FiltersDiscountsPackages() {
    const { user: _user } = useAuth()
    const [searchFilter, setSearchFilter] = useState({})
    const [offices, setOffices] = useState()
    const [form] = Form.useForm();

    useEffect(() => {
        if (_user) {
            if (_user?.type !== 'Agency') {
                setSearchFilter({
                    ...searchFilter,
                    program: 'short_term',
                    // offices:''
                })
            }
        }
    }, [_user])

    const {
        data: discounts,
        isLoading,
        refetch
    } = useGetDiscounts({ type: 'table', ...searchFilter })

    console.log('esto es discounts')
    console.log(discounts)

    useEffect(() => {
        if (!isLoading && discounts) {
            const newOffices = []


            if (_user) {
                if (_user?.type !== 'Agency') {
                    discounts?.forEach(discount => {
                        discount?.sedes?.forEach(item => {
                            newOffices.push(item)
                        })
                    })
                }
                else {
                    discounts?.forEach(discount => {
                        discount?.branches?.forEach(item => {
                            newOffices.push(item)
                        })
                    })
                }
            }

            if (newOffices?.length > 0) {
                const uniqueArray = newOffices.filter((item, index) => {
                    // Devuelve true solo si el índice del primer elemento con el mismo "id" es igual al índice actual.
                    return index === newOffices.findIndex((obj) => obj.id === item.id)
                })
                setOffices(uniqueArray)
            } else {
                setOffices(newOffices)
            }
        }
    }, [isLoading, discounts])

    const onFinish = (values) => {
        var keys = {}
        if (values.sedes) {
            if (_user?.type !== 'Agency') {
                keys = { 'campus': values.sedes }
            }
            else {
                keys = { 'office': values.sedes }
            }
        }
        if (values.status) {
            keys = { ...keys, 'status': values.status }
        }
        if (values.name) {
            discounts.find((item, index) => {
                if (item.id == values.name) {
                    keys = { ...keys, 'search': discounts[index].name }
                }
            })

        }
        if (_user) {
            if (_user?.type !== 'Agency') {
                keys = { ...keys, program: 'short_term' }
            }
        }
        setSearchFilter(keys)
    };

    const onReset = () => {
        if (_user) {
            if (_user?.type !== 'Agency') {
                setSearchFilter({
                    program: 'short_term',
                    // offices:''
                })
            }
            else {
                setSearchFilter({})
            }
        }
        form.resetFields();
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
        >
            <Row gutter={20}>
                <Col span={7} >
                    <Form.Item
                        colon={false}
                        label='Quote'
                        name='name'
                    >
                        <Select
                            size='small'

                        >
                            {discounts?.map((item) =>
                                <Option value={item.id}>
                                    {item.name}
                                </Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        label='Office'
                        colon={false}
                        name='sedes'
                    >
                        <Select
                            size='small'>
                            {offices?.map((item) =>
                                <Option value={item.id}>
                                    {item.name}
                                </Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label='Status'
                        colon={false}
                        name='status'
                    >
                        <Select
                            size='small'>
                            <Option key={1} value='Active'>Active</Option>
                            <Option key={2} value='Inactive'>Inactive</Option>

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Button className="byp-btn-gray" onClick={onReset}
                            >Clear</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType="submit">Search</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <TableDiscountsPackages
                        discounts={discounts}
                        isLoading={isLoading}
                        refetch={refetch}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export { FiltersDiscountsPackages }