import { Statistic, Typography } from 'antd'
import React from 'react'
import { usePackage } from '../../../providers/package/package-course-context'
import { RowQuoter } from './RowQuoter'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

const { Text } = Typography

function PackagePrice () {
  const { service } = usePackage()

  return (
    <>
      <RowQuoter
        colA={
          <>
            {service.getPrices() && (
              service.getPrices().costs?.map((d) => (
                <Text key={d.id}>{d.name}</Text>
              ))
            )}
            {service.courseHasDiscount() && service.getCourseDiscounts()?.map(
              d => (
                <p key={d.id} className='byp-fw-bold'>
                  {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                </p>
              )
            )}
            {/* {service.getDiscountsInKindGoods()?.map( // Discounts pf type Other (In kind)
              d => {
                const discount = d.discount ?? {}
                const endDate = new Date(discount.end)
                return (
                  <p key={d.id} className='byp-fw-bold'>
                    {d.name} discount, until {format(endDate, 'LLL dd yyyy')}
                  </p>
                )
              }
            )} */}
          </>
        }
        colB={
          <>
            {service.getPrices() && (
              service.getPrices().costs?.map((d) => (
                <div key={d.id}>
                  <Statistic
                    prefix=''
                    precision={2}
                    value={d.cost}
                    suffix={`${service.getCurrency()}`}
                  />
                </div>
              ))
            )}
            {service.courseHasDiscount() && (// Discounts
              <Statistic
                prefix=''
                className='price-after'
                precision={2}
                value={`${service.getCourseAmountWithDiscount()}`}
                suffix={`${service.getCurrency()}`}
              />
            )}
          </>
        }
      />
    </>
  )
}

export { PackagePrice }
