import React from 'react'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { UserAnalysisInfo } from './UserAnalysisInfo'
import { useLocation } from 'react-router-dom'

const URL_USER_ANALYSIS_PAGE = '/dashboard/analitics/user'

function UserAnalysisPage () {
  const { state } = useLocation()

  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_SUPER_ADMIN,
        userRoles.TYPE_SUPER_EDITOR,
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
        userRoles.TYPE_AGENCY_EDITOR,
        userRoles.TYPE_INSTITUTION,
        userRoles.TYPE_EG_ADMIN
      ]}
      isInBetaState
    >
      <MainTheme
        showMenu
      >
        <UserAnalysisInfo idUser={state?.idUser} nameRole={state?.nameRole} idSede={state?.idSede} stateSede={state?.sede} />
      </MainTheme>
    </PageProtected>
  )
}

export { UserAnalysisPage, URL_USER_ANALYSIS_PAGE }
