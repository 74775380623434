import { Typography } from 'antd'
import React from 'react'
const { Paragraph, Title } = Typography

const DiscountFeeValuesView = ({ data, showJson, ...props }) => {
  if (showJson) {
    return (
      <pre style={{ maxHeight: '40vh', overflow: 'auto' }}>
        {JSON.stringify(data ?? {}, null, 2)}
      </pre>
    )
  }
  return (
    <div {...props}>
      <Title level={5}>Name: {data?.name}</Title>
      <p><span className='w-100'>Discount: {data?.discount} {data?.discount_type} {data?.discount ? null : 'None'} </span></p>
      {data?.search_discount && (
        <span>Accommodation Fee: {data?.search_discount} {data?.search_discount_type} </span>
      )}
      <Paragraph ellipsis={{ rows: 3, expandable: false }}>
        {data?.discount_description}
      </Paragraph>
    </div>
  )
}

export { DiscountFeeValuesView }
