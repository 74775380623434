import { Col, Form, Input, Row, Select } from 'antd'
import { useMemo } from 'react'
import debounce from 'lodash/debounce'

const { Search } = Input
const { Option } = Select

const PackageTableFilter = ({ searchFilter, setSearchFilter, offices, programs }) => {
  const [form] = Form.useForm()

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  return (
    <Form form={form}>
      <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
        <Col xs={24} md={12} lg={8}>
          <Form.Item name='search'>
            <Search
              className='byp-input-gray'
              placeholder='Search'
              onChange={(event) => debounceFilter('search', event.target.value)}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={8}>
          <Form.Item name='offices'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('offices', e)
                  : handleRemoveFilter('offices')
              }}
              placeholder='All offices'
              className='w-100'
              allowClear
            >
              <Option key={0} value={null}>All offices</Option>
              {offices?.map((item) => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={8}>
          <Form.Item name='programs'>
            <Select
              style={{ width: '70%', marginLeft: '5%' }}
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('programs', e)
                  : handleRemoveFilter('programs')
              }}
              placeholder='All programs'
              className='w-100'
            >
              <Option key={0} value={null}>All programs</Option>
              {programs?.map((item) => (
                <Option key={item.id} value={item.id}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

      </Row>
    </Form>
  )
}

export { PackageTableFilter }
