import { Form, Select } from 'antd'
const { Option } = Select
function SelectStudentLocation ({ name, ...props }) {
  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select {...props}>
        <Option key={0} value='Both'>Both</Option>
        <Option key={1} value='Offshore'>Offshore</Option>
        <Option key={2} value='Onshore'>Onshore</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectStudentLocation }
