import * as React from "react"

export function LogoSchool ({props, color}) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#F7F7F7" d="M0 0H16V16H0z" />
      <path
        d="M13.5 5.5H10V2A1.5 1.5 0 008.5.5h-6A1.5 1.5 0 001 2v13a.5.5 0 00.5.5h3.25a.25.25 0 00.25-.25v-2.236c0-.269.207-.5.476-.513A.502.502 0 016 13v2.25a.25.25 0 00.25.25h8.25a.5.5 0 00.5-.5V7a1.5 1.5 0 00-1.5-1.5zM3.065 13.496a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm2.5 7.5a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm2.5 10a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm5.81 11.004H10v-8h3.5a.5.5 0 01.5.5v7.375a.125.125 0 01-.125.125z"
        fill={color??"#2D2F66"}
      />
      <path
        d="M12.5 12.5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1zm-2 5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1z"
        fill={color??"#2D2F66"}
      />
    </svg>
  )
}

