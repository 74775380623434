import { useQuery } from 'react-query'
import InsuranceRepository from '../../../repositories/InsuranceRepository'

function useGetAllInsurance (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetAllInsurance', filtersQuery],
    () => InsuranceRepository.insuranceList(filtersQuery),
    { retry: 2 }
  )
}

export { useGetAllInsurance }
