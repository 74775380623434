import { Checkbox, Modal, notification } from 'antd'
import { usePutAutoExchangeConfigUpdate } from '../../../../bussines/hooks/currencies/usePutAutoExchangeConfigUpdate'
import stateControl from '../../../../bussines/utils/state-control'
import { useEffect, useState } from 'react'

const CheckboxAutoExchange = ({ status = false, refetch, readOnly, currency, loading }) => {
  const [modal, context] = Modal.useModal()
  const { activeDailyAutoExchange, isLoading, currentState } = usePutAutoExchangeConfigUpdate()
  const [isChecked, setIsChecked] = useState(status)

  const handleOnChange = (val) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    let message = 'By checking on the automatically option, the Autoexchange function will now be applied everyday automatically until the option is no longer checked'
    if (!val?.target?.checked) {
      message = 'The Autoexchange function will now be desactivated'
    }
    modal.confirm({
      content: message,
      onOk () {
        setIsChecked(val?.target?.checked)
        activeDailyAutoExchange({ value: val?.target?.checked, currency })
        // console.log('🚀 ~ file: CheckboxAutoExchange.js:21 ~ onOk ~ val?.target?.checked:', val?.target?.checked)
      },
      onCancel () {
        // setIsChecked(!val?.target?.checked)
      }
    })
  }

  useEffect(() => {
    if (currentState === stateControl.STATE_SUCCESS && !isLoading) {
      notification.success({
        message: 'Data saved succesfully'
      })
      if (refetch) {
        refetch()
      }
    }
  }, [isLoading, currentState])

  useEffect(() => {
    setIsChecked(status)
  }, [status])

  return (
    <>
      {context}
      <Checkbox
        style={{ float: 'right' }}
        className='byp-ml-1'
        onChange={handleOnChange}
        checked={isChecked}
        disabled={isLoading || loading}
      > {(isLoading || loading) ? 'Please wait...' : 'Automatic'}
      </Checkbox>
    </>
  )
}

export { CheckboxAutoExchange }
