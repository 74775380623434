import { Button, Form, Input, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import background from '../login/assets/images/byp_login_poster.png'
import background2 from '../login/assets/images/luca-bravo-uk.jpg'
import { UserOutlined } from '@ant-design/icons'
import { useForgotPassword } from '../../bussines/hooks/user/useForgotPassword'
import stateControl from '../../bussines/utils/state-control'
import SessionService from '../../services/SessionService'
import { useNavigate } from 'react-router-dom'
import { URL_QUOTER_PAGE } from '../quoter'

const URL_FORGOT_PASSWORD_PAGE = '/forgot-password'

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [random, setRandom] = useState(1)
  const { isLoading, onSubmit, currentState, data, error } = useForgotPassword()

  const onFinish = (values) => {
    onSubmit(values.username)
  }

  useEffect(() => {
    setRandom(Math.floor(Math.random() * 10) + 1)
  }, [])

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: ForgotPassword.js:21 ~ useEffect ~ data', data)
      notification.success({
        message: 'We have emailed your password reset link!'
      })
    }
    if (!isLoading && error) {
      console.log('🚀 ~ file: ForgotPassword.js:21 ~ useEffect ~ data', error.response.data)
      notification.success({
        message: 'Something went wrong'
      })
    }
  }, [isLoading, currentState, data, error])

  React.useEffect(() => {
    if (SessionService.existSession()) {
      // if (searchParams.get('redirect')) {
      //   navigate(searchParams.get('redirect'))
      // } else {
      // }
      navigate(URL_QUOTER_PAGE)
    }
  }, [isLoading])

  return (
    <div>
      <div className='desktop-screen'>
        <div className='form-container'>
          <div className='img-logo-container'>
            <AppLogoNameHeader
              style={{
                width: '100%',
                objectFit: 'contain',
                height: '100%',
                paddingBottom: '18px'
              }}
            />
          </div>
          <Form
            name='forgot_password'
            className='login-form'
            style={{ width: '50%' }}
            onFinish={onFinish}
          >
            <h4 style={{ color: 'black' }}>Password assistance</h4>
            {
              currentState === stateControl.STATE_SUCCESS && (
                <p>
                  We have emailed your password reset link!
                </p>
              )
            }
            {
              currentState === stateControl.STATE_INITIAL && (
                <p>
                  Enter the email address associated with your Quote Your Program account.
                </p>
              )
            }
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!'
                }
              ]}
              style={{
                marginBottom: 24
              }}
            >
              <Input
                size='large'
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Email'
              />
            </Form.Item>
            <Button
              loading={isLoading}
              className='byp-btn-blue-200'
              block
              htmlType='submit'
            >
              CONTINUE
            </Button>
            {
              currentState === stateControl.STATE_SUCCESS && (
                <Button
                  loading={isLoading}
                  type='link'
                  htmlType='submit'
                >
                  resend
                </Button>
              )
            }

            <br />
            <h5 className='byp-title byp-fw-bold byp-mt-1'>Has your email changed?</h5>
            <p>
              If you no longer use the email address associated with your account,
              you may contact <a>Customer Service</a> for help restoring access to your account.
            </p>
          </Form>
        </div>
        <div className='img-container-login'>
          <img
            src={random <= 5 ? background : background2}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export { ForgotPasswordPage, URL_FORGOT_PASSWORD_PAGE }
