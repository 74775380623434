import { Col, notification, Row } from 'antd'
import React, { useEffect } from 'react'
import { useGetMostPopularCourseByUser } from '../../../../../bussines/hooks/agency/analytics/users/useGetMostPopularCourses'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

const MostPopularCourses = ({ time, user, program, campus, region, subregion, countrie, institution, agency }) => {
  const { data, isLoading, error } = useGetMostPopularCourseByUser({ user, program, institution, campus, limit: 4, agency_region: region, agency_subregion: subregion, agency_country: countrie, agency_id: agency, ...time })
  // console.log(data)
  useEffect(() => {
    if (!isLoading) {
      if (data) {
        // console.log('🚀 ~ file: MostPopularCourses.js:11 ~ useEffect ~ data', data)
      } else if (error) {
        notification.error({
          message: 'Something went wrong'
        })
        // console.log('🚀 ~ file: MostPopularCourses.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, data, error])

  if (isLoading) {
    return <BasicSpinner />
  }

  function CalculatorTextSize ({ texto }) {
    let variable = 30

    if (texto.length > 20) {
      variable = 17
    } else {
      if (texto.length > 15) {
        variable = 22
      } else {
        variable = 27
      }
    }
    return (
      <div>
        <h5 className='quote-overview--color-blue' style={{ fontSize: variable }}>{texto}</h5>
      </div>
    )
  }
  return (
    <Row gutter={[8, 8]}>
      {
        data?.map((item) => (
          <Col
            key={item.course + '_' + item.quotes_count}
            xs={24}
            sm={12}
            xl={6}
          >
            <div className='quote-list-item-card d-flex' style={{ padding: '0.5rem 1.5rem', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
              <p className='margin-0 byp-color-gray-100 byp-text-sm'>{item?.quotes_count} - Quotes created</p>
              <CalculatorTextSize texto={item?.course} />
              <div className='d-flex align-center' style={{ justifyContent: 'space-between' }}>
                {/* <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>{item?.duration?.split(' ').join('-') ?? item?.weeks + '-weeks'}</p> */}
                {/* <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>Language</p> */}
              </div>
            </div>
          </Col>
        ))
      }
    </Row>
  )
}

export default MostPopularCourses
