import React from 'react'
import { Alert, Button, Checkbox, Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useLoginUser } from '../../../bussines/hooks/user/useLoginUser'
import { LoadingForm } from '../../../common/components/LoadingForm'
import SessionService from '../../../services/SessionService'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { URL_REGISTER_PAGE } from '../../register'
import { URL_QUOTER_PAGE } from '../../quoter'

const LoginForm = ({ isForAB = false }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isLoading, onSubmit, currentState, state } = useLoginUser()

  const onFinish = (values) => {
    onSubmit(values.username, values.password, values.remember)
  }

  const handleNavigateRegister = (event) => {
    event.preventDefault()
    navigate(URL_REGISTER_PAGE)
  }

  React.useEffect(() => {
    if (SessionService.existSession()) {
      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'))
      } else {
        navigate(URL_QUOTER_PAGE)
      }
    }
  }, [isLoading])

  if (isLoading) {
    return <LoadingForm message='Login in...' />
  }

  if (currentState === state.LOGIN_SUCCESS) {
    return <LoadingForm message='Please wait...' />
  }

  return (
    <Form
      name='normal_login'
      className='login-form'
      initialValues={{
        password: process.env.REACT_APP_PASSWORD || '',
        remember: true
      }}
      onFinish={onFinish}
    >
      {currentState === state.LOGIN_ERROR && (
        <Form.Item>
          <Alert
            message='These credentials do not match our records'
            type='error'
          />
        </Form.Item>
      )}
      <Form.Item
        name='username'
        rules={[
          {
            required: true,
            message: 'Please input your Username!'
          }
        ]}
        style={{
          marginBottom: 24
        }}
      >
        <Input
          size='large'
          prefix={<UserOutlined className='site-form-item-icon' />}
          placeholder='Email'
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Please input your Password!'
          }
        ]}
        style={{
          marginBottom: 24
        }}
      >
        <Input.Password
          size='large'
          prefix={<LockOutlined className='site-form-item-icon' />}
          type='password'
          placeholder='Password'
          string=''
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name='remember' valuePropName='checked' noStyle>
          <Checkbox>Keep me signed in</Checkbox>
        </Form.Item>

        <a className='login-form-forgot' href='/forgot-password'>
          Forgot your password?
        </a>
      </Form.Item>

      <Form.Item>
        <Button
          size='large'
          type='primary'
          htmlType='submit'
          className='login-form-button'
        >
          Sign in
        </Button>
      </Form.Item>
      <a
        href={isForAB ? '/registerab/$2y$10$DwiblQ' : 'https://32sifu6bwk2.typeform.com/to/tzXiQ9CV'}
        // onClick={handleNavigateRegister}
      >
        <span style={{ color: 'black' }}>or, </span>  Create account
      </a>
    </Form>
  )
}

export { LoginForm }
