import { Button, Col, Modal, Row } from 'antd'

const ModalWantAccommodation = ({ handleOk, handleCancel, open, handleCloseModal }) => {
  return (
    <>
      <Modal
        open={open}
        onCancel={handleCloseModal}
        className='application-form_accomodation-modal'
        footer={
          <Row gutter={16}>
            <Col span={12}>
              <Button block onClick={handleOk} className='byp-btn-red-50 fs-12 br-05' size='large'>
                Quiero Hospedaje
              </Button>
            </Col>
            <Col span={12}>
              <Button block onClick={handleCancel} type='text' className='fs-12 br-05 fw-6 color-gray-200' size='large'>
                Continuar sin hospedaje
              </Button>
            </Col>
          </Row>
        }
      >
        <h3>¿Deseas contratar hospedaje?</h3>
        Notamos que no haz contratado un hospedaje, si lo deseas podemos ayudarte.
      </Modal>
    </>
  )
}

export default ModalWantAccommodation
