import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormInsurance } from './FormInsurance'
import { TopBarMenuCustom } from '../components/TopBarMenuCustom'

const URL_CREATE_INSURANCE_PAGE = '/dashboard/agency/create/insurance'

function CreateInsurancePage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_MANAGER,
        userRoles.TYPE_AGENCY_CONSULTANT,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
        userRoles.TYPE_AGENCY_EDITOR,
        userRoles.TYPE_INSTITUTION
      ]}
    >
      <MainTheme
        showMenu
      >

        <TopBarMenuCustom />

        <FormInsurance />
      </MainTheme>
    </PageProtected>
  )
}

export { CreateInsurancePage, URL_CREATE_INSURANCE_PAGE }
