import React, { useState } from 'react'
import { Col, List, Row, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import icon from '../../../common/assets/images/icon.png'

const { Paragraph } = Typography

function CardItemCourse ({ item }) {
  const LongText = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false)

    const showMore = () => setShowAll(true)
    const showLess = () => setShowAll(false)

    if (content?.length <= limit) {
      // there is nothing more to show
      return <div className='card-content'>{content}</div>
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <div className='card-content'>
          {content}
          <a style={{ color: 'blue' }} onClick={showLess}>  Read less</a>
        </div>
      )
    }
    if (!content) {
      return <a />
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content?.substring(0, limit) + '...'
    return (
      <div className='card-content'>
        {toShow}
        <a style={{ color: 'blue' }} onClick={showMore}>Read more</a>
      </div>
    )
  }

  const handleImage = () => {
    if (item.course.type === 'package') {
      if (item?.image) {
        return item.image
      }
    }

    if (item.campus.profilePhotoUrl) {
      return item.campus.profilePhotoUrl
    }
    return icon
  }

  const handleUrl = () => {
    if (item.course.type === 'package') {
      return `/package-course/${item.course?.id}/${item.campus?.id}`
    }
    if (item.course.type === 'short') {
      return `/short-term-course/${item.course?.id}/${item.campus?.id}`
    }
    return `/quote-course/${item.course?.id}/${item.campus?.id}`
  }

  const handleAddress = () => {
    if (item.city?.name && item.country?.name) {
      return ' ' + item.city?.name + '-' + item.country?.name
    } else if (item?.campus?.city?.name && item?.campus?.country?.name) {
      return ' ' + item.campus.city?.name + '-' + item.campus.country?.name
    }
  }

  return (
    <div style={{ paddingBottom: '30px' }}>
      <div className='ant-card ant-card-hoverable card-quoter' style={{ boxShadow: 'rgb(0 0 0 / 0%) 0px 1px 2px -2px, rgb(0 0 0 / 0%) 0px 3px 6px 0px, rgb(0 0 0 / 11%) -4px 6px 7px 0px' }}>
        <div className='card-quoter-image'>
          <img
            alt={item.label}
            src={handleImage()}
            style={{ objectFit: 'cover', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}
          />

        </div>

        <List.Item
          style={{ MozBorderRadiusTopRight: '20px', borderRadiusBottomRight: '20px', display: 'inline-block', paddingRight: '.9rem', paddingLeft: '.9rem', lineHeight: '20px' }}
          className='offer-list w-100'
          key={item.title}
        >
          <Row>

            <Col span={22} style={{ width: '120%' }}>
              <List.Item.Meta
                className='card-content text-hidden'
                title={
                  <span>
                    <b>{item.campus.name}</b>
                    {handleAddress()}
                  </span>
                }
                description={item?.course?.label}
              />
              <Paragraph ellipsis={{ rows: 4 }}>
                <LongText content={item.course?.description} limit={160} />
              </Paragraph>
            </Col>
          </Row>
          <Link
            className='ant-btn ant-btn-block byp-btn-blue-100'
            to={handleUrl()}
            target='_blank'
          >Get quote <ArrowRightOutlined />
          </Link>
        </List.Item>
      </div>
    </div>
  )
}

export { CardItemCourse }
