import { useState } from 'react'
import { useMutation } from 'react-query'
import AgencyRepository from '../../../repositories/AgencyRepository'
import state from './../../../utils/state-control'

function useCreateOffice () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.createOffice, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * Create Office
   * @param data
   */
  const createOffice = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, createOffice }
}

export { useCreateOffice }
