import { Button, Col, Divider, Form, Input, InputNumber, Row, Typography } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React from 'react'
import { SelectCurrencyFormItem } from '../../../../../common/components/helper-form/SelectCurrencyFormItem'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'

const { Title } = Typography

function AgencyMiscellaneousFieldContainer ({ form }) {
  const arrayMiscellaneous = Form.useWatch('miscellaneous', form) || []
  return (
    <>
      <Title level={5}>Extra agency costs</Title>
      <Form.List name='miscellaneous'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Divider />
                <Row gutter={8} align='middle'>
                  <Col span={22}>
                    <FloatLabel
                      label='Service Fee'
                      value={arrayMiscellaneous[name]?.name}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[{ required: true, message: 'The name is required' }]}
                      >
                        <Input />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                  <Col span={2}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                  <Col span={5}>
                    <FloatLabel
                      label='Cost'
                      value={arrayMiscellaneous[name]?.cost}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'cost']}
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <InputNumber
                          min={0}
                          style={{ width: '100%' }}
                          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                  <Col span={10}>
                    <div id='currency-select-2'>
                      <FloatLabel
                        label='Currency'
                        value={arrayMiscellaneous[name]?.divisa}
                      >
                        <SelectCurrencyFormItem
                          {...restField}
                          name={[name, 'divisa']}
                          style={{ width: '100%' }}
                          rules={[{ required: true, message: 'This field is required' }]}
                        />
                      </FloatLabel>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
            <Row gutter={8}>
              <Col span={22}>
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </>
  )
}

export { AgencyMiscellaneousFieldContainer }
