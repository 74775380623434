import * as React from "react"

export const EditIcon = (props) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.762 4.178c-.063.04-.136.071-.187.122-1.642 1.64-3.282 3.282-4.926 4.92a2.147 2.147 0 00-.552.89c-.246.768-.515 1.528-.762 2.296-.362 1.13.473 2.265 1.648 2.24a1.93 1.93 0 00.536-.1c.78-.25 1.556-.513 2.335-.77.305-.101.564-.272.792-.5 1.648-1.653 3.298-3.3 4.948-4.95.053-.054.094-.117.138-.176l.047.029c.004.053.01.106.01.159 0 3.318.002 6.638 0 9.957 0 1.208-.916 2.122-2.122 2.124-4.021.002-8.042.002-12.066 0-1.207 0-2.124-.916-2.124-2.12-.002-4.021-.002-8.042 0-12.064 0-1.208.915-2.126 2.12-2.126 3.32-.002 6.64 0 9.96 0h.15l.055.069z"
        fill="#2D2F66"
      />
      <path
        d="M16.445 3.041l2.408 2.406c-.037.039-.082.09-.129.139-2.29 2.287-4.577 4.579-6.87 6.862-.12.12-.284.214-.444.271-.732.257-1.471.493-2.205.744-.25.085-.474.067-.662-.127-.18-.187-.191-.407-.108-.65.257-.751.502-1.507.758-2.258a.892.892 0 01.202-.335c2.322-2.332 4.646-4.654 6.973-6.978.026-.027.055-.051.077-.074zM19.686 4.585c-.794-.792-1.589-1.586-2.371-2.369.328-.34.646-.709 1.006-1.03a.86.86 0 011.135.014c.434.395.85.813 1.244 1.246a.872.872 0 01-.006 1.151c-.317.349-.674.664-1.008.988z"
        fill="#2D2F66"
      />
    </svg>
  )
}

