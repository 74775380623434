import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetYearsByCampus (id) {
  return useQuery(['useGetYearsByCampus', id],
    () => CampusRespository.getYears(id), {
      retry: 2,
      disabled: !!id
    })
}

export { useGetYearsByCampus }
