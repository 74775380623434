import { isSameOrAfter } from '../../../utils/isSameOrAfter'
import { isSameOrBefore } from '../../../utils/isSameOrBefore'
import { addWeeks, differenceInWeeks } from 'date-fns'

function unicCost (objectCost) {
  return objectCost.cost ?? 0
}

function multiplyWeek (objectCost, weeks) {
  return (objectCost.cost * weeks) ?? 0
}

export function sumArrayCost (costs) {
  if (Array.isArray(costs)) {
    return costs.reduce((a, b) => a + b, 0)
  }
  return 0
}

/**
 * applyBetweenTime
 * @param objectCost
 * @param weeks
 * @param startDate
 * @param highSeasons
 * @returns {number|*}
 */
function applyBetweenTime (objectCost, weeks, startDate, highSeasons) {
  let realWeeks = 0
  const endDate = addWeeks(new Date(startDate), weeks)
  const arrayCost = []
  for (const sns of highSeasons) {
    if (isSameOrAfter(new Date(sns.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(sns.hs_end), new Date(endDate))
    ) {
      realWeeks += differenceInWeeks(new Date(sns.hs_end), new Date(sns.hs_start))
      arrayCost.push(objectCost.cost)
      continue
    }

    if (isSameOrAfter(new Date(sns.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(sns.hs_start), new Date(endDate))
    ) {
      realWeeks += differenceInWeeks(new Date(endDate), new Date(sns.hs_start))
      arrayCost.push(objectCost.cost)
      continue
    }

    if (isSameOrAfter(new Date(sns.hs_end), new Date(startDate)) &&
      isSameOrBefore(new Date(sns.hs_end), new Date(endDate))
    ) {
      realWeeks += differenceInWeeks(new Date(sns.hs_end), new Date(startDate))
      arrayCost.push(objectCost.cost)
    }
  }
  const costTotal = sumArrayCost(arrayCost)
  return costTotal * realWeeks
}

/**
 * calculateServiceCost
 * @param {Array} arrayCost
 * @param {int} weeks
 * @param {string} startDate
 * @param {Array} highSeasons
 * @returns {number}
 */
function calculateServiceCost (arrayCost, weeks, startDate, highSeasons) {
  if (!arrayCost.length) {
    return 0
  }

  const categories = {
    unic: [],
    standard: [],
    seasonal: []
  }
  const arrCost = arrayCost.map(cost => {
    let __cost = 0
    switch (cost.type) {
      case 'unic':
        __cost = unicCost(cost)
        categories.unic.push(__cost)
        return __cost

      case 'standard':
        __cost = multiplyWeek(cost, weeks)
        categories.standard.push(__cost)
        return __cost

      case 'seasonal':
        __cost = applyBetweenTime(cost, weeks, startDate, highSeasons)
        categories.seasonal.push(__cost)
        return __cost

      default:
        return __cost
    }
  })

  return {
    total: sumArrayCost(arrCost),
    ...categories
  }
}

export { calculateServiceCost }
