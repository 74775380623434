import api from '../../services/api'

class ApplicationRepository {
  async create (params) {
    const response = await api.post('/aplications/create', { ...params })
    return response.data
  }

  async show (id) {
    const response = await api.get('aplication/' + id)
    return response.data
  }
}

export default new ApplicationRepository()
