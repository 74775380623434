import { Statistic, Table } from 'antd'
import { useEffect, useState } from 'react'
const { Column } = Table

function getStyle (type) {
  // if (type === 'seasonal' || type === 'discount') {
  if (type === 'discount') {
    return {
      color: 'red',
      fontWeight: 'bold'
    }
  }
  return {}
}

function QuoteOverviewCosts ({ isLoading, data, divisa, exchangeVal, type, agentDivisa, includedInService = [] }) {
  const [costs, setCosts] = useState([])
  const getValue = (value) => {
    if (value === null) return ''
    let newValue = null
    if (!isNaN(value)) {
      newValue = Number(value)
      newValue = newValue.toFixed(2)
      newValue = newValue.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }
    return !isNaN(value) ? `$${newValue}` : value
  }

  const CostCell = ({ record }) => {
    if (record.duration === 'Included') {
      return <span>-</span>
    }
    if (record.cost === 0) {
      return <span style={{ color: 'red' }}><b>INCLUDED</b></span>
    }
    return (
      <Statistic
        value={getValue(record.cost)} precision={2}
        className={(record.cost < 0 || record.type === 'discount') ? 'discount' : null}
      />
    )
  }

  useEffect(() => {
    const res = data.filter(cost => {
      return cost?.isAgency !== 'Agency'
    })
    const included = includedInService?.map((includedItem) => ({
      name: includedItem.name,
      cost: 0,
      duration: 'Included'
    }))

    setCosts([
      ...res,
      ...included
    ])
  }, [])

  return (
    <Table
      loading={isLoading}
      dataSource={costs ?? []}
      className='byp-table-clear quote-overview-table'
      bordered
      rowKey='id'
      pagination={{
        hideOnSinglePage: true,
        pageSize: 50
        // showSizeChanger: true
      }}
    >
      <Column
        title='Concept' dataIndex='name' key='name'
        render={(_, record) => (
          <>
            <span style={getStyle(record.type)}>{record.name}</span>
            {record.description && (
              <p className='description-cost' style={getStyle(record.type)}>{record.description}</p>
            )}
          </>
        )}
      />
      <Column
        title='Payment'
        render={({ duration, type }) => (
          <span style={getStyle(type)}>{duration}</span>
        )}
      />
      <Column
        title={'Total ' + divisa} dataIndex='cost' key='cost'
        render={(_, record) => (
          <>
            <span className='data-label'>Total {divisa}</span>
            <CostCell record={record} />
          </>
        )}
      />
      {
        type === 'Agency' && agentDivisa?.code !== divisa && (
          <Column
            title={`Aprox ${agentDivisa?.code}`} dataindex='' key=''
            render={({ cost, type }) => (
              exchangeVal > 0
                ? (
                  <>
                    <span className='data-label'>Aprox {agentDivisa?.code}</span>
                    <Statistic value={getValue(Number(cost) * exchangeVal)} precision={2} className={(cost < 0 || type === 'discount') ? 'discount' : null} />
                  </>
                  )
                : (<span style={getStyle(type)}>{isLoading ? 'Please wait...' : 'You do not have a exchange value configured'}</span>)
            )}
          />
        )
      }
    </Table>
  )
}

export { QuoteOverviewCosts }
