import { Form, Select } from 'antd'
import { institutionTypes } from '../../../languageConfiguration/infoCourseTranslate'
const { Option } = Select

const typesTranslations = (type, lang) => {
  const translations = institutionTypes[lang] ?? null
  if (!translations) return type
  return translations[type] ?? type
}

const SelectTypeOfInstitutionFormItem = ({ name, label, program, lang, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: 'This field is required' }]}
    >
      <Select
        className='w-100'
        defaultValue={program === 'graduate' ? 'Public University' : 'Private'}
        {...rest}
      >
        {program === 'graduate' && (
          <>
            <Option value='Public University'>
              {!lang && 'Public University'}
              {lang && typesTranslations('Public University', lang)}
            </Option>
            <Option value='Private University'>
              {!lang && 'Private University'}
              {lang && typesTranslations('Private University', lang)}
            </Option>
            <Option value='Public College'>
              {!lang && 'Public College'}
              {lang && typesTranslations('Public College', lang)}
            </Option>
            <Option value='Private College'>
              {!lang && 'Private College'}
              {lang && typesTranslations('Private College', lang)}
            </Option>
          </>
        )}
        {(program === 'academic_years' || program === 'boarding_schools' || program === 'short_term') && (
          <>
            <Option value='Private'>Private</Option>
            <Option value='Public'>Public</Option>
          </>
        )}
      </Select>
    </Form.Item>
  )
}

export { SelectTypeOfInstitutionFormItem }
