import { Checkbox, Col, Form, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetAllCities } from '../../../bussines/hooks/quoter/useGetAllCities'
import { useGetAllCitiesByIso } from '../../../bussines/hooks/quoter/useGetAllCitiesByIso'

const { Option } = Select

function SelectCityByIsoFormItem ({ showSearch, showAll, loading, isListField, fieldKey, ...props }) {
  const { iso, filters } = props

  const { isLoading, data: cities } = useGetAllCitiesByIso(iso, filters)
  return (
    <Form.Item {...props}>
      <Select
        {...props}
        loading={isLoading}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        placeholder={props.placeholder ?? ''}
        showSearch={showSearch ?? true}
        autoComplete='none'
      >
        {showAll && <Option value={null}>All</Option>}
        {cities?.map(item => (
          <Option key={item.id} value={item.id}>{`${item?.name}`}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

function SelectCitiesAll ({ name, onChange, single, allowClear, form, rules, filters = {}, isAgencyOffice, isReports, isDisabled, ...props }) {
  const [checkAllBranch, setCheckAllBranch] = useState(false)
  const _name = name ?? 'branches'
  const { iso } = props
  const { isLoading, data } = useGetAllCities(iso ? { country: iso } : filters)

  const onCheckAllChangeBranch = (e) => {
    setCheckAllBranch(e.target.checked)
  }

  useEffect(() => {
    if (data) {
      const ids = data.map(c => (c.id))
      if (form) {
        checkAllBranch ? form.setFieldsValue({ [_name]: ids }) : form.setFieldsValue({ [_name]: [] })
      }
    }
  }, [checkAllBranch, data])

  return (
    <>
      <Row>
        {props.label && (
          <Col style={{ paddingTop: '12px' }} span={24} className={isReports ? 'title-multi-select' : ''}>
            {props.label + ':'}
          </Col>
        )}

        <Col xs={24} md={isReports ? 24 : props.label ? 20 : 24} className={isReports ? 'multi-select' : ''}>
          <Row className='byp-dashboard-header byp-mb-1'>
            <Col xs={24} md={4} className='byp-justify-centered' >
              <Form.Item className='byp-m-0-important prefix' name={name + 'all'} valuePropName='checked'>
                <Checkbox onChange={onCheckAllChangeBranch} disabled={isAgencyOffice || isDisabled}>
                  {(props.label && !isReports) && 'All ' + name}
                  {!props.label && 'All '}
                  {isReports && 'All'}
                </Checkbox>
              </Form.Item>
            </Col>

            <Col xs={24} md={20} className='byp-justify-centered'>
              <Form.Item
                name={_name}
                rules={rules}
                className='byp-m-0-important'
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder=''
                  loading={isLoading}
                  mode='multiple'
                  className='byp-m-0-important'
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  onChange={onChange}
                  disabled={isAgencyOffice || isDisabled}
                  maxTagCount='responsive'
                  bordered={false}
                >
                  {data?.map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export { SelectCityByIsoFormItem, SelectCitiesAll }
