import { Form, Select } from 'antd'
import React from 'react'
import { useGetCampusOfCourse } from '../../../bussines/hooks/school/useGetCampusOfCourse'
const { Option } = Select
function SelectCampusOfCourse ({ onChange, mode, campus, course, filter, ...props }) {
  const { isLoading, data } = useGetCampusOfCourse(campus, course, filter)

  return (
    <Form.Item {...props}>
      <Select
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        loading={isLoading}
        mode={mode}
        className='byp-m-0'
        onChange={onChange}
      >
        {data?.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectCampusOfCourse }
