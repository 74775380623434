import React from 'react'
import icon_schools from '../../../common/assets/images/icon_schools.png'
import schools from '../../../common/assets/images/schools.png'


export function HomeSectionC() {
  return (
    <div className='mb-responsive'>
      <div className="d-flex align-items-center px-0 my-5 home-sectioncde-home">
        <div className="col-md-8 p-0">
          <div
            className="card box-shadow-0 border-0 align-items-center home-card-responseive"
            style={{ background: "#CDDFFF", width: "100%", minHeight: '25.938rem' }}
          >
            <div className="card-content home-sectionc-card" style={{ width: "30rem", marginTop:'2rem', marginBottom:'2rem', marginLeft:'-5rem' }}>
              <div class='home-sectionc-title'>
                <img src={icon_schools} alt="" className="mb-1" style={{width:'70px',height: '70px'}}></img>
                <div>
                  <h5 style={{marginTop:'1rem'}}>
                    <b>Schools</b>
                  </h5>
                  <p className='byp-sub-black'>
                    Share your prices and promotions in just minutes. QYP has a
                    modern, secure and easy to use interface.
                  </p>
                </div>
              </div>
              <ul class="byp-blue-200 byp-text-cont-blue">
                <li>
                  User-friendly interface with powerful options to generate
                  accurate quotes, taking into account all the variables you want.
                </li>
                <li>
                  Get statistics on your schools' performance and make data-driven
                  decisions.
                </li>
                <li>
                  Once you upload your first quote, updating your promotions will
                  only take an instant.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="d-md-block col-12 col-md-4 p-0 home-img-c"
          style={{ margin: "-10rem" }}
        >
          <div class="card card-transparent box-shadow-0 border-0">
            <div className='home-img'>
              <img src={schools} alt="" className="byp-poster" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}