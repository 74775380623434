import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { useGetGrades } from '../../../bussines/hooks/quoter/useGetGrades'
const { Option } = Select

// Return institutions types that has offers
const SelectGradesFormItem = ({ name, filters = {}, className, noStyle, onChange, showAll, ...rest }) => {
  const { data } = useGetGrades(filters)

  useEffect(() => {
    if (data) {
      console.log('🚀 ~ file: SelectGradesFormItem.js:10 ~ useEffect ~ data', data)
    }
  }, [data])

  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
    >
      <Select
        className={className}
        showSearch
        size={rest?.size}
        onChange={onChange}
        placeholder={rest.placeholder ?? ''}
        {...rest}
      >
        {data?.map((item) => (
          <Option key={item} value={item}>{item}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectGradesFormItem }
