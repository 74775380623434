import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useFindOffer (courseId, campusId, filters = {}) {
  return useQuery(['useFindOffer', courseId, campusId, filters],
    () => CourseRepository.findOffer(courseId, campusId, filters), {
      retry: 1,
      enabled: !!courseId || !!campusId
    })
}

function useFindOfferPackage (courseId, campusId, filters = {}) {
  return useQuery(['useFindOfferPackage', courseId, filters],
    () => CourseRepository.findOfferPackage(courseId, campusId, filters), {
      retry: 1,
      enabled: !!courseId || !!campusId
    })
}

export { useFindOffer, useFindOfferPackage }
