import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormUploadPromotions } from './FormUploadPromotions'

const URL_SHORT_TERM_PROMOTIONS_PAGE = '/dashboard/short_term_course/upload-promotions'

function UploadPromotionsPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_INSTITUTION
      ]}
    >
      <MainTheme
        showMenu
      >
        <FormUploadPromotions />
      </MainTheme>
    </PageProtected>
  )
}

export { UploadPromotionsPage, URL_SHORT_TERM_PROMOTIONS_PAGE }
