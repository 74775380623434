import { useStepByStep } from '../../../../providers/stepbystep/step-service-provider'
import { Form } from 'antd'
import React from 'react'
import { InstitutionExtrasFieldsContainer } from '../steps/institution-extras/InstitutionExtrasFieldsContainer'
import { InstitutionFormStepProfile } from './InstitutionFormStepProfile'
import PropTypes from 'prop-types'
import { useProfileEnterprise } from '../../../../providers/enterprise/profile-enterprise-provider'
import { FullPageSpinner } from '../../../../common/components/FullPageSpinner'

function InstitutionFormExtras ({ currentStep }) {
  const { initValues } = useProfileEnterprise()
  const { form, handleOnFinish } = useStepByStep()

  const onFinish = (values) => {
    handleOnFinish(currentStep, values)
  }

  if (!initValues) {
    return <FullPageSpinner title='Cargando datos' />
  }

  return (
    <Form
      form={form}
      layout='vertical'
      scrollToFirstError
      name='InstitutionFormStepAddContacts'
      onFinish={onFinish}
      initialValues={initValues}
    >
      <InstitutionExtrasFieldsContainer form={form} />
    </Form>
  )
}

InstitutionFormStepProfile.propTypes = {
  currentStep: PropTypes.number.isRequired
}

export { InstitutionFormExtras }
