import React from 'react'
import { ApplicationsQuotesDataContainer } from '../../dashboards/agency/analitics/ApplicationsQuotesDataContainer'
import { CreatedQuotesDataContainer } from '../../dashboards/agency/analitics/CreatedQuotesDataContainer'
import { SendsQuotesDataContainer } from '../../dashboards/agency/analitics/SendsQuotesDataContainer'
import { SeenQuotesDataContainer } from '../../dashboards/agency/analitics/SeenQuotesDataContainer'
import { NotSeenQuotesDataContainer } from '../../dashboards/agency/analitics/NotSeenQuotesDataContainer'

const getColumns = (category) => {
  if (category === 'created' || category === 'seen' || category === 'notseen') {
    return [
      { label: 'Date of creation', key: 'created_at', withColumn: '12.5%' },
      { label: 'Program', key: 'program', withColumn: '15%' },
      { label: 'School', key: 'school', withColumn: '25%' },
      { label: 'Course', key: 'course', withColumn: '12.5%' },
      { label: 'ID client', key: 'student_id', withColumn: '7.5%' },
      { label: 'View', key: 'view', withColumn: '7.5%' },
      { label: 'Client email', key: 'client_email', withColumn: '20%' }
    ]
  }
  if (category === 'sended') {
    return [
      { label: 'Date of send', key: 'date_of_send', withColumn: '10%' },
      { label: 'Program', key: 'program', withColumn: '15%' },
      { label: 'School', key: 'school', withColumn: '25%' },
      { label: 'Course', key: 'course', withColumn: '20%' },
      { label: 'ID client', key: 'student_id', withColumn: '7.5%' },
      { label: 'Client email', key: 'client_email', withColumn: '22.5%' }
    ]
  }
  if (category === 'applications') {
    return [
      { label: 'Date of application', key: 'date_of_application', withColumn: '10%' },
      { label: 'Program', key: 'program', withColumn: '15%' },
      { label: 'School', key: 'school', withColumn: '25%' },
      { label: 'Course', key: 'course', withColumn: '12.5%' },
      { label: 'Client', key: 'student_id', withColumn: '7.5%' },
      { label: 'Client email', key: 'client_email', withColumn: '20%' },
      { label: 'Client Phone', key: 'client_phone', withColumn: '10%' }
    ]
  }
  return []
}

function TableQuotes ({ category, user, office, time, setOrderby, orderby, studentID }) {
  return (
    <div className='table-total-quote-responsive'>
      <table className='table-total-quote table_my_reports'>
        <thead>
          <tr>
            {getColumns(category)?.map(category =>
              (category.key === 'created_at' || category.key === 'date_of_send' || category.key === 'date_of_application')
                ? (
                  <th key={category.key} style={{ width: category?.withColumn }}><span>{category.label}</span></th>
                  )
                : (
                  <th key={category.key} onClick={() => setOrderby(category.key)} style={{ width: category?.withColumn }}><span style={{ cursor: 'pointer' }}>{category.label}</span></th>
                  ))}
          </tr>
        </thead>
        <tbody>
          {getDataSource(category, user, office, time, orderby, studentID)}
        </tbody>
      </table>
    </div>
  )
}

const getDataSource = (category, user, office, time, orderby, studentID) => {
  if (category === 'created') {
    return <CreatedQuotesDataContainer user={user} office={office} time={time} orderby={orderby} studentID={studentID} />
  }
  if (category === 'sended') {
    return <SendsQuotesDataContainer user={user} office={office} time={time} orderby={orderby} studentID={studentID} />
  }
  if (category === 'applications') {
    return <ApplicationsQuotesDataContainer user={user} office={office} time={time} orderby={orderby} studentID={studentID} />
  }
  if (category === 'seen') {
    return <SeenQuotesDataContainer user={user} office={office} time={time} orderby={orderby} studentID={studentID} />
  }
  if (category === 'notseen') {
    return <NotSeenQuotesDataContainer user={user} office={office} time={time} orderby={orderby} studentID={studentID} />
  }
  return []
}

export { TableQuotes }
