import { Button, Col, Row } from 'antd'
import { useGetMyPartners } from '../../../bussines/hooks/partners/useGetMyPartners'
import { useEffect, useState } from 'react'
import { EducationGroupSchoolCard } from './EducationGroupSchoolCard'
import { EducationGroupInstitutionFilters } from './EducationGroupInstitutionFilters'

const GroupEducationPartnersContainier = () => {
  const [filters, setFilters] = useState({ page: 1 })
  const { data, isLoading, refetch } = useGetMyPartners(filters)
  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: index.js:13 ~ useEffect ~ data:', data)
    }
  }, [data, isLoading])

  return (
    <div style={{ padding: '0 8vw' }}>
      <h6 style={{ color: 'var(--blue-100)' }}>
        Select the institutions you want to see and select your favorites
      </h6>
      <EducationGroupInstitutionFilters setFilters={setFilters} filters={filters} />
      <br />
      <Row gutter={24}>
        {data?.data?.map((item) => (
          <Col key={item.id} xs={12} md={8} xxl={6}>
            <EducationGroupSchoolCard
              institution={item}
              refetch={refetch}
            />
          </Col>
        ))}
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px'
        }}
      >
        <Button
          style={{
            borderColor: 'var(--blue-100)',
            color: 'var(--blue-100)',
            fontWeight: 'bold'
          }}
        >
          Load more
        </Button>
      </div>
    </div>
  )
}

export { GroupEducationPartnersContainier }
