import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

/**
 * useGetMyEstimates
 * @param {Object} filter
 * @returns {UseQueryResult}
 */
function useGetMyEstimates (filter) {
  const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetMyEstimates', queryString],
    () => QuoteRepository.getEstimates(queryString), {
      retry: 2,
      enabled: !!filter
    })
}

export { useGetMyEstimates }
