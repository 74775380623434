import { useQuoter } from '../../../providers/quoter/quoter-course-context'
// import { format } from 'date-fns'
import { Col, Row } from 'antd'
import { format } from 'date-fns'
import { SelectStartAndEndDatesFormItem } from '../../../common/components/helper-form/SelectStartAndEndDatesFormItem'
import moment from 'moment'

function SelectMedicalStartAndDuration () {
  const { formConfig, setFormConfig } = useQuoter()

  const handleChangeDate = (val, name) => {
    setFormConfig({
      ...formConfig,
      [name]: moment(val).toDate()
    })
  }

  function getMedicalDates () {
    if (formConfig?.medical_start && formConfig?.medical_end) {
      return <i>{format(formConfig?.medical_start, 'LLLL d, Y')} to {format(formConfig?.medical_end, 'LLLL d, Y')}</i>
    }
    return ''
  }

  return (
    <Row gutter={12} className='w-100'>
      <SelectStartAndEndDatesFormItem
        startName='medical_start'
        endName='medical_end'
        handleChangeDate={handleChangeDate}
      />
      <Col span={24}>
        <p style={{ marginBottom: '0', marginTop: '.5rem' }}>
          {getMedicalDates()}
        </p>
      </Col>
    </Row>
  )
}

export { SelectMedicalStartAndDuration }
