import { Form, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { format } from 'date-fns'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

const { Option } = Select

function OtherSection () {
  const [selected, setSelected] = useState()
  const [isMandatory, setIsMandatory] = useState(false)
  const [haveMandatory, setHaveMandatory] = useState(false)
  const { service, formConfig, setFormConfig, course, isLoadingOffer, prices } = useQuoter()

  const handleChange = (value, id, cost, frequency) => {
    let fees = []
    if (!formConfig.fees) {
      setFormConfig({
        ...formConfig,
        fees: []
      })
    }
    if (value) {
      fees = formConfig.fees ?? []
      fees?.push({ id, cost, frequency })
    } else {
      formConfig.fees?.map((fee) => (
        fee.id !== id ? fees.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      fees
    })
  }

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      insurance: included
    })
  }

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      healthInsuranceId: selected
    })
  }, [selected])

  useEffect(() => {
    if (course && !isLoadingOffer) {
      const loadInitialData = async () => {
        try {
          const hs = service.getPrices()?.extras
          if (hs) {
            for (const item of hs) {
              if (item.mandatory) {
                setHaveMandatory(true)
              }
            }
          }

          const medical = service.getArrayHealtInsurance()
          if (medical) {
            for (const item of medical) {
              if (item.mandatory) {
                setIsMandatory(true)
              }
            }
          }
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }
      loadInitialData()
    }
  }, [course, isLoadingOffer, prices])

  return (
    <div style={{ marginTop: '20px' }}>
      {service.getArrayHealtInsurance()?.length > 0 && (
        <RowQuoter
          colA={
            <>
              <p className='w-100'>
                Add insurance
                {!isMandatory && (
                  <Switch
                    onChange={handleChangeSwitch} checked={service.includeInsurance()} checkedChildren='YES'
                    unCheckedChildren='NO'
                  />
                )}
              </p>
              {
                (service.includeInsurance() || isMandatory) && (
                  <>
                    <Form.Item
                      label='Insurance name'
                      name='health_insurance'
                      className='byp-mb-0'
                      rules={[{
                        required: true,
                        message: 'Field required'
                      }]}
                    >
                      <Select
                        style={{ width: '95%' }}
                        allowClear
                        placeholder='Insurance name'
                        onChange={setSelected}
                        size='small'
                        disabled={isMandatory}
                      >
                        {service.getArrayHealtInsurance().map((itm) => {
                          return <Option key={itm.id} value={itm.id}>{itm.name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                    {
                      // Insurance description
                      service.getInsuranceSelected()?.description
                    }
                    {
                      service.getInsuranceDiscounts().length > 0 && (
                        service.getInsuranceDiscounts()?.map((d) => (
                          <p key={d.id} className='byp-fw-bold'>
                            {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                          </p>
                        ))
                      )
                    }
                  </>
                )
              }
            </>
          }
          colB={
            <StatisticWithDiscounts
              discounts={service.getInsuranceDiscounts()}
              amount={service.getAmountHealthInsurance()?.toFixed(2)}
              currency={service.getCurrency()}
              subtotal={service.getSubtotalInsurance()}
              active={service.includeInsurance()}
            />
          }
        />
      )}
      {service.getPrices()?.extras?.map((item) => {
        return item.name !== 'Registration Fee'
          ? (
            <RowQuoter
              key={item.id}
              colA={
                <>
                  <p>
                    {item.name}
                    {!item?.mandatory && (
                      <Switch
                        onChange={(value) => { handleChange(value, item.id, item.cost, item.frequency) }}
                        className='byp-ml-1'
                        checked={service.isChecked(item.id)}
                        checkedChildren='YES'
                        unCheckedChildren='NO'
                      />
                    )}
                  </p>
                  <p className='description-cost'>{item.description}</p>
                </>
              }
              colB={
                <StatisticWithDiscounts
                  discounts={service.getFeeDiscounts(item.id)}
                  amount={service.getFeeCost(item.id)?.toFixed(2)}
                  currency={service.getCurrency()}
                  subtotal={service.getFeeSubtotal(item.id)}
                  active={service.isChecked(item.id)}
                />
              }
            />
            )
          : null
      })}
    </div>
  )
}

export { OtherSection }
