import React, { useEffect, useState } from 'react'
import { Avatar, Col, Layout, PageHeader, Row, Typography } from 'antd'
import { QuoterForm } from './components/QuoterForm'
import { useQuoter } from '../../providers/quoter/quoter-course-context'
import '../../common/assets/css/switch-theme.less'
import { QuoterHighEducationForm } from './components/QuoterHighEducationForm'
import { ProgramIntake } from './components/ProgramIntake'

const { Content } = Layout
const { Paragraph } = Typography

export default function FormContent ({ setHasPermision }) {
  const { course, service, offerError, isOfferError, isLoadingOffer } = useQuoter()
  const [showLangQuoter, setShowLangQuoter] = useState()

  useEffect(() => {
    if (offerError && isOfferError) {
      console.log('🚀 ~ file: index.js ~ line 94 ~ useEffect ~ offerError', offerError)
    }
  }, [offerError, isOfferError])

  useEffect(() => {
    const program = course?.offer?.program?.name
    if (program === 'language' || program === 'summer_camps') {
      setShowLangQuoter('yes')
    } else {
      setShowLangQuoter('no')
    }

    if (!course?.is_active) {
      setHasPermision(false)
    }
  }, [course])

  if (offerError && isOfferError) {
    return 'ups'
  }

  return (
    <PageHeader
      className='antd-card content-quoter'
      ghost={false}
      title={
        <Row>
          <Col>
            <Avatar
              style={{ maxWidth: '4.5rem', maxHeight: '4.5rem' }}
              src={course?.offer?.campus?.profilePhotoUrl}
              icon={<img src={course?.offer?.campus?.parent?.profile_photo_url} alt='' />}
            />
          </Col>
          <Col className='byp-justify-centered'>
            <h4 className='byp-title byp-m-0 quote-course--title__responsive'>{course?.offer?.campus?.name}</h4>
          </Col>
        </Row>
      }
      extra={[
        service.isInstitution() && <small key='3'>{course?.offer?.campus?.address}</small>
      ]}
      footer={
        <>
          {course?.offer?.program?.name !== 'language' && (
            <Paragraph style={{ paddingBottom: '2rem' }}>
              <i className='byp-fw-bold'>
                Note: These prices and start dates are only for refererence. The final price is when you get the letter of acceptance.<br />
                To apply and be accepted you must have all the requirements from the institution.<br />
                Places are subject to change to college availability. The cost is only for the first year or first semester program<br />
                *The official hosting date is a reference and the definitive date will be when the start date is known.
              </i>
            </Paragraph>
          )}
        </>
      }
    >
      <h3 className='byp-title byp-text-md'>{course?.offer?.campus?.group_name}</h3>
      <h3 className='byp-title'>{course?.offer?.name}</h3>
      {course?.offer?.program?.name === 'graduate' && (
        <Row className='high-info-quote'>
          <Col span={12}>
            {course?.offer?.code && (<span>Code: <b>{course?.offer?.code}</b></span>)}
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p style={{ marginBottom: '.4rem' }}>Length: <b>{service?.courseDuration()}</b></p>
            <p>Type of Institution: <b>{course?.offer?.campus.type}</b></p>
          </Col>
        </Row>
      )}
      {course?.offer?.program?.name === 'graduate' && (
        <Row className='high-info-quote-tablet'>
          <Col span={12}>
            {course?.offer?.code && (<span>Code: <b>{course?.offer?.code}</b></span>)}
            <ProgramIntake />
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p>Type of Institution: <b>{course?.offer?.campus.type}</b></p>
            <p style={{ marginBottom: '.4rem' }}>Program Length: <b>{service?.courseDuration()}</b></p>
          </Col>
        </Row>
      )}
      {(course?.offer?.program?.name === 'academic_years' || course?.offer?.program?.name === 'boarding_schools') && (
        <Row>
          <Col span={12}>
            <p>Grades: <span className='byp-fw-bold'>{course?.offer?.campus?.grades?.join('-')}</span></p>
            <p>Course type: <span className='byp-fw-bold'>{course?.offer?.type}</span></p>
          </Col>
          <Col span={12} style={{ textAlign: 'end' }}>
            <p>Length: <span className='byp-fw-bold'>{course?.offer?.length} {course?.offer?.time}</span></p>
            <p>Type of Institution: <span className='byp-fw-bold'>{course?.offer?.campus?.type}</span></p>
          </Col>
        </Row>
      )}
      <br />
      <Content>
        {!isLoadingOffer && showLangQuoter === 'yes' && <QuoterForm />}
        {!isLoadingOffer && showLangQuoter === 'no' && <QuoterHighEducationForm />}
      </Content>
    </PageHeader>
  )
}
