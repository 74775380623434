import { Typography } from 'antd'
import React, { useEffect } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

const { Text } = Typography

function FieldPayTuition ({ form }) {
  const { course, service, isLoadingOffer, prices } = useQuoter()
  // TODO: move this to the QuoterForm or in hte QuoterFormConfigured
  useEffect(() => {
    if (course && !isLoadingOffer) {
      // Here was the initilization
    }
  }, [course, isLoadingOffer, prices])

  return (
    <RowQuoter
      colA={
        <>
          <Text>Registration Fee</Text>
          {
            service.getTuitionFeeDiscounts().length > 0 && (
              service.getTuitionFeeDiscounts()?.map((d) => (
                <p key={d.id} className='byp-fw-bold'>
                  {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                </p>
              ))
            )
          }
        </>
      }
      colB={
        <>
          <StatisticWithDiscounts
            discounts={service.getTuitionFeeDiscounts()}
            amount={service.getAmountInscription()?.toFixed(2)}
            currency={service.getCurrency()}
            subtotal={service.getAmountInscription()?.toFixed(2) - service.getTuitionFeeDiscountsAmount()}
            active
          />
        </>
      }
    />
  )
}

export { FieldPayTuition }
