import { Form, Select, Statistic, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { SelectMedicalStartAndDuration } from './SelectMedicalStartAndDuration'

const { Option } = Select

function MedicalSection () {
  const [selected, setSelected] = useState()
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      medical: included
    })
  }

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      medicalId: selected
    })
  }, [selected])

  return (
    <div style={{ marginTop: '20px' }}>
      <RowQuoter
        colA={
          <p className='w-100'>
            Add medical insurance <Switch onChange={handleChangeSwitch} checked={formConfig.medical} checkedChildren='YES' unCheckedChildren='NO' />
          </p>
        }
      />

      {service.includeMedical() && (
        <RowQuoter // Select Accomodation
          hr={service.getAmountMedical() <= 0}
          colA={
            <>
              <Form.Item
                label='Medical Insurance Name'
                name='medical'
                rules={[{
                  required: true,
                  message: 'Field required'
                }]}
              >
                <Select
                  style={{ width: '95%' }}
                  allowClear
                  placeholder='No seleccionado'
                  onChange={setSelected}
                  size='small'
                >
                  {service.getMedicalInsurance().map((itm) => {
                    return <Option key={itm.id} value={itm.id}>{itm.name}</Option>
                  })}
                </Select>
              </Form.Item>
              {formConfig.medicalId && <SelectMedicalStartAndDuration />}
            </>
          }
          colB={
            service.includeMedical() && (
              <>
                {// Low Season Cost
                  service.getAmountMedical() > 0 && (
                    <Statistic
                      prefix=''
                      precision={2}
                      value={`${service.getAmountMedical()}`}
                      suffix={`${service.getAgencyMedicalCurrency()}`}
                    />
                  )
                }
              </>
            )
          }
        />
      )}

    </div>
  )
}

export { MedicalSection }
