import React, { useEffect, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import { AppLogoNameHeader } from '../../../common/components/AppLogoNameHeader'
import '../../../../src/common/assets/css/main-theme.less'
import { URL_PRICING_AND_FAQ_PAGE } from '../../pricingandfaq/PricingAndFAQ'
import { URL_ABOUT_PAGE } from '../../about/AboutPage'
import SessionService from '../../../services/SessionService'

const { Header } = Layout
export function HomeTopBar ({ handleClick, isActive }) {
  const [route, setRoute] = useState({
    url: '../../login/',
    label: 'Log in',
    existSession: false
  })

  useEffect(() => {
    if (SessionService.existSession()) {
      setRoute({
        url: '/quote',
        label: 'Home',
        existSession: true
      })
    }
  }, [])
  return (
    <Header className='header-theme'>
      <Row style={{ margin: '0 1rem' }}>
        <Col xs={15} md={4} lg={4}>
          <AppLogoNameHeader className='logo-small-footer' />
        </Col>
        <Col xs={0} md={20} lg={20} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', overflow: 'hidden' }} className='navbar__links'>
          <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={URL_ABOUT_PAGE} style={{ border: 'none', fontSize: '14px' }}> <b>About us</b> </a>
          <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={URL_ABOUT_PAGE} style={{ border: 'none', fontSize: '14px' }}> <b>Contact</b> </a>
          <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={URL_PRICING_AND_FAQ_PAGE} style={{ border: 'none', fontSize: '14px' }}> <b>Pricing</b> </a>
          <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={route.url}> <b style={{ fontSize: '14px' }}>{route.label}</b> </a>
          {/* <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href='../../register/' style={{ background: '#2D2F66', color: '#F4F4F4', border: '1px' }}> <b style={{ fontSize: '14px' }}>Sign in</b> </a> */}
          {!route.existSession && (
            <a
              className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button'
              href='https://32sifu6bwk2.typeform.com/to/tzXiQ9CV'
              style={{ background: '#2D2F66', color: '#F4F4F4', border: '1px' }}
            >
              <b style={{ fontSize: '14px' }}>Sign in</b>
            </a>

          )}
        </Col>

        <div className={`d-flex align-center ${isActive ? 'byp-bg-blue-200' : ''}`} style={{ marginRight: '0.5rem' }} onClick={handleClick}>
          <button className={`nav-menu ${isActive ? 'active byp-bg-blue-200' : ''}`}>
            <div />
            <div />
            <div />
          </button>
        </div>

        <Col xs={5}>
          <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={route.url}> <b style={{ fontSize: '0.75rem' }}>{route.label}</b> </a>
        </Col>

        <div className={`nav__menu_list ${isActive ? 'active' : ''}`}>
          <div>
            <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={URL_ABOUT_PAGE} style={{ border: 'none', fontSize: '14px' }}> <b>About us</b> </a>
            <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href={URL_ABOUT_PAGE} style={{ border: 'none', fontSize: '14px' }}> <b>Contact</b> </a>
            <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href='' style={{ border: 'none', fontSize: '14px' }}> <b>Pricing</b> </a>
            <a className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' href='../../register/' style={{ background: '#2D2F66', color: '#F4F4F4', border: '1px' }}> <b style={{ fontSize: '14px' }}>Sign in</b> </a>
          </div>
        </div>
      </Row>
    </Header>
  )
}
