import { Button, Col, Collapse, Form, Input, Modal, Radio, Row, notification } from 'antd'
import { DatesFormItem } from '../../../../common/components/helper-form/DatesFormItem'
import { PlusOutlined } from '@ant-design/icons'
import { useGetCampus } from '../../../../bussines/hooks/school/useGetCampus'
import { useEffect, useState } from 'react'
import utilsState from '../../../../bussines/utils/state-control'
import { ConceptCostFormItem } from '../components/ConceptCostFormItem'
import { HeaderFormCreateCourse } from '../components/HeaderFormCreateCourse'
import { MaterialsPricesFormItem } from '../components/MaterialsPricesFormItem'
import { ConceptCostFrequencyListFormItem } from '../components/ConceptCostFrequencyListFormItem'
import { SelectStudentLocation } from '../../../../common/components/helper-form/SelectStudentLocation'
import { HighSeasonsFormItem } from '../components/HighSeasonsFormItem'
import { AddAndRemoveListButtons } from '../components/AddAndRemoveListButtons'
import TextArea from 'antd/lib/input/TextArea'
import { useGetBasePricesByProgram } from '../../../../bussines/hooks/school/useGetBasePricesByProgram'
import { useLocation, useNavigate } from 'react-router-dom'
import { URL_COURSE_CREATE_PAGE } from '../Courses/CreateCoursePage'
import { ExceptionStepController } from '../../../register/components/ExceptionStepController'
import { useValidation } from '../../../../providers/validation/validations-provider'
import { SelectCampusAndYearByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusAndYearByProgramFormItem'
import { useFindProgram } from '../../../../bussines/hooks/program/useFindProgram'
import { SelectCountriesByRegionFormItem } from '../../../../common/components/places-helper-form/SelectCountriesByRegionFormItem'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ModalUserAlias } from '../../../../common/components/ModalUserAlias'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'
import { formatStringDate } from '../../../../utils/formatStringDate'
import { stringDateToMoment } from '../../../../utils/formatMoment'

const initialFees = [
  { name: 'Registration Fee', cost: 0, frequency: 'unic' }
]

const { Panel } = Collapse

function FormCreatePrices ({ program }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()

  const { campusId: campusID, yearId: yearID } = location.state ?? { campusId: undefined, yearId: undefined }

  const [openModalAlias, setOpenModalAlias] = useState(false)
  const [campusId, setCampusId] = useState(campusID)
  const [programId, setProgramId] = useState()
  const [yearId, setYearId] = useState(yearID)
  const [divisa, setDivisa] = useState('USD')
  const [redirect, setRedirect] = useState(false)
  const [materialConfirm, setMaterialConfirm] = useState(false)
  const [programName, setProgramName] = useState(program)
  const [activePanels, setActivePanels] = useState([1, 2, 3, 4, 5, 6, 7, 8])
  const [, setEnableBtn] = useState('false')
  const [switchMandatories, setSwitchMandatories] = useState([])
  const [switchMandatories2, setSwitchMandatories2] = useState([])
  const [valiatedData, setValiatedData] = useState({})
  const [isNewData, setisNewData] = useState(false)

  const { savePrices, isLoading, currentState } = useValidation()
  const { data: campus, isLoading: isLoadingCampus } = useGetCampus(campusId)
  const { data: programData, isLoading: isLoadingProgram } = useFindProgram(program)
  const { data: basePrices, isLoading: isLoadingPrices, refetch, isFetching } = useGetBasePricesByProgram(campusId, programId, yearId)

  const materials = Form.useWatch('materials', form)
  const insurance = Form.useWatch('insurance', form)
  const hs = Form.useWatch('high_season', form)

  const onFinish = (values) => {
    // return console.log('🚀 ~ onFinish ~ values:', values)
    let funciona = true
    let isAllOk = true
    if (values?.weeks) {
      const weeks = values?.weeks
      weeks.forEach(week => {
        if (week.cost === 0) {
          isAllOk = false
        }
      })
    } else if (!values?.cost || values?.cost > 0) {
      isAllOk = true
    } else {
      isAllOk = false
    }
    if (values?.high_seasons?.length > 0) {
      values.high_seasons.forEach(item => {
        if (item.hs_end < item.hs_start) {
          funciona = false
        }
      })
    }
    if (values.holidays?.length > 0) {
      values.holidays = values.holidays.map(d => {
        const fDate = formatStringDate(d.date, 'yyyy-LL-dd')
        return {
          ...d,
          date: fDate
        }
      })
    }
    if (funciona && isAllOk) {
      values.toAprove = redirect
      if (values?.insurances && values?.insurances?.length > 0) {
        values?.insurances?.forEach((item, index) => {
          item.mandatory = switchMandatories[index]
        })
      }
      if (!values.program_id) {
        values.program_id = programData?.id
      }
      setValiatedData(values)
      setOpenModalAlias(true)
    } else if (!isAllOk) {
      notification.error({
        message: 'Please enter a valid value for the cost of the course.'
      })
    } else {
      notification.error({
        message: 'You cannot enter an end date earlier than the start date'
      })
    }
  }

  const onSave = () => {
    setRedirect(false)
  }

  const onAprove = () => {
    setRedirect(true)
  }

  const onChangeHs = ({ campus_id: id, year_id: yearId, materials, region }) => {
    if (id !== undefined) {
      setCampusId(id)
      setYearId()
      form.setFieldsValue({ year_id: null })
    }
    if (yearId !== undefined) setYearId(yearId)
    if (region !== undefined) form.setFieldsValue({ countries: undefined })
    if (materials === true) {
      setMaterialConfirm(false)
    }
  }

  const handleMandatory = async (value, index, lengthArray) => {
    // const newValue = []
    if (value) {
      const array = Array(lengthArray).fill(false)
      array[index] = value
      setSwitchMandatories(array)
    } else {
      switchMandatories[index] = value
      setSwitchMandatories(currentValue => {
        currentValue[index] = value
        return currentValue
      })
    }
  }

  const handleMandatory2 = async (value, index, lengthArray) => {
    setSwitchMandatories2(currentValue => {
      currentValue[index] = value
      return currentValue
    })
  }

  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      // const nameTraslated = langTranslations.find(translation => translation.attribute === 'label')
      const data = basePrices
      const formValues = { ...data }
      formValues.fees = data?.fees?.map(fee => {
        const nameTraslated = fee.translations?.find(translation => translation.to === lang)
        return {
          ...fee,
          name: nameTraslated?.translation
        }
      })
      formValues.materials_prices = data?.materials_prices?.map(fee => {
        const nameTraslated = fee.translations?.find(translation => translation.to === lang)
        return {
          ...fee,
          name: nameTraslated?.translation
        }
      })
      formValues.insurances = data?.insurances?.map(fee => {
        const nameTraslated = fee.translations?.find(translation => translation.to === lang)
        return {
          ...fee,
          name: nameTraslated?.translation
        }
      })
      formValues.extras = data?.extras?.map(fee => {
        const nameTraslated = fee.translations?.find(translation => translation.to === lang && translation.attribute === 'name')
        const descriptionTraslated = fee.translations?.find(translation => translation.to === lang && translation.attribute === 'description')
        return {
          ...fee,
          name: nameTraslated?.translation,
          description: descriptionTraslated?.translation
        }
      })
      formValues.notes = data.translations?.find(translation => translation.to === lang && translation.attribute === 'comments')?.translation
      formValues.high_seasons = data.high_seasons?.map(hs => {
        const fees = hs?.fees?.map(fee => {
          const nameTraslated = fee.translations?.find(translation => translation.to === lang)
          return {
            ...fee,
            name: nameTraslated?.translation
          }
        })
        return {
          ...hs,
          fees
        }
      })
      formValues.includes = data?.includes?.map(included => {
        const nameTraslated = included.translationables?.find(translation => translation.to === lang)
        return {
          ...included,
          name: nameTraslated?.translation
        }
      })
      formValues.translation = lang
      form.setFieldsValue(formValues)
    } else {
      setFormData()
    }
  }

  const setFormData = () => {
    const panels = ['1', '2']
    const formValues = { ...basePrices }
    // const formValues = basePrices
    if (basePrices.fees?.length === 0) formValues.fees = initialFees

    formValues.holidays = basePrices.holidays?.map(h => {
      return {
        ...h,
        date: stringDateToMoment(h.date)
      }
    })

    if (basePrices.materials_prices?.length === 0) {
      formValues.materials_prices = [{}]
      formValues.materials = false
    } else {
      formValues.materials = true
      panels.push('3')
    }

    if (basePrices.high_seasons?.length === 0) {
      formValues.high_seasons = [{}]
      formValues.high_season = false
    } else {
      formValues.high_season = true
      panels.push('4')
    }
    if (basePrices.insurances?.length === 0) {
      formValues.insurances = [{}]
      formValues.insurance = false
    } else {
      formValues.insurance = true
      panels.push('5')
    }
    if (basePrices.extras?.length > 0) panels.push('6')
    if (basePrices.includes?.length > 0) panels.push('7')
    if (basePrices.holidays?.length > 0) panels.push('8')
    if (basePrices.notes?.length > 0) panels.push('9')

    formValues.translation = null
    form.setFieldsValue(formValues)
    setActivePanels(panels)
    setisNewData(true)
  }

  // Redirect on save
  useEffect(() => {
    if (!isLoading && currentState === utilsState.STATE_SUCCESS) {
      const campuses = form.getFieldValue('campuses')
      let message = redirect ? 'Precios aprobados' : 'Precios guardados'
      redirect && campuses?.length > 0 ? message += ' para ' + campus?.name + ' y guardados para los otros campus' : message += ''

      form.resetFields()
      modal.success({
        title: 'Success!',
        content: message,
        onOk () {
          if (redirect) {
            navigate(
              URL_COURSE_CREATE_PAGE.replace(':program', program),
              { state: { menuItemSelected: program + '_courses_create', openSub: program, openSub2: program + '_courses' } }
            )
          } else {
            refetch()
            setOpenModalAlias(false)
          }
        }
      })
    }
  }, [isLoading, currentState])

  // set divisa of the campus
  useEffect(() => {
    if (!isLoadingCampus && campus) {
      form.setFieldsValue({ divisa_code: campus?.divisa_code })
      setDivisa(campus?.divisa_code)
    }
  }, [campus])

  useEffect(() => {
    if (!isLoadingPrices && basePrices) {
      setFormData()
    }
  }, [isLoadingPrices, basePrices])

  useEffect(() => {
    if (!isLoadingProgram && programData) {
      form.setFieldsValue({ program_id: programData?.id })
      setProgramId(programData?.id)
    }
  }, [programData, isLoadingProgram])

  useEffect(() => {
    setProgramName(program)
  }, [program])

  return (
    <ExceptionStepController usePostMethod={useValidation}>
      <Form
        layout='horizontal'
        scrollToFirstError
        name='FormCreateQuote'
        onFinish={onFinish}
        form={form}
        onValuesChange={onChangeHs}
        initialValues={{
          campus_id: campusId,
          year_id: yearId
        }}
        requiredMark={false}
      >
        {contextHolder}
        <div className='byp-dashboard-content'>
          <Row>
            <Col span={18}>
              <h6>
                <b><i>Let's get start to upload the prices! The base prices are the general course prices:</i></b>
              </h6>
            </Col>
            <Col span={6}>
              <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={7} className='byp-text-sm byp-fw-bold'>You're at:</Col>
                <Col xs={12} md={11}>
                  <Form.Item className='margin-0'>
                    <Input className='graduate_show_category--input' value={programData?.label ?? '__________'} readOnly size='small' />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <SelectCampusAndYearByProgramFormItem program={program} label={programData?.label} campusID={campusId} />
          {
            isLoadingPrices && <LottieCargando />
          }
          <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '.5rem' }}>
            <SelectAvailableTranslationsFormItem
              name='translation'
              formItemProps={{
                noStyle: true
              }}
              selectProps={{
                size: 'small',
                style: { minWidth: '8rem' },
                onChange: (lang) => handleChangeTranslateLanguage(lang),
                allowClear: true
              }}
            />
          </div>
          {
            campusId && programId && yearId && !isLoadingPrices && (
              <div>
                <Collapse
                  defaultActiveKey={activePanels}
                >
                  <Panel header='Applicable nationalities' key='1'>
                    <SelectCountriesByRegionFormItem form={form} />
                    <SelectStudentLocation
                      name='student_location'
                      label='Student location'
                    />
                  </Panel>
                  <Panel header='Initial Costs' key='2'>
                    <Row>
                      <Col xs={6} lg={3} className='byp-pt-1'> Currency: </Col>
                      <Col xs={18} lg={8}>
                        <Form.Item
                          name='divisa_code'
                        >
                          <Input value={divisa} readOnly disabled />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <ConceptCostFrequencyListFormItem
                          name='fees'
                          divisa={divisa}
                          form={form}
                          program={programName}
                        />
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header='Materials costs' key='3'>
                    <Row>
                      <Col span={24} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        Include course materials?
                        <Form.Item
                          name='materials'
                          style={{ margin: '0' }}
                        >
                          <Radio.Group className='float-right'>
                            <Radio value> Yes </Radio>
                            <Radio value={false}> No </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {!materials && !materialConfirm && (
                          <>
                            <p>Are the materials included?</p>
                            <Button
                              className='byp-btn-blue-100'
                              onClick={() => setMaterialConfirm(true)}
                            > Yes
                            </Button>
                            <Button
                              className='byp-btn-blue-200'
                              onClick={() => form.setFieldsValue({ materials: true })}
                            > No
                            </Button>
                          </>
                        )}
                        {materials && (
                          <MaterialsPricesFormItem name='materials_prices' divisa={divisa} form={form} program={programName} />
                        )}
                      </Col>
                    </Row>
                  </Panel>
                  {programName !== 'academic_years' && programName !== 'graduate' && programName !== 'boarding_schools' && (
                    <Panel header='Additional costs for peak season' key='4'>
                      <Row>
                        <Col span={24} style={{ marginBottom: '24px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                          Do you have additional costs in certain season?
                          <Form.Item
                            name='high_season'
                            style={{ margin: '0' }}
                          >
                            <Radio.Group>
                              <Radio value key={1}> Yes </Radio>
                              <Radio value={false} key={0}> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        {hs && <HighSeasonsFormItem name='high_seasons' divisa={divisa} setEnableBtn={setEnableBtn} />}
                      </Row>
                    </Panel>
                  )}
                  <Panel header='Medical Insurance' key='5'>
                    <Row>
                      <Col span={24} style={{ marginBottom: '24px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        Do you want to add medical insurance?
                        <Form.Item
                          name='insurance'
                          style={{ margin: '0' }}
                        >
                          <Radio.Group>
                            <Radio value key={1}> Yes </Radio>
                            <Radio value={false} key={0}> No </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {insurance && (
                          <ConceptCostFormItem
                            name='insurances'
                            divisa={divisa}
                            labelAdd='Add'
                            labelDelete='Delete'
                            program={programName}
                            form={form}
                            handleMandatory={handleMandatory}
                            switchMandatories={switchMandatories}
                            setSwitchMandatories={setSwitchMandatories}
                          />
                        )}
                      </Col>
                    </Row>
                  </Panel>
                  {programName !== 'academic_years' && programName !== 'graduate' && programName !== 'boarding_schools' && (
                    <Panel header='Additional costs' key='6'>
                      <Row>
                        <Col span={24} className='byp-fw-bold byp-mb-1'>Add any other cost you need </Col>
                        <Col span={24}>
                          <ConceptCostFormItem
                            name='extras'
                            divisa={divisa}
                            labelAdd='Add'
                            labelDelete='Delete'
                            isPackage
                            program={programName}
                            handleMandatory={handleMandatory2}
                            switchMandatories={switchMandatories2}
                            setSwitchMandatories={setSwitchMandatories2}
                            isNewData={isNewData}
                            setisNewData={setisNewData}
                          />
                        </Col>
                      </Row>
                    </Panel>
                  )}
                  <Panel header='Additional information' key='7'>
                    <h6 className='byp-mt-1'><b>Free concepts that include your costs, that you want to emphasize</b></h6>
                    <Row style={{ width: '100%' }}>
                      <Form.List
                        name='includes'
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Row key={key} style={{ width: '100%' }} gutter={16}>
                                <Col span={20}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                  >
                                    <Input
                                      className='byp-input'
                                    />
                                  </Form.Item>
                                </Col>
                                <AddAndRemoveListButtons
                                  fields={fields}
                                  index={index}
                                  add={add}
                                  remove={remove}
                                  name={name}
                                />
                              </Row>
                            ))}
                            {
                              fields.length === 0 && (
                                <div className='w-100'>
                                  <Form.Item>
                                    <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                                      Add
                                    </Button>
                                  </Form.Item>
                                </div>
                              )
                            }
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Panel>
                  <Panel header='Holidays' key='8'>
                    <DatesFormItem name='holidays' />
                  </Panel>
                  <Panel header='Additional comments' key='9'>
                    <Form.Item name='notes'>
                      <TextArea />
                    </Form.Item>
                  </Panel>
                  {/* {programName !== 'academic_years' && (
                  )} */}
                </Collapse>
                <HeaderFormCreateCourse
                  name='campuses'
                  courseCategory='Idioma'
                  campusId={campusId}
                  programId={programId}
                  filters={{ year: yearId }}
                  form={form}
                />
              </div>
            )
          }
        </div>
        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              <Button
                className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
                htmlType='submit'
                loading={isLoading || isFetching}
                onClick={onAprove}
              >Approve
              </Button>
              <Button
                className='byp-btn-blue-200 float-right float-right byp-mt-1'
                onClick={onSave}
                htmlType='submit'
                loading={isLoading || isFetching}
              >Save
              </Button>
              <Button
                className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
                onClick={() => window.history.back()}
              >Return
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <ModalUserAlias
        isModalOpen={openModalAlias}
        setIsModalOpen={setOpenModalAlias}
        onSuccess={(userAlias) => {
          valiatedData.user_alias = userAlias
          console.log('🚀 ~ file: FormCreatePrices.js:492 ~ FormCreatePrices ~ valiatedData:', valiatedData)
          savePrices(valiatedData)
        }}
        isLoading={isLoading}
      />
    </ExceptionStepController>
  )
}

export { FormCreatePrices }
