import { addDays, differenceInDays, differenceInWeeks, format } from 'date-fns'
import { isSameOrAfter } from '../../../utils/isSameOrAfter'
import { isSameOrBefore } from '../../../utils/isSameOrBefore'
import { datesAreInPeackSeason } from '../../../utils/PeakSeasonDatesFunctions'
import { formatStringDate } from '../../../utils/formatStringDate'

const dayNumber = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
}

// Calculate the difference in weeks of the date 1 + first friday to date 2
/**
 *
 * @param {*} date1 start date
 * @param {*} date2 end date
 * @param {*} endWeek end day of the week
 * @returns int
 */
function difInWeeks (date1, date2, endWeek = 'Friday') {
  const startDay = formatStringDate(date1, 'EEEE')
  const day = dayNumber[startDay]
  let daysToFriday = 7 - day
  if (daysToFriday < 0) {
    daysToFriday = daysToFriday + 7
  }
  const endOfFirstWeek = addDays(new Date(date1), daysToFriday)
  const difInWeekss = differenceInWeeks(endOfFirstWeek, new Date(date2))
  return difInWeekss
}

function calculateHSCosts (highSeason, weeks) {
  const standard = highSeason.hs_cost ?? 0
  const extra = highSeason.hs_extra_cost ?? 0
  const minor = highSeason.hs_minor_cost ?? 0
  const costs = {
    standard_cost: standard * weeks,
    extra_cost: extra * weeks,
    minor_cost: minor * weeks
  }
  return costs
}

function getHSCosts (highSeason) {
  const costs = {
    standard_cost: highSeason.hs_cost ?? 0,
    extra_cost: highSeason.hs_extra_cost ?? 0,
    minor_cost: highSeason.hs_minor_cost ?? 0
  }
  return costs
}

function calculateAccomodationHGS (startDate, endDate, highSeasons, endWeek = 5) {
  const CostsByHs = []
  const Costs = {
    standard_cost: 0,
    extra_cost: 0,
    minor_cost: 0,
    weeks: 0
  }
  for (const highSeason of highSeasons) {
    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
    isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      const nWeeks = difInWeeks(highSeason.hs_end, highSeason.hs_start, endWeek)
      const nDays = differenceInDays(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      // console.log(
      //   'first option',
      //   '\n HS id: ', highSeason.id,
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 7
      // )
      const hsCosts = calculateHSCosts(highSeason, nWeeks)
      CostsByHs.push({
        id: highSeason.id,
        costs: getHSCosts(highSeason),
        weeks: nWeeks,
        daysOff: nDays % 7,
        start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
        start_formated: highSeason.hs_start_formated,
        end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
        end_formated: highSeason.hs_end_formated,
        fees: highSeason.fees,
        days: differenceInDays(new Date(highSeason.hs_end), new Date(highSeason.hs_start))
      })
      Costs.standard_cost += hsCosts?.standard_cost
      Costs.extra_cost += hsCosts?.extra_cost
      Costs.minor_cost += hsCosts?.minor_cost
      Costs.weeks += nWeeks
      continue
    }

    if (isSameOrAfter(new Date(highSeason.hs_start), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_start), new Date(endDate))
    ) {
      const nWeeks = difInWeeks(endDate, highSeason.hs_start, endWeek)
      const nDays = differenceInDays(new Date(endDate), new Date(highSeason.hs_start))
      // console.log(
      //   'Second option',
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 7
      // )
      const hsCosts = calculateHSCosts(highSeason, nWeeks)
      Costs.standard_cost += hsCosts?.standard_cost
      Costs.extra_cost += hsCosts?.extra_cost
      Costs.minor_cost += hsCosts?.minor_cost
      Costs.weeks += nWeeks
      CostsByHs.push({
        id: highSeason.id,
        costs: getHSCosts(highSeason),
        weeks: nWeeks,
        daysOff: nDays % 7,
        start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
        end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
        start_formated: highSeason.hs_start_formated,
        end_formated: highSeason.hs_end_formated,
        fees: highSeason.fees,
        days: differenceInDays(new Date(endDate), new Date(highSeason.hs_start))
      })
      continue
    }

    if (isSameOrAfter(new Date(highSeason.hs_end), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
    ) {
      const nWeeks = difInWeeks(highSeason.hs_end, startDate, endWeek)
      const nDays = differenceInDays(new Date(highSeason.hs_end), new Date(startDate))
      // console.log(
      //   'Third option',
      //   '\n HS id: ', highSeason.id,
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 8
      // )
      const hsCosts = calculateHSCosts(highSeason, nWeeks)
      Costs.standard_cost += hsCosts?.standard_cost
      Costs.extra_cost += hsCosts?.extra_cost
      Costs.minor_cost += hsCosts?.minor_cost
      Costs.weeks += nWeeks
      CostsByHs.push({
        id: highSeason.id,
        costs: getHSCosts(highSeason),
        weeks: nWeeks,
        daysOff: nDays % 7,
        start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
        end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
        start_formated: highSeason.hs_start_formated,
        end_formated: highSeason.hs_end_formated,
        fees: highSeason.fees,
        days: differenceInDays(new Date(highSeason.hs_end), new Date(startDate))
      })
      continue
    }
    // The High Season is still valid and continue throug the course dates
    /**
     * H ----------------------------- S
     *       a-------------c
     */
    if (datesAreInPeackSeason(startDate, endDate, highSeason)) {
      const nWeeks = difInWeeks(endDate, startDate, endWeek)
      const nDays = differenceInDays(new Date(endDate), new Date(startDate))
      // console.log(
      //   'Fourt option',
      //   '\n HS id: ', highSeason.id,
      //   '\n nWeeks', nWeeks,
      //   '\n nDays', nDays,
      //   '\n nDaysOff', nDays % 7,
      //   '\n start: ', new Date(highSeason.hs_start),
      //   '\n endDate: ', new Date(highSeason.hs_end)
      // )
      const hsCosts = calculateHSCosts(highSeason, nWeeks)
      Costs.standard_cost += hsCosts?.standard_cost
      Costs.extra_cost += hsCosts?.extra_cost
      Costs.minor_cost += hsCosts?.minor_cost
      Costs.weeks += nWeeks
      CostsByHs.push({
        id: highSeason.id,
        start: formatStringDate(highSeason.hs_start, 'yyyy-LL-dd'),
        end: formatStringDate(highSeason.hs_end, 'yyyy-LL-dd'),
        start_formated: highSeason.hs_start_formated,
        end_formated: highSeason.hs_end_formated,
        costs: getHSCosts(highSeason),
        weeks: nWeeks,
        daysOff: nDays % 7,
        fees: highSeason.fees,
        days: differenceInDays(new Date(endDate), new Date(startDate))
      })
    }
  }
  Costs.details = CostsByHs
  return Costs
}

export { calculateAccomodationHGS }
