import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

function useGetCustomQuoteView (campus, type) {
  return useQuery(['useGetCustomQuoteView', campus, type],
    () => QuoteRepository.getCustomedQuoteView(campus, type), {
      retry: 2,
      disabled: !!campus || !!type
    })
}

export { useGetCustomQuoteView }
