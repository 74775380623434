import React, { useState } from 'react'
import { Button, Modal, Row, Input, Avatar, Col } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
// import { IconCalendar, IconStartFilled, IconDeleteFilled } from '../../../common/assets/svg/icons'
import { format, toDate } from 'date-fns'
import { URL_COPY } from '../../../config'
import { ApplyProgramModal } from '../../quote-preview/components/ApplyProgramModal'
import { RateQuoteComponent } from './RateQuoteComponent'
import { ButtonDeleteStudenIdFromQuote } from './ButtonDeleteStudenIdFromQuote'
import { SendedIcon } from '../../quote-preview/assets/imgs/Icons'
import { convertMxToUsDateFormat } from '../../../utils/formatStringDate'

function getIntakeFormat (intake) {
  const meses = [
    'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
    'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
  ]
  const formatoFecha = /^\d{2}\/\d{4}$/

  if (formatoFecha.test(intake)) {
    const mes = Number(intake.split('/')[0]) - 1
    const year = intake.split('/')[1]
    return `${meses[mes]} ${year}`
  }

  return intake
}

function CourseCompleteDisplayed ({ client, newQuote, quote, isActive, refetch, authUser }) {
  const [mdl, setMdl] = useState()

  const opnMdl = () => {
    setMdl(true)
  }

  const clsMdl = () => {
    setMdl(false)
  }

  const [clipboardValue, setClipBoardValue] = useState('')

  function PasteText () {
    navigator.clipboard.writeText(clipboardValue)
  }

  const handleFormatMoney = (cost, discount) => {
    if (discount) {
      cost -= discount
    }
    return cost?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleFormatDate = (date, isArrival) => {
    const fecha = new Date(date)
    let fechaUTC = null

    if (isArrival) {
      const date = fecha.getTime() - fecha.getTimezoneOffset() * 60 * 1000
      fechaUTC = new Date(date)
    } else {
      const date = fecha.getTime() + fecha.getTimezoneOffset() * 60 * 1000
      fechaUTC = new Date(date)
    }

    const meses = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]

    const mesAbreviado = meses[fechaUTC.getUTCMonth()]

    const dia = fechaUTC.getUTCDate()
    const año = fechaUTC.getUTCFullYear()

    const fechaFormateada = `${mesAbreviado} ${dia},${año}`
    return fechaFormateada
  }

  const seequote = event => {
    const value = event.currentTarget.value
    window.open(value)
  }

  function getStartLabel (start, program) {
    if (program === 'academic_years' || program === 'boarding_schools') {
      try {
        const date1 = new Date(start)
        const date = toDate(date1)
        date.setUTCDate(date.getDate())
        const date2 = new Date(date)
        return format(date2, 'MM/yyyy')
      } catch (error) {
        const date1 = new Date(convertMxToUsDateFormat(start))
        const date = toDate(date1)
        date.setUTCDate(date.getDate())
        const date2 = new Date(date)
        return format(date2, 'MM/yyyy')
      }
    }
    return start
  }

  function getDate (itemDate) {
    const date = new Date(itemDate)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Obt', 'Nov', 'Dec']
    return `${date.getUTCDate()} ${months[date.getMonth()]}`
  }
  
  return (
    <div style={{ paddingBottom: '30px' }}>
      <Col style={{ borderRadius: '50px', display: 'flex', boxShadow: 'rgb(0 0 0 / 0%) 0px 1px 2px -2px, rgb(0 0 0 / 0%) 0px 3px 6px 0px, rgb(0 0 0 / 11%) -4px 6px 7px 0px' }} className='row-to-column'  > 
        <Col span={24} lg={8}>
          <img className='course-img' src={quote?.campus_img} alt={quote?.campus_name} />
        </Col>
        <Col span={24} lg={16} className='client-quotes-second-section'>
          <div className='client-quotes-title-section-mobile'>

            <Col lg={ 10 } className='course-title' style={{ whiteSpace: 'nowrap' }}>
              {quote?.campus_name}
            </Col>

            {client
              ? (
                <Col class='client-quotes-stars-section'>
                  <RateQuoteComponent quote={quote} />
                  <TextBlue text1={`${getDate(quote?.created_at)} - ${getDate(quote?.expiration)}`} text2={isActive ? 'ACTIVE' : 'INACTIVE'} isActive={isActive} />
                </Col>)
              : (newQuote
                  ? (
                    <Row className='client-quotes-stars-section'>
                      <TextBlue text1={`${getDate(quote?.created_at)} - ${getDate(quote?.expiration)}`} text2={isActive ? 'ACTIVE' : 'INACTIVE'} isActive={isActive} />
                      <Col>
                        {authUser && <ButtonDeleteStudenIdFromQuote quoteId={quote?.id} refetch={refetch} />}
                      </Col>
                    </Row>)
                  : (
                    <Row className='client-quotes-stars-section'>
                      <Col style={{ display: 'flex' }}>
                        <RateQuoteComponent quote={quote} />
                      </Col>
                      <TextBlue text1={`${getDate(quote?.created_at)} - ${getDate(quote?.expiration)}`} text2={isActive ? 'ACTIVE' : 'INACTIVE'} isActive={isActive} />
                      <Col>
                        {authUser && <ButtonDeleteStudenIdFromQuote quoteId={quote?.id} refetch={refetch} />}
                      </Col>
                    </Row>))}
          </div>
          <Row style={{ marginRight: '4vw', display: 'flex', flexDirection: 'column' }}>

            <Col style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }} className='byp-mt-0-25'>{quote?.course}</Col>
            {
              (!quote?.duration && quote?.weeks) && (<Row className='byp-my-0-25' style={{ fontSize: '1.125rem', color: 'var(--blue-100)', fontWeight: 'bold' }}>{quote?.weeks} weeks</Row>)
            }
            {
              quote?.duration && (<Row className='byp-my-0-25' style={{ fontSize: '1.125rem', color: 'var(--blue-100)', fontWeight: 'bold' }}>{quote?.duration}</Row>)
            }

            <Row className='byp-my-0-25'> <small>{quote?.course_description}</small></Row>

            <Row style={{ fontSize: '1.25rem', color: 'var(--blue-100)' }} className='byp-my-0-25'>
              <Col>
                <b>{handleFormatMoney(quote?.cost, quote?.discount)} {quote?.totals?.totals[0].divisa}</b>
              </Col>
              <Col>
                <small>&nbsp;/ TOTAL</small>
                {(quote?.totals?.institution_cost_in_agency_currency && authUser?.type === 'Agency') && (
                  <span style={{ fontSize: '.75rem' }} className='byp-ml-1'>
                    Aprox {quote?.totals?.institution_cost_in_agency_currency} {quote?.totals?.agency_currency}
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <small>'This prices are for reference only</small>
              </Col>
            </Row>

            {quote?.type !== 'package' && (
              <Row style={{ display: 'flex', gap: '1vw', paddingBottom: '20px' }} className='byp-my-0-25'>
                {(quote?.name_program === 'language' || quote?.name_program === 'summer_camps') && (
                  <>
                    {(quote?.arrival && quote?.departure) && <ButtonsDouble text1='Arrival' text2='Departure' textInside1={handleFormatDate(quote?.arrival, true)} textInside2={handleFormatDate(quote?.departure, true)} />}
                    <ButtonsDouble className='client-quotes-buttons-double' text1='Start classes' text2='End classes' textInside1={handleFormatDate(quote?.start_date)} textInside2={handleFormatDate(quote?.end_date)} />
                  </>
                )}
                {(quote?.name_program === 'academic_years' || quote?.name_program === 'boarding_schools' || quote?.name_program === 'graduate') && !quote?.start_date && (
                  <ButtonOnly text1='Intake' textInside1={getStartLabel(quote?.intake, quote?.name_program)} />
                )}
                {(quote?.name_program === 'academic_years' || quote?.name_program === 'boarding_schools' || quote?.name_program === 'graduate') && quote?.start_date && (
                  <ButtonsDouble text1='Intake' text2='Specific date' textInside1={getStartLabel(quote?.intake, quote?.name_program)} textInside2={handleFormatDate(quote?.start_date)} />
                )}
              </Row>

            )}
            {(quote?.type === 'package' || quote?.type === 'short') && (
              <Row style={{ display: 'flex', gap: '1vw', paddingBottom: '20px' }} className='byp-my-0-25'>
                <ButtonsDouble
                  text1='Start date'
                  text2='Program length'
                  textInside1={quote?.start_date}
                  textInside2={quote?.weeks + ' weeks'}
                />
              </Row>
            )}

            {newQuote ?? (
              <div className='client-quotes-height'>
                <div style={{ height: '3px', backgroundColor: '#CDDFFF' }} />
                <Row style={{ display: 'flex', padding: '10px 0' }} gutter={60}>
                  <Col className='client-quotes-buttoms'>
                    <div className='course-agent'>Agent {quote?.agent.name}</div>
                  </Col>
                  <Col className='client-quotes-buttoms-book'>
                    <ApplyProgramModal quoteUuid={quote.uuid} courseName={quote.course} campusName={quote.campus_name} logo={quote.campus_img} botonBook />
                  </Col>
                  <Row className='client-quotes-see-share' gutter={10}>
                    <Col span={12}>
                      <Button value={`${URL_COPY}/quote-preview/${quote?.uuid}`} onClick={seequote} style={{ backgroundColor: 'var(--blue-100)', color: 'white', height: '40px', width: '100px', fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', width: '100%' }} icon={<SendedIcon className='course-icon' />}> See</Button>
                    </Col>
                    <Col span={12}>
                      <Button onClick={opnMdl} id='myInput' style={{ backgroundColor: '#FFCC00', height: '40px', width: '100px', fontSize: '1rem', color: 'var(--blue-200)', fontWeight: 'bolder', width: '100%' }}>Share</Button>
                      <Modal open={mdl} visible={mdl} footer='' onCancel={clsMdl}>
                        <p style={{ color: 'var(--blue-200)', fontWeight: '700' }}>Share quote</p>
                        <Row gutter={10} className='d-flex align-center'>
                          <Col>
                            <Avatar src={quote.campus_img} size={41} className='share-image byp-mb-1' />
                          </Col>
                          <Col>
                            <h4 className='byp-m-1' style={{ color: 'black' }}>{quote.campus_name},&nbsp;
                              {quote.course}
                            </h4>
                          </Col>
                        </Row>
                        <p style={{ textAlign: 'justify' }}>{quote?.course_description}</p>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                          <LinkOutlined style={{ fontSize: '20px' }} />
                          <Input
                            id='myInput'
                            value={`${URL_COPY}/quote-preview/${quote?.uuid}`} onFocus={(e) => setClipBoardValue(e.target.value)}
                            suffix={
                              <Button onClick={PasteText} style={{ color: 'var(--blue-100)', border: 'none', marginRight: '-10px' }}>COPY</Button>
                            }
                          />
                        </div>
                      </Modal>
                    </Col>
                  </Row>
                </Row>
              </div>
            )}

          </Row>
        </Col>
      </Col>
    </div>
  )
}

function ButtonsDouble ({ text1, text2, textInside1, textInside2 }) {
  return (
    <Row style={{ display: 'flex' }}>
      <Col style={{ borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }} className='course-btn-double'>
        <Col style={{ color: 'var(--blue-200)', fontSize: '.80rem' }}>
          {text1}
        </Col>
        <Col style={{ fontSize: '.80rem', marginBottom: '0' }}>
          {text1 === 'Intake' && (<b>{getIntakeFormat(textInside1)}</b>)}
          {text1 !== 'Intake' && (<b>{textInside1}</b>)}
        </Col>
      </Col>
      <Col style={{ borderTopRightRadius: '15px', borderBottomRightRadius: '15px' }} className='course-btn-double'>
        <Col style={{ color: 'var(--blue-200)', fontSize: '.80rem' }}>
          {text2}
        </Col>
        <Col style={{ fontSize: '.80rem', marginBottom: '0' }}>
          <b>{textInside2}</b>
        </Col>
      </Col>
    </Row>
  )
}

function ButtonOnly ({ text1, textInside1 }) {
  return (
    <div style={{ display: 'flex' }}>
      <div className='course-btn-only'>
        <p style={{ color: 'var(--blue-200)', fontSize: '.80rem' }}>
          {text1}
        </p>
        <p style={{ marginTop: '-15px', fontSize: '.80rem', marginBottom: '0' }}>
          {text1 === 'Intake' && (<b>{getIntakeFormat(textInside1)}</b>)}
          {text1 !== 'Intake' && (<b>{textInside1}</b>)}
        </p>
      </div>
    </div>
  )
}

function TextBlue ({ text1, text2, isActive }) {
  return (
    <Row>
      <Col className={!isActive ? 'text-gray-first' : 'text-blue-first'} style={{ padding: '2px 4px' }}>{text1}</Col>
      <Col className={!isActive ? 'text-gray-second' : 'text-blue-second'} style={{ padding: '2px 4px' }}>{text2}</Col>
    </Row>
  )
}

export { CourseCompleteDisplayed }
