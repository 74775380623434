import React, { useEffect, useState } from 'react'
import { Col, Row, Spin } from 'antd'
import { LanguageForm } from './forms-search/LanguageForm'
import { HighEducationForm } from './forms-search/HighEducationForm'
import { ButtonCategorySelector } from './ButtonsCategorySelector'
import { useGetMyServiceCategories } from '../../../bussines/hooks/school/useGetMyServiceCategories'
import { useSearch } from '../../../providers/search/search-context-provider'
import LottieCargando from '../../../lotties/LottieCargando'
import { HighSchoolForm } from './forms-search/HighSchoolForm'
import { AdvanceSearchModal } from './AdvancedSearchModal'
import { useLocation } from 'react-router-dom'
import { ShortTermForm } from './forms-search/ShortTermForm'

function getCollection (programs) {
  const menuItems = programs?.map((program) => (
    {
      label: program.label,
      key: program.name,
      form: <p>form</p>
    }
  ))
  return menuItems ?? []
}

const FilterCategory = ({ subType, staticPage = false, params }) => {
  const [collection, setCollection] = useState([])
  const [formSelected, changeFormSelect] = React.useState('')
  const { isLoading, data } = useGetMyServiceCategories()
  const { changeSearch, totalResults, isSearching } = useSearch()
  const [isActive, setIsActive] = useState(false)
  const [selected, setSelected] = useState('language')
  const location = useLocation()

  useEffect(() => {
    if (data && !isLoading) {
      const items = getCollection(data)
      const firstItem = items[0] ?? null
      if (firstItem?.key !== 'graduate') {
        changeFormSelect(firstItem?.key)
        setSelected(firstItem?.key)
      } else {
        setSelected('diploma')
        changeFormSelect(subType)
      }
      sortCollections(items)
      // Here makes the search at the home page by default
      changeSearch('LANG_FORM', {
        all: true,
        program: firstItem?.key
      })
    }
  }, [isLoading, data])

  useEffect(() => {
    if (data && !isLoading) {
      const items = getCollection(data)
      const firstItem = items[0] ?? null
      // console.log('🚀 ~ file: FilterCategory.js ~ line 86 ~ useEffect ~ firstItem', firstItem)
      if (firstItem?.key === 'graduate') {
        setSelected(subType)
        changeFormSelect(subType)
      }
    }
  }, [subType])

  useEffect(() => {
    if (location?.state?.program) {
      handleChangeProgram(location?.state?.program)
      setSelected(location?.state?.program)
    }
  }, [])

  const handleChangeProgram = (program) => {
    // console.log(
    //   '🚀 ~ file: FilterCategory.js:76 ~ handleChangeProgram ~ program:',
    //   program,
    //   getProgram(program),
    //   getType(program),
    //   getSubType(program)
    // )
    // console.log(program)
    changeFormSelect(program)
    changeSearch('LANG_FORM', {
      all: true,
      program: getProgram(program),
      type: getType(program),
      subtype: getSubType(program)
    })
  }

  const sortCollections = (data) => {
    const orden = ['language', 'academic_years', 'boarding_schools', 'graduate', 'short_term']
    let items = orden.map(orden => {
      return data.find(x => x.key === orden)
    })
    items = items.filter(item => item)

    setCollection(items)
  }

  const types = ['bachelor', 'postgraduate', 'master', 'diploma']
  const subtypes = ['diploma', 'advanced_diploma', 'certificate', 'graduate_diploma']
  const getProgram = (program) => {
    if (types.includes(program) || subtypes.includes(program)) {
      return 'graduate'
    }
    return program
  }

  const getType = (program) => {
    if (types.includes(program)) {
      return types[types.indexOf(program)]
    }
    if (subtypes.includes(program)) {
      return 'diploma'
    }
    return null
  }
  const getSubType = (program) => {
    if (subtypes.includes(program) && !program === 'graduate_diploma') {
      return subtypes[subtypes.indexOf(program)]
    }
    return null
  }

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <>
      <div style={{ paddingTop: '20px' }}>
        <div className='home-btns-responsive'>
          <ButtonCategorySelector collection={collection} onChange={handleChangeProgram} selected={selected} setIsActive={setIsActive} />
        </div>
        <div style={{ margin: '24px 8px' }}>
          {totalResults >= 0 && (
            <Row className='byp-centered byp-mb-1'>
              {totalResults > 0 && !isSearching && (
                <h6 className='byp-blue-100 byp-fw-bold'>Look among {totalResults} results!</h6>
              )}
              {totalResults === 0 && !isSearching && (
                <h6 className='byp-blue-100 byp-fw-bold'>No results</h6>
              )}
              {isSearching && (
                <h6 className='byp-blue-100 byp-fw-bold'>
                  <Spin />
                </h6>
              )}
            </Row>
          )}
          {!staticPage &&
            <Row>
              {formSelected === 'language' && <Col span={24}><LanguageForm /></Col>}
              {formSelected === 'academic_years' && <Col span={24}><HighSchoolForm program='academic_years' /></Col>}
              {formSelected === 'boarding_schools' && <Col span={24}><HighSchoolForm program='boarding_schools' /></Col>}
              {formSelected === 'short_term' && <Col span={24}><ShortTermForm /></Col>}

              {formSelected === 'graduate_diploma' && <Col span={24}><HighEducationForm type='diploma' /></Col>}
              {/* {formSelected === 'diploma' && <Col span={24}><HighEducationForm type='diploma' subType='diploma' /></Col>}
              {formSelected === 'advanced_diploma' && <Col span={24}><HighEducationForm type='diploma' subType='advanced_diploma' /></Col>} */}
              {/* {formSelected === 'certificate' && <Col span={24}><HighEducationForm type='diploma' subType='certificate' /></Col>} */}
              {formSelected === 'bachelor' && <Col span={24}><HighEducationForm type='bachelor' /></Col>}
              {formSelected === 'postgraduate' && <Col span={24}><HighEducationForm type='postgraduate' /></Col>}
              {formSelected === 'master' && <Col span={24}><HighEducationForm type='master' /></Col>}
            </Row>}
        </div>
      </div>

      <AdvanceSearchModal
        isActive={isActive}
        setIsActive={setIsActive}
        collection={collection}
        staticPage={staticPage}
        params={params}
      />
    </>
  )
}

export { FilterCategory }
