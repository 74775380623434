import { Col, Form, Row, Switch, Typography } from 'antd'
import React from 'react'
import { usePackage } from '../../../providers/package/package-course-context'

const { Text } = Typography
function ItemService ({ item }) {
  const { service, formConfig, setFormConfig } = usePackage()

  const handleChange = (value, id, cost, name) => {
    let agencyServices = []
    if (!formConfig.agencyServices) {
      setFormConfig({
        ...formConfig,
        agencyServices: []
      })
    }
    if (value) {
      agencyServices = formConfig.agencyServices ?? []
      agencyServices?.push({ id, cost: cost?.cost, name })
    } else {
      formConfig.agencyServices?.map((service) => (
        service.id !== id ? agencyServices.push(service) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      agencyServices
    })
  }

  return (
    <Row key={item.id} gutter={12} className='w-100'>
      <Col xs={24} md={18} lg={18}>
        <Text>{item.label}</Text>
        <Form.Item noStyle name={['services', item.name]}>
          <Switch
            onChange={(value) => { handleChange(value, item.id, item.costs[0] ?? null, item.name) }}
            className='byp-ml-1'
            checked={service.serviceIsChecked(item.id)}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={6} lg={6} className='byp-border-left byp-centered'>
        <Text>
          $ {service.getAgencyServiceSelectedCost(item.id)?.toFixed(2)} <span> </span>
          {service.getAgencyServiceCurrency(item.id)}
        </Text>
        {!service.getExchangeValue(service.getAgencyServiceCurrency(item.id)) && 'Exchange value not found'}
        {
          service.getExchangeValue(service.getAgencyServiceCurrency(item.id)) && (
            service.calculateExchangeValue(
              service.getAgencyServiceSelectedCost(item.id)?.toFixed(2),
              service.getExchangeValue(service.getAgencyServiceCurrency(item.id))
            )
          )
        }
        {' ' + service.getAgencyCurrency()}
      </Col>
      <hr className='byp-hr' />
    </Row>
  )
}

function AgencyCostSections () {
  const { service } = usePackage()
  return (
    <div style={{ marginTop: '20px' }}>{
      service.getAgencyMiscellaneous()?.map((item) => {
        return <ItemService key={item.id} item={item} />
      })
    }
    </div>
  )
}

export { AgencyCostSections }
