import { Row, Button, Col, Divider, Switch, Select, Form, Pagination, Modal } from 'antd'
import { useGetAgencies, useGetAgenciesNames } from '../../bussines/hooks/agency/useGetAgencies'
import { useEffect, useState } from 'react'
import { useGetAgencyInstitutionsCountries } from '../../bussines/hooks/agency/agency_institutions/useGetInstitutionsCountries'
import { crypt } from '../../bussines/utils/basicEncript'
import { URL_USER_AGENCIES_PAGE } from '../dashboards/Institution/analitics/AgenciesAnaliticsPage'
import { Link } from 'react-router-dom'
import { useActiveType } from '../../bussines/hooks/agency/agency_institutions/useActiveAgency'
import { useAuth } from '../../providers/auth/auth-context-provider'

function urlEncrypte (id) {
  let url = crypt('qyp2023', id.toString())
  url = URL_USER_AGENCIES_PAGE + '/agencies/:agencyId'.replace(':agencyId', url)
  return (url)
}

const { Option } = Select
function AgencyView ({ agency, refetch }) {
  const { activeType, isLoading } = useActiveType()
  const [isConsultant, setIsConsultant] = useState(false)
  const { user: _user } = useAuth()
  const [modal, context] = Modal.useModal()

  const handleStatus = (value, id) => {
    if (isConsultant) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    activeType({ type: 'disagreement', is_active: value, agency: id })
    refetch()
  }

  useEffect(() => {
    const role = _user?.roles
    if (role?.includes('Education Group Consultant')) {
      setIsConsultant(true)
    }
  }, [_user])

  return (
    <>
      {context}
      <Row style={{ backgroundColor: 'white', padding: '0.75rem', borderRadius: '10px', height: '100%' }}>
        <Col
          span={8} style={{ display: 'flex', justifyContent: 'center', marginTop: '0.625rem' }}
        >
          <img
            style={{ maxWidth: '80%', height: '110px' }}
            src={agency?.profile_photo_url}
            alt='byp'
          />
        </Col>
        <Divider
          type='vertical'
          style={{
            background: '#CDDFFF',
            padding: '55px 0',
            margin: '2.5vh 0',
            marginLeft: '10px'
          }}
        />

        <Col span={14} style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px' }}>
          <p
            style={{
              fontSize: '1vw',
              fontWeight: '700',
              color: 'var(--blue-200)',
              width: '100%',
              marginBottom: '0'
            }}
          >
            {agency?.name}
          </p>

          {(_user?.type === 'Education group' && !isConsultant) &&
            <Row>
              {agency?.email &&
                <Col span={24}>
                  <span className='byp-color-gray-100' style={{ fontSize: '0.75rem' }}>Contact</span>
                  <p className='byp-m-0 byp-blue-200 byp-text-md'>{agency?.email}</p>
                </Col>}
              {agency?.tel &&
                <Col span={24}>
                  <span className='byp-color-gray-100' style={{ fontSize: '0.75rem' }}>Phone</span>
                  <p className='byp-m-0 byp-blue-200 byp-text-md'>{agency?.tel}</p>
                </Col>}
            </Row>}

          <Switch
            className='byp-mt-1'
            style={{ width: 'fit-content' }}
            checkedChildren='AGREEMENT'
            unCheckedChildren='OFF'
            onChange={(e) => handleStatus(e, agency?.id)}
            checked={agency?.isactive}
            loading={isLoading}
          />
          <Button style={{ marginTop: '10px', color: 'var(--blue-100)', borderColor: 'var(--blue-100)', fontWeight: 'bold', backgroundColor: '#F4F4F4' }}>
            <Link
              to={urlEncrypte(agency?.id)}
              state={{ menuItemSelected: 'agencies-analysis', openSub: 'analitic-institution' }}
            >See more
            </Link>
          </Button>
        </Col>
      </Row>
    </>
  )
}

function AgenciesSearcher ({ setPage, setCountry, setAgency }) {
  const [searchCountries, setSearchCountries] = useState()
  const [searchAgencie, setSearchAgencies] = useState()
  const [searchCountry, setSearchCountry] = useState()

  const {
    data: countries
  } = useGetAgencyInstitutionsCountries(searchCountries)

  const { data: agencies } = useGetAgenciesNames()

  const handleSubmit = () => {
    setCountry(searchCountry)
    setAgency(searchAgencie)
    setPage(1)
  }

  const handleClear = () => {
    setCountry()
    setAgency()
    setSearchCountry()
    setSearchAgencies()
    setPage(1)
  }
  return (
    <Row gutter={20} style={{ paddingTop: '20px' }}>
      <Col style={{ color: 'var(--blue-100)', marginTop: '3px', fontSize: '15px', fontWeight: 'bold' }}>
        Search by
      </Col>
      <Col span={10}>
        <Form.Item label='Agency'>
          <Select onChange={(value) => setSearchAgencies(value)} value={searchAgencie}>
            {agencies?.map((agency) => (
              <Option key={agency.id} value={agency.id}>
                {`${agency.name}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={9}>
        <Form.Item label='Country'>
          <Select
            showSearch
            onSearch={(value) => setSearchCountries(value)}
            onChange={(value) => setSearchCountry(value)}
            value={searchCountry}
          >
            {countries?.map((item) => (
              <Option key={item.countryid} value={item.countryname}>
                {item.countryname}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col style={{ gap: '20px' }}>
        <Button
          style={{
            backgroundColor: 'lightgray',
            color: 'black',
            fontWeight: 'bold'
          }}
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          style={{
            backgroundColor: 'var(--blue-100)',
            color: 'white',
            fontWeight: 'bold'
          }}
          onClick={handleSubmit}
        >
          Search
        </Button>
      </Col>
    </Row>
  )
}

function AgenciesComponents () {
  const [agencies, setAgencies] = useState([])
  const [country, setCountry] = useState()
  const [agency, setAgency] = useState()
  const [page, setPage] = useState(1)
  // const [limit, setLimit] = useState(9)
  const { data, refetch } = useGetAgencies({ page, country, agency })

  // const handlePage = () => {
  //   if (data?.meta?.total > limit) {
  //     setLimit(currentLimit => currentLimit + 9)
  //   }
  // }

  useEffect(() => {
    // if (page !== 1 && agencies?.length === data?.meta.to) {
    //   setAgencies(currentAgencies => [...currentAgencies.slice(currentAgencies?.length - 10, currentAgencies?.length - 1), ...(Array.isArray(data?.data) ? data.data : [])])
    // }
    // if (page !== 1 && agencies?.length !== data?.meta.to) {
    //   setAgencies(currentAgencies => [...currentAgencies, ...(Array.isArray(data?.data) ? data.data : [])])
    // }
    // if (page === 1) {
    //   setAgencies(data?.data)
    // }
    setAgencies(data?.data)
  }, [data])
  return (
    <div style={{ padding: '0 5vw' }}>
      <h6 style={{ color: 'var(--blue-100)' }}>
        Select the agencies you want to see
      </h6>
      <AgenciesSearcher setPage={setPage} setCountry={setCountry} setAgency={setAgency} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3,1fr)',
          columnGap: '3vw',
          rowGap: '4vh',
          paddingTop: '10px'

        }}
      >
        {agencies?.map(agency => (
          <AgencyView key={agency.id} agency={agency} refetch={refetch} />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '30px'
        }}
      >
        {/* {data?.meta?.total > limit &&
          <Button
            style={{
              borderColor: 'var(--blue-100)',
              color: 'var(--blue-100)',
              fontWeight: 'bold',
              backgroundColor: '#F4F4F4'
            }}
            onClick={handlePage}
          >
            Load more
          </Button>} */}
        <Pagination
          defaultCurrent={page}
          pageSize={data?.meta?.per_page ?? 9}
          total={data?.meta?.total ?? 0}
          onChange={setPage}
          hideOnSinglePage
          showQuickJumper={false}
          showSizeChanger={false}
        />
      </div>
    </div>
  )
}

export { AgenciesComponents }
