import { useQuery } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function useGetAllUsers (filters = {}) {
  return useQuery(['useGetAllUsers', filters], () => AdminRepository.allUsers(filters), {
    retry: 1
  })
}

export { useGetAllUsers }
