import { useMutation } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'
import state from './../../utils/state-control'
import { useState } from 'react'

function useSaveExchangeValues () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.saveExchangeValues, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const saveExchangeValues = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, saveExchangeValues }
}

export { useSaveExchangeValues }
