import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

/**
 * Return the years an campus of a discount
 * @param {*} id
 * @returns [years => [], campus => []]
 */
function useGetDiscountYears (id) {
  return useQuery(['useGetDiscountYears', id],
    () => DiscountRepository.years(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useGetDiscountYears }
