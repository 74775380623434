import { Col, Form, Row, Switch } from 'antd'
import React, { useState } from 'react'

function ToggleFormItem ({ disabled = true, ...props }) {
  const [toggle, setToggle] = useState(false)
  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true)
  }

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item {...props} valuePropName='checked'>
          <Switch onClick={toggler} disabled={disabled} 
          checkedChildren='YES'
          unCheckedChildren='OFF'
          style={{display: 'flex'}}
          />
        </Form.Item>
      </Col>
      <Col style={{ marginTop: '0.3rem' }}>
        {toggle
          ? props.checkedtext ?? 'Añadir a la cotización'
          : props.uncheckedtext ?? 'Ocultar de la cotización'}
      </Col>
    </Row>
  )
}

export { ToggleFormItem }
