import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetInstitutions (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetInstitutions', filtersQuery],
    () => AgencyRepository.institutions(filtersQuery),
    { retry: 2 }
  )
}

export { useGetInstitutions }
