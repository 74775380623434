import { useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Unauthorized } from '../errors/Unauthorized'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { PackageCourseProvider } from '../../providers/package/package-course-context'
import { Button, Col, Row } from 'antd'
import { URL_QUOTER_PAGE } from '../quoter'
import FormContent from './FormContent'
import { QuoteFormDetails } from './components/QuoteFormDetails'
import { PackageFormConfigured } from './components/PackageFormConfigured'

const URL_PACKAGE_COURSE_PAGE = '/package-course/:courseId/:campusId'
const URL_SHORT_COURSE_PAGE = '/short-term-course/:courseId/:campusId'
const URL_PACKAGE_COURSE_PREVIEW_PAGE = '/package-course/:courseId/:campusId/:mode'
const URL_SHORT_COURSE_PREVIEW_PAGE = '/short-term-course/:courseId/:campusId/:mode'
const PackageCoursePage = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const baseQuoteId = params.get('quote') ?? undefined
  const { courseId, campusId, yearId, mode } = useParams()
  const location = useLocation()
  const { quoteId } = location.state ?? { quoteId: baseQuoteId }
  const [hasPermision, setHasPermision] = useState(true)

  if (!hasPermision) {
    return <Unauthorized />
  }
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <PackageCourseProvider courseId={courseId} campusId={campusId} yearId={yearId} quoteId={quoteId} mode={mode}>
          <PackageFormConfigured>
            <Button className='byp-mb-1 byp-btn-blue-100 quote-course-btn' onClick={() => navigate(URL_QUOTER_PAGE)}>
              Go Back
            </Button>
            <Row gutter={16}>
              <Col xs={24} lg={16}>
                <FormContent setHasPermision={setHasPermision} />
              </Col>
              <Col xs={24} lg={8}>
                <QuoteFormDetails />
              </Col>
            </Row>
          </PackageFormConfigured>
        </PackageCourseProvider>
      </MainTheme>
    </PageProtected>
  )
}
export {
  PackageCoursePage,
  URL_PACKAGE_COURSE_PAGE, URL_SHORT_COURSE_PAGE,
  URL_PACKAGE_COURSE_PREVIEW_PAGE,
  URL_SHORT_COURSE_PREVIEW_PAGE
}
