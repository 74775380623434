import { Form } from 'antd'
import LottieCargando from '../../../../lotties/LottieCargando'
import { STEP_PROFILE } from '../steps/education-group-profile/EducationGroupProfileStep'
import { useProfileEnterprise } from '../../../../providers/enterprise/profile-enterprise-provider'
import { useStepByStep } from '../../../../providers/stepbystep/step-service-provider'
import { EducationGroupProfileFieldsContainer } from '../steps/education-group-profile/EducationGroupProfileFieldsContainer'

function EducationGroupFormConfigured () {
  const { initValues } = useProfileEnterprise()
  const { form, handleOnFinish } = useStepByStep()

  const onFinish = (values) => {
    handleOnFinish(STEP_PROFILE, values)
  }

  if (!initValues) {
    return <LottieCargando />
  }

  return (
    <Form
      form={form}
      layout='vertical'
      scrollToFirstError
      name='EducationGroupFormConfigured'
      onFinish={onFinish}
      initialValues={initValues}
    >
      <EducationGroupProfileFieldsContainer form={form} />
    </Form>
  )
}

export { EducationGroupFormConfigured }
