import { Form, Select, Statistic, Switch, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { AddAndRemoveListButtons } from '../../dashboards/Institution/components/AddAndRemoveListButtons'

const { Option } = Select

function ServiceSection ({ agencyServices }) {
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      serviceAgency: included
    })
  }

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      serviceId: agencyServices
    })
  }, [agencyServices])

  return (
    <>
      <RowQuoter
        colA={
          <p className='w-100'>
            Add services <Switch onChange={handleChangeSwitch} checked={formConfig.serviceAgency} checkedChildren='YES' unCheckedChildren='NO' />
          </p>
        }
      />

      {service.includeService() && (
        <Form.List
          name='services_agency'
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <FormServices keyService={key} name={name} index={index} service={service} fields={fields} add={add} remove={remove} key={key} />
              ))}
              {fields.length === 0 && (
                add()
              )}
            </>
          )}
        </Form.List>
      )}
    </>
  )
}

const FormServices = ({ keyService, name, index, service, fields, add, remove }) => {
  const [id, setId] = useState()

  return (
    <Row key={keyService}>
      <RowQuoter
        hr={service.getAmountService(id) <= 0}
        colA={
          <>
            <Form.Item
              name={[name, 'service_agency']}
              label='Service name'
              rules={[{
                required: true,
                message: 'Field required'
              }]}
            >
              <Select
                style={{ width: '95%' }}
                allowClear
                placeholder='No seleccionado'
                onChange={setId}
                size='small'
              >
                {service.getServiceAgency().map((itm) => {
                  return <Option key={itm.id} value={itm.id}>{itm.name}</Option>
                })}
              </Select>
            </Form.Item>
          </>
        }
        colB={
          service.includeService() && (
            <>
              {// Low Season Cost
                service.getAmountService(id) > 0 && (
                  <Statistic
                    prefix=''
                    precision={2}
                    value={`${service.getAmountService(id)}`}
                    suffix={`${service.getServiceAgencyCurrency(id)}`}
                  />
                )
              }
            </>
          )
        }
      />

      <AddAndRemoveListButtons
        fields={fields}
        index={index}
        add={add}
        remove={remove}
        name={name}
        labelAdd='Add additional service'
        labelDelete='Delete additional service'
      />
    </Row>
  )
}

export { ServiceSection }
