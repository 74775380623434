import React, { useEffect } from 'react'
import { Form, Select, notification } from 'antd'
import { useGetPlans } from '../../../bussines/hooks/plan/useGetPlans'

const { Option } = Select

export const SelectPlanFormItem = ({ name, filters, ...props }) => {
  const { data, isLoading, error } = useGetPlans(filters)

  useEffect(() => {
    if (!isLoading && error) {
      notification.error({
        message: 'Ups, somenthing went wrong at updating the plans'
      })
    }
  }, [isLoading, error])
  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select
        loading={isLoading}
        {...props}
        style={{ width:'150px' }}
      >
        {data?.data?.map((plan) => (
          <Option key={plan.id} value={plan.id}>{plan.name}</Option>
        ))}
        {/* <Option value='Free Version'>Free Version</Option>
        <Option value='Trial Version'>Trial Version</Option>
        <Option value='Monthly Version'>Monthly Version</Option>
        <Option value='Semestral Version'>Semestral Version</Option>
        <Option value='Yearly Version'>Yearly Version</Option> */}
      </Select>
    </Form.Item>
  )
}
