import { Button, notification } from 'antd'
import { useEffect } from 'react'
import { useDeleteStudentIdFromQuote } from '../../../bussines/hooks/quote/useDeleteStudentIdFromQuote'
import stateControl from '../../../bussines/utils/state-control'
import { IconDeleteFilled } from '../../../common/assets/svg/icons'

const ButtonDeleteStudenIdFromQuote = ({ quoteId, refetch, ...props }) => {
  const { data, isLoading, error, currentState, removeQuoteFromStudent } = useDeleteStudentIdFromQuote(quoteId)

  useEffect(() => {
    if (data && currentState === stateControl.STATE_SUCCESS) {
      if (refetch) {
        refetch()
      }
      notification.success({
        message: 'Quote removed from this student'
      })
    }
  }, [data, currentState, isLoading, error])

  return (
    <Button
      onClick={() => {
        removeQuoteFromStudent(quoteId)
      }}
      {...props}
      loading={isLoading}
      type='text'
      style={{ display: 'flex', alignItems: 'center', paddingLeft: '0' }}
    >
      <IconDeleteFilled color='red' width='26' height='36' />
    </Button>
  )
}

export { ButtonDeleteStudenIdFromQuote }
