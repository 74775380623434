import { Button, Col, Collapse, Form, Input, Row, Tag } from 'antd'
import { AddAndRemoveListButtons } from '../components/AddAndRemoveListButtons'
import { ConceptDiscountFormItem } from '../components/ConceptDiscountFormItem'
import { PlusOutlined } from '@ant-design/icons'
import { BasicRadioGroupFormItem } from '../../../../common/components/helper-form/BasicRadioGroupFormItem'

const { Panel } = Collapse

const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const header = (
    <>
      {values[name][position].campus} <Tag className='byp-ml-1'>{values[name][position].status}</Tag>
    </>
  )
  return header
}

const getDivisa = (position, values, name) => {
  if (position === undefined || values === undefined) return 'Sin divisa'
  return values[name][position].divisa ?? 'Divisa inválida'
}

function FormBasePricesDiscountsContainer ({ form, onFinish, name, parenListName, program }) {
  const listName = name
  const arrayFees = Form.useWatch(listName, form) || []
  return (
    <Form.List
      name={listName}
    >
      {(fields, { add, remove }, { errors }) => (
        <Collapse
          defaultActiveKey={['0']}
          expandIconPosition='right'
        >
          {fields.map(({ key, name, ...restField }, index) => (
            <Panel
              header={
                panelHeader(
                  restField.fieldKey,
                  form.getFieldValue(),
                  listName
                )
              }
              key={key}
            >
              <Collapse
                defaultActiveKey={['1']}
              >
                <Panel header='Initial costs' key='1'>
                  <Row>
                    <Col span={6} className='byp-pt-1'> Currency: </Col>
                    <Col span={18}>
                      {getDivisa(
                        restField.fieldKey,
                        form.getFieldValue(),
                        listName
                      )}
                    </Col>
                    <Col span={24}>
                      <ConceptDiscountFormItem
                        name={[name, 'fees']}
                        cleanName='fees'
                        list={arrayFees[name]}
                        form={form}
                        showInKind
                      />
                    </Col>
                  </Row>
                </Panel>
                <Panel header='Materials costs' key='2'>
                  <Row>
                    <Col span={24}>
                      <BasicRadioGroupFormItem
                        name={[name, 'include_materials']}
                        label='Include discounts in this section?'
                      />
                    </Col>
                    <Col span={24}>
                      {arrayFees[name]?.include_materials && <ConceptDiscountFormItem name={[name, 'materials_prices']} form={form} />}
                    </Col>
                  </Row>
                </Panel>
                <Panel header='Medical insurance' key='3'>
                  <Row>
                    <Col span={24}>
                      <BasicRadioGroupFormItem
                        name={[name, 'include_insurances']}
                        label='Include discounts in this section?'
                      />
                    </Col>
                    <Col span={24}>
                      {arrayFees[name]?.include_insurances && <ConceptDiscountFormItem name={[name, 'insurances']} form={form} />}
                    </Col>
                  </Row>
                </Panel>
                {program === 'language' && (
                  <Panel header='Aditional cost' key='4'>
                    <Row>
                      <Col span={24}>
                        <ConceptDiscountFormItem name={[name, 'extras']} form={form} />
                      </Col>
                    </Row>
                  </Panel>
                )}
                <Panel header='Additional benefits you wish to add' key='7'>
                  <h6 className='byp-mt-1'><b>Discount or promo: </b></h6>
                  <Row style={{ width: '100%' }}>
                    <Form.List
                      name={[name, 'benefits']}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Row key={key} style={{ width: '100%' }} gutter={16}>
                              <Col span={20}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'name']}
                                  rules={[{ required: true, message: 'This field is required' }]}
                                >
                                  <Input
                                    className='byp-input'
                                  />
                                </Form.Item>
                              </Col>
                              <AddAndRemoveListButtons
                                fields={fields}
                                index={index}
                                add={add}
                                remove={remove}
                                name={name}
                              />
                            </Row>
                          ))}
                          {
                            fields.length === 0 && (
                              <div className='w-100'>
                                <Form.Item>
                                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                                    Add
                                  </Button>
                                </Form.Item>
                              </div>
                            )
                          }
                        </>
                      )}
                    </Form.List>
                  </Row>
                </Panel>
              </Collapse>
            </Panel>
          ))}
        </Collapse>
      )}
    </Form.List>
  )
}

export { FormBasePricesDiscountsContainer }
