import { Button, Col, Form, Input, InputNumber, notification, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useGetMiscellaneous } from '../../../../../bussines/hooks/agency/miscellaneous/useGetMiscellaneous'
import { useSaveMiscellanous } from '../../../../../bussines/hooks/agency/miscellaneous/useSaveMiscellanous'
import { useUpdateMiscellanous } from '../../../../../bussines/hooks/agency/miscellaneous/useUpdateMiscellanous'
import { useDeleteService } from '../../../../../bussines/hooks/school/useDeleteService'
import { useFindCourse } from '../../../../../bussines/hooks/school/useFindCourse'
import stateControl from '../../../../../bussines/utils/state-control'
import { SelectCurrencyFormItem } from '../../../../../common/components/helper-form/SelectCurrencyFormItem'
import { PrefixLabelSelector } from '../../../Institution/components/PrefixLabelFormItem'
import { MiscellaneousTable } from './MiscellaneousTable'

export const MiscellaneousForm = () => {
  const [form] = Form.useForm()
  const [divisa, setDivisa] = useState()
  const [id, setId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const { saveMiscellaneous, isLoading, currentState } = useSaveMiscellanous()
  const { data, isLoading: isLoadingList, refetch } = useGetMiscellaneous()
  const { deleteService, isLoading: isDeleting, currentState: deleteState } = useDeleteService()
  const { updateMiscellaneous, isLoading: isUpdating, currentState: updateState, error: errorUpdating } = useUpdateMiscellanous()
  const { data: miscellaneous, isLoading: isLoadingMisc } = useFindCourse(id)
  const prefixLabelSelector = <PrefixLabelSelector divisa={divisa} />

  const handleFinsh = (values) => {
    if (isUpdateMode) {
      updateMiscellaneous(id, values)
    } else {
      saveMiscellaneous(values)
    }
  }

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'Data saved'
      })
      refetch()
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoadingMisc && miscellaneous) {
      form.setFieldsValue(miscellaneous)
      setIsUpdateMode(true)
    }
  }, [miscellaneous, isLoadingMisc])

  useEffect(() => {
    if (!isDeleting && deleteState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'Miscellaneous deleted'
      })
      refetch()
    }
  }, [isDeleting, deleteState])

  useEffect(() => {
    if (!isLoadingList && data) {
      console.log('🚀 ~ file: MiscellaneousForm.js:31 ~ useEffect ~ data:', data)
    }
  }, [data, isLoadingList])

  useEffect(() => {
    if (!isUpdating && updateState === stateControl.STATE_SUCCESS) {
      form.resetFields()
      notification.success({
        message: 'Miscellaneous updated'
      })
      setIsUpdateMode(false)
      setId()
      refetch()
    }
    if (!isUpdating && errorUpdating) {
      notification.error({
        message: 'something went wrong'
      })
    }
  }, [isUpdating])

  return (
    <Row gutter={24} className='byp-dashboard-content' style={{ margin:'0 15px' }}>
      <Col xs={24} md={14} lg={10}>
        <Form onFinish={handleFinsh} form={form}>
          <h1 className='byp-title'>Agency Miscellaneous</h1>
          <p>Place here any costs your agency charges in its programs</p>
          <SelectCurrencyFormItem
            name='divisa'
            label='Currency'
            size='small'
            setDivisa={setDivisa}
            rules={[{ required: true, message: 'This field is required' }]}
          />
          <h5 className='byp-title'>Name and costs</h5>
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item name='name' label='Service fee name' rules={[{ required: true, message: 'This field is required' }]}>
                <Input />
              </Form.Item>

            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='cost' rules={[{ required: true, message: 'This field is required',
            
            },
            () => ({
              validator(_, value) {
                if (0< value ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The number must be positive'));
              },
            }),
            ]} label='Cost'>
                <InputNumber addonBefore={prefixLabelSelector} min={0}/>
              </Form.Item>
            </Col>
          </Row>
          <div className='w-100' style={{ display: 'flex', justifyContent: 'end' }}>
            <Button htmlType='submit' className='byp-btn-blue-100'>
              {isUpdateMode ? 'Update' : 'Save'}
            </Button>
          </div>
        </Form>
      </Col>
      <Col xs={24} md={10} lg={14}>
        <MiscellaneousTable
          services={data ?? []}
          deleteItem={deleteService}
          isLoading={isDeleting}
          setId={setId}
        />
      </Col>
    </Row>
  )
}
