import { Button, Popover } from 'antd'

const DetailsPopOver = ({ date, user, office }) => {
  const content = (
    <div>
      <h6>Date of change: <b>{date}</b></h6>
      <h6>User who modified it: <b>{user}</b></h6>
      <h6>User’s office: <b>{office}</b></h6>
    </div>
  )
  return (
    <Popover content={content} title='Details' trigger='hover' placement='topRight'>
      <Button type='text' className='byp-title byp-fw-bold'>?</Button>
    </Popover>
  )
}

export { DetailsPopOver }
