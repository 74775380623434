import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetPopularSchools (filters) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetPopularSchools', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularSchools(filtersQuery),
    {
      staleTime: 5000
    }
  )
}

export { useGetPopularSchools }
