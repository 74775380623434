import React from "react";
import { Table, Switch } from "antd";
const { Column } = Table;

function TableMaterialsStatus({ data, isLoading }) {
  const typeButton = (text) => {
    if (text === "Published") {
      return <a className="published__btn">PUBLISHED</a>;
    } else if (text === "In process") {
      return <a className="in-process__btn">IN PROCESS</a>;
    } else {
      return <a className="missing__btn">MISSING</a>;
    }
  };

  return (
    <Table
      loading={isLoading}
      dataSource={data ?? []}
      className="byp-table-dashboard table-materials"
      rowKey="id"
    >
      <Column title="School" dataIndex="name" key="name" />
      <Column
        title="Documents"
        dataIndex="documents"
        key="sede"
        render={(_, sede) => typeButton(sede.documents)}
      />
      <Column
        title="Quote pics"
        dataIndex="documents"
        key="sede"
        render={(_, sede) => typeButton(sede.web)}
      />
      <Column
        title="PDF pics"
        dataIndex="documents"
        key="sede"
        render={(_, sede) => typeButton(sede.pdf)}
      />
    </Table>
  );
}

export { TableMaterialsStatus };
