import api from '../../services/api'

class PlanRepository {
  async getPlans (params = {}) {
    const response = await api.get('/plans', { params })
    return response.data
  }

  async assignPlan (data) {
    const response = await api.post('/plans/assign', data)
    return response.data
  }
}

export default new PlanRepository()
