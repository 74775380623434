import { Col, Row, Radio, Form, Select, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import TimeFilters from '../../agency/analitics/TimeFilters'
import { SelectProgramsFormItem } from '../../../../common/components/helper-form/SelectProgramsFormItem'
import TrendsCountries from '../components/TrendsCountries'
import { useGetQYPQuotesByTime } from '../../../../bussines/hooks/admin/useGetQYPQuotesByTime'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'
import { SummarizedResults } from '../components/SummarizedResults'
import { ChartDoughnut } from '../components/ChartDoughnut'
import { ChartTotalQuotesTrends } from '../components/ChartTotalQuotesTrends'

const { Option } = Select

// data de prueba
const dataPrueba = [
  { enterprise_type: 'Mexico', quotes: 10 },
  { enterprise_type: 'Canada', quotes: 17 },
  { enterprise_type: 'Mexico', quotes: 4 }
]

const TrendsChartInfo = () => {
  const [time, setTime] = useState({})
  const { data, isLoading } = useGetQYPQuotesByTime(time)
  const [program, setProgram] = useState()
  const [isActive, setActive] = useState(0)
  const [country, setCountry] = useState()

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: TrendsChartInfo.js:15 ~ useEffect ~ data:', data)
    }
  }, [isLoading])
  return (
    <Row className='byp-dashboard-content'>
      <Col span={24}>
        <Row>
          <Col span={24} className='byp-mb-1'>
            <h3 className='byp-title'>Trends</h3>
          </Col>

          <Col span={24}>
            <TimeFilters setTime={setTime} hasDataRange={false} isActive={!!isActive} />
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} lg={12} className='byp-mt-1 byp-mb-1'>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Data comparison program per country</h4>
          </Col>
          <Col xs={24} sm={24} lg={12} className='byp-mt-1 byp-mb-1'>
            <Row justify='end'>
              <div className='trends__select-program'>
                <label>Choose program</label>
                <SelectProgramsFormItem style={{ width: '300px' }} size='small' onChange={setProgram} />
              </div>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={24} className='border-bottom__blue byp-mb-1'>
            <TrendsCountries program={program} country={country} setCountry={setCountry} />
            {isLoading && <BasicSpinner />}
          </Col>
          <Col span={24} className='byp-mb-1'>
            <Row>
              <Col span={24} className='radio_container'>
                <p>Would you like to compare between specific range of time?</p>
                <Radio.Group onChange={e => setActive(e.target.value)} value={isActive}>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Col>
              {!!isActive && (
                <>
                  <Col span={6} className='byp-mt-1'>
                    <Form.Item
                      label='Filter by'
                    >
                      <Select value='semester'>
                        <Option value='month'>Month</Option>
                        <Option value='trimester'>Trimester</Option>
                        <Option value='semester'>Semester</Option>
                        <Option value='year'>Year</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[12, 12]}>
                      <Col span={6}>
                        <Form.Item
                          label='Filter by'
                        >
                          <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label='between'
                        >
                          <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col span={24} className='border-bottom__blue byp-mb-1'>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Summarized results</h4>
            <SummarizedResults />
          </Col>
        </Row>

        <Row gutter={[33, 33]}>
          <Col xs={24} sm={24} lg={7}>
            {!isLoading && <ChartDoughnut data={dataPrueba} isTrends />}
            {isLoading && <BasicSpinner />}
          </Col>
          <Col xs={24} sm={24} lg={17}>
            {!isLoading && <ChartTotalQuotesTrends />}
            {isLoading && <BasicSpinner />}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { TrendsChartInfo }
