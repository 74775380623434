import React from 'react'
import { Card, Col, Row } from 'antd'
import { EmailIcon, FacebookIcon, InstagramIcon, LinkedinIcon, OtherSocialIcon, PhoneIcon, TwitterIcon, WhatsapIcon, YoutubeIcon, TikTokIcon } from '../assets/imgs/Icons'

const getSocialNetworkIcon = (networkName, url) => {
  if (networkName === 'Tik Tok') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <TikTokIcon />
      </a>
    )
  } else if (networkName === 'Facebook') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <FacebookIcon />
      </a>
    )
  } else if (networkName === 'Instagram') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <InstagramIcon />
      </a>
    )
  } else if (networkName === 'Youtube') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <YoutubeIcon />
      </a>
    )
  } else if (networkName === 'Twitter') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <TwitterIcon />
      </a>
    )
  } else if (networkName === 'Linkedin') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <LinkedinIcon />
      </a>
    )
  } else {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <OtherSocialIcon />
      </a>
    )
  }
}

export const QuoteAgency = ({ quote, className }) => {
  const getSocialNetworks = () => {
    return quote.agency.social_networks?.filter(network => {
      return network.show
    })
  }
  const handleUrl = (link, link2) => {
    if (link) {
      if (!link?.startsWith('http')) {
        link = 'http://' + link
      }
      return link
    } else if (link2) {
      if (!link2?.startsWith('http')) {
        link2 = 'http://' + link2
      }
      return link2
    }

    return link
  }
  return (
    <Card bordered={false} className={className}>
      <div className='you-can-discover ycd-bg align-items' style={{ height: '100%' }}>
        <div className='agency-profile-contener'>
          <Col span={8} className='agency-profile quote-profile'>
            <img src={quote.agency.profilePhotoUrl} style={{ width: '80px', height: '80px', objectFit: 'cover' }} alt={quote.agency.name} />
            <h2 className='agent-name'>{quote?.agent.sede.parent.name}</h2>
            <div style={{ display: 'flex', gap: '7px', flexWrap: 'wrap', justifyContent: 'center', paddingBottom: '20px' }}>
              {
                quote.agency.email && (
                  <a href={`mailto:${quote.agency.email}`} target='_blank' className='social-media' rel='noreferrer'>
                    <EmailIcon />
                  </a>
                )
              }
              {
                quote.agency.whatsapp && (
                  <a href={`tel:${quote.agency.whatsapp}`} target='_blank' className='social-media' rel='noreferrer'>
                    <PhoneIcon />
                  </a>
                )
              }
              {
                quote.agency.whatsapp && (
                  <a href={`https://wa.me/${quote.agency.whatsapp}`} target='_blank' className='social-media' rel='noreferrer'>
                    <WhatsapIcon />
                  </a>
                )
              }
              {
                getSocialNetworks()?.map(network => getSocialNetworkIcon(network.name, network.url))
              }
            </div>
            <a href={handleUrl(quote?.agency.web, quote?.web_subgroup)} target='_blank' className='quote-social-btn' rel='noreferrer'>
              Website
            </a>
          </Col>
          <Col xs={24} sm={24} md={16} className='quote-description'>
            <p style={{ fontSize: '0.875rem' }}>{quote.agent.sede.description}</p>
          </Col>
        </div>
      </div>
    </Card>
  )
}
