import React, { useEffect, useState } from 'react'
import { SelectBranchesAll } from '../../components/SelectBranchesAll'
import { SelectCitiesAll } from '../../../../../common/components/helper-form/SelectCityByIsoFormItem'
import { SelectCountriesAll } from '../../../../../common/components/helper-form/SelectAsyncNationalitiesFormItem'
import { Form } from 'antd'
import { SelectUsersAll } from '../../components/SelectUsersAll'

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => clearTimeout(timeoutId)
  }, [value, delay])

  return debouncedValue
}

const SelectsReportsUni = ({ form, setCountries, setCities, setOffices, isAgencyOffice, setUsers, company, isLoading, defaultuser }) => {
  const countries = Form.useWatch('countries', form)
  const cities = Form.useWatch('cities', form)
  const offices = Form.useWatch('offices', form)
  const users = Form.useWatch('users', form)

  const queryDebounceCountries = useDebounce(countries, 800)
  const queryDebounceCities = useDebounce(cities, 800)
  const queryDebounceOffices = useDebounce(offices, 800)
  const queryDebounceUsers = useDebounce(users, 800)

  useEffect(() => {
    setCountries(queryDebounceCountries)
  }, [queryDebounceCountries])

  useEffect(() => {
    setCities(queryDebounceCities)
  }, [queryDebounceCities])

  useEffect(() => {
    setOffices(queryDebounceOffices)
  }, [queryDebounceOffices])

  useEffect(() => {
    setUsers(queryDebounceUsers)
  }, [queryDebounceUsers])

  return (
    <Form
      form={form}
    >
      <SelectCountriesAll
        label='Select country'
        name='countries'
        isReports
        form={form}
        isAgencyOffice={isAgencyOffice}
        filters={{
          company: company?.id,
          company_type: company?.type
        }}
        isDisabled={isLoading}
      />
      <SelectCitiesAll
        label='Select city'
        name='cities'
        isReports
        form={form}
        isAgencyOffice={isAgencyOffice}
        filters={
          queryDebounceCountries?.length > 0
            ? { countries: queryDebounceCountries, company: company?.id, company_type: company?.type }
            : { company: company?.id, company_type: company?.type }
        }
        isDisabled={isLoading}
      />
      <SelectBranchesAll
        mode='multiple'
        name='offices'
        label='Select Offices'
        form={form}
        isReports
        isAgencyOffice={isAgencyOffice}
        filters={{
          countries: queryDebounceCountries?.length > 0 ? queryDebounceCountries : undefined,
          cities: queryDebounceCities?.length > 0 ? queryDebounceCities : undefined
        }}
        isDisabled={isLoading}
      />
      <SelectUsersAll
        mode='multiple'
        name='users'
        label='Select Users'
        form={form}
        isReports
        filters={{
          countries: queryDebounceCountries?.length > 0 ? queryDebounceCountries : undefined,
          cities: queryDebounceCities?.length > 0 ? queryDebounceCities : undefined,
          sedes: queryDebounceOffices?.length > 0 ? queryDebounceOffices : undefined
        }}
        isDisabled={isLoading}
        defaultuser={defaultuser}
      />
    </Form>
  )
}

export { SelectsReportsUni }
