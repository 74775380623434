import { Form, Select } from 'antd'
const { Option } = Select

const rules = [{ required: true, message: 'This field is required' }]

const SelectUsersFormItem = ({ name, label, program, data, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
    >
      <Select
        className='w-100'
        {...rest}
      >
        {data?.map((item) => (
          <Option key={'user_' + item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectUsersFormItem }
