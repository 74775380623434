import * as React from "react"

export function Flecha (props) {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.203 1.704l-3.473 9.38c-.22.592-.85.897-1.41.68a1.079 1.079 0 01-.61-.615l-1.1-2.783a2.165 2.165 0 00-1.141-1.197L.819 6.013c-.55-.24-.802-.908-.56-1.492a1.15 1.15 0 01.622-.63L9.815.247c.564-.23 1.19.059 1.397.647.092.26.088.548-.009.81z"
        fill="#fff"
      />
    </svg>
  )
}
