import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { useUser } from './../../../providers/auth/user-context-provider'
import { AgencyMenuItems } from '../menus/menu-agency'
import ENTERPRISE_TYPES from '../../../bussines/utils/enterprise-types-control'
import { useGetMyServiceCategories } from '../../../bussines/hooks/school/useGetMyServiceCategories'
import institutionMenuItems from '../menus/institutionMenuItems'
import GroupEducationMenuItems from '../menus/GroupEducationMenuItems'
import SuperAdminMenuItems from '../menus/SuperAdminMenuItems'

const UserMenuSwitcher = () => {
  const location = useLocation()
  const { data } = useGetMyServiceCategories()
  const { menuItemSelected, openSub, openSub2, openSub3 } = location.state ?? '1'
  const user = useUser()

  let menuSelected = []
  switch (user.type) {
    case ENTERPRISE_TYPES.AGENCY:
      menuSelected = AgencyMenuItems()
      break
    case ENTERPRISE_TYPES.INSTITUTION:
      menuSelected = institutionMenuItems(data)
      break
    case ENTERPRISE_TYPES.EDUCATION_GROUP:
      menuSelected = GroupEducationMenuItems(data)
      break
    case ENTERPRISE_TYPES.SUPER_ADMIN:
      menuSelected = SuperAdminMenuItems(data)
      break
    default:
      menuSelected = institutionMenuItems(data)
  }

  const [openKeys, setOpenKeys] = useState([openSub, openSub2, openSub3])

  useEffect(() => {
    setOpenKeys([openSub, openSub2, openSub3])
  }, [openSub, openSub2, openSub3])

  return (
    <Menu
      mode='inline'
      defaultSelectedKeys={[menuItemSelected ? String(menuItemSelected) : '1']}
      defaultOpenKeys={[openSub, openSub2, openSub3]}
      style={{
        height: '0px',
        borderRight: 0,
        zIndex: 99999
      }}
      items={menuSelected}
      className='byp-dashboard-menu'
      onChange={(data) => console.log('metu item changed', data)}
      selectedKeys={[menuItemSelected]}
      openKeys={openKeys}
      onOpenChange={(keys) => setOpenKeys(keys)}
    />
  )
}

export { UserMenuSwitcher }
