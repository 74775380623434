import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Modal,
  notification
} from 'antd'
import moment from 'moment'
import utilsState from '../../../../bussines/utils/state-control'
import { usePostSaveDiscount } from '../../../../bussines/hooks/school/discounts/usePostSaveDiscount'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { TableDiscounts } from '../Tables/TableDiscounts'
import { useEffect, useState } from 'react'
import { useGetDiscounts } from '../../../../bussines/hooks/school/discounts/useGetDiscounts'
import { useFindDiscount } from '../../../../bussines/hooks/school/discounts/useFindDiscount'
import {
  useUpdateDiscount,
  useUpdateStatusDiscount
} from '../../../../bussines/hooks/school/discounts/useUpdateDiscount'
import { useDeleteDiscount } from '../../../../bussines/hooks/school/discounts/useDeleteDiscount'
import { format } from 'date-fns'
import { BasicRadioGroupFormItem } from '../../../../common/components/helper-form/BasicRadioGroupFormItem'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { showErrorModal } from '../../../../utils/propsForModal'
import { IntakeDatesListFormItem } from '../../../../common/components/helper-form/IntakeDatesListFormItem'
import { SelectCountriesByRegionFormItem } from '../../../../common/components/places-helper-form/SelectCountriesByRegionFormItem'
import { useNavigate } from 'react-router-dom'
import { URL_CUSTOMDISCOUNT_PAGE } from './CustomDiscountsPage'
import { formatStringDate } from '../../../../utils/formatStringDate'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

const { RangePicker } = DatePicker
const { Option } = Select

const rules = [{ required: true, message: 'This field is required' }]
const dateFormat = 'YYYY/MM/DD'

function FormCreateDiscounts ({ program }) {
  const [form] = Form.useForm()
  const byRegions = Form.useWatch('by_regions', form)
  const [modal, contextHolder] = Modal.useModal()
  const [page, setPage] = useState(1)

  const [discountId, setDiscountId] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)

  const {
    data: discounts,
    isLoading,
    refetch
  } = useGetDiscounts(program && { program, page, type: 'table' })
  const {
    currentState,
    isLoading: isSaving,
    saveDiscount,
    error: errorSave,
    isError: isErrorSaving
  } = usePostSaveDiscount()
  const {
    updateDiscount,
    isLoading: isUpdating,
    currentState: updateState,
    error: errorUpdate,
    isError: isErrorUpdating
  } = useUpdateDiscount()
  const {
    updateDiscount: updateStatus,
    isLoading: isUpdatingStatus,
    currentState: updateStateStatus,
    error: errorUpdateStatus,
    isError: isErrorUpdatingStatus
  } = useUpdateStatusDiscount()
  const {
    deleteDiscount,
    isLoading: isDeleting,
    currentState: deleteState,
    error: errorDelete,
    isError: isErrorDeleting
  } = useDeleteDiscount()
  const { data: discount, isLoading: isSearching } =
    useFindDiscount(discountId)

  const onFinish = (values) => {
    if (values.course_start_range) {
      const rangeStart = format(
        new Date(values.course_start_range[0] ?? null),
        'Y-LL-dd'
      ) // Y-LL-dd
      const rangeEnd = format(
        new Date(values.course_start_range[1] ?? null),
        'yyyy-LL-dd'
      ) // Y-LL-dd
      values.range_start = rangeStart
      values.range_end = rangeEnd
    }
    if (values.intake_date) {
      // values.intake_date = format(new Date(values.intake_date), 'yyyy-LL-dd')
      const nIntakeDates = values.intake_date?.map((intake) =>
        formatStringDate(intake.intake_date, 'LLLL/dd/yyyy')
      )
      values.intake_date = nIntakeDates
    }
    if (values.year) values.year = new Date(values.year).getFullYear()
    console.log(
      '🚀 ~ file: FormCreateDiscounts.js:42 ~ onFinish ~ values',
      values
    )
    isUpdateMode ? updateDiscount(values, discountId) : saveDiscount(values)
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const translations = discount?.translations ?? []
      const langTranslations = translations.filter(translation => translation.to === lang)
      // Set the new values
      const nameTraslated = langTranslations.find(translation => translation.attribute === 'name')
      // const descTraslated = langTranslations.find(translation => translation.attribute === 'description')
      form.setFieldsValue({
        name: nameTraslated?.translation,
        // description: descTraslated?.translation
      })
    } else {
      setFormData()
    }
  }

  const setFormData = () => {
    const start = discount.course_start_range[0] ?? null
    const end = discount.course_start_range[1] ?? null
    discount.course_start_range[0] = moment(start)
    discount.course_start_range[1] = moment(end)
    discount.year = moment(discount.year)
    if (
      (discount.intake_date && program === 'academic_years') ||
      (discount.intake_date && program === 'boarding_schools')
    ) {
      const intakes = discount.intake_date?.map((intake) => ({
        intake_date: moment(intake)
      }))
      discount.intake_date = intakes
    }
    if (!discount.region) {
      discount.by_regions = false
      discount.region = undefined
      discount.subregions = undefined
      discount.exclude_countries = undefined
    } else {
      discount.by_regions = true
    }
    discount.translation = null
    form.setFieldsValue(discount)
  }

  useEffect(() => {
    if (!isSaving && currentState === utilsState.STATE_SUCCESS) {
      notification.success({
        message: 'Data saved'
      })
      form.resetFields()
      refetch()
    }
  }, [isSaving, currentState])

  useEffect(() => {
    if (!isUpdating && updateState === utilsState.STATE_SUCCESS) {
      notification.success({
        message: 'Data updated'
      })
      setIsUpdateMode(false)
      form.resetFields()
      setDiscountId()
      refetch()
    }
  }, [isUpdating, updateState])

  useEffect(() => {
    if (!isUpdatingStatus && updateStateStatus === utilsState.STATE_SUCCESS) {
      setIsUpdateMode(false)
      setDiscountId()
      refetch()
    }
  }, [isUpdatingStatus, updateStateStatus])

  useEffect(() => {
    if (!isDeleting && deleteState === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [isDeleting, deleteState])

  useEffect(() => {
    if (isErrorUpdating && errorUpdate) {
      showErrorModal(modal, errorUpdate.response.data.message)
    }
    if (isErrorSaving && errorSave) {
      showErrorModal(modal, errorSave.response.data.message)
    }
    if (isErrorDeleting && errorDelete) {
      showErrorModal(modal, errorDelete.response.data.message)
    }
    if (isErrorUpdatingStatus && errorUpdateStatus) {
      showErrorModal(modal, errorUpdateStatus.response.data.message)
    }
  }, [
    errorSave,
    isErrorSaving,
    errorUpdate,
    isErrorUpdating,
    errorDelete,
    isErrorDeleting,
    errorUpdateStatus,
    isErrorUpdatingStatus
  ])

  useEffect(() => {
    if (!isSearching && discount) {
      setIsUpdateMode(true)
      setFormData()
    }
  }, [isSearching, discount])

  const navigate = useNavigate()

  const onContinue = (value) => {
    navigate(URL_CUSTOMDISCOUNT_PAGE.replace(':program', program), {
      state: {
        menuItemSelected: program + '_discounts_prices',
        openSub: program,
        openSub2: program + '_discounts'
      }
    })
  }

  return (
    <Form
      layout='vertical'
      scrollToFirstError
      name='FormCreateDiscount'
      onFinish={onFinish}
      requiredMark={false}
      form={form}
      initialValues={{
        regions: [{}],
        by_regions: true,
        intake_date: [{}]
      }}
    >
      <div className='byp-dashboard-content'>
        {contextHolder}
        <Row gutter={16} className='byp-mt-1'>
          <Col xs={24} md={10} lg={12}>
            <Row gutter={24}>
              <Col span={8}>
                <h5 className='byp-title'>Create your promotions</h5>
              </Col>
              <Col span={16}>
                <Row
                  gutter={[5]}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Col span={8} className='byp-text-sm byp-fw-bold'>
                    You're at:
                  </Col>
                  <Col span={8}>
                    <SelectSchoolProgramsFormItem
                      name='service_category'
                      rules={rules}
                      program={program}
                      form={form}
                      disabled
                      className='graduate_show_category'
                    />
                  </Col>
                  <Col xs={8}>
                    <SelectAvailableTranslationsFormItem
                      name='translation'
                      formItemProps={{
                        noStyle: true,
                        className: 'margin-0'
                      }}
                      selectProps={{
                        size: 'small',
                        className: 'w-100 margin-0',
                        onChange: (lang) => handleChangeTranslateLanguage(lang),
                        allowClear: true
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <p className='byp-mt-1'>
              Create your promotions so they can be reflected in the quote{' '}
            </p>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                Promo name
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name='name' rules={rules}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                Program type
              </Col>
              <Col xs={24} md={12}>
                <SelectSchoolProgramsFormItem
                  name='service_category'
                  rules={rules}
                  program={program}
                  form={form}
                />
              </Col>
              <Col xs={24} md={12}>
                Start
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name='start' rules={rules}>
                  <Input type='date' />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                Deadline
              </Col>
              <Col xs={24} md={12}>
                <Form.Item name='end' rules={rules}>
                  <Input type='date' />
                </Form.Item>
              </Col>

              <h6 className='byp-title byp-fw-bold w-100'>
                Course requirements
              </h6>
              {
                // Intake
                program === 'graduate' && (
                  <>
                    <Col xs={24} md={15}>
                      <Form.Item label='Course intake starts' name='intake' rules={[{required: true, message: 'This field is required' }]}>
                        <Select
                          size='small'
                          mode='multiple'
                          placeholder='Select a option'
                        >
                          <Option key='Spring' value='Spring'>
                            Spring
                          </Option>
                          <Option key='Winter' value='Winter'>
                            Winter
                          </Option>
                          <Option key='Fall' value='Fall'>
                            Fall
                          </Option>
                          <Option key='Summer' value='Summer'>
                            Summer
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={9}>
                      <Form.Item
                        label='Year'
                        name='year'
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <DatePicker picker='year' format='YYYY' />
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {(program === 'academic_years' ||
                program === 'boarding_schools') && (
                  <Col xs={24}>
                    <IntakeDatesListFormItem name='intake_date' form={form} />
                    {/* <Form.Item label='Course intake starts' name='intake_date' rules={[{ required: true, message: 'This field is required' }]}>
                        <DatePicker picker='month' format='MM/YYYY' />
                      </Form.Item> */}
                  </Col>
              )}
              {program !== 'graduate' &&
                program !== 'academic_years' &&
                program !== 'boarding_schools' && (
                  <>
                    <Col xs={24} md={12}>
                      Start dates of the course:
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item name='course_start_range' rules={rules}>
                        <RangePicker format={dateFormat} />
                      </Form.Item>
                    </Col>
                  </>
                )}
            </Row>

            <h6 className='byp-title byp-fw-bold w-100'>Promotion goes to</h6>
            <BasicRadioGroupFormItem
              name='by_regions'
              label='Specific group of regions'
            />
            {byRegions && <SelectCountriesByRegionFormItem form={form} />}
            <h6 className='byp-title byp-mt-1'>Schools to which it applies</h6>
            <SelectCampusByProgramFormItem
              program={program}
              mode='multiple'
              name='sedes'
              rules={rules}
              isTag
              showAll
              form={form}
              placeholder='Campus where the discount is offered'
            />

            {program !== 'graduate' &&
              program !== 'academic_years' &&
              program !== 'boarding_schools' && (
                <Row>
                  <Col xs={24} md={12}>
                    <h6>
                      <b>Student location</b>
                    </h6>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item name='student_location' rules={rules}>
                      <Select className='w-100'>
                        <Option key='Both'>Both</Option>
                        <Option key='Offshore'>Offshore</Option>
                        <Option key='Onshore'>Onshore</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            <div className='byp-mt-1'>
              <Button
                type='primary'
                className='float-right'
                htmlType='submit'
                loading={isLoading || isSaving || isUpdating || isDeleting}
              >
                {isUpdateMode ? 'Update' : 'Save'}
              </Button>
            </div>
          </Col>
          {/*  - TABLA - */}
          <Col xs={24} md={14} lg={12}>
            <TableDiscounts
              data={discounts}
              isLoading={isLoading || isSaving || isUpdating || isDeleting}
              refetch={refetch}
              setDiscountId={setDiscountId}
              deleteDiscount={deleteDiscount}
              updateDiscount={updateStatus}
              isUpdatingStatus={isUpdatingStatus}
              setPage={setPage}
              program={program}
            />
          </Col>
        </Row>
      </div>
      <div className='byp-dashboard-footer'>
        <Row gutter={12} className='byp-form-footer'>
          <Col span={24}>
            <Button
              className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
              htmlType='submit'
              onClick={onContinue}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export { FormCreateDiscounts }
