import { API_URL } from '../config'

/**
 * Removes the storage api base part from the url
 * @param {*} url http://....storage/something_urlesco_here
 * @returns url something_urlesco_here
 */
const cleanLogoUrl = (url) => {
  const logoUrl = url
  const urlParts = logoUrl.split('storage/')
  const partscount = urlParts.length
  const cleanedLogo = urlParts[partscount - 1]
  console.log('🚀 ~ file: cleanStorageUrl.js:13 ~ cleanLogoUrl ~ cleanedLogo:', cleanedLogo, urlParts)
  return cleanedLogo
  // const apiBasesUrl = API_URL.split('/api')
  // const apiBaseUrl = apiBasesUrl[0]
  // const includeApiUrl = logoUrl.includes(apiBaseUrl + '/storage/')
  // console.log('🚀 ~ file: cleanStorageUrl.js:13 ~ cleanLogoUrl ~ includeApiUrl:', includeApiUrl)
  // if (includeApiUrl) {
  //   const urlParts = url.split(apiBaseUrl + '/storage/')
  //   const partscount = urlParts.length
  //   const cleanedLogo = urlParts[partscount - 1]
  //   console.log(
  //     '🚀 ~ file: cleanStorageUrl.js:17 ~ cleanLogoUrl ~ cleanedLogo:',
  //     cleanedLogo,
  //     'urlParts', urlParts
  //   )
  //   return cleanedLogo
  // }
  // console.log('🚀 ~ LOGO YA ESTABA LIMPIO:', url)
  // return url
}

export {
  cleanLogoUrl
}
