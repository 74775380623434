import { Form, Select } from 'antd'
import { useGetFeeYears } from '../../../bussines/hooks/agency/insurances/useGetFeeYears'
const { Option } = Select

export function SelectFeeYearFormItem ({ feeId, name, type, noStyle, showAll, ...props }) {
  const { data: years } = useGetFeeYears(feeId)

  return (
    <Form.Item
      name={name}
      {...props}
      noStyle={noStyle}
    >
      <Select {...props} defaultActiveFirstOption>
        {showAll && <Option value={null}>All</Option>}
        {years?.map((item) => (
          <Option key={item.id} value={item.id}>{item.year}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}
