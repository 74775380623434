import { Row, Checkbox, Button, Form, Input, Modal, notification, Avatar } from 'antd'
import { useEffect, useState } from 'react'
import { useApplyForProgram } from '../../../bussines/hooks/quote/useApplyForProgram'
import stateControl from '../../../bussines/utils/state-control'
import { showErrorModal } from '../../../utils/propsForModal'
import { URL_ABOUT_POLICY_PAGE } from '../../about/PolicyPage'
import { URL_ABOUT_TERMS_AND_CONDITIONS_PAGE } from '../../about/TermsAndConditionsPage'
import { IconCalendar } from '../../../common/assets/svg/icons'

const required = { required: true, message: 'This element is required' }
const email = { type: 'email', message: 'Please, insert an valid email' }

const ApplyProgramModal = ({ quoteUuid, courseName, campusName, logo, botonBook, t }) => {
  const { applyForProgram, currentState, error, isLoading, data } = useApplyForProgram()
  const [mdl, setMdl] = useState()
  const [modal, contextHolder] = Modal.useModal()

  const opnMdl = () => {
    setMdl(true)
  }

  const clsMdl = () => {
    setMdl(false)
  }

  const handleSubmit = (values) => {
    applyForProgram(quoteUuid, values)
  }

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      notification.info({
        message: 'Application succesfully'
      })
      setMdl(false)
      console.log('\n Succes \n', data)
    }
  }, [currentState, isLoading])

  useEffect(() => {
    if (!isLoading && error) {
      showErrorModal(modal, error.response.data.message)
      console.log('\n Error \n', error)
    }
  }, [error, isLoading])

  if (botonBook) {
    return (
      <>
        {contextHolder}
        <Button
          block
          onClick={opnMdl}
          className='book-now-responsive'
          style={{ fontWeight: 'bold', gap: '5px', height: '40px', backgroundColor: 'var(--blue-200)', color: 'white', fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <IconCalendar fill='white' />
          Book now!
        </Button>
        <Modal
          title='Apply for this program'
          visible={mdl}
          footer=''
          onCancel={clsMdl}
          className='apply-modal'
        >
          <Row>
            <h4><Avatar src={logo} size={80} />  {campusName}, {courseName}</h4>
          </Row>
          <Form layout='vertical' requiredMark={false} onFinish={handleSubmit}>
            <Form.Item label='Full name:' name='name' rules={[required]}>
              <Input placeholder='example' className='apply-input' />
            </Form.Item>
            <Form.Item label='Email:' name='email' rules={[required, email]}>
              <Input placeholder='example' type='email' className='apply-input' />
            </Form.Item>
            <Form.Item label='Phone:' name='phone'>
              <Input placeholder='example' type='phone' className='apply-input' />
            </Form.Item>

            <Form.Item
              name='remember' valuePropName='checked'
              rules={[
                {
                  validator: async (_, checked) => {
                    if (!checked) {
                      return Promise.reject(
                        new Error('This element is required')
                      )
                    }
                  }
                }

              ]}

            >
              <Checkbox className='checkbox-apply'>
                Do you agree with our <a href={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE} target='_blank' rel='noreferrer'>Terms of Service </a>and<a href={URL_ABOUT_POLICY_PAGE} target='_blank' rel='noreferrer'> Privace Policy</a>
              </Checkbox>
            </Form.Item>

            <Button
              className='byp-btn-blue-100'
              block
              htmlType='submit'
              loading={isLoading}
              disabled={isLoading}
              style={{ height: '40px' }}
            >
              Apply
            </Button>
          </Form>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        {contextHolder}
        <Button
          className='byp-btn-yellow quote-overview--color-blue btn-apply'
          block
          onClick={opnMdl}
          style={{ paddingLeft: '3px', paddingRight: '3px' }}
        >
          Apply
        </Button>
        <Modal
          title='Apply for this program'
          visible={mdl}
          footer=''
          onCancel={clsMdl}
          className='apply-modal'
        >
          <Row>
            <h4> {logo} {campusName}, {courseName}</h4>
          </Row>
          <Form layout='vertical' requiredMark={false} onFinish={handleSubmit}>
            <Form.Item label='Full name:' name='name' rules={[required]}>
              <Input placeholder='example' className='apply-input' />
            </Form.Item>
            <Form.Item label='Email:' name='email' rules={[required, email]}>
              <Input placeholder='example' type='email' className='apply-input' />
            </Form.Item>
            <Form.Item label='Phone:' name='phone'>
              <Input placeholder='example' type='phone' className='apply-input' />
            </Form.Item>

            <Form.Item
              name='remember' valuePropName='checked'
              rules={[
                {
                  validator: async (_, checked) => {
                    if (!checked) {
                      return Promise.reject(
                        new Error('This element is required')
                      )
                    }
                  }
                }

              ]}

            >
              <Checkbox className='checkbox-apply'>
                Do you agree with our <a href={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE} target='_blank' rel='noreferrer'>Terms of Service </a>and<a href={URL_ABOUT_POLICY_PAGE} target='_blank' rel='noreferrer'> Privace Policy</a>
              </Checkbox>
            </Form.Item>

            <Button
              className='byp-btn-blue-100'
              block
              htmlType='submit'
              loading={isLoading}
              disabled={isLoading}
              style={{ height: '40px' }}
            >
              Apply
            </Button>
          </Form>
        </Modal>
      </>
    )
  }
}

export {
  ApplyProgramModal
}
