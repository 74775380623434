import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetAgencyUsers (office) {
  return useQuery(['useGetAgencyUsers', office], () => GeneralAnalysisRepository.getAgencyUsers(office)
  ,{
    staleTime: 5000
  }
  )
  
}

export { useGetAgencyUsers }
