import { useQuery } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function useGetQYPQuotesByTime (program, countries = [], filters = {}) {
  return useQuery(['useGetQYPQuotesByTime', filters], () => AdminRepository.totalQYPQuotesByTyme(filters), {
    retry: 1
  })
}

export { useGetQYPQuotesByTime }
