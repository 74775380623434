import { Form, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { crypt } from '../../../bussines/utils/basicEncript'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
// import { URL_QUOTE_PREVIEW } from '../../quote-preview'
import { URL_VENDOR_PAGE } from '../../about/VendorPage'
import { showErrorModal } from '../../../utils/propsForModal'
import { URL_QUOTE_APPLY_PREVIEW } from '../../quote-apply-preview'
import { useAuth } from '../../../providers/auth/auth-context-provider'

function QuoterFormConfigured ({ children }) {
  const {
    service, prices,
    form, handleFinishForm, course, errorPost,
    isSuccessPost, isErrorPost, quote,
    setFormConfig, formConfig, baseQuote
  } = useQuoter()
  const { user } = useAuth()
  const [modal, contextHolder] = Modal.useModal()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (isSuccessPost) {
      if (quote) {
        const roles = user?.role_names ?? []
        console.log('🚀 ~ useEffect ~ roles:', roles)
        if (roles?.includes('super-admin')) {
          window.open(URL_QUOTE_APPLY_PREVIEW.replace(':id', quote?.uuid))
        } else {
          if (quote.student_id) {
            const studentIdEncripted = crypt('qyp2023', quote?.student_id)
            let url = URL_VENDOR_PAGE.replace(':studentId', studentIdEncripted)
            const userIdEncripted = crypt('qyp2023', '' + quote?.user_id)
            url = url.replace(':agentId', userIdEncripted)
            window.open(url)
          } else {
            window.open('/quote-preview/' + quote?.uuid)
          }
        }
      }
    }
    if (isErrorPost) {
      const errMsg = errorPost?.response?.data?.message
      showErrorModal(modal, errMsg)
    }
  }, [isSuccessPost, isErrorPost, quote])

  useEffect(() => {
    if (course) {
      const { offer } = course
      const loadInitialData = async () => {
        if (!formConfig.fees) {
          setFormConfig({
            ...formConfig,
            fees: []
          })
        }

        const newFees = formConfig.fees ?? []
        const courseCosts = formConfig.courseCosts ?? []
        const formValues = {}
        const formConfigValues = {}

        if (!initialized) {
          setInitialized(true)
          const availableIntakes = service.getCourseIntakes()
          const first = availableIntakes[0] ?? {}
          const intakeV = first.id + '&' + first.season + '&' + first.intake
          const values = intakeV.split('&')
          if (offer.program?.name === 'graduate' && !formConfig.intake && first.id) {
            formValues.intake = intakeV
            formValues.length = offer.length
            formValues.duration_type = offer.time

            formConfigValues.intake = values[2] + ''
            formConfigValues.duration = offer.length
            formConfigValues.duration_type = offer.time

            // const dates = service.getDates(values[2] + '')
            // service?.searchPrices(dates[0])
            service?.searchPricesById(values[0], values[2])
          } else if ((offer.program?.name === 'academic_years' || offer.program?.name === 'boarding_schools') && !formConfig.intake && first.id) {
            // const intakes = service.getCourseIntake() ?? [null]
            // formValues.intake_date = intakes[0]
            // formValues.length = offer.length

            // formConfigValues.intake = intakes[0]
          }
        }

        try {
          const alreadyActivePrices = formConfig.fees ?? []
          const hs = service?.getPrices()?.extras
          if (hs) {
            for (const item of hs) {
              const extraAlreadyIncluded = alreadyActivePrices.find((costItem) => costItem.id === item.id)
              if (item.mandatory && !extraAlreadyIncluded) {
                newFees.push({
                  id: item.id,
                  cost: item.cost,
                  frequency: item.frequency
                })
                await service.isChecked(item.id)
              }
            }
          }

          const basePrices = service?.getPrices()?.base_prices
          if (basePrices) {
            for (const item of basePrices) {
              const alreadyIncluded = alreadyActivePrices.find((costItem) => costItem.id === item.id)
              if (!alreadyIncluded) {
                if (item.mandatory && item.name !== 'Registration Fee') {
                  newFees.push({ id: item.id, cost: item.cost, frequency: item.frequency })
                  await service.isChecked(item.id)
                }
              }
            }
          }
          formConfigValues.fees = newFees

          const medical = service?.getArrayHealtInsurance()
          if (medical) {
            for (const item of medical) {
              if (item.mandatory) {
                formValues.health_insurance = item.id
                formConfigValues.insurance = true
                formConfigValues.healthInsuranceId = item.id
              }
            }
          }

          const courseCost = service?.courseCosts()
          const alreadyActiveCosts = formConfig.courseCosts ?? []
          if (courseCost) {
            for (const item of courseCost) {
              if (item.mandatory) {
                const alreadyIncluded = alreadyActiveCosts.find((costItem) => costItem.id === item.id)
                if (alreadyIncluded) {
                  // console.log('🚀 ~ file: FieldPayTuition.js:81 ~ loadInitialData ~ alreadyIncluded:', alreadyIncluded)
                } else {
                  courseCosts.push({ id: item.id, cost: item.cost ?? null, frequency: item.frequency, name: item.name })
                  await service.isCostChecked(item.id)
                }
              }
            }
          }

          const materials = service.getSchoolMaterial() ?? []
          const foundMandatory = materials?.find((material) => material.mandatory)

          formConfigValues.courseCosts = courseCosts

          if (foundMandatory) {
            formValues.materials = true
            formValues.material = foundMandatory?.id
            formConfigValues.materials = true
            formConfigValues.material = foundMandatory?.id
            formConfigValues.mandatoryMaterial = true
          }

          form.setFieldsValue(formValues)
          const nconfig = {
            ...formConfig,
            ...formConfigValues
          }
          setFormConfig(nconfig)
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }
      if (!baseQuote) {
        loadInitialData()
      }
    }
  }, [course, prices])

  return (
    <Form
      form={form}
      layout='horizontal'
      name='basic'
      onFinish={handleFinishForm}
      autoComplete='off'
      scrollToFirstError
      requiredMark={false}
      initialValues={{
        // weeks: service.getMinWeeks()
      }}
      onFinishFailed={
        () => notification.info({
          message: 'Please, fill all the required data',
          placement: 'topLeft'
        })
      }
    >
      {contextHolder}
      {children}
    </Form>
  )
}

export { QuoterFormConfigured }
