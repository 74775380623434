import { Col, Form, Row, Select } from 'antd'
import Search from 'antd/lib/transfer/search'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useState } from 'react'
import { useGetDiscounts } from '../../../bussines/hooks/school/discounts/useGetDiscounts'

const { Option } = Select

function FiltersFormUploadPromotions({ searchFilter, setSearchFilter, program }) {
  const [offices, setOffices] = useState([])
  const {
    data: discounts
  } = useGetDiscounts({ type: 'table', program: 'short_term' ,...searchFilter })

  useEffect(() => {
    const newOffices = []
    if (discounts) {
      
      discounts?.forEach(discount => {
        discount?.sedes?.forEach(item => {
          newOffices.push(item)
        })
      })
    }

    if (newOffices?.length > 0) {
      const uniqueArray = newOffices.filter((item, index) => {
        // Devuelve true solo si el índice del primer elemento con el mismo "id" es igual al índice actual.
        return index === newOffices.findIndex((obj) => obj.id === item.id)
      })
      setOffices(uniqueArray)
    } else {
      setOffices(newOffices)
    }
  }, [discounts])

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  return (
    <Row style={{ marginBottom: '20px' }}>
      <Col xs={24} md={12} lg={6}>
        <Search
          className='byp-input-gray'
          placeholder='Search'
          onChange={(event) => debounceFilter('search', event.target.value)}
          allowClear
        />
      </Col>
      <Col span={8}>
        <Form.Item>
          <Select
            style={{ width: '70%', marginLeft: '5%' }}
            size='small'
            placeholder='All the schools'
            className='w-100'
            allowClear
            onChange={(e) => {
              e
                ? handleAddFilter('campus', e)
                : handleRemoveFilter('campus')
            }}
          >
            {offices?.map(office => <Option key={office.id} value={office.id}>{office.name}</Option>)}
          </Select>
        </Form.Item>

      </Col>
      <Col span={9}>
        <Select
          style={{ width: '70%', marginLeft: '5%' }}
          size='small'
          onChange={(e) => {
            e
              ? handleAddFilter('status', e)
              : handleRemoveFilter('status')
          }}
          placeholder='Status'
          className='w-100'
          
        >
          <Option key={0} value={null}>All status</Option>
          <Option key={1} value='Active'>Active</Option>
          <Option key={2} value='Inactive'>Inactive</Option>
        </Select>
      </Col>
    </Row>
  )
}

export { FiltersFormUploadPromotions }
