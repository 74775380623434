import { Form, Switch, Select, Row } from 'antd'
import React from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

const { Option } = Select

function FieldSchoolMaterials () {
  const { service, setFormConfig, formConfig } = useQuoter()
  const materials = service.getSchoolMaterial()

  const handleChangeSwitch = (b) => {
    setFormConfig({
      ...formConfig,
      materials: b
    })
  }

  const handleChange = (id) => {
    setFormConfig({
      ...formConfig,
      material: id
    })
  }

  return (
    <RowQuoter
      colA={
        <>
          {!formConfig.mandatoryMaterial && (
            <Row style={{ alignItems: 'baseline' }}>
              ¿Add materials?
              <Form.Item name='materials' valuePropName='checked'>
                <Switch
                  onChange={handleChangeSwitch}
                  className='byp-ml-1' checkedChildren='YES'
                  unCheckedChildren='NO'
                />
              </Form.Item>
            </Row>
          )}
          {
            formConfig.materials && (
              <>
                <Form.Item
                  name='material'
                  rules={[{
                    required: true,
                    message: 'Field required'
                  }]}
                  style={{ marginBottom: 0 }}
                >
                  <Select onChange={handleChange} size='small'>
                    {materials?.map((item) => (
                      <Option key={item.id} value={item.id}> {item.name} </Option>
                    ))}
                  </Select>
                </Form.Item>
                {
                  service.getMaterialDiscounts().length > 0 && (
                    service.getMaterialDiscounts()?.map((d) => (
                      <p key={d.id} className='byp-fw-bold'>
                        {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'dd LLL, yyyy')}
                      </p>
                    ))
                  )
                }
              </>
            )
          }
        </>
      }
      colB={
        <StatisticWithDiscounts
          discounts={service.getMaterialDiscounts()}
          amount={service.getAmountMaterials()}
          currency={service.getCurrency()}
          subtotal={service.getTotalMaterials()}
          active={formConfig.materials && service.getSelectedMaterial()}
        />
      }
    />
  )
}

export { FieldSchoolMaterials }
