import { useQuery } from 'react-query'
import { NationalityRepository } from '../../repositories/NationalityRepository'

function useGetAllNationalities (filters = {}) {
  return useQuery(
    ['useGetAllNationalities', filters],
    () => NationalityRepository.all(filters),
    {
      retry: 2
    })
}
export { useGetAllNationalities }
