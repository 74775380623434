import * as React from "react"

export const MarketingMaterialIcon = (props) => {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="nonecurrentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 .167v3.5a1.167 1.167 0 001.167 1.166h3.5v5.833a1.167 1.167 0 01-1.167 1.167H6.528a3.792 3.792 0 00-4.195-6.126V1.333A1.167 1.167 0 013.5.167H7zm.875.291v3.208a.292.292 0 00.292.292h3.208l-3.5-3.5zM7 9.208a3.208 3.208 0 10-6.417 0 3.208 3.208 0 006.417 0zM4.083 9.5l.001 1.46a.292.292 0 11-.583 0V9.5H2.039a.292.292 0 010-.584H3.5V7.458a.292.292 0 01.583 0v1.458H5.54a.292.292 0 010 .584H4.083z"
        fill="currentColor"
      />
    </svg>
  )
}