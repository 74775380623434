import { Col, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetSchoolsAverage } from '../../../../../bussines/hooks/agency/analytics/users/useGetAverages'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { SelectSchools } from '../../../../../common/components/helper-form/SelectInstitutions'
import { SelectProgramsFormItem } from '../../../../../common/components/helper-form/SelectProgramsFormItem'
import { ArrowDownIcons, ArrowUpIcons, CalendarIcon, LanguageIcon, LocationIcon } from '../../../../quote-preview/assets/imgs/Icons'
import { SelectCampus } from '../../components/SelectCampusAll'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'

const SchoolAverage = ({ user, office, time, firstOption = false }) => {
  const [program, setprogram] = useState()
  const [institution, setinstitution] = useState()
  const [campus, setCampus] = useState()
  const { data, isLoading, error } = useGetSchoolsAverage({ user, office, ...time, limit: 4, program, institution, campus })
  const _user = useAuth()

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        //
      } else if (error) {
        notification.error({
          message: 'Something went wrong'
        })
      }
    }
  }, [isLoading, data, error])

  function TextDisplayer ({ text, direccion, range }) {
    if (text) {
      if (range) {
        let i = 0
        while (text.charAt(i) !== ' ') {
          i++
        }
        if (text.substring(0, i) !== 'undefined') {
          return (
            <div className='d-flex justify-center align-center byp-my-1-vh' style={{ gap: '.3vw', whiteSpace: 'nowrap' }}>
              <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '2vw' }}> {text.substring(0, i)} <span className='byp-text-sm quote-overview--color-blue'>{text.substring(i + 1)}</span> </p>
              {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
            </div>
          )
        }
      }

      if (text.indexOf('undefined') !== 0) {
        if (text.length < 10) {
          return (
            <div className='d-flex justify-center align-center byp-my-1-vh' style={{ gap: '.5vw' }}>
              <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '3vh' }}>{text}</p>
              {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
            </div>
          )
        }
        return (
          <div className='d-flex justify-center align-center byp-my-0-5-vh' style={{ gap: '.5vw' }}>
            <p className='quote-overview--color-blue byp-m-0' style={{ fontSize: '2vh' }}>{text}</p>
            {direccion === 'up' ? <ArrowUpIcons /> : <ArrowDownIcons />}
          </div>
        )
      }
    }
  }

  return (
    <>
      <div className='d-flex average-gap-0-5 selects-responsive'>
        {_user?.user?.type !== 'Institution' &&
          <SelectSchools
            className='user-analysis__select byp-mb-1'
            placeholder=''
            style={{ width: 120 }}
            size='small'
            onChange={(val) => setinstitution(val)}
            firstOption={firstOption}
            value={institution}
            filters={_user?.user?.type === 'Education group' && { fromGroup: _user?.company?.name }}
          />}
        {_user?.user?.type === 'Institution' &&
          <SelectCampus
            className='user-analysis__select byp-mb-1'
            name='schools'
            style={{ width: 120 }}
            onChange={(val) => setCampus(val)}
            size='small'
            placeholder=''
            program={program}
            firstOption={firstOption}
            value={campus}
          />}
        <SelectProgramsFormItem
          className='user-analysis__select byp-mb-1'
          placeholder=''
          style={{ width: 170 }}
          size='small'
          onChange={(val) => setprogram(val)}
          firstOption={firstOption}
          value={program}
        />
      </div>

      {isLoading && <BasicSpinner />}
      {!isLoading && (
        <div className='program-average'>
          <h3 className='byp-color-gray-100 byp-text-0-8 byp-m-0'>Language</h3>
          <Row>
            <Col span={12}>
              <div className='program-average-card active' style={{ textAlign: 'center' }}>
                <LanguageIcon />
                <TextDisplayer text={data?.language?.major?.name} direccion='up' />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.language?.major?.count} quotes</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='program-average-card' style={{ textAlign: 'center' }}>
                <LanguageIcon />
                <TextDisplayer text={data?.language?.minor?.name} direccion='down' />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.language?.minor?.count} quotes</p>
              </div>
            </Col>
          </Row>

          <h3 className='byp-color-gray-100 byp-text-0-8 byp-m-0'>Range time</h3>
          <Row>
            <Col span={12}>
              <div className='program-average-card active' style={{ textAlign: 'center' }}>
                <CalendarIcon />
                <TextDisplayer text={data?.time?.major?.duration ? data?.time?.major?.duration : data?.time?.major?.weeks + ' weeks'} direccion='up' range />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.time?.major?.quotes_count} quotes</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='program-average-card' style={{ textAlign: 'center' }}>
                <CalendarIcon />
                <TextDisplayer text={data?.time?.minor?.duration ? data?.time?.minor?.duration : data?.time?.minor?.weeks + ' weeks'} direccion='down' range> </TextDisplayer>
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.time?.minor?.quotes_count} quotes</p>
              </div>
            </Col>
          </Row>

          <h3 className='byp-color-gray-100 byp-text-0-8 byp-m-0'>City</h3>
          <Row>
            <Col span={12}>
              <div className='program-average-card active' style={{ textAlign: 'center' }}>
                <LocationIcon />
                <TextDisplayer text={data?.city?.major?.name} direccion='up' />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.city?.major?.quotes_count} quotes</p>
              </div>
            </Col>
            <Col span={12}>
              <div className='program-average-card' style={{ textAlign: 'center' }}>
                <LocationIcon />
                <TextDisplayer text={data?.city?.minor?.name} direccion='down' />
                <p className='byp-m-0 byp-text-sm byp-color-gray-100'>{data?.city?.minor?.quotes_count} quotes</p>
              </div>
            </Col>
          </Row>
        </div>)}
    </>
  )
}

export default SchoolAverage
