import { Form, Select } from 'antd'
import React from 'react'
import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'

const { Option } = Select

function SelectPhoneCodeFormItem ({ showSearch, loading, isListField, fieldKey, noStyle, ...props }) {
  const { isLoading, data: countries } = useGetAllNationalities()
  const filterOptions = (input, option) => {
    return option.value.indexOf(input) >= 0
  }

  return (
    <Form.Item {...props} noStyle={noStyle}>
      <Select
        size='large'
        showSearch
        loading={isLoading}
        filterOption={filterOptions}
        placeholder={props.placeholder ?? ''}
        {...props}
      >
        {countries?.map(item => (
          <Option key={item.id} value={'+' + item.phone_code + ':' + item.iso2}>
            <img src={`https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${item.iso2}.svg`} alt={item.name} width='20' />
            <span>
              {`+${item.phone_code}`}
            </span>
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectPhoneCodeFormItem }
