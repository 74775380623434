import * as React from "react"

export const MyPlanIcon = (props) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.415 4.827l-3.47-.504-1.553-3.146a.44.44 0 00-.785 0L4.057 4.323l-3.471.504a.437.437 0 00-.242.747l2.511 2.448-.593 3.458a.437.437 0 00.634.46L6 10.309l3.105 1.633a.437.437 0 00.634-.46l-.593-3.459 2.511-2.448a.437.437 0 00-.242-.747z"
        fill="currentColor"
      />
    </svg>
  )
}