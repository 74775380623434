import { useQuery } from 'react-query'
import TransportRepository from '../../repositories/TransportRepository'

function useGetCampusOfFee (campusId, feeId, filter) {
  const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetCampusOfFee', campusId, feeId, queryString],
    () => TransportRepository.getFeeCampusList(campusId, feeId, queryString), {
      retry: 2,
      enabled: !!campusId
    })
}

export { useGetCampusOfFee }
