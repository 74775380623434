import logo from './../assets/images/logo.png'
import book from './../assets/images/book.png'
import { useNavigate } from 'react-router-dom'
import { URL_QUOTER_PAGE } from '../../pages/quoter'

const AppLogoNameHeader = ({ changeImage = false, img, style, ...props }) => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(URL_QUOTER_PAGE)
  }
  if (img) {
    return (
      <img
        style={style ?? { cursor: 'pointer' }}
        onClick={handleOnClick}
        {...props}
        src={img} alt='byp'
      />
    )
  }
  return (
    <img
      style={style ?? { cursor: 'pointer' }}
      onClick={handleOnClick}
      {...props}
      src={changeImage ? book : logo} alt='byp'
    />
  )
}

export { AppLogoNameHeader }
