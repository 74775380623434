import React from 'react'
import { Collapse, Modal, Typography } from 'antd'
import '../common/assets/css/modal-error.less'
import SessionService from '../services/SessionService'
import { superAdminRoles } from './UsersAndRoles'
const { Text } = Typography
const { Panel } = Collapse

const defaultMessage = 'Ocurrió un error al tratar de contactar al servidor' +
', por favor, trata de nuevo en unos minutos.' +
' Si el error continua contactanos a admin@quoteyourprogram.com'
const defaultTitle = 'Algo salió mal'
const config = (
  errorDetails,
  message = defaultMessage,
  title = defaultTitle
) => ({
  title,
  content: (
    <>
      <Text className='w-100'>
        {message}
      </Text>
      {errorDetails && (
        <Collapse bordered={false} className='modal-error-details'>
          <Panel header='Error details' key='details'>
            <Text>{errorDetails}</Text>
          </Panel>
        </Collapse>
      )}
    </>
  ),
  icon: '',
  className: 'byp-modal-error'
})

const showErrorModal = (errorDetails, message = defaultMessage, title = defaultTitle) => {
  const session = SessionService.getCurrentSession()
  if (session) {
    const { user } = session
    const validEmails = ['nashiely@edulynks.com']
    const validRoles = superAdminRoles
    const isValidEmail = validEmails.includes(user.email)
    const isValidRole = validRoles.includes(user.role_name)
    if (isValidEmail || isValidRole) {
      Modal.error(config(errorDetails, message, title))
      return
    }
  }
  Modal.error(config(undefined, message, title))
}
export {
  config,
  showErrorModal,
  defaultMessage
}
