import { Form, Select } from 'antd'
import React from 'react'
import { useGetAllNationalities } from '../../../bussines/hooks/quoter/useGetAllNationalities'

const { Option } = Select

function SelectAllCitiesFormItem (props) {
  const { mode } = props
  const { isLoading, data: cities } = useGetAllNationalities()
  return (
    <Form.Item {...props}>
      <Select
        loading={isLoading}
        mode={mode ?? null}
        placeholder={props.placeholder ?? ''}
        disabled={props.disabled}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {cities?.map((item) => (
          <Option key={item.id} value={item.id}>{`${item.name}`}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectAllCitiesFormItem }
