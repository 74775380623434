import { Col, Divider, Form, Input, Row } from 'antd'
import React from 'react'
import { formatIntake, formatStartDate } from '../../../utils/formatQuoteStartDate'
import { convertirFecha2 } from '../../../utils/getEndIntake'

const required = { required: true, message: 'This element is required' }
const lenValidation = { len: 10, message: 'The phone number must be exactly 10 characters' }

function InfoQuote ({ quote, showPhoneInput = false }) {
  console.log('🚀 ~ InfoQuote ~ quote:', quote)
  return (
    <div className='info-quote__container'>
      <h2>Aparta tu lugar</h2>
      <p>A continuación te compartimos los pasos a seguir para que tu aplicación sea un éxito</p>
      <Divider />

      <h3 style={{ marginBottom: '0.75rem' }}>Confirma la duración del curso</h3>
      <Row gutter={[16, 16]} style={{ marginBottom: '0.75rem' }}>
        <Col xs={24} lg={12} span={12}>
          <div className='d-flex' style={{ gap: '0.5rem' }}>
            <span style={{ fontWeight: '700' }}>Inicio de Clases</span>
            <p style={{ fontWeight: '300' }}>{quote?.intake ? formatIntake(quote?.intake) : formatStartDate(quote?.course?.start)}</p>
          </div>
        </Col>
        <Col xs={24} lg={12} span={12}>
          <div className='d-flex' style={{ gap: '0.5rem' }}>
            <span style={{ fontWeight: '700' }}>Fin de Clases</span>
            <p style={{ fontWeight: '300' }}>{quote?.intake ? formatIntake(convertirFecha2(quote?.intake, quote?.year?.year, quote?.course?.length)) : formatStartDate(quote?.course?.end)}</p>
          </div>
        </Col>
      </Row>

      {(quote?.course?.program === 'academic_years' || quote?.course?.program === 'boarding_schools') && (
        <>
          <h3 style={{ marginBottom: '0.75rem' }}>Tipo de programa</h3>
          <Row gutter={[16, 16]} style={{ marginBottom: '0.75rem' }}>
            <Col xs={24} lg={24} span={24}>
              <div className='d-flex' style={{ gap: '0.5rem' }}>
                <span style={{ fontWeight: '700' }}>Años Académicos</span>
                <p style={{ fontWeight: '300' }}>{quote?.course?.name}</p>
              </div>
              <div className='d-flex' style={{ gap: '0.5rem' }}>
                <span style={{ fontWeight: '700' }}>Duración: </span>
                <p style={{ fontWeight: '300' }}>Año Académico</p>
              </div>
              <div className='d-flex' style={{ gap: '0.5rem' }}>
                <span style={{ fontWeight: '700' }}>Grado: </span>
                <p style={{ fontWeight: '300' }}>{quote?.grade}</p>
              </div>
            </Col>
          </Row>
        </>
      )}

      {quote?.lodging && (
        <>
          <h3>Sobre tu alojamiento</h3>
          <p className='info-quote__acomodation' style={{ marginBottom: '0.75rem' }}>{quote?.lodging?.name}</p>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <span>Llegada</span>
              <p>{formatStartDate(quote?.lodging?.start)}</p>
            </Col>
            <Col span={12}>
              <span>Salida</span>
              <p>{formatStartDate(quote?.lodging?.end)}</p>
            </Col>
          </Row>
        </>
      )}

      <Divider />
      {showPhoneInput && (
        <>
          <Row>
            <Col span={24} className='info-quote_phone-container'>
              <Form.Item name='phone' rules={[required, lenValidation]} label='Para continuar ingresa tu número de teléfono'>
                <Input placeholder='Teléfono' />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
        </>
      )}

      <div className='info-quote__terminos'>
        <p className='terminos-mobile fs-14 fw-4 text-black'>Al continuar tu proceso de admisión das por hecho que has aceptado los
          <span className='info-quote__terminos-span'> términos y condiciones </span>
          así como las <span className='info-quote__terminos-span'> políticas de privacidad</span>
        </p>
      </div>

      <Divider />
    </div>
  )
}

export default InfoQuote
