import React from 'react'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { ReportsUniInfo } from './ReportsUniInfo'

const URL_REPORTS_UNI_PAGE = '/dashboard/reports'

function ReportsUniPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
      isInBetaState
    >
      <MainTheme
        showMenu
      >
        <ReportsUniInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { ReportsUniPage, URL_REPORTS_UNI_PAGE }
