import React, { useEffect, useState } from 'react'
import { Row, Spin } from 'antd'
import { ButtonCategorySelector } from './ButtonsCategorySelector'
import { useGetMyServiceCategories } from '../../../bussines/hooks/school/useGetMyServiceCategories'
import { useSearch } from '../../../providers/search/search-context-provider'
import LottieCargando from '../../../lotties/LottieCargando'
import { AdvanceSearchModal } from './AdvancedSearchModal'
import { useNavigate } from 'react-router-dom'

function getCollection (programs) {
  const menuItems = programs?.map((program) => (
    {
      label: program.label,
      key: program.name,
      form: <p>form</p>
    }
  ))
  return menuItems ?? []
}

const FilterCategoryStatic = ({ staticPage = false, params }) => {
  const [collection, setCollection] = useState([])
  // const [formSelected, changeFormSelect] = React.useState('')
  const { isLoading, data } = useGetMyServiceCategories()
  const { changeSearch, totalResults, isSearching } = useSearch()
  const [isActive, setIsActive] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (data && !isLoading) {
      const items = getCollection(data)
      const firstItem = items[0] ?? null
      // console.log('🚀 ~ file: FilterCategory.js ~ line 86 ~ useEffect ~ firstItem', firstItem)
      // changeFormSelect(firstItem?.key)
      sortCollections(items)
      // Here makes the search at the home page by default
      changeSearch('LANG_FORM', {
        all: true,
        program: firstItem?.key
      })
    }
  }, [isLoading, data])

  const handleChangeProgram = (program) => {
    const programs = []

    // changeFormSelect(program)
    if (getProgram(program) && getProgram(program) !== 'graduate') programs.push(getProgram(program))
    if (getSubType(program)) programs.push(getSubType(program))
    if (getType(program)) programs.push(getType(program))

    navigate(
      '/quote2',
      {
        state: {
          program: programs, countries: params.countries, search: params.search, advanced: true, institutions: params.institutions
        }
      }
    )
  }

  const sortCollections = (data) => {
    const orden = ['language', 'academic_years', 'boarding_schools', 'graduate', 'short_term']
    let items = orden.map(orden => {
      return data.find(x => x.key === orden)
    })
    items = items.filter(item => item)

    setCollection(items)
  }

  const types = ['bachelor', 'postgraduate', 'master', 'diploma']
  const subtypes = ['diploma', 'advanced_diploma', 'certificate']
  const getProgram = (program) => {
    if (types.includes(program) || subtypes.includes(program)) {
      return 'graduate'
    }
    return program
  }

  const getType = (program) => {
    if (types.includes(program)) {
      return types[types.indexOf(program)]
    }
    return null
  }
  const getSubType = (program) => {
    if (subtypes.includes(program)) {
      return subtypes[subtypes.indexOf(program)]
    }
    return null
  }

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <>
      <div style={{ paddingTop: '20px' }}>
        <div className='home-btns-responsive'>
          <ButtonCategorySelector collection={collection} onChange={handleChangeProgram} selected='search' setIsActive={setIsActive} />
        </div>
        <div style={{ margin: '24px 8px' }}>
          {totalResults >= 0 && (
            <Row className='byp-centered byp-mb-1'>
              {totalResults > 0 && !isSearching && (
                <h6 className='byp-blue-100 byp-fw-bold'>Look among {totalResults} results!</h6>
              )}
              {totalResults === 0 && !isSearching && (
                <h6 className='byp-blue-100 byp-fw-bold'>No results</h6>
              )}
              {isSearching && (
                <h6 className='byp-blue-100 byp-fw-bold'>
                  <Spin />
                </h6>
              )}
            </Row>
          )}
        </div>
      </div>

      <AdvanceSearchModal isActive={isActive} setIsActive={setIsActive} collection={collection} staticPage={staticPage} params={params} />
    </>
  )
}

export { FilterCategoryStatic }
