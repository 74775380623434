import { Button, Col, Collapse, Form, Row, Modal, Input, Space, Tag } from 'antd'
import { useEffect, useState } from 'react'
import { useGetCampus } from '../../../../bussines/hooks/school/useGetCampus'
import { useGetServiceByCategoryAndSede } from '../../../../bussines/hooks/school/useGetServiceByCategoryAndSede'
import { usePostServiceSavePrices } from '../../../../bussines/hooks/school/usePostServiceSavePrices'
import { ConceptCostListFormItem } from '../components/ConceptCostListFormItem'
import { CoursePriceRangeFormList } from '../components/CoursePriceRangeFormList'
import utilsState from '../../../../bussines/utils/state-control'
import { useLocation, useNavigate } from 'react-router-dom'
import { showErrorModal } from '../../../../utils/propsForModal'
import { SelectCampusOfCourse } from '../../../../common/components/helper-form/SelectCampusOfCourse'
import { URL_LODGING_CREATE_PAGE } from '../Lodging/CreateLodgingsPage'
import { SelectCampusAndYearByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusAndYearByProgramFormItem'
import { useFindProgram } from '../../../../bussines/hooks/program/useFindProgram'
import { FormConcept } from './FormConcept'
import { SelectCampusProgramYearAndCourseFormItem } from '../../../../common/components/helper-form/SelectCampusProgramYearAndCourseFormItem'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { URL_DISCOUNTS_CREATE_PAGE } from '../Discounts/CreateDiscountsPage'
import LottieCargando from '../../../../lotties/LottieCargando'
import { ModalUserAlias } from '../../../../common/components/ModalUserAlias'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

const { Panel } = Collapse

const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const data = values[name][position]
  const label = data?.label
  const status = data?.offer_status === 'Aproved' ? 'Approved' : data?.offer_status
  return <span>{label}  <Tag style={{ marginLeft: '1rem' }}>{status}</Tag>  </span>
}

function FormCreateCoursePrices ({ program, type }) {
  const [form] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()

  const { campusId, yearId: yearID } = location.state ?? {
    campusId: undefined,
    yearId: undefined
  }
  const { course_name: courseName, type: programType, subtype } = location.state ?? {}

  const [campusID, setCampusID] = useState(campusId)
  const [programID, setProgramID] = useState()
  const [yearId, setYearId] = useState(yearID)
  const [divisa, setDivisa] = useState()
  const [aprove, setAprove] = useState(false)
  const [subType, setSubType] = useState(type)
  const [course, setCourse] = useState()
  const [courseData, setCouseData] = useState([])
  const [defaultOp, setDefaultOp] = useState(['0'])
  const [savingAll, setSavingAll] = useState(false)
  const [modal, contextHolder] = Modal.useModal()

  const [openModalAlias, setOpenModalAlias] = useState(false)
  const [valiatedData, setValiatedData] = useState({})

  const { data: programData, isLoading: isLoadingProgram } =
    useFindProgram(program)
  const {
    data: coursesData,
    isLoading,
    refetch
  } = useGetServiceByCategoryAndSede(programID, campusID, yearId, subType)
  const { data: campus, isLoading: isLoadingCampus } = useGetCampus(campusID)
  const {
    currentState,
    isLoading: savingPrices,
    savePrices,
    error
  } = usePostServiceSavePrices()

  const onFinish = (values, toAprove = false, index) => {
    console.log('🚀 ~ file: FormCreateCoursePrices.js:144 ~ onFinish ~ values:', values)
    values.toAprove = toAprove
    if (!values.program_id) {
      values.program_id = programData?.id
    }
    if (index === undefined) {
      setAprove(toAprove)
      setSavingAll(true)
      // savePrices(values)
      setValiatedData(values)
      setOpenModalAlias(true)
    } else {
      setSavingAll(false)
      const course = values.courses[index]
      values.courses = [course]
      form.validateFields().then((valid) => {
        // savePrices(values, index)
        setValiatedData(values)
        setOpenModalAlias(true)
      })
      setAprove(false)
      setSavingAll(false)
    }
  }

  const setFormData = () => {
    coursesData?.find((course, index) => {
      if (course.label === courseName) {
        setDefaultOp([index + ''])
        return true
      }
      return false
    })
    coursesData?.forEach((course) => {
      if (course.concepts.length === 0) {
        if (program === 'boarding_schools') {
          if (course?.concepts?.length <= 0 && course?.costs.length >= 0) {
            course.concepts = course.costs
            course.costs = []
          }
        }

        if (course?.concepts?.length <= 0) {
          if (
            program === 'graduate' ||
            program === 'academic_years' ||
            program === 'boarding_schools'
          ) {
            course.concepts = [{ name: '', cost: 0, frecuency: '' }]
          }
        }
      }
    })
    // TODO: Remove the innecesary data
    const cleanData = coursesData?.map(itemData => {
      return {
        id: itemData.id,
        name: itemData.name,
        label: itemData.label,
        costs: itemData.costs,
        concepts: itemData.concepts,
        minnor_costs: itemData.minnor_costs
      }
    })
    form.setFieldsValue({
      courses: cleanData,
      translation: null
    })
    console.log('🚀 ~ setFormData ~ cleanData:', cleanData)
  }

  const onUpdate = ({
    campus_id: campusID,
    program_id: programID,
    year_id: yearID
  }) => {
    // console.log('campusID', campusID)
    // console.log('yearID', yearID)
    if (campusID !== undefined) {
      setCampusID(campusID)
      setYearId()
      form.setFieldsValue({ year_id: null })
    }
    if (yearID !== undefined) setYearId(yearID)
  }

  const list = Form.useWatch('courses', form) || []
  const listName = 'courses'

  const onContinue = (values) => {
    if (program === 'boarding_schools') {
      navigate(URL_DISCOUNTS_CREATE_PAGE.replace(':program', program), {
        state: {
          menuItemSelected: program + '_discounts_create',
          openSub: program,
          openSub2: program + '_discounts'
        }
      })
    } else {
      navigate(URL_LODGING_CREATE_PAGE.replace(':program', program), {
        state: {
          menuItemSelected: program + '_lodgings_create',
          openSub: program,
          openSub2: program + '_lodgings'
        }
      })
    }
  }

  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const data = coursesData?.map((course) => {
        let concepts = []
        let costs = course.costs

        if (course.concepts.length === 0) {
          if (program === 'boarding_schools') {
            if (course?.concepts?.length <= 0 && course?.costs.length >= 0) {
              concepts = course.costs?.map(cost => {
                const nameTraslated = cost.translations?.find(translation => translation.to === lang)
                return {
                  ...cost,
                  name: nameTraslated?.translation
                }
              })
              costs = []
            }
          }
          if (course?.concepts?.length <= 0) {
            if (
              program === 'graduate' ||
              program === 'academic_years' ||
              program === 'boarding_schools'
            ) {
              concepts = [{ name: '', cost: 0, frecuency: '' }]
            }
          }
        } else {
          concepts = course.concepts?.map(cost => {
            const nameTraslated = cost.translations?.find(translation => translation.to === lang)
            return {
              ...cost,
              name: nameTraslated?.translation
            }
          })
        }

        const extras = course.minnor_costs?.map(cost => {
          const nameTraslated = cost.translations?.find(translation => translation.to === lang)
          return {
            ...cost,
            name: nameTraslated?.translation
          }
        })
        return {
          ...course,
          concepts,
          costs,
          minnor_costs: extras
        }
      })
      const cleanData = data?.map(itemData => {
        return {
          id: itemData.id,
          name: itemData.name,
          label: itemData.label,
          costs: itemData.costs,
          concepts: itemData.concepts,
          minnor_costs: itemData.minnor_costs
        }
      })
      form.setFieldsValue({
        courses: cleanData,
        translation: lang
      })
    } else {
      setFormData()
    }
  }

  useEffect(() => {
    if (!savingPrices && currentState === utilsState.STATE_SUCCESS) {
      modal.success({
        title: 'Success!',
        content: 'Información guardada',
        onOk () {
          if (aprove && savingAll) {
            if (program === 'boarding_schools') {
              const URL = URL_DISCOUNTS_CREATE_PAGE.replace(
                ':program',
                program
              )
              navigate(URL, {
                state: {
                  menuItemSelected: program + '_discounts_create',
                  openSub: program,
                  openSub2: program + '_discounts'
                }
              })
            } else {
              const URL = URL_LODGING_CREATE_PAGE.replace(':program', program)
              navigate(URL, {
                state: {
                  menuItemSelected: program + '_lodgings_create',
                  openSub: program,
                  openSub2: program + '_lodgings'
                }
              })
            }
          } else {
            refetch()
          }
        }
      })
      setOpenModalAlias(false)
    }
  }, [savingPrices, currentState])

  useEffect(() => {
    if (type) form.setFieldsValue({ subtype: type })
  }, [type])

  useEffect(() => {
    setSubType(type)
  }, [type])

  useEffect(() => {
    setCouseData(coursesData)
  }, [coursesData])

  useEffect(() => {
    course === 'all'
      ? setCouseData(coursesData)
      : setCouseData(
        coursesData?.filter((courseData) => courseData.id === course)
      )
  }, [course])

  useEffect(() => {
    if (!isLoadingProgram && programData) {
      form.setFieldsValue({ program_id: programData?.id })
      setProgramID(programData?.id)
    }
  }, [programData, isLoadingProgram])

  useEffect(() => {
    if (error?.response.data) {
      showErrorModal(modal, error.response.data.message)
    }
  }, [error])

  // Get Divisa for campus
  useEffect(() => {
    if (!isLoadingCampus && campus) {
      form.setFieldsValue({ divisa_code: campus?.divisa_code })
      setDivisa(campus?.divisa_code)
    }
  }, [campus])

  // Get courses Data
  useEffect(() => {
    if (!isLoading && courseData) {
      setFormData()
    }
  }, [isLoading, courseData])

  return (
    <>
      <Form
        layout='vertical'
        scrollToFirstError
        name='FormCreateQuote'
        onFinish={onFinish}
        form={form}
        onValuesChange={onUpdate}
        initialValues={{
          campus_id: campusID,
          program_id: programID,
          year_id: yearId,
          subtype: subtype ?? programType
        }}
      >
        {contextHolder}
        <div className='byp-dashboard-content'>
          <Row gutter={24}>
            <Col span={16}>
              <h5 className='byp-title'>Course prices</h5>
            </Col>
            <Col span={8}>
              <Row
                gutter={[5]}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Col span={4} className='byp-text-sm byp-fw-bold'>
                  You're at:
                </Col>
                <Col xs={12} md={10}>
                  <Form.Item className='margin-0'>
                    <Input
                      className='graduate_show_category--input'
                      value={programData?.label ?? '__________'}
                      readOnly
                      size='small'
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} md={10}>
                  <SelectCampusByProgramFormItem
                    size='small'
                    program={program}
                    name='campus_id'
                    className='graduate_show_type school'
                    disabled
                    placeholder='School name'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {['graduate', 'academic_years', 'boarding_schools'].includes(program) && (
            <SelectCampusProgramYearAndCourseFormItem
              program={program}
              label={programData?.label}
              campusID={campusID}
              courses={coursesData}
              setSubType={setSubType}
              setCourse={setCourse}
              form={form}
              haveSearch
              pricesData={courseData}
            />
          )}
          {!['graduate', 'academic_years', 'boarding_schools'].includes(program) && (
            <SelectCampusAndYearByProgramFormItem
              program={program}
              label={programData?.label}
              campusID={campusID}
            />
          )}
          {isLoading && <LottieCargando />}
          {coursesData && coursesData.length > 0 && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '.5rem' }}>
              <SelectAvailableTranslationsFormItem
                name='translation'
                formItemProps={{
                  noStyle: true
                }}
                selectProps={{
                  size: 'small',
                  style: { minWidth: '8rem' },
                  onChange: (lang) => handleChangeTranslateLanguage(lang),
                  allowClear: true
                }}
              />
            </div>
          )}
          {coursesData !== undefined && coursesData.length > 0 && (
            campusID && programID && yearId && !isLoading && (
              <Form.List name={listName}>
                {(fields, { add, remove }, { errors }) => (
                  <Collapse
                    defaultActiveKey={courseName ? [] : ['0']}
                    expandIconPosition='right'
                    className='course-prices'
                    activeKey={defaultOp}
                    onChange={setDefaultOp}
                  >
                    {courseName
                      ? fields.map(({ key, name, ...restField }, index) => (
                        <Panel
                          header={panelHeader(
                            restField.fieldKey,
                            form.getFieldValue(),
                            'courses'
                          )}
                          key={index}
                          className='byp-p-0'
                        >
                          <Row
                            className='byp-list-course-item-content'
                            gutter={16}
                          >
                            <Col lg={14}>
                              <h6 className='w-100'>
                                {' '}
                                Currency{' '}
                                <span className='byp-ml-1 byp-fw-bold'>
                                  {divisa}
                                </span>{' '}
                              </h6>
                              {program !== 'graduate' && program !== 'academic_years' && program !== 'boarding_schools'
                                ? (
                                  <>
                                    <h6 className='w-100'>
                                      <b>Time ranges</b>
                                    </h6>
                                    <CoursePriceRangeFormList
                                      name={[name, 'costs']}
                                      divisa={divisa}
                                    />
                                  </>
                                )
                                : (
                                  <FormConcept
                                    name={[name, 'concepts']}
                                    divisa={divisa}
                                    program={program}
                                    form={form}
                                    listName={listName}
                                  />
                                )}
                            </Col>
                            <Col lg={10}>
                              <h6 className='byp-title'>
                                Courses where this course prices are the same
                              </h6>
                              <SelectCampusOfCourse
                                name={[name, 'campus']}
                                campus={campusID}
                                course={list[key]?.id}
                                filter={{ year: yearId }}
                                mode='multiple'
                              />
                              {program !== 'graduate' &&
                                program !== 'academic_years' &&
                                program !== 'boarding_schools' && (
                                  <Row className='w-100'>
                                    <h6>
                                      <b>Extra cost</b>
                                    </h6>
                                    <ConceptCostListFormItem
                                      name={[name, 'minnor_costs']}
                                      divisa={divisa}
                                    />
                                  </Row>
                                )}
                            </Col>
                          </Row>
                          <div className='byp-list-course-item-footer'>
                            <Row className='w-100'>
                              <Col span={24}>
                                <Button
                                  className='byp-ml-1 float-right byp-btn-blue-100'
                                  onClick={() => {
                                    onFinish(
                                      form.getFieldsValue(),
                                      true,
                                      index
                                    )
                                  }}
                                  loading={savingPrices}
                                >
                                  Approve
                                </Button>
                                <Button
                                  className='float-right byp-btn-blue-200'
                                  onClick={() => {
                                    onFinish(
                                      form.getFieldsValue(),
                                      false,
                                      index
                                    )
                                  }}
                                  loading={savingPrices}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Panel>
                      ))
                      : fields.map(({ key, name, ...restField }, index) => (
                        <Panel
                          header={panelHeader(
                            restField.fieldKey,
                            form.getFieldValue(),
                            'courses'
                          )}
                          key={index}
                          className='byp-p-0'
                        >
                          <Row
                            className='byp-list-course-item-content'
                            gutter={15}
                          >
                            <Col lg={14}>
                              <h6 className='w-100'>
                                {' '}
                                Currency{' '}
                                <span className='byp-ml-1 byp-fw-bold'>
                                  {divisa}
                                </span>{' '}
                              </h6>
                              {
                                program !== 'graduate' &&
                                  program !== 'academic_years' &&
                                  program !== 'boarding_schools'
                                  ? (
                                    <>
                                      <h6 className='w-100'>
                                        <b>Time ranges</b>
                                      </h6>
                                      <CoursePriceRangeFormList
                                        name={[name, 'costs']}
                                        divisa={divisa}
                                      />
                                    </>
                                  )
                                  : (
                                    <FormConcept
                                      name={[name, 'concepts']}
                                      divisa={divisa}
                                      program={program}
                                      form={form}
                                      listName={listName}
                                    />
                                  )
                              }
                            </Col>
                            <Col lg={10}>
                              <h6 className='byp-title'>
                                Courses where this course prices are the same
                              </h6>
                              <SelectCampusOfCourse
                                name={[name, 'campus']}
                                campus={campusID}
                                course={list[key]?.id}
                                filter={{ year: yearId }}
                                mode='multiple'
                              />
                              {program !== 'graduate' &&
                                program !== 'academic_years' &&
                                program !== 'boarding_schools' && (
                                  <Row className='w-100'>
                                    <h6>
                                      <b>Extra cost</b>
                                    </h6>
                                    <ConceptCostListFormItem
                                      name={[name, 'minnor_costs']}
                                      divisa={divisa}
                                    />
                                  </Row>
                                )}
                            </Col>
                          </Row>
                          <div className='byp-list-course-item-footer'>
                            <Row className='w-100'>
                              <Col span={24}>
                                <Button
                                  className='byp-ml-1 float-right byp-btn-blue-100'
                                  onClick={() => {
                                    onFinish(
                                      form.getFieldsValue(),
                                      true,
                                      index
                                    )
                                  }}
                                  loading={savingPrices}
                                >
                                  Approve
                                </Button>
                                <Button
                                  className='float-right byp-btn-blue-200'
                                  onClick={() => {
                                    onFinish(
                                      form.getFieldsValue(),
                                      false,
                                      index
                                    )
                                  }}
                                  loading={savingPrices}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Panel>
                      ))}
                  </Collapse>
                )}
              </Form.List>)
          )}
          {coursesData !== undefined && coursesData.length === 0 && (
            <h4 style={{ color: 'black', marginTop: '10px' }}>
              <b>
                <i>There's nothing here. You need to create a course first</i>
              </b>
            </h4>
          )}
        </div>
        <div className='byp-dashboard-footer'>
          <Row gutter={12} className='byp-form-footer'>
            <Col span={24}>
              <Space style={{ width: '100%', justifyContent: 'end' }}>
                <Button
                  className='byp-btn-blue-100-outline byp-mt-1'
                  htmlType='submit'
                  loading={savingPrices}
                  onClick={onContinue}
                >
                  Continue
                </Button>
                <Button
                  className='byp-btn-blue-100 byp-mt-1'
                  onClick={() => {
                    onFinish(form.getFieldsValue(), true)
                  }}
                  loading={savingPrices}
                  disabled={!campusId && !yearId}
                >
                  Approve All
                </Button>
                <Button
                  className='byp-btn-blue-200 byp-mt-1'
                  htmlType='submit'
                  loading={savingPrices}
                  disabled={!campusId && !yearId}
                >
                  Save all
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Form>
      <ModalUserAlias
        isModalOpen={openModalAlias}
        setIsModalOpen={setOpenModalAlias}
        onSuccess={(userAlias) => {
          valiatedData.user_alias = userAlias
          savePrices(valiatedData)
        }}
        isLoading={savingPrices}
      />
    </>
  )
}

export { FormCreateCoursePrices }
