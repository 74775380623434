import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'
import removeInvalidValuesFromParams from '../../../../../utils/removeInvalidValuesFromParams'

function useGetQuoteActionsDetails (user, filters = {}) {
  for (const key in filters) {
    if (filters[key] === undefined || !filters[key]?.length === 0) {
      delete filters[key]
    }
  }

  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(
    ['useGetQuoteActionsDetails', filtersQuery, user],
    () => GeneralAnalysisRepository.getQuoteActionsByUser(user, filtersQuery),
    {
      enabled: !!user || !!filters.office
    }
  )
}

function useGetQuoteActionsDetailsReports (filters = {}) {
  const validParams = removeInvalidValuesFromParams(filters)
  return useQuery(
    ['useGetQuoteActionsDetailsReports', validParams],
    () => GeneralAnalysisRepository.getQuoteActions(validParams),
    {
      enabled: !!filters
    }
  )
}

export { useGetQuoteActionsDetails, useGetQuoteActionsDetailsReports }
