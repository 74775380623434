import { Col, Form, InputNumber, Row, Select } from 'antd'
const { Option } = Select
const CourseLengthFormItem = (props) => {
  return (
    <Row className='w-100'>
      <h6 className='byp-title w-100 byp-fw-bold'>Program Length</h6>
      <Col span={18}>
        <Row gutter={[16, 16]}>
          <Col span={4}>Length</Col>
          <Col span={6}>
            <Form.Item
              name='length'
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={3} style={{ textAlign: 'center' }}>of</Col>
          <Col span={11}>
            <Form.Item
              name='time'
            >
              <Select
                size='small'
              >
                <Option value='days'>days</Option>
                <Option value='weeks'>weeks</Option>
                <Option value='months'>months</Option>
                <Option value='semester'>Semester</Option>
                <Option value='years'>years</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { CourseLengthFormItem }
