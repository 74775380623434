import { Checkbox, Col, Form, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetUserAndInvitation } from '../../../bussines/hooks/user/useGetUserAndInvitation'
import { useAuth } from '../../../providers/auth/auth-context-provider'
const { Option } = Select

const SelectOtherUsersFormItem = ({ name, label, program, filters = {}, ...rest }) => {
  const { data } = useGetUserAndInvitation(filters)
  const [validUsers, setValidUsers] = useState([])
  const _form = Form.useFormInstance()
  const { user } = useAuth()

  useEffect(() => {
    if (data?.users) {
      console.log('🚀 ~ file: SelectOtherUsersFormItem.js:15 ~ useEffect ~ data:', data)
      const validData = data?.users?.filter((item) => item.id !== user.id)
      setValidUsers(validData)
    }
  }, [data])

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      const ids = validUsers.map((user) => user.id)
      // console.log('🚀 ~ file: SelectOtherUsersFormItem.js:25 ~ onCheckAllChange ~ ids:', ids)
      _form.setFieldsValue({
        [name]: ids
      })
    }
  }

  return (
    <Row className='byp-dashboard-header byp-mb-1'>
      <Col xs={24} md={7} className='byp-justify-centered'>
        <Form.Item className='byp-m-0-important prefix' name={name + '_all'} valuePropName='checked'>
          <Checkbox onChange={onCheckAllChange}>
            All agents
          </Checkbox>
        </Form.Item>
      </Col>
      <Col xs={24} md={17} className='byp-justify-centered'>
        <Form.Item
          name={name}
          label={label}
          className='byp-m-0-important'
        >
          <Select
            style={{ width: '100%' }}
            placeholder=''
            // loading={isLoading}
            mode='multiple'
            className='byp-m-0-important'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
          >
            {validUsers?.map((item) => (
              <Option key={'user_' + item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}

export { SelectOtherUsersFormItem }
