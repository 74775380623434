const PUBLISH_QUOTES_STATES = {
  language: { menuItemSelected: 18, openSub: 'sub3' },
  spring_courses: { menuItemSelected: 19, openSub: 'sub3' },
  academic_years: { menuItemSelected: 20, openSub: 'sub3' },
  boarding_schools: { menuItemSelected: 21, openSub: 'sub3' },
  diplomas_he: { menuItemSelected: 22, openSub: 'sub3' },
  bachelors_he: { menuItemSelected: 23, openSub: 'sub3' },
  graduate: { menuItemSelected: 24, openSub: 'sub3' },
  services: { menuItemSelected: 25, openSub: 'sub3' }
}

export { PUBLISH_QUOTES_STATES }
