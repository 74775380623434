function convertirFecha2 (intake, yearStart, duration) {
  const parts = duration.split(' ')
  const yearEnd = Number(parts[0]) + Number(yearStart)

  // Sumar dependiendo del tipo de duración
  switch (parts[1]) {
    case 'months':
      return sumarMeses(intake, Number(parts[0]))
    case 'days':
      return sumarDias(intake, Number(parts[0]))
    case 'years':
      return `${intake} ${yearEnd}`
    case 'weeks':
      return sumarSemanas(intake, Number(parts[0]))
    case 'semester':
      return sumarSemestres(intake, yearEnd, Number(parts[0]))
    default:
      return 'Duración no reconocida'
  }
}

// Función para sumar meses
function sumarMeses (intake, meses) {
  const date = new Date(intake)
  date.setMonth(date.getMonth() + meses)
  const newYear = date.getFullYear()
  const newMonth = date.getMonth() + 1
  const newDay = date.getDate()
  return `${newDay}/${newMonth}/${newYear}`
}

// Función para sumar días
function sumarDias (intake, dias) {
  const date = new Date(intake)
  date.setDate(date.getDate() + dias)
  const newYear = date.getFullYear()
  const newMonth = date.getMonth() + 1
  const newDay = date.getDate()
  return `${newDay}/${newMonth}/${newYear}`
}

// Función para sumar semanas
function sumarSemanas (intake, semanas) {
  return sumarDias(intake, semanas * 7)
}

// Función para sumar semestres
function sumarSemestres (intake, semestres) {
  const mesesPorSemestre = 6
  return sumarMeses(intake, semestres * mesesPorSemestre)
}

export { convertirFecha2 }
