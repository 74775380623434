import React, { useState, useEffect, useMemo } from 'react'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import { useGetAllNationalities } from '../../../../bussines/hooks/quoter/useGetAllNationalities'
import { useSearch } from '../../../../providers/search/search-context-provider'
import { useGetLanguages } from '../../../../bussines/hooks/quoter/useGetLanguages'
import debounce from 'lodash/debounce'
import { useGetPlaces } from '../../../../bussines/hooks/quoter/useGetPlaces'
import { CloseOutlined } from '@ant-design/icons'

const { Option } = Select

const LanguageForm = () => {
  const { changeSearch } = useSearch()
  const [searchFilter, setSearchFilter] = useState({
    program: 'language'
  })
  const [allPlaces, setAllPlaces] = useState([])
  const [searchlang, setSearchlang] = useState(false)
  const [searchNation, setSearchNation] = useState(false)
  const [form] = Form.useForm()
  const { isLoading: isLoadingLanguage, data: languages } = useGetLanguages()
  const { data: places, isFetching } = useGetPlaces(
    searchFilter?.searchPlace && {
      search: searchFilter?.searchPlace ?? '',
      lang: searchFilter?.lang ?? '',
      program: 'language'
    }
  )
  const { isLoading: isLoadingNationalities, data: nationalities } = useGetAllNationalities()

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    // console.log('🚀 ~ file: LanguageForm.js:33 ~ handleOnSubmitSearch ~ searchFilter:', searchFilter)
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (isNaN(searchFilter?.lang)) {
      delete searchFilter.lang
    }
    if (countFilter) {
      changeSearch('LANG_FORM', {
        ...searchFilter
      })
    }
  }

  const handleClearFilters = () => {
    const nFilter = {
      program: 'language'
    }
    setSearchFilter(nFilter)
    form.resetFields()
    changeSearch('LANG_FORM', {
      ...nFilter
    })
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilter = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilter)
      // console.log('sending the request :x', key, value)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilter = {
      ...searchFilter,
      [key]: value
    }
    if (key === 'place' && typeof value == 'string') {
      const place = value.split(':')
      nFilter.place = place[0]
      nFilter.place_type = place[1]
    } else if (key === 'place') {
      nFilter.place = 'undefined'
      nFilter.place_type = 'undefined'
    }
    setSearchFilter(nFilter)
  }

  const handleRemoveFilter = (key) => {
    setSearchFilter(nFilter => {
      const copy = { ...nFilter }
      delete copy[key]
      return copy
    })
  }

  useEffect(() => {
    if (places) {
      // console.log('🚀 ~ file: LanguageForm.js:86 ~ useEffect ~ places', places)
      const formatedCampus = places.campus?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'campus'
        }
      })
      const formatedCountries = places.countries?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'country'
        }
      })
      const formatedInstitutions = places.institutions?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'institution'
        }
      })
      // const formatedAddress = places.address?.map(({ id, address }) => {
      //   return {
      //     value: address,
      //     label: address,
      //     type: 'address'
      //   }
      // })
      const formatedCities = places.cities?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'city'
        }
      })
      setAllPlaces([
        ...formatedCampus,
        ...formatedCountries,
        ...formatedInstitutions,
        ...formatedCities
      ])
    }
  }, [places])

  return (
    <Form form={form}>
      <Input.Group compact className='language-from-responsive'>
        <Form.Item noStyle name='lang'>
          <Select
            className='select-index'
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              setSearchlang(!!search)
            }}
            placeholder='Language'
            style={{ width: '25%' }}
            size='large'
            allowClear
            loading={isLoadingLanguage}
            onChange={(e) => {
              handleAddFilter('lang', Number(e))
            }}
          >
            {searchlang && languages?.map((item) => <Option key={item?.id} value={item?.id}>{item?.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item noStyle name='place'>
          <Select
            className='select-index'
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              debounceFilter('searchPlace', search)
            }}
            placeholder='Location (search by city, country or school)'
            style={{ width: '25%' }}
            size='large'
            allowClear
            loading={isFetching}
            onChange={(e) => {
              handleAddFilter('place', e)
            }}
          >
            {allPlaces?.map((item) => <Option key={item?.type + item?.value} value={item?.value + ':' + item?.type}>{item?.label}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item noStyle name='country'>
          <Select
            className='select-index'
            showSearch
            onSearch={(search) => {
              setSearchNation(!!search)
            }}
            style={{ width: '20%' }}
            placeholder='Nationality'
            size='large'
            loading={isLoadingNationalities}
            disabled={!nationalities}
            allowClear
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onChange={(e) => Number(e) > 0 ? handleAddFilter('clientNationalityId', Number(e)) : handleRemoveFilter('clientNationalityId')}
          >
            {searchNation && nationalities?.map((item) => <Option key={item?.id} value={item?.id}>{item?.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item noStyle name='age'>
          <InputNumber
            className='select-index select-age-responsive'
            min={1}
            placeholder='Age'
            default={1}
            size='large'
            style={{ width: '20%' }}
            onChange={(e) => Number(e) > 0 ? handleAddFilter('age', Number(e)) : handleRemoveFilter('age')}
          />
        </Form.Item>
        {/*
          <div className='button-without-border' style={{ width: '5%' }}>
            <ButtonModalFilter />
          </div>
        */}
        <div className='button-without-border button-without-responsive' style={{ width: '3%' }}>
          <Button icon={<CloseOutlined />} block size='large' style={{ backgroundColor: '#D4D4D4' }} onClick={handleClearFilters} />
        </div>
        <div className='button-without-border-start button-search-responsive' style={{ width: '7%' }}>
          <Button onClick={handleOnSubmitSearch} type='primary' block size='large'>Search</Button>
        </div>
      </Input.Group>
    </Form>
  )
}

export { LanguageForm }
