import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useGetUserAndInvitation (filters) {
  return useQuery(
    ['useGetUserAndInvitation', filters],
    () => UserRepository.getUsersAndInvitations(filters),
    {
      retry: 1
    }
  )
}

function useGetUserAndInvitationPaginated (filters) {
  return useQuery(
    ['useGetUserAndInvitationPaginated', filters],
    () => UserRepository.getUsersAndInvitationsPaginated(filters),
    {
      retry: 1
    }
  )
}

export { useGetUserAndInvitation, useGetUserAndInvitationPaginated }
