import React, { useEffect, useState } from 'react'
import { Typography, Radio, Form, Input, Divider, Row, Col, Button } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SocialNetworksFormItem } from '../../../../../common/components/helper-form/SocialNetworksFormItem'

const { Title } = Typography

function InstitutionExtrasFieldsContainer ({ form }) {
  const isAssociation = Form.useWatch('association', form)
  const [isRequired, setisRequired] = useState(false)

  useEffect(() => {
    setisRequired(isAssociation)
  }, [isAssociation])
  const change = () => {
    isRequired ? setisRequired(false) : setisRequired(true)
  }

  return (
    <div>
      <br />
      <Title level={5}>Are you part of any asociation?</Title>
      <Form.Item
        name='association'
        // label='Are you part of any asociation?'
      >
        <Radio.Group>
          <Radio value onChange={change}>Yes</Radio>
          <Radio value={false} onChange={change}>No</Radio>
        </Radio.Group>
      </Form.Item>
      {
        isRequired && (
          <Form.List name='references'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Divider />
                    <Row gutter={8}>
                      <Col span={22}>
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[{ required: true, message: 'The name is required' }]}
                        >
                          <Input placeholder='Association name' />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                      <Col span={22}>
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[{
                            type: 'email',
                            required: true,
                            message: 'A valid email is required'
                          }]}
                        >
                          <Input placeholder='Email' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}

                <Row gutter={8}>
                  <Col span={22}>
                    <Form.Item>
                      <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>

              </>
            )}
          </Form.List>
        )
      }
      <Title level={5}>Social networks</Title>
      <SocialNetworksFormItem form={form} name='social_networks' />
    </div>
  )
}

export { InstitutionExtrasFieldsContainer }
