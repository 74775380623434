import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useFindCourse (courseId) {
  return useQuery(['useFindCourse', courseId],
    () => CourseRepository.findCourse(courseId), {
      retry: 2,
      enabled: !!courseId
    })
}

export { useFindCourse }
