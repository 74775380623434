import React from 'react'
import { FullPageSpinner } from '../../common/components/FullPageSpinner'
import { useFetchUser } from '../../bussines/hooks/user/useFetchUser'
import SessionService from '../../services/SessionService'
import { FullPageUserVerification } from '../../common/components/FullPageUserVerification'
// import { NotFoundPage } from '../../common/components/NotFoundPage'
import { Unauthorized } from '../../pages/errors/Unauthorized'
import { validateUserAccess, validEmails } from '../../bussines/settings/validate-user-access'
import PropTypes from 'prop-types'
// import { URL_WEBSITE } from '../../config'
import LottieCargando from '../../lotties/LottieCargando'

const AuthContext = React.createContext()

function AuthProvider (props) {
  const { isLoading, isError, data } = useFetchUser()
  const [isLogout, setIsLogout] = React.useState(false)
  const [dateFormat, setDateFormat] = React.useState('dd LLLL yyyy')

  const handleRedirectToWebsite = () => {
    SessionService.destroySession()
    window.location = '/'
  }

  React.useEffect(() => {
    if (isLogout) {
      handleRedirectToWebsite()
    }
  }, [isLogout])

  React.useEffect(() => {
    if (isError) {
      // TODO: Display a modal with the error and a redirect button
      handleRedirectToWebsite()
    }
  }, [isError, isLogout])

  if (isLoading) {
    return <LottieCargando />
  }

  if (isError) {
    // return <FullPageSpinner title='Abriendo inicio de sesion...' />
    return <LottieCargando />
  }

  if (isLogout) {
    return <FullPageSpinner title='Regresa pronto...' />
  }

  const logout = () => {
    setIsLogout(true)
  }

  if (!data?.user?.email_verified_at) {
    return <FullPageUserVerification logout={logout} company={data?.company} />
  }

  if (
    !validateUserAccess({
      roles: data?.user.roles ?? [],
      permissionTypes: props.permissionTypes
    })
  ) {
    // console.log(data)
    return <Unauthorized />
  }

  if (props.onlyEdulinks && !validEmails.includes(data?.company?.email) && data?.user?.type === 'Agency') {
    // console.log('🚀 ~ file: auth-context-provider.js:65 ~ AuthProvider ~ props.onlyEdulinks', props.onlyEdulinks)
    return <Unauthorized />
  }

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        company: data.company,
        companyStatus: data.user_company_status,
        registrationStatus: parseInt(data.registration_status),
        logout,
        dateFormat,
        setDateFormat
      }}
      {...props}
    />
  )
}

AuthProvider.propTypes = {
  permissionTypes: PropTypes.array.isRequired
}

AuthProvider.default = {
  permissionTypes: [],
  onlyEdulinks: false
}

function useAuth () {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
