import React from 'react'
import { Row, Col} from 'antd'

const TopUsersMyReports = () => {
  return (
    <Row className='quote-top-users' gutter={[8, 8]}>
      
        
          <Col
            style={{ width:'256px', height:'254px' }}>
            <div className='quote-list-item-card byp-align-centered' style={{ padding: '0.625rem 1rem', justifyContent: 'space-between' }}>

              {/* imagen de perfil
              <img className='img-rounded' style={{ width: '6.875rem', height: '6.875rem', position:'relative', bottom:'10px' }} src={user.profile_photo_url} alt={user.name} /> 
              */}
              <p className='byp-blue-100 byp-fw-bold byp-footer-center' style={{ position:'relative', bottom:'10px' }} >Nombre usuario</p>
              <div className='d-flex w-100' style={{ paddingBottom: '0.5rem' }}>
                <div className='w-100' style={{ padding: '0 0.5rem', marginLeft:' 20px'}}>
                  <p className='margin-0 byp-color-gray-100 byp-text-sm' style={{ fontSize:'10px' }}>Quotes created</p>
                  <p className='margin-0 byp-text-sm byp-fw-bold'>12</p>
                </div>
                <div className='w-100 byp-border-left' style={{ padding: '0 0.45rem', marginRight:' 20px' }}>
                  <p className='margin-0 byp-color-gray-100 byp-text-sm' style={{ fontSize:'10px' }}>Quotes sended</p>
                  <p className='margin-0 byp-text-sm byp-fw-bold'>24</p>
                </div>
              </div>
            </div>
          </Col>
    
      
    </Row>
  )
}

export {TopUsersMyReports}