import { Row, Col, Button, Collapse, notification, Tag } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { usePostPublishFee } from '../../../../../bussines/hooks/fee/usePostPublishFee'
import stateControl from '../../../../../bussines/utils/state-control'
import { URL_UPLOAD_PRICES_ADD_SERVICES } from '../../services/CreatePricesAdditionalServices'
import { RowCostInfo, RowInfoTags } from '../insurances/RowInfo'
const { Panel } = Collapse

const ServiceInfo = ({ data, refetch }) => {
  const { publishFee, isLoading, currentState } = usePostPublishFee()

  const handlePublish = () => {
    publishFee(data.id)
  }

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Status updated'
      })
    }
  }, [currentState, isLoading])

  return (
    <Row>
      <Col span={24}>
        <p>
          {data?.name}
          <Tag className='byp-ml-1'>
            <small>{data?.status}</small>
          </Tag>
        </p>
        <h6><b>Confirm that every block is correct before publishing your quote</b></h6>
      </Col>
      <Col span={24}>
        <Collapse
          defaultActiveKey={['discounts', 'info']}
          expandIconPosition='left'
        >
          <Panel header='Discounts and promotions' key='discounts'>
            <></>
          </Panel>
          <Panel header='Additional service information' key='info'>
            <RowInfoTags label='Program type' tags={[{ name: data?.program }]} />
            <RowInfoTags label='Offices' tags={data?.offices} />
            <RowInfoTags label='Schools' tags={data?.schools} />
            <hr />

            <h5 className='byp-title byp-fw-bold'>Additional service prices</h5>
            <span>Currency: <b>{data?.currency?.code}</b></span>

            {
              data?.costs?.map(
                (cost) => <RowCostInfo key={'cost_service_' + cost.id} label={cost.concept} cost={cost.cost} frequency={cost.frequency} />)
            }
            <hr />

            <h5 className='byp-title byp-fw-bold'>Additional concepts</h5>
            {
              data?.concepts?.map(
                (conscept) => conscept.name
              )
            }
            <hr />

            <h5 className='byp-title byp-fw-bold'>Additional comments</h5>
            <p>{data.comments}</p>

            <div className='w-100'>
              <Link
                to={URL_UPLOAD_PRICES_ADD_SERVICES}
                state={{ feeId: data?.id }}
                className='ant-btn byp-btn-blue-100 float-right'
              > Edit block
              </Link>
            </div>
            <br />
          </Panel>
        </Collapse>
        <br />
      </Col>
      <Col span={24}>
        <Button
          onClick={handlePublish}
          loading={isLoading}
          className='byp-btn-blue-100 float-right'
        > {data?.status === 'Published' ? 'Unpublish' : 'Publish'}
        </Button>
      </Col>
    </Row>
  )
}

export { ServiceInfo }
