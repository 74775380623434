import React from 'react'
import { Form } from 'antd'
import { UserProfileFieldsContainer } from '../steps/invitation-user-profile/UserProfileFieldsContainer'
import { useStepByStep } from '../../../../providers/stepbystep/step-service-provider'
import { USER_PROFILE_STEP } from '../steps/user-profile/UserProfileStep'

function UserProfileForm () {
  const { form, handleOnFinish } = useStepByStep()

  const onFinish = (values) => {
    handleOnFinish(USER_PROFILE_STEP, values)
  }

  return (
    <Form
      form={form}
      layout='vertical'
      scrollToFirstError
      name='UserProfileForm'
      onFinish={onFinish}
      initialValues={{
        country: 142
      }}
    >
      <UserProfileFieldsContainer />
    </Form>
  )
}

export { UserProfileForm }
