import { Form, Row, Col, Input, Button, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect, useState } from 'react'
import { useCreateCampus } from '../../../../bussines/hooks/school/useCreateCampus'
import { UploadSchoolMediaForm } from './UploadSchoolMediaForm'
import { useUpdateCampus } from '../../../../bussines/hooks/school/useUpdateCampus'
import { SelectCurrencyFormItem } from '../../../../common/components/helper-form/SelectCurrencyFormItem'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { API_URL } from '../../../../config'
import MapAddressFormItem from '../../../../common/components/MapAddressFormItem'
import { YearsFormList } from '../components/YearsFormList'
import { UploadLogo } from '../../../../common/components/UploadLogo'
import { useFindProgram } from '../../../../bussines/hooks/program/useFindProgram'
import { usePostDeleteCampusLogo } from '../../../../bussines/hooks/school/usePostDeleteCampusLogo'
import { SelectCountryAndCityFormItem } from '../../../../common/components/helper-form/SelectCountryAndCityFormItem'
import { HighSchoolsFields } from './HighSchoolsFields'
import { SelectTypeOfInstitutionFormItem } from '../../../../common/components/helper-form/SelectTypeOfInstitutionFormItem'
import stateControl from '../../../../bussines/utils/state-control'
import { BasicRadioGroupFormItem } from '../../../../common/components/helper-form/BasicRadioGroupFormItem'
import { GradesFormItem } from './components/GradesFormItem'
import { cleanLogoUrl } from '../../../../utils/cleanStorageUrl'
import { formatStringDate } from '../../../../utils/formatStringDate'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

function SchoolForm (props) {
  const {
    program, refetch, campusId, form,
    isUpdateMode, setIsUpdateMode, setCampusId,
    address, setAddress, campusCoords,
    campusCountry, campusCountryCode, logo
  } = props
  const { createCampus, data: resp, isLoading, isError, error: errorAtCreate } = useCreateCampus()
  const { updateCampus, data, isLoading: updating, error, currentState } = useUpdateCampus()
  const [coords, setCoords] = useState(campusCoords)
  const [country, setCountry] = useState(campusCountry)
  const [city, setCity] = useState()
  const [countryCode, setCountryCode] = useState(campusCountryCode)
  const { data: programData } = useFindProgram(props.program)

  const [programNames, setProgramName] = useState([])

  const isGroup = Form.useWatch('isGroup', form)

  const onFinish = (values) => {
    if (campusId) values.id = campusId
    values.coords = coords
    values.lat = coords.lat
    values.lng = coords.lng
    values.country = country ?? values.country
    values.country_code = countryCode
    values.city = city ?? values.city
    values.type_institution = values?.type_institution || (program === 'graduate' ? 'Public University' : 'Private')

    if (!values.isGroup) values.group_name = null

    if (isUpdateMode) {
      if (values.logo?.includes(`${API_URL}/storage/`)) {
        values.logo = values.logo.replace(`${API_URL}/storage/`, '')
      }
    }

    const files = values.images?.filter(img => img.response?.id).map(img => img.response.id)
    // TODO: Replace this with the formatYearsList util
    const years = values.years?.map(year => {
      return {
        id: year.id ?? null,
        year: formatStringDate(year.year, 'Y'),
        start: formatStringDate(year.start, 'yyyy-LL-dd'),
        end: formatStringDate(year.end, 'yyyy-LL-dd'),
        prices_start: formatStringDate(year.prices_start, 'yyyy-LL-dd'),
        prices_end: formatStringDate(year.prices_end, 'yyyy-LL-dd')
      }
    })
    values.images = files
    values.years = years
    // Remove the http://byp.test/storage/ from the logo url
    if (values.logo) {
      values.logo = cleanLogoUrl(values.logo)
    }
    console.log('🚀 ~ file: SchoolForm.js:77 ~ onFinish ~ values:', values)
    isUpdateMode ? updateCampus(values) : createCampus(values)
  }

  const onAddressChange = ({ address, mapAddress }) => {
    if (address !== undefined) {
      setAddress(address)
      form.setFieldsValue({ mapAddress: address })
    }
  }

  const addressChange = (newAddress, coords, addresComponents) => {
    if (newAddress !== undefined) {
      setAddress(newAddress)
      setCountry(addresComponents?.country)
      setCountryCode(addresComponents?.country_code)
      setCity(addresComponents?.city)
      form.setFieldsValue({ mapAddress: newAddress, address: newAddress })
    }
  }

  useEffect(() => {
    if (resp && resp.saved) {
      notification.success({
        message: 'School saved',
        placement: 'top'
      })
      form.resetFields()
      refetch()
    }
    if (isError && errorAtCreate && !isLoading) {
      notification.error({
        message: errorAtCreate?.response?.data?.message ?? 'Something whent wrong',
        placement: 'top'
      })
    }
  }, [resp, isLoading])

  useEffect(() => {
    if (!updating && data && data.updated) {
      notification.success({
        message: 'School updated',
        placement: 'top'
      })
      setIsUpdateMode(false)
      form.resetFields()
      refetch()
      setCampusId(null)
    }
    if (!updating && error && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message ?? 'Something whent wrong',
        placement: 'top'
      })
    }
  }, [updating, currentState, error])

  useEffect(() => {
    if (campusCoords) {
      setCoords(campusCoords)
    }
  }, [campusCoords])

  useEffect(() => {
    if (program) {
      if (isUpdateMode) {
        form.resetFields()
        setIsUpdateMode(false)
        setCampusId(null)
      }
      form.resetFields(['name', 'address', 'logo', 'divisa'])
    }
  }, [program])

  return (
    <Form
      layout='horizontal'
      scrollToFirstError
      name='SchoolForm'
      onFinish={onFinish}
      form={form}
      initialValues={{
        images: [],
        years: [{}],
        grade_range: [6, 12],
        isGroup: false,
        type_institution: (program === 'academic_years' || program === 'boarding_schools') && 'Private'
      }}
      onValuesChange={onAddressChange}
      requiredMark={false}
      labelCol={{
        xs: 8,
        md: 8,
        xl: 6
      }}
      labelAlign='left'
    >
      <Row> {/* HEADER */}
        <Col span={14}>
          <h3 className='byp-title'>Upload your schools </h3>
        </Col>
        <Col span={10}>
          <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={8} className='byp-text-sm byp-fw-bold'>You're at:</Col>
            <Col xs={8}>
              <Form.Item className='margin-0 you-are-at'>
                <Input className='graduate_show_category--input' value={programData?.label ?? '__________'} readOnly size='small' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <SelectAvailableTranslationsFormItem
                name='translation'
                formItemProps={{
                  noStyle: true,
                  className: 'margin-0'
                }}
                selectProps={{
                  size: 'small',
                  className: 'w-100 margin-0',
                  // onChange: (lang) => handleChangeTranslateLanguage(lang),
                  allowClear: true
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {program !== 'graduate' && (
        <p>
          To be able to start making your quotations, the first step is to register all your schools.
        </p>
      )}
      {program === 'graduate' && (
        <p>
          To be able to start making your quotations, the first step is to register all information about your school.
        </p>
      )}
      <Row gutter={8} className='byp-mt-1'> {/* School information */}
        <Col span={24}><h6 className='byp-title'><b>School information</b></h6></Col>
        <Col xs={24} md={24} style={{ display: 'flex', gap: '10px', alignItems: 'center', margin: '10px 0' }}>
          <UploadLogo
            name='logo'
            form={form}
            action={`${API_URL}/campus/upload/logo`}
            usePostDeleteLogo={usePostDeleteCampusLogo}
            profileUrl={logo}
            className='school-form__img'
            forCampus
            program={program}
            resp={resp}
            data={{
              type: 'insitution',
              id: 'x'
            }}
          />
        </Col>

        {(program === 'academic_years' || program === 'boarding_schools' || program === 'short_term') && (
          <>
            <Col xs={24} md={6}>Are you a school district?</Col>
            <Col xs={24} md={18}><BasicRadioGroupFormItem name='isGroup' /></Col>
            {isGroup && (
              <>

                <Col span={24}>
                  <Form.Item
                    name='group_name'
                    rules={[{ required: true, message: 'This field is required' }]}
                    label='School district'
                  >
                    <Input placeholder='School district name' />
                  </Form.Item>
                </Col>
              </>
            )}
          </>
        )}

        <Col xs={24}>
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'This field is required' }]}
            label='School name'
          >
            <Input placeholder='Name' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24}>
          <MapAddressFormItem
            name='address'
            TextArea='Example'
            address={address}
            label='School address'
            coords={coords}
            setCoords={setCoords}
            addressChange={addressChange}
            rules={[{ required: true, message: 'This field is required' }]}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col xs={24} md={6}> </Col>
        <Col xs={24} md={18}>
          <SelectCountryAndCityFormItem
            placeholderCountry='Choose the Country'
            placeholderCity='Choose the City'
            name='school'
            className='school-city'
            id_country={form.getFieldsValue(['school'])}
            rules={[{ required: true, message: 'This field is required' }]}
          />
        </Col>
      </Row>
      {
        (program === 'academic_years' || program === 'graduate' || program === 'boarding_schools' || program === 'short_term') && ( // Type of institution
          <SelectTypeOfInstitutionFormItem
            name='type_institution'
            program={program} label='Type of institution'
            // lang='es'
          />
        )
      }
      <Row gutter={8}>
        {/* <Col xs={24} md={8}>Currency</Col> */}
        <Col span={24}>
          <SelectCurrencyFormItem
            name='divisa'
            label='Currency'
            rules={[{ required: true, message: 'This field is required' }]}

          />
        </Col>
      </Row>
      {(program === 'academic_years' || program === 'boarding_schools' || program === 'short_term') && (
        <HighSchoolsFields />
      )}
      <Row gutter={8}>
        <Col
          span={24}
          className='byp-custom-select'
        >
          <h6 className='byp-title'><b>The school offers this programs</b></h6>
          <SelectSchoolProgramsFormItem
            name='service_categories'
            mode='multiple'
            rules={[{ required: true, message: 'This field is required' }]}
            setProgramName={setProgramName}
            program={program}
            form={form}
          />
        </Col>
      </Row>

      {(programNames?.includes('academic_years') || programNames?.includes('boarding_schools')) && (
        <GradesFormItem />
      )}

      {program === 'short_term' && (
        <>
          <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>Age range:</b></p>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label='Minimum'
                name='min_age'
                rules={[{ required: true, message: 'This field is required' }]}

              >
                <Input
                  size='small'
                  type='number'
                  min='5'
                  max='55'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Maximum'
                name='max_age'
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input
                  size='small'
                  type='number'
                  min='5'
                  max='55'
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {
        (program !== 'graduate' && program !== 'academic_years' && program !== 'boarding_schools')
          ? <h5 className='byp-title'><b>Add a brief description of the school</b></h5>
          : (
            <h5 className='byp-title'><b>Description of the school</b></h5>
            )
      }
      <Row gutter={8}>
        <Col>
          {
            (program !== 'graduate' && program !== 'academic_years' && program !== 'boarding_schools')
              ? <h6 className='byp-title'><b>450 characteres description</b></h6>
              : (
                <h6 className='byp-title'><b>Share with us a brief description about your school</b></h6>
                )
          }
        </Col>
        <Col span={24}>
          <Form.Item
            name='description'
          >
            <TextArea showCount maxLength={600} placeholder='Write anything you want to mention' />
          </Form.Item>
        </Col>
        <Col>
          <h6 className='byp-title'> <b>Pictures and video: We invite you to upload al least 5 pictures and 1 video</b></h6>
        </Col>
        <Col span={24}>
          <UploadSchoolMediaForm name='images' action={`${API_URL}/campus/upload/files`} form={form} maxCount={10} />
        </Col>
        {/* <Col>
          <h6 className='byp-title'>
            Te invitamos a que subas 1 video
          </h6>
        </Col> */}
      </Row>

      <h6 className='byp-title byp-mt-1'>
        <b>Years</b> <small>(dates on which prices are valid)</small>
      </h6>

      <Row gutter={12}>
        <Col xs={24}>
          <YearsFormList
            rules={[{ required: true, message: 'This field is required' }]}
            name='years'
          />
        </Col>
        <Col xs={24} md={18}>
          <Form.Item
            className='margin-0'
            name='website'
            label='School link'
            rules={[
              // {
              //   type: 'url',
              //   message: 'This field must be a valid url.'
              // },
              {
                required: true,
                message: 'This field is required.'
              }
            ]}
          >
            <Input addonBefore='https://' />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Button
            type='primary'
            htmlType='submit'
            className='float-right byp-mt-1'
            loading={isLoading || updating}
          >
            {isUpdateMode ? 'Update' : 'Save school'}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { SchoolForm }
