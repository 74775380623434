import { Col, Form, Input, Row } from 'antd'

// Webhook entrante: ENTERPRISE_BITRIX_GET_CONTACT
// Webhook entrante: ENTERPRISE_BITRIX_STUDENT_NULL_ID
// Webhook entrante: ENTERPRISE_BITRIX_ADD_QUOTE
const BitrixFormFields = () => {
  return (
    <Row gutter={12}>
      <Col xs={24}>
        <Form.Item
          name='add_quote_webhook'
          label='Webhook for ADD QUOTE'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder='.../crm.quote.add.json' />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name='get_contact_webhook'
          label='Webhook for GET STUDENT / CONTACT'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder='.../crm.contact.get.json' />
        </Form.Item>
      </Col>
    </Row>
  )
}

export { BitrixFormFields }
