import { Button, Card, Col, DatePicker, Form, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import 'moment/locale/es-mx'
// import locale from 'antd/es/date-picker/locale/es_ES'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { SelectStartAndEndDatesFormItem } from '../../../../common/components/helper-form/SelectStartAndEndDatesFormItem'
import React from 'react'
import { AddAndRemoveNewButtons } from './AddAndRemoveNewButtons'
import moment from 'moment'

function YearsFormList({ name, form, ...props }) {
  const thisList = Form.useWatch(name, form)

  const disabledStartDates = (current) => {
    let disabled = current < moment().startOf('year')
    return disabled
  }

  const getStartValue = (index) => {
    if (!thisList) return undefined
    if (!index > 0) return undefined
    const fileds = thisList[index - 1]
    if (fileds?.prices_end) {
      return fileds.prices_end
    }
    return undefined
  }

  return (
    <Form.List
      name={name}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <React.Fragment key={key}>
              <Card key={key} className='byp-mb-1'>
                <Row gutter={12} >
                  <Col span={12}>
                    <Form.Item label='Year' name={[name, 'year']} rules={[{ required: true, message: 'This field is required' }]}
                      labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}
                    >
                      <DatePicker picker='year' format='YYYY' style={{ width:'100%' }} disabledDate={disabledStartDates}/>
                    </Form.Item>
                  </Col>
                </Row>

                <h6 className='byp-title'>Publication dates</h6>
                <SelectStartAndEndDatesFormItem
                  startName={[name, 'start']}
                  endName={[name, 'end']}
                  handleChangeDate={(vals) => console.log(vals)}
                />




                <h6 className='byp-title byp-mt-1'>Start Dates</h6>
                <SelectStartAndEndDatesFormItem
                  startName={[name, 'prices_start']}
                  endName={[name, 'prices_end']}
                  handleChangeDate={(vals) => console.log(vals)}
                  startValue={getStartValue(index)}
                />


              </Card>
              <Row className='w-100 byp-bg-blue'>

              </Row>

              <AddAndRemoveNewButtons
                fields={fields}
                index={index}
                add={add}
                remove={remove}
                name={name}
              />

            </React.Fragment>
          ))}
          {
            fields.length === 0 && (
              add()
            )
          }
        </>
      )}
    </Form.List>
  )
}

export { YearsFormList }
