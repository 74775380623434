import { useEffect } from 'react'
import { Typography } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePostValidateInvitation } from '../../bussines/hooks/user/usePostValidateInvitation'
import { LoadingCourses } from '../quoter/components/LoadingCourses'
import { URL_STEP_BY_STEP_PAGE_AGENCY } from '../../pages/register/AgencyStepByStepRegister'
import { URL_STEP_BY_STEP_PAGE_COLLEGE } from '../../pages/register/InstitutionStepByStepRegister'
import ENTERPRISE_TYPES from '../../bussines/utils/enterprise-types-control'

const { Paragraph } = Typography

const URL_CONTINUE_REGISTRATION = '/continue-registration'

function ContinueRegister () {
  const navigate = useNavigate()
  const { validateInvitation, state, currentState, data } = usePostValidateInvitation()

  const [params] = useSearchParams()
  const invitation = params.get('invitation')

  useEffect(() => {
    if (invitation) {
      validateInvitation(invitation)
    }
  }, [invitation])

  useEffect(() => {
    if (currentState === state.STATE_SUCCESS && data?.type) {
      switch (data.type) {
        case ENTERPRISE_TYPES.AGENCY:
          navigate(URL_STEP_BY_STEP_PAGE_AGENCY)
          break
        case ENTERPRISE_TYPES.INSTITUTION:
          navigate(URL_STEP_BY_STEP_PAGE_COLLEGE)
          break

        default:
          break
      }
    }
  }, [currentState, data])
  return (
    <>
      {currentState === state.STATE_ERROR
        ? (
          <Paragraph>The link is incorrect</Paragraph>
          )
        : (
          <LoadingCourses message='Espere un momento por favor...' />
          )}
    </>
  )
}

export { ContinueRegister, URL_CONTINUE_REGISTRATION }
