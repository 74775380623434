import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

function useQuote (quoteId, enabled = true) {
  return useQuery(['useQuote', quoteId], () => QuoteRepository.findQuote(quoteId), {
    retry: 2,
    enabled
  })
}

export { useQuote }
