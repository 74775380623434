import { useState } from 'react'
import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import UserRepository from '../../repositories/UserRepository'

function useUpdatePassword () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.updatePassword, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const updatePassword = (data) => {
    mutation.mutate({ data })
  }

  return { ...mutation, state, currentState, updatePassword }
}

export { useUpdatePassword }
