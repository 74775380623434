import { Form, Col, message, Row, Upload, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { isJpgOrPng, isLt2M, headersForUpload } from '../../../../utils/uploadImageUtils'

function UploadSchoolMediaForm ({ name, maxCount, action, form, setGallery, enable, gallery }) {
  const _name = Form.useWatch('name', form) || ''
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)

  function beforeUpload (file) {
    const _isJpgOrPng = isJpgOrPng(file)
    if (!_isJpgOrPng) {
      message.error('Solo puedes subir imágenes con formato jpeg o png!')
    }
    const _isLt2M = isLt2M(file)
    if (!_isLt2M) {
      message.error('Image must smaller than 2MB!')
    }

    return _isJpgOrPng && _isLt2M
  }

  const handleChange = ({ file, fileList }) => {
    setFileList(fileList)
    if (file.status === 'uploading') {
      setLoading(true)
    } else if (file.status === 'done') {
      form.setFieldsValue({ [name]: fileList })
      message.success(`${file.name} file uploaded successfully`)
      setLoading(false)
      if (setGallery) {
        const formatedList = fileList.filter(img => img.response?.id).map(img => ({ url: img.response.url }))
        setGallery(formatedList)
      }
    } else if (file.status === 'removed') {
      form.setFieldsValue({ [name]: fileList })
      if (setGallery) {
        const formatedList = fileList.filter(img => img.response?.id).map(img => ({ url: img.response.url }))
        setGallery(formatedList)
      }
    } else {
      message.error(`${file.status} file upload failed.`)
      setLoading(false)
    }
  }
  useEffect(() => {
    const defaultImgs = form.getFieldValue(name) || []
    setFileList(defaultImgs)
  }, [_name])

  useEffect(() => {
    const defaultImgs = form.getFieldValue(name) || []
    setFileList(defaultImgs)
  }, [gallery])

  return (
    <Row>
      <Col span={24}>
        <Form.Item name={name} style={{ display: 'none' }}>
          <Input />
        </Form.Item>
        <Upload
          name='image'
          listType='picture'
          className={enable === false ? 'display-none' : 'avatar-uploader dashboard-media-uploader w-100'}
          action={action}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          headers={headersForUpload()}
          maxCount={maxCount ?? 5}
          fileList={fileList}
        >
          <div className='byp-btn-media-upload btn-media-img byp-centered-row btn-media-text '>
            {loading ? <LoadingOutlined /> : <PlusOutlined />} Upload file
          </div>
        </Upload>
        <div className={enable === false ? 'avatar-uploader dashboard-media-uploader w-100' : 'display-none'}>
          <div className='byp-btn-media-upload btn-media-img byp-centered-row btn-media-text '>
            {loading ? <LoadingOutlined /> : <PlusOutlined />} Upload file
          </div>
        </div>
      </Col>
    </Row>
  )
}

export { UploadSchoolMediaForm }
