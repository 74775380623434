/**
 * Removes keys with undefined value front the Object
 * @param {Object} params
 * @returns {Object}
 */
const removeInvalidValuesFromParams = (params) => {
  for (const key in params) {
    if (params[key] === undefined || !params[key]?.length === 0) {
      delete params[key]
    }
    if (params[key] === null) {
      delete params[key]
    }
  }
  return params
}

export default removeInvalidValuesFromParams
