import { Col, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { SelectLanguageFormItem } from '../../../common/components/helper-form/SelectLanguageFormItem'
import { useGetInstitutionByProgram } from '../../../bussines/hooks/agency/analytics/program/useGetInstitutionsByProgram'

const { Option } = Select
const required = { required: true, message: 'Este dato es requerido' }

const allEducation = [
  { label: 'Primaria', value: 'Primaria' },
  { label: 'Secundaria', value: 'Secundaria' },
  { label: 'Preparatoria', value: 'Preparatoria' },
  { label: 'Licenciatura', value: 'Licenciatura' },
  { label: 'Maestría', value: 'Maestría' },
  { label: 'Posgrado', value: 'Posgrado' }
]

function ProgramaForm ({ disabled = false, program }) {
  const [institutions, setInstitutions] = useState([])

  const { data, isLoading } = useGetInstitutionByProgram(program)
  const handleUniqueArr = (array) => {
    return array?.filter((item, index) => {
      return array.findIndex(obj => obj.id === item.id) === index
    })
  }

  useEffect(() => {
    setInstitutions(handleUniqueArr(data))
  }, [data])

  return (
    <Row gutter={[16]}>
      <Col span={24}>
        <h2 className='subtitle-aplication'>Programa al que te inscribes</h2>
      </Col>
      {/* Program */}
      <Col xs={24} md={12}>
        <label className='label-aplication'>Tipo de Programa</label>
        <Form.Item name='program_name'>
          <Input disabled className='aplication__input' size='large' />
        </Form.Item>
      </Col>
      {/* School */}
      <Col xs={24} md={12}>
        <label className='label-aplication'>Escuela</label>
        <Form.Item
          name='school'
        >
          <Select
            placeholder=''
            style={{ width: '100%' }}
            loading={isLoading}
            size='large'
            disabled
            className='aplication__input-select byp-p-0'
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
          >
            {institutions?.map(institution => (
              <Option key={institution.id} value={institution.id}>{institution.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <label className='label-aplication'>Nombre del Curso</label>
        <Form.Item
          name='course'
        >
          <Input className='aplication__input' disabled />
        </Form.Item>
      </Col>
      {/* Campus */}
      <Col xs={24} md={12}>
        <label className='label-aplication'>Campus</label>
        <Form.Item name='campus_name'>
          <Input disabled className='aplication__input' size='large' />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <label className='label-aplication'>Duración</label>
        <Form.Item
          name='duration'
        >
          <Input className='aplication__input' disabled />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className='label-aplication'>Fecha de Inicio</label>
        <Form.Item
          name='start_date'
        >
          <Input className='aplication__input' disabled />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <label className='label-aplication'>Nivel de estudios actuales</label>
        <Form.Item
          name='education_level'
          rules={[required]}
          disabled={disabled}
          className='byp-select aplication__input-multiselect byp-p-0 transparent br-1'
        >
          <Select
            placeholder='Nivel de estudios actuales'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
            className='byp-select aplication__input-multiselect byp-p-0 transparent br-1'
            disabled={disabled}
            rules={[required]}
            size='large'
          >
            {allEducation?.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <label className='label-aplication'>Idiomas que hablas</label>
        <SelectLanguageFormItem
          name='languages'
          mode='multiple'
          className='byp-select aplication__input-multiselect byp-p-0 transparent br-1'
          size='large'
          disabled={disabled}
          rules={[required]}
        />
      </Col>
    </Row>
  )
}

export default ProgramaForm
