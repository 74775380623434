import { Button, Modal } from 'antd'
import { useState } from 'react'
import { DeleteIcon } from '../theme/icons/DeleteIcon'

const ConfirmModal = ({ isLoading, onOk, id, setCampusId, form, setIsUpdateMode, hasPermision = true, icon = <DeleteIcon /> }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modal, context] = Modal.useModal()

  const showModal = () => {
    if (onOk === undefined || id === undefined) return
    setIsModalVisible(true)
  }

  const handleOk = () => {
    if (!hasPermision) {
      setIsModalVisible(false)
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    setIsModalVisible(false)
    onOk(id)
    if (setCampusId) {
      setCampusId(null)
    }
    if (setIsUpdateMode) setIsUpdateMode(false)
    form.resetFields()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {context}
      <Button
        size='small'
        loading={isLoading}
        onClick={showModal}
        icon={icon}
        style={{ borderColor:'white' }}
      />
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        okText='Confirm'
        onCancel={handleCancel}
        className='byp-confirm-modal'
        centered
        width={400}
      >
        <h5 className='byp-title byp-fw-bold'>Are you sure you want to continue?</h5>
      </Modal>
    </>
  )
}

export default ConfirmModal
