import React from 'react'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { FilterCategoryStatic } from './components/FilterCategoryStatic'
import { ListSearchQuoteResultStatic } from './components/ListSearchQuoteResultStatic'
import { SearchProvider } from '../../providers/search/search-context-provider'
import { useLocation } from 'react-router-dom'

import { userRoles } from '../../bussines/settings/validate-user-access'

const URL_QUOTER_STATIC_PAGE = '/quote2'

const QuoterPageStatic = () => {
  const location = useLocation()

  return (
    <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel showMainTheme={false}>
        <div className='layout-index'>
          <SearchProvider>
            <FilterCategoryStatic staticPage params={location.state} />
            <ListSearchQuoteResultStatic staticPage params={location.state} />
          </SearchProvider>
        </div>
      </MainTheme>
    </ValidateEnterpriseProfile>
  )
}
export { QuoterPageStatic, URL_QUOTER_STATIC_PAGE }
