import React from 'react'
import logo from '../assets/images/logo.png'

const PhoneTheme = ({ showMainTheme }) => {
  return (
      <div className={!showMainTheme ? 'show-phone-theme' : 'dont-show-phone-theme'}>
        <img
          src={logo} alt='byp'
          className='show-phone__image'
        />
        <h1 className='show-phone__title'>
          Welcome to <b>QYP</b> the portal to quote and make your quotes
        </h1>
        <p className='show-phone__text'>
          Remember that if your need to use all the functions in QYP, you need to use a Desktop Screen.
        </p>
        {/* <button className='show-phone__btn'>Start</button> */}
      </div>
  )
}

export { PhoneTheme }