import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
const URL_UPLOAD_PACKAGES_PAGE = '/dashboard/agency/create/package'
// TODO: DELETE THIS
function CreatePackagePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >

        {/* <TopBarMenuCustom /> */}

        {/* <FormCreatePackages /> */}

      </MainTheme>
    </PageProtected>
  )
}

export { CreatePackagePage, URL_UPLOAD_PACKAGES_PAGE }
