import React from 'react';
import one from '../../../common/assets/images/one.png';
import two from '../../../common/assets/images/two.png';
import three from '../../../common/assets/images/three.png';
import four from '../../../common/assets/images/four.png';
import Flech from '../../../common/assets/images/Flech.png';

export function HomeSectionG() {
  return (
    <div className='d-flex justify-center ' style={{ flexWrap: 'wrap', marginBottom: '7rem' }}>
      <div style={{ textAlign: 'center' }}>
        <img src={one} alt='' className='byp-mb-2'/>
        <p className='text-homnesection-g'>
          <b>Create<br/> your profile</b>
        </p>
      </div>
      <div style={{ paddingTop: '4.5rem' }}>
        <img src={Flech} style={{ width: '5rem' }} alt='' />
      </div>
      <div style={{ textAlign: 'center' }}>
        <img src={two} alt='' className='byp-mb-2'/>
        <p className='text-homnesection-g'>
          <b>Conect<br/> to your<br/> partner schools</b>
        </p>
      </div>
      <div className='home-sectiong-hidden' style={{ paddingTop: '4.5rem' }}>
        <img src={Flech} style={{ width: '5rem' }} alt='' />
      </div>
      <div style={{ textAlign: 'center' }}>
        <img src={three} alt='' className='byp-mb-2'/>
        <p className='text-homnesection-g'>
          <b>Customize<br/> your quote</b>
        </p>
      </div>
      <div style={{ paddingTop: '4.5rem' }}>
        <img src={Flech} style={{ width: '5rem' }} alt='' />
      </div>
      <div style={{ textAlign: 'center' }}>
        <img src={four} alt='' className='byp-mb-2'/>
        <p className='text-homnesection-g'>
          <b>Send the quote<br/> to your students<br/> or connect to your CRM</b>
        </p>
      </div>
    </div>
  );
}
