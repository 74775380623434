import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetMostPopularCourseByUser ({ user, program, campus, ...filters }) {
  const allFilters = { user, program, campus, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetMostPopularCourseByUser', filtersQuery],
    () => GeneralAnalysisRepository.getMostPopularCoursesByUser(filtersQuery)
  )
}

export { useGetMostPopularCourseByUser }
