import React, { useEffect } from 'react'
import { FormTitle } from '../../texts/FormTitle'
import { Button, Modal, Space } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import { UserProfileForm } from '../../forms/UserProfileForm'
import { useStepByStep } from '../../../../../providers/stepbystep/step-service-provider'
import { useNavigate } from 'react-router-dom'

const USER_PROFILE_STEP = 1

const config = {
  title: 'Algo salió mal!',
  content: <>Sucedió un error al actualizar el perfil :(</>
}
function UserProfileStep() {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal()
  const { handleOnSubmit, isLoadingPost, states, usePostStepCurrentState } = useStepByStep()

  useEffect(() => {
    if (usePostStepCurrentState === states.STATE_ERROR) modal.error(config)
    if (usePostStepCurrentState === states.STATE_SUCCESS) navigate('/quote')
  }, [usePostStepCurrentState])

  return (
    <StepBaseTemplate
      justify='space-around'
      footer={
        <Space>
          <Button
            type='link'
            size='large'
            onClick={() => navigate('/quote')}
          > Later
          </Button>
          <Button
            type='primary'
            size='large'
            onClick={handleOnSubmit}
            loading={isLoadingPost}
            disabled={isLoadingPost}
          > Save
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <div style={{ margin: '18px 5%' }}>
        <FormTitle
          title='Update your profile'
        />
        <UserProfileForm />
      </div>
    </StepBaseTemplate>
  )
}

export { UserProfileStep, USER_PROFILE_STEP }
