import { Link } from 'react-router-dom'
import { URL_ACTIVATE_QUOTES_PAGE } from '../../../../pages/dashboards/Institution/ActivationQuotes/ActivateQuotesPage'
// import { URL_ACTIVATE_PACKAGE_AGENCY_PAGE } from '../../../../pages/dashboards/agency/ActivationPackage/ActivatePackagePage'
import { URL_ACTIVATE_SHORT_AGENCY_PAGE } from '../../../../pages/dashboards/Institution/ActivationQuotes/ActivateShortPage'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}

// Items for Quote Status Menu
function getQuotesStatusItems (programs) {
  const menuItems = programs?.map((program) => {
    if (program?.name === 'short_term') {
      return (
        getItem(
          <Link
            to={URL_ACTIVATE_SHORT_AGENCY_PAGE}
            state={{ menuItemSelected: 'packages-status', openSub: 'published-services' }}
          >{program.label}
          </Link>,
          'packages-status'
        )
      )
    }
    return (
      getItem(
        <Link
          to={URL_ACTIVATE_QUOTES_PAGE + '?program=' + program.name}
          state={{ menuItemSelected: 'status_' + program.name, openSub: 'sub2' }}
        >{program.label}
        </Link>,
        'status_' + program.name
      )
    )
  })
  return menuItems
}

export { getItem, getQuotesStatusItems }
