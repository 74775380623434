const LinkStyleByStatus = {
  Created: 'byp-color-gray-100 byp-fw-bold',
  Pending: 'byp-color-white byp-fw-bold',
  Aproved: 'byp-color-white byp-fw-bold',
  Published: 'byp-color-white byp-fw-bold',
  Active: 'byp-color-white byp-fw-bold'
}

const labelByStatus = {
  Created: 'Missing',
  Pending: 'To review',
  Aproved: 'Added',
  Published: 'Added',
  Active: 'Added'
}

function getLinkClass (status) {
  return LinkStyleByStatus[status]
}

function getLabel (status) {
  return labelByStatus[status]
}

export {
  getLinkClass,
  getLabel
}
