import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetCourseDurations } from '../../../bussines/hooks/quoter/useGetCourseDurations'
const { Option } = Select

// Return institutions types that has offers
const SearchCourseDuration = ({ name, filters = {}, className, noStyle, onChange, showAll, ...rest }) => {
  const { data } = useGetCourseDurations(filters)
  const [optionsTime, setOptionsTime] = useState([])

  useEffect(() => {
    if (data && data.length > 0) {
      
      const newData = [];
  
      for (let i = 0; i < data.length; i++) {
          const item = data[i];
          if (item["time"] === "months") {
              const months = parseInt(item["duration"]);
              if (months % 12 === 0) {
                  const years = Math.floor(months / 12);
                  item["duration"] = years.toString();
                  item["time"] = "years";
              }
          }
          if (!newData.some((newItem) => newItem["duration"] === item["duration"] && newItem["time"] === item["time"])) {
              newData.push(item);
          }
      }
  
      setOptionsTime(newData)
    }
  }, [data])

  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
    >
      <Select
        className={className}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        size={rest?.size}
        onChange={onChange}
        placeholder={rest.placeholder ?? ''}
        {...rest}
      >
        {optionsTime?.map((item) => {
          const val = item.duration + ':' + item.time
          return <Option key={val} value={val}>{item.duration + ' ' + item.time}</Option>
        })}
      </Select>
    </Form.Item>
  )
}

export { SearchCourseDuration }
