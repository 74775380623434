// import { Link } from 'react-router-dom'
import { LaptopOutlined, RiseOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { URL_EDIT_AGENCY } from '../../../pages/agency/EditAgencyProfilePage'
import { URL_CURRENCIES_PAGE } from '../../../pages/dashboards/currencies'
import { URL_UPLOAD_DOCUMENTS_PAGE } from '../../../pages/dashboards/Institution/Documents/UploadDocumentsPage'
import { URL_CREATE_OFFICES_PAGE } from '../../../pages/dashboards/offices/CrudOfficePage'
import { URL_CREATE_USERS_PAGE } from '../../../pages/dashboards/users/CrudUserPage'
// import { URL_UPLOAD_PACKAGES_PAGE } from '../../../pages/dashboards/agency/packages/CreatePackagePage'
// import { URL_UPLOAD_PRICES_PACKAGES_PAGE } from '../../../pages/dashboards/agency/packages/CreatePricesPackagePage'
import { URL_CREATE_INSURANCE_PAGE } from '../../../pages/dashboards/agency/insurances/CreateInsurancePage'
import { URL_CREATE_INSURANCE_PRICES_PAGE } from '../../../pages/dashboards/agency/insurancePrices/InsurancePricesPage'
import { URL_UPLOAD_ADD_SERVICES_PAGE } from '../../../pages/dashboards/agency/services/CreateAdditionalServices'
import { URL_UPLOAD_PRICES_ADD_SERVICES } from '../../../pages/dashboards/agency/services/CreatePricesAdditionalServices'
import { URL_SERVICES_STATUS_PAGE } from '../../../pages/dashboards/agency/service-status/ServiceStatusPage'
import { URL_GENERAL_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/general/GeneralAnalysisPage'
import { URL_USER_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/user/UserAnalysisPage'
import { URL_ADD_SERVICE_PROMOTIONS_PAGE } from '../../../pages/dashboards/agency/services/AdionalServicePromotions'
import { URL_OFFICE_ANALITCIS_PAGE } from '../../../pages/dashboards/agency/analitics/office/OfficeAnaliticsPage'
import { URL_PROGRAM_ANALITCIS_PAGE } from '../../../pages/dashboards/agency/analitics/program/ProgramAnaliticsPage'
import { URL_CREATE_MISCELLANEOUS_PAGE } from '../../../pages/dashboards/agency/miscellaneous/MiscellaneousPage'
import { IconAnalyticsSharp, IconDocumentAdd, IconListCheck, IconSchool, DesktopAgency, AgencyProfile, Reports, Miscellaneous, Packages, PublishedServices, Currencies, Users, Offices, } from '../../assets/svg/icons'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useEffect, useState } from 'react'
import { URL_REPORTS_UNI_PAGE } from '../../../pages/dashboards/agency/reports/reports-unificado/ReportsUniPage'
import { APPLYBOARD_AGENCY } from '../../../config'
import { URL_HOME_LANDER_PAGE } from '../../../pages/dashboards/home-slider/HomeSliderPage'
import { MarketingMaterialIcon } from '../icons/MarketingMaterialIcon'
import { URL_PACKAGES_PAGE } from '../../../pages/dashboards/packages/PackagesPage'
import { URL_PACKAGES_PRICES_PAGE } from '../../../pages/dashboards/packages/PackagesPricesPage'
import { URL_ACTIVATE_PACKAGE_AGENCY_PAGE } from '../../../pages/dashboards/agency/ActivationPackage/ActivatePackagePage'
import { URL_PACKAGES_PROMOTIONS_PAGE } from '../../../pages/dashboards/packages/UploadPromotionsPage'
import { URL_PACKAGES_PROMOTIONS_RULES_PAGE } from '../../../pages/dashboards/packagesPromotions/PromotionsRulesPage'
import { URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE } from '../../../pages/dashboards/packages/DiscountsPackagesPage'

function getItem (label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default [

  getItem(
    <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
    '1',
    <LaptopOutlined />
  ),
  getItem(<Link to={URL_EDIT_AGENCY}>Agency profile</Link>, '2', <LaptopOutlined />),
  // getItem('Sedes', '3', <LaptopOutlined />),
  // getItem('Elaborar cotizaciones', 'sub1', <LaptopOutlined />, [
  //   getItem('idiomas', '5'),
  //   getItem('Campamentos', '6')
  // ]),

  getItem(
    'Analytics',
    'analitic',
    <IconAnalyticsSharp />,
    [
      getItem(
        <Link
          to={URL_GENERAL_ANALYSIS_PAGE}
          state={{ menuItemSelected: 'general-analysis', openSub: 'analitic' }}
        >General analysis
        </Link>,
        'general-analysis'
      ),
      getItem(
        <Link
          to={URL_OFFICE_ANALITCIS_PAGE}
          state={{ menuItemSelected: 'office-analysis', openSub: 'analitic' }}
        >Office analysis
        </Link>,
        'office-analysis'
      ),
      getItem(
        <Link
          to={URL_PROGRAM_ANALITCIS_PAGE}
          state={{ menuItemSelected: 'program-analysis', openSub: 'analitic' }}
        >Program analysis
        </Link>,
        'program-analysis'
      ),
      getItem(
        <Link
          to={URL_USER_ANALYSIS_PAGE}
          state={{ menuItemSelected: 'user-analysis', openSub: 'analitic' }}
        >User analysis
        </Link>,
        'user-analysis'
      )
    ]
  ),
  getItem(
    <Link to={URL_REPORTS_UNI_PAGE} state={{ menuItemSelected: 'user-reports' }}>Reports</Link>,
    'user-reports',
    <RiseOutlined color='var(--blue-200)' />
  ),
  getItem(
    <Link to={URL_CREATE_MISCELLANEOUS_PAGE} state={{ menuItemSelected: 'miscellaneous' }}>Miscellaneous</Link>,
    'miscellaneous',
    <IconDocumentAdd color='var(--blue-200)' />
  ),
  getItem(
    'Custom services',
    'customs-quotes',
    <IconListCheck />,
    [
      // getItem(
      //   'Packages',
      //   'packages', undefined,
      //   [
      //     getItem(
      //       <Link
      //         to={URL_UPLOAD_PACKAGES_PAGE}
      //         state={{ menuItemSelected: 'upload-pack', openSub: 'customs-quotes', openSub2: 'packages' }}
      //       >Upload package
      //       </Link>,
      //       'upload-pack'
      //     ),
      //     getItem(
      //       <Link
      //         to={URL_UPLOAD_PRICES_PACKAGES_PAGE}
      //         state={{ menuItemSelected: 'prices-pack', openSub: 'customs-quotes', openSub2: 'packages' }}
      //       >Prices for package
      //       </Link>,
      //       'prices-pack'
      //     )
      //   ]
      // ),
      getItem(
        'Additional service',
        'add-service', undefined,
        [
          getItem(
            <Link
              to={URL_UPLOAD_ADD_SERVICES_PAGE}
              state={{ menuItemSelected: 'upload-service', openSub: 'customs-quotes', openSub2: 'add-service' }}
            >Upload service
            </Link>,
            'upload-service'
          ),
          getItem(
            <Link
              to={URL_UPLOAD_PRICES_ADD_SERVICES}
              state={{ menuItemSelected: 'prices-service', openSub: 'customs-quotes', openSub2: 'add-service' }}
            >Prices for service
            </Link>,
            'prices-service'
          )
        ]
      ),
      getItem(
        'Medical Insurance',
        'medical', undefined,
        [
          getItem(
            <Link
              to={URL_CREATE_INSURANCE_PAGE}
              state={{ menuItemSelected: 'medical_form', openSub: 'customs-quotes', openSub2: 'medical' }}
            >Upload medical insurance
            </Link>,
            'medical_form'
          ),
          getItem(
            <Link
              to={URL_CREATE_INSURANCE_PRICES_PAGE}
              state={{ menuItemSelected: 'medical_prices', openSub: 'customs-quotes', openSub2: 'medical' }}
            >Prices medical insurance
            </Link>,
            'medical_prices'
          ),
          getItem(
            <Link
              to={URL_ADD_SERVICE_PROMOTIONS_PAGE}
              state={{ menuItemSelected: 'promos', openSub: 'published-services' }}
            >Promotions
            </Link>,
            'promos'
          )
        ]
      ),
      getItem(
        <Link
          to={URL_ADD_SERVICE_PROMOTIONS_PAGE}
          state={{ menuItemSelected: 'promos', openSub: 'published-services' }}
        >Promotions
        </Link>,
        'promos'
      )
    ]
  ),
  getItem(
    'Published services',
    'published-services',
    <IconListCheck />,
    [
      getItem(
        <Link
          to={URL_SERVICES_STATUS_PAGE}
          state={{ menuItemSelected: 'services-status', openSub: 'published-services' }}
        >Insurance and services
        </Link>,
        'services-status'
      ),
      getItem(
        <Link
          to={URL_ACTIVATE_PACKAGE_AGENCY_PAGE}
          state={{ menuItemSelected: 'packages-status', openSub: 'published-services' }}
        >Packages
        </Link>,
        'packages-status'
      )
    ]
  ),
  getItem(
    'Marketing material',
    'material',
    <IconDocumentAdd />,
    [
      getItem(
        <Link
          to={URL_UPLOAD_DOCUMENTS_PAGE}
          state={{ menuItemSelected: 'upload-documents', openSub: 'material' }}
        >Upload documents
        </Link>,
        'upload-documents'
      )
    ]
  ),
  getItem(
    'Quote status',
    'quote'
  ),
  getItem(
    'Schools',
    'schools',
    <IconSchool />
  ),
  getItem(
    <Link to={URL_CREATE_OFFICES_PAGE} state={{ menuItemSelected: 'offices' }}>Offices</Link>,
    'offices', <LaptopOutlined />
  ),
  getItem(
    <Link to={URL_CREATE_USERS_PAGE} state={{ menuItemSelected: 'users' }}>Users</Link>,
    'users', <LaptopOutlined />
  ),
  getItem(
    <Link to={URL_CURRENCIES_PAGE} state={{ menuItemSelected: 'currencies' }}>Currencies</Link>,
    'currencies', <LaptopOutlined />
  )
]

function AgencyMenuItems() {
  const { user, company } = useAuth()
  const [AgencyProfileAccess, setAgencyProfileAccess] = useState(true)

  useEffect(() => {
    const roles = user?.role_names ?? []
    if (roles.includes('agency-office-manager') && company.name === APPLYBOARD_AGENCY) {
      setAgencyProfileAccess(false)
    }
  }, [user])
  const items = [
    getItem(
      <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
      '1',
      <DesktopAgency />
    ),
    getItem(
      AgencyProfileAccess ? <Link to={URL_EDIT_AGENCY}>Agency profile</Link> : 'Agency profile',
      '2',
      <AgencyProfile />
    ),
    getItem(
      'Analytics',
      'analitic',
      <IconAnalyticsSharp />,
      [
        getItem(
          <Link
            to={URL_GENERAL_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'general-analysis', openSub: 'analitic' }}
          >General analysis
          </Link>,
          'general-analysis'
        ),
        getItem(
          <Link
            to={URL_OFFICE_ANALITCIS_PAGE}
            state={{ menuItemSelected: 'office-analysis', openSub: 'analitic' }}
          >Office analysis
          </Link>,
          'office-analysis'
        ),
        getItem(
          <Link
            to={URL_PROGRAM_ANALITCIS_PAGE}
            state={{ menuItemSelected: 'program-analysis', openSub: 'analitic' }}
          >Program analysis
          </Link>,
          'program-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'user-analysis', openSub: 'analitic' }}
          >User analysis
          </Link>,
          'user-analysis'
        )
      ]
    ),
    getItem(
      <Link to={URL_REPORTS_UNI_PAGE} state={{ menuItemSelected: 'user-reports' }}>Reports</Link>,
      'user-reports',
      <Reports />
    ),
    getItem(
      <Link to={URL_CREATE_MISCELLANEOUS_PAGE} state={{ menuItemSelected: 'miscellaneous' }}>Miscellaneous</Link>,
      'miscellaneous',
      <Miscellaneous />
    ),
    getItem(
      'Custom services',
      'customs-quotes',
      <IconListCheck />,
      [
        getItem(
          'Additional service',
          'add-service', undefined,
          [
            getItem(
              <Link
                to={URL_UPLOAD_ADD_SERVICES_PAGE}
                state={{ menuItemSelected: 'upload-service', openSub: 'customs-quotes', openSub2: 'add-service' }}
              >Upload service
              </Link>,
              'upload-service'
            ),
            getItem(
              <Link
                to={URL_UPLOAD_PRICES_ADD_SERVICES}
                state={{ menuItemSelected: 'prices-service', openSub: 'customs-quotes', openSub2: 'add-service' }}
              >Prices for service
              </Link>,
              'prices-service'
            )
          ]
        ),
        getItem(
          'Medical Insurance',
          'medical', undefined,
          [
            getItem(
              <Link
                to={URL_CREATE_INSURANCE_PAGE}
                state={{ menuItemSelected: 'medical_form', openSub: 'customs-quotes', openSub2: 'medical' }}
              >Upload medical insurance
              </Link>,
              'medical_form'
            ),
            getItem(
              <Link
                to={URL_CREATE_INSURANCE_PRICES_PAGE}
                state={{ menuItemSelected: 'medical_prices', openSub: 'customs-quotes', openSub2: 'medical' }}
              >Prices medical insurance
              </Link>,
              'medical_prices'
            )
          ]
        ),
        getItem(
          <Link
            to={URL_ADD_SERVICE_PROMOTIONS_PAGE}
            state={{ menuItemSelected: 'promos', openSub: 'published-services' }}
          >Promotions
          </Link>,
          'promos'
        )
      ]
    ),
    getItem(
      'Published services',
      'published-services',
      <PublishedServices />,
      [
        getItem(
          <Link
            to={URL_SERVICES_STATUS_PAGE}
            state={{ menuItemSelected: 'services-status', openSub: 'published-services' }}
          >Insurance and services
          </Link>,
          'services-status'
        ),
      ]
    ),
    getItem(
      'Marketing material',
      'material',
      <IconDocumentAdd />,
      [
        getItem(
          <Link
            to={URL_UPLOAD_DOCUMENTS_PAGE}
            state={{ menuItemSelected: 'upload-documents', openSub: 'material' }}
          >Upload documents
          </Link>,
          'upload-documents'
        ),
        getItem(
          <Link
            to={URL_HOME_LANDER_PAGE}
            state={{ menuItemSelected: 'home-slider', openSub: 'material' }}
          >Home Slider Pictures
          </Link>,
          'home-slider'
        )
      ]
    ),
    getItem(
      'Packages',
      'packages',
      <Packages />,
      [
        getItem(
          <Link
            to={URL_PACKAGES_PAGE}
            state={{ menuItemSelected: 'upload-packages', openSub: 'packages' }}
          >Upload packages
          </Link>
          ,
          'upload-packages'
        ),
        getItem(
          <Link
            to={URL_PACKAGES_PRICES_PAGE}
            state={{ menuItemSelected: 'prices-packages', openSub: 'packages' }}
          >Upload prices
          </Link>,
          'prices-packages'
        ),
        getItem(

          'Promotions packages',
          'promotions-packages',
          undefined,
          [
            getItem(
              <Link
                to={URL_PACKAGES_PROMOTIONS_PAGE}
                state={{ menuItemSelected:'upload-promotions' , openSub: 'promotions-packages',openSub2: 'packages' }}
              >
                Upload promotions
              </Link>,
              'upload-promotions'
            ),
            getItem(
              <Link
                to={URL_PACKAGES_PROMOTIONS_RULES_PAGE}
                state={{ menuItemSelected:'promotions-rules' , openSub: 'promotions-packages',openSub2: 'packages' }}
              >
                Promotions rules
              </Link>,
              'promotions-rules'
            ),
            getItem(
              <Link
                to={URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE}
                state={{ menuItemSelected:'all-promotions' , openSub: 'promotions-packages',openSub2: 'packages' }}
              >
               All promotions
              </Link>,
              'all-promotions'
            ),
          ],
        ),
        getItem(
          <Link
            to={URL_ACTIVATE_PACKAGE_AGENCY_PAGE}
            state={{ menuItemSelected: 'packages-status', openSub: 'packages' }}
          >Quote status
          </Link>,
          'packages-status'
        )
      ]
    ),
    
    getItem(
      <Link to={URL_CREATE_OFFICES_PAGE} state={{ menuItemSelected: 'offices' }}>Offices</Link>,
      'offices', <Offices />
    ),
    getItem(
      <Link to={URL_CREATE_USERS_PAGE} state={{ menuItemSelected: 'users' }}>Users</Link>,
      'users', <Users />
    ),
    getItem(
      <Link to={URL_CURRENCIES_PAGE} state={{ menuItemSelected: 'currencies' }}>Currencies</Link>,
      'currencies', <Currencies />
    )
  ]

  if (!AgencyProfileAccess) {
    items.splice(1, 1)
  }

  return items
}

export { AgencyMenuItems }

