const userRoles = {
  TYPE_SUPER_ADMIN: 'Super Admin',
  TYPE_SUPER_EDITOR: 'Super Editor',
  TYPE_AGENCY: 'Agency Administrator',
  TYPE_AGENCY_MANAGER: 'Agency Manager',
  TYPE_AGENCY_OFFICE_MANAGER: 'Agency Office Manager',
  TYPE_AGENCY_CONSULTANT: 'Agency Consultant',
  TYPE_AGENCY_COMMERCIAL_MANAGER: 'Agency Commercial Manager',
  TYPE_AGENCY_EDITOR: 'Agency Editor',

  TYPE_INSTITUTION: 'Institution Administrator',

  TYPE_EG_ADMIN: 'Education Group Admin',
  TYPE_EG_CONSULTANT: 'Education Group Consultant',

  TYPE_ANY: 'any'
}

const validEmails = [
  'info@edulynks.com', // Nashiely account in qyp
  'agenciaa2@gmail.com', // Angies account in mydevs
  'agencyalx@mail.com' // // Alx account in local
]

const validateUserAccess = ({ roles, permissionTypes = [] }) => {
  if (!Array.isArray(permissionTypes) || !Array.isArray(roles)) {
    return false
  }

  if (permissionTypes.includes(userRoles.TYPE_ANY)) {
    return true
  }

  let existsRoleInList = false

  permissionTypes.every(element => {
    existsRoleInList = roles.includes(element)
    return !existsRoleInList
  })

  return existsRoleInList
}

export { validateUserAccess, userRoles, validEmails }
