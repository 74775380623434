import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PrefixLabelSelector } from './PrefixLabelFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { BasicRadioGroupFormItem } from '../../../../common/components/helper-form/BasicRadioGroupFormItem'
import { SelectTimeFrequencyFormItem } from '../../../../common/components/helper-form/SelectTimeFrequencyFormItem'
import { useEffect, useState } from 'react'

const { Option } = Select

function MaterialsPricesFormItem ({ name, divisa, form, program }) {
  const list = Form.useWatch(name, form) || []
  const listName = name
  const prefixLabelSelector = <PrefixLabelSelector divisa={divisa} />

  const [frequencies, setFrequencies] = useState({ week: true, unic: true })

  useEffect(() => {
    if (program === 'graduate' || program === 'academic_years' || program === 'boarding_schools') {
      setFrequencies({ unic: true, semester: true, year: true })
    } else {
      setFrequencies({ week: true, unic: true })
    }
  }, [program])

  const onChange = (value, index) => {
    if (!value) return
    const listValues = form.getFieldValue(name) || []
    const nValues = listValues.map((item, itemIndex) => {
      if (itemIndex !== index && item.mandatory) {
        item.mandatory = false
      }
      if (itemIndex === index) {
        item.mandatory = value
      }
      return item
    })
    form.setFieldsValue({
      [listName]: nValues
    })
  }

  return (
    <div>
      <Form.List
        name={listName}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={16} className='w-100 byp-mb-1'>
                <Col xs={24} md={16} lg={20}>
                  <Form.Item
                    name={[name, 'name']}
                    label='Package Name'
                    style={{ marginBottom: '8px' }}
                  >
                    <Input placeholder='Name of material package' />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} lg={4} style={{ display: 'flex', justifyContent: 'end' }}>
                  <Row gutter={16}>
                    <Col>
                      Mandatory
                    </Col>
                    <Col>
                      <Form.Item
                        name={[name, 'mandatory']}
                      >
                        <Switch
                          checkedChildren='YES'
                          unCheckedChildren='NO'
                          checked={list[name]?.mandatory}
                          onChange={(value) => {
                            onChange(value, name)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {program !== 'graduate' && program !== 'academic_years' && program !== 'boarding_schools' && (
                  <Col span={24}>
                    <BasicRadioGroupFormItem
                      label='Type of cost'
                      name={[name, 'cost_range_type']}
                      falseText='Only'
                      trueText='By rank'
                    />
                  </Col>
                )}
                {
                  list[key]?.cost_range_type && (
                    <div style={{ marginBottom: '0px' }}>
                      <Row gutter={8}>
                        <Col span={24}>Minimum of: </Col>
                        <Col xs={12} md={12}>
                          <Form.Item
                            name={[name, 'min']}
                          >
                            <Input
                              type='number'
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} md={12}>
                          <Form.Item
                            name={[name, 'min_frequency']}
                          >
                            <Select
                              className='w-100'
                            >
                              <Option key='weeks'>Weeks</Option>
                            </Select>

                          </Form.Item>
                        </Col>
                      </Row>
                      <Col xs={12} md={12}>
                        <Row gutter={8}>
                          <Col xs={24}>Maximum of: </Col>
                          <Col xs={12} md={12}>
                            <Form.Item
                              name={[name, 'max']}
                            >
                              <Input type='number' />
                            </Form.Item>
                          </Col>
                          <Col xs={12} md={12}>
                            <Form.Item
                              name={[name, 'max_frequency']}
                            >
                              <Select
                                className='w-100'
                              >
                                <Option key='weeks'>Weeks</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  )
                }

                <Row style={{ width: '100%', padding: '0 8px' }} gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name={[name, 'cost']}
                      rules={[{ required: true, message: 'This field is required' }]}
                      label='Materials Costs'
                    >
                      <InputNumber addonBefore={prefixLabelSelector} controls={false} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <SelectTimeFrequencyFormItem name={[name, 'frequency']} frequencies={frequencies} label='Charging for' />
                  </Col>
                </Row>

                {
                  list[key]?.cost_range_type && (
                    <>
                      <Col xs={12} md={6}> Extra cost </Col>
                      <Col xs={12} md={8}>
                        <Form.Item
                          name={[name, 'extra_cost']}
                        >
                          <Input type='number' addonBefore={prefixLabelSelector} />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={5}> Charging for </Col>
                      <Col xs={12} md={5}>
                        <Form.Item
                          name={[name, 'extra_cost_frequency']}
                        >
                          <Select
                            className='byp-mb-1 w-100'
                            placeholder='Select how many times'
                          >
                            <Option key='weeks'>Weeks</Option>
                            <Option key='unic'>Only time</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} md={6}> Cost limit </Col>
                      <Col xs={12} md={8}>
                        <Form.Item
                          name={[name, 'cost_max']}
                        >
                          <Input type='number' addonBefore={prefixLabelSelector} />
                        </Form.Item>
                      </Col>
                    </>

                  )
                }

                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                  labelAdd='Add materials'
                  labelDelete='Delete materials'
                />

              </Row>
            ))}
            {
              fields.length === 0 && (
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { MaterialsPricesFormItem }
