import { Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import { SelectCountriesFormItem } from '../../../../common/components/helper-form/SelectCountriesFormItem'
import { ClosedIcon } from '../../../quote-preview/assets/imgs/Icons'

export default function TrendsCountries ({ program, country, setCountry }) {
  const [countries, setCounties] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const handleSetCountry = () => {
    const newCountry = country.split('-')
    setCounties([...countries, {
      id: newCountry[0],
      name: newCountry[1]
    }])
    setModalOpen(false)
    setCountry()
  }

  const handleRemoveCountry = (index) => {
    const newArray = countries.filter((_, i) => i !== index)
    setCounties(newArray)
  }

  const handleUpperCase = (text) => {
    if (text) {
      const array = text?.split('_')
      array[0] = array[0]?.charAt(0)?.toUpperCase() + array[0].slice(1)
      return array?.join(' ')
    }
  }

  return (
    <>
      <Row gutter={[12, 12]}>
        {countries.length > 0 && (
          countries.map((countryItem, index) => (
            <Col xs={24} sm={24} md={12} lg={6} key={countryItem.id}>
              <div className='card__trends-country'>
                <div>
                  <p>{`${countryItem.name}`}</p>
                  <div style={{ cursor: 'pointer', borderRadius: '9999px', overflow: 'hidden' }} onClick={() => handleRemoveCountry(index)}>
                    <ClosedIcon />
                  </div>
                </div>
                <p className='card__program'>{handleUpperCase(program)}</p>
              </div>
            </Col>
          ))
        )}
        {countries.length < 4 && (
          <Col xs={24} sm={24} md={12} lg={6}>
            <div className='card__add-more'>
              <div onClick={e => setModalOpen(true)}>
                +
              </div>
              <p>Add more</p>
            </div>
          </Col>
        )}
      </Row>
      <Modal
        title='Select country'
        centered
        visible={modalOpen}
        onOk={() => handleSetCountry(false)}
        onCancel={() => setModalOpen(false)}
      >
        <SelectCountriesFormItem onChange={setCountry} value={country} withName />
      </Modal>
    </>
  )
}
