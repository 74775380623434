import { useState } from 'react'
import stateControl from '../../utils/state-control'
import { useMutation } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function usePostResetPassword () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(AdminRepository.resetPassword, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const resetPassword = ({ email, password, confirmation }) => {
    mutation.mutate({ email, password, password_confirmation: confirmation })
  }

  return { ...mutation, resetPassword, currentState, stateControl }
}

export { usePostResetPassword }
