// MOVE THIS INTO A UTILS METHOD
function obtenerNombreDiaSemana (fecha) {
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
  return diasSemana[fecha.getDay()]
}

function obtenerNombreMes (fecha) {
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  return meses[fecha.getMonth()]
}

function formatStartDate (fechaStr) {
  const fecha = new Date(fechaStr)

  const nombreDiaSemana = obtenerNombreDiaSemana(fecha)
  const nombreMes = obtenerNombreMes(fecha)

  const dia = fecha.getDate()
  const año = fecha.getFullYear()

  const resultado = `${nombreDiaSemana} ${dia} de ${nombreMes}, ${año}`
  return resultado
}

function formatIntake (fechaStr) {
  const fecha = new Date(fechaStr)

  const nombreMes = obtenerNombreMes(fecha)

  const dia = fecha.getDate()
  const año = fecha.getFullYear()

  const resultado = `${dia} de ${nombreMes}, ${año}`
  return resultado
}

export { formatStartDate, formatIntake }
