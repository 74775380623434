import { Button, Switch, Table } from 'antd'
import { useChangeStatusFee } from '../../../../bussines/hooks/fee/useChangeStatusFee'
import { useEffect, useState } from 'react'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { LodgingTransportTableFilters } from './Filters/LodgingTransportTableFilters'
import LottieCargando from '../../../../lotties/LottieCargando'

const { Column } = Table

function TableLodgins (props) {
  const {
    isLoading,
    data,
    onDelete,
    isDeleting,
    setLodgingID,
    refetch,
    program,
    metaData,
    setPage,
    setSearchFilter,
    showDelete = true,
    showStatus = true
  } = props
  const { changeStatus, state, currentState, isLoading: isUpdating } = useChangeStatusFee()
  const [lodgings, setLodgings] = useState(data)

  useEffect(() => {
    if (!isUpdating && currentState === state.STATE_SUCCESS) {
      refetch()
    }
  }, [currentState, isUpdating])

  useEffect(() => {
    if (!isLoading && data) {
      setLodgings(data)
    }
  }, [isLoading, data])

  return (
    <div>
      <LodgingTransportTableFilters program={program} setFilters={setSearchFilter} data={data} />
      {isLoading && (
        <LottieCargando />
      )}
      {!isLoading && (
        <Table
          loading={isLoading}
          dataSource={lodgings ?? []}
          className='byp-table-dashboard'
          title={() => 'Added accomodations'}
          rowKey='id'
          pagination={{
            current: metaData?.current_page,
            size: metaData?.last_page,
            total: metaData?.total,
            pageSize: metaData?.per_page,
            onChange: (val) => setPage(val)
          }}
        >
          <Column
            title='Name' dataIndex='name' key='name'
            render={(_, record) => (
              <a>{record.name}</a>
            )}
          />
          {program !== 'academic_years' &&
            <Column
              title='Travel time'
              dataIndex='distance_to_school'
              key='distance_to_school'
              responsive={['lg']}
              render={(_, record) => (
                record.distance_to_school
              )}
            />}
          <Column
            title='Campus'
            dataIndex='campus'
            key='campus'
            responsive={['lg']}
            render={(_, record) => (
              record.name_sedes?.map((sede) => {
                return (
                  <p key={sede}>{sede}</p>
                )
              })
            )}
          />
          {showStatus && (
            <Column
              title='Status'
              key='status'
              render={(_, record) => (
                <>
                  <Switch
                    checked={record?.status === 'Active'}
                    checkedChildren='ACTIVE'
                    unCheckedChildren='INACTIVE'
                    style={{ alignItems: 'center', display: 'flex' }}
                    loading={isUpdating || isLoading}
                    onClick={
                      (value) => (changeStatus(record.id))
                    }
                  />
                </>
              )}
            />
          )}
          <Column
            title='Actions'
            key='action'
            render={(_, record) => (
              <div style={{ alignItems: 'center', alignContent: 'center', display: 'flex' }}>
                <Button
                  className='btn-icon byp-btn-edit'
                  onClick={
                    () => {
                      setLodgingID(record.id)
                    }
                  }
                ><EditIcon />
                </Button>
                {showDelete && (
                  <ConfirmModal
                    onOk={onDelete}
                    isLoading={isUpdating || isLoading || isDeleting}
                    id={record.id}
                  />
                )}
              </div>

            )}
          />
        </Table>
      )}
    </div>
  )
}

export { TableLodgins }
