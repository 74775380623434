import { Divider } from 'antd'

const QuoteFormDetailsInstitutionGroups = ({ groups }) => {
  return (
    <>
      <h6 className='byp-title'>You don´t have ageement</h6>
      <span>Try with: </span>
      <ul>
        {
          groups?.map((group) => (
            <li key={group.name}><small>{group.name}</small></li>
          ))
        }
      </ul>
      <Divider style={{ margin: '10px 0' }} />
    </>
  )
}

export { QuoteFormDetailsInstitutionGroups }
