import React from 'react'
import '../../../common/assets/css/quote-gallery.less'
import { MainThemeCarrousel } from '../../../common/theme/partials/MainThemeCarrousel'

const getUrl = (img) => {
  if (img.src) return img.src
  if (img.profile_photo_url) return img.profile_photo_url
  if (img.url) return img.url
}
const getBGStyle = (gallery, index) => ({
  backgroundImage: `url(${gallery[index] ? getUrl(gallery[index]) : null})`
})

const QuoteGallery = ({ gallery }) => {
  const withGallary = () => (
    <div className='site-card-wrapper '>
      <div className='grid-wrapper'>
        <div className='item item1' style={getBGStyle(gallery, 0)} />
        <div className='item item2' style={getBGStyle(gallery, 1)} />
        <div className='item item3' style={getBGStyle(gallery, 2)} />
        <div className='item item4' style={getBGStyle(gallery, 3)} />
        <div className='item item5' style={getBGStyle(gallery, 4)} />
      </div>
    </div>
  )

  const withOutGallery = () => (
    <div className='carrousel-gallery'>
      <MainThemeCarrousel />
    </div>
  )

  return (
    <div className='quote-gallery' style={{ paddingTop: '15px' }}>
      {gallery.length > 0 ? withGallary() : withOutGallery()}
    </div>
  )
}

export { QuoteGallery }
