import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useAuth } from '../auth/auth-context-provider'
import { useGetSchoolProfile } from '../../bussines/hooks/school/useGetSchoolProfile'
import { useGetAgencyProfile } from '../../bussines/hooks/agency/useGetAgencyProfile'
import ENTERPRISE_TYPES from '../../bussines/utils/enterprise-types-control'
import { useGetEducationGroupProfile } from '../../bussines/hooks/education-group/useGetEducationGroupProfile'
const StepContext = React.createContext()
function StepProvider (props) {
  const { useGetStep, usePostSteps } = props
  const [form] = Form.useForm()
  const [step, __setStep] = useState(-1)
  const [totalStep, __setTotalStep] = useState(0)
  const { isLoading, data } = useGetStep()
  const { user } = useAuth()
  const [useGetCompanyProfile] = useState(() => {
    switch (user.type) {
      case ENTERPRISE_TYPES.INSTITUTION:
        return useGetSchoolProfile
      case ENTERPRISE_TYPES.AGENCY:
        return useGetAgencyProfile
      case ENTERPRISE_TYPES.EDUCATION_GROUP:
        return useGetEducationGroupProfile
      case ENTERPRISE_TYPES.SUPER_ADMIN:
        return useGetEducationGroupProfile
      default:
        throw new Error('User ' + user.type + ' type no acepted')
    }
  })
  const { refetch } = useGetCompanyProfile()
  const { isLoading: isLoadingPost, state: states, currentState: usePostStepCurrentState, nextStep, data: resp, isError: isPostError, error } = usePostSteps()

  useEffect(() => {
    if (resp && resp.saved) {
      __setStep(step => step + 1)
    }
  }, [resp])

  useEffect(() => {
    if (!isLoading && data && data?.step > 0) {
      __setStep(data.step)
      __setTotalStep(data.total_steps)
    }
  }, [isLoading, data])

  const handleOnSubmit = () => {
    form.submit()
  }

  const handleOnFinish = (stp, values) => {
    nextStep(stp, values)
  }

  const backStep = () => {
    if (step < 0) return
    refetch()
    __setStep(step - 1)
  }

  if (nextStep === null) {
    throw new Error('Is required implement nextStep')
  }

  return (
    <StepContext.Provider
      value={{
        error,
        isPostError,
        isLoading,
        isLoadingPost,
        step,
        totalStep,
        form,
        states,
        usePostStepCurrentState,
        handleOnSubmit,
        handleOnFinish,
        backStep
      }}
      {...props}
    />
  )
}

StepProvider.propTypes = {
  useGetStep: PropTypes.func.isRequired,
  usePostSteps: PropTypes.func.isRequired
}

/**
 * useStepByStep
 * @throws {Error} error
 * @returns {{ isPostError, isLoading, isLoadingPost, step, totalStep, form, states, usePostStepCurrentState, handleOnSubmit, handleOnFinish, changeCurrentStep }} context
 */
function useStepByStep () {
  const context = React.useContext(StepContext)
  if (context === undefined) {
    throw new Error('useStepByStep must be used within a StepProvider')
  }
  return context
}

export { StepProvider, useStepByStep }
