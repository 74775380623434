import { Button, Col, Form, Input, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { AddAndRemoveListButtons } from '../../pages/dashboards/Institution/components/AddAndRemoveListButtons'

const BasicFormListItem = ({ name }) => {
  const listName = name
  return (
    <Form.List
      name={listName}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} style={{ width: '100%' }} gutter={16} align='middle'>
              <Col span={20}>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                >
                  <Input
                    className='byp-input'
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Col>
            </Row>
          ))}
          {
            fields.length === 0 && (
              <div className='w-100'>
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              </div>
            )
          }
        </>
      )}
    </Form.List>
  )
}

export { BasicFormListItem }
