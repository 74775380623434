import React from 'react'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import { Unauthorized } from '../../pages/errors/Unauthorized'
import { ServiceUnavailable } from '../../pages/errors/ServiceUnavailable'

export class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, status: null }
  }

  static getDerivedStateFromError (error) {
    // Save the error log
    // console.log('🚀 ~ file: ErrorBoundary.js:10 ~ ErrorBoundary ~ getDerivedStateFromError ~ error', error)
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      status: error?.response?.status,
      error
    }
  }

  componentDidCatch (error, errorInfo) {
    this.functionTathLogTheError(error, errorInfo)
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }

  functionTathLogTheError () {
    console.log('')
  }

  render () {
    if ((this.state.status === 401 || this.state.status === 403) && this.state.hasError) {
      return (
        <Unauthorized />
      )
    }

    if (this.state.status === 503 && this.state.hasError) {
      return (
        <ServiceUnavailable />
      )
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div style={this.props.style ?? { height: '80vh' }} className='byp-centered'>
            <div className='w-100'>
              <AppLogoNameHeader
                style={{
                  width: '40%',
                  marginLeft: '30%',
                  objectFit: 'contain',
                  // height: '100%',
                  paddingBottom: '18px'
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              Ups, something went wrong. Please contact us...
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
