import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetCampusDataTable (category, filters) {
  const queryString = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetCampusDataTable', category, queryString],
    () => CampusRespository.getCampusTable(category, queryString), {
      retry: 2,
      enabled: !!category,
      refetchOnWindowFocus: true
    })
}

export { useGetCampusDataTable }
