import React from 'react'
import { Col, Row, Typography } from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography

const TitleSection = ({ label, icon = null, extra = null, extra2 = null }) => {
  return (
    <Row>
      <Col xs={{span:24,order:1}} sm={{span:24,order:1}} md={{span:24,order:1}} lg={{span:18, order:0,left: '1.875rem'}} style={{ left: '1.875rem' }}>
        <Title className='title-section' level={3} style={{ marginTop: 20, marginBottom: 15 }}>
          {icon ?? ''}&nbsp; &nbsp;{label}&nbsp;
        </Title>
      </Col>
      {/*<Col span={4} className='byp-centered'>{extra}</Col>*/}
      <Col xs={{span:24,order:0,textAlign:'left'}} sm={{span:24,order:0,textAlign:'left'}} md={{span:24,order:0,textAlign:'left'}} lg={{span:6, order:0,right:'1.875rem', textAlign:'right'}} xl={{span:6, order:0,right:'1.875rem', textAlign:'right'}} xxl={{span:6, order:0,right:'1.875rem', textAlign:'right'}} style={{ textAlign: 'end' }} >{extra2}</Col>
    </Row>
  )
}

TitleSection.propTypes = {
  label: PropTypes.string.isRequired
}

export { TitleSection }
