import { Row, Col, Button, Form, Select } from 'antd'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { HolderOutlined } from '@ant-design/icons'
import { useSearch } from '../../../providers/search/search-context-provider'
import { SelectCampusAll } from '../../dashboards/agency/components/SelectCampusAll'
import { SelectBranchesFormItem } from '../../../common/components/helper-form/SelectBranchesFormItem'
import { SelectUsersAll } from '../../dashboards/agency/components/SelectUsersAll'
import React from 'react'
import { SelectSchools } from '../../../common/components/helper-form/SelectInstitutions'

const { Option } = Select

function Searcher () {
  const { user } = useAuth()
  const [form] = Form.useForm()
  const { changeSearch, search } = useSearch()

  const onFinish = (values) => {
    changeSearch('', {
      ...search,
      ...values
    })
  }

  const onReset = () => {
    form.resetFields()
  }

  if (user?.role_names === 'agency-office-manager') {
    return (
      <Row gutter={15} style={{ paddingTop: '20px' }}>
        <Col span={2}>
          <small>Order by</small>
        </Col>
        <Col span={4}>
          <Form.Item label='Status' name='status'>
            <Select>
              <Option key='1' value={undefined}>ALL</Option>
              <Option key='2' value='ACTIVE'>ACTIVE</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <SelectSchools label='Institution' name='institution' />
        </Col>
        <Col span={5}>
          <SelectCampusAll form={form} name='campus' select label='Schools'/>
        </Col>
        <Col span={5}>
          <SelectUsersAll label='Users' name='user' select />
        </Col>
        <Col span={3} style={{ gap: '10px', display: 'flex' }}>
          <Button style={{ backgroundColor: 'lightgray', color: 'black', fontWeight: 'bold' }}>
            Clear
          </Button>
          <Button style={{ backgroundColor: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}>
            Search
          </Button>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row gutter={15} style={{ paddingTop: '20px' }}>
        <Col span={2} style={{ color: 'var(--blue-100)', fontWeight: 'bold', display: 'flex', flexWrap: 'wrap', alignContent: 'center', paddingBottom: '35px' }}>
          <HolderOutlined style={{ color: 'var(--blue-100)', webkitTransform: 'rotate(-90deg)', fontWeight: 'bolder' }} /> Order by
        </Col>
        <Col span={22}>
          <Form name='searcher' onFinish={onFinish} form={form}>
            <Row gutter={15}>
              <Col span={4}>
                <Form.Item label='Status' name='status'>
                  <Select>
                    <Option key='1' value={undefined}>ALL</Option>
                    <Option key='2' value='ACTIVE'>ACTIVE</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <SelectSchools label='Institution' name='institution' />
              </Col>
              <Col span={4}>
                <SelectCampusAll form={form} name='campus' select label='Schools'/>
              </Col>
              <Col span={4}>
                <SelectBranchesFormItem name='office' label='Offices' />
              </Col>
              <Col span={4}>
                <SelectUsersAll label='Users' name='user' select />
              </Col>
              <Form.Item>
                <Col style={{ gap: '10px', display: 'flex' }}>
                  <Button style={{ backgroundColor: 'lightgray', color: 'black', fontWeight: 'bold' }} htmlType='button' onClick={onReset}>
                    Clear
                  </Button>
                  <Button style={{ backgroundColor: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }} htmlType='submit'>
                    Search
                  </Button>
                </Col>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    )
  }
}

export { Searcher }
