import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { OneAgencyInfo } from '../../agency/analitics/one-agency/components/OneAgencyInfo'
import { AgenciesAnaliticsInfo } from './AgenciesAnaliticsInfo'

function AgenciesAnaliticsDisplay () {
  const { agencyId: cryptAgencyId } = useParams()
  const [agencyId, setAgencyId] = useState()

  useEffect(() => {
    if (cryptAgencyId) {
      setAgencyId(cryptAgencyId)
    } else {
      setAgencyId(undefined)
    }
  }, [cryptAgencyId])

  if (agencyId) {
    return (
      <OneAgencyInfo />
    )
  } else {
    return (
      <AgenciesAnaliticsInfo />
    )
  }
}

export { AgenciesAnaliticsDisplay }
