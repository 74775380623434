import { Button, Col, Collapse, Form, Row, notification } from 'antd'
import { SelectDiscountFormItem } from '../../../common/components/helper-form/SelectDiscountFormItem'
import { useGetDiscountPackageValues } from '../../../bussines/hooks/school/discounts/useGetDiscountPackageValues'
import { useEffect, useState } from 'react'
import { PackageCostDiscountFormList } from './components/PackageCostDiscountFormList'
import { useSaveDiscountPackageValues } from '../../../bussines/hooks/school/discounts/useSaveDiscountPackageValues'
import { BasicSpinner } from '../../../common/components/BasicSpinner'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../providers/auth/auth-context-provider'
const { Panel } = Collapse

const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const list = values[name] ?? []
  const packageData = list[position] ?? []
  if (!packageData) return ''
  const header = (
    <>
      {packageData.name}
      {/* <Tag className='byp-ml-1'>{values[name][position].status}</Tag> */}
    </>
  )
  return header
}

function PromotionsRulesForm () {
  const location = useLocation()
  const { discountId } = location.state ?? { discountId: undefined }
  console.log('🚀 ~ file: PromotionsRulesForm.js:27 ~ PromotionsRulesForm ~ location:', location, discountId)
  const [form] = Form.useForm()
  const [discount, setDiscount] = useState(discountId)
  const { user: _user } = useAuth()
  const [type, setType] = useState('package')
  const { data: values, isLoading, isError, error, refetch: reloadValues, isFetching: isRealoading } = useGetDiscountPackageValues(discount, undefined, { type: type })
  const { savePackageValues, isLoading: isSaving, data: saveResponse, isError: isSaveError, error: saveError } = useSaveDiscountPackageValues()

  useEffect(() => {
    if (_user && _user.type === 'Agency') {
      setType('package')
    } else if (_user && _user.type !== 'Agency') {
      setType('short_term')
    }
  }, [_user])

  useEffect(() => {
    if (discountId) {
      form.setFieldsValue({
        discount: discountId
      })
    }
  }, discountId)

  useEffect(() => {
    if (!isLoading && values) {
      console.log('🚀 ~ file: PromotionsRulesForm.js:126 ~ useEffect ~ values:', values)
      const newValues = values.data?.map((valueData) => {
        const costs = []
        if (valueData.price_type === 'open') {
          const weeks = valueData.weeks ?? []
          if (!weeks.length) {
            weeks.push({})
          }
          costs.push(...weeks)
        } else {
          const wCost = valueData.cost ?? { type: 'Whole cost' }
          costs.push(wCost)
        }
        return {
          id: valueData.id,
          name: valueData.label,
          costs,
          price_type: valueData.price_type
        }
      })
      form.setFieldsValue({
        packages: newValues
      })
    } else if (!isLoading && isError) {
      notification.error({
        message: error.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [isLoading, values])

  useEffect(() => {
    if (!isSaving && saveResponse) {
      console.log('🚀 ~ file: PromotionsRulesForm.js:175 ~ useEffect ~ saveResponse:', saveResponse)
      reloadValues()
    } else if (!isSaving && isSaveError) {
      notification.error({
        message: saveError.response?.data?.message ?? 'Something went wrong'
      })
    }
  }, [isSaving])

  const handleFinish = (values, approve = false) => {
    values.approve = approve
    console.log('🚀 ~ file: PromotionsRulesForm.js:155 ~ handleFinish ~ values:', values)
    savePackageValues(discount, values)
  }
  return (
    <Form className='byp-dashboard-content' form={form} onFinish={handleFinish}>
      <Row gutter={20}>
        <Col span={24}>
          <h4 style={{ paddingBottom: '30px' }}> Discounts rules </h4>
          <Row gutter={20}>
            <Col span={6}>
              <SelectDiscountFormItem
                name='discount'
                onChange={(val) => {
                  console.log('🚀 ~ file: PromotionsRulesForm.js:139 ~ PromotionsRulesForm ~ val:', val)
                  setDiscount(val)
                }}
                allowClear
                size='small'
                program={location?.state?.program}
                discount={discount}
              />
            </Col>
          </Row>
          {(isLoading || isRealoading) && <BasicSpinner />}
          <Form.List name='packages'>
            {(fields, { add, remove }, { errors }) => (
              <Collapse bordered={false} defaultActiveKey={['0', '1', '2', '3', '4']} expandIconPosition='right'>
                {!isLoading && !isRealoading && fields.map(({ key, name, ...restField }, index) => (
                  <Panel
                    header={
                      panelHeader(
                        restField.fieldKey,
                        form.getFieldValue(),
                        'packages'
                      )
                    }
                    key={key}
                  >
                    <PackageCostDiscountFormList
                      name={[name, 'costs']}
                      parentName='packages'
                      childIndex={name}
                      showInKind
                    />
                  </Panel>
                ))}
              </Collapse>
            )}
          </Form.List>
        </Col>
      </Row>
      {discount && !isLoading && (
        <Row style={{ width: '100%', padding: '15px 0', justifyContent: 'flex-end' }} gutter={10}>
          <Col>
            <Button type='primary' onClick={() => handleFinish(form.getFieldsValue(), true)}>
              Approve
            </Button>
          </Col>
          <Col>
            <Button
              className='byp-ml-1 byp-btn-blue-100'
              htmlType='submit'
              loading={isSaving}
            >
              Save
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  )
}

export { PromotionsRulesForm }
