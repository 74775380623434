import { useParams, useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormExtraInfo } from './components/FormExtraInfo'

const URL_COURSE_EXTRA_INFO_PAGE = '/dashboard/:program/courses/extra-info'

const CourseExtraInfoPage = () => {
  const { program } = useParams()
  const [params] = useSearchParams()
  const type = params.get('type')
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          <FormExtraInfo program={program} type={type} />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { CourseExtraInfoPage, URL_COURSE_EXTRA_INFO_PAGE }
