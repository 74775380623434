import { Checkbox, Col, Form, Row, Select } from 'antd'
import { useState, useEffect } from 'react'
import { useGetAllCampus } from '../../../../bussines/hooks/school/useGetAllCampus'
const { Option } = Select

function SelectCampusAll ({
  name,
  onChange,
  single,
  allowClear,
  form,
  rules,
  program,
  select,
  isTag,
  label,
  backgroundColor,
  ...props
}) {
  const [checkAll, setCheckAll] = useState(false)
  const _name = name ?? 'schools'
  const institutions = Form.useWatch('institutions', form)
  const { isLoading, data } = useGetAllCampus(
    program ? { program, institutions } : { institutions }
  )

  const onCheckAllChange = (e) => {
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    if (data) {
      const ids = data.campus.map((c) => c.id)
      if (select) {
        //
      } else {
        checkAll
          ? form.setFieldsValue({ [_name]: ids })
          : form.setFieldsValue({ [_name]: [] })
      }
    }
  }, [checkAll, data])

  if (select) {
    return (
      <Form.Item name={_name} className='byp-m-0' label={label ?? ''}>
        {/* <Select
          style={{ width: '100%' }}
          placeholder=''
          loading={isLoading}
          className='byp-m-0'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          maxTagCount={isTag ? 'responsive' : ''}
          mode={isTag ? 'multiple' : ''}
        >
          <Option value='All'>All</Option>
          {data?.campus?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select> */}
        <Select
          style={{ width: '100%' }}
          placeholder=''
          loading={isLoading}
          className='byp-m-0'
          filterOption={
            (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          maxTagCount={isTag ? 'responsive' : ''}
          mode={isTag ? 'multiple' : ''}
          onChange={(value) => {
            if (value.includes('All')) {
              setCheckAll(true)
              form.setFieldsValue({
                [_name]: data?.campus?.map((item) => item.id)
              })
            } else {
              setCheckAll(false)
              form.setFieldsValue({ [_name]: value })
            }
          }}
        >
          <Option value='All'>All</Option>
          {data?.campus?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    )
  } else {
    return (
      <>
        <Row>
          <Col style={{ paddingTop: '12px' }} xs={24} md={4}>
            {label + ':'}
          </Col>

          <Col xs={24} md={20}>
            <Row className='byp-dashboard-header byp-mb-1'>
              <Col
                xs={24}
                md={7}
                className='byp-justify-centered'
                style={{ borderRight: 'solid var(--gray-100) 1px' }}
              >
                <Form.Item
                  className='byp-m-0 prefix'
                  name={name + 'all'}
                  valuePropName='checked'
                >
                  <Checkbox onChange={onCheckAllChange}>
                    {'All ' + name}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={17} className='byp-justify-centered'>
                <Form.Item name={_name} rules={rules} className='byp-m-0'>
                  <Select
                    style={{ width: '100%' }}
                    placeholder=''
                    loading={isLoading}
                    mode='multiple'
                    className='byp-m-0'
                    maxTagCount='responsive'
                    filterOption={
                      (input, option) => option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {data?.campus?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

function SelectCampus ({
  name,
  onChange,
  single,
  allowClear,
  rules,
  program,
  firstOption = false,
  selectProps,
  ...props
}) {
  const _name = name ?? 'schools'
  const { isLoading, data } = useGetAllCampus()

  useEffect(() => {
    if (firstOption && data?.campus?.length > 0) {
      onChange(data?.campus[0]?.id)
    }
  }, [data])

  return (
    <>
      <Form.Item name={_name} rules={rules} className='byp-m-0' {...props}>
        {' '}
        <Select
          style={{ width: '100%' }}
          placeholder=''
          loading={isLoading}
          className='byp-m-0'
          maxTagCount='responsive'
          onChange={onChange}
          value={props?.value}
          {...props}
          {...selectProps}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {data?.campus?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export { SelectCampusAll, SelectCampus }
