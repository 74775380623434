import { Button, Row, Space } from 'antd'
// import { useState } from 'react'
import { MinusOutlined } from '@ant-design/icons'

function AddAndRemoveNewButtons ({ fields, index, add, remove, name }) {
  function AddButton () {
    return (
      <Button className='buttonAddList' onClick={() => add(name, index + 1)}>
        +
      </Button>
    )
  }

  function DeleteButton () {
    return (
      <Button className='buttonDeleteList'>
        <MinusOutlined onClick={() => remove(name)} />
      </Button>
    )
  }

  if (fields.length === 1) {
    return (
      <AddButton style={{ margin: '20px 0', paddingLeft: '5px' }} />
    )
  } else {
    return (
      <Row style={{ margin: '10px 0', paddingLeft: '5px', alignItems: 'center' }}>
        <Space>
          <AddButton />
          <DeleteButton />
        </Space>
      </Row>
    )
  }
}

export { AddAndRemoveNewButtons }
