import { Col, Form, Row, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { FieldHighSeason } from './FieldHighSeason'
import { format } from 'date-fns'
import { TransportMinorCosts } from './TransportMinorCosts'
import { StatisticWithDiscounts } from '../../../common/components/StatisticWithDiscounts'
import { getDiscountSymbol } from '../../../utils/getDiscountSymbol'
import { formatStringDate } from '../../../utils/formatStringDate'

const { Option } = Select

function TransportSection() {
  const [selected, setSelected] = useState()
  const [waySelected, setWaySelected] = useState()
  const { service, formConfig, setFormConfig } = useQuoter()

  const handleChangeSwitch = (included) => {
    setFormConfig({
      ...formConfig,
      transport: included
    })
  }

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      transportId: selected
    })
  }, [selected])

  useEffect(() => {
    setFormConfig({
      ...formConfig,
      transportWaySelected: waySelected
    })
  }, [waySelected])

  return (
    <div style={{ marginTop: '20px' }}>
      <RowQuoter
        colA={
          <Row gutter={12}>
            <Col span={24}>
              <p className='w-100'>
                Include <Switch
                  onChange={handleChangeSwitch} checked={service.includeTransport()} checkedChildren='YES'
                  unCheckedChildren='NO'
                />
              </p>
            </Col>
            <Col span={24}>
              {
                service.includeTransport() &&
                <>
                  <Form.Item label='Transport' name='transport'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your transport',
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      placeholder='unselected'
                      onChange={setSelected}
                      size='small'
                    >
                      {service.getArrayTransport().map((itm) => {
                        return <Option key={itm.id} value={itm.id}>{itm.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label='Booking:' name='transport_way'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your booking',
                    },
                  ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      placeholder='unselected'
                      onChange={setWaySelected}
                      size='small'
                    >
                      {service.getWaysTransport().map((itm) => {
                        return <Option key={itm} value={itm}>{itm}</Option>
                      })}
                    </Select>
                  </Form.Item>
                  {service.getTransportDiscounts().length > 0 && (
                    service.getTransportDiscounts()?.map((d) => (
                      <p key={d.id} className='byp-fw-bold'>
                        {getDiscountSymbol(d.value, d.type, service.getCurrency())} {d.name} discount, until {formatStringDate(d.end, 'LLL dd yyyy')}
                      </p>
                    ))
                  )}
                </>
              }
            </Col>
          </Row>
        }
        colB={
          formConfig.transportWaySelected && (
            <StatisticWithDiscounts
              discounts={service.getTransportDiscounts()}
              amount={service.getAmountTransport()}
              currency={service.getCurrency()}
              subtotal={service.getAmountTransport()?.toFixed(2) - service.getTransportDiscountsAmount()}
              active={service.includeTransport() && formConfig.transportWaySelected}
            />
          )
        }
      />
      <TransportMinorCosts />
      {(service.getTransportIsHighSeason()?.start || service.getTransportIsHighSeason()?.end) && <FieldHighSeason forTransport />}
    </div>
  )
}

export { TransportSection }
