import React, { useEffect, useState } from 'react'
import { Col, Image, Row, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { URL_APLICACION_PAGE } from '../../aplicacion'
import { programs } from '../../../bussines/utils/programTranslations'
import { ThemeCarrouselSmall } from '../../../common/theme/partials/MainThemeCarrousel'
import { formatStringDate } from '../../../utils/formatStringDate'
import icon from '../../../common/assets/images/icon.png'
// import { FaLocationDot } from 'react-icons/fa6'
// import { GoBellFill, GoDotFill } from 'react-icons/go'
// import { IoDocumentText } from 'react-icons/io5'
// import { TbCoinFilled } from 'react-icons/tb'
// import '../../../common/assets/css/card-paid-quote.less'
// import '../../../common/assets/css/card-my-quote.less'
import { URL_QUOTE_SUMMARY_PAGE } from '../../quote-summary'

const { Title } = Typography

const CardMyQuote = ({ item }) => {
  const navigate = useNavigate()
  const [campus, setCampus] = useState()
  const [course, setCourse] = useState()
  const handleUrl = () => {
    return URL_QUOTE_SUMMARY_PAGE.replace(':id', item.quote.uuid)
    // return '#'
  }
  const handleFormatoUrl = () => {
    const to = URL_APLICACION_PAGE.replace(':id', item.quote.uuid).replace(':applicationId', item.id)
    navigate(to)
  }

  useEffect(() => {
    if (item) {
      setCampus(item.quote?.provider?.campus)
      setCourse(item.quote?.course)
    }
  }, [item])

  return (
    <div>
      <div className='my-quote-tabs'>
        <span className='my-quote-tab centered'>
          {/* <GoBellFill style={{ marginRight: '.2rem' }} /> */}
          Documentos del curso
        </span>
        <span
          className={item.form ? 'my-quote-tab centered' : 'my-quote-tab active centered'}
          onClick={handleFormatoUrl}
          style={{ cursor: 'pointer' }}
        >
          {/* <IoDocumentText style={{ marginRight: '.2rem' }} /> */}
          Formato de Aplicación
          {/* {!item.form && <GoDotFill color='#FF395C' />} */}
        </span>
        <span className='my-quote-tab centered'>
          {/* <TbCoinFilled style={{ marginRight: '.2rem' }} /> */}
          Pagos
        </span>
      </div>
      <div className='box-shadow-cards my-quote'>
        <Row className='w-100'>
          {/* Image */}
          <Col xs={24} sm={12} md={8} lg={6} className='ant-image-h-100 ant-image-w-100' style={{ position: 'relative', borderTopLeftRadius: '20px' }}>
            {campus?.gallery?.length >= 1 && (
              <ThemeCarrouselSmall slides={campus?.gallery} urlProperty='src' inRandomOrder />
            )}
            {!campus?.gallery?.length >= 1 && (
              <Image
                src={campus?.profilePhotoUrl || icon}
                style={{
                  objectFit: 'cover',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                  maxWidth: '100%',
                  minHeight: '100%'
                }}
              />
            )}
            <span className='program-label'>
              {programs[item.quote.course.program] ?? item.quote.course.program}
            </span>
          </Col>
          {/* Content */}
          <Col span={24} sm={12} md={16} lg={18} style={{ padding: '1rem' }}>
            <div style={{ display: 'inline-flex', justifyContent: 'space-between' }} className='w-100'>
              <p className='fs-12 fw-3'><span className='upper'>{campus?.city?.name}</span> - {campus?.name}</p>
              <p className='fs-11 fw-5'>
                {/* <FaLocationDot /> */}
                {campus?.address}
              </p>
            </div>
            <Link to={handleUrl()}>
              <Title level={3} className='course-title'>{course?.name}</Title>
            </Link>
            <Link to={handleUrl()}>
              <p className='course-duration'>
                Curso de {item.quote.course.weeks ?? item.quote.course.course_length} semanas
              </p>
            </Link>
            <p className='fs-12 fw-4'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque etiam nunc, vitae ante tristique lectus pretium. Vulputate sed dolor, pellentesque viverra sed.
            </p>
            <Row>
              {item.quote.lodging && (
                <Col lg={12}>
                  Alojamiento - {item.quote.lodging.name}
                  <Row>
                    <Col span={12}>
                      <p className='fs-10 fw-7 byp-mb-0'>Llegada</p>
                      <p className='fs-14 fw-4'>{formatStringDate(item.quote.lodging.start, "dd 'de' LLLL yyyy")}</p>
                    </Col>
                    <Col span={12}>
                      <p className='fs-10 fw-7 byp-mb-0'>Salida</p>
                      <p className='fs-14 fw-4'>{formatStringDate(item.quote.lodging.end, "dd 'de' LLLL yyyy")}</p>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col lg={12}>
                Clases
                <Row>
                  <Col span={12}>
                    <p className='fs-10 fw-7 byp-mb-0'>Inicio</p>
                    <p className='fs-14 fw-4'>{formatStringDate(item.quote.course.start, "dd 'de' LLLL yyyy")}</p>
                  </Col>
                  <Col span={12}>
                    <p className='fs-10 fw-7 byp-mb-0'>Termino</p>
                    <p className='fs-14 fw-4'>{formatStringDate(item.quote.course.end, "dd 'de' LLLL yyyy")}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { CardMyQuote }
