import React from 'react'
import { MainThemeFooter } from '../../common/theme/partials/MainThemeFooter'
import { HomeSectionJ } from '../home/components/homeSectionJ'
import { HomeSectionK } from '../home/components/homeSectionK'
import { HomeTopBar } from '../home/components/homeTopBar'
import logo from '../../common/assets/images/logo.png'

const URL_PRICING_AND_FAQ_PAGE = '/workingonit'

const PricingAndFAQ = () => {
  return (
    <>
      <HomeTopBar />
      <div className='pricing-page'>
        <picture>
          <img src={logo} alt='QYP beta' />
        </picture>

        <h1>We are working on this!</h1>
      </div>
      <HomeSectionJ />
      <HomeSectionK />
      <MainThemeFooter />
    </>
  )
}

export { URL_PRICING_AND_FAQ_PAGE, PricingAndFAQ }