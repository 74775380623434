import { Statistic, Table } from 'antd'

export function QuoteOverviewTotals ({ totals, divisa, agent, exchangeVal, agentDivisa, className, soloTotals }) {
  const columns = []

  if (agent?.type === 'Agency') {
    columns.push(
      {
        title: 'Currency',
        key: 'divisa',
        render: ({ divisa }) => 'TOTAL ' + divisa
      },
      {
        title: 'TOTAL',
        key: 'total',
        render: ({ total }) => <Statistic prefix='$' value={`${total}`} precision={2} />
      },
      {
        title: 'APROX ' + agentDivisa?.code,
        key: 'aprox',
        render: ({ aprox }) => <Statistic prefix='$' value={`${aprox}`} precision={2} />
      }
    )
  } else {
    if (soloTotals) {
      columns.push(
        {},
        {
          // title: 'Divisa',
          title: 'TOTAL',
          key: 'total',
          render: ({ total }) => <Statistic prefix='$' value={`${total}`} precision={2} />

        }
      )
    } else {
      columns.push(
        {
          title: 'Currency',
          key: 'divisa',
          render: ({ divisa }) => ('TOTAL ' + divisa)
        },
        {
          // title: 'Divisa',
          title: 'TOTAL',
          key: 'total',
          render: ({ total }) => <Statistic prefix='$' value={`${total}`} precision={2} />

        }
      )
    }
  }

  return (
    <Table
      pagination={{ hideOnSinglePage: true }}
      className={'byp-table-clear ' + className}
      dataSource={totals}
      columns={columns} direction='rtl'
    />
  )
}
