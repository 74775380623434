import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { MiscellaneousForm } from './components/MiscellaneousForm'

const URL_CREATE_MISCELLANEOUS_PAGE = '/dashboard/miscellaneous'

function MiscellaneousPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <MiscellaneousForm />
      </MainTheme>
    </PageProtected>
  )
}

export { MiscellaneousPage, URL_CREATE_MISCELLANEOUS_PAGE }
