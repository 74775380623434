import { Collapse, Form, Tag } from 'antd'
import { ConceptDiscountFormItem } from '../components/ConceptDiscountFormItem'

const { Panel } = Collapse

const panelHeader = (position, values, name) => {
  if (position === undefined || values === undefined) return ''
  const header = (
    <>
      {values[name][position].campus} <Tag className='byp-ml-1'>{values[name][position].status}</Tag>
    </>
  )
  return header
}

// const getDivisa = (position, values, name) => {
//   if (position === undefined || values === undefined) return 'Sin divisa'
//   return values[name][position].divisa ?? 'Divisa inválida'
// }

// const header = (name) => {
//   return name === 'lodgings' ? 'Accommodation name' : 'Transport name'
// }
function DiscountConceptFieldsContainer ({ form, name, subname }) {
  const listName = name
  const array = Form.useWatch(listName, form) || []
  return (
    <Form.List
      name={listName}
    >
      {(fields, { add, remove }, { errors }) => (
        <Collapse
          defaultActiveKey={['0']}
          expandIconPosition='right'
        >
          {fields.map(({ key, name, ...restField }, index) => (
            <Panel
              header={
                panelHeader(
                  restField.fieldKey,
                  form.getFieldValue(),
                  listName
                )
              }
              key={key}
            >
              <ConceptDiscountFormItem
                name={[name, subname]}
                cleanName={subname}
                form={form}
                list={array[name]}
              />
            </Panel>
          ))}
        </Collapse>
      )}
    </Form.List>
  )
}

export { DiscountConceptFieldsContainer }
