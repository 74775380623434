import React from 'react'
import { Row, Col, Space, Progress } from 'antd'
import {
  CreatedIcon,
  SendedIcon,
  VisitedIcon,
  ApplicationsIcon
} from '../../../../../quote-preview/assets/imgs/Icons'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'
import { useGetQuotesStatisticsByProgram } from '../../../../../../bussines/hooks/agency/analytics/program/useGetQuotesStatisticsByProgram'

const ProgramTotalQuotes = ({ time, program, campus, institution }) => {
  const { data, isLoading } = useGetQuotesStatisticsByProgram(program, campus, { ...time, institution })

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]} className='total-quote-user'>
      <Col
        className='total-quote'
      >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <CreatedIcon />
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.created?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.created?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Created</p>
        </div>
      </Col>
      <Col
        className='total-quote'
      >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <SendedIcon />
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.sended?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.sended?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Sent by email</p>
        </div>
      </Col>
      <Col
        className='total-quote'
      >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <VisitedIcon />
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.visited?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.visited?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Seen <i className='byp-text-sm'>(mail and link)</i></p>
        </div>
      </Col>
      <Col
        className='total-quote'
      >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <VisitedIcon />
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.notseen?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.notseen?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Not Seen <i className='byp-text-sm'>(mail and link)</i></p>
        </div>
      </Col>
      <Col
        className='total-quote user-analysis-total'
      >
        <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem', height: '100%' }}>
          <ApplicationsIcon />
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.applications?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.applications?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Applications</p>
        </div>
      </Col>
    </Row>
  )
}

export default ProgramTotalQuotes
