import { userRoles } from '../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { TopBarMenuCustom } from '../agency/components/TopBarMenuCustom'
import { FormPackages } from './FormPackages'

const URL_PACKAGES_PAGE = '/dashboard/packages'

function PackagesPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_SUPER_ADMIN,
        userRoles.TYPE_SUPER_EDITOR,
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_MANAGER,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
        userRoles.TYPE_AGENCY_EDITOR,
        userRoles.TYPE_INSTITUTION,
        userRoles.TYPE_EG_ADMIN
      ]}
    >
      <MainTheme
        showMenu
      >
        <TopBarMenuCustom customizable='packages' />
        <FormPackages />
      </MainTheme>
    </PageProtected>
  )
}

export { PackagesPage, URL_PACKAGES_PAGE }
