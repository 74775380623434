import { Link } from 'react-router-dom'
import { URL_INSTITUTION_SCHOOLS_PAGE } from '../../../pages/dashboards/Institution/Schools/CreateSchoolsPage'
import { URL_UPLOAD_PRICES_PAGE } from '../../../pages/dashboards/Institution/Prices/UploadPricesPage'
import { URL_COURSE_CREATE_PAGE } from '../../../pages/dashboards/Institution/Courses/CreateCoursePage'
import { URL_COURSEPRICES_CREATE_PAGE } from '../../../pages/dashboards/Institution/CoursePrices/CreateCoursePricesPage'
import { URL_LODGING_CREATE_PAGE } from '../../../pages/dashboards/Institution/Lodging/CreateLodgingsPage'
import { URL_LODGINGPRICES_CREATE_PAGE } from '../../../pages/dashboards/Institution/LodgingPrices/CreateLodgingPricesPage'
import { URL_UPLOAD_TRANSPORTS_PAGE } from '../../../pages/dashboards/Institution/Transports/UploadTransportsPage'
import { URL_TRANSPORTGPRICES_CREATE_PAGE } from '../../../pages/dashboards/Institution/TransportPrices/CreateTransportPricesPage'
import { URL_DISCOUNTS_CREATE_PAGE } from '../../../pages/dashboards/Institution/Discounts/CreateDiscountsPage'
import { URL_CUSTOMDISCOUNT_PAGE } from '../../../pages/dashboards/Institution/Discounts/CustomDiscountsPage'
import { URL_EDIT_INSTITUTION } from '../../../pages/institution/EditInstitutionProfilePage'
import { StatusQuoteIcon } from '../icons/IconsSvg'
import { URL_CUSTOM_QUOTE_PAGE } from '../../../pages/dashboards/Institution/Quote/CustomQuotePage'
import { URL_UPLOAD_DOCUMENTS_PAGE } from '../../../pages/dashboards/Institution/Documents/UploadDocumentsPage'
import { URL_CREATE_USERS_PAGE } from '../../../pages/dashboards/users/CrudUserPage'
import { DesktopIcon } from '../icons/DesktopIcon'
import { SchoolProfileIcon } from '../icons/SchoolProfileIcon'
import { SchoolsIcon } from '../icons/SchoolsIcon'
import { MarketingMaterialIcon } from '../icons/MarketingMaterialIcon'
import { UsersIcon } from '../icons/UsersIcon'
import { MyPlanIcon } from '../icons/MyPlanIcon'
import { URL_MATERIALS_STATUS_PAGE } from '../../../pages/dashboards/Institution/MaterialsStatus/MaterialsStatusPage'
import { getItem, getQuotesStatusItems } from './components/get-menu-item'
import { IconAnalyticsSharp } from '../../assets/svg/icons'
import { URL_GENERAL_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/general/GeneralAnalysisPage'
import { URL_USER_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/user/UserAnalysisPage'
// import { URL_OFFICE_ANALITCIS_PAGE } from '../../../pages/dashboards/agency/analitics/office/OfficeAnaliticsPage'
import { URL_USER_AGENCIES_PAGE } from '../../../pages/dashboards/Institution/analitics/AgenciesAnaliticsPage'
import { URL_SCHOOL_ANALITCIS_PAGE } from '../../../pages/dashboards/agency/analitics/school/SchoolAnaliticsPage'
import { URL_HOME_LANDER_PAGE } from '../../../pages/dashboards/home-slider/HomeSliderPage'
import { URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE } from '../../../pages/dashboards/packages/DiscountsPackagesPage'
import { URL_PACKAGES_PROMOTIONS_RULES_PAGE } from '../../../pages/dashboards/packagesPromotions/PromotionsRulesPage'
import { URL_SHORT_TERM_PAGE } from '../../../pages/dashboards/Institution/ShortTerm'
import { URL_SHORT_TERM_PRICES_PAGE } from '../../../pages/dashboards/Institution/ShortTerm/ShortTermPricesPage'
import { URL_SHORT_TERM_PROMOTIONS_PAGE } from '../../../pages/dashboards/Institution/ShortTerm/UploadPromotionsPage'
import { URL_COURSE_EXTRA_INFO_PAGE } from '../../../pages/dashboards/Institution/Extra/CourseExtraInfoPage'
import { URL_ACCOMODATION_EXTRA_INFO_PAGE } from '../../../pages/dashboards/Institution/Extra/AccomodationExtraInfoPage'
import { URL_CAMPUS_EXTRA_INFO_PAGE } from '../../../pages/dashboards/Institution/Extra/CampusExtraInfoPage'

function getCoursesItems (program) {
  return [
    getItem(
      <Link
        to={URL_COURSE_CREATE_PAGE.replace(':program', program)}
        state={{ menuItemSelected: program + '_courses_create', openSub: program, openSub2: program + '_courses' }}
      >Upload courses
      </Link>,
      program + '_courses_create'
    ),
    getItem(
      <Link
        to={URL_COURSE_EXTRA_INFO_PAGE.replace(':program', program)}
        state={{ menuItemSelected: program + '_courses_create_extra_info', openSub: program, openSub2: program + '_courses' }}
      >Upload extra info
      </Link>,
      program + '_courses_create_extra_info'
    ),
    getItem(
      <Link
        to={URL_COURSEPRICES_CREATE_PAGE.replace(':program', program)}
        state={{ menuItemSelected: program + '_courses_prices', openSub: program, openSub2: program + '_courses' }}
      >Upload course prices
      </Link>,
      program + '_courses_prices'
    )
  ]
}

function getCoursesSubmenuItems (program, submenu) {
  const url = URL_COURSE_CREATE_PAGE.replace(':program', program)
  const pricesUrl = URL_COURSEPRICES_CREATE_PAGE.replace(':program', program)
  const extraInfoUrl = URL_COURSE_EXTRA_INFO_PAGE.replace(':program', program)
  return [
    getItem(
      <Link
        to={submenu ? url + '?type=' + submenu : url}
        state={{
          menuItemSelected: program + '_courses_create' + submenu,
          openSub: program,
          openSub2: program + '_courses',
          openSub3: program + '_courses_submenu_' + submenu
        }}
      >Upload courses
      </Link>,
      program + '_courses_create' + submenu
    ),
    getItem(
      <Link
        to={submenu ? extraInfoUrl + '?type=' + submenu : extraInfoUrl}
        state={{
          menuItemSelected: program + '_courses_create_extra_info' + submenu,
          openSub: program,
          openSub2: program + '_courses',
          openSub3: program + '_courses_submenu_' + submenu
        }}
      >Extra info
      </Link>,
      program + '_courses_create_extra_info' + submenu
    ),
    getItem(
      <Link
        to={submenu ? pricesUrl + '?type=' + submenu : url}
        state={{ menuItemSelected: program + '_courses_prices' + submenu, openSub: program, openSub2: program + '_courses' }}
      >Upload course prices
      </Link>,
      program + '_courses_prices' + submenu
    )
  ]
}
// Submenu For Courses
function getCoursesSubmenu (program) {
  if (program !== 'graduate') {
    return getCoursesItems(program)
  } else {
    return [
      getItem(
        'Diploma',
        program + '_courses_submenu_diploma',
        null,
        getCoursesSubmenuItems(program, 'diploma')
      ),
      getItem(
        'Bachelor Degree',
        program + '_courses_submenu_bachelor',
        null,
        getCoursesSubmenuItems(program, 'bachelor')
      ),
      getItem(
        'Postgraduate Degree',
        program + '_courses_submenu_postgraduate',
        null,
        getCoursesSubmenuItems(program, 'postgraduate')
      ),
      getItem(
        'Master Degree',
        program + '_courses_submenu_master',
        null,
        getCoursesSubmenuItems(program, 'master')
      )
    ]
  }
}
// Submenu Items For Promgrams menu
function getProgramSubMenu (program) {
  if (program === 'boarding_schools') {
    return [
      getItem(
        <Link
          to={URL_INSTITUTION_SCHOOLS_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_schools', openSub: program }}
        >Upload schools
        </Link>,
        program + '_schools',
        <SchoolsIcon />
      ),
      getItem(
        <Link
          to={URL_CAMPUS_EXTRA_INFO_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_schools_extra_info', openSub: program }}
        >Schools extra info
        </Link>,
        program + '_schools_extra_info'
      ),
      getItem(
        <Link
          to={URL_UPLOAD_PRICES_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_general', openSub: program }}
        >Upload general prices
        </Link>,
        program + '_general'
      ),
      getItem(
        'Courses', program + '_courses', <></>,
        getCoursesSubmenu(program)
      ),
      getItem(
        'Discounts and promotions',
        program + '_discounts',
        <></>,
        [
          getItem(
            <Link
              to={URL_DISCOUNTS_CREATE_PAGE.replace(':program', program)}
              state={{ menuItemSelected: program + '_discounts_create', openSub: program, openSub2: program + '_discounts' }}
            >Upload
            </Link>,
            program + '_discounts_create'
          ),
          getItem(
            <Link
              to={URL_CUSTOMDISCOUNT_PAGE.replace(':program', program)}
              state={{ menuItemSelected: program + '_discounts_prices', openSub: program, openSub2: program + '_discounts' }}
            >Customize
            </Link>,
            program + '_discounts_prices'
          )
        ]
      )
    ]
  } else if (program === 'short_term') {
    return [
      getItem(
        <Link
          to={URL_INSTITUTION_SCHOOLS_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_schools', openSub: program }}
        >Upload schools
        </Link>,
        program + '_schools'
      ),
      getItem(
        <Link
          to={URL_CAMPUS_EXTRA_INFO_PAGE.replace(':program', program)}
          state={{ menuItemSelected: program + '_schools_extra_info', openSub: program }}
        >Schools extra info
        </Link>,
        program + '_schools_extra_info'
      ),
      getItem(
        <Link
          to={URL_SHORT_TERM_PAGE}
          state={{ menuItemSelected: 'upload-packages', openSub: program }}
        >Upload Short Term
        </Link>
        ,
        'upload-packages'
      ),
      getItem(
        <Link
          to={URL_SHORT_TERM_PRICES_PAGE}
          state={{ menuItemSelected: 'prices-packages', openSub: program }}
        >Upload prices
        </Link>,
        'prices-packages'
      ),
      getItem(

        'Dicounts and promotions',
        'promotions-packages',
        undefined,
        [
          getItem(
            <Link
              to={URL_SHORT_TERM_PROMOTIONS_PAGE}
              state={{ menuItemSelected: 'upload-promotions', openSub: 'promotions-packages', openSub2: program }}
            >
              Upload promotions
            </Link>,
            'upload-promotions'
          ),
          getItem(
            <Link
              to={URL_PACKAGES_PROMOTIONS_RULES_PAGE}
              state={{
                menuItemSelected: 'promotions-rules',
                openSub: 'promotions-packages',
                openSub2: program,
                program
              }}
            >
              Promotions rules
            </Link>,
            'promotions-rules'
          ),
          getItem(
            <Link
              to={URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE}
              state={{ menuItemSelected: 'all-promotions', openSub: 'promotions-packages', openSub2: program }}
            >
              All promotions
            </Link>,
            'all-promotions'
          )
        ]
      )
    ]
  }
  return [
    getItem(
      <Link
        to={URL_INSTITUTION_SCHOOLS_PAGE.replace(':program', program)}
        state={{ menuItemSelected: program + '_schools', openSub: program }}
      >Upload schools
      </Link>,
      program + '_schools'
    ),
    getItem(
      <Link
        to={URL_CAMPUS_EXTRA_INFO_PAGE.replace(':program', program)}
        state={{ menuItemSelected: program + '_schools_extra_info', openSub: program }}
      >Schools extra info
      </Link>,
      program + '_schools_extra_info'
    ),
    getItem(
      <Link
        to={URL_UPLOAD_PRICES_PAGE.replace(':program', program)}
        state={{ menuItemSelected: program + '_general', openSub: program }}
      >Upload general prices
      </Link>,
      program + '_general'
    ),
    getItem(
      'Courses', program + '_courses', <></>,
      getCoursesSubmenu(program)
    ),
    getItem(
      'Accommodations', program + '_lodgings', <></>,
      [
        getItem(
          <Link
            to={URL_LODGING_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_lodgings_create', openSub: program, openSub2: program + '_lodgings' }}
          >Upload accommodations
          </Link>,
          program + '_lodgings_create'
        ),
        getItem(
          <Link
            to={URL_ACCOMODATION_EXTRA_INFO_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_lodgings_create_extra_info', openSub: program, openSub2: program + '_lodgings' }}
          >Extra info
          </Link>,
          program + '_lodgings_create_extra_info'
        ),
        getItem(
          <Link
            to={URL_LODGINGPRICES_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_lodgings_prices', openSub: program, openSub2: program + '_lodgings' }}
          >Accommodation fees
          </Link>,
          program + '_lodgings_prices'
        )
      ]
    ),
    getItem(
      'Transport',
      program + '_transports',
      <></>,
      [
        getItem(
          <Link
            to={URL_UPLOAD_TRANSPORTS_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_transports_create', openSub: program, openSub2: program + '_transports' }}
          >Upload transports
          </Link>,
          program + '_transports_create'
        ),
        getItem(
          <Link
            to={URL_TRANSPORTGPRICES_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_transports_prices', openSub: program, openSub2: program + '_transports' }}
          >Transport fees
          </Link>,
          program + '_transports_prices'
        )
      ]
    ),
    getItem(
      'Discounts and promotions',
      program + '_discounts',
      <></>,
      [
        getItem(
          <Link
            to={URL_DISCOUNTS_CREATE_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_discounts_create', openSub: program, openSub2: program + '_discounts' }}
          >Upload
          </Link>,
          program + '_discounts_create'
        ),
        getItem(
          <Link
            to={URL_CUSTOMDISCOUNT_PAGE.replace(':program', program)}
            state={{ menuItemSelected: program + '_discounts_prices', openSub: program, openSub2: program + '_discounts' }}
          >Customize
          </Link>,
          program + '_discounts_prices'
        )
      ]
    )
  ]
}

function getProgramsMenus (programs) {
  const menuItems = programs?.map((program) => (
    getItem(
      program?.label, program.name, undefined,
      getProgramSubMenu(program.name)
    )
  ))
  return menuItems ?? []
}

function institutionMenuItems (programs) {
  return [
    getItem(
      <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
      '1',
      <DesktopIcon />
    ),
    getItem(
      <Link to={URL_EDIT_INSTITUTION}>School Profile</Link>,
      '2',
      <SchoolProfileIcon />
    ),
    getItem(
      'Analytics',
      'analitic-institution',
      <IconAnalyticsSharp />,
      [
        getItem(
          <Link
            to={URL_GENERAL_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'general-analysis', openSub: 'analitic-institution' }}
          >General analysis
          </Link>,
          'general-analysis'
        ),
        getItem(
          <Link
            to={URL_SCHOOL_ANALITCIS_PAGE}
            state={{ menuItemSelected: 'schools-analysis', openSub: 'analitic-institution' }}
          >School's analysis
          </Link>,
          'schools-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_AGENCIES_PAGE + '/agencies'}
            state={{ menuItemSelected: 'agencies-analysis', openSub: 'analitic-institution' }}
          >Agencies' analysis
          </Link>,
          'agencies-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'user-analysis', openSub: 'analitic-institution' }}
          >User analysis
          </Link>,
          'user-analysis'
        )
      ]
    ),
    ...getProgramsMenus(programs),
    getItem(
      'Quotes status',
      'sub2',
      <StatusQuoteIcon />,
      getQuotesStatusItems(programs)
    ),
    getItem(
      'Marketing material',
      'material',
      <MarketingMaterialIcon />,
      [
        getItem(
          <Link
            to={URL_UPLOAD_DOCUMENTS_PAGE}
            state={{ menuItemSelected: 'upload-documents', openSub: 'material' }}
          >Upload documents
          </Link>,
          'upload-documents'
        ),
        getItem(
          <Link
            to={URL_CUSTOM_QUOTE_PAGE}
            state={{ menuItemSelected: 'custom-quote', openSub: 'material' }}
          >Upload quote pictures
          </Link>,
          'custom-quote'
        ),
        getItem(
          <Link
            to={URL_HOME_LANDER_PAGE}
            state={{ menuItemSelected: 'home-slider', openSub: 'material' }}
          >Home Slider Pictures
          </Link>,
          'home-slider'
        ),
        getItem(
          <Link
            to={URL_MATERIALS_STATUS_PAGE}
            state={{ menuItemSelected: 'material-status', openSub: 'material' }}
          >Materials Status
          </Link>,
          'material-status'
        )
      ]
    ),
    getItem(
      <Link to={URL_CREATE_USERS_PAGE} state={{ menuItemSelected: 'users' }}>Users</Link>,
      'users', <UsersIcon />
    ),
    getItem(
      'My plan',
      '31', <MyPlanIcon />
    )
  ]
}

export default institutionMenuItems
