import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetMostPopularPrograms (filters = {}) {
  return useQuery(['useGetMostPopularPrograms', filters],
    () => GeneralAnalysisRepository.getMostPopularPrograms(filters),
    {
      staleTime: 5000
    }
  )
}

export { useGetMostPopularPrograms }
