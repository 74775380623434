import { useState } from 'react'
import { useMutation } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'
import SessionService from '../../../services/SessionService'

const state = {
  STATE_INITIAL: 'STATE_INITIAL',
  STATE_SUCCESS: 'STATE_SUCCESS',
  STATE_ERROR: 'STATE_ERROR'
}

function useCreateUser () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const [errorMessage, setErrorMessage] = useState('')
  const { mutate, isLoading, error, isError } = useMutation(
    AgencyRepository.register,
    {
      onSuccess: (d) => {
        SessionService.createSession(d)
        setCurrentState(state.STATE_SUCCESS)
      },
      onError: (e) => {
        setErrorMessage(e?.response?.data?.message ?? '')
        setCurrentState(state.STATE_ERROR)
      }
    }
  )

  /**
   * Register
   * @param name
   * @param email
   * @param password
   * @param passwordConfirmation
   * @param typeRegister
   */
  const register = (name, email, password, passwordConfirmation, typeRegister, role) => {
    mutate({
      name,
      email,
      password,
      password_confirmation: passwordConfirmation,
      type_register: typeRegister,
      role
    })
  }

  return {
    isLoading,
    register,
    state,
    error,
    currentState,
    isError,
    errorMessage
  }
}

export { useCreateUser }
