import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useGetRolesBySede (sede) {
  return useQuery(['useGetRolesBySede', sede], () => UserRepository.rolesBySede(sede), {
    enabled: !!sede,
    retry: 1
  })
}

export { useGetRolesBySede }
