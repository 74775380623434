import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useGetDiscountPackageValues (discount, year = null, filters = {}) {
  return useQuery(['useGetDiscountPackageValues', discount, year, filters],
    () => DiscountRepository.getPackageValues(discount, year, filters), {
      retry: 1,
      enabled: !!discount
    })
}

export { useGetDiscountPackageValues }
