import React from 'react'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormMaterialsStatus } from './FormMaterialsStatus'

const URL_MATERIALS_STATUS_PAGE = '/dashboard/custom/quote/materials'

function MaterialsStatusPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          <FormMaterialsStatus />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { MaterialsStatusPage, URL_MATERIALS_STATUS_PAGE }
