import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'

const ModalUserAlias = ({ isModalOpen, setIsModalOpen, onSuccess, isLoading }) => {
  const [isThereError, setError] = useState(false)
  const [, isFinished] = useState(false)
  const [form] = Form.useForm()

  const Error = () => {
    setError(true)
  }

  const NoError = () => {
    setError(false)
  }

  function VerifyMistake () {
    if (form.getFieldValue('username')) {
      if (form.getFieldValue('username').trim().length === 0) {
        Error()
      } else {
        isFinished(true)
        NoError()
        if (onSuccess) onSuccess(form.getFieldValue('username'))
        console.log('Ok Buddy, Lets GO')
      }
    } else {
      Error()
    }
  }

  function ShowMessage () {
    if (isThereError) {
      return (
        <div style={{ color: 'red' }}>
          *Insert a name
        </div>
      )
    }
  }

  return (
    <Form
      // onFinish={finish}
      form={form}
      className='w-100'
    >
      <Modal open={isModalOpen} visible={isModalOpen} onCancel={() => setIsModalOpen(false)} closable={false} footer='' width='400px'>
        <Form.Item name='username' label='Insert your name'>
          <Input />
        </Form.Item>
        <ShowMessage />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '10px' }}>
          <Button onClick={() => setIsModalOpen(false)} style={{ fontWeight: 'bold' }}>
            Cancel
          </Button>
          <Button
            onClick={VerifyMistake}
            style={{ background: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}
            loading={isLoading}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </Form>
  )
}

export { ModalUserAlias }
