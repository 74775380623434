import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useFindUser (id) {
  return useQuery(['useFindUser', id],
    () => UserRepository.findUser(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useFindUser }
