import { Col, Collapse, Form, Input, InputNumber, Row, Select } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import { CoursePriceRangeFormList } from './CoursePriceRangeFormList'
const { Panel } = Collapse
const { Option } = Select

const readOnly = (field) => {
  if (!field) return false
  return (field.discount_type === undefined || field.discount_type === null || field.discount_type === 'none')
}

const isRequired = (field, name) => {
  if (!field) return []
  if (field[name] !== undefined && field[name] !== null && field.discount_type !== 'none') {
    return [{ required: true, message: 'This field is required' }]
  } else {
    return []
  }
}

function ConceptDiscountFormItem ({ name, cleanName = 'courses', rootName, showInKind = false, list = [], hidden = false, ranges = false }) {
  const array = list[cleanName] || []
  const form = Form.useFormInstance()
  return (
    <div className='w-100'>
      <Form.List
        name={name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const onChangeSomeValue = () => {
                const course = array[name]
                const campusWithChanges = form.getFieldValue('campusWithChanges') ?? []
                const coursesWithChanges = form.getFieldValue('coursesWithChanges') ?? []
                if (campusWithChanges.includes(list.campus_id)) {
                  const isAlreadyInluded = coursesWithChanges?.find(
                    (item) => item.id === course.id && item.campus === course.campus_id
                  )
                  if (!isAlreadyInluded) {
                    coursesWithChanges.push({ id: course.id, campus: course.campus_id })
                    // console.log('🚀 ~ file: ConceptDiscountFormItem.js:43 ~ onChangeSomeValue ~ coursesWithChanges:', coursesWithChanges)
                    form.setFieldsValue({
                      coursesWithChanges
                    })
                  }
                } else {
                  campusWithChanges.push(list.campus_id)
                  coursesWithChanges.push({ id: course.id, campus: course.campus_id })
                  // console.log('🚀 ~ file: ConceptDiscountFormItem.js:51 ~ onChangeSomeValue ~ coursesWithChanges:', coursesWithChanges)
                  form.setFieldsValue({
                    campusWithChanges,
                    coursesWithChanges
                  })
                }
              }
              return (
                <Collapse
                  key={'collapese_' + key}
                  defaultActiveKey={['1']}
                >
                  <Panel header={array[name]?.label ?? array[name]?.name} key='1'>
                    <Row key={key} gutter={16}>
                      <Col xs={24} md={hidden ? 0 : 10}>
                        <Form.Item
                          name={[name, 'name']}
                        >
                          <Input hidden={hidden} />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={6}>
                        {/* {
                          curso.x.courses.j.hasChanges
                        } */}
                        <Form.Item
                          name={[name, 'discount_type']}
                          // rules={isRequired(array[name], 'discount')}
                        >
                          <Select
                            allowClear size='small'
                            onChange={onChangeSomeValue}
                          >
                            <Option key={0} value='none'>None</Option>
                            <Option key={1} value='amount'>Quantity</Option>
                            <Option key={2} value='percentage'>Percentage</Option>
                            {showInKind && <Option key={4} value='inkind'>Other</Option>}
                          </Select>
                        </Form.Item>
                      </Col>
                      {
                        array[name]?.discount_type === 'inkind' && (
                          <Col xs={12} md={8}>
                            <FormItem
                              name={[name, 'discount']}
                              rules={isRequired(array[name], 'discount_type')}
                            >
                              <Input
                                placeholder='Good Name'
                                onChange={onChangeSomeValue}
                              />
                            </FormItem>
                            <FormItem
                              name={[name, 'discount_description']}
                            >
                              <TextArea
                                placeholder='Good(s) description'
                                onChange={onChangeSomeValue}
                              />
                            </FormItem>
                          </Col>
                        )
                      }
                      {
                        array[name]?.discount_type !== 'inkind' && (
                          <Col xs={12} md={8}>
                            <Form.Item
                              name={[name, 'discount']}
                              className='byp-input-gray'
                              rules={isRequired(array[name], 'discount_type')}
                            >
                              <InputNumber
                                min={0}
                                addonAfter={array[name]?.discount_type === 'percentage' && '%'}
                                readOnly={readOnly(array[name])}
                                onChange={onChangeSomeValue}
                                controls={false}
                              />
                            </Form.Item>
                          </Col>
                        )
                      }
                      {
                        // accomodation search fees
                        array[name]?.accomodation_fee && (
                          <>
                            <Col xs={24} md={hidden ? 0 : 10}>
                              <p>accomodation_fee</p>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Item
                                name={[name, 'search_discount_type']}
                                // rules={isRequired(array[name], 'search_discount')}
                              >
                                <Select allowClear size='small'>
                                  <Option key={0} value='none'>None</Option>
                                  <Option key={1} value='amount'>Quantity</Option>
                                  <Option key={2} value='percentage'>Percentage</Option>
                                  {/* <Option key={3} value='total'>Total</Option> */}
                                  {/* {showInKind && <Option key={4} value='inkind'>Other</Option>} */}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={12} md={8}>
                              <Form.Item
                                name={[name, 'search_discount']}
                                className='byp-input-gray'
                                rules={isRequired(array[name], 'search_discount_type')}
                              >
                                <InputNumber
                                  min={0}
                                  addonAfter={array[name]?.discount_type === 'percentage' && '%'}
                                  // readOnly={readOnly(array[name])}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }
                      {ranges && (
                        <Col xs={24}>
                          <CoursePriceRangeFormList
                            name={[name, 'ranges']}
                            onlyRanks
                            onChangeSomeValue={onChangeSomeValue}
                          />
                        </Col>
                      )}
                    </Row>
                  </Panel>
                </Collapse>
              )
            })}
          </>
        )}
      </Form.List>
    </div>
  )
}

export { ConceptDiscountFormItem }
