import React from 'react'
import { StepBaseTemplate } from './components/StepBaseTemplate'
import { RegisterForm } from './components/forms/RegisterForm'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import { useSearchParams } from 'react-router-dom'

const URL_INVITATION_REGISTER_PAGE = '/register/invitation'

function InvitationRegister () {
  const [params] = useSearchParams()
  const email = params.get('email') ?? ''
  const type = params.get('type') ?? 'User'
  const role = params.get('role') ?? 'user'
  const invitation = true

  return (
    <StepBaseTemplate justify='space-around' align='middle'>
      <div style={{ margin: '18px 25%' }}>
        <AppLogoNameHeader
          style={{
            paddingBottom: 42,
            maxWidth: 300,
            padding: 18
          }}
        />
        <RegisterForm email={email} type={type} role={role} invitation={invitation} />
      </div>
    </StepBaseTemplate>
  )
}

export { InvitationRegister, URL_INVITATION_REGISTER_PAGE }
