import { Button, Col } from 'antd'
import { PlusOutlined, MinusOutlined} from '@ant-design/icons'

function DeleteButton ({ remove, name, notDelete }) {
  return (
    !notDelete && (
      <Col >
        <Button
          className='byp-btn-list-function-del'
          style={{display:"flex"}}
          onClick={() => remove(name)}
          icon={<MinusOutlined />}
        >
          {/*<MinusOutlined />*/}
          Delete season
        </Button>
      </Col>
    )
  )
}
function AddAndRemoveSeasonBtn ({ fields, index, add, remove, name, notDelete }) {
  return (
    <>
      {
        (fields.length > 1 && index < (fields.length - 1))
          ? (
            <DeleteButton remove={remove} name={name} notDelete={notDelete} />
            )
          : (
            <>
              <DeleteButton remove={remove} name={name} notDelete={notDelete} />
              {/*<Col span={2}>*/}
                <Button
                  className='byp-btn-list-function-add'
                  onClick={() => add()}
                  style={{marginLeft:'.5rem'}}
                  icon={<PlusOutlined />}
                  
                >
                  Add season
                </Button>
              {/*</Col>*/}
            </>
            )
      }
    </>
  )
}

export { AddAndRemoveSeasonBtn }
