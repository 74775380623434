import { userRoles } from '../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { FormUser } from './FormUser'

const URL_CREATE_USERS_PAGE = '/dashboard/users'
const URL_CREATE_USERS_PAGE_ID = '/dashboard/users/:id'

function CrudUserPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <FormUser />
      </MainTheme>
    </PageProtected>
  )
}

export { CrudUserPage, URL_CREATE_USERS_PAGE, URL_CREATE_USERS_PAGE_ID }
