import * as React from "react"

export const UsersIcon = (props) => {
  return (
    <svg
      width={14}
      height={12}
      viewBox="0 0 14 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.125 11.25s-.875 0-.875-.875.875-3.5 4.375-3.5S14 9.5 14 10.375s-.875.875-.875.875h-7zM9.625 6a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.564 11.25a1.959 1.959 0 01-.189-.875c0-1.186.595-2.406 1.694-3.255a5.534 5.534 0 00-1.694-.245C.875 6.875 0 9.5 0 10.375s.875.875.875.875h3.689z"
        fill="currentColor"
      />
      <path
        d="M3.938 6a2.188 2.188 0 100-4.375 2.188 2.188 0 000 4.375z"
        fill="currentColor"
      />
    </svg>
  )
}