import React from 'react'
import { useUpdateProfileGus } from '../bussines/hooks/admin/usePutConvertCampusInstitution'
import { PageProtected } from '../common/components/PageProtected'
import { userRoles } from '../bussines/settings/validate-user-access'

const CONVERT_CAMPUS_TO_INSTITUTION = '/admin/profile_gus'

const ConvertCampusToInstitution = () => {
  const { isLoading, error } = useUpdateProfileGus()
  // const { convert, isLoading, error } = usePutConvertCampusInstitution()
  // useEffect(() => {
  //   convert(36)
  // }, [])

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_SUPER_ADMIN]}>
      <>
        {error && <p>error</p>}
        {isLoading && <p>Loading...</p>}
        {!isLoading && !error && <p>Profile created</p>}
      </>
    </PageProtected>
  )
}

export { ConvertCampusToInstitution, CONVERT_CAMPUS_TO_INSTITUTION }
