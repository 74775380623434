import { addDays, addWeeks, differenceInDays, format, isSameMonth, parseISO } from 'date-fns'
import { CalculateLodgingCost, CalculateLodgingExtraNightCost } from '../bussines/logic/quoter/CalculateLodgingCost'
import { calculateTotalDiscount } from '../bussines/logic/quoter/DiscountsLogic'
import { calculateHighSeasonCosts, isHighSeason } from '../bussines/logic/quoter/isHighSeason'
import { calculateCost, calculateCostByDays, calculateHsCost } from '../pages/quote-course/utils/calculateCost'
import { calculateServiceCost, sumArrayCost } from '../bussines/logic/quoter/calculateServiceCost'
import { calculateAccomodationHGS } from '../bussines/logic/quoter/calculateAccommodationHighSeason'
import { isSameOrAfter } from '../utils/isSameOrAfter'
import { isSameOrBefore } from '../utils/isSameOrBefore'
import { notification } from 'antd'
import { formatStringDate } from '../utils/formatStringDate'

const dayNumber = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7
}

// const seasons = ['Spring', 'Summer', 'Fall', 'Winter']

export class HighEducationQuoterService {
  constructor (courseItem, config, setFormConfig, user, exchangeValues, prices, setPrices) {
    config.enableAllDates = true
    this.course = courseItem
    this.config = config
    this.setFormConfig = setFormConfig
    this.user = user
    this.exchanges = exchangeValues
    this.prices = prices
    this.setPrices = setPrices
  }

  searchPrices (date) {
    let nDate = new Date(parseISO(date))
    nDate = nDate.toISOString()
    nDate = new Date(nDate)
    const allPrices = this.course?.offer?.prices ?? []
    const found = allPrices.find((prices) => {
      let startDate = new Date(parseISO(prices.year?.prices_start ?? null))
      startDate = startDate.toISOString()
      startDate = new Date(startDate)
      const endDate = new Date(parseISO(prices.year?.prices_end ?? null))
      if (isSameOrAfter(nDate, startDate) && isSameOrBefore(nDate, endDate)) {
        return true
      }
      return false
    })
    // console.log('found', found, date, this.config)
    if (found) {
      if (found?.year?.year !== this.getPrices()?.year?.year) {
        // this.config.lodging
        // this.config.lodgingId
        // this.config.medical
        // this.config.serviceAgency
        this.setFormConfig({
          ...this.config,
          courseCosts: [],
          appliedGoods: [],
          transportMinorCosts: [],
          lodgingExtras: [],
          PeakSeasonFees: [],
          fees: []
        })
      }
      this.setPrices(found)
    } else {
      const datesForPrices = allPrices.map((prices) => ({
        start: prices.year?.prices_start,
        end: prices.year?.prices_end,
        year: prices.year?.year
      }))
      let datesMessafe = ''
      datesForPrices.forEach(prices => {
        datesMessafe = datesMessafe + '[' + prices.year + ': De ' + prices.start + ' al ' + prices.end + ']' + '\n'
      })
      notification.info({
        message: 'Prices for this date  (' + nDate + ')  not found \n' + datesMessafe
      })
    }
  }

  searchPricesById (id, nDate) {
    const allPrices = this.course?.offer?.prices ?? []
    const found = allPrices.find((prices) => {
      return prices?.year?.id === parseInt(id)
    })
    if (found) {
      if (found?.year?.year !== this.getPrices()?.year?.year) {
        // this.config.lodging
        // this.config.lodgingId
        // this.config.medical
        // this.config.serviceAgency
        this.setFormConfig({
          ...this.config,
          courseCosts: [],
          appliedGoods: [],
          transportMinorCosts: [],
          lodgingExtras: [],
          PeakSeasonFees: [],
          fees: []
        })
      }
      this.setPrices(found)
    } else {
      const datesForPrices = allPrices.map((prices) => ({
        start: prices.year?.prices_start,
        end: prices.year?.prices_end,
        year: prices.year?.year
      }))
      let datesMessafe = ''
      datesForPrices.forEach(prices => {
        datesMessafe = datesMessafe + '[' + prices.year + ': De ' + prices.start + ' al ' + prices.end + ']' + '\n'
      })
      notification.info({
        message: 'Prices for this date  (' + nDate + ')  not found \n' + datesMessafe
      })
    }
  }

  getPrices () {
    return this.prices ?? this.course?.offer?.prices[0]
  }

  getPricesYear () {
    return this.getPrices()?.year
  }

  getCourseProgram () {
    return this.course.offer.program
  }

  getBasesPrices () {
    return this.getPrices()?.base_prices
  }

  isInstitution () {
    return this.user.type === 'Institution'
  }

  isAgency () {
    return this.user.type === 'Agency'
  }

  getCurrency () {
    return this.course.offer?.campus?.divisa?.code
  }

  getCurrencyId () {
    return this.getPrices()?.divisa?.id
  }

  isOnline () {
    return this.course.offer.modality === 'Online'
  }

  getNameCourse () {
    return this.getPrices()?.label ?? this.getPrices()?.name
  }

  getTotalWeeks () {
    return this.config.weeks ?? 0
  }

  getSchoolName () {
    return this.course.offer?.campus?.name
  }

  getComments () {
    return this.getPrices()?.comments || null
  }

  getDocuments () {
    return this.course.offer?.campus?.documents || []
  }

  // Course Seaon Dates
  getDates (intake, yearString = null) {
    // const year = this.getPricesYear().year
    let year = yearString
    const today = new Date()
    if (!year) year = today.getFullYear()
    const seasons = {
      Spring: [year + '-03-21', year + '-06-20'],
      Summer: [year + '-06-21', year + '-09-20'],
      Fall: [year + '-09-21', year + '-12-20'],
      Winter: [year + '-12-21', (Number(year) + 1) + '-03-20']
    }
    return seasons[intake ?? this.config.intake]
  }

  getCourseIntakes () {
    const prices = this.course?.offer?.prices ?? []
    const pricesDates = prices.map((prices) => prices.year)
    const intakes = this.course.offer?.intake ?? []
    const uniqueIntakes = intakes.filter((item, i, ar) => ar.indexOf(item) === i)
    const intakesWithPrices = []
    pricesDates.forEach(price => {
      const startDate = new Date(price?.prices_start)
      const endDate = new Date(price?.prices_end)
      const res = this.getSeasonsInRange(startDate, endDate, uniqueIntakes, price?.id)
      intakesWithPrices.push(...res)
    })

    // const today = new Date()
    // const validIntakes = uniqueIntakes.filter((intake) => {
    //   if (seasons.includes(intake)) {
    //     const dates = this.getDates(intake)
    //     const nDate = dates[0]
    //     return isSameOrAfter(new Date(nDate), today)
    //   }
    //   return false
    // })
    // // return validIntakes
    // const intakesWithPrices = validIntakes.filter((intake) => {
    //   const dates = this.getDates(intake)
    //   const intakeDate = dates[0]
    //   const found = pricesDates.find((dates) => {
    //     const start = new Date(dates.prices_start)
    //     const firstValidation = isSameOrAfter(new Date(intakeDate), start)
    //     const end = new Date(dates.prices_end)
    //     const secondValidation = isSameOrBefore(new Date(intakeDate), end)
    //     return firstValidation || secondValidation
    //   })
    //   return !!found
    // })
    return intakesWithPrices
  }

  isWithinRange (date, start, end) {
    return date >= start && date <= end
  }

  getSeasonsInRange (startDate, endDate, intakes, id) {
    const seasons = {
      Spring: ['-03-21', '-06-20'],
      Summer: ['-06-21', '-09-20'],
      Fall: ['-09-21', '-12-20'],
      Winter: ['-12-21', '-03-20']
    }

    const seasonsInRange = []

    const today = new Date()

    for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
      for (const season in seasons) {
        if (intakes.includes(season)) {
          let start, end
          if (season !== 'Winter') {
            start = new Date(year + seasons[season][0])
            end = new Date(year + seasons[season][1])
          } else {
            start = new Date(year + seasons[season][0])
            end = new Date((year + 1) + seasons[season][1])
          }
          if (start >= today && end >= today) {
            if (this.isWithinRange(start, startDate, endDate) && this.isWithinRange(end, startDate, endDate)) {
              seasonsInRange.push({ year, season: `${season} ${year}`, start, end, id, intake: season })
            } else {
              // console.log(season, ' is not in range')
              // TODO: In Winter Case, if the prices dates ends in ex. December 31 2024, it should be possible to take it even thougt the
              // prices ends before the intake?
            }
          }
        }
      }
    }
    return seasonsInRange
  }

  getCourseIntake () {
    const prices = this.course?.offer?.prices ?? []
    const pricesDates = prices.map((prices) => prices.year)
    const intakes = this.course.offer?.intake_date ?? []
    const uniqueIntakes = intakes.filter((item, i, ar) => ar.indexOf(item) === i)
    const today = new Date()
    const validIntakes = uniqueIntakes.filter((intake) => {
      const nDate = new Date(intake)
      return isSameOrAfter(nDate, today)
    })
    const intakesWithPrices = validIntakes.filter((intake) => {
      const intakeDate = new Date(intake)
      const found = pricesDates.find((dates) => {
        const start = new Date(dates.prices_start)
        const firstValidation = isSameOrAfter(intakeDate, start)
        const end = new Date(dates.prices_end)
        const secondValidation = isSameOrBefore(intakeDate, end)
        return firstValidation && secondValidation
      })
      return !!found
    })
    return intakesWithPrices
  }

  getCourseStartDates () {
    return this.course.offer?.start_dates ?? []
  }

  getStartCourse () {
    if (this.config.startCourse) {
      const dateIso = parseISO(this.config.startCourse)
      return formatStringDate(dateIso, 'yyyy-MM-dd')
      // return format(new Date(parseISO(this.config.startCourse)), 'yyyy-MM-dd')
    }
    return null
  }

  // getCourseIntake
  getStartString (dateFormat = 'dd-LL-yyyy') {
    if (this.config.startCourse) {
      const startDate = new Date(parseISO(this.config.startCourse))
      return format(startDate, dateFormat)
    }
    if (this.config.intake_date) {
      return this.config.intake_date
    }
    if (this.config.intake) {
      return this.config.intake
    }
    return null
  }

  getEndCourse () {
    const dates = this.getDates()
    if (!dates) {
      return null
    }
    return dates[1] ?? null
  }

  getEndString () {
    if (!this.config?.duration || !this.config?.duration_type) return null
    return this.config.duration + ' ' + this.config.duration_type
  }

  expires () {
    return this.config.expires || null
  }

  getMedicalInsuranceDates () {
    const dates = {
      start: null,
      end: null
    }
    if (this.config.medical_start) {
      dates.start = format(this.config.medical_start, 'y-m-d')
    }
    if (this.config.medical_end) {
      dates.end = format(this.config.medical_end, 'y-m-d')
    }
    return dates
  }

  // Duration labels
  courseDuration () {
    return this.course.offer.length + ' ' + this.course.offer.time
  }

  getDurationLabelN (value, frequency) {
    if (frequency === 'unic') return value + ' Single payment'
    if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
      return value + ' Weeks'
    }
    if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
      return value + ' Weeks'
    }
    if (frequency === 'year' || frequency === 'anual') {
      return value + ' Year(s)'
    }
    if (frequency === 'semester') {
      return value + ' Semesters'
    }
  }

  getDurationLabel (frequency) {
    if (frequency === 'unic') return 'Single payment'
    const courseLength = this.course.offer.length
    const courseLengthType = this.course.offer.time
    if (courseLengthType === 'years') {
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return (courseLength * 52) + ' Weeks'
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return (courseLength * 12) + ' Weeks'
      }
      if (frequency === 'year' || frequency === 'anual') {
        return courseLength + ' Year(s)'
      }
      if (frequency === 'semester') {
        return (courseLength * 2) + ' Semesters'
      }
    }
    if (courseLengthType === 'semester') {
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return (courseLength * 26) + ' Weeks'
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return (courseLength * 6) + ' Weeks'
      }
      if (frequency === 'year' || frequency === 'anual') {
        return (Math.ceil(courseLength / 2)) + ' Year(s)'
      }
      if (frequency === 'semester') {
        return courseLength + ' Semesters'
      }
    }
    if (courseLengthType === 'months') {
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return (courseLength * 4) + ' Months'
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return courseLength + ' Months'
      }
      if (frequency === 'semester') {
        return (Math.ceil(courseLength / 6)) + ' Semesters'
      }
    }
    // if (frequency === 'day') {
    // return '' + (courseDays + 1) + ' Days'
    // }
    return 'Single payment.'
  }

  getStudentNationality () {
    return {
      country: this.config?.student_nationality,
      subregion: this.config?.student_subregion,
      region: this.config?.student_region
    }
  }

  // Calculate the cost X depending on his frequency and the course lengh/frequency
  calculateCost (cost, frequency) {
    const courseLength = this.course.offer.length
    const courseLengthType = this.course.offer.time
    if (frequency === courseLengthType) return (cost * courseLength)
    if (frequency === 'unic') return cost
    if (courseLengthType === 'years' || courseLengthType === 'year') {
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return cost * (courseLength * 52) // Weeks in years
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return cost * (courseLength * 12) // MOnths in years
      }
      if (frequency === 'semester') {
        return cost * (courseLength * 2) // MOnths in years
      }
      if (frequency === 'year' || frequency === 'anual') {
        return cost * (courseLength) // MOnths in years
      }
    }
    if (courseLengthType === 'semester') {
      if (frequency === 'year' || frequency === 'years') {
        return cost * (Math.ceil(courseLength / 2))
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return cost * (courseLength * 6)
      }
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return cost * (courseLength * 26) // Weeks in semester
      }
    }
    if (courseLengthType === 'months') {
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return cost * (courseLength * 4) // Weeks in months
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return cost * (courseLength)
      }
      if (frequency === 'semester') {
        return cost * (Math.ceil(courseLength / 6))
      }
    }
    if (courseLengthType === 'days') {
      if (frequency === 'weeks' || frequency === 'weekly' || frequency === 'week') {
        return (cost / 7) * (courseLength)
      }
      if (frequency === 'months' || frequency === 'monthly' || frequency === 'month') {
        return (cost / 30) * (courseLength)
      }
      if (frequency === 'year' || frequency === 'anual') {
        return (cost / 365) * (courseLength)
      }
      if (frequency === 'day') {
        return cost * courseLength
      }
    }
    return cost
  }

  // Course Costs
  courseCosts () {
    return this.getPrices()?.costs || []
  }

  isCostChecked (id) {
    const found = this.config?.courseCosts?.find((el) => (el.id === id))
    return found
  }

  courseCost (id) {
    const found = this.config?.courseCosts?.find((el) => (el.id === id))
    if (!found) return 0
    return this.calculateCost(found.cost, found.frequency)
  }

  courseCostDiscounts (id) {
    const isChecked = this.config?.courseCosts?.find((el) => (el.id === id))
    if (!isChecked) return []
    const found = this.getPrices()?.costs.find((el) => (el.id === id))
    if (!found) return []
    return this.getValidDiscounts(found.discounts)
  }

  courseCostDiscountAmount (id, discountId = null) {
    const discounts = this.courseCostDiscounts(id)
    if (discountId) {
      const discountFoud = discounts.find((discount) => discount.id === discountId)
      return calculateTotalDiscount([discountFoud], this.courseCost(id))
    }
    return calculateTotalDiscount(discounts, this.courseCost(id))
  }

  courseCostSubtotal (id) {
    return this.courseCost(id) - this.courseCostDiscountAmount(id)
  }

  getCourseAmount () {
    if (!this.config?.courseCosts) return 0
    let total = 0
    this.config?.courseCosts.forEach((item) => {
      total += this.calculateCost(item.cost, item.frequency)
    })
    return total
  }

  getCourseAmounts () {
    if (!this.config?.courseCosts) return []
    const courseCosts = []
    this.config?.courseCosts.forEach((item) => {
      const discounts = this.courseCostDiscounts(item.id) || []
      const data = discounts.map((disc) => {
        return {
          id: disc.id,
          amount: this.courseCostDiscountAmount(item.id, disc.id).toFixed(2)
        }
      })
      courseCosts.push({
        id: item.id,
        duration: this.getDurationLabel(item.frequency),
        cost: this.calculateCost(item.cost, item.frequency),
        discounts: data
      })
    })
    return courseCosts
  }

  getCourseSubtotal () {
    if (!this.config?.courseCosts) return 0
    const costTotal = this.getCourseAmount()
    let total = 0
    this.config?.courseCosts.forEach((item) => {
      total += this.courseCostDiscountAmount(item.id)
    })
    return costTotal - total
  }

  getCourseDiscountAmount () {
    let total = 0
    this.config?.courseCosts?.forEach((item) => {
      total += this.courseCostDiscountAmount(item.id)
    })
    return total
  }

  subTotalCourse () {
    const st = (
      this.getCourseAmount() +
      this.getAmountInscription() +
      // this.getHighSeasonFeesAmount() +
      this.getAmountMaterials()
    ) ?? 0
    if (isNaN(st)) return 0
    return st ?? 0
  }

  getCourseDiscounts () {
    return []
  }

  courseHasDiscount () {
    const discounts = this.getCourseDiscounts()
    return discounts.length > 0
  }

  getDiscountsAppliedInCourse () {
    const values = []
    const costs = this.config.courseCosts || []
    costs.forEach(element => {
      const discounts = this.courseCostDiscounts(element.id) || []
      const data = discounts.map((item) => {
        return {
          id: item.id,
          amount: this.courseCostDiscountAmount(element.id, item.id).toFixed(2), 
          costId: element.id
        }
      })
      values.push(...data)
    })
    return values
  }

  isMinorCostChecked (id) {
    const found = this.config?.minorCosts?.find((el) => el.id === id)
    return found
  }

  // Agency Services

  getQuoteAgencyServices () {
    return this.config?.agencyServices || []
  }

  /**
   * ------------------------------------ High Seasons ------------------------------------
   */
  getHighSeasons () {
    return this.getPrices()?.high_seasons || []
  }

  /**
   * Tuition (Registation Fee)
   */
  getTuitionFee () {
    const found = this.getPrices()?.base_prices?.find((el) => (el.name === 'Registration Fee'))
    return found
  }

  getTuitionFeeDiscounts () {
    const fee = this.getTuitionFee()
    if (!fee) return []
    const applicableDiscounts = this.getValidDiscounts(fee.discounts)
    return applicableDiscounts ?? []
  }

  getTuitionDiscountsValues () {
    const values = this.getTuitionFeeDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getAmountInscription())?.toFixed(2)
      })
    )
    return values
  }

  getTuitionFeeDiscountsAmount () {
    const fee = this.getTuitionFee()
    if (!fee) return 0
    const discounts = this.getTuitionFeeDiscounts() || []
    const totalDiscount = calculateTotalDiscount(discounts, this.getAmountInscription())
    return totalDiscount
  }

  getAmountInscription () {
    const found = this.getTuitionFee()
    return this.calculateCost(found?.cost ?? 0, found?.frequency) ?? 0
  }

  /**
   * Materials
   */
  getSchoolMaterial () {
    if (this.getPrices()?.materials.length >= 1) {
      return this.getPrices()?.materials
    }
    return null
  }

  getSelectedMaterial () {
    if (!this.config?.materials) return null
    const found = this.getPrices()?.materials?.find((el) => el.id === this.config?.material)
    return found
  }

  getMaterialDiscounts () {
    const material = this.getSelectedMaterial()
    if (!material) return []
    const applicableDiscounts = this.getValidDiscounts(material.discounts)
    return applicableDiscounts ?? []
  }

  getMaterialDiscountValues () {
    const material = this.getSelectedMaterial()
    if (!material) return []
    const values = this.getMaterialDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getAmountMaterials())?.toFixed(2)
      })
    )
    return values
  }

  getMaterialDiscountsAmount () {
    const values = this.getMaterialDiscountValues() ?? []
    let total = 0
    values.forEach(element => {
      total += Number(element.amount)
    })
    return total
  }

  getAmountMaterials () {
    if (!this.config?.materials || !this.config?.material) return 0
    const found = this.getPrices()?.materials?.find((el) => el.id === this.config?.material)
    if (!found) return 0
    const cost = this.calculateCost(found.cost ?? 0, found.frequency) ?? found.cost
    if (found.cost_max && cost > found.cost_max) return found.cost_max ?? 0
    if (!cost || isNaN(cost)) return 0
    return cost
  }

  getAmountMedical () {
    if (!this.config?.medical) return 0
    const found = this.getPrices()?.medical_insurance?.find((el) => el.id === this.config?.medicalId)
    let cost = 0
    let days = 0
    const start = this.config.medical_start
    const end = this.config.medical_end
    if (start && end) {
      days = differenceInDays(end, start)
      // console.log('🚀 ~ days', days, '🚀 ~ insurance', found)
    }
    found?.costs?.forEach(medicalCost => {
      cost += calculateCostByDays(medicalCost.cost, medicalCost.frequency, days)
    })

    return cost
  }

  getAmountService (id) {
    if (!this.config?.serviceAgency) return 0
    const found = this.getPrices()?.service_agency?.find((el) => el.id === id)
    let cost = 0

    found?.costs?.forEach(serviceCost => {
      cost += this.calculateCost(serviceCost.cost, serviceCost.frequency)
    })

    return cost
  }

  getTotalMaterials () {
    return this.getAmountMaterials() - this.getMaterialDiscountsAmount()
  }

  /**
   * Lodging
   */
  getArrayLodging () {
    return this.getPrices()?.lodgings ?? []
  }

  getMedicalInsurance () {
    return this.getPrices()?.medical_insurance ?? []
  }

  getServiceAgency () {
    return this.getPrices()?.service_agency ?? []
  }

  getIncluded () {
    return this.getPrices()?.includes || []
  }

  hasLodging () {
    // return !!this.getPrices()?.lodgings
    const lodgings = this.getPrices()?.lodgings || []
    return lodgings.length > 0
  }

  includeLodging () {
    return this.config.lodging
  }

  includeMedical () {
    return this.config.medical
  }

  includeService () {
    return this.config.serviceAgency
  }

  getSelectedLodging () {
    if (!this.config?.lodging) {
      return 0
    }
    const lodgingCollection = this.getArrayLodging()
    const foundCurrentLodging = lodgingCollection.find((lo) => {
      return lo.id === this.config?.lodgingId
    })

    return foundCurrentLodging
  }

  getSelectedMedical () {
    if (!this.config?.medical) {
      return 0
    }
    const medicalCollection = this.getMedicalInsurance()
    const foundCurrentMedical = medicalCollection.find((lo) => {
      return lo.id === this.config?.medicalId
    })

    return foundCurrentMedical
  }

  getSelectedServices () {
    const additionalsServices = this.getArrayOfServices(this.config?.serviceId)
    const divisasAdditional = {}

    additionalsServices.forEach(cost => {
      if (divisasAdditional[cost.currency] === undefined) divisasAdditional[cost.currency] = []
      divisasAdditional[cost.currency].push(cost)
    })

    return divisasAdditional
  }

  getAmountAdditionalService () {
    if (!this.config?.serviceAgency) {
      return 0
    }
    let amountAdditionalService = 0
    let currency = ''

    this.config?.serviceId?.forEach(service => {
      amountAdditionalService += this.getAmountService(service.service_agency)
      currency = this.getServiceAgencyCurrency(service.service_agency)
    })

    return {
      total: amountAdditionalService,
      currency
    }
  }

  getLodgingSelectedExtraCosts () {
    // return this.config.lodgingExtras || []
    const extras = this.config.lodgingExtras || []
    return extras?.map((extra) => {
      const discounts = this.getLodgingExtraCostsDiscounts(extra.id)?.map((disc) => ({
        id: disc.id,
        amout: calculateTotalDiscount([disc], this.calculateLodgingExtraCosts(extra.id))
      }))
      return {
        ...extra,
        duration: this.getDurationLabel(extra.frequency),
        discounts
      }
    })
  }

  getLodgingWeeks () {
    return this.config.lodgingWeeks ?? 0
  }

  getDayStartLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging?.arrival_day) return null
    return dayNumber[foundCurrentLodging?.arrival_day]
  }

  getDayEndLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging?.departure_day) return null
    return dayNumber[foundCurrentLodging?.departure_day]
  }

  getLodgingEnd () {
    if (!this.config?.lodgingStart || !this.config?.lodgingWeeks) {
      return null
    }
    const day = this.getDayStartLodging()
    const endDay = this.getDayEndLodging()
    let daysToEnd = endDay - day
    if (daysToEnd <= 1) daysToEnd = daysToEnd + 6
    const endOfFirstWeek = addDays(new Date(this.config?.lodgingStart), daysToEnd)
    return addWeeks(endOfFirstWeek, (this.config?.lodgingWeeks - 1))
  }

  getLodgingEndString () {
    if (!this.getLodgingEnd()) return null
    return format(this.getLodgingEnd(), 'dd LLLL Y')
  }

  getLodgingAcommodationFee () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) {
      return 0
    }
    return foundCurrentLodging.accomodation_fee ?? 0
  }

  getAcommodationFeeDiscounts () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return []
    const discounts = foundCurrentLodging.accomodation_fee_discounts ?? []
    return this.getValidDiscounts(discounts)
  }

  getAcommodationFeeDiscountsAmount () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return []
    const discounts = this.getAcommodationFeeDiscounts()
    return calculateTotalDiscount(discounts, foundCurrentLodging.accomodation_fee ?? 0)
  }

  getAcommodationFeeDiscountsValues () {
    const cost = this.getSelectedLodging()?.accomodation_fee ?? 0
    const values = this.getAcommodationFeeDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], cost)?.toFixed(2)
      })
    )
    return values
  }

  AcommodationFeeTotal () {
    const cost = this.getLodgingAcommodationFee()
    if (!cost) return 0
    return cost - this.getAcommodationFeeDiscountsAmount()
  }

  getAmountLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return 0
    if (this.config.lodgingWeeks && this.config.lodgingStart) {
      return CalculateLodgingCost(
        foundCurrentLodging,
        this.config.lodgingStart,
        this.getLodgingWeeks(),
        []
        // this.getHighSeasons()
      )
    }
    if (this.getCourseProgram().name === 'graduate') return 0
    return this.calculateCost(foundCurrentLodging?.cost, foundCurrentLodging?.frequency) ?? 0
  }

  includeExtraNight () {
    const lodgingId = this.config.lodgingId
    const hasExtraNightCost = this.getPrices()?.lodgings.find(lodging => lodging.id === lodgingId)
    return this.config.lodgingId && Number(hasExtraNightCost.extra_cost) > 0
  }

  getLodgingExtraCosts () {
    const lodging = this.getSelectedLodging()
    if (!lodging) return []
    return lodging.extra_costs || []
  }

  getLodgingExtraCostsDiscounts (id) {
    const lodging = this.getSelectedLodging()
    if (!lodging) return []
    const selectedExtras = this.config.lodgingExtras || []
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return []
    const Accfee = lodging.extra_costs?.find((extra) => extra.name === 'Accommodation Fee')
    if (Accfee?.id === id) {
      return this.getLodgingDiscounts()
    }
    return []
  }

  getLodgingExtraCostsAmount () {
    let total = 0
    const selectedExtras = this.config.lodgingExtras || []
    selectedExtras.forEach(extra => {
      const cost = this.calculateLodgingExtraSubtotal(extra.id)
      extra.total = cost
      extra.subtotal = this.calculateLodgingExtraCosts(extra.id)
      total += cost
    })
    return total
  }

  getLodgingDiscounts () {
    const lodging = this.getSelectedLodging()
    if (!lodging) return []
    const applicableDiscounts = this.getValidDiscounts(lodging.discounts)
    return applicableDiscounts
  }

  getLodgingDiscountsValues () {
    const cost = this.getAmountLodging()
    if (cost <= 0) return []
    const values = this.getLodgingDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getAmountLodging())?.toFixed(2)
      })
    )
    return values
  }

  getLodgingDiscountsAmount () {
    const lodging = this.getSelectedLodging()
    if (!lodging) return 0
    const lodgingCost = this.getAmountLodging()
    const totalDiscount = calculateTotalDiscount(this.getLodgingDiscounts(), lodgingCost)
    return lodgingCost > 0 ? totalDiscount : 0
  }

  getLodgingHighSeasons () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) {
      return []
    }
    return foundCurrentLodging.high_seasons
  }

  getLodgingIsHighSeason () {
    const isPS = isHighSeason(
      this.getLodgingWeeks(),
      this.config?.lodgingStart ?? null,
      this.getLodgingHighSeasons() ?? []
    )
    // console.log('🚀 ~ file: HighEducationQuoterService.js:800 ~ HighEducationQuoterService ~ getLodgingIsHighSeason ~ isPS', isPS)
    return isPS
  }

  getLodgingAmountHighSeason () {
    const isTrue = this.getLodgingIsHighSeason()
    if (!isTrue) return 0

    const result = calculateAccomodationHGS(
      this.config?.lodgingStart ?? null,
      this.getLodgingEnd(),
      this.getLodgingHighSeasons(),
      this.getDayEndLodging()
    )
    const total = result?.standard_cost
    return total
  }

  getLodgingHighSeasonValues () {
    const isTrue = this.getLodgingIsHighSeason()
    if (!isTrue) {
      return []
    }

    const result = calculateAccomodationHGS(
      this.config?.lodgingStart ?? null,
      this.getLodgingEnd(),
      this.getLodgingHighSeasons(),
      this.getDayEndLodging()
    )
    // console.log('🚀 ~ file: HighEducationQuoterService.js:838 ~ HighEducationQuoterService ~ getLodgingHighSeasonValues ~ result', result)
    return result
  }

  getHighSeasonAmountLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    const values = this.getLodgingHighSeasonValues()
    if (values?.details) {
      // let weeks = 0
      // let hsCost = 0
      // let daysOff = 0
      // values.details?.forEach((hs) => {
      //   weeks += hs.weeks
      //   hsCost = hs.costs.standard_cost
      //   daysOff += hs.daysOff
      // })
      // const lowSeasonWeeks = this.getLodgingWeeks() - weeks
      // return (lowSeasonWeeks * foundCurrentLodging.cost) + (weeks * hsCost) + (daysOff * (hsCost / 7))
    }
    if (this.getLodgingWeeks()) {
      return calculateCost(foundCurrentLodging?.cost, foundCurrentLodging?.frequency, this.getLodgingWeeks())
    }
    return this.calculateCost(foundCurrentLodging?.cost, foundCurrentLodging?.frequency)
  }

  // TO CHECK
  getAmountsLodging () {
    const foundCurrentLodging = this.getSelectedLodging()
    const values = this.getLodgingHighSeasonValues()

    let weeks = 0
    let hsCost = 0
    let daysOff = 0
    values.details?.forEach((hs) => {
      weeks += hs.weeks
      // daysOff += hs.daysOff
      daysOff += 0
      hsCost = hs.costs.standard_cost
    })
    const lowSeasonWeeks = this.getLodgingWeeks() - weeks
    const data = {
      ls_cost: lowSeasonWeeks * foundCurrentLodging?.cost,
      ls_weeks: lowSeasonWeeks,
      hs_cost: (weeks * hsCost) + (daysOff * (hsCost / 7)),
      hs_costExt: daysOff * (hsCost / 7),
      hs_weeks: weeks
    }
    return data
  }

  getLodgingExtraAmountHighSeason () {
    const isTrue = this.getLodgingIsHighSeason()
    const lodgingExtraStart = this.getLodgingEnd()
    if (!isTrue) {
      return 0
    }
    if (!lodgingExtraStart || !this.config?.lodgingExtraNights) {
      return 0
    }
    const start = lodgingExtraStart
    const duration = this.config.lodgingExtraNights
    const end = addDays(new Date(start), duration)
    const result = calculateHighSeasonCosts(
      lodgingExtraStart ?? null,
      end,
      this.getLodgingHighSeasons(),
      'lodging'
    )
    let t = 0
    const hsList = result?.details ?? []
    hsList.forEach(hs => {
      t += hs.costs.extra_cost * hs.days
    })
    return t
  }

  getLodgingExEnd () {
    if (!this.config?.lodgingExtraNights) {
      return null
    }
    const start = this.getLodgingEnd()
    const duration = this.config.lodgingExtraNights
    return addDays(new Date(start), duration)
  }

  getLodgingExEndFormated () {
    const end = this.getLodgingExEnd()
    if (!end) return null
    return format(end, 'dd-LL-Y')
  }

  getLodgingExEndString () {
    const end = this.getLodgingExEnd()
    if (!end) return null
    return format(end, 'dd LLLL Y')
  }

  getAmountLodgingExtNight () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging || !this.config.lodgingExtraNights || !this.config.extra_nigth || !this.getLodgingEnd()) {
      return 0
    }
    return CalculateLodgingExtraNightCost(
      foundCurrentLodging,
      this.getLodgingEnd(),
      this.config.lodgingExtraNights,
      this.getHighSeasons()
    )
  }

  // Check if the extra nights are in high season
  getLodginExgIsHighSeason () {
    const startExtraNights = this.getLodgingEnd()
    const endExtraNights = addDays(startExtraNights, this.config.lodgingExtraNights)
    const values = calculateHighSeasonCosts(startExtraNights, endExtraNights, this.getLodgingHighSeasons() ?? [], 'lodging')
    return values ?? []
  }

  getLodgingExAmounts () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) {
      return {
        ls_cost: 0, hs_cost: 0, ls_days: 0, hs_days: 0
      }
    }
    const values = this.getLodginExgIsHighSeason()
    let days = 0
    let hsCost = 0
    values.details?.forEach((hs) => {
      days += hs.days
      hsCost = hs.costs.extra_cost
    })
    const lowSeasonDays = this.config.lodgingExtraNights - days
    return {
      ls_cost: (lowSeasonDays * foundCurrentLodging.extra_cost),
      hs_cost: (days * hsCost),
      ls_days: lowSeasonDays,
      hs_days: days
    }
  }

  getLodgingExHighSeasonAmount () {
    const values = this.getLodgingExAmounts()
    return values.ls_cost + values.hs_cost
  }

  isLodgingExtraSelected (id) {
    const found = this.config.lodgingExtras?.find((item) => (item.id === id))
    return !!found
  }

  isLodgingPSFeeSelected (id) {
    const found = this.config.PeakSeasonFees?.find((item) => (item.id === id))
    return !!found
  }

  LodgingPSFeesSelected () {
    const foundCurrentLodging = this.getSelectedLodging()
    if (!foundCurrentLodging) return []
    const highSesons = this.getLodgingHighSeasonValues()?.details
    const values = this.config.PeakSeasonFees?.map((fee) => {
      const hs = highSesons.find((item) => item.id === fee.hsId)
      return {
        id: fee.id,
        // cost: fee.cost * hs.weeks
        cost: calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff)
      }
    })
    return values
  }

  calculateLodgingExtraCosts (id) {
    const selectedExtras = this.config.lodgingExtras || []
    const extras = this.getLodgingExtraCosts()
    if (selectedExtras.length < 1 || extras.length < 1) return 0
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return 0
    const weeks = this.getLodgingWeeks()
    if (weeks && this.getCourseProgram() !== 'academic_years') {
      return calculateCost(found.cost, found.frequency, weeks)
    }
    // return 0
    return this.calculateCost(found.cost, found.frequency)
  }

  calculateLodgingExtraSubtotal (id) {
    const selectedExtras = this.config.lodgingExtras || []
    const found = selectedExtras.find((extra) => extra.id === id)
    if (!found) return 0
    const cost = this.calculateLodgingExtraCosts(id)
    const discounts = this.getLodgingExtraCostsDiscounts(id)
    const discountAmout = calculateTotalDiscount(discounts, cost)
    return (cost - discountAmout)
  }

  /**
   * Transport
   */
  hasTransports () {
    const transports = this.getPrices()?.transports || []
    return transports.length > 0
  }

  includeTransport () {
    return this.config?.transport
  }

  getTransport () {
    const transportCollection = this.getArrayTransport()
    const foundTransport = transportCollection.find((t) => {
      return t.id === this.config?.transportId
    })
    return foundTransport
  }

  getArrayTransport () {
    return this.getPrices()?.transports ?? []
  }

  getAmountTransport () {
    if (!this.includeTransport()) return 0
    const ways = this.config.transportWaySelected
    const foundTransport = this.getTransport()
    if (!foundTransport || !ways) return 0
    let cost = 0
    ways === 'One Way' ? cost = foundTransport.one_way_cost : cost = foundTransport.two_ways_cost
    return cost
  }

  getWaysTransport () {
    const transport = this.getTransport()
    const ways = []
    const oneWayCost = transport?.one_way_cost ?? null
    const twoWaysCost = transport?.two_ways_cost ?? null
    if (oneWayCost >= 0) ways.push('One Way')
    if (twoWaysCost >= 0) ways.push('Round Way')
    return ways
  }

  getTransportDiscounts () {
    const trasport = this.getTransport()
    if (!this.config.transport || !trasport) return []
    return this.getValidDiscounts(trasport.discounts)
  }

  getTransportDiscountsAmount () {
    const trasport = this.getTransport()
    if (!trasport || this.getAmountTransport() <= 0) return 0
    const totalDiscount = calculateTotalDiscount(this.getTransportDiscounts(), this.getAmountTransport())
    return totalDiscount
  }

  getTransportDiscountsValues () {
    const values = this.getTransportDiscounts().map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getAmountTransport())?.toFixed(2)
      })
    )
    return values
  }

  getSubtotalTrasnport () {
    let total = 0
    total += (this.getAmountTransport() + this.getTransportAmountHighSeason()) - this.getTransportDiscountsAmount()

    if (isNaN(total)) return 0

    return total
  }

  getTransportIsHighSeason () {
    return {
      end: false,
      start: false,
      end_cost: 0,
      start_cost: 0
    }
  }

  getTransportAmountHighSeason () {
    const isHighSeason = this.getTransportIsHighSeason()
    if (isHighSeason.end && isHighSeason.start) {
      return (isHighSeason.end_cost + isHighSeason.start_cost)
    }
    if (isHighSeason.start) {
      return isHighSeason.start_cost
    }
    if (isHighSeason.end) {
      return isHighSeason.end_cost
    }
    return 0
  }

  getTransportMinorCosts () {
    const transport = this.getTransport()
    if (!transport) return []
    return transport.minor_costs
  }

  getTransportTotal () {
    let total = 0
    total += this.getSubtotalTrasnport()

    this.transportMinorCostValues()?.forEach((item) => {
      if (this.isTransportMinorCostChecked(item.id)) {
        total += item.cost
      }
    })

    return total
  }

  isTransportMinorCostChecked (id) {
    const found = this.config?.transportMinorCosts?.find((el) => el.id === id)
    return found
  }

  transportMinorCostValues () {
    const values = []
    this.getTransportMinorCosts()?.forEach((item) => {
      if (this.isTransportMinorCostChecked(item.id)) {
        values.push({
          id: item.id,
          cost: this.config.transportWaySelected === 'One Way' ? item.cost : (item.cost * 2),
          duration: this.getDurationLabel(item.frequency)
        })
      }
    })
    return values
  }

  /**
   * Insurance
   */
  getArrayHealtInsurance () {
    return this.getPrices()?.insurances ?? []
  }

  includeInsurance () {
    return this.config?.insurance
  }

  getInsuranceSelected () {
    if (!this.config?.healthInsuranceId) return null
    const insurance =
    this.getPrices()?.insurances?.find(
      (el) => el.id === this.config?.healthInsuranceId
    )
    return insurance
  }

  getInsuranceDiscounts () {
    const insurance = this.getInsuranceSelected()
    if (!insurance || !this.includeInsurance()) return []
    return this.getValidDiscounts(insurance.discounts)
  }

  getAmountHealthInsurance () {
    if (!this.config?.healthInsuranceId) return 0
    if (!this.config?.insurance) return 0

    const costHealthInsurance = this.getInsuranceSelected()
    if (!costHealthInsurance) return 0
    return this.calculateCost(costHealthInsurance?.cost, costHealthInsurance?.frequency) ?? costHealthInsurance?.cost
  }

  getInsuranceDiscountAmount () {
    const cost = this.getAmountHealthInsurance()
    const discounts = this.getInsuranceDiscounts()
    return calculateTotalDiscount(discounts, cost)
  }

  getInsuranceDiscountValues () {
    const insurance = this.getInsuranceSelected()
    if (!insurance) return []
    const cost = this.getAmountHealthInsurance()
    const values = this.getInsuranceDiscounts()?.map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], cost).toFixed(2)
      })
    )
    return values
  }

  getSubtotalInsurance () {
    return this.getAmountHealthInsurance() - this.getInsuranceDiscountAmount()
  }

  // FEES
  getFees () {
    const bases = this.getPrices()?.base_prices || []
    const extras = this.getPrices()?.extras || []
    const allFees = [...bases, ...extras]
    return allFees
  }

  getFeeCost (id) {
    const fee = this.config.fees?.find((i) => i.id === id)
    if (!fee) return 0
    return this.calculateCost(fee.cost, fee.frequency) ?? fee.cost
  }

  getFeeDiscounts (id) {
    const isSelected = this.config.fees?.find((i) => i.id === id)
    if (!id || id === undefined || !isSelected) return []
    const allFees = [...this.getPrices()?.base_prices, ...this.getPrices()?.extras]
    const fee = allFees?.find((i) => i.id === id)
    if (!fee) return []
    return this.getValidDiscounts(fee.discounts)
  }

  getFeeDiscontAmount (id) {
    const discounts = this.getFeeDiscounts(id)
    const cost = this.getFeeCost(id)
    return calculateTotalDiscount(discounts, cost)
  }

  getFeeSubtotal (id) {
    return this.getFeeCost(id) - this.getFeeDiscontAmount(id)
  }

  getHighSeasonFeesAmount () {
    // const data = this.getHighSeasonFeesCosts()
    // let total = 0
    // data.forEach(hs => {
    //   total += hs.total ?? 0
    // })
    // return total
    return 0
  }

  isChecked (id) {
    const found = this.config?.fees?.find((el) => el.id === id)
    return found
  }

  // AGENCY
  getAgencyMiscellaneous () {
    return this.course?.agency_services ?? []
  }

  serviceIsChecked (id) {
    const found = this.config?.agencyServices?.find((el) => (el.id === id))
    return found
  }

  getAgencyServiceCost (id) {
    const found = this.course.agency_services.find((service) => service.id === id)
    const costs = found.costs
    const cost = costs[0] ?? null
    return cost?.cost ?? 0
  }

  getAgencyServiceSelectedCost (id) {
    const mis = this.config?.agencyServices?.find((i) => i.id === id)
    return mis?.cost ?? 0
  }

  getAgencyServiceCurrency (id) {
    const found = this.course.agency_services.find((service) => service.id === id)
    return found?.divisa?.code
  }

  getExchangeValue (currency) {
    if (!this.exchanges) return 1
    if (currency === this.getAgencyCurrency()) return 1
    const found = this.exchanges.find((value) => value?.code === currency)
    return found?.exchange_value
  }

  calculateExchangeValue (cost, exchangeVal) {
    if (!cost || !exchangeVal) console.log('Somenting is wrong ', cost, exchangeVal)
    return +(cost * exchangeVal)?.toFixed(2) ?? 'exchange value not found'
  }

  getAgencyCurrency () {
    return this.course.agency_currency?.code
  }

  getAgencyMedicalCurrency () {
    const medical = this.getSelectedMedical()
    return medical?.currency?.code
  }

  getServiceAgencyCurrency (id) {
    const service = this.getPrices()?.service_agency?.find((el) => el.id === id)
    return service?.currency?.code
  }

  // Totals
  subTotalLodging () {
    let lodgingCost = this.getAmountLodging()
    if (this.getLodgingHighSeasonValues().weeks > 0) {
      lodgingCost = this.getHighSeasonAmountLodging()
      this.LodgingPSFeesSelected()?.forEach((item) => {
        lodgingCost += item.cost
      })
    }
    return (
      this.getAmountLodgingExtNight() +
      lodgingCost +
      // this.getLodgingExtraAmountHighSeason() +
      this.getLodgingExtraCostsAmount() +
      this.AcommodationFeeTotal()
    ) ?? 0
  }

  subTotalAgencyCost () {
    return (this.getAmountActivities() ?? 0) +
      (this.getAmountServiceQuote() ?? 0)
  }

  subTotalCourseExtra () {
    let feesCost = 0
    if (this.config?.fees) {
      this.config?.fees.map((fee) => (
        feesCost = feesCost + this.getFeeSubtotal(fee.id)
      ))
    }
    return feesCost
  }

  getAmountActivities () {
    const foundCostSeason = this.course?.agency_services?.activities?.map((el) => {
      if (this.config?.activitiesId?.find((activityId) => activityId === el.id)) {
        return calculateServiceCost(
          el.costs ?? [],
          this.getTotalWeeks(),
          this.getCourseStart(),
          this.getHighSeason()
        )?.total
      }
      return 0
    })
    return sumArrayCost(foundCostSeason)
  }

  // getFeeDiscontAmount (id) {
  //   const discounts = this.getFeeDiscounts(id)
  //   const cost = this.getFeeCost(id)
  //   return calculateTotalDiscount(discounts, cost)
  // }

  // getFeeSubtotal (id) {
  //   return this.getFeeCost(id) - this.getFeeDiscontAmount(id)
  // }

  getAmountServiceQuote () {
    if (!this.config['service-quote']) {
      return 0
    }

    const costServiceQuote =
      this.course?.agency_services?.agency_miscellaneous?.map((el) => {
        if (el.name === 'service-quote') {
          return calculateServiceCost(
            el.costs ?? [],
            this.getTotalWeeks(),
            this.getCourseStart(),
            this.getHighSeason()
          )?.total
        }
        return 0
      })

    return sumArrayCost(costServiceQuote)
  }

  getAmountVisa () {
    return 0
  }

  getTotalDiscounts () {
    return (
      this.getTuitionFeeDiscountsAmount() +
      this.getCourseDiscountAmount() +
      this.getLodgingDiscountsAmount() +
      this.getInsuranceDiscountAmount() +
      Number(this.getMaterialDiscountsAmount()) +
      this.getTransportDiscountsAmount()
    )
  }

  getSubTotalCampusAmount () {
    let cost = 0
    cost += this.subTotalCourse() + this.subTotalLodging() + this.subTotalAgencyCost()
    cost += this.subTotalCourseExtra() + this.getAmountHealthInsurance()
    cost += (this.getAmountTransport() + this.getTransportAmountHighSeason())
    if (isNaN(cost)) {
      return 0
    }
    return cost ?? 0
  }

  getCampusDiscountAmount () {
    const totalDiscounts = (
      this.getCourseDiscountAmount() +
      this.getMaterialDiscountsAmount() +
      this.getTuitionFeeDiscountsAmount() +
      this.getLodgingDiscountsAmount() +
      this.getTransportDiscountsAmount() +
      this.getInsuranceDiscountAmount()
      // this.getExtraDiscountsAmount(),
      // this.getFeesDiscountAmount()
    ) ?? 0
    // console.log('totalDiscounts', totalDiscounts)
    return totalDiscounts
  }

  getFeeDiscountValues (id) {
    const values = this.getFeeDiscounts(id).map(
      (discount) => ({
        id: discount.id,
        amount: calculateTotalDiscount([discount], this.getFeeCost(id))?.toFixed(2)
      })
    )
    return values
  }

  getFeesDiscountValues () {
    const values = this.config.fees?.map((i) => ({
      id: i.id,
      cost: this.getFeeCost(i.id),
      discounts: this.getFeeDiscountValues(i.id),
      duration: this.getDurationLabel(i.frequency)
    }))
    return values
  }

  getTotalCampusAmount () {
    const cost = this.getSubTotalCampusAmount() - Number(this.getCampusDiscountAmount())
    if (isNaN(cost)) {
      return this.getSubTotalCampusAmount()
    }
    return cost ?? 0
  }

  getTotalsAmount () {
    const costs = {
      campus: this.getTotalCampusAmount(),
      campus_subtotal: this.getSubTotalCampusAmount(),
      campus_currency: this.getCurrency(),

      agency: this.calculateAgencyMiscellaneousTotal(),
      agency_subtotal: this.calculateAgencyMiscellaneousTotal(),
      agency_currency: this.getAgencyCurrency(),

      medical: this.getAmountMedical(),
      medical_subtotal: this.getAmountMedical(),
      medical_currency: this.getAgencyMedicalCurrency()
    }
    if (this.getAgencyCurrency() === this.getCurrency()) {
      costs.total = costs.campus + costs.agency
    } else {
      // When converting to campus currency is a divition operation
      const agencyCosts = (costs.agency / this.getExchangeValue(costs.campus_currency)) ?? 0
      costs.total_in_campus_currency = isNaN(agencyCosts) ? costs.campus : (costs.campus + agencyCosts)
      costs.subtotaltotal_in_campus_currency = isNaN(agencyCosts) ? costs.campus_subtotal : (costs.campus_subtotal + agencyCosts)
      // When converting to agency currency is a product operation
      if (this.getAgencyCurrency()) {
        const campusCosts = (costs.campus * this.getExchangeValue(costs.campus_currency)) ?? 0
        costs.total_in_agency_currency = campusCosts + costs.agency
      }
    }
    return costs
  }

  // Return the agency services amount in agency currency
  calculateAgencyMiscellaneousTotal () {
    let total = 0
    this.config?.agencyServices?.map((misc) => {
      let miscTotal = 0
      misc.currency = this.getAgencyServiceCurrency(misc.id)
      if (misc.currency === this.getAgencyCurrency()) {
        miscTotal = misc.cost ?? 0
      } else {
        miscTotal = this.calculateExchangeValue(misc.cost, this.getExchangeValue(misc.currency))
      }
      misc.total = miscTotal
      total += miscTotal
      return total
    })
    return total
  }

  getSubTotalQuoteAmount () {
    let cost = 0
    cost += (
      this.subTotalCourse() +
      this.subTotalLodging() +
      this.subTotalAgencyCost()
    )
    cost += (
      this.subTotalCourseExtra() +
      this.getAmountHealthInsurance() +
      this.getSubtotalTrasnport()
    )
    if (this.getCurrency() === this.getAgencyCurrency()) {
      cost += this.calculateAgencyMiscellaneousTotal()
    }
    // Add Transpot minor costs
    this.config.transportMinorCosts?.forEach((item) => {
      const addCost = this.config.transportWaySelected === 'One Way' ? item.cost : (item.cost * 2)
      cost += addCost
    })
    if (isNaN(cost)) {
      return 0
    }
    return cost
  }

  getAllGoods () {
    if (!this.config?.courseStart) return []
    const allGoods = []
    // Course Goods
    const goods = this.getPrices()?.goods ?? []
    allGoods.push(...goods)
    // Base prices Goods
    const basePrices = this.getPrices()?.base_prices ?? []
    const basePricesChecked = this.config.fees ?? []
    const allChecked = [...basePricesChecked, { id: this.getTuitionFee()?.id }]
    basePrices?.forEach(element => {
      const bGoodss = element.goods ?? []
      const found = allChecked.find((item) => item?.id === element.id)
      if (found) {
        allGoods.push(...bGoodss)
      }
    })
    return allGoods
  }

  // Discounts in Kinds
  getValidGoods () {
    const goods = this.getAllGoods() ?? []
    let validGoods = goods.filter((good) => {
      const found = good.discount.intake?.find((intake) => intake === this.config.intake)
      return !!found
    })
    // Goods from the costs
    const courseCosts = this.courseCosts()
    const activeCosts = this.config.courseCosts
    activeCosts?.map((cost) => {
      const found = courseCosts.find((courseCost) => courseCost.id === cost.id)
      validGoods.push(...found?.goods ?? [])
      return found?.goods ?? []
    })
    if (this.config.student_nationality) {
      validGoods = validGoods?.filter(good => !good.discount?.exceptions?.includes(this.config?.student_nationality))
      this.validAppliedGoods()
    }
    return validGoods
  }

  validAppliedGoods () {
    const goods = this.getPrices()?.goods ?? []
    let validGoods = goods.filter((good) => {
      const found = good.discount.intake?.find((intake) => intake === this.config.intake)
      return !!found
    })
    // Goods from the costs
    const courseCosts = this.courseCosts()
    const activeCosts = this.config.courseCosts
    activeCosts?.map((cost) => {
      const found = courseCosts.find((courseCost) => courseCost.id === cost.id)
      validGoods.push(...found?.goods ?? [])
      return found?.goods ?? []
    })
    if (this.config?.student_nationality) {
      validGoods = validGoods?.filter(good => good?.discount?.exceptions?.includes(this.config?.student_nationality))
      validGoods = validGoods?.map(good => good.id)
      if (this.config.appliedGoods?.length > 0) {
        this.config.appliedGoods = this.config?.appliedGoods?.filter(good => !validGoods?.includes(good))
      }
    }
  }

  getGoodsIds () {
    return this.config.appliedGoods ?? []
  }

  isGoodChecked (id) {
    return !!this.config.appliedGoods?.find((item) => item === id)
  }

  getValidDiscounts (discounts) {
    const intakeSelected = this.getStartString('LL-dd-yyyy')
    const studentLocation = this.getStudentNationality()
    if (this.getCourseProgram()?.name === 'graduate') {
      return discounts?.filter(
        (discount) => {
          const found = discount.season?.find((intake) => intake === intakeSelected)
          let dateFound = false
          if (!found) {
            const date = new Date(intakeSelected)
            dateFound = discount.season?.find((season) => {
              const dates = this.getDates(season, discount.year)
              if (dates) {
                const start = dates[0]
                const end = dates[1]
                if (isSameOrAfter(date, new Date(start)) && isSameOrBefore(date, new Date(end))) {
                  return true
                } else {
                  console.log('The selected date does not match in the season ', season, 'start: ' + start, 'end: ' + end )
                }
              }
              return false
            })
            if (!dateFound) return false
          }
          if (studentLocation.country && discount.discount_exceptions) {
            const noApplicable = discount.discount_exceptions.includes(studentLocation.country)
            if (noApplicable) return false
          }
          if (studentLocation.region && discount.discount_regions) {
            const applicable = discount.discount_regions.includes(studentLocation.region)
            if (!applicable) return false
          }
          if (studentLocation.subregion && discount.discount_subregions) {
            const applicable = discount.discount_subregions.includes(studentLocation.subregion)
            if (!applicable) return false
          }
          return !!found || !!dateFound
        }
      )
    }
    return discounts?.filter(
      (discount) => {
        const found = discount.season?.find((intake) => {
          if (!intakeSelected) return false
          return isSameMonth(new Date(intake), new Date(intakeSelected))
        })
        let dateFound = false
        if (!found && intakeSelected) {
          const date = new Date(intakeSelected)
          dateFound = discount.season?.find((season) => {
            const dates = this.getDates(season)
            if (dates) {
              const start = dates[0]
              const end = dates[1]
              if (isSameOrAfter(date, new Date(start)) && isSameOrBefore(date, new Date(end))) {
                // console.log('the selected date is in ' + season + ' dates')
                return true
              }
            }
            return false
          })
          if (!dateFound) return false
        }
        if (studentLocation.country && discount.discount_exceptions) {
          const noApplicable = discount.discount_exceptions.includes(studentLocation.country)
          if (noApplicable) return false
        }
        if (studentLocation.region && discount.discount_regions) {
          const applicable = discount.discount_regions.includes(studentLocation.region)
          if (!applicable) return false
        }
        if (studentLocation.subregion && discount.discount_subregions) {
          const applicable = discount.discount_subregions.includes(studentLocation.subregion)
          if (!applicable) return false
        }
        return !!found || !!dateFound
      }
    )
  }

  // Total Quote
  getTotalQuoteAmount () {
    return (
      this.getSubTotalQuoteAmount() -
      this.getTotalDiscounts()
    ) ?? this.getSubTotalQuoteAmount()
  }

  getArrayOfServices (services) {
    const groupServices = []
    services?.forEach(service => {
      groupServices.push({
        id: service.service_agency,
        cost: this.getAmountService(service.service_agency),
        currency: this.getServiceAgencyCurrency(service.service_agency)
      })
    })
    return groupServices
  }
}
