import { UserOutlined, LockOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons'

const statusItems = [
  {
    label: 'Pending',
    key: 'Pending',
    icon: <UserOutlined />
  },
  {
    label: 'Active',
    key: 'Active',
    icon: <UserOutlined />
  },
  {
    label: 'Deleted',
    key: 'Deleted',
    icon: <UserOutlined />,
    danger: true
  },
  {
    label: 'Created',
    key: 'Created',
    icon: <UserOutlined />,
    disabled: true
  }
]

const aprovalStatusItems = [
  {
    label: 'Pending',
    key: 'Pending',
    icon: <LockOutlined />
  },
  {
    label: 'Approved',
    key: 'Aproved',
    icon: <CheckCircleOutlined />
  },
  {
    label: 'Activate',
    key: 'Active',
    icon: <CheckCircleOutlined />
  },
  {
    label: 'Suspended',
    key: 'Deleted',
    icon: <StopOutlined />
  },
  {
    label: 'Deleted',
    key: 'Force_delete',
    icon: <StopOutlined />
  }
]

const emailValidationtatusItems = [
  {
    label: 'Pending',
    key: 'Pending',
    icon: <LockOutlined />
  },
  {
    label: 'Validated',
    key: 'Aproved',
    icon: <CheckCircleOutlined />
  },
  {
    label: 'Rejected',
    key: 'Deleted',
    icon: <StopOutlined />
  }
]

export { statusItems, aprovalStatusItems, emailValidationtatusItems }
