import { Col, Form, Input, Row, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { SelectCampusByProgramFormItem } from '../../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { SelectStudyAreasFormItem } from '../../../../../common/components/helper-form/SelectStudyAreasFormItem'
import { programTypes } from '../../../../../utils/programTypes'
import { SelectCourseTypeFormItem } from '../../../../../common/components/helper-form/SelectCourseTypeFormItem'
const { Search } = Input
const { Option } = Select

const CourseTableFilter = ({ program = 'graduate', type, courses, setCourses, searchFilter, setSearchFilter }) => {
  // const [searchFilter, setSearchFilter] = useState({})
  const [subTypes, setSubtypes] = useState()
  const [form] = Form.useForm()

  // function updateCouses () {
  //   const nCourses = courses?.filter((course) => {
  //     let ok = true
  //     if (searchFilter?.sede) {
  //       ok = !!course.sedes?.find((sede) => sede.id === searchFilter?.sede)
  //     }
  //     if (searchFilter?.area && ok) {
  //       ok = course.area?.includes(searchFilter?.area)
  //     }
  //     if (searchFilter?.search && ok) {
  //       ok = !!course.label?.toLowerCase().includes(searchFilter?.search?.toLowerCase())
  //     }
  //     if (searchFilter?.type && ok) {
  //       ok = course.properties?.sub_type === searchFilter?.type
  //     }
  //     if (searchFilter?.course_type && ok) {
  //       ok = course.properties?.course_type === searchFilter?.course_type
  //     }
  //     return ok
  //   })
  //   setCourses(nCourses)
  // }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilters = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilters)
    }, 500)
  })

  useEffect(() => {
    // updateCouses()
  }, [searchFilter])

  useEffect(() => {
    handleOnChange(type)
    form.resetFields()
    setSearchFilter({})
  }, [type])

  const handleOnChange = (val) => {
    const found = programTypes.find((item) => (item.value === val))
    setSubtypes(found?.subtypes)
  }

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...searchFilter,
      [key]: value,
      page: 1
    }
    setSearchFilter(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  const SearchCourseFilter = () => {
    return (
      <Form.Item name='search'>
        <Search
          className='byp-input-gray'
          placeholder='Search'
          onChange={(event) => debounceFilter('search', event.target.value)}
          allowClear
          size='large'
        />
      </Form.Item>
    )
  }

  const CourseTypeFileter = () => {
    return (
      <SelectCourseTypeFormItem
        name='course_type'
        placeholder='All types'
        onChange={(e) => {
          e
            ? handleAddFilter('course_type', e)
            : handleRemoveFilter('course_type')
        }}
        showAll
        size='small'
      />
    )
  }

  const BySchoolsFilter = () => {
    return (
      <SelectCampusByProgramFormItem
        program={program}
        name='sede'
        onChange={(e) => {
          e > 0
            ? handleAddFilter('sede', Number(e))
            : handleRemoveFilter('sede')
        }}
        showAll
        placeholder='All the schools'
        size='small'
      />
    )
  }

  return (
    <Form form={form}>
      {program === 'graduate' && (
        <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
          <Col xs={24} md={12} lg={8}>
            <SearchCourseFilter />
          </Col>
          {subTypes && (
            <Col span={6}>
              <Form.Item name='type'>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  onChange={(e) => {
                    e
                      ? handleAddFilter('sub_type', e)
                      : handleRemoveFilter('sub_type')
                  }}
                  className='w-100'
                  placeholder={type === 'diploma' ? 'All Program Sub-type' : 'All types'}
                >
                  {subTypes && <Option key={'subtype' + 0} value={null}>All Subtypes</Option>}
                  {subTypes?.map((item) => <Option key={item.key} value={item.value}>{item.label}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={5}>
            <BySchoolsFilter />
          </Col>
          <Col span={5}>
            <SelectStudyAreasFormItem
              onChange={(e) => {
                e
                  ? handleAddFilter('area', e)
                  : handleRemoveFilter('area')
              }}
              showAll
              placeholder='All areas'
              name='area'
              size='small'
            />
          </Col>
        </Row>
      )}
      {program !== 'graduate' && (
        <Row gutter={24} className='w-100 content-quoter byp-mb-1'>
          <Col xs={24} md={12} lg={program === 'language' ? 12 : 8}>
            <SearchCourseFilter />
          </Col>
          {(program === 'boarding_schools' || program === 'academic_years') && (
            <Col xs={6}>
              <CourseTypeFileter />
            </Col>
          )}
          {subTypes && (
            <Col span={6}>
              <Form.Item name='type'>
                <Select
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  onChange={(e) => {
                    e
                      ? handleAddFilter('type', e)
                      : handleRemoveFilter('type')
                  }}
                  className='w-100'
                  placeholder={type === 'diploma' ? 'All Program Sub-type' : 'All types'}
                  size='small'
                >
                  {subTypes && <Option key={'subtype' + 0} value={null}>All</Option>}
                  {subTypes?.map((item) => <Option key={item.key} value={item.value}>{item.label}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={(program === 'language' || program === 'academic_years') ? 8 : 6}>
            <BySchoolsFilter />
          </Col>
        </Row>
      )}
    </Form>
  )
}

export { CourseTableFilter }
