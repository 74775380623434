import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import AgencyRepository from '../../repositories/AgencyRepository'

function useDownloadExchangeHistoryByCurrency () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.getMyExchangeHistoryByCurrency, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const downloadHistory = (currency) => {
    mutation.mutate({ currency })
  }

  return { ...mutation, state, currentState, downloadHistory }
}

export { useDownloadExchangeHistoryByCurrency }
