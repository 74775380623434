import { Button, Col, Row, Tag } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { URL_USER_AGENCIES_PAGE } from '../analitics/AgenciesAnaliticsPage'
import { crypt } from '../../../../bussines/utils/basicEncript'

const CardTopAgencie = ({ agency }) => {
  function urlEncrypte () {
    if (agency) {
      let url = crypt('qyp2023', agency.id + ' ')
      url = URL_USER_AGENCIES_PAGE + '/agencies/:agencyId'.replace(':agencyId', url)
      return (url)
    }
  }

  return (
    <Col span={24} className='card-top-agencie'>
      <Row gutter={24} className='card-top-agencie__container'>
        <Col md={10}>
          <picture className='card-img'>
            <img src={agency.profile_photo_url} alt='' />
          </picture>
        </Col>
        <Col md={14}>
          {(agency.status === 'Deleted' && !agency.deleted_at) && (
            <Tag
              style={{ float: 'right', fontSize: '10px' }}
              className='byp-bg-yelow-100 byp-color-white byp-fw-bold'
            >
              Suspended
            </Tag>
          )}
          {(agency.status === 'Deleted' && agency.deleted_at) && (
            <Tag
              style={{ float: 'right', fontSize: '10px' }}
              className='byp-bg-red byp-color-white byp-fw-bold'
            >
              Agency Deleted
            </Tag>
          )}
          <h3>
            {agency.name}
          </h3>
          <Row>
            <Col span={12} className='card-quote-created'>
              <h4>Quotes created</h4>
              <p>{agency.quotes_count}</p>
            </Col>
            <Col span={12} className='card-quote-sended'>
              <h4>Quotes sended</h4>
              <p>{agency.quotes_send ?? 0}</p>
            </Col>
          </Row>
          <Button
            className='w-100 margin-0 byp-btn-back'
            style={{ background: '#F4F4F4' }}
          >
            {agency.status !== 'Deleted' && (
              <Link
                to={urlEncrypte()}
                state={{ menuItemSelected: 'agencies-analysis', openSub: 'analitic-institution' }}
              >See more
              </Link>
            )}
          </Button>
        </Col>
      </Row>
    </Col>
  )
}

export { CardTopAgencie }
