import { Form, Select } from 'antd'
const { Option } = Select
function SelectWeekDay ({ hasNoneOption = false, isAccommodation, ...props }) {
  return (
    <Form.Item
      {...props}
    >
      <Select {...props}>
        {!isAccommodation && <Option key={0} value='none'>None</Option>}
        <Option key={1} value='Saturday'>Saturday</Option>
        <Option key={2} value='Sunday'>Sunday</Option>
        <Option key={3} value='Monday'>Monday</Option>
        <Option key={4} value='Tuesday'>Tuesday</Option>
        <Option key={5} value='Wednesday'>Wednesday</Option>
        <Option key={6} value='Thursday'>Thursday</Option>
        <Option key={7} value='Friday'>Friday</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectWeekDay }
