import { useQuery } from 'react-query'
import { NationalityRepository } from '../../repositories/NationalityRepository'

function useGetCountriesBySubRegions (subregion) {
  return useQuery(['useGetCountriesBySubRegions', subregion],
    () => NationalityRepository.countriesBySubRegions(subregion), {
      retry: 2,
      enabled: !!subregion
    })
}

export { useGetCountriesBySubRegions }
