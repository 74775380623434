import axios from 'axios'
import { API_URL } from '../config'
import SessionService from './SessionService'

export const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*'
}
export const headersWithToken = {
  'Access-Control-Allow-Credentials': true,
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*'
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers
})

axiosInstance.interceptors.request.use(function (config) {
  if (SessionService.existSession()) {
    config.headers = {
      ...headersWithToken,
      Authorization: `Bearer ${SessionService.getAccessToken()}`
    }
  }

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default axiosInstance
