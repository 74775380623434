import { useMutation } from 'react-query'
import state from './../../utils/state-control'
import { useState } from 'react'
import UserRepository from '../../repositories/UserRepository'

function useDeleteUser () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(UserRepository.delete, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const deleteUser = (id) => {
    mutation.mutate({ id })
  }

  return { ...mutation, state, currentState, deleteUser }
}

export { useDeleteUser }
