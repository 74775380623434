import { useEffect, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { Col, Row } from 'antd'
import { SelectCampusByProgramFormItem } from './SelectCampusByProgramFormItem'
import { SelectYearByCampus } from './SelectYearByCampus'
import { SelectProgramSubTypeByCampus } from './SelectProgramSubTypeByCampus'
import { SelectCourseByCampus } from './SelectCourseByCampus'
import { useGetYearsByCampus } from '../../../bussines/hooks/school/useGetYearsByCampus'

export const SelectCampusProgramYearAndCourseFormItem = ({
  program, campusID, courses,
  setSubType, setCourse, form,
  pricesData, haveSearch
}) => {
  const location = useLocation()
  const { course_name: courseName } = location.state ?? '1'

  const [campusId, setCampusId] = useState(campusID)
  const { data: programs } = useGetYearsByCampus(campusID)
  const getcategories = (id) => {
    form.setFieldsValue({ course_id: undefined })
    setCourse()
    setCampusId(id)
  }
  const getSubType = (subType) => {
    form.setFieldsValue({ course_id: undefined })
    setSubType(subType)
  }
  const [params] = useSearchParams()
  const type = params.get('type')

  useEffect(() => {
    if (type) {
      // form.setFieldsValue({ course_id: undefined, campus_id: undefined, year_id: undefined })
    }
    setSubType(type)
  }, [type])

  useEffect(() => {
    if (
      programs && programs?.length > 0 &&
      pricesData && pricesData?.length > 0
    ) {
      pricesData.map((def) => {
        if (def.label === courseName) {
          // Why set the course_id as the label?
          form.setFieldsValue({
            course_id: def.label
          })
        }
        return null
      })
    }
  }, [programs, pricesData])
  return (
    <Row gutter={12}>
      <Col xs={9} md={6}>
        <SelectCampusByProgramFormItem
          size='small'
          program={program}
          name='campus_id'
          onChange={getcategories}
          label='School'
        />
      </Col>
      {program === 'graduate' && (
        <Col xs={9} md={6}>
          <SelectProgramSubTypeByCampus
            size='small'
            program={program}
            name='subtype'
            label='Program sub-type'
            getSubType={getSubType}
          />
        </Col>
      )}
      <Col xs={9} md={6}>
        <SelectYearByCampus
          size='small'
          campus={campusId}
          name='year_id'
          label='Year'
        />
      </Col>
      <Col xs={9} md={6}>
        <SelectCourseByCampus
          size='small'
          program={program}
          name='course_id'
          label='Course'
          courses={courses}
          setCourse={setCourse}
          haveSearch={haveSearch}
        />
      </Col>
    </Row>
  )
}
