import api from '../../services/api'

class TagRepository {
  async tags (params) {
    const response = await api.get('tags', { params })
    return response.data
  }

  async find (slug) {
    const response = await api.get(`tags/${slug}/show`)
    return response.data
  }
}

export default new TagRepository()
