import React, { useEffect } from 'react'
import { Col, Collapse, notification, Row, Form } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { LodgingSection } from './LodgingSection'
import { MedicalSection } from './MedicalSection'
import { ServiceSection } from './ServiceSection'
import { OtherSection } from './OtherSection'
import { ClientInfoSection } from './ClientInfo'
import { AgencyCostSections } from './AgencyCostSections'
import { FieldPayTuition } from './FieldPayTuition'
import { FieldSchoolMaterials } from './FieldSchoolMaterials'
import { SelectStartAndDuration } from './SelectStartAndDuration'
import { FieldCourse } from './FieldCourse'
import { TransportSection } from './TransportSection'
import moment from 'moment'
import { CampusPricesSection } from './CampusPricesSection'
import { FieldCourseGoods } from './FieldCourseGoods'

const { Panel } = Collapse

function QuoterForm () {
  const {
    service, isLoadingBaseQuote, baseQuote, form, setFormConfig, formConfig, isSuccessPost, isLoadingPost, isPreview
  } = useQuoter()

  const agencyServices = Form.useWatch('services_agency', form)

  useEffect(() => {
    if (!isLoadingBaseQuote && baseQuote?.id) {
      form.setFieldsValue({
        weeks: baseQuote.weeks,
        startEvent: moment(baseQuote.arrival_date),
        student_nationality: baseQuote.student_nationality,
        student_location: baseQuote.student_location,
        expiration: moment(baseQuote.expiration) ?? null,
        materials: baseQuote.materials,
        material: baseQuote.material,
        lodging: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks: baseQuote.lodging_properties?.weeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        transport: baseQuote.transport,
        transport_way: baseQuote.transport_ways,
        health_insurance: baseQuote.insurance,
        medical: baseQuote.agency_insurance.length > 0 ? baseQuote.agency_insurance[0].id : null,
        services_agency: baseQuote.additional_services.map((item) => ({ service_agency: item.id })),

        client_name: baseQuote.client_name,
        message: baseQuote.message
      })

      const agencyServices = []
      if (service.isAgency()) {
        baseQuote.agency_services?.map((item) => (
          agencyServices.push({ id: item.id, cost: service.getAgencyServiceCost(item.id) })
        ))
      }

      const includedFees = []
      service.getFees()?.map(({ id, cost }) => (
        baseQuote.fees.includes(id) ? includedFees.push({ id, cost }) : null
      ))

      setFormConfig({
        ...formConfig,
        materials: baseQuote.materials,
        material: baseQuote.material,
        courseStart: moment(baseQuote.arrival_date),
        weeks: baseQuote.weeks,
        lodging: baseQuote.include_lodging,
        lodgingId: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks: baseQuote.lodging_properties?.weeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        lodgingExtras: baseQuote.lodging_extras,
        transport: baseQuote.include_transport,
        transportId: baseQuote.transport,
        transportWaySelected: baseQuote.transport_ways,
        transportMinorCosts: baseQuote.transport_minor_costs,
        fees: includedFees,
        insurance: baseQuote.include_insurance,
        healthInsuranceId: baseQuote.insurance,
        agencyServices,
        serviceAgency: baseQuote.additional_services?.length > 0,
        medical: baseQuote.agency_insurance?.length > 0
      })
    }
  }, [isLoadingBaseQuote, baseQuote])

  useEffect(() => {
    if (!isLoadingPost && isSuccessPost) {
      notification.info({
        message: 'Quote saved',
        description: '',
        placement: 'top'
      })
    }
  }, [isSuccessPost, isLoadingPost])

  return (
    <>
      <Row>
        <Col md={12}><h5 className='byp-title byp-mr-1'>Summary</h5></Col>
        <Col md={12} style={{ textAlign: 'center' }}><h5 className='byp-title byp-ml-1'>Cost</h5></Col>
      </Row>
      <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} expandIconPosition='right'>
        <Panel header='Program Fees' key='1'>
          <SelectStartAndDuration />
          <FieldPayTuition form={form} />
          <FieldCourse />
          {service.getSchoolMaterial() && <FieldSchoolMaterials />}
          <CampusPricesSection />
          <FieldCourseGoods />
        </Panel>

        {
          service.hasLodging() && !service.isOnline() &&
            <Panel header='Accommodation fees' key='2'>
              <LodgingSection />
            </Panel>
        }

        {
          service.hasTransports() && !service.isOnline() &&
            <Panel header='Airport Pick Up / Drop off' key='3'>
              <TransportSection />
            </Panel>
        }

        {(service.getArrayHealtInsurance()?.length > 0 || service.getPrices()?.extras?.length > 0) && (
          <Panel header='Extras' key='4'>
            <OtherSection />
          </Panel>
        )}

        {
          service.isAgency() &&
            <Panel header='Agency costs' key='5'>
              <AgencyCostSections />
            </Panel>
        }

        {(service.getServiceAgency()?.length > 0 && service.isAgency()) && (
          <Panel header='Additional services' key='8'>
            <ServiceSection agencyServices={agencyServices} />
          </Panel>
        )}

        {
          (service.getMedicalInsurance()?.length > 0 && service.isAgency()) &&
            <Panel header='Medical Insurance' key='7'>
              <MedicalSection />
            </Panel>
        }

        {
          !isPreview && (
            <Panel header='Customize this quote' key='6'>
              <ClientInfoSection />
            </Panel>
          )
        }
      </Collapse>
    </>
  )
}

export { QuoterForm }
