import { Col, Form, Row, Select } from 'antd'
// import { useState } from 'react'
// import { useGetCountriesByRegions } from '../../../bussines/hooks/places/useGetCountriesByRegion'
import { useGetCountriesBySubRegions } from '../../../bussines/hooks/places/useGetCountriesBySubRegion'
import { useGetSubregionsByRegions } from '../../../bussines/hooks/places/useGetSubregionsByRegion'
import { SelectRegionsFormItem } from './SelectRegionsFormItem'

const { Option } = Select
export function SelectCountriesByRegionFormItem ({ form }) {
  // const [region, setRegion] = useState()
  const regions = Form.useWatch('region', form)
  const subRegions = Form.useWatch('subregions', form)
  // const { data: countries } = useGetCountriesByRegions(regions)
  const { data: countries } = useGetCountriesBySubRegions(subRegions)
  const { data: subregions } = useGetSubregionsByRegions(regions)

  const handleSelectAll = (val) => {
    if (val.includes('all')) {
      const allSubRegions = subregions?.flat()?.map((subregion) => subregion.subregion)
      form.setFieldsValue({ subregions: allSubRegions })
    }
  }

  const handleSelectAllExclude = (val) => {
    if (val.includes('all')) {
      const allExcludeCountries = countries?.flat()?.map((country) => country.id)
      form.setFieldsValue({ exclude_countries: allExcludeCountries })
    }
  }

  return (
    <div>
      <Row gutter={12} style={{ marginBottom: '2rem' }}>
        <Col xs={24} md={8}>
          <SelectRegionsFormItem
            name='region' mode='multiple'
            label='Student location region'
            global
            form={form}
            rules={[{ required: true, message: 'This field is required' }]}
            labelAlign='left' labelCol={{ span: 12 }}
          />
        </Col>
        <Col xs={24} md={16}>
          <Form.Item name='subregions' placeholder='Add more countries' label='Subregions' rules={[{ required: true, message: 'This field is required' }]}>
            <Select
              maxTagCount='responsive'
              mode='multiple'
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={handleSelectAll}
              allowClear
            >
              {subregions && (
                <Option key='all_subregions' value='all'>All</Option>
              )}
              {subregions?.flat()?.map((subregion) => (
                <Option key={subregion.subregion} value={subregion.subregion}>{subregion.subregion}</Option>
                /* <Option key={country.id} value={country.id}>{country.name}</Option> */
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Col xs={24} md={16} style={{ marginTop: '-10px', marginBottom: '-10px' }}>
        <Form.Item
          name='exclude_countries'
          placeholder='Add more countries'
          label='Excluded country'
          labelAlign='left' labelCol={{ span: 6 }}
        >
          <Select mode='multiple' onChange={handleSelectAllExclude} maxTagCount='responsive' allowClear showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            <Option key='all_subregions' value='all'>All</Option>
            {countries?.flat()?.map((country) => (
              <Option key={country.id} value={country.id}>{country.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

    </div>
  )
}
