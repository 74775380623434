import { useQuery } from 'react-query'
import SchoolRepository from '../../repositories/SchoolRepository'
import removeInvalidValuesFromParams from '../../../utils/removeInvalidValuesFromParams'

function useGetAllCampus (filters = {}) {
  const validParams = removeInvalidValuesFromParams(filters)
  const filtersQuery = Object.keys(validParams).map((key) => key + '=' + validParams[key]).join('&')
  return useQuery(['useGetAllCampus', filtersQuery],
    () => SchoolRepository.getCampus(filtersQuery), {
      retry: 1
    })
}

export { useGetAllCampus }
