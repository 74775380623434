import { Button, Col, Divider, Form, Input, Row, Typography, Checkbox } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React from 'react'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'

const { Title } = Typography

function AgencyPartnerFieldsContainer ({ form }) {
  const arrayPartners = Form.useWatch('partners', form) || []

  return (
    <div>
      <Title level={5}>Partners</Title>
      <Form.List
        name='partners'
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <Divider />
                <Row gutter={8}>
                  <Col span={22}>
                    <FloatLabel
                      label='Nombre de la Escuela'
                      value={arrayPartners[name]?.name}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        rules={[{ required: true, message: 'The name is required' }]}
                      >
                        <Input />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                  <Col span={2}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                  <Col span={22}>
                    <FloatLabel
                      label='Nombre contacto principal'
                      value={arrayPartners[name]?.contact}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'contact']}
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <Input />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                  <Col span={22}>
                    <FloatLabel
                      label='Email'
                      value={arrayPartners[name]?.email}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        rules={[{ required: true, message: 'This field is required' },
                          { type: 'email', message: 'No es un email válido' }]}
                      >
                        <Input />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                  <Col span={22}>
                    <Form.Item
                      {...restField}
                      name={[name, 'send_invitation']}
                      valuePropName='checked'
                    >
                      <Checkbox className='float-right'>Send invitation</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

              </div>
            ))}

            <Row gutter={8}>
              <Col span={22}>
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </>
        )}

      </Form.List>
    </div>
  )
}

export { AgencyPartnerFieldsContainer }
