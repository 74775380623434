import { Collapse } from 'antd'
const { Panel } = Collapse
function QuoteSchoolDocuments ({ filesSchool = [], filesAgency = [], isAgency }) {
  return (
    <div className='w-100 content-quoter'>
      {
        isAgency && (
          <Collapse bordered={false} defaultActiveKey={['0']} className='borderless byp-mt-1'>
            <Panel header='Agency documents' style={{background: '#FAFAFA', borderBottom: '1px solid #d9d9d9'}}>
              {
                filesAgency.map((doc) => (
                  <div key={doc.id} style={{borderBottom: '1px solid #686868', padding: '5px 0'}}>
                    <a href={doc.profile_photo_url} download target='_blank' rel='noreferrer'>{doc.name ?? doc.type}</a>
                  </div>
                ))
              }
            </Panel>
          </Collapse>
        )
      }
      <Collapse bordered={false} defaultActiveKey={['0']} className='borderless byp-mt-1'>
        <Panel header='School documents' style={{background: '#FAFAFA', borderBottom: '1px solid #d9d9d9'}}>
          {
            filesSchool.map((doc) => (
              <div key={doc.id} style={{borderBottom: '1px solid #686868', padding: '5px 0'}}>
                <a href={doc.profile_photo_url} download target='_blank' rel='noreferrer'>{doc.name ?? doc.type}</a>
              </div>
            ))
          }
        </Panel>
      </Collapse>
      {/* <Button className='byp-btn-blue-100 byp-mt-1'>
        Admin your documents
      </Button> */}
    </div>
  )
}

export { QuoteSchoolDocuments }
