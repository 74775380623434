import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const InstitutionTermsAndConditions = () => {
  return (
    <div className='margen-text-about'>
      {/* Quoteyourprogram.com Terms and Conditions
          <br /> */}
      <h2><b>Institutions and Service Companies</b></h2>
      <p>
        Last update January 30, 2023.
      </p>
      <p>
        <b>Service description:</b>
      </p>
      <p>
        Quoteyourprogram is a quoting platform which seeks to integrate the international education
        industry (institutions, educational agencies and service companies) within a single platform,
        providing a quoting service in an easy and intuitive way.
      </p>
      <p>
        Through the platform we allow schools and service companies to create quotes, update
        their prices, promotions and publish them in order to allow their agents and other industry
        member to access them and be able to offer their study courses and services abroad.
      </p>

      <h2>Generalities</h2>
      <p>
        This policy of terms and conditions comes into force as of January 31, 2023 and by
        accessing or using this service through the website of quoteyourprogram.com the user
        acknowledges to have read, understood and accepted these Terms and conditions either in
        a personal manner or on behalf of a company or entity and agrees to have the legal authority
        to bind such entity or its affiliates to these terms.
      </p>
      <p>
        Quoteyourprogram.com has the faculty to modify these terms and conditions at any time
        which will be updated on its website ww.quoteyourprogram.com and will be valid from the
        day of its publication. If the user does not agree with the updates or changes made at the
        time, they can stop using the platform.
      </p>
      <p>
        The use of the platform does not constitute an employment relationship between the user
        and quoteyourprogram and the user agrees that it is a contract for the provision of services
        through an intermediation between several companies.
      </p>
      <p>
        Quoteyourprogram reserves the right to use the platform to organizations or service
        companies that are not recognized by the international education industry, as well as to
        suspend the use to institutions known to have a bad reputation, prices and promotions with
        dumping, complaints or demands attributable to their services, or to institutions or service
        companies that do not offer a commission to educational agencies.
      </p>
      <p>
        <b>Terms of use:</b> The user can access the platform in order to quote or have their product
        listed according to the terms and conditions agreed under the contracted plan. It is not
        permitted to grant licenses, sublicenses, loan, sell, resell, rent, lease, copy, transfer, assign,
        distribute or share the use of the platform except as expressly permitted in these terms and
        conditions. At the same time, it is prohibited to use this service in an illegal way or in a way
        which interferes or disrupts the integrity of the performance of the service and its
        components. It is forbidden to adapt, hack, copy or try to access unauthorized spaces within
        this platform.
      </p>
      <p>
        Quoteyouprogram is an intermediary between the education industry (schools and service
        companies) and agencies and is not responsible for the information provided on the platform
        by its users or errors in information or prices. The user is responsible for checking that the
        information published by your institution or your agency is correct.
      </p>
      <p>
        Quoteyourprogram, being an external intermediary, is not responsible for a bad relationship
        or termination of the contractual relationship between agencies and institutions.
      </p>
      <h2>Use of the platform and information</h2>
      <p>
        The user is responsible for the confidentiality of their username and password as well as for
        the activities that occur under their login and account. The user acknowledges and accepts
        that their login is used by one (1) person and are not allowed to share or lend their username
        and password with multiple people. The user acknowledges and accepts that any misuse of
        the quote, whether due to error or ignorance, may have a consequence on the prices offered
        and will be under their own responsibility, as well as any price change or modification made
        to their account in their quotes.
      </p>
      <p>
        The user agrees that there are third-party providers of technology and information hosting to
        provide the platform service such as hardware, software, networks, storage, and technology
        to operate and maintain the service, and is in agreement that Quoteyourprogram access any
        of their accounts for technical service support and platform improvement.
      </p>
      <p>
        The user agrees that some of the quoteyourprogram functions are accessed through an API
        (Application Program Interface); Any use of the API through a third-party service that is
        accessed through quoteyourprogram is subject to the terms and conditions of said service.
      </p>
      <h2>
        Information about your institution, courses and services
      </h2>
      <p>
        The user is responsible for updating the information of their institution, courses or services in
        a timely manner, as well as including or disabling information. During the period of
        implementation of their institution or service in quoteyourprogram.com, the user has the
        responsibility to deliver information in a timely manner, factual information, photographs and
        advertising material that belongs to you and accept that they be shared through the platform
        of quoteyourprogram
      </p>
      <p>
        All the information received for the implementation of institutions or services will take 15 to
        25 business days to be reflected on the platform. Once the information is implemented, the
        user will be responsible for testing the quoters and making observations of changes and
        improvements in prices.
      </p>
      <p>
        Once the information is implemented, the user will receive an editor username and password
        where they will be able to make modifications, updates, which will be at their own risk.
      </p>
      <h2>
        Use of licenses:
      </h2>
      <p>
        The user agrees that the material provided within the platform, whether it be texts, images,
        videos and any promotional material are under the user’s license and intellectual property.
      </p>
      <p>
        The user agrees to the use of all these licenses necessary in and for their content for
        quoteyourprogram.com to provide the service.
      </p>
      <p>
        Any information or advertising material that is under the profile of each institution or service
        company is at their own risk.
      </p>
      <h2>
        Change of prices and updates of promotions or information
      </h2>
      <p>
        Any price change, promotion update or information on new products, services or courses

        must be communicated in writing and 10 days in advance in order to have the platform

        updated at all times. Any changes that are not received under this period make the user

        aware that their prices will not be published in a timely manner, and that the information

        could be outdated, running the risk of having incomplete or erroneous information.
      </p>
      <h2>
        Privacy and information data policies:
      </h2>
      <p>
        Quoteyourprogram collects information from clients under their prior authorization to be

        contacted by their agency or institution and they accept that they know the privacy policies of

        the platform. Quoteyourprogram does not use or share this information with third parties and

        the user is responsible for sharing their company's privacy notice as well as for the misuse of

        their information.
      </p>
      <h2>
        Service improvements and changes:
      </h2>
      <p>
        Quoteyourprogram reserves the right to modify the platform either by adding or removing

        functionality in order to improve the service. The user agrees that quoteyourprogram has the

        exclusive privilege to make modifications to the platform at any time.
      </p>
      <p>
        The user agrees that quoteyourprogram may assign or transfer these terms and conditions

        in whole or in part without restriction.
      </p>
      <h2>
        Modification of plans and rates:
      </h2>
      <p>
        Quoteyourprogram has the faculty to modify plans and rates of the platform. These

        modifications will be informed 30 days in advance. Quoteyourprogram is not obligated to

        provide refunds or credits, but reserves the right to evaluate each case individually.
      </p>
      <h2>
        Payments and payment methods:
      </h2>
      <p>
        The user agrees to use the platform in the plan selected by their choice, which can be billed

        monthly, semi-annually or annually according to the payment plan of your choice.
      </p>
      <p>
        The form of payment is through credit card charge. Quoteyourpogram uses an external

        intermediary to collect payment and manage payment processing through credit card. This

        intermediary does not store or retain billing information. Once the payment is made,

        Quoteyourprogram will send the invoice corresponding to said payment to the registered

        mail.
      </p>
      <p>
        The costs of the platform do not include taxes, which will be charged according to what

        corresponds to the laws of the user’s country. The user agrees to pay any taxes applicable

        to the use of the platform service.
      </p>
      <h2>
        Validity and renewal:
      </h2>
      <p>
        The validity period of use of the platform in the user´s contracted plan will be according to

        their selection and this will be automatically renewed for the previous contracted period

        subject to any modification in their plan received previously in writing 20 days in advance.
      </p>
      <h2>
        Suspension of service:
      </h2>
      <p>
        Quoteyourprogram reserves the right to suspend or even cancel the subscription in case of

        late payments on their service, as well as for using the platform in an illegal way that violates

        local, state and federal rules or foreign regulations in the user´s country.
      </p>
      <p>
        <b>
          Service cancellation:</b> The user can cancel or downgrade their plan at any given time. Said

        movement must be notified 20 days in advance and must be done in writing.
        At the time of canceling their account, the user agrees that the profiles and information

        obtained on the platform will stop being published, the user will not be able to access it nor

        will their clients or agencies, and the user also acknowledges that this information will be

        removed from the platform within a period of 10 business days.
      </p>
      <p>
        Quoteyourprogram reserves the right to modify, temporarily or indefinitely discontinue the

        platform service as well as suspend, cancel, delete an account or the use of the service for

        any reason, even if quoteyourprogram believes that the user has violated the terms and

        conditions or has done misuse of the platform. Any suspicion of fraudulent, abusive or illegal

        activity may be grounds for termination of use of the service and may be referred to the

        appropriate law enforcement authorities. Quoteyourprogram shall not be liable to the user or

        any third party for any modification, suspension or interruption of the service.
      </p>
      <h2>
        Brand Use:
      </h2>
      <p>
        The user accepts and acknowledges that the "Quoteyourprogram" brand as well as its

        various QYP logos can only be used for promotional purposes to identify themselves as a

        customer or user of the platform. These brands are exclusive and logos are exclusive to

        Quoteyourprogram and should be consulted at the time of use.
      </p>
      <p>
        The user agrees that quoteyourprogram may disclose the user’s name, brand, logo in sales

        presentations, platform website or advertising marketing material in order to publicize the

        collaboration between both parties.
      </p>
      <h2>
        Warranty Waiver
      </h2>
      <p>
        The user agrees to, accepts and waives that the service, including the site, content and all

        components of the server, network, APIs and external providers that make the platform have

        the proper functioning of the system and does not offer any guarantee of any kind, in the

        maximum extent permitted by law. Quoteyourprogram expressly disclaims any warranties,

        whether express or implied, including, but not limited to, the implied warranties of

        merchantability. The user agrees that quoteyourprogram is an intermediary between

        institutions, service companies and agencies for the communication of their prices to the

        final customer and acknowledges that Quoteyourprogram does not guarantee that the

        service will be uninterrupted, timely, secure and free of errors or viruses. No information or

        advice obtained by the user from Quoteyourprogram or through the service shall create any

        warranty not expressly set forth in these terms and conditions.
      </p>
      <h2>
        Limitation of Liability:
      </h2>
      <p>
        Under no circumstances and under no legal theory (whether in contract, tort or otherwise)

        shall Quoteyourprogram be liable to the user or any third party for any indirect or direct,

        incidental, or incidental damages, errors in pricing, promotions, interruption of platform

        and/or loss of data. Quoteyourprogram shall not be liable for any damages, costs, losses or

        liabilities in excess of the monthly service fee paid or paid by the user during the month prior

        to the time of any claim for money damages.
      </p>
      <p>
        The user agrees when using the quoteyourprogram platform to accept that there are risks in

        timely price changes, promotions, updates and finger errors when processing the information

        on the platform and the limitations that this entails in the use of it and erroneous information

        is at the user’s own risk.
      </p>
      <h2>
        Causes of force majeure:
      </h2>
      <p>
        Under circumstances of force majeure such as natural disasters, pandemics, acts of war,

        hostility, sabotage, electricity, internet or communications outage, government restrictions or

        force majeure, neither party will be responsible for the non-compliance or delay of the

        service.
      </p>
      <p>
        The user accepts and agrees that this contract is valid even if it is signed electronically and

        supersedes any previous agreement had between the user and Quoteyourprogram and any

        dispute regarding this contract or service will be carried out through the laws of Mexico in

        Guadalajara Jalisco.
      </p>
    </div>
  )
}

export { InstitutionTermsAndConditions }
