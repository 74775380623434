import { Statistic, Table } from 'antd'
const { Column } = Table
function QuoteOverviewAgencyCosts ({ isLoading, data, agentData, userData, exchangeVal, getExchangeVal, divisa }) {
  // function getDurationLabel (weeks) {
  //   let label = 'Single payment'
  //   if (weeks) {
  //     label = weeks + ' Semanas'
  //   }
  //   return (
  //     label
  //   )
  // }

  const getValue = (value) => {
    if (value === null) return ''
    return !isNaN(value) ? `$${value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}` : value
  }

  return (
    <Table
      loading={isLoading}
      dataSource={data ?? []}
      className='byp-table-clear quote-overview-table'
      rowKey='name'
      showHeader={false}
      pagination={{
        hideOnSinglePage: true
      }}
    >
      <Column
        title='Concept' dataIndex='name' key='name'
        render={(_, record) => (
          record.cost < 0 ? <span style={{ color: 'red', fontWeight: 'bold' }}>{record.name}</span> : <span>{record.name}</span>
        )}
      />
      {/* <Column
        title='Payment' dataIndex='weeks' key='weeks'
        render={(_, record) => (
          record.duration ?? getDurationLabel(record.properties?.weeks)
          // record.duration
        )}
      /> */}
      <Column
        title={`Total ${data[0].divisa?.code}`} dataindex='' key=''
        render={({ cost, divisa }) => (
          <>
            <span className='data-label'>Total {data[0].divisa?.code}</span>
            <Statistic value={getValue(cost)} precision={2} />
          </>
        )}
      />
      {/* <Column
        title={`Aprox ${agentData?.sede?.divisa?.code}`} dataindex='' key=''
        render={({ cost, divisa }) => (
          getExchangeVal(divisa?.code) > 0
            ? (
              <>
                <span className='data-label'>Aprox {agentData?.sede?.divisa?.code}</span>
                <Statistic value={getValue(cost * getExchangeVal(divisa?.code))} precision={2} />
              </>
              )
            : 'You do not have a exchange value configured'
        )}
      /> */}
    </Table>
  )
}

export { QuoteOverviewAgencyCosts }
