const bypEvents = [
  {
    name: 'general-prices-aprove',
    label: 'Approved General Prices'
  },
  {
    name: 'general-prices-save',
    label: 'Saved General Prices'
  },
  {
    name: 'course-prices-aprove',
    label: 'Approved Course Prices'
  },
  {
    name: 'course-prices-save',
    label: 'Saved Course Prices'
  },
  {
    name: 'lodging-prices-aprove',
    label: 'Approved Accommodation Prices'
  },
  {
    name: 'lodging-prices-save',
    label: 'Saved Accommodation Prices'
  },
  {
    name: 'transport-prices-aprove',
    label: 'Approved Transport Prices'
  },
  {
    name: 'transport-prices-save',
    label: 'Saved Transport Prices'
  },
  {
    name: 'discounts-base-prices-aprove',
    label: 'Discount Values Approved In Base Prices'
  },
  {
    name: 'discounts-base-prices-save',
    label: 'Discount Values Saved in Base Prices'
  },
  {
    name: 'discounts-course-prices-aprove',
    label: 'Discount Values Approved In Course Prices'
  },
  {
    name: 'discounts-course-prices-save',
    label: 'Discount Values Saved In Course Prices'
  },
  {
    name: 'discounts-lodging-prices-aprove',
    label: 'Discount Values Approved In Accommodation Prices'
  },
  {
    name: 'discounts-lodging-prices-save',
    label: 'Discount Values Saved In Accommodation Prices'
  },
  {
    name: 'discounts-transport-prices-aprove',
    label: 'Discount Values Approved In Transport Prices'
  },
  {
    name: 'discounts-transport-prices-save',
    label: 'Discount Values Saved In Transport Prices'
  }
]

const getEventLabel = (eventName) => {
  const found = bypEvents.find((event) => event.name === eventName)
  return found?.label || eventName
}

export { bypEvents, getEventLabel }
