// import { HeaderStep } from '../../HeaderStep'
import FooterStep from '../../../../../common/components/steps/FooterStep'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import { EducationGroupFormConfigured } from '../../forms/EducationGroupFormConfigured'
import { FormTitle } from '../../texts/FormTitle'
export const STEP_PROFILE = 1

function EducationGroupProfileStep () {
  return (
    <StepBaseTemplate
      justify='space-around'
      footer={<FooterStep />}
    >
      <div style={{ margin: '18px 5%' }}>
        {/* <HeaderStep />   */}
        <FormTitle
          title='Edit your education group profile '
          text="Let's get started, enter your education group details so that you have the opportunity to offer Additional costs to your clients, as well as customize quotes and searches."
        />
        <EducationGroupFormConfigured />
      </div>
    </StepBaseTemplate>
  )
}

export { EducationGroupProfileStep }
