import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
function LoadingCourses ({ message = '' }) {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <LoadingOutlined style={{ fontSize: 36, margin: 14 }} />
      <br />
      {message}
    </div>
  )
}

export { LoadingCourses }
