import React from 'react'
import PropTypes from 'prop-types'
const ValidationContext = React.createContext()

function ValidationsProvider (props) {
  const { usePost } = props
  const { savePrices, isLoading, state, currentState, data, isError, error } = usePost()

  return (
    <ValidationContext.Provider
      value={{ savePrices, isLoading, state, currentState, data, isError, error }}
      {...props}
    />
  )
}

ValidationsProvider.propTypes = {
  usePost: PropTypes.func.isRequired
}

/**
 * useValidation
 * @returns {{ savePrices, isLoading, state, currentState, data, isError, error }} context
 */
function useValidation () {
  const context = React.useContext(ValidationContext)
  if (context === undefined) {
    throw new Error('useValidation must be used within a ValidationsProvider')
  }
  return context
}

export { ValidationsProvider, useValidation }
