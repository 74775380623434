import { useState } from "react";
import { useMutation } from "react-query";
import AgencyRepository from "../../../repositories/AgencyRepository";
import state from "../../../utils/state-control";

function usePostInactiveHeartInstitution() {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL);
  const mutation = useMutation(AgencyRepository.saveInactiveHeartInstitution, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS);
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR);
    },
    throwOnError: true,
  });

  /**
   * nextStep
   * @param step
   * @param data
   */
  const postInactiveHeartInstitution = (data) => {
    mutation.mutate(data);
  };

  return { ...mutation, state, currentState, postInactiveHeartInstitution };
}

export { usePostInactiveHeartInstitution };
