import { useEffect, useState } from 'react'
import { Table } from 'antd'
import { useGetLogs } from '../../../../../bussines/hooks/logs/useGetLogs'
import { GeneralPricesView } from './GeneralPricesView'
import { getEventLabel } from '../../../../../utils/logEvents'
import { BasePricesDiscountView } from './BasePricesDiscountView'
import { TransportPricesView } from './TransportPricesView'
import { LogsTableFilters } from './LogsTableFilters'
import { CoursePricesView } from './CoursePricesView'
import { AccommodationPricesView } from './AccommodationPricesView'
import { DiscountCourseValuesView } from './DiscountCourseValuesView'
import { DiscountFeeValuesView } from './DiscountFeeValuesView'
const { Column } = Table

const LogsTable = () => {
  const [filters, setFilters] = useState({ page: 1 })
  const [paginationData, setPaginationData] = useState({})
  const { data: logs, isLoading } = useGetLogs(filters)
  useEffect(() => {
    if (!isLoading && logs) {
      console.log('🚀 ~ file: LogsTable.js:7 ~ useEffect ~ logs:', logs)
      setPaginationData({
        current: logs?.current_page,
        size: logs?.last_page,
        total: logs?.total,
        pageSize: logs?.per_page,
        onChange: (val) => setFilters({ ...filters, page: val })
      })
    }
  }, [logs])
  return (
    <>
      <LogsTableFilters setFilters={setFilters} filters={filters} />
      <Table
        loading={isLoading}
        dataSource={logs?.data ?? []}
        className='byp-table-dashboard'
        title={() => 'QYO LOGS'}
        rowKey='id'
        pagination={paginationData}
      >
        <Column
          title='Alias' dataIndex='alias' key='alias' width='10%'
        />
        <Column
          title='Evento'
          key='event'
          render={(_, record) => {
            return <span>{getEventLabel(record.event)} </span>
          }}
          width='10%'
        />
        <Column
          title='Item'
          width='10%'
          render={(_, record) => {
            return <span>{record.logueable?.name}</span>
          }}
        />
        <Column
          title='Fecha'
          key='time'
          render={(_, record) => (
            <div>{record.time}</div>
          )}
          width='10%'
        />
        <Column
          title='Valores anteriores'
          key='old_values'
          responsive={['lg']}
          render={(_, record) => {
            if (['general-prices-aprove', 'general-prices-save', 'general-prices-approve'].includes(record.event)) {
              return <GeneralPricesView data={record.values} campus={record.logueable} />
            }
            if (['transport-prices-aprove', 'transport-prices-approve', 'transport-prices-save'].includes(record.event)) {
              return <TransportPricesView data={record.values} campus={record.logueable} />
            }
            if (['course-prices-aprove', 'course-prices-approve', 'course-prices-save'].includes(record.event)) {
              return <CoursePricesView data={record.values} campus={record.logueable} />
            }
            if (['lodging-prices-aprove', 'lodging-prices-approve', 'lodging-prices-save'].includes(record.event)) {
              return <AccommodationPricesView data={record.values} campus={record.logueable} />
            }
            if (['discounts-base-prices-aprove', 'discounts-base-prices-approve', 'discounts-base-prices-save'].includes(record.event)) {
              return <BasePricesDiscountView data={record.values} campus={record.logueable} />
            }
            if (['discounts-course-prices-aprove', 'discounts-course-prices-approve', 'discounts-course-prices-save'].includes(record.event)) {
              return <DiscountCourseValuesView data={record.values} campus={record.logueable} />
            }
            if (['discounts-transport-prices-aprove', 'discounts-transport-prices-approve', 'discounts-transport-prices-save'].includes(record.event)) {
              return <DiscountFeeValuesView data={record.values} campus={record.logueable} />
            }
            if (['discounts-lodging-prices-aprove', 'discounts-lodging-prices-approve', 'discounts-lodging-prices-save'].includes(record.event)) {
              return <DiscountFeeValuesView data={record.values} campus={record.logueable} />
            }
            return <TransportPricesView data={record.values} campus={record.logueable} showJson />
          }}
        />
      </Table>
    </>
  )
}

export { LogsTable }
