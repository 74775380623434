import { Button, Checkbox, Form, Input, Modal } from 'antd'
import { HeartFilled } from '@ant-design/icons'
import { useState } from 'react'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { SelectOtherUsersFormItem } from '../../../common/components/helper-form/SelectOtherUsersFormItem'
import { SelectBranchesAll } from '../../dashboards/agency/components/SelectBranchesAll'

const ModalAddToFavorites = ({ submit, opnMdl, clsMdl, setFavorite }) => {
  const [openStateModal, setOpenStateModal] = useState(false)
  const { user } = useAuth()
  const [offices, setOffices] = useState()

  return (
    <>
      <Button
        icon={<HeartFilled />}
        className='byp-btn-yellow-blue'
        onClick={() => setOpenStateModal(true)}
      >
        Favorites
      </Button>
      <Modal
        title='Publish quote'
        visible={openStateModal}
        footer=''
        onCancel={() => setOpenStateModal(false)}
        className='apply-modal'
      >
        <Form.Item
          name='favorite_name'
        >
          <Input />
        </Form.Item>
        {
          user?.role_names.includes('agency-office-manager') && (
            <>
              <h4>Assign the agent</h4>
              <p>Choose the agent from your office responsible for this quote</p>
              <Form.Item
                name='assign_myself'
                valuePropName='checked'
              >
                <Checkbox
                  className='checkbox-apply'
                >
                  Myself
                </Checkbox>
              </Form.Item>
              <p>Other person:</p>
              <SelectOtherUsersFormItem
                placeholder='-find user-'
                name='assign_others'
                mode='multiple'
              />
            </>
          )
        }
        {
          user?.role_names.includes('agency-manager') && (
            <>
              <Form.Item
                name='assign_myself'
                valuePropName='checked'
              >
                <Checkbox
                  className='checkbox-apply'
                >
                  Myself
                </Checkbox>
              </Form.Item>
              <h4>Assign the agent</h4>
              <p>Other office:</p>
              <SelectBranchesAll
                placeholder='-find user-'
                name='branches'
                mode='multiple'
                onChange={(ids) => {
                  setOffices(ids)
                }}
              />
              <p>Other person:</p>
              <SelectOtherUsersFormItem
                placeholder='-find user-'
                name='assign_others'
                filters={{ sedes: offices }}
                mode='multiple'
              />
            </>
          )
        }
        <Button
          className='byp-btn-blue-100'
          onClick={() => {
            setFavorite(true)
            submit()
            setOpenStateModal(false)
          }}
          // loading={isLoading}
          style={{ width: '100%' }}
        // disabled={isLoading}
        >
          {user?.role_names.includes('agency-office-manager') && 'Publish and assign'}
          {!user?.role_names.includes('agency-office-manager') && 'Publish'}
        </Button>
      </Modal>
    </>
  )
}

export { ModalAddToFavorites }
