import React from 'react'
import { QuoteCalculatorService } from '../../services/QuoteCalculatorService'
import { useFindOffer } from '../../bussines/hooks/quoter/useFindOffer'
import { Form } from 'antd'
import { formatQuoterForm } from '../../pages/quote-course/utils/formatQuoterForm'
import { usePostQuote } from '../../bussines/hooks/quoter/usePostQuote'
import { useAuth } from '../auth/auth-context-provider'
import { useGetBaseQuote } from '../../bussines/hooks/quote/useGetBaseQuote'
import { useGetMyExchangeValues } from '../../bussines/hooks/currencies/useGetMyExcahngeValues'
import { HighEducationQuoterService } from '../../services/HighEducationQuoterService'
import LottieCargando from '../../lotties/LottieCargando'

const SearchContext = React.createContext()
const dateFormat = 'DD MMMM, YYYY'
const dateFnsFormat = 'dd LLLL yyyy'

function QuoteCourseProvider (props) {
  const [form] = Form.useForm()
  const { user } = useAuth()

  const [formConfig, setFormConfig] = React.useState({})
  const [prices, setPrices] = React.useState()
  const [favorite, setFavorite] = React.useState(false)

  const { isLoading, data, refetch, error: offerError, isError: isOfferError } = useFindOffer(props?.courseId, props?.campusId, props?.mode ? { mode: props?.mode } : {})
  const { isLoading: isLoadingBaseQuote, data: baseQuote } = useGetBaseQuote(props?.quoteId)
  const { isLoading: isLoadingPost, isSuccess: isSuccessPost, create, isError: isErrorPost, data: dataQuote, error: errorPost } = usePostQuote()
  const { data: exchangeValues, isLoading: loadingExchangeValues } = useGetMyExchangeValues()

  if (!isLoading && offerError && isOfferError) {
    if (offerError?.response?.status === 401) {
      const exception = new Error()
      exception.name = 'CustomError'

      exception.response = {
        status: offerError?.response?.status,
        data: {
          detail: 'HTTP Error 401 unauthorized'
        }
      }

      throw exception
    }
    return (
      <div className='w-100 byp-centered' style={{ height: '75vh' }}>
        {offerError?.response?.data?.message}
      </div>
    )
  }

  let service
  if (data?.offer?.program?.name === 'language' || data?.offer?.program?.name === 'summer_camps') {
    service = new QuoteCalculatorService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  } else {
    service = new HighEducationQuoterService(data, formConfig, setFormConfig, user, exchangeValues?.exchanges, prices, setPrices)
  }

  const submit = () => {
    form.submit()
  }

  const handleFinishForm = (values) => {
    values.campus = props?.campusId
    values.year = props?.yearId
    values.favorite = favorite

    const format = formatQuoterForm(data?.offer?.id, values, service, data.offer?.program?.name)
    console.log('🚀 ~ file: quoter-course-context.js ~ line 39 ~ handleFinishForm ~ format', format)
    create(format)
  }

  if (isLoading) {
    return <LottieCargando />
  }

  return (
    <SearchContext.Provider
      value={{
        isLoadingOffer: isLoading,
        offerError,
        isOfferError,
        refetch,
        isErrorPost,
        errorPost,
        handleFinishForm,
        isLoadingPost,
        isSuccessPost,
        submit,
        form,
        course: data,
        prices,
        quote: dataQuote,
        service,
        formConfig,
        setFormConfig,
        setFavorite,
        isLoadingBaseQuote,
        baseQuote,
        exchangeValues,
        loadingExchangeValues,
        isPreview: props?.mode === 'preview',
        dateFormat,
        dateFnsFormat
      }}
      {...props}
    />
  )
}

function useQuoter () {
  const context = React.useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useQuoter must be used within a QuoteCourseProvider')
  }
  return context
}

export { QuoteCourseProvider, useQuoter }
