import * as React from "react"

export const SchoolsIcon = (props) => {
  return (
    <svg
      width={12}
      height={11}
      viewBox="0 0 12 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.25 4.833h-1.167v-3.5h.584V.167H1.333v1.166h.584v3.5H.75a.583.583 0 00-.583.584v5.25h11.666v-5.25a.583.583 0 00-.583-.584zM7.167 9.5V7.167H4.833V9.5h-1.75V1.333h5.834V9.5h-1.75z"
        fill="currentColor"
      />
      <path
        d="M4.25 2.5h1.167v1.167H4.25V2.5zm2.333 0H7.75v1.167H6.583V2.5zM4.25 4.833h1.167V6H4.25V4.833zm2.333 0H7.75V6H6.583V4.833z"
        fill="currentColor"
      />
    </svg>
  )
}