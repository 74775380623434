function CalculateLodgingCost (lodging, startDate, weeks, highSeasons) {
  let cost = 0
  cost = lodging?.cost * weeks ?? 0
  return cost
}

function CalculateLodgingExtraNightCost (lodging, startDate, nights, highSeasons) {
  let cost = 0
  cost = lodging?.extra_cost * nights ?? 0
  return cost
}

export { CalculateLodgingCost, CalculateLodgingExtraNightCost }
