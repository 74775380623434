import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useFindInvitation (id) {
  return useQuery(['useFindInvitation', id],
    () => UserRepository.findInvitation(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useFindInvitation }
