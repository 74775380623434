import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

function useGetStudyAreas (filters = {}) {
  const queryString = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetStudyAreas', queryString],
    () => CourseRepository.getStudyAreas(queryString), {
      retry: 2
    })
}

export { useGetStudyAreas }
