import React from 'react'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../providers/validation/ErrorBoundary'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import QuoteSummaryContent from './QuoteSummaryContent'

const URL_QUOTE_SUMMARY_PAGE = '/quote-summary/:id'

const QuoteSummaryPage = () => {
  const { id } = useParams()

  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_ANY
      ]}
    >
      <ErrorBoundary>
        <QuoteSummaryContent quoteUuid={id} />
      </ErrorBoundary>
    </PageProtected>
  )
}

export { URL_QUOTE_SUMMARY_PAGE, QuoteSummaryPage }
