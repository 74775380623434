import { PublicTheme } from '../../common/theme/PublicTheme'
import { HomeSectionJ } from '../home/components/homeSectionJ'
import { HomeSectionK } from '../home/components/homeSectionK'
import header from './../../common/assets/images/fotos-portada.png'
import { TextTermsAndConditions } from './components/TextTermsAndConditions'
import { InstitutionTermsAndConditions } from './components/InstitutionTermsAndConditions'

const URL_ABOUT_TERMS_AND_CONDITIONS_INSTITUTION_PAGE = '/about/terms_and_conditions/institution'

function AboutTermsAndConditionsInstitutionPage () {
  return (
    <PublicTheme>
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '12.5px' }}>
        <img src={header} className='image-about-responsive' alt='' />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly',  margin: '4vh 0px', fontSize: '15px' }}>
        <a href='/about/terms_and_conditions/institution' style={{ color: 'var(--blue-200)', fontWeight: 'bold' }}>Terms and conditions Institutions</a>
        <a href='/about/terms_and_conditions/agency' style={{ color: 'var(--blue-200)', fontWeight: 'bold' }}>Terms and conditions Agencies</a>
      </div>
      <InstitutionTermsAndConditions />
      <HomeSectionJ />
      <HomeSectionK />
    </PublicTheme>
  )
}

export { AboutTermsAndConditionsInstitutionPage, URL_ABOUT_TERMS_AND_CONDITIONS_INSTITUTION_PAGE }