import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from './img/error404.png'
import logoquote from '../../common/assets/images/byp_logo_div.png'
import { URL_QUOTER_PAGE } from '../quoter'

const PageNotFound = () => {
  return (
    <Row>
      <Col span={12} className='error-image'>
        <img src={logo} alt='404 error' />
      </Col>
      <Col span={12} className='error-content'>
        <img src={logoquote} alt='logo qyp' className='error__img' />
        <h1 className='byp-mt-1 byp-mb-1 byp-title-1 byp-fw-bold'>Page not found...</h1>
        <p className='byp-text-md'>Sorry, the page you are looking for doesn't exist or has been been moved.</p>
        <Link
          to={URL_QUOTER_PAGE}
        >
          <button className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' style={{ background: '#2460b7', color: '#f4f4f4', border: '1px solid #1890FF' }}>
            Go back
          </button>
        </Link>
      </Col>
    </Row>
  )
}

export { PageNotFound }