import React from 'react'
import { Avatar } from 'antd'
import { EmailIcon, FacebookIcon, InstagramIcon, LinkedinIcon, OtherSocialIcon, PhoneIcon, TwitterIcon, WhatsapIcon, YoutubeIcon } from '../../quote-preview/assets/imgs/Icons'

const getSocialNetworkIcon = (networkName, url) => {
  if (networkName === 'Facebook') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <FacebookIcon />
      </a>
    )
  } else if (networkName === 'Instagram') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <InstagramIcon />
      </a>
    )
  } else if (networkName === 'Youtube') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <YoutubeIcon />
      </a>
    )
  } else if (networkName === 'Twitter') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <TwitterIcon />
      </a>
    )
  } else if (networkName === 'Linkedin') {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <LinkedinIcon />
      </a>
    )
  } else {
    return (
      <a href={`http://${url}`} target='_blank' className='social-media' rel='noreferrer'>
        <OtherSocialIcon />
      </a>
    )
  }
}

const getSocialNetworks = (agencys) => {
  return agencys?.social_networks?.filter(network => {
    return network.show
  })
}

function FooterBlue({ agency }) {
  return (
    <div className='show-Footer-client-quotes'>
      <div style={{ display: 'flex', alignSelf: 'center', paddingLeft: '20px' }}>
        <Avatar src={agency?.profile_photo_url} size={94} shape='square'  />
      </div>
      <div style={{ width: 'auto', alignSelf: 'center', paddingLeft: '20px', display: 'flex' }}>

        <div style={{ paddingLeft: '10px' }}>
          <p style={{ marginBottom: 6, fontSize: '30px', color: 'white', position: 'relative', top: '15px', fontWeight: '400' }}>
            {agency?.name}
          </p>
          {agency?.website && (
            <p style={{ fontSize: '18px', color: 'white' }}>
              <a style={{ textDecoration: 'none', color: 'white' }} href={`http://${agency?.website}`} target='_blank' rel='noreferrer'>{agency?.website}</a>
            </p>
          )}
        </div>
      </div>
      <div className='footer-social-networks'>
        {
          agency?.email && (
            <a href={`mailto:${agency.email}`} target='_blank' className='social-media' rel='noreferrer'>
              <EmailIcon />
            </a>
          )
        }
        {
          agency?.whatsapp && (
            <a href={`tel:${agency.whatsapp}`} target='_blank' className='social-media' rel='noreferrer'>
              <PhoneIcon />
            </a>
          )
        }
        {
          agency?.whatsapp && (
            <a href={`https://wa.me/${agency.whatsapp}`} target='_blank' className='social-media' rel='noreferrer'>
              <WhatsapIcon />
            </a>
          )
        }
        {
          getSocialNetworks(agency)?.map(network => getSocialNetworkIcon(network.name, network.url))
        }
      </div>
      <div className='display-none' style={{ paddingTop: '33px', textAlign: 'end', width: 'auto', paddingRight: '30px' }}>
        <p style={{ marginBottom: 6, color: 'white', fontSize: '25px', fontWeight: '700' }}>
          Book now!!
        </p>
        <p style={{ color: 'white', fontSize: '16px', fontWeight: '400', position: 'relative', bottom: '10px' }}>
          Contact your agent and get ready for your next trip
        </p>
      </div>
    </div>
  )
}

export { FooterBlue }
