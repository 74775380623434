import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { FormCreateCourses } from './FormCreateCourses'
import { useParams, useSearchParams } from 'react-router-dom'
import TopBarMenu from '../components/TopBarMenu'

const URL_COURSE_CREATE_PAGE = '/dashboard/institution/create/courses/:program'

function CreateCoursePage () {
  const { program } = useParams()
  const [params] = useSearchParams()
  const type = params.get('type')
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <h4>
          <TopBarMenu program={program} type={type} key={'top_menu_' + program} />
        </h4>
        <FormCreateCourses program={program} type={type} />
      </MainTheme>
    </PageProtected>
  )
}

export { CreateCoursePage, URL_COURSE_CREATE_PAGE }
