import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetSlider (filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetAllCampus', filtersQuery],
    () => CampusRespository.getSlider(filtersQuery), {
      retry: 1
    })
}

export { useGetSlider }
