import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { LoginPage, URL_LOGIN_PAGE } from '../pages/login'
import { QuoterPage, URL_QUOTER_PAGE } from '../pages/quoter'
import { QuoterPageStatic, URL_QUOTER_STATIC_PAGE } from '../pages/quoter/quote2'
import history from './route-history'
import { RegisterPage, URL_REGISTER_PAGE_BETA } from '../pages/register'
import { PolicyPage, URL_POLICY_PAGE } from '../pages/register/PolicyPage'
import {
  AgencyStepByStepRegister,
  URL_STEP_BY_STEP_PAGE_AGENCY
} from '../pages/register/AgencyStepByStepRegister'
import {
  EditAgencyProfilePage,
  URL_EDIT_AGENCY
} from '../pages/agency/EditAgencyProfilePage'
import {
  EditInstitutionProfilePage,
  URL_EDIT_INSTITUTION
} from '../pages/institution/EditInstitutionProfilePage'
import {
  SendEmailToPartner,
  URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE
} from '../pages/register/SendEmailToPartner'
import { QuotePreview, URL_QUOTE_PREVIEW } from '../pages/quote-preview'
import {
  InstitutionStepByStepRegister,
  URL_STEP_BY_STEP_PAGE_COLLEGE
} from '../pages/register/InstitutionStepByStepRegister'
import { UserRegister, URL_USER_REGISTER } from '../pages/register/UserRegister'
import {
  InvitationRegister,
  URL_INVITATION_REGISTER_PAGE
} from '../pages/register/InvitationRegister'
import {
  ContinueRegister,
  URL_CONTINUE_REGISTRATION
} from '../pages/register/ContinueRegister'
import { DashboardPage, URL_DASHBOARD_PAGE } from '../pages/dashboards'
import {
  CreateSchoolsPage,
  URL_INSTITUTION_SCHOOLS_PAGE
} from '../pages/dashboards/Institution/Schools/CreateSchoolsPage'
import {
  CreateCoursePage,
  URL_COURSE_CREATE_PAGE
} from '../pages/dashboards/Institution/Courses/CreateCoursePage'
import {
  CreatePackagePage,
  URL_UPLOAD_PACKAGES_PAGE
} from '../pages/dashboards/agency/packages/CreatePackagePage'
import {
  CreatePricesPackagePage,
  URL_UPLOAD_PRICES_PACKAGES_PAGE
} from '../pages/dashboards/agency/packages/CreatePricesPackagePage'
import {
  GeneralAnalysisPage,
  URL_GENERAL_ANALYSIS_PAGE
} from '../pages/dashboards/agency/analitics/general/GeneralAnalysisPage'
import {
  UserAnalysisPage,
  URL_USER_ANALYSIS_PAGE
} from '../pages/dashboards/agency/analitics/user/UserAnalysisPage'

import { UploadPricesPage, URL_UPLOAD_PRICES_PAGE } from '../pages/dashboards/Institution/Prices/UploadPricesPage'
import { UploadTransportsPage, URL_UPLOAD_TRANSPORTS_PAGE } from '../pages/dashboards/Institution/Transports/UploadTransportsPage'
import { CreateLodgingsPage, URL_LODGING_CREATE_PAGE } from '../pages/dashboards/Institution/Lodging/CreateLodgingsPage'
import { CreateDiscountsPage, URL_DISCOUNTS_CREATE_PAGE } from '../pages/dashboards/Institution/Discounts/CreateDiscountsPage'
import { ActivateQuotesPage, URL_ACTIVATE_QUOTES_PAGE } from '../pages/dashboards/Institution/ActivationQuotes/ActivateQuotesPage'
import { EditUserProfilePage, URL_EDIT_USER } from '../pages/user/EditUserProfilePage'
import { CreateCoursePricesPage, URL_COURSEPRICES_CREATE_PAGE } from '../pages/dashboards/Institution/CoursePrices/CreateCoursePricesPage'
import { CreateLodgingPricesPage, URL_LODGINGPRICES_CREATE_PAGE } from '../pages/dashboards/Institution/LodgingPrices/CreateLodgingPricesPage'
import { CreateTransportPricesPage, URL_TRANSPORTGPRICES_CREATE_PAGE } from '../pages/dashboards/Institution/TransportPrices/CreateTransportPricesPage'
import { CustomDiscountsPage, URL_CUSTOMDISCOUNT_PAGE } from '../pages/dashboards/Institution/Discounts/CustomDiscountsPage'
import { ApproveQuotesPage, URL_APPROVE_QUOTES_PAGE } from '../pages/dashboards/Institution/ApproveQuotes/ApproveQuotesPage'
import { CustomQuotePage, URL_CUSTOM_QUOTE_PAGE } from '../pages/dashboards/Institution/Quote/CustomQuotePage'
import { MyQuotesPage, URL_MYQUOTES_PAGE } from '../pages/my-quotes'
import { UploadDocumentsPage, URL_UPLOAD_DOCUMENTS_PAGE } from '../pages/dashboards/Institution/Documents/UploadDocumentsPage'
import { CrudUserPage, URL_CREATE_USERS_PAGE, URL_CREATE_USERS_PAGE_ID } from '../pages/dashboards/users/CrudUserPage'
import { CrudOfficePage, URL_CREATE_OFFICES_PAGE } from '../pages/dashboards/offices/CrudOfficePage'
import { CurrenciesPage, URL_CURRENCIES_PAGE } from '../pages/dashboards/currencies'
import { MaterialsStatusPage, URL_MATERIALS_STATUS_PAGE } from '../pages/dashboards/Institution/MaterialsStatus/MaterialsStatusPage'
import { HomePage, URL_HOME_PAGE } from '../pages/home'
import { CreateInsurancePage, URL_CREATE_INSURANCE_PAGE } from '../pages/dashboards/agency/insurances/CreateInsurancePage'
import { InsurancePricesPage, URL_CREATE_INSURANCE_PRICES_PAGE } from '../pages/dashboards/agency/insurancePrices/InsurancePricesPage'
import { CreateAdditionalServices, URL_UPLOAD_ADD_SERVICES_PAGE } from '../pages/dashboards/agency/services/CreateAdditionalServices'
import { CreatePricesAdditionalServices, URL_UPLOAD_PRICES_ADD_SERVICES } from '../pages/dashboards/agency/services/CreatePricesAdditionalServices'
import { ServiceStatusPage, URL_SERVICES_STATUS_PAGE } from '../pages/dashboards/agency/service-status/ServiceStatusPage'
import { AproveInsurancePage, URL_APROVE_INSURANCE_PAGE } from '../pages/dashboards/agency/aprove/insurances/AproveInsurancePage'
import { AproveServicePage, URL_APROVE_SERVICE_PAGE } from '../pages/dashboards/agency/aprove/services/AproveServicePage'
// import {UpdateModality,URL_UPDATE_MODALITY} from '../bussines/repositories/UpdateModality.js'
import { ForgotPasswordPage, URL_FORGOT_PASSWORD_PAGE } from '../pages/password-recover/ForgotPassword'
import { ResetPasswordPage, URL_RESET_PASSWORD_PAGE } from '../pages/password-recover/ResetPassword'
import { PageNotFound } from '../pages/errors/PageNotFound'
import { AdionalServicePromotions, URL_ADD_SERVICE_PROMOTIONS_PAGE } from '../pages/dashboards/agency/services/AdionalServicePromotions'
import { ProgramAnaliticsPage, URL_PROGRAM_ANALITCIS_PAGE } from '../pages/dashboards/agency/analitics/program/ProgramAnaliticsPage'
import { OfficeAnaliticsPage, URL_OFFICE_ANALITCIS_PAGE } from '../pages/dashboards/agency/analitics/office/OfficeAnaliticsPage'
import { AboutPolicyPage, URL_ABOUT_POLICY_PAGE } from '../pages/about/PolicyPage'
import { AboutTermsAndConditionsPage, URL_ABOUT_TERMS_AND_CONDITIONS_PAGE } from '../pages/about/TermsAndConditionsPage'
import { PricingAndFAQ, URL_PRICING_AND_FAQ_PAGE } from '../pages/pricingandfaq/PricingAndFAQ'
import { AboutPage, URL_ABOUT_PAGE } from '../pages/about/AboutPage'
import { MiscellaneousPage, URL_CREATE_MISCELLANEOUS_PAGE } from '../pages/dashboards/agency/miscellaneous/MiscellaneousPage'
import { AboutTermsAndConditionsInstitutionPage, URL_ABOUT_TERMS_AND_CONDITIONS_INSTITUTION_PAGE } from '../pages/about/TermsAndConditionsPageAgency'
import { AboutTermsAndConditionsAgencyPage, URL_ABOUT_TERMS_AND_CONDITIONS_AGENCY_PAGE } from '../pages/about/TermsAndConditionsAgencyPage'
import { NewPage, URL_NEW_PAGE } from '../pages/about/NewPage'
import { VendorPage, URL_VENDOR_PAGE } from '../pages/about/VendorPage'
import { ReportsPage, URL_REPORTS_PAGE } from '../pages/dashboards/agency/reports/ReportsPage'
import { ClientPage, URL_CLIENT_PAGE } from '../pages/about/ClientPage'
import { ReportsUniPage, URL_REPORTS_UNI_PAGE } from '../pages/dashboards/agency/reports/reports-unificado/ReportsUniPage'
import { RedirectToClientPage, URL_CLIENT_REDIRECT_PAGE } from '../pages/about/RedirectToClientPage'
import { LoginABPage, URL_LOGIN_AB_PAGE } from '../pages/login-ab'
import { RegisterABPage, URL_REGISTER_AB_PAGE } from '../pages/register-ab'
import { RegisterABAgencyPage, URL_REGISTER_AGENCY_AB_PAGE } from '../pages/register-ab/RegisterABAgencyPage'
import { EduactionGroupStepByStepRegister, URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP } from '../pages/register/EduactionGroupStepByStepRegister'
import { PartnersPage, URL_PARTNERS_PAGE } from '../pages/partners'
import { InstitutionsForAgencyPage, URL_INSTITUTIONS_FOR_AGENCY } from '../pages/agency/InstitutionsForAgencyPage'
import { AgenciesAnaliticsPage, URL_USER_AGENCIES_PAGE } from '../pages/dashboards/Institution/analitics/AgenciesAnaliticsPage'
import { AgencysListPage, URL_AGENCYS_LIST_PAGE } from '../pages/institution/AgencysListPage'
import { EditGroupEducationProfilePage, URL_EDIT_GROUP_EDUCATION } from '../pages/group-education/EditGroupEducationProfilePage'
import { SchoolAnaliticsPage, URL_SCHOOL_ANALITCIS_PAGE } from '../pages/dashboards/agency/analitics/school/SchoolAnaliticsPage'
import { HomeSliderPage, URL_HOME_LANDER_PAGE } from '../pages/dashboards/home-slider/HomeSliderPage'
import { AllUsersPage, URL_USERS_PAGE } from '../pages/dashboards/users/AllUsersPage'
import { URL_SCHOOL_ANALITCIS_GROUP_EDUCATIVE_PAGE, SchoolEducativeGroupPage } from '../pages/dashboards/agency/analitics/school-educative-group/school-educative-group-page'
import { QuoterCoursePage, URL_QUOTE_COURSE_PAGE, URL_QUOTE_COURSE_PREVIEW_PAGE } from '../pages/quote-course/QuoterCoursePage'
import { URL_QUOTE_COURSE_PAGE_EDIT, QuoterCoursePageEdit } from '../pages/quote-course'
import { IntegrationsPage, URL_INTEGRATIONS_PAGE } from '../pages/integrations/IntegrationsPage'
import { CONVERT_CAMPUS_TO_INSTITUTION, ConvertCampusToInstitution } from '../Trash/ConvertCampusToInstitution'
import { TestPage, URL_TESTS_PAGE } from '../pages/test/TestPage'
import { Trends, URL_TRENDS_MASTER_PAGE } from '../pages/dashboards/charts/trends'
import { General, URL_GENERAL_MASTER_PAGE } from '../pages/dashboards/charts/general'
import { PackagesPage, URL_PACKAGES_PAGE } from '../pages/dashboards/packages/PackagesPage'
import { PackagesPricesPage, URL_PACKAGES_PRICES_PAGE } from '../pages/dashboards/packages/PackagesPricesPage'
import { ActivateQuotesAgencyPage, URL_ACTIVATE_PACKAGE_AGENCY_PAGE } from '../pages/dashboards/agency/ActivationPackage/ActivatePackagePage'
import { ApprovePackagePage, URL_APPROVE_PACKAGE_PAGE } from '../pages/dashboards/agency/aprove/package/AprovePackage'
import { PackageCoursePage, URL_PACKAGE_COURSE_PAGE, URL_PACKAGE_COURSE_PREVIEW_PAGE, URL_SHORT_COURSE_PAGE, URL_SHORT_COURSE_PREVIEW_PAGE } from '../pages/quote-course-package'
import { UploadPromotionsPage, URL_PACKAGES_PROMOTIONS_PAGE } from '../pages/dashboards/packages/UploadPromotionsPage'
import { DiscountsPackagesPage, URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE } from '../pages/dashboards/packages/DiscountsPackagesPage'
import { PromotionsRulesPage, URL_PACKAGES_PROMOTIONS_RULES_PAGE } from '../pages/dashboards/packagesPromotions/PromotionsRulesPage'
import { ShortTermPage, URL_SHORT_TERM_PAGE } from '../pages/dashboards/Institution/ShortTerm'
import { ShortTermPricesPage, URL_SHORT_TERM_PRICES_PAGE } from '../pages/dashboards/Institution/ShortTerm/ShortTermPricesPage'
import { UploadPromotionsPage as UploadPromotionsShortPage, URL_SHORT_TERM_PROMOTIONS_PAGE } from '../pages/dashboards/Institution/ShortTerm/UploadPromotionsPage'
import { ActivateQuotesShortPage, URL_ACTIVATE_SHORT_AGENCY_PAGE } from '../pages/dashboards/Institution/ActivationQuotes/ActivateShortPage'
import { ApproveShortPage, URL_APPROVE_SHORT_PAGE } from '../pages/dashboards/Institution/ApproveQuotes/ApproveShort'
import { TableCountryCitiesPage, URL_TABLE_COUNTRY_CITIES_PAGE } from '../pages/dashboards/tables-pages/TableCountryCitiesPage'
import { TableProgramPage, URL_TABLE_PROGRAMS_PAGE } from '../pages/dashboards/tables-pages/TableProgramsPage'
import { EditPageCountryPage, URL_EDIT_PAGE_CITY_PAGE, URL_EDIT_PAGE_COUNTRY_PAGE } from '../pages/dashboards/edit-page/EditPageCountryPage'
import { EditPageProgramPage, URL_EDIT_PAGE_PROGRAM_PAGE } from '../pages/dashboards/edit-page/EditPageProgramPage'
import { CourseExtraInfoPage, URL_COURSE_EXTRA_INFO_PAGE } from '../pages/dashboards/Institution/Extra/CourseExtraInfoPage'
import { AccomodationExtraInfoPage, URL_ACCOMODATION_EXTRA_INFO_PAGE } from '../pages/dashboards/Institution/Extra/AccomodationExtraInfoPage'
import { CampusExtraInfoPage, URL_CAMPUS_EXTRA_INFO_PAGE } from '../pages/dashboards/Institution/Extra/CampusExtraInfoPage'
import { LogsPage, URL_LOGS } from '../pages/dashboards/Institution/log'
import { QuotePreviewApply, URL_QUOTE_APPLY_PREVIEW } from '../pages/quote-apply-preview'
import { ApplyConfirmationPage, URL_CONFIRMATION_PAGE } from '../pages/confirmation/apartado'
import { AplicacionPage, URL_APLICACION_PAGE } from '../pages/aplicacion'
import { ApplicationConfirmation, URL_CONFIRMATION_PAGE2 } from '../pages/confirmation/apply'
import { MyCoursesPage, URL_USER_COURSES } from '../pages/user/MyCoursesPage'
import { QuoteSummaryPage, URL_QUOTE_SUMMARY_PAGE } from '../pages/quote-summary'
import { ConfirmationQuotePage, URL_CONFIRMATION_QUOTE_PAGE } from '../pages/confirmation-quote'

const PageRoutes = () => (
  <Router history={history}>
    <Routes>
      <Route path={URL_PACKAGES_PROMOTIONS_DISCOUNTS_PAGE} element={<DiscountsPackagesPage />} />
      <Route path={URL_PACKAGES_PAGE} element={<PackagesPage />} />
      <Route path={URL_PACKAGES_PROMOTIONS_RULES_PAGE} element={<PromotionsRulesPage />} />
      <Route path={URL_PACKAGES_PRICES_PAGE} element={<PackagesPricesPage />} />
      <Route path={URL_USER_AGENCIES_PAGE}>
        <Route path='agencies' element={<AgenciesAnaliticsPage />} />
        <Route path='agencies/:agencyId' element={<AgenciesAnaliticsPage />} />
      </Route>
      <Route path={URL_PACKAGES_PROMOTIONS_PAGE} element={<UploadPromotionsPage />} />
      <Route path={URL_HOME_LANDER_PAGE} element={<HomeSliderPage />} />
      <Route path={URL_SCHOOL_ANALITCIS_GROUP_EDUCATIVE_PAGE} element={<SchoolEducativeGroupPage />} />
      <Route path={URL_AGENCYS_LIST_PAGE} element={<AgencysListPage />} />

      <Route path={URL_INSTITUTIONS_FOR_AGENCY} element={<InstitutionsForAgencyPage />} />
      <Route path={URL_LOGIN_PAGE} element={<LoginPage />} />
      <Route path={URL_LOGIN_AB_PAGE} element={<LoginABPage />} />

      <Route path={URL_QUOTER_PAGE} element={<QuoterPage />} />
      <Route path={URL_QUOTER_STATIC_PAGE} element={<QuoterPageStatic />} />
      <Route path={URL_QUOTE_COURSE_PAGE} element={<QuoterCoursePage />} />
      <Route path={URL_QUOTE_COURSE_PREVIEW_PAGE} element={<QuoterCoursePage />} />
      <Route path={URL_QUOTE_APPLY_PREVIEW} element={<QuotePreviewApply />} />
      <Route path={URL_CONFIRMATION_PAGE} element={<ApplyConfirmationPage />} />
      <Route path={URL_APLICACION_PAGE} element={<AplicacionPage />} />
      <Route path={URL_CONFIRMATION_PAGE2} element={<ApplicationConfirmation />} />
      <Route path={URL_QUOTE_SUMMARY_PAGE} element={<QuoteSummaryPage />} />
      <Route path={URL_CONFIRMATION_QUOTE_PAGE} element={<ConfirmationQuotePage />} />

      <Route path={URL_USER_COURSES} element={<MyCoursesPage />} />

      <Route path={URL_PACKAGE_COURSE_PAGE} element={<PackageCoursePage />} />
      <Route path={URL_PACKAGE_COURSE_PREVIEW_PAGE} element={<PackageCoursePage />} />
      <Route path={URL_SHORT_COURSE_PAGE} element={<PackageCoursePage />} />
      <Route path={URL_SHORT_COURSE_PREVIEW_PAGE} element={<PackageCoursePage />} />
      <Route path={URL_QUOTE_COURSE_PAGE_EDIT} element={<QuoterCoursePageEdit />} />
      <Route path={URL_REGISTER_PAGE_BETA} element={<RegisterPage />} />
      <Route path={URL_REGISTER_AB_PAGE} element={<RegisterABPage />} />
      <Route path={URL_REGISTER_AGENCY_AB_PAGE} element={<RegisterABAgencyPage />} />
      <Route path={URL_POLICY_PAGE} element={<PolicyPage />} />
      <Route path={URL_ABOUT_TERMS_AND_CONDITIONS_INSTITUTION_PAGE} element={<AboutTermsAndConditionsInstitutionPage />} />
      <Route path={URL_ABOUT_TERMS_AND_CONDITIONS_AGENCY_PAGE} element={<AboutTermsAndConditionsAgencyPage />} />
      <Route path={URL_VENDOR_PAGE} element={<VendorPage />} />
      <Route path={URL_CLIENT_PAGE} element={<ClientPage />} />
      <Route path={URL_CLIENT_REDIRECT_PAGE} element={<RedirectToClientPage />} />
      <Route path={URL_SHORT_TERM_PAGE} element={<ShortTermPage />} />
      <Route path={URL_SHORT_TERM_PRICES_PAGE} element={<ShortTermPricesPage />} />
      <Route path={URL_SHORT_TERM_PROMOTIONS_PAGE} element={<UploadPromotionsShortPage />} />
      <Route path={URL_ACTIVATE_SHORT_AGENCY_PAGE} element={<ActivateQuotesShortPage />} />
      <Route
        path={URL_STEP_BY_STEP_PAGE_AGENCY}
        element={<AgencyStepByStepRegister />}
      />
      <Route path={URL_NEW_PAGE} element={<NewPage />} />
      <Route
        path={URL_EDIT_AGENCY}
        element={<EditAgencyProfilePage />}
      />
      <Route
        path={URL_EDIT_INSTITUTION}
        element={<EditInstitutionProfilePage />}
      />
      <Route
        path={URL_EDIT_GROUP_EDUCATION}
        element={<EditGroupEducationProfilePage />}
      />
      <Route
        path={URL_STEP_BY_STEP_PAGE_COLLEGE}
        element={<InstitutionStepByStepRegister />}
      />
      <Route
        path={URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP}
        element={<EduactionGroupStepByStepRegister />}
      />
      <Route
        path={URL_SHARE_ACCOUNT_WITH_PARTNER_PAGE}
        element={<SendEmailToPartner />}
      />
      <Route path={URL_QUOTE_PREVIEW} element={<QuotePreview />} />
      <Route path={URL_USER_REGISTER} element={<UserRegister />} />
      <Route path={URL_EDIT_USER} element={<EditUserProfilePage />} />
      <Route
        path={URL_INVITATION_REGISTER_PAGE}
        element={<InvitationRegister />}
      />
      <Route path={URL_CONTINUE_REGISTRATION} element={<ContinueRegister />} />

      <Route path={URL_DASHBOARD_PAGE} element={<DashboardPage />} />
      <Route path={URL_INSTITUTION_SCHOOLS_PAGE} element={<CreateSchoolsPage />} />
      <Route path={URL_CAMPUS_EXTRA_INFO_PAGE} element={<CampusExtraInfoPage />} />

      <Route path={URL_UPLOAD_PRICES_PAGE} element={<UploadPricesPage />} />

      <Route path={URL_COURSE_CREATE_PAGE} element={<CreateCoursePage />} />
      <Route path={URL_COURSEPRICES_CREATE_PAGE} element={<CreateCoursePricesPage />} />
      <Route path={URL_COURSE_EXTRA_INFO_PAGE} element={<CourseExtraInfoPage />} />

      <Route path={URL_LODGING_CREATE_PAGE} element={<CreateLodgingsPage />} />
      <Route path={URL_LODGINGPRICES_CREATE_PAGE} element={<CreateLodgingPricesPage />} />
      <Route path={URL_ACCOMODATION_EXTRA_INFO_PAGE} element={<AccomodationExtraInfoPage />} />

      <Route path={URL_UPLOAD_TRANSPORTS_PAGE} element={<UploadTransportsPage />} />
      <Route path={URL_TRANSPORTGPRICES_CREATE_PAGE} element={<CreateTransportPricesPage />} />
      <Route path={URL_DISCOUNTS_CREATE_PAGE} element={<CreateDiscountsPage />} />
      <Route path={URL_CUSTOMDISCOUNT_PAGE} element={<CustomDiscountsPage />} />
      <Route path={URL_CUSTOM_QUOTE_PAGE} element={<CustomQuotePage />} />
      <Route path={URL_UPLOAD_DOCUMENTS_PAGE} element={<UploadDocumentsPage />} />

      <Route path={URL_CREATE_USERS_PAGE} element={<CrudUserPage />} />
      <Route path={URL_CREATE_USERS_PAGE_ID} element={<CrudUserPage />} />
      <Route path={URL_USERS_PAGE} element={<AllUsersPage />} />
      <Route path={URL_CREATE_OFFICES_PAGE} element={<CrudOfficePage />} />
      <Route path={URL_CURRENCIES_PAGE} element={<CurrenciesPage />} />
      <Route path={URL_MATERIALS_STATUS_PAGE} element={<MaterialsStatusPage />} />

      <Route path={URL_UPLOAD_PACKAGES_PAGE} element={<CreatePackagePage />} />
      <Route path={URL_UPLOAD_PRICES_PACKAGES_PAGE} element={<CreatePricesPackagePage />} />

      <Route path={URL_UPLOAD_ADD_SERVICES_PAGE} element={<CreateAdditionalServices />} />
      <Route path={URL_UPLOAD_PRICES_ADD_SERVICES} element={<CreatePricesAdditionalServices />} />

      <Route path={URL_GENERAL_ANALYSIS_PAGE} element={<GeneralAnalysisPage />} />
      <Route path={URL_USER_ANALYSIS_PAGE} element={<UserAnalysisPage />} />
      <Route path={URL_PROGRAM_ANALITCIS_PAGE} element={<ProgramAnaliticsPage />} />
      <Route path={URL_OFFICE_ANALITCIS_PAGE} element={<OfficeAnaliticsPage />} />
      <Route path={URL_SCHOOL_ANALITCIS_PAGE} element={<SchoolAnaliticsPage />} />

      <Route path={URL_ACTIVATE_QUOTES_PAGE} element={<ActivateQuotesPage />} />
      <Route path={URL_ACTIVATE_PACKAGE_AGENCY_PAGE} element={<ActivateQuotesAgencyPage />} />
      <Route path={URL_APPROVE_QUOTES_PAGE} element={<ApproveQuotesPage />} />
      <Route path={URL_APPROVE_PACKAGE_PAGE} element={<ApprovePackagePage />} />
      <Route path={URL_APPROVE_SHORT_PAGE} element={<ApproveShortPage />} />
      <Route path={URL_MYQUOTES_PAGE} element={<MyQuotesPage />} />

      <Route path={URL_CREATE_INSURANCE_PAGE} element={<CreateInsurancePage />} />
      <Route path={URL_CREATE_INSURANCE_PRICES_PAGE} element={<InsurancePricesPage />} />
      <Route path={URL_SERVICES_STATUS_PAGE} element={<ServiceStatusPage />} />
      <Route path={URL_ADD_SERVICE_PROMOTIONS_PAGE} element={<AdionalServicePromotions />} />
      <Route path={URL_CREATE_MISCELLANEOUS_PAGE} element={<MiscellaneousPage />} />

      <Route path={URL_APROVE_INSURANCE_PAGE} element={<AproveInsurancePage />} />
      <Route path={URL_APROVE_SERVICE_PAGE} element={<AproveServicePage />} />

      {/* <Route path={URL_UPDATE_MODALITY} element={<UpdateModality />} /> */}
      {/* <Route path={URL_QUOTE_LANG_PAGE} element={<CourseLang />} /> */}

      <Route path={URL_FORGOT_PASSWORD_PAGE} element={<ForgotPasswordPage />} />
      <Route path={URL_RESET_PASSWORD_PAGE} element={<ResetPasswordPage />} />

      <Route path={URL_ABOUT_PAGE} element={<AboutPage />} />
      <Route path={URL_ABOUT_POLICY_PAGE} element={<AboutPolicyPage />} />
      <Route path={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE} element={<AboutTermsAndConditionsPage />} />
      <Route path={URL_PRICING_AND_FAQ_PAGE} element={<PricingAndFAQ />} />
      <Route path={URL_REPORTS_PAGE} element={<ReportsPage />} />
      <Route path={URL_REPORTS_UNI_PAGE} element={<ReportsUniPage />} />
      <Route path={URL_PARTNERS_PAGE} element={<PartnersPage />} />
      <Route path={URL_INTEGRATIONS_PAGE} element={<IntegrationsPage />} />
      <Route path={CONVERT_CAMPUS_TO_INSTITUTION} element={<ConvertCampusToInstitution />} />

      {/* tables pages */}
      <Route path={URL_TABLE_COUNTRY_CITIES_PAGE} element={<TableCountryCitiesPage />} />
      <Route path={URL_TABLE_PROGRAMS_PAGE} element={<TableProgramPage />} />

      {/* Edit pages */}
      <Route path={URL_EDIT_PAGE_COUNTRY_PAGE} element={<EditPageCountryPage />} />
      <Route path={URL_EDIT_PAGE_CITY_PAGE} element={<EditPageCountryPage />} />
      <Route path={URL_EDIT_PAGE_PROGRAM_PAGE} element={<EditPageProgramPage />} />

      {/* super admin charts */}
      <Route path={URL_TRENDS_MASTER_PAGE} element={<Trends />} />
      <Route path={URL_GENERAL_MASTER_PAGE} element={<General />} />

      {/* Test pages */}
      <Route path={URL_TESTS_PAGE} element={<TestPage />} />
      <Route path={URL_LOGS} element={<LogsPage />} />

      {/* <Route path='/' element={<QuoterPage />} /> */}
      <Route path={URL_HOME_PAGE} element={<HomePage />} />

      <Route path='*' element={<PageNotFound />} />
    </Routes>
  </Router>
)

export { PageRoutes }
