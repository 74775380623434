import { Button, Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import TimeFilters from '../../agency/analitics/TimeFilters'
import { IconExcel } from '../../../../common/assets/svg/icons'
import { TotalsGeneral } from '../components/TotalsGeneral'
import { ChartDoughnut } from '../components/ChartDoughnut'
import { TrendsGeneral } from '../components/TrendsGeneral'
import { Link } from 'react-router-dom'
import { URL_TRENDS_MASTER_PAGE } from '../trends'
import { ChartTotalQuotes } from '../components/ChartTotalQuotes'
import { useGetTotalQYPQuotes } from '../../../../bussines/hooks/admin/useGetTotalQYPQuotes'
import { BasicSpinner } from '../../../../common/components/BasicSpinner'

const GeneralChartInfo = () => {
  const [time, setTime] = useState({})
  const { data, isLoading } = useGetTotalQYPQuotes(time)
  const [typeCompany, setTypeCompany] = useState()

  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: GeneralChartInfo.js:19 ~ useEffect ~ data:', data?.enterprises)
    }
  }, [isLoading])

  return (
    <Row className='byp-dashboard-content'>
      <Col span={24}>
        <Row>
          <Col span={24} className='byp-mb-1'>
            <h3 className='byp-title'>General analysis</h3>
          </Col>

          <Col xs={24} sm={24} lg={12}>
            <TimeFilters setTime={setTime} />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Row justify={window.innerWidth >= 992 ? 'end' : 'start'} className={window.innerWidth >= 992 ? '' : 'byp-mt-1'}>
              <Col>
                <Button
                  style={{
                    backgroundColor: '#CDDFFF',
                    border: 'none',
                    color: '#2C2F66',
                    fontWeight: 'bold'
                  }}
                >
                  <IconExcel />
                  <span
                    style={{ marginLeft: '7px' }}
                    className='align-icon-btn'
                  >
                    Download Excel
                  </span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={24} className='byp-mt-1 byp-mb-1 border-bottom__blue'>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Totals</h4>
            {!isLoading && <TotalsGeneral time={time} data={data?.enterprises ?? []} />}
            {isLoading && <BasicSpinner />}
          </Col>
        </Row>

        <Row gutter={[33, 33]}>
          <Col xs={24} sm={24} lg={7}>
            <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Summarized results</h4>
            {!isLoading && <ChartDoughnut data={data?.enterprises} setTypeCompany={setTypeCompany} />}
            {isLoading && <BasicSpinner />}
          </Col>
          <Col xs={24} sm={24} lg={17}>
            <Row className='byp-mb-1'>
              <Col span={12}>
                <h4 className='byp-title-4 byp-black byp-fw-bold'>Trends</h4>
              </Col>
              <Col span={12}>
                <Row justify='end'>
                  <Col>
                    <Link to={URL_TRENDS_MASTER_PAGE} state={{ menuItemSelected: 'comparative-results-superadmin' }}>
                      <Button className='general__see-more'>See more</Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            {isLoading && <BasicSpinner />}
            {!isLoading && (
              <TrendsGeneral
                data={data?.programs}
                dataByCompany={data?.enterprises}
                typeCompany={typeCompany}
              />
            )}
            {isLoading && <BasicSpinner />}
            {!isLoading && (
              <ChartTotalQuotes
                data={data?.programs_history}
                dataByCompany={data?.enterprises}
                typeCompany={typeCompany}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { GeneralChartInfo }
