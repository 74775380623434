import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'
import removeInvalidValuesFromParams from '../../../../../utils/removeInvalidValuesFromParams'

function useGetQuoteDetailsByUser (user, filters = {}) {
  const validParams = removeInvalidValuesFromParams(filters)
  const filtersQuery = Object.keys(validParams).map((key) => key + '=' + validParams[key]).join('&')
  return useQuery(
    ['useGetQuoteDetailsByUser', filtersQuery],
    () => GeneralAnalysisRepository.getQuoteDetailsByUser(user, filtersQuery),
    {
      enabled: !!user
    }
  )
}

function useGetQuoteDetails (filters = {}) {
  const validParams = removeInvalidValuesFromParams(filters)
  const filtersQuery = Object.keys(validParams).map((key) => key + '=' + validParams[key]).join('&')
  return useQuery(
    ['useGetQuoteDetails', filtersQuery],
    () => GeneralAnalysisRepository.getQuoteDetails(filtersQuery),
    {
      enabled: !!filters.office || !!filters.user
    }
  )
}

function useGetQuoteDetailsReports (filters = {}) {
  const validParams = removeInvalidValuesFromParams(filters)
  return useQuery(
    ['useGetQuoteDetailsReports', validParams],
    () => GeneralAnalysisRepository.getQuoteDetailsReports(validParams)
  )
}

export { useGetQuoteDetails, useGetQuoteDetailsByUser, useGetQuoteDetailsReports }
