import { Form, Select } from 'antd'
const { Option } = Select
const SelectTimeFrequencyFormItem = ({ name, frequencies = { all: true }, label, ...props }) => {
  return (
    <Form.Item
      name={name}
      label={label}
    >
      <Select
        className='w-100'
        placeholder='Select how many times'
        size='small'
        {...props}
      >
        {(frequencies?.all || frequencies?.unic) && <Option key='unic'>Only time</Option>}
        {(frequencies?.all || frequencies?.day) && <Option key='day'>Daily</Option>}
        {(frequencies?.all || frequencies?.week) && <Option key='weeks'>Weekly</Option>}
        {(frequencies?.all || frequencies?.month) && <Option key='monthly'>Monthly</Option>}
        {(frequencies?.all || frequencies?.months) && <Option key='every 4 months'>Every 4 months</Option>}
        {(frequencies?.all || frequencies?.semester) && <Option key='semester'>Semester</Option>}
        {(frequencies?.all || frequencies?.year) && <Option key='anual'>Anual</Option>}
      </Select>
    </Form.Item>
  )
}

export { SelectTimeFrequencyFormItem }
