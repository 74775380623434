import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useGetBlockPrices (block, discount, year, campus, course) {
  return useQuery(['useGetBlockPrices', block, discount, year, campus, course],
    () => DiscountRepository.getBlockPrices(block, discount, year, campus, course), {
      retry: 1,
      enabled: !!block && !!discount && !!year && !!campus && (block !== 'curso' || (block === 'curso' && !!course))
    })
}

export { useGetBlockPrices }
