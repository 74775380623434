import React from 'react'
import Lottie from 'react-lottie'
import animationData from './loading-qyp.json'

function LottieCargando () {
  const defaultOptions = {
    animationData
  }

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height='95vh'
        width={550}
      />
    </div>
  )
}

export default LottieCargando
