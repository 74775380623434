import { Button, Col, Form, Input, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useGetCourseForPublish } from '../../../../bussines/hooks/school/course/useGetCourseForPublish'
import { usePostPublishCourse } from '../../../../bussines/hooks/school/course/usePostPublishCourse'
import { useGetCampus } from '../../../../bussines/hooks/school/useGetCampus'
import { QuoteInfo } from './QuoteInfo'
import utilsState from '../../../../bussines/utils/state-control'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { CompletedCampusCourseYear } from '../../../../common/components/helper-form/CompletedCampusCourseYear'

function QuoteInfoContainer ({ category }) {
  const navigate = useNavigate()
  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isThereError, setError] = useState(false)
  const [finish, isFinished] = useState(false)
  const [quoterUrl, setQuoterUrl] = useState()

  const { campusId: sedeId, courseId: serviceId, yearId: yearID } = location.state ? location.state : JSON.parse(localStorage.getItem('campus')) ? JSON.parse(localStorage.getItem('campus')) : { campusId: undefined, courseId: undefined, yearId: undefined }
  const [form] = Form.useForm()
  const [modal, context] = Modal.useModal()

  const [campusId, setCampusId] = useState(sedeId)
  const [courseID, setCourseId] = useState(serviceId)
  const [yearId, setYearId] = useState(yearID)
  const [activeKeys, setActiveKeys] = useState(['base_prices'])
  const { data: course, isLoading, refetch } = useGetCourseForPublish(campusId, courseID, yearId)

  const { data: campus } = useGetCampus(campusId)

  const { publishCourse, isLoading: isPublishing, currentState } = usePostPublishCourse()

  function VerifyMistake() {
    if (form.getFieldValue('user2')) {
      if (form.getFieldValue('user2').trim().length === 0) {
        Error()
      } else {
        isFinished(true)
        publishCourse(campusId, courseID, yearId, form.getFieldValue('user2'))
        setIsModalOpen(false)
        NoError()
      }
    } else {
      Error()
    }
  }

  useEffect(() => {
    if (!isLoading && course) {
      const keys = ['course_prices']
      course?.lodgings?.map((lodging) => (
        lodging.status !== 'Aproved' ? keys.push(lodging.id) : null
      ))
      course?.transports?.map((transport) => (
        transport.status !== 'Aproved' ? keys.push(transport.id) : null
      ))
      setActiveKeys(keys)
      const url = `/quote-course/${courseID}/${campusId}`
      if (course.status === 'Published') {
        setQuoterUrl(url)
      } else {
        setQuoterUrl(url + '/preview')
      }
    }
  }, [isLoading, course])

  useEffect(() => {
    if (!isPublishing && currentState === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [isPublishing, currentState])

  const onUpdate = ({ campus_id: campusID, course_id: courseID, year_id: yearID }) => {
    if (campusID !== undefined) {
      setCampusId(campusID)
      form.setFieldsValue({ course_id: undefined, year_id: undefined })
      setCourseId()
      setYearId()
    }
    if (courseID !== undefined) setCourseId(courseID)
    if (yearID !== undefined) setYearId(yearID)
  }

  const onClick = (url, state) => {
    navigate(url, {
      state: {
        campusId,
        // programId: course?.program_id,
        yearId,
        ...state
      }
    })
  }

  function ShowMessage () {
    if (isThereError) {
      return (
        <div style={{ color: 'red' }}>
          *Insert a name
        </div>
      )
    }
  }

  const Error = () => {
    setError(true)
  }

  const NoError = () => {
    setError(false)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    NoError()
  }

  if (isLoading == false) {

    return (
      <div style={{ width: '100%' }}>
        <CompletedCampusCourseYear course={course} campus={campus?.name} program={course?.program.label} />
        <Form
          onFinish={finish}
          form={form}
          onValuesChange={onUpdate}
          className='w-100'
          initialValues={{
            campus_id: campusId,
            course_id: courseID,
            year_id: yearId
          }}
        >

          {context}
          <Row gutter={24}>
            <Col xs={24} md={18} lg={20}>

            </Col>
          </Row>

          {course
            ? (
              <Row>
                <Col>
                  <p style={{
                    color: '#000000',
                    lineHeight: '22px',
                    fontSize: '14px',
                    fontWeight: '700'
                  }}
                  >
                    Confirm that every block is correct before publishing your quote
                  </p>
                </Col>
              </Row>
            )
            : null}
          {
            course
              ? <QuoteInfo
                onClick={onClick}
                course={course}
                divisa={campus?.divisa_code}
                activeKeys={activeKeys}
                setActiveKeys={setActiveKeys}
                category={category}
              />
              : null
          }
          {
            course
              ? (
                <div className='w-100' style={{ padding: '1rem' }}>

                  {/* <Button
                    loading={isPublishing}
                    onClick={showModal}
                    className='float-right byp-ml-1 byp-btn-blue-100'
                  >
                    {course.status === 'Published' ? 'Cancel publication' : 'Publish'}
                  </Button> */}
                  <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} visible={isModalOpen} closable={false}
                    footer={''} width='400px'>
                    <Form.Item name='user2' label='Insert your name'>
                      <Input />
                    </Form.Item>
                    <ShowMessage />
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '10px' }}>
                      <Button onClick={handleCancel} style={{ fontWeight: 'bold' }}>
                        Cancel
                      </Button>
                      <Button onClick={VerifyMistake} style={{ background: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}>
                        Confirm
                      </Button>
                    </div>
                  </Modal>
                  <Link
                    target='_blank'
                    to={{
                      pathname: quoterUrl,
                      state: {
                        campusId,
                        programId: course?.program_id,
                        yearId
                      }
                    }}
                  >
                    <Button
                      className='byp-btn-blue-200 float-right'
                    >
                      Preview
                    </Button>
                  </Link>
                  {/* <Button
                className='float-right byp-ml-1'
                style={{ border: '1px solid #2460B7', color: '#2460B7', marginRight: '8px', background: '#F4F4F4', fontWeight: '700' }}
                onClick={() => navigate(-1)}

              >
                Return
              </Button> */}
                </div>
              )
              : null
          }
        </Form>
      </div>
    )
  }
}

export { QuoteInfoContainer }
