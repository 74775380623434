import { Button, Col, Form, Row, Select } from 'antd'
import { useState, useEffect } from 'react'
import { SelectInstitutions } from '../../../../../common/components/helper-form/SelectInstitutions'
import { SelectSchoolProgramsFormItem } from '../../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
const { Option } = Select
const ServiceStatusTableFilters = ({ searchFilter, setSearchFilter, data }) => {
  const [form] = Form.useForm()
  const [nSearchFilter, setNSearchFilter] = useState(searchFilter)
  const [allYears, setAllYears] = useState([])
  const [QType, setQType] = useState()

  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...nSearchFilter,
      [key]: value
    }
    if (key === 'type') setQType(value)
    setNSearchFilter(nFilters)
    console.log('🚀 ~ file: ServiceStatusTableFilters.js:20 ~ handleAddFilter ~ value', value)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...nSearchFilter }
    delete nFilters[key]
    setNSearchFilter(nFilters)
  }

  const handleSubmit = (data) => {
    console.log('🚀 ~ file: ServiceStatusTableFilters.js:22 ~ hnaldeSubmit ~ data', data)
    setSearchFilter(data)
  }

  const handleClear = () => {
    setNSearchFilter({})
    setSearchFilter({})
    form.resetFields()
  }

  useEffect(() => {
    if (data) {
      const years = data?.map(fee => {
        return {
          year: fee.year
        }
      })
      const set = new Set(years?.map(JSON.stringify))
      const arr = Array.from(set)?.map(JSON.parse)

      const arrSinDuplicaciones = arr.filter(elements => {
        return elements.year !== null
      })

      console.log(arrSinDuplicaciones)
      if (years) setAllYears([...arrSinDuplicaciones])
    }
  }, [data])

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={6} className='w-100 content-quoter byp-mb-1'>
        <Col xs={24} md={6} lg={4}>
          <Form.Item
            name='type'
            label='Quote type'
          >
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(e) => {
                e
                  ? handleAddFilter('type', e)
                  : handleRemoveFilter('type')
              }}
              className='w-100'
              placeholder='All'
              size='small'
            >
              <Option key='all_service_types' value={null}>All</Option>
              <Option key='insurance_type' value='insurance'>Insurance</Option>
              <Option key='service_type' value='service'>Service</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={4}>
          <SelectSchoolProgramsFormItem
            name='program'
            onChange={(e) => {
              e
                ? handleAddFilter('type', e)
                : handleRemoveFilter('type')
            }}
            showAll
            size='small'
            label='Program'
            placeholder='All'
          />
        </Col>
        <Col xs={24} md={6} lg={4}>
          <SelectInstitutions
            name='school'
            label='Institution'
            placeholder='All'
            onChange={(e) => {
              e
                ? handleAddFilter('school', e)
                : handleRemoveFilter('school')
            }}
            showAll
            size='small'
          />
        </Col>
        <Col xs={24} md={6} lg={4}>
          <Form.Item
            name='status'
            label='Status'
          >
            <Select
              size='small'
              onChange={(e) => {
                e
                  ? handleAddFilter('status', e)
                  : handleRemoveFilter('status')
              }}
              placeholder='All'
              className='w-100'
            >
              <Option key={0} value={null}>All status</Option>
              <Option key={1} value='Aproved'>Aproved</Option>
              <Option key={2} value='Pending'>Pending</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={6} lg={4}>
          {QType === 'insurance' && (
            <Form.Item
              name='year'
              label='Year'
            >
              <Select
                size='small'
                onChange={(e) => {
                  e
                    ? handleAddFilter('year', e)
                    : handleRemoveFilter('year')
                }}
                placeholder='All'
                className='w-100'
              >
                <Option key='all_service_years' value={null}>All</Option>
                {allYears?.map((item) => <Option key={item.year} value={item.year}>{item.year}</Option>)}
              </Select>
            </Form.Item>)}
        </Col>
        <Col xs={24} md={6} lg={2}>
          <Button
            onClick={handleClear}
            className='byp-bg-gray50 byp-color-gray-100 byp-fw-bold'
          >
            Clear
          </Button>
        </Col>
        <Col xs={24} md={6} lg={2}>
          <Button
            htmlType='submit'
            // size='small'
            className='byp-btn-blue-100'
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { ServiceStatusTableFilters }
