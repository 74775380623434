import React, { useState } from 'react'
import { StepBaseTemplate } from './components/StepBaseTemplate'
import { Col, Row } from 'antd'
import { TitleSection } from '../../common/components/TitleSection'
// import { TextPolicy } from './components/texts/TextPolicy'
import { ButtonAcceptTermsAndConditions } from './components/texts/policy/ButtonAcceptTermsAndConditions'
import { TextConfirmationRegister } from './components/texts/TextConfirmationRegister'
import { useLocation } from 'react-router-dom'
import { TextTermsAndConditions } from '../about/components/TextTermsAndConditions'

const URL_POLICY_PAGE = '/policy'

function PolicyPage () {
  const [register, setRegister] = useState(false)
  const { state: locationState } = useLocation()
  return (
    <div>
      <StepBaseTemplate
        footer={
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <ButtonAcceptTermsAndConditions successRegister={setRegister} />
            </Col>
          </Row>
        }
      >
        {!register
          ? <div style={{ paddingLeft: '24px', paddingRight: '24px' }}><TitleSection label='Terms And Conditions' /></div>
          : null}
        {register
          ? <TextConfirmationRegister />
          : <TextTermsAndConditions type={locationState?.type} />}
      </StepBaseTemplate>
    </div>
  )
}

export { PolicyPage, URL_POLICY_PAGE }
