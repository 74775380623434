import { Col, Divider, Row, notification } from 'antd'
import { usePostActivateInstitution } from '../../../bussines/hooks/education-group/usePostActivateInstitution'
import stateControl from '../../../bussines/utils/state-control'
import { useEffect, useState } from 'react'
import ConfirmModal from '../../../common/components/ConfirmModal'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { userRoles } from '../../../bussines/settings/validate-user-access'

function EducationGroupSchoolCard ({ institution, refetch }) {
  const { activate, isLoading, currentState } = usePostActivateInstitution()
  const [hasPermision, setHasPermision] = useState(true)
  const { user: _user } = useAuth()

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      refetch()
    }
    if (!isLoading && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: 'something went wrong'
      })
    }
  }, [isLoading, currentState])

  useEffect(() => {
    const role = _user?.roles
    if (role.includes(userRoles.TYPE_EG_CONSULTANT)) {
      setHasPermision(false)
    }
  }, [_user])

  return (
    <Row style={{ backgroundColor: 'white', borderRadius: '.4rem' }}>
      <Col
        span={8}
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          style={{ maxWidth: '80%', maxHeight: '110px', margin: '2vh 2vw' }}
          src={institution?.profile_photo_url}
          alt='byp'
        />
      </Col>
      <Divider
        type='vertical'
        style={{
          background: '#CDDFFF',
          padding: '55px 0',
          margin: '2.5vh 0',
          marginLeft: '10px'
        }}
      />
      <Col span={14}>
        <p
          style={{
            fontSize: '1vw',
            fontWeight: '700',
            color: 'var(--blue-200)',
            margin: '15px 1.5vw',
            width: '95%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {institution?.name}
          <ConfirmModal onOk={activate} isLoading={isLoading} id={{ institution: institution.id }} hasPermision={hasPermision} />
        </p>
        <div className='w-100 byp-ml-1 byp-mb-1' style={{ fontSize: '1vw' }}>
          <small><span className='byp-gray byp-fw-bold'>Contact</span></small> <br />
          <small><span className='byp-title'>{institution.email}</span></small> <br />
          <small><span className='byp-gray byp-fw-bold'>Phone</span></small> <br />
          <small><span className='byp-title'>{institution.tel}</span></small> <br />
        </div>
      </Col>
    </Row>
  )
}

export { EducationGroupSchoolCard }
