import { Col, Form, Row, Slider } from 'antd'
import { useState } from 'react'

const marks = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12'
  // 12: {
  //   style: {
  //     color: '#f50'
  //   },
  //   label: <strong>12</strong>
  // }
}

const GradesFormItem = () => {
  const [minGrade, setMinGrade] = useState(4)
  const [maxGrade, setMaxGrade] = useState(7)

  const onChangeRange = (values) => {
    const [min, max] = values
    setMinGrade(min)
    setMaxGrade(max)
  }

  return (
    <Row>
      <Col xs={24}>
        <h6 className='byp-title'><b>Grades offered:</b></h6>
      </Col>
      <Col xs={24} md={3} style={{ marginTop: '5px' }}>
        <h6 className='byp-title'> {minGrade} to {maxGrade}</h6>
      </Col>
      <Col xs={24} md={21} align='middle'>
        <Form.Item name='grades'>
          <Slider className='slider_range' defaultValue={[minGrade, maxGrade]} range min={1} max={12} onChange={onChangeRange} marks={marks} />
        </Form.Item>
      </Col>
    </Row>
  )
}

export { GradesFormItem }
