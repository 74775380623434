import React, { useEffect, useState } from 'react'
import { BackTop, Col, List, Row, Button } from 'antd'
import { ListSearchQuoteMap } from './ListSearchQuoteMap'
import { useSearch } from '../../../providers/search/search-context-provider'
import { useGetCoursesByFilter, useGetCoursesLocationsByFilter } from '../../../bussines/hooks/quoter/useGetCoursesByFilter'
import { CardItemCourse } from './CardItemCourse'
import '../../../common/assets/css/offer-list-theme.less'
import LottieCargando from '../../../lotties/LottieCargando'
import { usePutCourseExpired } from '../../../bussines/hooks/school/course/usePutCourseExpired'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { showErrorModal } from '../../../utils/errorModal'

const ListSearchQuoteResult = ({ setSubType }) => {
  usePutCourseExpired()
  const [showMap, setShowMap] = useState(false)
  const { user: _user } = useAuth()
  const { isSearch, search, changeSearch, setTotalResults, setIsSearching } = useSearch()
  const { isLoading, data, error } = useGetCoursesByFilter(search)
  const [isFirst, setIsFirst] = useState(false)
  const { isLoading: isLoadingL, data: locations, error: errorL } = useGetCoursesLocationsByFilter(
    {
      ...search,
      page: 1
    }
  )

  const handleGroupByCampus = (place) => {
    changeSearch('LANG_FORM', {
      ...search,
      place,
      place_type: 'campus'
    })
  }

  useEffect(() => {
    setIsSearching(isLoading)
    if (!isLoading && error) {
      showErrorModal(error.response?.data?.message)
    }
    if (!isLoading && data) {
      setTotalResults(data?.meta?.total)
      if (data?.data && !isFirst && (_user === 'Institution')) {
        setIsFirst(true)
        setSubType(data?.data[0]?.course?.properties?.sub_type)
      }
    }
  }, [isLoading, data])

  useEffect(() => {
    if (!isLoadingL && errorL) {
      showErrorModal(errorL.response?.data?.message)
    }
    if (!isLoadingL && locations) {
      // console.log('🚀 ~ file: ListSearchQuoteResult.js:30 ~ useEffect ~ locations', locations)
    }
  }, [isLoadingL, locations, errorL])

  if (isSearch) {
    return ''
  }

  const ListSection = () => {
    return (
      <>
        <div className='d-flex align-center' style={{ marginBottom: '2rem', justifyContent: 'space-between' }}>
          <Row style={{ paddingLeft: '0', width: '100%' }}>
            <></>
          </Row>
          <Button className='byp-btn-blue-100 btn-show-map' onClick={() => setShowMap(!showMap)}>
            {showMap ? 'See list' : 'See map'}
          </Button>
        </div>
        <List
          style={{ paddingTop: '10px' }}
          className={showMap ? 'show-notshow offer-list-container' : 'offer-list-container'}
          itemLayout='vertical'
          pagination={{
            onChange: page => {
              changeSearch('LANG_FORM', {
                ...search,
                page
              })
            },
            total: data?.meta?.total,
            pageSize: data?.meta?.per_page ?? 10,
            hideOnSinglePage: true,
            current: data?.meta?.current_page
          }}
          dataSource={data?.data || []}
          renderItem={(item, key) => <CardItemCourse key={key} item={item} />}
        />
      </>
    )
  }

  const MapSection = () => {
    return (
      <ListSearchQuoteMap
        courses={data?.data ?? []}
        campusList={locations ?? []}
        onClickPopup={handleGroupByCampus}
      />
    )
  }

  return (
    <div style={{ marginLeft: '2%', marginRight: '2%' }}>
      <BackTop />
      <Row gutter={16} className='hidden-scroll'>
        <Col xs={24} md={24} lg={12}>
          {isLoading && !isLoadingL && <LottieCargando />}
          {!isLoading && (
            <ListSection />
          )}
        </Col>
        <Col xs={0} md={24} lg={12} style={{ height: '90vh' }} className={showMap ? '' : 'show-notshow'}>
          {!isLoading && isLoadingL && <LottieCargando />}
          {!isLoadingL && (
            <MapSection />
          )}
        </Col>
      </Row>
    </div>
  )
}

export { ListSearchQuoteResult }
