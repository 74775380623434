const frequencies = [
  {
    key: 'unic',
    name: 'unic',
    label: 'Only time'
  },
  {
    key: 'day',
    name: 'day',
    label: 'Daily'
  },
  {
    key: 'week',
    name: 'weeks',
    label: 'Weekly'
  },
  {
    key: 'month',
    name: 'monthly',
    label: 'Monthly'
  },
  {
    key: 'months',
    name: 'every 4 months',
    label: 'Every 4 months'
  },
  {
    key: 'semester',
    name: 'semester',
    label: 'Semester'
  },
  {
    key: 'year',
    name: 'anual',
    label: 'Anual'
  }
]

const getFrequencyLabel = (frequencyName) => {
  const found = frequencies.find((freq) => freq.name === frequencyName)
  return found?.label || frequencyName
}

export { frequencies, getFrequencyLabel }
