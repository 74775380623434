import { useQuery } from 'react-query'
import InsuranceRepository from '../../../repositories/InsuranceRepository'

function useGetFeeYears (feeId, filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetFeeYears', feeId, filtersQuery],
    () => InsuranceRepository.getFeeYears(feeId, filtersQuery),
    {
      retry: 2,
      enabled: !!feeId
    }
  )
}

export { useGetFeeYears }
