import React, { useEffect, useState } from 'react'
import { Form, notification } from 'antd'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { URL_DASHBOARD_PAGE } from '../../pages/dashboards'

const ProfileEnterpriseContext = React.createContext()

function ProfileEnterpriseProvider (props) {
  const navigate = useNavigate()
  const { useGetCompanyProfile, usePostUpdateCompanyProfile } = props
  const [initValues, setInitValues] = useState('')
  const { isFetching: loadingProfile, data: dataProfile, refetch } = useGetCompanyProfile()
  const {
    isLoading: isLoadingUpdateEnterprise,
    currentState: currentStateUpdateEnterprise,
    update,
    data: dataUpdateEnterprise,
    error: errorUpdateCompanyProfile
  } = usePostUpdateCompanyProfile()

  const [form] = Form.useForm()

  useEffect(() => {
    if (!loadingProfile && dataProfile?.profile) {
      const { profile } = dataProfile
      // console.log('🚀 ~ file: profile-enterprise-provider.js:27 ~ useEffect ~ profile:', profile)
      const allocialNetworks = [
        {
          name: 'Facebook',
          url: '',
          show: false
        }, {
          name: 'Twitter',
          url: '',
          show: false
        }, {
          name: 'Linkedin',
          url: '',
          show: false
        }, {
          name: 'Instagram',
          url: '',
          show: false
        }, {
          name: 'Youtube',
          url: '',
          show: false
        }, {
          name: 'Tik Tok',
          url: '',
          show: false
        }
      ]

      const formatedSocialNetworks = allocialNetworks.map((auxSocialNetwork) => {
        const hasSocialNewtkork = profile.social_networks?.find(element => element.name === auxSocialNetwork.name)
        if (hasSocialNewtkork) {
          return hasSocialNewtkork
        } else {
          return auxSocialNetwork
        }
      })

      profile.name = profile.name ?? ''
      profile.email = profile.email ?? ''
      profile.profile_photo_url = profile.profile_photo_url ?? ''
      profile.description = profile.description ?? ''
      profile.accept_from = profile.accept_from ?? []

      profile.service_category = profile.services ?? []

      profile.references = profile.references?.length
        ? profile.references
        : [{
            name: '',
            email: ''
          }]
      profile.social_networks = formatedSocialNetworks
      profile.campus = profile.campus?.length ? profile.campus : [{}]
      profile.offices = profile.offices ?? []
      // profile.offices = profile.offices?.length ? profile.offices : []
      profile.association = !!profile.association

      // Agency
      profile.divisa = profile.divisa ?? ''
      profile.address = profile.address ?? ''
      profile.tel = profile.tel ?? ''
      profile.whatsapp = profile.whatsapp ?? ''
      profile.website = profile.website ?? ''
      profile.cp = profile.cp ?? ''
      profile.ceo = profile.ceo ?? ''
      profile.foundation = profile.foundation ?? ''
      profile.years_of_experience = profile.years_of_experience ?? ''
      profile.countries = profile.countries ?? []
      profile.show_all = profile.show_all || true
      profile.template = profile.template || 1
      profile.bank_data = profile.bank_data?.length ? profile.bank_data : [{}]
      profile.payment_methods = profile.payment_methods?.length ? profile.payment_methods : [{}]
      profile.miscellaneous = profile.miscellaneous?.length ? profile.miscellaneous : [{ cost: 0 }]
      profile.branches = profile.branches || [{
        divisa: 69,
        phone_code: '52'
      }]
      profile.role = profile.role || 'Gerente Comercial'
      profile.invitations = profile.invitations || [{
        role: 'Administrador'
      }]
      profile.show_all = false

      profile.location = {
        city: profile.city,
        country: profile.country
      }

      profile.isGroup = profile.groups?.length > 0
      setInitValues(profile)
    }
  }, [loadingProfile, dataProfile])

  const handleOnSubmit = () => {
    updateEnterprise(form.getFieldsValue())
    // form.submit() // For some reason tThis is no sending the updated form data
    form.resetFields()
  }

  const updateEnterprise = (data) => {
    update(data)
  }

  const handleHomePage = () => {
    navigate(URL_DASHBOARD_PAGE)
  }

  const openNotification = (title, message = '', placement = 'top') => {
    notification.info({
      message: `${title}`,
      description:
      `${message}`,
      placement
    })
  }

  return (
    <ProfileEnterpriseContext.Provider
      value={{
        form,
        refetch,
        loadingProfile,
        dataProfile,
        initValues,
        isLoadingUpdateEnterprise,
        currentStateUpdateEnterprise,
        dataUpdateEnterprise,
        errorUpdateCompanyProfile,
        handleOnSubmit,
        updateEnterprise,
        openNotification,
        handleHomePage
      }}
      {...props}
    />
  )
}

ProfileEnterpriseProvider.propTypes = {
  useGetCompanyProfile: PropTypes.func.isRequired,
  usePostUpdateCompanyProfile: PropTypes.func.isRequired
}

/**
 * useProfileEnterprise
 * @returns {{ loadingProfile, dataProfile, handleOnSubmit, handleOnUpdate }} context
 */
function useProfileEnterprise () {
  const context = React.useContext(ProfileEnterpriseContext)
  if (context === undefined) {
    throw new Error('useProfileEnterprise must be used within a ProfileEnterpriseProvider')
  }
  return context
}

export { ProfileEnterpriseProvider, useProfileEnterprise }
