import { Button, Col, Form, Input, Row } from 'antd'
import React from 'react'
import { RiDeleteBin6Fill } from 'react-icons/ri'

const required = { required: true, message: 'Este dato es requerido' }

function BrothersListForm ({ disabled = false, listName = 'contacts', className }) {
  return (
    <Form.List
      name={listName}
      className={className}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} style={{ width: '100%' }} gutter={16} align='middle'>
              <Col xs={24} md={12}>
                <label className='label-aplication'>Nombre Completo</label>
                <span
                  className='byp-ml-1 contact-form_delete br-4'
                  style={{ border: 'solid 1px var(--red-50)', borderRadius: '4px', display: 'inline-flex', width: '1.4rem', height: '1.4rem', padding: '.2rem', cursor: 'pointer' }}
                  onClick={() => remove(index)}
                >
                  <RiDeleteBin6Fill color='var(--red-50)' />
                </span>
                <Form.Item
                  {...restField}
                  name={[name, 'full_name']}
                  rules={[required]}
                >
                  <Input className='aplication__input' disabled={disabled} />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <label className='label-aplication'>Edad</label>
                <Form.Item
                  {...restField}
                  name={[name, 'age']}
                  rules={[required]}
                >
                  <Input className='aplication__input' disabled={disabled} type='number' min={0} max={99} />
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Col span={24}>
            <Button className='byp-btn-gray-300 br-06 fs-12 fw-5' size='large' onClick={() => add()} disabled={disabled}> {/*  icon={<PlusOutlined />} */}
              Agregar otro hermano
            </Button>
          </Col>
        </>
      )}
    </Form.List>
  )
}

export default BrothersListForm
