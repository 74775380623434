import React from 'react'
import { Col, Row } from 'antd'

// Función para calcular numero aleatorio
function getRandomNumber (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function StepBaseTemplate ({ children, align, justify, footer }) {
  // Genereamos random del 1 al 9 ya que esas son las imagenes
  const randomNumber = getRandomNumber(1, 9)

  // Función para importar todas las imagenes de la carpeta
  const importAll = (r) => {
    const images = {}
    r.keys().forEach((key) => (images[key] = r(key)))
    return images
  }

  // Importamos todas las imagenes
  const images = importAll(
    require.context(
      '../../../common/assets/images/RandomImages/RR',
      false,
      /\.(png|jpe?g|svg)$/
    )
  )

  return (
    <div>
      <Row style={{ height: '100vh' }} justify={justify} align={align}>
        <Col
          className='RR'
          sm={0}
          md={8}
          lg={12}
          style={{
            // height: '100vh',
            // backgroundImage: `url('${resp}')`,
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            // backgroundAttachment: 'fixed',
            // backgroundPosition: 'center right',
          }}
        >
          <style>{`

              
              .step-footer-style{
                background-color: transparent
                border-top:none
              }

              #action-btns {
                display: flex 
                justify-content: space-between 
                align-items: center 
                margin-left:-100px
              }
              
              .RR{
                  height: 30% 
                  width: 100% 
                  margin-top:-20%
                }
              .Formulario{
                width: 100%
              }
              
              @media (min-width: 600px) {
                .RR {
                  height: 100% 
                  margin-top:0
                }
              }

              
              @media (min-width: 768px) {
                .RR {
                  height: 100% 
                }
                .step-footer-style{
                  background-color: #F7F7F7 
                  border-top: 1px solid #F7F7F7 
                }
              }
          `}
          </style>
          {
            // Mandamos a llamar imagen random
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={images['./RR' + randomNumber + '.png']}
            />
          }
        </Col>
        <Col className='Formulario' sm={24} md={16} lg={12}>
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'scroll',
              maxHeight: '100vh'
            }}
          >
            <div className='step-body'>{children}</div>
          </div>

          <div className='step-footer-fixed'>
            <Row justify='end'>
              <Col className='step-footer-style' span={12}>
                <div style={{ margin: 16 }}>{footer}</div>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>
    </div>
  )
}

export { StepBaseTemplate }
