import { useState } from 'react'
import stateControl from '../../utils/state-control'
import AgencyRepository from '../../repositories/AgencyRepository'
import { useMutation } from 'react-query'

function usePutAutoExchangeConfigUpdate () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.activeDailyAutoExchange, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  const activeDailyAutoExchange = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, stateControl, currentState, activeDailyAutoExchange }
}

export { usePutAutoExchangeConfigUpdate }
