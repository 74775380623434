import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormInsurancePrices } from './FormInsurancePrices'
import { TopBarMenuCustom } from '../components/TopBarMenuCustom'
import { PageProtected } from '../../../../common/components/PageProtected'

const URL_CREATE_INSURANCE_PRICES_PAGE = '/dashboard/insurance-prices'

const InsurancePricesPage = () => {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <TopBarMenuCustom />
        <FormInsurancePrices />
      </MainTheme>
    </PageProtected>
  )
}

export { InsurancePricesPage, URL_CREATE_INSURANCE_PRICES_PAGE }
