import React from 'react'
import { SelectRegionsFormItem } from '../../../../common/components/places-helper-form/SelectRegionsFormItem'
import { Form, Select, Button } from 'antd'
import { useGetSubregionsByRegions } from '../../../../bussines/hooks/places/useGetSubregionsByRegion'
import { useGetCountriesBySubRegions } from '../../../../bussines/hooks/places/useGetCountriesBySubRegion'
import { CloseOutlined } from '@ant-design/icons'
import { useGetAgencies } from '../../../../bussines/hooks/agency/useGetAgencies'

const { Option } = Select
const SelectAgenciesByLocation = ({ form }) => {
  const regions = Form.useWatch('region', form)
  const subRegions = Form.useWatch('subregions', form)
  const country = Form.useWatch('countrie', form)

  const { data: subregions } = useGetSubregionsByRegions([regions])
  const { data: countries } = useGetCountriesBySubRegions([subRegions])
  const { data: agencies } = useGetAgencies({ region: regions, sub_region: subRegions, country })

  const handleSelects = (select) => {
    if (select === 'region') {
      form.setFieldsValue({
        region: 'global',
        subregions: 'undefined',
        countrie: 'undefined',
        agency: 'undefined'
      })
    } else if (select === 'subregion') {
      form.setFieldsValue({
        subregions: 'undefined',
        countrie: 'undefined',
        agency: 'undefined'
      })
    } else if (select === 'country') {
      form.setFieldsValue({
        countrie: 'undefined',
        agency: 'undefined'
      })
    }
  }

  const onChangeForm = (values) => {
    if (values.region) {
      form.setFieldsValue({
        subregions: 'undefined',
        countrie: 'undefined',
        agency: 'undefined'
      })
    }
    if (values.subregions) {
      form.setFieldsValue({
        countrie: 'undefined',
        agency: 'undefined'
      })
    }
    if (values.subregions) {
      form.setFieldsValue({
        countrie: 'undefined',
        agency: 'undefined'
      })
    }
    if (values.countrie) {
      form.setFieldsValue({
        agency: 'undefined'
      })
    }
  }

  return (
    <Form
      form={form}
      className='d-flex selects-responsive'
      style={{ gap: '0.625rem' }}
      initialValues={{
        region: 'global',
        subregions: 'undefined',
        countries: 'undefined'
      }}
      onValuesChange={onChangeForm}
    >
      <div className='selec-agencie-analitics'>
        <SelectRegionsFormItem
          name='region'
          form={form}
          className='user-analysis__select'
          size='small'
          style={{ width: 100 }}
          allowClear={false}
          global
          globalLabel='Regions'
        />
        <Button onClick={() => handleSelects('region')}>
          <CloseOutlined />
        </Button>
      </div>
      <div className='selec-agencie-analitics'>
        <Form.Item name='subregions' className='byp-m-0'>
          <Select
            maxTagCount='responsive'
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            className='user-analysis__select'
            size='small'
            style={{ width: 175 }}
          >
            <Option key='first' value='undefined'>Zones</Option>
            {subregions?.flat()?.map((subregion) => (
              <Option key={subregion.subregion} value={subregion.subregion}>{subregion.subregion}</Option>
            ))}
          </Select>
        </Form.Item>
        <Button onClick={() => handleSelects('subregion')}>
          <CloseOutlined />
        </Button>
      </div>
      <div className='selec-agencie-analitics'>
        <Form.Item name='countrie' placeholder='Add more countries' className='byp-m-0'>
          <Select
            maxTagCount='responsive'
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder='Countries'
            className='user-analysis__select'
            size='small'
            style={{ width: 175 }}
          >
            <Option key='first' value='undefined'>Countries</Option>
            {countries?.flat()?.map((country) => (
              <Option key={country.id} value={country.id}>{country.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Button onClick={() => handleSelects('country')}>
          <CloseOutlined />
        </Button>
      </div>
      {/* <div className='selec-agencie-analitics'>
        <Form.Item name='agency' placeholder='Agencie' className='byp-m-0'>
          <Select
            maxTagCount='responsive'
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder='Agencies'
            className='user-analysis__select'
            size='small'
            style={{ width: 175 }}
          >
            <Option key='first' value='undefined'>Agencies</Option>
            {agencies?.data?.flat()?.map((country) => (
              <Option key={country.id} value={country.id}>{country.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Button onClick={() => handleSelects('agency')}>
          <CloseOutlined />
        </Button>
      </div> */}
    </Form>
  )
}

export { SelectAgenciesByLocation }
