import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

function useGetAgencyByStudentId (params) {
  return useQuery(['useGetAgencyByStudentId', params], () => QuoteRepository.getAgency(params), {
    retry: 2,
    enabled: !!params?.agent_id
  })
}

export { useGetAgencyByStudentId }
