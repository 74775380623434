import { useQuery } from 'react-query'
import CourseRepository from '../../../repositories/CourseRepository'

function useGetCoursesByProgram (program, params = {}) {
  return useQuery(['useGetCoursesByProgram', program, params],
    () => CourseRepository.getCoursesByProgram(program, params), {
      retry: 2,
      disabled: !!program
    })
}

export { useGetCoursesByProgram }
