import { useQuery } from 'react-query'
import PackagesRepository from '../../repositories/PackagesRepository'

function useGetPackages (filters = {}) {
  return useQuery(['useGetPackages', filters],
    () => PackagesRepository.packages(filters), {
      retry: 1
    })
}

function useGetOfficesPackage () {
  return useQuery(['useGetOfficesPackage'],
    () => PackagesRepository.packagesOffices(), {
      retry: 1
    })
}

export { useGetPackages, useGetOfficesPackage }
