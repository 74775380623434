

function TextInsideButton({ text}) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" width="10" height="10" stroke-width='2' stroke='var(--blue-100)'>
                <path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                ></path>
            </svg>
            {text}
        </div>
    )
}

export {TextInsideButton}