import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetTopUsers (limit, filters = {}) {
  return useQuery(['useGetTopUsers', limit, filters], () => GeneralAnalysisRepository.getTopUsers(limit, filters),
  {
    staleTime: 5000
  }
  )
  
}

export { useGetTopUsers }
