import React from 'react'
import icon_agencies from '../../../common/assets/images/icon_agencies.png'
import agencies from '../../../common/assets/images/agencies.png'


export function HomeSectionD() {
  return (
    <div className='mb-responsive-lg'>
      <div className="d-flex align-items-center px-0 py-2 my-5 justify-content-end home-sectioncde-home">
        <div className="col-md-8 p-0" style={{top: '1rem'}}>
          <div
            className="card box-shadow-0 border-0 align-items-center home-card-responseive"
            style={{position: 'relative', background: '#cddfff', width: '100%' }}
          >
            <div className="card-content home-sectiond-card" style={{ width: '35rem', marginLeft: '7rem', marginTop:'2rem', marginBottom:'2rem' }}>
              <div className='home-sectiond-title'>
                <img
                  src={icon_agencies}
                  alt=""
                  className="mb-1"
                  style={{width:'70px',height: '70px'}}
                /> 
                <div>
                  <h5 style={{marginTop:'1rem'}}>
                    <b>Agencies</b>
                  </h5>
                  <p className='byp-sub-black'>
                    QYP is created out of the need to generate accurate quotes in a
                    fast and intuitive way, based on current school promotions.
                    Transparency and privacy are our main values.
                  </p>
                </div>
              </div>
              <ul className="byp-blue-200 byp-text-cont-blue">
                <li>
                  QYP is not a CRM so it will not have access to your customer
                  information.
                </li>
                <li>You’ll be able to integrate your CRM or website</li>
                <li>
                  You’ll get customizable and easy to share quotes in PDF or
                  through a link.
                </li>
                <li>
                  Get statistics by quote, consultant, institution and
                  destination.
                </li>
              </ul>
            </div>
            <div
              className="d-md-block col-12 col-md-4 p-0 home-img-d"
              style={{position: 'absolute', left: 'calc(-16rem - 2vw)', top: '50%', transform: 'translateY(-50%)'}}
            >
              <div className="card card-transparent box-shadow-0 border-0">
                <div className='home-img'>
                  <img
                    src={agencies}
                    alt=""
                    className="byp-poster"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}