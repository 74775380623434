import React from 'react'
import { StepProvider } from '../../providers/stepbystep/step-service-provider'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { EditUserProfile } from '../register/components/forms/User/EditUserProfile'
import { useGetStep } from '../../bussines/hooks/user/useGetStep'
import { useUpdateUserProfile } from '../../bussines/hooks/user/useUpdateUserProfile'

const URL_EDIT_USER = '/user/edit'

function EditUserProfilePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <StepProvider useGetStep={useGetStep} usePostSteps={useUpdateUserProfile}>
        <EditUserProfile />
      </StepProvider>
    </PageProtected>
  )
}

export { EditUserProfilePage, URL_EDIT_USER }
