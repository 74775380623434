import React from 'react'
import { StepBaseTemplate } from './components/StepBaseTemplate'
import { RegisterForm } from './components/forms/RegisterForm'
import { AppLogoNameHeader } from '../../common/components/AppLogoNameHeader'
import { useSearchParams } from 'react-router-dom'

const URL_REGISTER_PAGE = '/register'
const URL_REGISTER_PAGE_BETA = '/register/$2y$10$DwiblQ'

function RegisterPage () {
  const [params] = useSearchParams()
  const email = params.get('email') ?? ''
  const type = params.get('type') ?? ''

  return (
    <StepBaseTemplate justify='space-around' align='middle'>
      <div style={{ margin: '18px 25%' }}>
        <AppLogoNameHeader
          style={{
            paddingBottom: 42,
            maxWidth: 300,
            padding: 18
          }}
        />
        <RegisterForm email={email} type={type} />
      </div>
    </StepBaseTemplate>
  )
}

export { RegisterPage, URL_REGISTER_PAGE, URL_REGISTER_PAGE_BETA }
