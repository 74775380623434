import React, { Suspense, useEffect, useState } from 'react'
import { Pagination, notification } from 'antd'
import header from './../../../common/assets/images/banner-hero.png'
import { FooterBlue } from './FooterBlue'
import { CourseCompleteDisplayed } from './CourseCompletedDisplayed'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useParams } from 'react-router-dom'
import { useGetClientQuotes, useGetClientQuotesInactive } from '../../../bussines/hooks/agency/useGetQuotesByStudent'
import { useGetAgencyByStudentId } from '../../../bussines/hooks/agency/useGetAgencyByStudentId'
import { decrypt } from '../../../bussines/utils/basicEncript'
import { Unauthorized } from '../../errors/Unauthorized'
import { HeaderClientQuotes } from './HeaderClientQuotes'
import { WhatsAppOutlined } from '@ant-design/icons'
import LottieCargando from '../../../lotties/LottieCargando'
import { useTranslation } from 'react-i18next'
import { BasicSpinner } from '../../../common/components/BasicSpinner'

function WelcomeUser ({ textDisplayed }) {
  return (
    <div>
      <p style={{ fontSize: '3rem', paddingTop: '10px', color: 'white', fontWeight: 'bold' }}>
        {textDisplayed && 'Hello, ' + textDisplayed}
      </p>
    </div>
  )
}

function IconPlus () {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='75px' height='75px' viewBox='0 0 24 24' fill='none'>
      <path
        d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z'
        fill='lightgray'
      />
    </svg>
  )
}

function VendorPageComponent () {
  const { i18n } = useTranslation(['quotesPage'])
  const { user } = useAuth()
  const { studentId: cryptStudentId, agentId: cryptAgentId } = useParams()
  const [studentId, setStudentId] = useState()
  const [agentId, setAgentId] = useState()
  const [authorized, setAuthorized] = useState(true)
  const [page, setPage] = useState(1)
  const [inactivePage, setInactivePage] = useState(1)
  const { data: quotes, refetch, error, isLoading } = useGetClientQuotes({ studentId, agentId, page })
  const { data: quotesInactive, refetch: refetchInactive, error: errorInactive, isLoading: isLoadingInactive } = useGetClientQuotesInactive({ studentId, agentId, page: inactivePage })
  const { data: agencys } = useGetAgencyByStudentId({ student_id: studentId, agent_id: agentId })

  const [arrayIsActive, setArrayIsActive] = useState([])
  const [arrayIsInactive, setArrayIsInactive] = useState([])
  const [name, setName] = useState('')
  const [marginIfHaveActive, setMarginIfHaveActive] = useState('0')

  const [language, setLanguage] = useState('en')

  function getIsActive (endDate, startDate) {
    const currentDate = new Date()
    const start = new Date(startDate)
    const end = new Date(endDate)

    return currentDate >= start && currentDate <= end
  }

  // This change the language to spanish: 'cause now we are usin Google Translator api
  useEffect(() => {
    if (i18n.language === 'es') {
      i18n.changeLanguage('en')
    }
  }, [language])

  useEffect(() => {
    if (cryptStudentId) {
      setStudentId(decrypt('qyp2023', cryptStudentId))
    }
    if (cryptAgentId) {
      setAgentId(decrypt('qyp2023', cryptAgentId))
    }
  }, [cryptStudentId, cryptAgentId])

  useEffect(() => {
    if (!isLoading && quotes) {
      const active = []
      const quotesData = quotes?.data ?? []
      quotesData?.forEach(quote => {
        if (getIsActive(quote.expiration, quote.created_at)) {
          active.push(quote)
        } else {
          console.log('🚀 ~ file: VendorPageComponent.js:91 ~ useEffect ~ Inactive quote found:', quote.uuid)
        }
      })

      setName(quotesData[0]?.custom_name)
      setMarginIfHaveActive(active.length === 0 ? '10rem' : '')
      // setArrayIsActive(active)
      console.log('🚀 ~ file: VendorPageComponent.js:98 ~ useEffect ~ active:', active)
      setArrayIsActive(quotesData)
    }
    if (!isLoading && error) {
      if (error?.response?.status === 401) {
        setAuthorized(false)
      } else {
        notification.error({
          placement: 'top',
          message: error?.response?.data?.message ?? 'Something Went Wrong'
        })
      }
    }
  }, [quotes, error, isLoading])

  useEffect(() => {
    if (!isLoadingInactive && quotesInactive) {
      const inactive = []
      const quotesData = quotesInactive?.data ?? []
      quotesData?.forEach(quote => {
        if (!getIsActive(quote.expiration, quote.created_at)) {
          inactive.push(quote)
        }
      })
      setName(quotesData[0]?.custom_name)
      setArrayIsInactive(inactive)
    }
    if (!isLoadingInactive && error) {
      if (errorInactive?.response?.status === 401) {
        setAuthorized(false)
      } else {
        notification.error({
          placement: 'top',
          message: error?.response?.data?.message ?? 'Something Went Wrong'
        })
      }
    }
  }, [quotesInactive, errorInactive, isLoadingInactive])

  if (!authorized) {
    return <Unauthorized />
  }

  return (
    <Suspense fallback='Cargando traducciones...'>
      <HeaderClientQuotes agencys={agencys} studentId={studentId} language={language} setLanguage={setLanguage} i18n={i18n} />
      <img src={header} style={{ width: '100%', height: '15rem', objectFit: 'cover' }} alt='' />
      {isLoading && <LottieCargando />}
      {!isLoading && (
        <div className='client-quotes-section-quotes'>
          <WelcomeUser textDisplayed={name} />
          {quotes?.data?.map((quote) => (
            <CourseCompleteDisplayed
              key={quote.uuid}
              quote={quote}
              refetch={refetch}
              isActive
              authUser={user}
            />
          ))}
          <div style={{ textAlign: 'end' }}>
            <Pagination
              className='byp-w-100'
              defaultCurrent={1}
              current={quotes?.meta?.current_page ?? 1}
              // size='small'
              pageSize={quotes?.meta?.per_page ?? 20}
              total={quotes?.meta?.total ?? 0}
              onChange={setPage}
              hideOnSinglePage
              showQuickJumper={false}
              showSizeChanger={false}
            />
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center', fontSize: '16px', color: 'gray', marginTop: marginIfHaveActive }}>
            <a className='course-create-link' href='/quote' style={{ textDecoration: 'none' }}>
              <IconPlus style={{ paddingRight: '30px' }} />
              <span>Create new quote</span>
            </a>
          </div>
          {isLoadingInactive && <BasicSpinner />}
          {arrayIsInactive?.map((quote) => (
            <CourseCompleteDisplayed key={quote.uuid} quote={quote} refetch={refetchInactive} authUser={user} />
          ))}
          <div style={{ textAlign: 'end' }}>
            <Pagination
              className='byp-w-100'
              defaultCurrent={1}
              current={quotesInactive?.meta?.current_page ?? 1}
              // size='small'
              pageSize={quotesInactive?.meta?.per_page ?? 20}
              total={quotesInactive?.meta?.total ?? 0}
              onChange={setInactivePage}
              hideOnSinglePage
              showQuickJumper={false}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
      {
        agencys?.data?.whatsapp &&
          <div className='whatsapp-container'>
            <a href={'https://wa.me/' + agencys?.data?.whatsapp}>
              <div className='whatsapp-icon'>
                <WhatsAppOutlined />
              </div>
            </a>
          </div>
      }
      <FooterBlue agency={agencys?.data} />
    </Suspense>
  )
}

export { VendorPageComponent }
