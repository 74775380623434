import { Avatar, Card, Col, Row, Typography } from 'antd'
import { EmailIcon, TwitterIcon, TikTokIcon, WhatsapIcon } from '../assets/imgs/Icons'
const { Paragraph } = Typography

const handleUrl = (link) => {
  if (!link?.startsWith('http')) {
    link = 'http://' + link
  }

  return link
}

export const QuoteInstitution = ({ quote, className }) => (
  <Card bordered={false} className={className}>
    <div className='you-can-discover ycd-bg align-items'>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={18} lg={8}>
          <div className='align-items quote-institution-responsive-network-social text-center'>
            <Avatar
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 68
              }}
              style={{
                width: '110px',
                height: '110px',
                objectFit: 'contain'
              }}
              src={quote?.provider?.campus?.logo}
              icon={
                <img
                  src={quote?.provider?.logo}
                  className='img-rounded'
                  alt={quote.provider.campus.name}
                  style={{
                    width: '110px',
                    height: '110px',
                    objectFit: 'contain'
                  }}
                />
              }
            />
            <h2 className='agent-name'>{quote.provider.campus.name}</h2>
            <div className='social'>
              {
                quote.agency.email && (
                  <a href={`mailto:${quote.agency.email}`} className='social-media'>
                    <EmailIcon />
                  </a>
                )
              }
              {
                quote.agent.whatsapp && (
                  <a href={`https://wa.me/${quote.agency.whatsapp}`} className='social-media'>
                    <WhatsapIcon />
                  </a>
                )
              }
              {
                quote.agent.twitter && (
                  <a href={quote.agency.twitter} className='social-media'>
                    <TwitterIcon />
                  </a>
                )
              }
              {
                quote.agent.tiktok && (
                  <a href={quote.agency.tiktok} target='_blank' className='social-media' rel='noreferrer noopener'>
                    <TikTokIcon />
                  </a>
                )
              }
            </div>
            <div className='center-btn'>
              <a href={handleUrl(quote?.website)} target='_blank' rel='noreferrer'>
                <button className='quote-social-btn'>
                  Know more
                </button>
              </a>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16}>
          <div className='align-items description byp-jst-rsp'>
            <Paragraph ellipsis={{ rows: 8 }} style={{ fontSize: '0.875rem' }}>{quote.agent.sede.description}</Paragraph>
          </div>
        </Col>
      </Row>
    </div>
  </Card>
)
