import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { SchoolAnaliticsInfo } from './SchoolAnaliticsInfo'

const URL_SCHOOL_ANALITCIS_PAGE = '/dashboard/analitics/school'

function SchoolAnaliticsPage () {
  return (
    <PageProtected permissionTypes={[
      userRoles.TYPE_INSTITUTION,
      userRoles.TYPE_SUPER_ADMIN,
      userRoles.TYPE_SUPER_EDITOR
    ]}
    >
      <MainTheme
        showMenu
      >
        <SchoolAnaliticsInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { SchoolAnaliticsPage, URL_SCHOOL_ANALITCIS_PAGE }
