import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useGetUsersByRoleAndSede (sede, role) {
  return useQuery(['useGetUsersByRoleAndSede', sede, role], () => UserRepository.usersByRolesAndSede(role, sede), {
    enabled: !!sede && !!role,
    retry: 2
  })
}

export { useGetUsersByRoleAndSede }
