import { TitleSection } from '../../../../common/components/TitleSection'
import icon from '../../../../common/assets/images/icon.png'
import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

function TextShareEmailForCompleteRegister () {
  return (
    <>
      <img src={icon} alt='byp-icon' />
      <TitleSection label='¡Correo confirmado!' />
      <Text>Iniciaremos la personalización y creación de tu agencia
        este proceso te llevará un aproximado de 30 minutos, tus
        avances se guardan automáticamente, puedes salir
        y volver a continuar más tarde.
        Ó, puedes enviar una invitación para que alguien más realice
        este proceso.
      </Text>
      <br />
      <br />
      <Text>Si deseas que alguien más realice este proceso
        ingresa su correo electronico a continuación.
      </Text>
      <br />
      <br />
    </>
  )
}
export { TextShareEmailForCompleteRegister }
