import React from 'react'
import { AgencyTermsAndConditions } from './AgencyTermsAndConditions'
import { InstitutionTermsAndConditions } from './InstitutionTermsAndConditions'


function TextTermsAndConditions ({ type = 'Institution' }) {
  if (type === 'Institution') {
    return (
      <InstitutionTermsAndConditions />
    )
  } else {
    return <AgencyTermsAndConditions />
  }
}
export { TextTermsAndConditions }
