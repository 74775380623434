import { useQuery } from 'react-query'
import QuoteRepository from '../../repositories/QuoteRepository'

// Public route
function useGetQuotesByStudent ({ studentId, agentId, page }) {
  return useQuery(['useGetQuotesByStudent', studentId, agentId, page],
    () => QuoteRepository.getQuotesByStudent({ studentId, agentId, page }),
    {
      enabled: !!studentId && !!agentId && !!page,
      retry: 1
    }
  )
}

function useGetQuotesByStudentInactive ({ studentId, agentId }) {
  return useQuery(['useGetQuotesByStudentInactive', studentId, agentId],
    () => QuoteRepository.getQuotesByStudentInactive({ studentId, agentId }),
    {
      enabled: !!studentId && !!agentId,
      retry: 1
    }
  )
}

// Auth route
function useGetClientQuotes ({ studentId, agentId, page }) {
  return useQuery(['useGetClientQuotes', studentId, agentId, page],
    () => QuoteRepository.getClientQuotes({ studentId, agentId, page }),
    {
      enabled: !!studentId && !!agentId && !!page,
      retry: 1
    }
  )
}

function useGetClientQuotesInactive ({ studentId, agentId, page = 1 }) {
  return useQuery(['useGetClientQuotesInactive', studentId, agentId, page],
    () => QuoteRepository.getClientQuotesInactive({ studentId, agentId, page }),
    {
      enabled: !!studentId && !!agentId,
      retry: 1
    }
  )
}

export { useGetQuotesByStudent, useGetClientQuotes, useGetClientQuotesInactive, useGetQuotesByStudentInactive }
