import { useQuery } from 'react-query'
import ApplicationRepository from '../../repositories/ApplicationRepository'

function useGetApplication (id) {
  return useQuery(['useGetApplication', id], () => ApplicationRepository.show(id), {
    retry: 2
  })
}

export { useGetApplication }
