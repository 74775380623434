import { AuthProvider } from '../../providers/auth/auth-context-provider'
import { MagicLinkProvider } from '../../providers/magic-link/magic-link-provider'
import { UserProvider } from '../../providers/auth/user-context-provider'

/**
 *
 * @param children
 * @param {Array} permissionTypes - validateUserAccess/userRoles
 * @returns {*}
 * @constructor
 */
const PageProtected = ({ children, permissionTypes, onlyEdulinks }) => {
  return (
    <MagicLinkProvider>
      <AuthProvider permissionTypes={permissionTypes} onlyEdulinks={onlyEdulinks}>
        <UserProvider>
          {children}
        </UserProvider>
      </AuthProvider>
    </MagicLinkProvider>
  )
}

export { PageProtected }
