import { useQuery } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function useGetInfoOffice (type, id, filters = {}) {
  return useQuery(
    ['useGetInfoOffice', type, id, filters],
    () => AdminRepository.getInfoOffice(type, id, filters),
    {
      retry: 1
    }
  )
}

export { useGetInfoOffice }
