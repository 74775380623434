import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetAllBranches (filters = {}) {
  return useQuery(
    ['useGetAllBranches', filters],
    () => AgencyRepository.getBranches(filters),
    { retry: 2 }
  )
}

export { useGetAllBranches }
