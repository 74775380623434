import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { PackageInfoContainer } from './PackageInfoContainer'

const URL_APPROVE_PACKAGE_PAGE = '/dashboard/agency/package/approve'

function ApprovePackagePage () {
  const [params] = useSearchParams()
  const category = params.get('program') ?? 'language'
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <PackageInfoContainer
            category={category}
          />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { ApprovePackagePage, URL_APPROVE_PACKAGE_PAGE }
