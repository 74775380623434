import { Link } from 'react-router-dom'
import { DesktopIcon } from '../icons/DesktopIcon'
import { getItem } from './components/get-menu-item'
import { IconAnalyticsSharp } from '../../assets/svg/icons'
import { URL_GENERAL_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/general/GeneralAnalysisPage'
import { URL_USER_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/user/UserAnalysisPage'
import { UsersIcon } from '../icons/UsersIcon'
import { URL_USERS_PAGE } from '../../../pages/dashboards/users/AllUsersPage'
import { URL_USER_AGENCIES_PAGE } from '../../../pages/dashboards/Institution/analitics/AgenciesAnaliticsPage'
import { URL_CREATE_USERS_PAGE } from '../../../pages/dashboards/users/CrudUserPage'
import { URL_SCHOOL_ANALITCIS_PAGE } from '../../../pages/dashboards/agency/analitics/school/SchoolAnaliticsPage'
import { URL_GENERAL_MASTER_PAGE } from '../../../pages/dashboards/charts/general'
import { URL_TRENDS_MASTER_PAGE } from '../../../pages/dashboards/charts/trends'
import { URL_TABLE_COUNTRY_CITIES_PAGE } from '../../../pages/dashboards/tables-pages/TableCountryCitiesPage'
import { URL_TABLE_PROGRAMS_PAGE } from '../../../pages/dashboards/tables-pages/TableProgramsPage'
import { IconsPages } from '../../../pages/quote-preview/assets/imgs/Icons'

function SuperAdminMenuItems () {
  return [
    getItem(
      <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
      '1',
      <DesktopIcon />
    ),
    getItem(
      <Link to={URL_GENERAL_MASTER_PAGE} state={{ menuItemSelected: 'general-analysis-superadmin' }}>General Analysis</Link>,
      'general-analysis-superadmin',
      <DesktopIcon />
    ),
    getItem(
      <Link to={URL_TRENDS_MASTER_PAGE} state={{ menuItemSelected: 'comparative-results-superadmin' }}>Comparative results</Link>,
      'comparative-results-superadmin',
      <IconAnalyticsSharp />
    ),
    getItem(
      'Analytics',
      'analitic',
      <IconAnalyticsSharp />,
      [
        getItem(
          <Link
            to={URL_GENERAL_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'general-analysis', openSub: 'analitic' }}
          >General analysis
          </Link>,
          'general-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_AGENCIES_PAGE + '/agencies'}
            state={{ menuItemSelected: 'agencies-analysis', openSub: 'analitic' }}
          >Agencies' analysis
          </Link>,
          'agencies-analysis'
        ),
        getItem(
          <Link
            to={URL_SCHOOL_ANALITCIS_PAGE}
            state={{ menuItemSelected: 'schools-analysis', openSub: 'analitic' }}
          >School's analysis
          </Link>,
          'schools-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'user-analysis', openSub: 'analitic' }}
          >User analysis
          </Link>,
          'user-analysis'
        )
      ]
    ),
    getItem(
      'Países',
      'paises',
      <IconsPages />,
      [
        getItem(
          <Link
            to={URL_TABLE_COUNTRY_CITIES_PAGE}
            state={{ menuItemSelected: 'paises-todos', openSub: 'paises', type: 'Agency' }}
          >Todos
          </Link>,
          'paises-todos'
        ),
        getItem(
          <Link
            to='/'
            state={{ menuItemSelected: 'paises-activar', openSub: 'paises', type: 'Institution' }}
          >Activar
          </Link>,
          'paises-activar'
        )
      ]
    ),
    getItem(
      'Programs',
      'programs',
      <IconsPages />,
      [
        getItem(
          <Link
            to={URL_TABLE_PROGRAMS_PAGE}
            state={{ menuItemSelected: 'programas-todos', openSub: 'programas', type: 'Agency' }}
          >Todos
          </Link>,
          'programas-todos'
        ),
        getItem(
          <Link
            to='/'
            state={{ menuItemSelected: 'programas-activar', openSub: 'programas', type: 'Institution' }}
          >Activar
          </Link>,
          'programas-activar'
        )
      ]
    ),
    getItem(
      <Link to={URL_CREATE_USERS_PAGE} state={{ menuItemSelected: 'users' }}>Users</Link>,
      'users', <UsersIcon />
    ),
    getItem(
      'Accounts',
      'accounts',
      <UsersIcon />,
      [
        getItem(
          <Link
            to={URL_USERS_PAGE}
            state={{ menuItemSelected: 'accounts-angency', openSub: 'accounts', type: 'Agency' }}
          >Agency
          </Link>,
          'accounts-angency'
        ),
        getItem(
          <Link
            to={URL_USERS_PAGE}
            state={{ menuItemSelected: 'accounts-schools', openSub: 'accounts', type: 'Institution' }}
          >Schools
          </Link>,
          'accounts-schools'
        ),
        getItem(
          <Link
            to={URL_USERS_PAGE}
            state={{ menuItemSelected: 'accounts-education', openSub: 'accounts', type: 'Education group' }}
          >Educational groups
          </Link>,
          'accounts-education'
        )
      ]
    )
  ]
}

export default SuperAdminMenuItems
