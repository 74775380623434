import { useState } from 'react'
import stateControl from '../../utils/state-control'
import { useMutation } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function useForceDeleteCompany () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(AdminRepository.forceDeleteCompany, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const forceDeleteCompany = ({ company, type }) => {
    mutation.mutate({ company, company_type: type })
  }

  return { ...mutation, forceDeleteCompany, currentState, stateControl }
}

export { useForceDeleteCompany }
