import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SessionService from '../../../services/SessionService'
import { URL_QUOTER_PAGE } from '../../quoter'
import { MainRegisterFieldsContainer } from '../../register/components/steps/main-register/MainRegisterFieldsContainer'
import { URL_REGISTER_AGENCY_AB_PAGE } from '../RegisterABAgencyPage'
import { useRegisterUserAB } from '../../../bussines/hooks/user/useRegisteABAgency'
import stateControl from '../../../bussines/utils/state-control'

function RegisterUserABForm ({ email, type, role, invitation = false }) {
  const navigate = useNavigate()
  const [redirect, setRedirect] = useState(true)
  const [temp, setTemp] = useState({
    password: process.env.REACT_APP_PASSWORD || '',
    confirm: process.env.REACT_APP_PASSWORD || '',
    email,
    type,
    role
  })
  const { registerApplyBoardUser, isLoading, error, currentState } = useRegisterUserAB()

  React.useEffect(() => {
    if (SessionService.existSession() && redirect) {
      navigate(URL_QUOTER_PAGE)
    }
  })

  const onFinish = (values) => {
    setRedirect(false)
    const state = {
      ...values,
      invitation,
      role,
      type: temp.type || values.type
    }
    setTemp(state)
    // navigate(URL_REGISTER_AGENCY_AB_PAGE, { state })
    registerApplyBoardUser(values)
  }

  useEffect(() => {
    if (!isLoading && currentState === stateControl.STATE_SUCCESS) {
      navigate(URL_REGISTER_AGENCY_AB_PAGE, { state: temp })
    }
  }, [isLoading, currentState, error])

  return (
    <Form layout='vertical' onFinish={onFinish} initialValues={temp}>
      <h4 className='byp-title' style={{ textAlign: 'center' }}>Register your user</h4>
      <MainRegisterFieldsContainer type='User' invitation={invitation} displayNameField loading={isLoading} />
    </Form>
  )
}

export { RegisterUserABForm }
