import { Form, Select } from 'antd'
import { useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { useGetTags } from '../../../../../bussines/hooks/tag/useGetTags'
const { Option } = Select

const SelectTagsFormItem = ({ name, label, type, ...rest }) => {
  const [searchFilter, setSearchFilter] = useState()
  const { data: tags } = useGetTags({ type, search: searchFilter })
  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      setSearchFilter(value)
      console.log('sending the request :x', key, value)
    }, 500)
  })
  return (
    <>
      <Form.Item
        name={name}
        label={label}
      >
        <Select
          className='w-100'
          mode='tags'
          onSearch={(value) => {
            debounceFilter('search', value)
          }}
          {...rest}
        >
          {tags?.data?.map((tag) => (
            <Option key={tag.name}>{tag.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export { SelectTagsFormItem }
