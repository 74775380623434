import { Button, Col, Form, Input, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import { AddAndRemoveListButtons } from '../../../pages/dashboards/Institution/components/AddAndRemoveListButtons'

const required = { required: true, message: 'This element is required' }

export function ListConceptDescriptionFormItem ({ name, form }) {
  return (
    <div className='w-100'>
      <Form.List
        name={name}
        initialValue={[
          {}
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={16}>
                <Col span={10}>
                  <Form.Item
                    name={[name, 'name']}
                    rules={[required]}
                  >
                    <Input
                      className='byp-input'
                      placeholder='Concept'
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Col>
                    <Form.Item
                      name={[name, 'description']}
                    >
                      <TextArea />
                    </Form.Item>
                  </Col>
                </Col>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Row>
            ))}
            {
              fields.length === 0 && (
                <Form.Item>
                  <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Form.Item>
              )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}
