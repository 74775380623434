import React from 'react'
import {
  Divider,
  Form,
  Input,
  Typography
} from 'antd'
import { SelectServicesCategoriesFormItem } from '../../../../../common/components/helper-form/SelectServicesCategoriesFormItem'
import { SelectCountriesFormItem } from '../../../../../common/components/helper-form/SelectCountriesFormItem'
import { usePostDeleteLogo } from '../../../../../bussines/hooks/school/usePostDeleteLogo'
import { API_URL } from '../../../../../config'
import { UploadLogo } from '../../../../../common/components/UploadLogo'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'
// import { CampusContainer } from './components/CampusContainer'
import { OfficesContainer } from './components/OfficesContainer'
import { GroupEducationFormItemList } from '../../../../../common/components/helper-form/GroupEducationFormItemList'

const { TextArea } = Input
const { Title } = Typography

function InstitutionProfileFieldsContainer ({ form, mode = 'register' }) {
  const profilePhotoUrl = form.getFieldValue('profile_photo_url')
  const servicesCategories = Form.useWatch('service_category', form)

  return (
    <>
      <Title level={5}>Basic information</Title>
      <br />
      <UploadLogo
        action={`${API_URL}/school/upload/logo`}
        usePostDeleteLogo={usePostDeleteLogo}
        profileUrl={profilePhotoUrl}
        form={form}
      />
      <Divider />
      <FloatLabel label='Institution name' value={Form.useWatch('name', form)}>
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'This field is required', whitespace: true }]}
        >
          <Input />
        </Form.Item>
      </FloatLabel>
      <FloatLabel label='Institution mail' value={Form.useWatch('email', form)}>
        <Form.Item
          name='email'
          rules={[{ required: true, message: 'This field is required', whitespace: true }]}
        >
          <Input />
        </Form.Item>
      </FloatLabel>
      <FloatLabel label='Institution about' value={Form.useWatch('description', form)}>
        <Form.Item name='description' rules={[{ required: true, message: 'This field is required', whitespace: true }]}>
          <TextArea rows={4} maxLength={600} />
        </Form.Item>
      </FloatLabel>

      <Title level={5}>Types of programs you offer</Title>

      <FloatLabel label='Select the programs you offer' value={servicesCategories}>
        <SelectServicesCategoriesFormItem
          mode='multiple'
          name='service_category'
          rules={[{ required: true, message: 'This field is required' }]}
        />
      </FloatLabel>

      <Title level={5}>
        Share us where your head office is located
      </Title>
      <OfficesContainer
        name='offices'
        form={form}
      />

      <GroupEducationFormItemList name='groups' displayEmail />

      <p> </p>
      <Title level={5}>
        Share us where do you receive studens from
        <small style={{ fontSize: '13px', fontStyle: 'italic' }}>
          {' '}(Maximum 5)
        </small>
      </Title>

      <FloatLabel
        label='Main markets on wich you focus your promotions:'
        value={Form.useWatch('accept_from', form)}
      >
        <SelectCountriesFormItem
          mode='multiple'
          name='accept_from'
          rules={[{ required: true, message: 'This field is required' }]}
          limit={5}
          countries={Form.useWatch('accept_from', form)}
        />
      </FloatLabel>
    </>
  )
}

export { InstitutionProfileFieldsContainer }
