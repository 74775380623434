import React from 'react'
import { Card, Tag, Typography } from 'antd'
import { getFrequencyLabel } from '../../../../../utils/frequencyUtils'
const { Title } = Typography

const TransportPricesView = ({ data, showJson, ...props }) => {
  if (showJson) {
    return (
      <pre style={{ maxHeight: '40vh', overflow: 'auto' }}>
        {JSON.stringify(data ?? {}, null, 2)}
      </pre>
    )
  }
  return (
    <Card {...props}>
      <Title level={5}>One Way Cost:  {data?.one_way_cost}</Title>
      <Title level={5}>Roundway Cost: {data?.two_ways_cost}</Title>
      <Title level={5}>Minor Costs</Title>
      {data?.minor_costs?.map((minorFee) => (
        <Tag key={minorFee.id}>
          <span className='byp-fw-bold'>{minorFee.name}: </span>
          <span> {minorFee.cost} {data.divisa} </span> <span>{getFrequencyLabel(minorFee.frequency)}</span>
        </Tag>
      ))}
    </Card>
  )
}

export { TransportPricesView }
