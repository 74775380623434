import { useQuery } from 'react-query'
import CampusRespository from '../../repositories/CampusRespository'

function useGetCampus (id) {
  return useQuery(['useGetCampus', id],
    () => CampusRespository.getFindCampus(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useGetCampus }
