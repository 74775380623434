import api from '../../services/api'

class TrasnportRepository {
  async find (id) {
    if (id === undefined) {
      return null
    }
    const response = await api.get(`/services/${id}`)
    return response.data
  }

  async get (filtersQuery) {
    const response = await api.get('/fees/transport/list?' + filtersQuery)
    return response.data
  }

  async getFeeCampusList (campus, fee, queryString) {
    if (campus === undefined || fee === undefined) {
      return
    }
    const response = await api.get(`/fees/${fee}/${campus}/sedes?${queryString}`)
    return response.data
  }

  async changeStatus ({ id }) {
    if (id === undefined) return null
    const response = await api.put(`/fees/${id}/activate`)
    return response.data
  }
}

export default new TrasnportRepository()
