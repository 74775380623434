import { useQuery } from 'react-query'
import LodgingRepository from '../../repositories/LodgingRepository'

function useGetFeesByCategoryAndSede (type, sede, category, year) {
  return useQuery(
    ['useGetFeesByCategoryAndSede', category, sede, year],
    () => LodgingRepository.getFeesByCategoryAndSede(type, sede, category, year), {
      retry: 2,
      enabled: !!category && !!sede && !!year
    }
  )
}

export { useGetFeesByCategoryAndSede }
