import { Button, Col, Form, Row, Select } from 'antd'
import React from 'react'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { SelectLanguageFormItem } from '../../../common/components/helper-form/SelectLanguageFormItem'

const { Option } = Select
const required = { required: true, message: 'Este dato es requerido' }

function LanguagesFormItemList ({ disabled = false, listName = 'languages', className }) {
  return (
    <Form.List
      name={listName}
      className={className}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} style={{ width: '100%' }} gutter={16} align='middle'>
              <Col xs={24} md={12}>
                <label className='label-aplication'>Cuál</label>
                <span
                  className='byp-ml-1 contact-form_delete br-4'
                  style={{
                    border: 'solid 1px var(--red-50)',
                    borderRadius: '4px',
                    display: 'none',
                    // display: 'inline-flex',
                    width: '1.4rem',
                    height: '1.4rem',
                    padding: '.2rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => remove(index)}
                >
                  <RiDeleteBin6Fill color='var(--red-50)' />
                </span>
                <SelectLanguageFormItem
                  name={[name, 'language']}
                  disabled={disabled}
                  className='byp-select aplication__input-multiselect byp-p-0 transparent br-1'
                  size='large'
                />
              </Col>

              <Col xs={24} md={12}>
                <label className='label-aplication'>¿Qué nivel?</label>
                <Form.Item
                  {...restField}
                  name={[name, 'level']}
                  rules={[required]}
                  className='aplication__input'
                >
                  <Select
                    className='byp-select transparent br-1 aplication__input byp-p-0 w-100'
                    size='large'
                    disabled={disabled}
                  >
                    <Option key='basic' value='basic'>Básico</Option>
                    <Option key='medium' value='medium'>Medio</Option>
                    <Option key='Advanced' value='Advanced'>Avanzado</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Col span={24}>
            <Button className='byp-btn-gray-300 br-06 fs-12 fw-5' size='large' onClick={() => add()} disabled={disabled}> {/*  icon={<PlusOutlined />} */}
              Agregar otro idioma
            </Button>
          </Col>
        </>
      )}
    </Form.List>
  )
}

export default LanguagesFormItemList
