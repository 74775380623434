import * as React from 'react'

export const DesktopIcon = (props) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.688 0a1.312 1.312 0 00-1.313 1.313v.875A1.313 1.313 0 005.688 3.5h2.625a1.313 1.313 0 001.312-1.313v-.874A1.313 1.313 0 008.312 0H5.688zm2.625.875a.438.438 0 01.437.438v.875a.437.437 0 01-.438.437H5.688a.437.437 0 01-.438-.438v-.874a.437.437 0 01.438-.438h2.625z"
      />
      <path
        d="M3.5 1.313h-.875a1.75 1.75 0 00-1.75 1.75v9.187A1.75 1.75 0 002.625 14h8.75a1.75 1.75 0 001.75-1.75V3.062a1.75 1.75 0 00-1.75-1.75H10.5v.875a2.188 2.188 0 01-2.188 2.188H5.688A2.188 2.188 0 013.5 2.187v-.874zM8.75 7a.875.875 0 011.75 0v4.375a.875.875 0 11-1.75 0V7zM3.5 10.5a.875.875 0 111.75 0v.875a.875.875 0 11-1.75 0V10.5zM7 7.875a.875.875 0 01.875.875v2.625a.875.875 0 11-1.75 0V8.75A.875.875 0 017 7.875z"
      />
    </svg>
  )
}
