import { useQuery } from 'react-query'
import UserRepository from '../../repositories/UserRepository'

function useGetUsers (filters = {}) {
  return useQuery(['useGetUsers', filters],
    () => UserRepository.getUser(filters), {
      retry: 1
    })
}

export { useGetUsers }
