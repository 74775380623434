import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from './img/error503.png'
import logoquote from '../../common/assets/images/byp_logo_div.png'
import { URL_QUOTER_PAGE } from '../quoter'

const ServiceUnavailable = () => {
  return (
    <Row>
      <Col span={12} className='error-image'>
        <img src={logo} alt='500 error' style={{ width: '100%', height: '100%' }} />
      </Col>
      <Col span={12} className='error-content'>
        <img src={logoquote} alt='logo qyp' className='error__img' />
        <h1 className='byp-mt-1 byp-mb-1 byp-title-1 byp-fw-bold'>Service unavailable</h1>
        <p className='byp-text-md'>Sorry, our server is currently unavailable because it is overloaded,<br />
        or under maintenance. Try coming back later.</p>
        <Link
          to={URL_QUOTER_PAGE}
        >
          <button className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button' style={{ background: '#2460b7', color: '#f4f4f4', border: '1px solid #1890FF' }}>
            Go back
          </button>
        </Link>
      </Col>
    </Row>
  )
}

export { ServiceUnavailable }