import api from '../../services/api'
class PackagesRepository {
  static async packages (params) {
    const response = await api.get('/packages', { params })
    return response.data
  }

  static async getPackage (id) {
    const response = await api.get(`/packages/${id}`)
    return response.data
  }

  static async getPackageYears (id) {
    const response = await api.get(`/packages/${id}/years`)
    return response.data
  }

  static async deletePackage ({ id }) {
    const response = await api.delete(`/packages/${id}`)
    return response.data
  }

  static async getPrices (id, params) {
    const response = await api.get(`/packages/${id}/prices`, { params })
    return response.data
  }

  static async savePrices ({ id, data }) {
    const response = await api.post(`/packages/${id}/prices`, data)
    return response.data
  }

  static async packagesOffices () {
    const response = await api.get('/packages/offices')
    return response.data
  }
}
export default PackagesRepository
