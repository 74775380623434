import React from 'react'
import { Card } from 'antd'
import { Map } from '../../../common/components/Map'

export const QuoteLocation = ({ campus, t }) => {
  return (
    <Card  bordered={false} style={{marginBottom: "13px", height:'100%'}}>
      <h4 >You'll be here</h4>
      <p>{campus.address}</p>

      <div>
        <Map address={campus.address} coords={{ lat: campus.coords?.lat, lng: campus.coords?.lng }} addressChange={console.log} draggable={false} showInput={false} />
      </div>

    </Card>
  )
}
