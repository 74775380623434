import { useState } from 'react'
import stateControl from '../../utils/state-control'
import { useMutation, useQuery } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function usePutConvertCampusInstitution () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(AdminRepository.convertCampusToInstitution, {
    onSuccess: d => { setCurrentState(stateControl.STATE_SUCCESS) },
    onError: (e) => { setCurrentState(stateControl.STATE_ERROR) }
  })

  const convert = (id) => {
    mutation.mutate(id)
  }

  return { ...mutation, convert, currentState, stateControl }
}

function useUpdateProfileGus () {
  return useQuery(['useUpdateProfileGus'],
    () => AdminRepository.updateProfileGus(), {
      retry: 1
    })
}

export { usePutConvertCampusInstitution, useUpdateProfileGus }
