import { useQuery } from 'react-query'
import CourseRepository from '../../../repositories/CourseRepository'

function useGetCourseForPublish (campus, course, year) {
  return useQuery(['useGetCourseForPublish', campus, course, year],
    () => CourseRepository.getCourseForPublish(campus, course, year), {
      retry: 2,
      disabled: !!campus || !!course || !!year
    })
}

export { useGetCourseForPublish }
