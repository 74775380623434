import { QuoteGallery } from '../../../quote-preview/components/QuoteGallery'
// import { QuoteInfo } from '../../../quote-preview/components/QuoteInfo'
import { Checkbox, Button } from 'antd'
import { TemplateA } from './components/TemplateA'
import { TemplateB } from './components/TemplateB'

const styleSelected = {
  order: '1px solid var(--blue-200)',
  color: 'var(--blue-200)',
  boxShadow: '1px 1px 0px 0px',
  fontWeight: 'bold'
}

// const getBGStyle = (gallery, index) => ({
//   backgroundImage: `url(${gallery[index] ? gallery[index].url : null})`
// })

const getBGStyle = (gallery, index) => {
  if (!gallery) {
    return {
      backgroundColor: 'dark'
    }
  }
  return {
    backgroundImage: `url(${gallery[index] ? gallery[index].url : null})`
  }
}

function WebQuotePreview ({
  gallery = [], footGallery, campusGallery, numberPage,
  changePage, activeTemplate, changeActiveTemplate, videoUrl
}) {
  const campusG = (gallery) => (
    <div className='campus-grid-container' style={{ padding: '1rem', maxHeight: '10rem' }}>
      <div className='campus1 campus-item' style={getBGStyle(gallery, 0)} />
      <div className='campus2 campus-item' style={getBGStyle(gallery, 1)} />
      <div className='campus3 campus-item' style={getBGStyle(gallery, 2)} />
    </div>
  )

  function onChange () {
    changeActiveTemplate(numberPage)
  }

  function Template3 () {
    return (
      <div className={numberPage !== 3 ? 'display-none' : ''}>
        {gallery && <QuoteGallery gallery={gallery} />}
      </div>
    )
  }

  function ButtonChooseTemplate () {
    return (
      <div style={{ backgroundColor: 'lightgray', display: 'flex', justifyContent: 'space-between', padding: '5px 10px' }}>
        <div style={{ fontWeight: '600', color: 'GrayText' }}>Customization - Template {numberPage}</div>
        <div style={{ fontWeight: '600', display: 'flex', gap: '5px' }}>
          <Checkbox onChange={onChange} checked={activeTemplate === numberPage} />
          Activate template
        </div>
      </div>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px ', marginTop: '30px', marginBottom: '1rem' }}>
        <Button>
          &lt;
        </Button>
        <Button
          onClick={() => { changePage(1) }}
          className={numberPage === 1 ? '' : ''}
          style={numberPage === 1 ? styleSelected : {}}
        >
          1
        </Button>
        <Button
          onClick={() => { changePage(2) }}
          className={numberPage === 2 ? '' : ''}
          style={numberPage === 2 ? styleSelected : {}}
        >
          2
        </Button>
        <Button
          onClick={() => { changePage(3) }}
          className={numberPage === 3 ? '' : ''}
          style={numberPage === 3 ? styleSelected : {}}
        >
          3
        </Button>
        <Button>
          &gt;
        </Button>
      </div>
      <ButtonChooseTemplate />
      {numberPage === 1 && (
        <TemplateB
          gallery={gallery}
          footGallery={footGallery}
          campusGallery={campusGallery}
          campusG={campusG}
        />
      )}
      {numberPage === 2 && (
        <TemplateA
          gallery={gallery}
          footGallery={footGallery}
          campusGallery={campusGallery}
          campusG={campusG}
          videoUrl={videoUrl}
        />
      )}
      {numberPage === 3 && <Template3 />}
    </>
  )
}

export { WebQuotePreview }
