import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { URL_DASHBOARD_PAGE } from '../../dashboards'
import { URL_QUOTER_PAGE } from '../../quoter'

const FormFooter = ({ form, isLoading }) => {
  const navigate = useNavigate()
  const handleHomePage = () => {
    navigate(URL_DASHBOARD_PAGE)
  }
  return (
    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
      <Button
        onClick={handleHomePage}
        className='byp-link'
      >
        Back
      </Button>
      <Button
        className='byp-btn-blue-200'
        size='large'
        onClick={() => form.submit()}
        loading={isLoading}
        disabled={isLoading}
      > Save
      </Button>
    </div>
  )
}

const SuccessFooter = ({ form, isLoading }) => {
  const navigate = useNavigate()
  const handleHomePage = () => {
    navigate(URL_QUOTER_PAGE)
  }

  const handleDashboardPage = () => {
    navigate(URL_DASHBOARD_PAGE)
  }
  return (
    <div style={{ justifyContent: 'end', display: 'flex' }}>
      <Button
        onClick={handleDashboardPage}
        className='byp-btn-blue-100'
      >
        Go to dashboard
      </Button>
      <Button
        className='byp-btn-blue-200'
        // size='large'
        onClick={handleHomePage}
        loading={isLoading}
        disabled={isLoading}
      > Go home
      </Button>
    </div>
  )
}

export { FormFooter, SuccessFooter }
