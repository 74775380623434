import { Switch } from 'antd'
import { RowQuoter } from './RowQuoter'
import { usePackage } from '../../../providers/package/package-course-context'

export const FieldCourseGoods = () => {
  const { service, formConfig, setFormConfig } = usePackage()

  const handleChange = (value, id) => {
    let appliedGoods = []
    if (!formConfig.appliedGoods) {
      setFormConfig({
        ...formConfig,
        appliedGoods: []
      })
    }
    if (value) {
      appliedGoods = formConfig.appliedGoods ?? []
      appliedGoods?.push(id)
    } else {
      formConfig.appliedGoods?.map((fee) => (
        fee !== id ? appliedGoods.push(fee) : null
      ))
    }
    setFormConfig({
      ...formConfig,
      appliedGoods
    })
  }

  return (
    <>
      {service.getValidGoods().length > 0 && (
        service.getValidGoods().map((item) => (
          <RowQuoter
            key={item.id}
            colA={
              <>
                <p>
                  {item.name}
                  <Switch
                    onChange={(value) => { handleChange(value, item.id) }}
                    className='byp-ml-1'
                    checked={service.isGoodChecked(item.id)}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                  />
                </p>
                {item.description}
              </>
            }
            colB={
              <></>
            }
          />
        ))
      )}
    </>
  )
}
