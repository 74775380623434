import React, { useEffect } from 'react'
import { Button, Result, Typography, notification } from 'antd'
import { usePostResendVerificatonEmail } from '../../bussines/hooks/agency/usePostResendVerificatonEmail'
import { AppLogoNameHeader } from './AppLogoNameHeader'
import ABLogo from '../assets/images/ab_logo.png'
import { APPLYBOARD_AGENCY } from '../../config'
import { showErrorModal } from '../../utils/errorModal'

const { Text } = Typography
const lang = 'en'
const title = {
  es: 'Esperando a que valides tu correo.',
  en: 'Waiting for you to validate your email.'
}

function FullPageUserVerification ({ message = '', logout, company }) {
  const { resend, isLoading, isSuccess, isError } = usePostResendVerificatonEmail()
  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: 'Email send!'
      })
    } else if (isError) {
      showErrorModal(undefined, 'There was an error sending the mail, plese try again')
    }
  }, [isSuccess, isError])
  return (
    <div className='byp-full-container'>
      <Result
        icon={
          <AppLogoNameHeader
            style={{ width: '50%' }}
            img={company?.name === APPLYBOARD_AGENCY ? ABLogo : undefined}
          />
        }
        title={title.en}
        extra={
          <div>
            <Button
              type='primary'
              ghost
              onClick={resend}
              disabled={isLoading}
              loading={isLoading}
            >
              {lang === 'es' && 'Reenviar correo de verificación'}
              {lang === 'en' && 'Resend verification email'}
            </Button>
            <Button
              type='primary'
              ghost
              onClick={logout}
              style={{ marginLeft: '1rem' }}

            >
              {lang === 'es' && 'Salir'}
              {lang === 'en' && 'Exit'}
            </Button>
            <div style={{ padding: '2rem' }}>
              <Text className='byp-fw-bold byp-title'>
                {/* {
                  isSuccess && 'Email send!'
                }
                {
                  isError && 'There was an error sending the mail, plese try again'
                } */}
              </Text>
            </div>
          </div>
        }
      />
    </div>
  )
}

export { FullPageUserVerification }
