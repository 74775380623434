import { Col, Form, InputNumber, Row, Statistic, Tag } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
// import moment from 'moment'

function SelectExtraNightStartAndDuration () {
  const { service, formConfig, setFormConfig } = useQuoter()

  return (
    <>
      {service.includeLodging() && (
        <RowQuoter
          colA={
            <Row gutter={12}>
              <Col xs={8} md={9}>
                <p style={{ marginBottom: '0' }}>Start: {service.getLodgingEndString()}</p>
                <p style={{ marginBottom: '0', marginTop: '.5rem' }}><i>End: {service.getLodgingExEndString()}</i></p>
              </Col>
              <Col xs={8} md={9}>
                <Form.Item
                  name='lodgingExtraNights'
                  rules={[{
                    required: true,
                    message: 'This field is required'
                  }]}
                  label='Duration'
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder='1'
                    onChange={(nNights) => {
                      setFormConfig({
                        ...formConfig,
                        lodgingExtraNights: nNights
                      })
                    }}
                    min={1}
                    max={7}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} md={6}>
                <Tag style={{ padding: '.44rem' }}>Nights</Tag>
              </Col>
            </Row>
          }
          colB={
            <>
              <Statistic
                prefix=''
                precision={2}
                // className={
                //   service.getLodgingExHighSeasonAmount() !== service.getAmountLodgingExtNight() ? 'price-before' : null
                // }
                value={`${service.getLodgingExAmounts()?.ls_cost}`}
                suffix={`${service.getCurrency()}`}
              />
            </>
          }
        />
      )}
      {service.includeLodging() && service.getLodginExgIsHighSeason()?.details?.length > 0 && (
        <RowQuoter
          colA={// Peak Seasons
            service.getLodginExgIsHighSeason()?.details?.map(
              (hs) => <p key={'hs_label' + hs.id}>high Season cost From {hs.start} to {hs.end}, {hs.days} days </p>
            )
          }
          colB={
            service.getLodgingExAmounts()?.hs_cost > 0 && (
              <Statistic
                prefix=''
                precision={2}
                value={`${service.getLodgingExAmounts()?.hs_cost}`}
                suffix={`${service.getCurrency()}`}
              />
            )
          }
        />
      )}
    </>
  )
}

export { SelectExtraNightStartAndDuration }
