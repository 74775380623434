import React from 'react'
import { StepProvider } from '../../providers/stepbystep/step-service-provider'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ProfileEnterpriseProvider } from '../../providers/enterprise/profile-enterprise-provider'
import { useGetEducationGroupProfile } from '../../bussines/hooks/education-group/useGetEducationGroupProfile'
import { EducationGroupStepRouter } from './components/EducationGroupStepRouter'
import { useGetEducationgroupStep } from '../../bussines/hooks/education-group/useGetEducationgroupStep'
import { usePostStepEducationGroup } from '../../bussines/hooks/education-group/usePostStepEducationGroup'
import { useUpdateEducationGroupProfile } from '../../bussines/hooks/education-group/useUpdateEducationGroupProfile'

const URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP = '/step-register/education-group'

function EduactionGroupStepByStepRegister () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <StepProvider useGetStep={useGetEducationgroupStep} usePostSteps={usePostStepEducationGroup} useGetCompanyProfile={useGetEducationGroupProfile}>
        <ProfileEnterpriseProvider useGetCompanyProfile={useGetEducationGroupProfile} usePostUpdateCompanyProfile={useUpdateEducationGroupProfile}>
          <EducationGroupStepRouter />
        </ProfileEnterpriseProvider>
      </StepProvider>
    </PageProtected>
  )
}

export { EduactionGroupStepByStepRegister, URL_STEP_BY_STEP_PAGE_EDUCATION_GROUP }
