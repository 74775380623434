import { useMutation } from 'react-query'
import state from '../../utils/state-control'
import { useState } from 'react'
import PlanRepository from '../../repositories/PlanRepository'

function usePostAssignPlan () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(PlanRepository.assignPlan, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param plan Plan id
   * @param company Company id
   * @param type Company type
   */
  const assignPlan = (plan, company, type) => {
    mutation.mutate({ plan_id: plan, company_id: company, company_type: type })
  }

  return { ...mutation, state, currentState, assignPlan }
}

export { usePostAssignPlan }
