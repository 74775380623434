import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../repositories/GeneralAnalysisRepository'

function useGetIntake ({ ...filters }) {
  const allFilters = { ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetIntake', filtersQuery],
    () => GeneralAnalysisRepository.getIntakes(filtersQuery)
  )
}

export { useGetIntake }
