import { Col, Row } from 'antd'
import { AppLogoNameHeader } from '../../components/AppLogoNameHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import { URL_ABOUT_TERMS_AND_CONDITIONS_PAGE } from '../../../pages/about/TermsAndConditionsPage'
import { URL_ABOUT_POLICY_PAGE } from '../../../pages/about/PolicyPage'
import { URL_PRICING_AND_FAQ_PAGE } from '../../../pages/pricingandfaq/PricingAndFAQ'
import { URL_ABOUT_PAGE } from '../../../pages/about/AboutPage'

const style = { padding: '8px 0' }

const MainThemeFooter = () => {
  return (
    <Row className='footer-content'>
      <Col className='gutter-row byp-jst-rsp-cnt byp-footer-center ' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }}>
        <div style={style}>
          <AppLogoNameHeader className='logo-small-footer byp-mt-1' />
          <br /><br />
          <p style={{ fontSize: '12px' }}>
            All Rights Reserved Quote YOUR PROGRAM 2022
          </p>
          <div className='home-links-responsive'>
            <Link
              to={URL_ABOUT_PAGE}
              state={{}}
            >
              <span className='align-icon-txt'>Contact Us</span>
            </Link>
            <Link
              to={URL_PRICING_AND_FAQ_PAGE}
              state={{}}
            >
              <span className='align-icon-txt'>FAQ’s</span>
            </Link>
            <Link
              to={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE}
              state={{}}
            >
              <span className='align-icon-txt'>Terms and Conditions</span>
            </Link>
            <Link
              to={URL_ABOUT_POLICY_PAGE}
              state={{}}
            >
              <span className='align-icon-txt'>Privacy Policy</span>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export { MainThemeFooter }
