export const studyMethod = {
  es: {
    'full time': 'Tiempo completo',
    'part time': 'Medio Tiempo'
  },
  pt: {
    'full time': 'Tempo completo',
    'part time': 'Meio tempo'
  }
}

export const programLenght = {
  es: {
    days: 'Días',
    weeks: 'Semanas',
    months: 'Meses',
    semester: 'Semestres',
    years: 'Años'
  },
  pt: {
    days: 'Dias',
    weeks: 'Semanas',
    months: 'Meses',
    semester: 'Semestres',
    years: 'Anos'
  }
}

export const studyArea = {
  es: {
    Business: 'Negocios',
    Arts: 'Artes',
    Sciences: 'Ciencias',
    Humanities: 'Humanidades',
    'Health & Community Studies': 'Salud y Servicio Comunitario',
    Other: 'Otros'
  },
  pt: {
    Business: 'Negócios',
    Arts: 'Artes',
    Sciences: 'Ciência',
    Humanities: 'Humanidades',
    'Health & Community Studies': 'Saúde e serviço comunitário',
    Other: 'Outros'
  }
}

export const programIntakes = {
  es: {
    Spring: 'Primavera',
    Summer: 'Verano',
    Fall: 'Otoño',
    Winter: 'Invierno'
  },
  pt: {
    Spring: 'Primavera',
    Summer: 'Verão',
    Fall: 'Outono',
    Winter: 'Inverno'
  }
}

export const courseLanguage = {
  es: {
    English: 'Inglés',
    Spanish: 'Español',
    Portuguese: 'Portugués',
    Italian: 'Italiano',
    French: 'Francés',
    German: 'Alemán',
    Arabic: 'Árabe'
  },
  pt: {
    English: 'Inglês',
    Spanish: 'Espanhol',
    Portuguese: 'Português',
    Italian: 'Italiano',
    French: 'Francês',
    German: 'Alemão',
    Arabic: 'Árabe'
  }
}

export const modalityCourse = {
  es: {
    Presential: 'Presencial',
    Online: 'Online',
    Hybrid: 'Híbrido'
  },
  pt: {
    Presential: 'Presencial',
    Online: 'Online',
    Hybrid: 'Híbrido'
  }
}

export const institutionTypes = {
  es: {
    'Public University': 'Universidad pública',
    'Private University': 'Universidad privada',
    'Public College': 'Colegio público',
    'Private College': 'Colegio privado',
    Private: 'Privado',
    Public: 'Pública'
  },
  pt: {
    'Public University': 'Universidade Pública',
    'Private University': 'Universidade Privada',
    'Public College': 'Faculdade Pública',
    'Private College': 'Faculdade Particular',
    Private: 'Particular',
    Public: 'Pública'
  }
}

export const waysTransport = {
  es: {
    'One Way': 'Ida',
    'Round Way': 'Ida y vuelta'
  }
}

export const courseDurationType = {
  es: {
    'Academic Year': 'Universidad pública',
    'Semester Program': 'Universidad privada',
    'Short term Program': 'College público'
  },
  pt: {
    'Academic Year': 'Universidade Pública',
    'Semester Program': 'Universidade Privada',
    'Short term Program': 'Faculdade Pública'
  }
}

export const programLabels = {
  es: {
    language: 'Idiomas',
    academic_years: 'Año académico',
    boarding_schools: 'Boarding'
  },
  pt: {
    language: 'Idiomas',
    academic_years: 'Año académico',
    boarding_schools: 'Boarding'
  }
}
