import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

function FullPageSpinner ({ title = 'Loading...' }) {
  return (
    <div className='App'>
      <div className='full-page-container'>
        <LoadingOutlined />
        <p>{title}</p>
      </div>
    </div>
  )
}

export { FullPageSpinner }
