import { Carousel, Col, Image, Row } from 'antd'
import { FooterGallery } from '../../../../quote-preview/components/FooterGallery'
import greeting from '../../../../../common/assets/images/quote-pictures-custom-1.png'
import costs from '../../../../../common/assets/images/quote-pictures-custom-2.png'
import map from '../../../../../common/assets/images/quote-pictures-custom-3.png'
import campus from '../../../../../common/assets/images/quote-pictures-custom-4.png'
import footer from '../../../../../common/assets/images/quote-pictures-custom-5.png'
import { slides } from '../../../../../common/theme/partials/MainThemeCarrousel'

const carouselHeight = '25vh'
const contentStyle = {
  height: carouselHeight,
  color: '#fff',
  lineHeight: '100%',
  textAlign: 'center',
  backgroundColor: '#2a2f3394',
  fontSize: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 20px'
}

const TemplateB = ({ gallery = [], footGallery = [], campusGallery, campusG }) => {
  return (
    <div>
      <div className='quote-web-preview-container'>
        <div className='greeting'>
          <Image src={greeting} />
        </div>
        <div className='gallery'>
          {gallery?.length > 0 && (
            <Carousel
              autoplay
              effect='fade'
              dots={{ className: 'byp-carousel-dots' }}
              style={{ height: carouselHeight }}
            >
              {gallery?.map((slide) => (
                <div key={slide?.text_en}>
                  <div style={{
                    backgroundImage: 'url(' + slide?.image + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%'
                  }}
                  >
                    <h3 style={contentStyle}>
                      {slide?.text_en}
                    </h3>
                  </div>
                </div>
              ))}
            </Carousel>
          )}

          {gallery?.length === 0 && (
            <Carousel autoplay effect='fade' dots={{ className: 'byp-carousel-dots' }} style={{ height: carouselHeight }}>
              {slides?.map((slide) => (
                <div key={slide?.text_en}>
                  <div style={{
                    backgroundImage: 'url(' + slide?.image + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%'
                  }}
                  >
                    <h3 style={contentStyle}>
                      {slide?.text_en}
                    </h3>
                  </div>
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <Row>
          <Col span={15}><Image src={costs} /></Col>
          <Col span={9}>
            <Image src={map} />
            {campusGallery?.length > 0 && campusG(campusGallery)}
            <Image src={campus} />
          </Col>
        </Row>
        <div className='footer'>
          <Image src={footer} />
        </div>
      </div>
      {footGallery[0] && <FooterGallery footGallery={footGallery} />}
    </div>
  )
}

export { TemplateB }
