import React, { useState } from 'react'
import { Col, Divider, Form, Input, InputNumber, Row, Slider, Typography } from 'antd'
import { SelectPhoneCodeFormItem } from '../../../../../common/components/helper-form/SelectPhoneCodeFormItem'
import { SelectCurrencyFormItem } from '../../../../../common/components/helper-form/SelectCurrencyFormItem'
import { SelectAllCitiesFormItem } from '../../../../../common/components/helper-form/SelectAllCitiesFormItem'
import { SocialNetworksFormItem } from '../../../../../common/components/helper-form/SocialNetworksFormItem'
import { API_URL } from '../../../../../config'
import { UploadLogo } from '../../../../../common/components/UploadLogo'
import { usePostDeleteLogo } from '../../../../../bussines/hooks/agency/usePostDeleteLogo'
import FloatLabel from '../../../../../common/components/helper-form/FloatLabel'
import { PasswordFormItem } from '../../../../../common/components/helper-form/PasswordFormItem'
import { SelectCountryAndCityFormItem } from '../../../../../common/components/helper-form/SelectCountryAndCityFormItem'

const { TextArea } = Input
const { Title } = Typography

function AgencyProfileFieldsContainer ({ name, form, readOnly, displayPassword = false, useMainOffice = true, data }) {
  const profilePhotoUrl = form.getFieldValue('profile_photo_url')
  const yearsExperience = form.getFieldValue('years_of_experience')
  // console.log(form.getFieldsValue())
  const [years, setInputValue] = useState(yearsExperience || 0)
  const onChange = (newValue) => {
    setInputValue(newValue)
  }

  return (
    <>
      <Title level={5}><b>Basic information</b></Title>
      <br />
      <UploadLogo
        action={useMainOffice ? `${API_URL}/agency/upload/logo` : `${API_URL}/office/upload/logo`}
        usePostDeleteLogo={usePostDeleteLogo}
        profileUrl={profilePhotoUrl}
      />
      <Divider />
      <FloatLabel label='Agency name' value={Form.useWatch('name', form)}>
        <Form.Item
          name='name'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      <FloatLabel label='Agency email' value={Form.useWatch('email', form)}>
        <Form.Item
          name='email'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      {displayPassword && (
        <PasswordFormItem />
      )}

      <FloatLabel label='Agency about' value={Form.useWatch('description', form)}>
        <Form.Item
          name='description'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <TextArea rows={4} maxLength={600} readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      <Col span={12}>
        <div id='currency-select-1'>
          <SelectCurrencyFormItem
            placeholder='Select your currency'
            disabled={readOnly}
            name='divisa'
            label='Default currency:'
            rules={[{ required: true, message: 'This field is required' }]}
          />
        </div>
      </Col>

      <Title level={5}><b>Parent Agency Contact Information</b></Title>

      <FloatLabel label='Agency address' value={Form.useWatch('address', form)}>
        <Form.Item
          name='address'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input readOnly={readOnly} />
        </Form.Item>
      </FloatLabel>

      <SelectCountryAndCityFormItem
        name='location'
        countryAgency={data?.country}
      />
      <Row gutter={10}>
        <Col span={12}>
          Phone
        </Col>
        <Col span={12}>
          WhatsApp
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4}>
          <SelectPhoneCodeFormItem
            showSearch
            disabled={readOnly}
            name='phone_code'
          />
        </Col>
        <Col span={8}>
          <Form.Item
            name='tel'
            rules={[{
              pattern: /^([0-9])*$/,
              message: 'Phone must be a number.'
            }]}
          >
            <Input readOnly={readOnly} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SelectPhoneCodeFormItem
            showSearch
            disabled={readOnly}
            name='phone_code'
          />
        </Col>
        <Col span={8}>
          <Form.Item
            name='whatsapp'
            rules={[{
              pattern: /^([0-9])*$/,
              message: 'Phone must be a number.'
            }]}
          >
            <Input readOnly={readOnly} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        Website
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <Form.Item
            readOnly={readOnly}
            name='website'
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input readOnly={readOnly} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '50%' }}>
          <FloatLabel label='ZIP CODE' value={Form.useWatch('cp', form)}>
            <Form.Item
              readOnly={readOnly}
              name='cp'
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input readOnly={readOnly} />
            </Form.Item>
          </FloatLabel>
        </Col>
      </Row>

      {/* <Title level={5}>History</Title>
      <FloatLabel label='CEO name' value={Form.useWatch('ceo', form)}>
        <Form.Item
          readOnly={readOnly}
          name='ceo'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input readOnly={readOnly} />
        </Form.Item>
      </FloatLabel> */}

      {/* <FloatLabel label='Foundation year' value={Form.useWatch('foundation', form)}>
        <Form.Item
          readOnly={readOnly}
          name='foundation'
        >
          <InputNumber
            style={{ width: '100%' }}
            min={1900}
            max={2022}
          />
        </Form.Item>
      </FloatLabel> */}

      {/* <Title level={5}>Years of Experience</Title> */}

      {/* <Row gutter={16}>
        <Col span={4}>{years} years</Col>
        <Col span={20}>
          <Form.Item
            name='years_of_experience'
          >
            <Slider onChange={onChange} max={60} disabled={readOnly} />
          </Form.Item>
        </Col>
      </Row> */}
      <Title level={5}>Countries to where the agency sends students</Title>
      <FloatLabel label='Add countries' value={Form.useWatch('countries', form)}>
        <SelectAllCitiesFormItem
          disabled={readOnly}
          name='countries'
          mode='multiple'
          style={{ width: '100%' }}
        />
      </FloatLabel>

      <Title level={5}>Social Networks</Title>
      <SocialNetworksFormItem
        form={form}
        readOnly={readOnly}
        name='social_networks'
      />
    </>
  )
}

export { AgencyProfileFieldsContainer }
