import { useMutation } from "react-query";
import state from "../../../../utils/state-control";
import { useState } from "react";
import AgencyRepository from "../../../../repositories/AgencyRepository";

function useDownloadGeneralAnalysis() {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL);
  const mutation = useMutation(AgencyRepository.getMyDownloadGeneralAnalysis, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS);
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR);
    },
  });

  /**
   * nextStep
   * @param step
   * @param data
   */
  const downloadHistory = (time) => {
    mutation.mutate({ time });
  };

  return { ...mutation, state, currentState, downloadHistory };
}

export { useDownloadGeneralAnalysis };
