const ENTERPRISE_TYPES = {
  AGENCY: 'Agency',
  INSTITUTION: 'Institution',
  SERVICE: 'Service',
  EDUCATION_GROUP: 'Education group',
  SUPER_ADMIN_GROUP: 'AdminGroup',
  SUPER_ADMIN: 'Super Admin'
}

export default ENTERPRISE_TYPES
