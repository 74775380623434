import React, { useState } from 'react'
import { Col, Layout, Row } from 'antd'
import { AppLogoNameHeader } from '../components/AppLogoNameHeader'
import './../assets/css/main-theme.less'
import { MainThemeMenu } from './partials/MainThemeMenu'
import { MainThemeCarrouselWithImages } from './partials/MainThemeCarrousel'
import { MainThemeFooter } from './partials/MainThemeFooter'
import { UserMenuSwitcher } from './partials/UserMenuSwitcher'
import { useAuth } from '../../providers/auth/auth-context-provider'
import { LogoSchool } from '../assets/images/icons/LogoSchool'
import { ErrorBoundary } from '../../providers/validation/ErrorBoundary'
import { PhoneTheme } from './PhoneTheme'

const { Header, Footer, Content, Sider } = Layout

const MainTheme = ({ children, activeCarousel = false, showMenu = false, showMainTheme = true }) => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  const { company } = useAuth()
  return (
    <ErrorBoundary>
      <Layout className={!showMainTheme ? 'dontShowMenu' : 'layout'}>
        <Header className='header-theme logo headerLayout header-pc'>
          <Row style={{ paddingLeft: '50px' }} gutter={20}>
            <Col xs={24} md={24} lg={1} style={{ textAlign: 'center' }}>
              {company?.profile_photo_url && (
                // <img src={company?.profile_photo_url} style={{ maxWidth: '25%' }} />
                <AppLogoNameHeader
                  // className='logo-small'
                  img={company?.profile_photo_url}
                  style={{ maxWidth: '50px', cursor: 'pointer' }}
                />
              )}
              {!company?.profile_photo_url && (
                <AppLogoNameHeader className='logo-small' />
              )}
            </Col>
            <Col xs={24} md={24} lg={1} style={{ textAlign: 'center' }}>
              <label className='qyp-label-main'>BETA</label>
            </Col>
            <Col lg={2} className='byp-title byp-m-0'>
              <LogoSchool /> {company?.name}
            </Col>
            <Col
              xs={24}
              md={24}
              lg={18}
              className='header-bar'
            >
              <MainThemeMenu />
            </Col>
          </Row>
        </Header>
        <Header className='header-theme logo headerLayout header-tablet'>
          <Row>
            {/* <Col md={2} className='home-left-icon'>
              <HomeLeftIcon />
            </Col> */}
            <Col span={3} style={{ textAlign: 'center' }}>
              <AppLogoNameHeader className='logo-small' changeImage />
            </Col>
            <Col xs={3} sm={3} md={2} style={{ textAlign: 'center' }}>
              <label className='qyp-label-main'>BETA</label>
            </Col>
            <Col xs={18} sm={18} md={19} className='d-flex' style={{ justifyContent: 'space-between' }}>
              <p className='byp-title byp-m-0'><LogoSchool /> {company?.name}</p>
              <MainThemeMenu handleClick={handleClick} isActive={isActive} />
            </Col>
          </Row>
        </Header>
        <Layout className={`app ${isActive ? 'no-scroll' : ''}`}>
          <Row style={{ width: '100%' }}>
            {
              showMenu && (
                <Col md={4} lg={4} style={{ zIndex: 999, textAlign: 'center' }}>
                  <Sider
                    width='100%'
                    className=''
                    breakpoint='lg'
                    collapsedWidth='0'
                    onBreakpoint={(broken) => {
                    }}
                    onCollapse={(collapsed, type) => {
                    }}
                  >
                    <UserMenuSwitcher />
                  </Sider>
                </Col>
              )
              }
            <Col xs={24} md={24} lg={showMenu ? 20 : 24} className='main-menu-container'>
              <Content>
                {activeCarousel && <MainThemeCarrouselWithImages />}
                <Content className='site-layout-content'>{children}</Content>
              </Content>
            </Col>
          </Row>
        </Layout>
        {
          !showMenu && (
            <Footer className={`app ${isActive ? 'no-show-footer site-layout-footer' : 'site-layout-footer'}`}><MainThemeFooter /></Footer>
          )
        }
      </Layout>
      <PhoneTheme showMainTheme={showMainTheme} />
    </ErrorBoundary>
  )
}

export { MainTheme }
