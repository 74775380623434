import api from '../../services/api'

class QuoteRepository {
  static async findQuote (quoteId) {
    const response = await api.get(`/quote/${quoteId}/show`)
    return response.data
  }

  static async getAgency (params) {
    const response = await api.get('/student-quotes-enterprise', { params })
    return response.data
  }

  static async getBaseQuote (quoteId) {
    if (quoteId === undefined) return
    const response = await api.get(`/quote/${quoteId}/get-base-data`)
    return response.data
  }

  static async customQuoteView (data) {
    const response = await api.post('/quotes/custom', data)
    return response.data
  }

  static async getCustomedQuoteView (campus, type) {
    if (campus === undefined || type === undefined) return
    const response = await api.post(`/quotes/${campus}/customed-view?type=${type}`)
    return response.data
  }

  static async getEstimates (queryString) {
    const response = await api.get(`/quotes?${queryString}`)
    return response.data
  }

  static async markAsfavorite (id) {
    const response = await api.post(`/quotes/${id}/add-to-favorites`)
    return response.data
  }

  static async sendByEmail ({ id, data }) {
    const response = await api.post(`/quotes/${id}/send`, data)
    return response.data
  }

  static async applyForProgram ({ id, data }) {
    const response = await api.post(`/quotes/${id}/apply`, data)
    return response.data
  }

  // Client Quotes Public
  static async getQuotesByStudent ({ studentId, agentId, page }) {
    const response = await api.get(`/student-quotes-public?student_id=${studentId}&agent_id=${agentId}&page=${page}`)
    return response.data
  }

  static async getQuotesByStudentInactive ({ studentId, agentId }) {
    const response = await api.get(`/student-quotes-public/inactive?student_id=${studentId}&agent_id=${agentId}`)
    return response.data
  }

  // Client Quotes Auth
  static async getClientQuotes ({ studentId, agentId, page }) {
    const response = await api.get(`/student-quotes?student_id=${studentId}&agent_id=${agentId}&page=${page}`)
    return response.data
  }

  static async getClientQuotesInactive ({ studentId, agentId, page }) {
    const response = await api.get(`/student-quotes/inactive?student_id=${studentId}&agent_id=${agentId}&page=${page}`)
    return response.data
  }

  static async deleteStudentIdFromQuote (id) {
    const response = await api.delete(`/quotes/${id}/remove-student-id`)
    return response.data
  }

  static async rateQuote ({ id, rate }) {
    const response = await api.get(`/quotes/${id}/rate`, { params: { rate } })
    return response.data
  }

  static async paymentPage ({ quote, params }) {
    const response = await api.get(`/quotes/${quote}/pay`, { params })
    return response.data
  }

  static async paidQuotes (filters) {
    const response = await api.get('/user/quotes/paid', { params: filters })
    return response.data
  }
}

export default QuoteRepository
