import { useGetEducationGroupProfile } from '../../bussines/hooks/education-group/useGetEducationGroupProfile'
import { useUpdateEducationGroupProfile } from '../../bussines/hooks/education-group/useUpdateEducationGroupProfile'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { ProfileEnterpriseProvider } from '../../providers/enterprise/profile-enterprise-provider'
import { EditGroupEducationProfile } from '../register/components/forms/GroupEducation/EditGroupEducationProfile'

const URL_EDIT_GROUP_EDUCATION = '/group-eduction/profile'

function EditGroupEducationProfilePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <ProfileEnterpriseProvider useGetCompanyProfile={useGetEducationGroupProfile} usePostUpdateCompanyProfile={useUpdateEducationGroupProfile}>
        <EditGroupEducationProfile />
      </ProfileEnterpriseProvider>
    </PageProtected>
  )
}

export { EditGroupEducationProfilePage, URL_EDIT_GROUP_EDUCATION }
