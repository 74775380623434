import axios from 'axios'
import { VIMEO_API_URL, VIMEO_TOKEN } from '../config'

export const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/vnd.vimeo.*+json;version=3.4',
  Authorization: `Bearer ${VIMEO_TOKEN}`
}

const axiosInstance = axios.create({
  baseURL: VIMEO_API_URL,
  headers
})

axiosInstance.interceptors.request.use(function (config) {
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default axiosInstance
