import { Button, Col, Collapse, Row, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { URL_CUSTOMDISCOUNT_PAGE } from '../../../Institution/Discounts/CustomDiscountsPage'
import { format } from 'date-fns'
import moment from 'moment'
import { URL_PACKAGES_PAGE } from '../../../packages/PackagesPage'
import { URL_PACKAGES_PRICES_PAGE } from '../../../packages/PackagesPricesPage'

const { Panel } = Collapse

const frequencyLabel = (frequency) => {
  switch (frequency) {
    case 'unic':
      return 'Only Time'
    case 'week':
      return 'Weeks'
    case 'weeks':
      return 'Weeks'
    case 'monthly':
      return 'Monthly'
    case 'moth':
      return 'Monthly'
    case 'every 4 months':
      return 'Every 4 months'
    case 'semester':
      return 'semester'
    case 'anual':
      return 'Anual'
    default:
      return ''
  }
}

const costItem = (item) => {
  return (
    <Row key={item.id} className='w-100'>
      <Col span={6}>{item.name}</Col>
      <Col span={5}>
        <b>${item.cost}.00</b>
      </Col>
      <Col span={6}>Charging for:</Col>
      <Col><b>{frequencyLabel(item.frequency)}</b></Col>
      <Col span={6}>{item.description}</Col>
    </Row>
  )
}

const panelFooter = (onClick, url, state) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        className='float-right byp-btn-blue-100'
        onClick={() => { onClick(url, state) }}
      >
        Edit block
      </Button>
    </div>
  )
}

function RowInfo ({ label, data, azul }) {
  if (azul) {
    return (
      <Row className='w-100'>
        <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title byp-fw-bold'>{label}</p></Col>
        <Col style={{ color: 'steelblue' }} xs={12} md={8} lg={12}>{data}</Col>
      </Row>
    )
  } else {
    return (
      <Row className='w-100'>
        <Col xs={12} md={8} lg={5} xxl={4}><p className='byp-title byp-fw-bold'>{label}</p></Col>
        <Col xs={12} md={8} lg={12}>{data}</Col>
      </Row>
    )
  }
}

function PackageInfo ({ course, divisa, activeKeys, setActiveKeys, onClick, category, yearId }) {
  const [discountId, setDiscountId] = useState()
  const [lenght, setLenght] = useState()

  const onChange = (values) => {
    setActiveKeys(values)
  }

  useEffect(() => {
    course?.discounts?.some((item) => {
      setDiscountId(item.id)
      return !!item.id
    })
    const start = moment(course.start_at)
    const end = moment(course.end_at)
    const lenghtDate = end - start
    const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
    setLenght(differenceWeeks + ' weeks')
  }, [course])

  return (
    <Collapse
      defaultActiveKey={activeKeys}
      expandIconPosition='left'
      onChange={onChange}
      activeKey={activeKeys}
    >
      <Panel header='Discounts and promotions' key='discounts'>
        {course?.discounts?.map((discount) => (
          <React.Fragment key={discount.id}>
            <h6 className='byp-title' style={{ textTransform: 'uppercase' }}>{discount.name}</h6>
            <Row>
              <Col xs={12} md={8} lg={4}><b>Promotions start on</b></Col>
              <Col xs={12} md={8} lg={4}>{discount.start}</Col>
              <Col xs={12} md={8} lg={4}><b>Limit date for aplication</b></Col>
              <Col xs={12} md={8} lg={4}>{discount.end}</Col>
              <Col xs={12} md={8} lg={4}><b>Course needs to start on</b></Col>
              <Col xs={12} md={8} lg={4}>{discount.service_start} - {discount.service_start_limit}</Col>
            </Row>
          </React.Fragment>
        ))}
        {panelFooter(
          onClick,
          URL_CUSTOMDISCOUNT_PAGE.replace(':program', category),
          {
            menuItemSelected: category + '_discounts_prices',
            openSub: category,
            openSub2: category + '_discounts',
            // year: '2022',
            discountId
          }
        )}
      </Panel>
      <Panel header='Package information' key='course_prices'>
        <RowInfo label='Package name' data={course?.name} />
        <RowInfo label='Description' data={course?.description} />
        <RowInfo label='Program' data={course?.program.label} />
        <RowInfo label='Package starts' data={course?.start_at} />
        <RowInfo label='Package ends' data={course?.end_at} />
        {
          !Array.isArray(course?.language) && (
            <RowInfo label='Language' data={course?.language} />
          )
        }
        {/* <RowInfo label='Minimum age' data={course?.min_age + ' years'} /> */}
        <RowInfo label='Length' data={lenght} />
        <RowInfo label='Available spots' data={course.spots??'Unlimited'} />
        {/* <RowInfo
          label='Start dates'
          data={
            course.start_dates?.map((dates) => (
              <Tag key={dates.date}>{dates.date}</Tag>
            ))
          }
        /> */}
        <RowInfo
          label='Start dates'
          data={
            course.years?.map((dates) => {
              const start = new Date(dates.start)
              const end = new Date(dates.end)
              return (
                <p key={'course_dates_' + dates}>
                  {/* <b>{dates.year}:</b> {format(start, 'dd MMMM, yyyy')} - {format(end, 'dd MMMM, yyyy')} */}
                  {format(start, 'dd MMMM, yyyy')} - {format(end, 'dd MMMM, yyyy')}
                </p>
              )
            })
          }
        />
        <RowInfo
          label='Publication dates'
          data={
            course.years?.map((dates) => {
              const start = new Date(dates.prices_start)
              const end = new Date(dates.prices_end)
              return (
                <p key={'course_dates_' + dates}>
                  {/* <b>{dates.year}:</b> {format(start, 'dd MMMM, yyyy')} - {format(end, 'dd MMMM, yyyy')} */}
                  {format(start, 'dd MMMM, yyyy')} - {format(end, 'dd MMMM, yyyy')}
                </p>
              )
            })
          }
        />
        {Array.isArray(course.intake_date) && (
          <RowInfo
            label='Program intake dates'
          />
        )}

        <RowInfo label='Package link' data={course?.link} />
        <RowInfo label='Additional comments' data={course?.comments} />
        <hr className='byp-hr' />
        {
          panelFooter(
            onClick,
            URL_PACKAGES_PAGE,
            {
              menuItemSelected: 'packages',
              openSub: 'packages',
              openSub2: '_courses',
              packageID: course.id
            }
          )
        }
      </Panel>
      <Panel header='General prices' key='base_prices'>
        <Row className='w-100'>
          <Col xs={12} md={8} lg={3}><p className='byp-title byp-fw-bold'>Student regions</p></Col>
          <Col xs={12} md={8} lg={5}>
            {course?.regions?.map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Col>
          <Col xs={12} md={8} lg={3}><p className='byp-title byp-fw-bold'>Student subregions</p></Col>
          <Col xs={12} md={8} lg={5}>
            {course?.subregions?.map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Col>
          <Col xs={12} md={8} lg={3}><p className='byp-title byp-fw-bold'>Excluded countries</p></Col>
          <Col xs={12} md={8} lg={5}>
            {course?.excluded_countries?.map((item) => (
              <Tag key={item.id}>{item.name}</Tag>
            ))}
          </Col>
        </Row>
        <RowInfo label='Student location:' data={course?.student_location} azul='Si' />

        <hr className='byp-hr' />

        <h6 className='byp-title' style={{ fontWeight: '700' }}>Package cost</h6>
        {course?.weeks?.map((item) => (
          costItem(item, divisa)
        ))}
        {!!course.cost && (
          <Row className='w-100'>
            <Col span={6}>Whole cost</Col>
            <Col span={5}>
              <b>${course.cost.cost}.00</b>
            </Col>
          </Row>
        )}

        {/* <Row>
          <Col span={1}>
            Divisa:
          </Col>
          <Col>
            <b>{divisa}</b>
          </Col>
        </Row> */}

        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Includes</h6>
        {course?.additionals?.map((item) => (
          <p key={item.id}>{item.name}</p>
        ))}
        <hr className='byp-hr' />
        <h6 className='byp-title' style={{ fontWeight: '700' }}>Additional comments</h6>
        <p>{course?.comments}</p>
        {panelFooter(
          onClick,
          URL_PACKAGES_PRICES_PAGE,
          {
            menuItemSelected: category + '_general',
            openSub: category,
            year: course.year,
            packageID: course.id
          }
        )}
      </Panel>
    </Collapse>
  )
}

export { PackageInfo }
