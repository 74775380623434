import { Form } from 'antd'
import React from 'react'
import { useStepByStep } from '../../../../providers/stepbystep/step-service-provider'
import { InstitutionProfileFieldsContainer } from '../steps/intitution-profile/InstitutionProfileFieldsContainer'
import PropTypes from 'prop-types'
import { useProfileEnterprise } from '../../../../providers/enterprise/profile-enterprise-provider'
/**
 * InstitutionFormStepProfile
 * @param currentStep
 * @returns {*}
 * @constructor
 */
function InstitutionFormStepProfile ({ currentStep }) {
  const { loadingProfile, initValues } = useProfileEnterprise()
  const { form, handleOnFinish } = useStepByStep()

  const onFinish = (values) => {
    handleOnFinish(currentStep, values)
  }

  if (loadingProfile || !initValues) {
    return <p>Cargando datos...</p>
  }

  return (
    <Form
      form={form}
      layout='vertical'
      scrollToFirstError
      name='InstitutionFormStepProfile'
      onFinish={onFinish}
      initialValues={initValues}
    >
      <InstitutionProfileFieldsContainer form={form} />
    </Form>
  )
}

InstitutionFormStepProfile.propTypes = {
  currentStep: PropTypes.number.isRequired
}

export { InstitutionFormStepProfile }
