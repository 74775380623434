import { Button, Input, Select, Table, Tag, Switch, Form, Modal, Checkbox, notification } from 'antd'
import utilsState from '../../../../bussines/utils/state-control'
import { useGetCampusDataTable } from '../../../../bussines/hooks/school/useGetCampusDataTable'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { URL_APPROVE_QUOTES_PAGE } from '../ApproveQuotes/ApproveQuotesPage'
import { URL_COURSEPRICES_CREATE_PAGE } from '../CoursePrices/CreateCoursePricesPage'
import { URL_LODGINGPRICES_CREATE_PAGE } from '../LodgingPrices/CreateLodgingPricesPage'
import { URL_TRANSPORTGPRICES_CREATE_PAGE } from '../TransportPrices/CreateTransportPricesPage'
import { URL_UPLOAD_PRICES_PAGE } from '../Prices/UploadPricesPage'
import { PUBLISH_QUOTES_STATES } from '../../../../bussines/utils/url-page-states'
import { usePostPublishCourse } from '../../../../bussines/hooks/school/course/usePostPublishCourse'
import { useSearch } from '../../../../providers/search/search-context-provider'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { SelectServiceByCategory } from '../../../../common/components/helper-form/SelectServiceByCategory'
import { URL_CUSTOMDISCOUNT_PAGE } from '../Discounts/CustomDiscountsPage'
import { SelectYearByCampus } from '../../../../common/components/helper-form/SelectYearByCampus'
import { classStyleByStatus } from '../../../../utils/tagStatusClass'
import { getLabel, getLinkClass } from '../../../../utils/StatusTagsUtils'
import { format } from 'date-fns'
import { usePostPublishOffers } from '../../../../bussines/hooks/school/offers/usePostPublishOffers'

const { Column } = Table
// const { Search } = Input
const { Option } = Select

function getClass (status) {
  return classStyleByStatus[status]
}

function TableQuotesCategory (props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isThereError, setError] = useState(false)
  const [form] = Form.useForm()
  const [finish, isFinished] = useState(false)
  const [campus, setcampus] = useState('')
  const [course, setcourse] = useState('')
  const [year, setyear] = useState('')
  const [metaData, setMetaData] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [selecteds, setSelecteds] = useState([])
  const [action, setAction] = useState('publish')

  const { category } = props

  const { search, changeSearch } = useSearch()
  const { isLoading, data: resp, refetch } = useGetCampusDataTable(category, search)
  const {
    publishCourse,
    isLoading: isPublishing,
    currentState
  } = usePostPublishCourse()
  const {
    publishOffers,
    isLoading: isPublishingOffers,
    currentState: publishState
  } = usePostPublishOffers()

  const [searchFilter, setSearchFilter] = useState({})
  const [campusId, setCampusId] = useState()

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (countFilter) {
      changeSearch('QUOTE_FORM', {
        ...searchFilter
      })
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const Error = () => {
    setError(true)
  }

  const NoError = () => {
    setError(false)
  }

  const handleAddFilter = (key, value) => {
    if ((key === 'course' || key === 'sede' || key === 'year') && (value !== null)) {
      value = Number(value)
    }
    const nFilter = {
      ...searchFilter,
      [key]: value
    }
    setSearchFilter(nFilter)
    if (key === 'sede') {
      setCampusId(value)
    }
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...searchFilter }
    delete nFilters[key]
    setSearchFilter(nFilters)
  }

  const sortByYear = (data) => {
    return data?.sort(function (a, b) {
      return b.year?.year - a.year?.year
    })
  }

  const getAddedStatusTag = (status = 'Created', url, state, campusId, courseId, yearId, type) => {
    let toUrl = url?.replace(':program', category) ?? ''
    if (type) {
      toUrl = toUrl + '?type=' + type
    }
    return (
      <Tag className={getClass(status)} style={{ marginRight: 0 }}>
        <Link
          to={toUrl}
          className={getLinkClass(status)}
          state={{ ...state, campusId, courseId, yearId }}
        > {getLabel(status)}
        </Link>
      </Tag>
    )
  }

  const isReadyForPublish = (record) => {
    const courseAproved = record.course_prices === 'Aproved' ?? false
    const coursePublished = record.course_prices === 'Published' ?? false
    const courseActive = record.course_prices === 'Active' ?? false
    const coursePrices = courseAproved || coursePublished || courseActive

    const aprovedTransports = record.transports_prices === 'Aproved' ?? false
    const aprovedLodgings = record.lodgings_prices === 'Aproved' ?? false
    const basePrices = record.status === 'Aproved' ?? false
    const isOnline = record.modality === 'Online' ?? false
    const alreadyPublished = record.publish?.status === 'Published' ?? false
    const isReady = (aprovedTransports && aprovedLodgings) || isOnline || category === 'academic_years' || category === 'graduate' || category === 'boarding_schools'
    return ((isReady && basePrices && coursePrices) || alreadyPublished) && !record.expired
  }

  const setData = (campusId, courseId, yearId) => {
    localStorage.setItem('campus', JSON.stringify({ ...PUBLISH_QUOTES_STATES[category], campusId, courseId, yearId }))
  }

  const getPublishTag = (record, campusId, courseId, category = false, yearId, expired) => {
    const published = record.publish?.status === 'Published' ?? false
    const url = category ? URL_APPROVE_QUOTES_PAGE + '?program=' + category : URL_APPROVE_QUOTES_PAGE
    const isReady = isReadyForPublish(record)
    if (isReady) {
      return (
        <Tag className={expired ? classStyleByStatus.Inactive : published ? classStyleByStatus.Aproved : classStyleByStatus.Created}>
          <Link
            target='_blank'
            onClick={() => setData(campusId, courseId, yearId)}
            style={{ color: expired ? 'red' : published ? 'var(--full-white)' : 'var(--gray-100)' }}
            to={url}
            state={{ ...PUBLISH_QUOTES_STATES[category], campusId, courseId, yearId }}
          > {expired ? 'Expired' : published ? 'Published' : 'Unpublished'}
          </Link>
        </Tag>
      )
    }
    return (
      <Tag className={expired ? classStyleByStatus.Inactive : published ? classStyleByStatus.Aproved : classStyleByStatus.Created}>
        {expired ? 'Expired' : published ? 'Published' : 'Unpublished'}
      </Tag>
    )
  }

  const PublishSwitchButton = (record) => {
    const status = record.publish?.status
    const handleSwitchChange = () => {
      setcampus(record.publish?.enterpriseable_id)
      setcourse(record.id)
      setyear(record.year?.id)
      setIsModalOpen(true)
    }
    return (
      <Switch
        disabled={!isReadyForPublish(record)}
        // disabled={() => !isReadyForPublish(record)}
        checked={status === 'Published'}
        onClick={handleSwitchChange}
        checkedChildren='PUBLISH' unCheckedChildren='UNPUBLISH'
      />
    )
  }

  function ShowMessage () {
    if (isThereError) {
      return (
        <div style={{ color: 'red' }}>
          *Insert a name
        </div>
      )
    }
  }

  function VerifyMistake () {
    const username = form.getFieldValue('username')
    if (username) {
      if (username.trim().length === 0) {
        Error()
      } else {
        isFinished(true)
        if (campus && course && year) {
          publishCourse(campus, course, year, username)
        } else if (selecteds.length > 0) {
          publishOffers({ offers: selecteds, action, user: username, select_all: selectAll, program: category })
        }
        setIsModalOpen(false)
        NoError()
      }
    } else {
      Error()
    }
  }

  const handleReset = () => {
    setSearchFilter({})
    changeSearch('QUOTE_FORM', {})
    setCampusId()
  }

  const handleSelectAll = (checked) => {
    const keys = Object.keys(form.getFieldsValue())
    const offerChecks = keys.filter(k => k.includes('offer_'))
    const formValues = {}
    const ids = []
    offerChecks.forEach(k => {
      formValues[k] = checked
      ids.push(Number(k.split('_')[1]))
    })
    form.setFieldsValue(formValues)
    if (checked) {
      setSelecteds(ids)
    } else {
      setSelecteds([]) // MAybe only remove the ids, in case there is another page of offers
    }
    setSelectAll(checked)
  }

  useEffect(() => {
    if (!isPublishing && currentState === utilsState.STATE_SUCCESS) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishing, currentState])

  useEffect(() => {
    if (!isPublishingOffers && publishState === utilsState.STATE_SUCCESS) {
      notification.success({
        message: 'Quotes succesfully published'
      })
      refetch()
    }
  }, [isPublishingOffers, publishState])

  useEffect(() => {
    localStorage.removeItem('campus')
  }, [])

  useEffect(() => {
    if (!isLoading && resp) {
      setMetaData({
        current: resp?.meta?.current_page,
        size: resp?.meta?.last_page,
        total: resp?.meta?.total,
        pageSize: resp?.meta?.per_page,
        showSizeChanger: false,
        onChange: (val) => {
          form.setFieldsValue({ select_all: false })
          handleSelectAll(false)
          setSearchFilter({
            ...searchFilter,
            page: val
          })
          changeSearch('QUOTE_FORM', {
            ...searchFilter,
            page: val
          })
        }
      })
    }
  }, [resp])

  return (
    <div style={{ width: '100%' }}>
      <h3 className='byp-title'> {resp?.program}</h3>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '10px', paddingTop: '30px', paddingBotton: '20px' }}>
        <div style={{ width: '25%' }}>
          <SelectCampusByProgramFormItem
            program={category}
            onChange={(e) => {
              if (e && e !== 'All') {
                handleAddFilter('sede', e)
              } else {
                handleRemoveFilter('sede')
              }
            }}
            showAll
            value={searchFilter?.sede}
            placeholder='Campus'
            label='Campus'
          />
        </div>
        <div style={{ width: '20%' }}>
          <SelectServiceByCategory
            program={category}
            onChange={(e) => {
              handleAddFilter('course', e)
            }}
            showAll
            placeholder='Course'
            label='Course'
            value={searchFilter?.course}
          />
        </div>
        <div style={{ width: '20%' }}>
          Status
          <Select
            style={{ width: '70%', marginLeft: '5%' }}
            size='small'
            onChange={(e) => {
              handleAddFilter('status', e)
            }}
            placeholder='Status'
            value={searchFilter?.status}
          >
            <Option key={0} value={null}>All</Option>
            <Option key={1} value='Published'>Published</Option>
            <Option key={2} value='Unpublished'>Unpublished</Option>
          </Select>
        </div>
        <div style={{ width: '20%' }}>
          <SelectYearByCampus
            placeholder='Year'
            label='Year'
            campus={campusId}
            showAll
            selectById={false}
            showDefault
            onChange={(e) => {
              handleAddFilter('year', e)
            }}
            value={searchFilter?.year}
          />
        </div>
        <div style={{ width: '15%', display: 'flex', gap: '20px', justifyContent: 'end' }}>
          <Button style={{ backgroundColor: 'lightgray', color: 'black' }} onClick={handleReset}>
            Clear
          </Button>

          <Button
            onClick={handleOnSubmitSearch}
            style={{ backgroundColor: 'var(--blue-100)', color: 'white' }}
          >Search
          </Button>
        </div>
      </div>
      {/* Offers */}
      <Form form={form}>
        <Table
          dataSource={sortByYear(resp?.data)}
          className='byp-table-dashboard table-sm'
          // title={() => 'Quotes ' + searchText ?? ''}
          loading={isLoading || isPublishing}
          rowKey='key'
          pagination={metaData}
        >
          <Column
            title={() => (
              <>
                <Form.Item name='select_all' valuePropName='checked'>
                  <Checkbox
                    onChange={(e) => {
                      handleSelectAll(e.target.checked)
                    }}
                  />
                </Form.Item>
              </>
            )}
            dataIndex='selected'
            key='selected'
            className=''
            render={(_, { offer_id: id }) => {
              return (
                <>
                  <Form.Item name={'offer_' + id} valuePropName='checked'>
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelecteds([...selecteds, id])
                        } else {
                          const found = selecteds.indexOf(id)
                          if (found > -1) {
                            const actualIds = selecteds.filter(offer => offer !== id)
                            setSelecteds(actualIds)
                          }
                          if (selectAll) {
                            setSelectAll(false)
                          }
                        }
                      }}
                    />
                  </Form.Item>
                </>
              )
            }}
          />
          <Column
            title='School'
            dataIndex='school'
            key='school'
            className='byp-fw-bold'
          />
          <Column
            title='Base prices'
            dataIndex='prices'
            key='prices'
            responsive={['md']}
            render={(_, { status, id, year, publish }) => getAddedStatusTag(
              status,
              URL_UPLOAD_PRICES_PAGE,
              { menuItemSelected: category + '_general', openSub: category },
              publish?.enterpriseable_id,
              id, year?.id
            )}
          />
          <Column
            title='Course'
            dataIndex='name'
            key='name'
            responsive={['md']}
            render={(_, record) => (
              <h6 className='byp-title byp-fw-bold byp-mr-1'><small>{record.name} ({record.year?.year}) </small></h6>
            )}
          />
          <Column
            title='Course prices'
            dataIndex='courses'
            key='courses'
            responsive={['md']}
            render={(_, record) => getAddedStatusTag(
              record.course_prices,
              URL_COURSEPRICES_CREATE_PAGE,
              { menuItemSelected: category + '_courses_prices', openSub: category, openSub2: category + '_courses', course_name: record.name },
              record.publish?.enterpriseable_id,
              record.id,
              record.year?.id,
              record.type
            )}
          />
          {
            category !== 'boarding_schools' && (
              <Column
                title='Accommodations prices'
                dataIndex='lodgings'
                key='lodgings'
                responsive={['md']}
                render={(_, record) => getAddedStatusTag(
                  record.lodgings_prices,
                  URL_LODGINGPRICES_CREATE_PAGE,
                  { menuItemSelected: category + '_lodgings_prices', openSub: category, openSub2: category + '_lodgings' },
                  record.publish?.enterpriseable_id,
                  record.id,
                  record.year?.id
                )}
              />
            )
          }
          {
            category !== 'boarding_schools' && (
              <Column
                title='Transport prices'
                dataIndex='transports'
                key='transports'
                responsive={['lg']}
                render={(_, record) => getAddedStatusTag(
                  record.transports_prices,
                  URL_TRANSPORTGPRICES_CREATE_PAGE,
                  { menuItemSelected: category + '_transports_prices', openSub: category, openSub2: category + '_transports' },
                  record.publish?.enterpriseable_id,
                  record.id,
                  record.year?.id
                )}
              />
            )
          }
          <Column
            title='Discounts'
            dataIndex='discounts'
            key='discounts'
            responsive={['lg']}
            render={(_, record) => getAddedStatusTag(
              record.discount_prices,
              URL_CUSTOMDISCOUNT_PAGE,
              { menuItemSelected: category + '_discounts_prices', openSub: category, openSub2: category + '_discounts' },
              record.publish?.enterpriseable_id,
              record.id,
              record.year?.id
            )}
          />
          <Column
            title='Status'
            dataIndex='publish'
            key='status'
            render={(_, record) => getPublishTag(
              record,
              record.publish?.enterpriseable_id,
              record.id,
              category,
              record.year?.id,
              record.expired
            )}
          />
          <Column
            title='Publish / Unpublish'
            dataIndex='publish'
            key='status'
            render={
              (_, record) => PublishSwitchButton(record)
            }
          />
          <Column
            title='Published By'
            render={
              (_, record) => {
                let time = ''
                let date = ''
                let dateTime = ''
                if (record.publishedByAt) {
                  time = new Date(record.publishedByAt)
                  date = format(time, 'LLLL dd, yyyy')
                  dateTime = format(time, 'HH:mm:ss')
                }
                return (
                  <span><b>{record.publishedBy}</b> <br /> <small>{date} {dateTime}</small></span>
                )
              }
            }
          />
          <Column
            title='Expiration'
            dataIndex='year'
            key='year'
            render={(_, { year }) => (
              <p>{year?.end}</p>
            )}
          />
        </Table>
      </Form>
      {selecteds.length > 0 && (
        <div className='multiple-publish-container'>
          <Button
            onClick={() => {
              setcampus()
              setcourse()
              setyear()
              setAction('publish')
              setIsModalOpen(true)
            }}
            className='btn-publish-multiple byp-btn-blue-100'
          >
            Publish selecteds
          </Button>
          <Button
            onClick={() => {
              setcampus()
              setcourse()
              setyear()
              setAction('unpublish')
              setIsModalOpen(true)
            }}
            className='btn-publish-multiple byp-bg-red byp-color-white byp-fw-bold'
          >
            Unpublish selecteds
          </Button>
        </div>
      )}
      {/* Schools without courses */}
      <h6 className='byp-title byp-mt-1'>Schools without courses</h6>
      <Table
        dataSource={resp?.campusWithoutCourses}
        className='byp-table-dashboard'
        // loading={isLoading}
        rowKey='school_id'
      >
        <Column
          title='School'
          dataIndex='school'
          key='school'
        />
        <Column
          title='Base prices'
          dataIndex='prices'
          key='prices'
          responsive={['md']}
          render={(_, { status, id, category_id: programId, publish }) => getAddedStatusTag(
            status,
            URL_UPLOAD_PRICES_PAGE,
            { menuItemSelected: 5, openSub: 'escuelas' },
            publish?.enterpriseable_id,
            id,
            programId
          )}
        />
        {
          category !== 'boarding_schools' && (
            <Column
              title='Lodging prices'
              dataIndex='lodgings'
              key='lodgings'
              responsive={['md']}
              render={(_, record) => getAddedStatusTag(
                record.lodgings_prices,
                URL_LODGINGPRICES_CREATE_PAGE,
                { menuItemSelected: 7.1, openSub: 'escuelas', openSub2: 'lodgings' },
                record.publish?.enterpriseable_id,
                record.id,
                record.category_id
              )}
            />
          )
        }
        {
          category !== 'boarding_schools' && (
            <Column
              title='Transport prices'
              dataIndex='transports'
              key='transports'
              responsive={['lg']}
              render={(_, record) => getAddedStatusTag(
                record.transports_prices,
                URL_TRANSPORTGPRICES_CREATE_PAGE,
                { menuItemSelected: 8.1, openSub: 'escuelas', openSub2: 'transports' },
                record.publish?.enterpriseable_id,
                record.id,
                record.category_id
              )}
            />
          )
        }
        <Column
          title='Discounts'
          dataIndex='discounts'
          key='discounts'
          responsive={['lg']}
          render={(_, record) => getAddedStatusTag()}
        />
        <Column
          title='Actions'
          key='action'
          render={(_, { published, ...data }) => (
            <></>
          )}
        />
      </Table>
      {/* Modal */}
      <Form
        onFinish={finish}
        form={form}
        className='w-100'
      >
        <Modal open={isModalOpen} visible={isModalOpen} onCancel={handleCancel} closable={false} footer='' width='400px'>
          <Form.Item name='username' label='Insert your name'>
            <Input />
          </Form.Item>
          <ShowMessage />
          <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '10px' }}>
            <Button onClick={handleCancel} style={{ fontWeight: 'bold' }}>
              Cancel
            </Button>
            <Button onClick={VerifyMistake} style={{ background: 'var(--blue-100)', color: 'white', fontWeight: 'bold' }}>
              Confirm
            </Button>
          </div>
        </Modal>
      </Form>
    </div>
  )
}

export { TableQuotesCategory }
