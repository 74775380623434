import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { useGetMostPopularPrograms } from '../../../../../bussines/hooks/agency/analytics/general/useGetMostPupularPrograms'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'

const MostPopularPrograms = ({ time, user, office, campus }) => {
  const [mostPopularPrograms, setMostPopularPrograms] = useState([])
  const { data: popularPrograms, isLoading } = useGetMostPopularPrograms({ ...time, user, office, campus })
  const { user: _user } = useAuth()

  useEffect(() => {
    // const roles = _user?.role_names ?? []
    setMostPopularPrograms(popularPrograms)
  }, [popularPrograms, _user])

  if (isLoading) {
    return <BasicSpinner />
  }

  function capitalize (text) {
    if (text === 'diploma' || text === 'certificate') {
      return text && text[0]?.toUpperCase() + text?.slice(1)
    }

    if (text === 'advanced_diploma') {
      const newText = text?.split('_')
      let textCapitalize = ''
      for (const i of newText) {
        textCapitalize += i[0]?.toUpperCase() + i?.slice(1) + ' '
      }
      return textCapitalize
    }

    return text
  }

  return (
    <Row gutter={[8, 8]}>
      {
        mostPopularPrograms?.map(popular => (
          <Col
            xs={24}
            sm={12}
            xl={6}
            key={popular.id}
          >
            <div className='quote-list-item-card' style={{ padding: '0.5rem 1.5rem' }}>
              <p className='margin-0 byp-color-gray-100 byp-text-sm'>Quotes created</p>
              <h5 className='byp-title-1 quote-overview--color-blue'>{popular.quotes}</h5>
              <p className='margin-0 byp-color-gray-100' style={{ fontSize: '0.8rem' }}>{capitalize(popular.label)}</p>
            </div>
          </Col>
        ))
      }
    </Row>
  )
}

export default MostPopularPrograms
