import api from '../../services/api'

class TablesPagesRepository {
  async getTableCountriesCities () {
    const response = await api.get('/pages/countries/list')
    return response.data
  }

  async getGetDataPage (data) {
    const response = await api.get('/pages/country/city/program', {
      params: {
        ...data
      }
    })
    return response.data
  }

  async getTablePrograms () {
    const response = await api.get('/pages/programs/list')
    return response.data
  }

  async postSavePageCountry (data) {
    const response = await api.post('/pages/countries', data)
    return response.data
  }

  async putSavePage (data) {
    const response = await api.put('/pages/update', data)
    return response.data
  }

  async postSavePageProgram (data) {
    const response = await api.post('/pages/program', data)
    return response.data
  }
}

export default new TablesPagesRepository()
