import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useFindDiscount (id) {
  return useQuery(['useFindDiscount', id],
    () => DiscountRepository.find(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useFindDiscount }
