import { useQuery } from 'react-query'
import SchoolRepository from '../../../repositories/SchoolRepository'

function useFindDocuments (id) {
  return useQuery(['useFindDocuments', id],
    () => SchoolRepository.findDocument(id), {
      retry: 2,
      enabled: !!id
    })
}

export { useFindDocuments }
