import React from 'react'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { GenearlAnalysisInfo } from './GeneralAnalysisInfo'

const URL_GENERAL_ANALYSIS_PAGE = '/dashboard/analitics/general'

function GeneralAnalysisPage () {
  return (
    <PageProtected permissionTypes={[
      userRoles.TYPE_SUPER_ADMIN,
      userRoles.TYPE_SUPER_EDITOR,
      userRoles.TYPE_AGENCY,
      userRoles.TYPE_AGENCY_OFFICE_MANAGER,
      userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
      userRoles.TYPE_AGENCY_EDITOR,
      userRoles.TYPE_INSTITUTION,
      userRoles.TYPE_EG_ADMIN
    ]}
    >
      <MainTheme
        showMenu
      >
        <GenearlAnalysisInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { GeneralAnalysisPage, URL_GENERAL_ANALYSIS_PAGE }
