import { Link } from 'react-router-dom'
import { API_URL } from '../../../../config'
import { URL_INSTITUTION_SCHOOLS_PAGE } from '../Schools/CreateSchoolsPage'
import { UploadSchoolMediaForm } from '../Schools/UploadSchoolMediaForm'

function PDFQuoteFieldsContainer ({ form, setGallery, setCitylGallery, setHeaderFooter }) {
  return (
    <>
      <h6 className='byp-title byp-fw-bold byp-sbtl-mkt-mtl'>Cover picture</h6>
      <UploadSchoolMediaForm
        form={form}
        name='cover'
        action={`${API_URL}/campus/upload/files`}
        maxCount={1}
        setGallery={setGallery}
      />

      <h6 className='byp-title byp-fw-bold byp-mt-1 byp-sbtl-mkt-mtl'>School pictures</h6>
      <p className='byp-txt-mkt-mtl-small'>Remember that you've uploaded these pictures when uploading your school, if you want to edit them, please click
        <Link
          to={URL_INSTITUTION_SCHOOLS_PAGE}
          state={{ menuItemSelected: 4, openSub: 'escuelas' }}
        >
          here
        </Link>
      </p>
    </>
  )
}

export { PDFQuoteFieldsContainer }
