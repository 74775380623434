import React from 'react'
import { Card, Typography } from 'antd'
import '../../../common/assets/css/main-theme.less'
import favicon from '../../../common/assets/svg/favicon.svg'

const { Paragraph } = Typography
const getBGStyle = (gallery, index) => ({
  backgroundImage: `url(${gallery[index] ? gallery[index].src : null})`
})

export const QuoteCampus = ({ provider }) => {
  const withImages = () => (
    <div className='campus-grid-container'>
      <div className='campus1 campus-item' style={getBGStyle(provider.campus.gallery, 0)} />
      <div className='campus2 campus-item' style={getBGStyle(provider.campus.gallery, 1)} />
      <div className='campus3 campus-item' style={getBGStyle(provider.campus.gallery, 2)} />
    </div>
  )

  const withOutImages = () => (
    <div className='quote-campus__logo'>
      <img src={favicon} style={{ width: '100%' }} alt={provider?.name} />
      {/* <img src={provider?.campus?.logo ?? favicon} style={{ width: '100%' }} alt={provider?.name} /> */}
    </div>
  )
  return (
    <Card bordered={false}>
      <h2 className='byp-title-4 byp-text-primary'>{provider.name}</h2>
      <Paragraph style={{ fontSize: '0.875rem' }}>
        {provider.description}
      </Paragraph>
      <h2 style={{ fontSize: '1.1177rem', color: '#2D2F66' }}>
        {provider.campus.name}
      </h2>
      <Paragraph style={{ fontSize: '0.875rem' }}>
        {provider.campus.description}
      </Paragraph>

      {provider.campus.gallery.length === 3 ? withImages() : withOutImages()}
    </Card>
  )
}
