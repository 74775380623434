import api from '../../services/api'

class GenearlAnalysisRepository {
  static async getTotalQuotes (time, idOffice, region, subregion, countrie, agency) {
    const response = await api.get('/dashboard/quotes', {
      params: {
        time: time?.time,
        start: time?.start,
        end: time?.end,
        office: idOffice,
        agency_region: region,
        agency_subregion: subregion,
        agency_country: countrie,
        agency_id: agency
      }
    })
    return response.data
  }

  static async getMostPopularPrograms (filters) {
    const response = await api.get('/dashboard/programs', {
      params: filters
    })
    return response.data
  }

  static async getMostPopularCities (filters) {
    const response = await api.get('/dashboard/cities', {
      params: filters
    })
    return response.data.cities
  }

  static async getMostPopularDates (query) {
    const response = await api.get('/dashboard/top-months?' + query)
    return response.data
  }

  static async getMostPopularAgencies (query) {
    const response = await api.get('/dashboard/top-agencies?' + query)
    return response.data
  }

  static async getMostPopularSchools (query) {
    const response = await api.get('/dashboard/top-schools?' + query)
    return response.data
  }

  static async getAgencyUsers (office) {
    const response = await api.get('/dashboard/users', {
      params: {
        sede: office
      }
    })
    return response.data
  }

  static async getTopUsers (limit, filters) {
    const response = await api.get('/dashboard/top-users?limit=' + limit, {
      params: filters
    })
    return response.data
  }

  static async getMostPopularCoursesByUser (query) {
    const response = await api.get('/dashboard/courses?' + query)
    return response.data
  }

  static async getIntakes (query) {
    const response = await api.get('/dashboard/top-months?' + query)
    return response.data
  }

  static async getSchoolsAverage (query) {
    const response = await api.get('/dashboard/schools-average?' + query)
    return response.data
  }

  static async getProgramAverage (query) {
    const response = await api.get('/dashboard/program-average?' + query)
    return response.data
  }

  static async getOfficeAverage (query) {
    const response = await api.get('/dashboard/office-average?' + query)
    return response.data
  }

  static async getQuoteAnaliticDetails (query) {
    const response = await api.get('/dashboard/program-average?' + query)
    return response.data
  }

  static async getQuoteDetailsByUser (user, query) {
    const response = await api.get(`/dashboard/${user}/quotes-details?` + query)
    return response.data
  }

  static async getQuoteDetails (query) {
    const response = await api.get('/dashboard/quotes-details?' + query)
    return response.data
  }

  static async getQuoteDetailsReports (query) {
    const response = await api.get('/dashboard/quotes-details', {
      params: {
        ...query
      }
    })
    return response.data
  }

  static async getQuoteActions (query) {
    const response = await api.get('/dashboard/quotes-actions', {
      params: {
        ...query
      }
    })
    return response.data
  }

  static async getQuoteActionsByUser (user, query) {
    const response = await api.get(`/dashboard/${user}/quotes-actions?` + query)
    return response.data
  }
}

export default GenearlAnalysisRepository
