import { useQuery } from 'react-query'
import FeeRepository from '../../repositories/FeeRepository'

function useGetFeesByCategory (category, filters = {}) {
  const filtersQuery = Object.keys(filters).map((key) => key + '=' + filters[key]).join('&')
  return useQuery(['useGetFeesByCategory', category, filtersQuery],
    () => FeeRepository.list(category, filtersQuery),
    { retry: 2, enabled: !!category }
  )
}

export { useGetFeesByCategory }
