import { useQuery } from 'react-query'
import AdminRepository from '../../repositories/AdminRepository'

function useGetTotalQYPQuotes (filters = {}) {
  return useQuery(['useGetTotalQYPQuotes', filters], () => AdminRepository.totalQYPQuotes(filters), {
    retry: 1
  })
}

export { useGetTotalQYPQuotes }
