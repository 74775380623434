import { Col, Form, Input, Row, Select } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { format } from 'date-fns'
import { formatStringDate } from '../../../utils/formatStringDate'
import { useEffect } from 'react'
const { Option } = Select

export function ProgramIntake () {
  const { service, formConfig, setFormConfig } = useQuoter()
  function UniversalDate (date) {
    let day = date.getUTCDay()
    switch (day) {
      case 1: day = 'Mon'
        break
      case 2: day = 'Tue'
        break
      case 3: day = 'Wed'
        break
      case 4: day = 'Thu'
        break
      case 5: day = 'Fri'
        break
      case 6: day = 'Sat'
        break
      case 7: day = 'Sun'
        break
    }
    let month = date.getUTCMonth() + 1
    switch (month) {
      case 1: month = 'January'
        break
      case 2: month = 'February'
        break
      case 3: month = 'March'
        break
      case 4: month = 'April'
        break
      case 5: month = 'May'
        break
      case 6: month = 'June'
        break
      case 7: month = 'July'
        break
      case 8: month = 'August'
        break
      case 9: month = 'September'
        break
      case 10: month = 'October'
        break
      case 11: month = 'November'
        break
      case 12: month = 'December'
        break
    }
    return day + ' ' + date.getUTCDate() + ' ' + month + ', ' + date.getUTCFullYear()
  }

  useEffect(() => {
    const parsedDate = Date.parse(formConfig.intake)
    if (!isNaN(parsedDate)) {
      const nDate = new Date(formConfig.intake)
      service?.searchPrices(format(nDate, 'yyyy-LL-dd'))
    }
  }, [formConfig.intake])

  return (
    <Row gutter={12}>
      {/* <Col md={16} lg={8}> */}
      {(service.getCourseProgram().name === 'graduate' && service.getCourseStartDates()?.length === 0) && (
        <Col md={16} lg={8}>
          <Form.Item label='Program Intake' name='intake'>
            <Select
              onChange={(intak) => {
                const values = intak.split('&')

                setFormConfig({
                  ...formConfig,
                  intake: values[2] + ''
                })
                if (!intak) {
                  return
                }
                service?.searchPricesById(values[0], values[2])
              }}
              size='small'
            >
              {service.getCourseStartDates()?.length > 0 && (
                <Option
                  key='intake_null'
                  value={null}
                >
                  None
                </Option>
              )}
              {service.getCourseIntakes()?.map((intake) => (
                <Option key={'intake_' + intake.season} value={intake.id + '&' + intake.season + '&' + intake.intake}>{intake.season}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {(service.getCourseProgram().name === 'academic_years' || service.getCourseProgram().name === 'boarding_schools') && (
        <Col md={16} lg={8}>
          <Form.Item label='Program intake:' name='intake_date'>
            <Select
              onChange={(intak) => {
                setFormConfig((currentValues) => {
                  return { ...currentValues, intake: intak }
                })
              }}
              size='small'
              readOnly
            >
              {service.getCourseStartDates()?.length > 0 && (
                <Option
                  key='intake_null'
                  value={null}
                >
                  None
                </Option>
              )}
              {service.getCourseIntake()?.map((intake) => (
                <Option
                  key={'intake_' + intake}
                  value={intake}
                >
                  {formatStringDate(intake, 'MM/yyyy')}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {/* </Col> */}
      {
        service.getCourseStartDates()?.length > 0 && (
          <Col span={8}>
            <Form.Item name='startCourse' label='Program Intake Date'>
              <Select
                onChange={(start) => {
                  setFormConfig({
                    ...formConfig,
                    startCourse: start
                  })
                  service?.searchPrices(start)
                }}
                size='small'
              >

                {service.getCourseStartDates()?.map((date) =>
                  <Option key={'start_date_' + date.date} value={date.date}>{UniversalDate(new Date(date.date))}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        )
      }
      <Col md={0} lg={8}>
        <Form.Item name='length'>
          <Input readOnly type='hidden' />
        </Form.Item>
      </Col>
      <Col md={0} lg={4}>
        <Form.Item name='duration_type'>
          <Input readOnly type='hidden' />
        </Form.Item>
      </Col>
    </Row>
  )
}
