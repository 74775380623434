import { useStepByStep } from '../../../providers/stepbystep/step-service-provider'
import React from 'react'
import { ExceptionStepController } from './ExceptionStepController'
import { InstitutionProfileStep, STEP_INSTITUTION_PROFILE } from './steps/intitution-profile/InstitutionProfileStep'
// import {
//   InstitutionAddContactsStep,
//   STEP_INSTITUTION_ADD_CONTACTS
// } from './steps/institution-add-contacts/InstitutionAddContactsStep'
import { InstitutionExtrasStep, STEP_INSTITUTION_EXTRA } from './steps/institution-extras/InstitutionExtrasStep'
import { InstitutionPartnerStep, STEP_INSTITUTION_PARTNER } from './steps/institution-partner/InstitutionPartnerStep'
// import { NotFoundPage } from '../../../common/components/NotFoundPage'
import { PageNotFound } from '../../../pages/errors/PageNotFound'
import { InstitutionFinishStep, STEP_INSTITUTION_FINISH } from './steps/finish-step/InstitutionFinishStep'
import { FirstStep, STEP_CONTINUE_REGISTRATION } from './steps/first-step/FirstStep'

function InstitutionStepRouter () {
  const { step, isLoading } = useStepByStep()

  if (isLoading) {
    return <p>Cargando datos...</p>
  }

  let template = null
  switch (step) {
    case STEP_CONTINUE_REGISTRATION:
      template = (<FirstStep />)
      break
    case STEP_INSTITUTION_PROFILE:
      template = (<InstitutionProfileStep />)
      break
    case STEP_INSTITUTION_EXTRA:
      template = (<InstitutionExtrasStep />)
      break
    // case STEP_INSTITUTION_ADD_CONTACTS:
    //   template = (<InstitutionAddContactsStep />)
    //   break
    // case STEP_INSTITUTION_PARTNER:
    //   template = <InstitutionPartnerStep />
    //   break
    case STEP_INSTITUTION_FINISH:
      template = <InstitutionFinishStep />
      break
    default:
      template = <PageNotFound />
      break
  }
  return <ExceptionStepController usePostMethod={useStepByStep}>{template}</ExceptionStepController>
}

export { InstitutionStepRouter }
