import { Col, Form, Row, Select } from 'antd'
import { bypEvents } from '../../../../../utils/logEvents'
const { Option } = Select

const LogsTableFilters = ({ filters, setFilters }) => {
  const handleAddFilter = (key, value) => {
    const nFilters = {
      ...filters,
      [key]: value,
      page: 1
    }
    setFilters(nFilters)
  }

  const handleRemoveFilter = (key) => {
    const nFilters = { ...filters }
    delete nFilters[key]
    setFilters(nFilters)
  }
  return (
    <Row gutter={16}>
      <Col xs={0} md={8}>
        <Form.Item name='type'>
          <Select
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            showSearch
            onChange={(e) => {
              e
                ? handleAddFilter('event', e)
                : handleRemoveFilter('event')
            }}
            className='w-100'
            placeholder='Event'
          >
            <Option value={null}>All</Option>
            {bypEvents.map((eventItem) => (
              <Option key={eventItem.name} value={eventItem.name}>{eventItem.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {/* <Col></Col> */}
      {/* <Col></Col> */}
    </Row>
  )
}

export { LogsTableFilters }
