import { useQuery } from 'react-query'
import PackagesRepository from '../../repositories/PackagesRepository'

function useGetPackageYears (id) {
  return useQuery(['useGetPackageYears', id],
    () => PackagesRepository.getPackageYears(id), {
      retry: 1,
      enabled: !!id
    })
}

export { useGetPackageYears }
