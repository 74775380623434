import { Checkbox, Col, Form, Row, Select } from 'antd'
import { useState, useEffect } from 'react'
import { useGetUsers } from '../../../../bussines/hooks/user/useGetUsers'
const { Option } = Select

function SelectUsersAll ({ name, onChange, single, allowClear, form, rules, filters, isReports, select, isDisabled, defaultuser, ...props }) {
  const [checkAllBranch, setCheckAllBranch] = useState(false)
  const _name = name ?? 'branches'

  const { data, isLoading } = useGetUsers(filters)

  const onCheckAllChangeBranch = (e) => {
    setCheckAllBranch(e.target.checked)
  }

  useEffect(() => {
    if (data) {
      const ids = data.map(c => (c.id))
      if (form) {
        checkAllBranch ? form.setFieldsValue({ [_name]: ids }) : defaultuser && form.setFieldsValue({ [_name]: [defaultuser] })
      }
    }
  }, [checkAllBranch, data])

  if (select) {
    return (
      <Form.Item
        name={_name}
        rules={rules}
        className='byp-m-0-important'
        label='User'
      >
        <Select
          style={{ width: '100%' }}
          placeholder=''
          loading={isLoading}
          maxTagCount= 'responsive'
          className='byp-m-0-important'
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
          onChange={onChange}
          bordered={false}
        >
          {data?.map(item => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
    )
  }
  else {
    return (
      <>
        <Row>
          {props.label && (
            <Col style={{ paddingTop: '12px' }} span={24} className={isReports ? 'title-multi-select' : ''}>
              {props.label + ':'}
            </Col>
          )}

          <Col xs={24} md={isReports ? 24 : props.label ? 20 : 24} className={isReports ? 'multi-select' : ''}>
            <Row className='byp-dashboard-header byp-mb-1'>

            <Col xs={24} md={4} className='byp-justify-centered' >
              <Form.Item className='byp-m-0-important prefix' name={name + 'all'} valuePropName='checked' >
                <Checkbox onChange={onCheckAllChangeBranch} disabled={isDisabled}>
                  {(props.label && !isReports) && 'All ' + name}
                  {!props.label && 'All '}
                  {isReports && 'All'}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} md={20} className='byp-justify-centered'>
              <Form.Item
                name={_name}
                rules={rules}
                className='byp-m-0-important'
              >
                <Select
                  style={{ width: '100%'}}
                  placeholder=''
                  loading={isLoading}
                  mode='multiple'
                  className='byp-m-0-important'
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  showSearch
                  onChange={onChange}
                  disabled={isDisabled}
                  bordered={false}
                >
                  {data?.map(item => (
                    <Option key={item.id} value={item.id} >{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      </>
    )
  }

}

export { SelectUsersAll }
