import { useQuery } from 'react-query'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useGetBlocksAppliedByDiscount (discount) {
  return useQuery(['useGetBlocksAppliedByDiscount', discount],
    () => DiscountRepository.getBlockApplied(discount), {
      retry: 2,
      enabled: !!discount
    })
}

export { useGetBlocksAppliedByDiscount }
