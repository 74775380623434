import heroImg from '../../../common/assets/images/bg-hero.png'
import bypLogo from '../../../common/assets/images/byp_logo_div.png'

const heroBgStyle = () => ({
  backgroundImage: `url(${heroImg})`
})


export function HomeSectionA() {
  return (
    <div className='landing-picture-principal-responsive' style={heroBgStyle()}>
      <h1 className='byp-blue-100-c byp-mb-1' style={{ paddingTop: '60px' }}>Quote Your Program!</h1>
      <h5 className='byp-black-a byp-mb-1' style={{ whiteSpace: 'normal', padding: '0 5vw' }}>
        The platform that unites the education industry, through a friendly, easy to use, intuitive and integrable platform with the main CRM and websites in the market.
      </h5>
      <a
        className='btn mr-1 mb-1 box-shadow-0 byp-topbar-button'
        href='https://32sifu6bwk2.typeform.com/to/tzXiQ9CV'
        style={{ background: '#2d2f66', color: '#f4f4f4', marginTop: '3rem' }}
      >
        <b style={{ fontSize: '0.875rem' }}>Start now!</b>
      </a>
      <div className='quoting-easy-responsive'>
        <img src={bypLogo} style={{ width: '250px', position: 'relative', top: '45px' }}
          alt='' />
        <div className='text-landing-responsive' >
          <p style={{ fontSize: '1rem', maxWidth: '47.5rem', fontWeight: 'bold', color: 'white' }}>
            Quote Your Program was created out of the need to generate quotes for study abroad programs in a fast and intuitive way. QYP is not a CRM, it is an intelligent online quote platform where you can customize quotes, present information from schools, and complement proposals with other industry services.
          </p>
          <h4 className='text-center'>
            <b className='' style={{ color: '#f2a706' }}> Quoting has never been so easy! </b>
          </h4>
        </div>
      </div>
    </div>
  )
}