import { useQuery } from 'react-query'
import LanguageRepository from '../../repositories/LanguageRepository'

function useAllLanguages () {
  return useQuery(['useAllLanguages'], LanguageRepository.all, {
    retry: 2
  })
}

export { useAllLanguages }
