import { Table, Tag } from 'antd'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LottieCargando from '../../../../lotties/LottieCargando'
import { tagStatusBasicClass } from '../../../../utils/tagStatusClass'
import { URL_APROVE_INSURANCE_PAGE } from '../aprove/insurances/AproveInsurancePage'
import { URL_APROVE_SERVICE_PAGE } from '../aprove/services/AproveServicePage'
import { ServiceStatusTableFilters } from './Filters/ServiceStatusTableFilters'
import { formatStringDate } from '../../../../utils/formatStringDate'

const { Column } = Table

const schoolsLabel = (schools) => {
  if (Array.isArray(schools)) {
    if (schools.length > 1) {
      const firstSchool = schools[0]
      return (
        <span>
          <b>{firstSchool.name}</b> <small><i>and {(schools.length - 1)} more</i></small>
        </span>
      )
    } else {
      if (schools.length === 1) {
        const firstSchool = schools[0]
        return firstSchool.name
      }
    }
  }
  return null
}

function getUrl (type) {
  if (type === 'service') {
    return URL_APROVE_SERVICE_PAGE
  } else {
    return URL_APROVE_INSURANCE_PAGE
  }
}

const LinkToAprove = ({ record }) => {
  if (record.costs_status === 'Published' || record.prices_status === 'Aproved' || record.costs_status === 'Aproved') {
    return (
      <Link
        to={getUrl(record.type)?.replace(':id', record.id) ?? ''}
        className={tagStatusBasicClass(record.costs_status_label)}
        style={{ textTransform: 'uppercase' }}
        state={{ yearId: record.year_id, year: record.year }}
      >{record.costs_status_label}
      </Link>
    )
  }
  return <span style={{ textTransform: 'uppercase' }}>{record.costs_status}</span>
}

const TableServicesStatus = ({ services, setId, isLoading, deleteFee, searchFilter, setSearchFilter }) => {
  const [data, setData] = useState(services)
  useEffect(() => {
    if (services) {
      setData(services)
      console.log('🚀 ~ file: TableServicesStatus.js:13 ~ useEffect ~ services', services)
    }
  }, [services])

  return (
    <>
      <ServiceStatusTableFilters
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        data={data}
      />
      {isLoading && <LottieCargando />}
      {!isLoading && (
        <Table
          loading={isLoading}
          dataSource={data ?? []}
          className='byp-table-dashboard'
          rowKey='id'
        >
          <Column
            title='Name' dataIndex='name' key='name'
            render={(_, record) => (
              <a>{record.name} {record.year}</a>
            )}
          />
          <Column
            title='Program'
            render={(_, record) => (
              <b>{record.program}</b>
            )}
          />
          <Column
            title='Institution'
            render={(_, record) => (
              <b>{record.institution}</b>
            )}
          />
          <Column
            title='Schools'
            render={(_, record) => (
              <b>{schoolsLabel(record.schools)}</b>
            )}
          />
          <Column
            title='Offices'
            dataIndex='offices'
            key='offices'
            responsive={['lg']}
            render={(_, record) => (
              record.offices?.map((item) => (
                <li key={item.id}>
                  <b>{item.name}</b>
                </li>
              ))
            )}
          />
          <Column
            title='Status'
            render={(_, record) => (
              <Tag className={tagStatusBasicClass(record.costs_status_label)}>
                <LinkToAprove record={record} />
              </Tag>
            )}
          />
          <Column
            title='Published by' dataIndex='published_by' key='published_by'
          />
          <Column
            title='Expiration date'
            render={(_, record) => (
              record.end_at ? formatStringDate(record.end_at, 'LLLL d, Y') : null
            )}
          />
        </Table>
      )}
    </>
  )
}

export { TableServicesStatus }
