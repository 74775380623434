import React, { useState, useEffect, useMemo } from 'react'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import { useSearch } from '../../../../providers/search/search-context-provider'
// import { useGetLanguages } from '../../../../bussines/hooks/quoter/useGetLanguages'
import debounce from 'lodash/debounce'
import { useGetPlaces } from '../../../../bussines/hooks/quoter/useGetPlaces'
import { CloseOutlined } from '@ant-design/icons'
// import { useGetCoursesByFilter } from '../../../../bussines/hooks/quoter/useGetCoursesByFilter'
// import { SelectStudyAreasFormItem } from '../../../../common/components/helper-form/SelectStudyAreasFormItem'
// import { SelectStudyAreasByProgramFormItem } from '../../../../common/components/helper-form/SelectStudyAreasByProgramFormItem'
// import { SelectTypeOfInstitutionsFormItem } from '../../../../common/components/helper-form/SelectTypeOfInstitutionsFormItem'
// import { SearchSubject } from '../../../../common/components/quoter-search/SearchSubject'
// import { SearchCourseDuration } from '../../../../common/components/quoter-search/SearchCourseDuration'
import { SelectReligionsFormItem } from '../../../../common/components/helper-form/SelectReligionsFormItem'
import { SearchActivities } from '../../../../common/components/quoter-search/SearchActivities'
import { useGetLanguagesShort } from '../../../../bussines/hooks/quoter/useGetLanguages'

const { Option } = Select

const ShortTermForm = ({ type, subType }) => {
  const { isLoading: isLoadingLanguage, data: languages } = useGetLanguagesShort('short_term')
  const [searchlang, setSearchlang] = useState(false)
  const { changeSearch } = useSearch()
  // const { data } = useGetCoursesByFilter({
  //   program: 'graduate',
  //   type,
  //   subtype: subType ?? null
  // })
  const [searchFilter, setSearchFilter] = useState({
    program: 'short_term'
    // type,
    // subtype: subType ?? null
  })
  const [allPlaces, setAllPlaces] = useState([])
  const [form] = Form.useForm()
  // const { isLoading: isLoadingLanguage } = useGetLanguages()
  const { data: places, isFetching } = useGetPlaces(
    searchFilter?.searchPlace && {
      search: searchFilter?.searchPlace ?? '',
      lang: searchFilter?.lang ?? '',
      program: 'short_term'
      //   type: type ?? null,
      //   subtype: subType ?? null
    }
  )

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (countFilter) {
      changeSearch('LANG_FORM', {
        ...searchFilter
      })
    }
  }

  const handleClearFilters = () => {
    const nFilter = {
      program: 'short_term'
      //   type,
      //   subtype: subType ?? null
    }
    setSearchFilter(nFilter)
    form.resetFields()
    changeSearch('LANG_FORM', {
      ...nFilter
    })
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilter = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilter)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilter = {
      ...searchFilter,
      [key]: value
    }
    if (key === 'place' && typeof value === 'string') {
      const place = value.split(':')
      nFilter.place = place[0]
      nFilter.place_type = place[1]
    } else if (key === 'place') {
      nFilter.place = 'undefined'
      nFilter.place_type = 'undefined'
    }
    if (key === 'duration') {
      const duration = value?.split(':')
      if (duration) {
        nFilter.duration = Number(duration[0])
        nFilter.time = duration[1]
      } else {
        nFilter.duration = undefined
        nFilter.time = undefined
      }
    }
    if (key === 'type') {
      nFilter.type = value
      nFilter.place_type = 'type'
    }
    setSearchFilter(nFilter)
    // console.log('🚀 ~ file: LanguageForm.js ~ line 38 ~ handleAddFilter ~ nFilter', nFilter)
  }

  useEffect(() => {
    if (places) {
      const formatedCampus = places.campus?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'campus'
        }
      })
      const formatedCountries = places.countries?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'country'
        }
      })
      const formatedInstitutions = places.institutions?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'institution'
        }
      })
      const formatedCities = places.cities?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'city'
        }
      })
      setAllPlaces([
        ...formatedCampus,
        ...formatedCountries,
        ...formatedInstitutions,
        ...formatedCities
      ])
    }
  }, [places])

  // useEffect(() => {
  //   const durations = data?.data?.map(course => {
  //     return {
  //       duration: course.course.properties.length,
  //       time: course.course.properties.time
  //     }
  //   })
  //   const set = new Set(durations?.map(JSON.stringify))
  //   const arrSinDuplicaciones = Array.from(set)?.map(JSON.parse)
  //   if (durations) setAllDurations([...arrSinDuplicaciones])
  // }, [data])
  const handleRemoveFilter = (key) => {
    setSearchFilter(nFilter => {
      const copy = { ...nFilter }
      delete copy[key]
      return copy
    })
  }

  return (
    <Form form={form}>
      <Input.Group compact className='high-education-form-responsive'>
        <Form.Item noStyle name='short_type'>
          <Select
            className='select-index'
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              debounceFilter('short_type', search)
            }}
            placeholder='Camp type'
            style={{ width: '15%' }}
            size='large'
            allowClear
            loading={isFetching}
            onChange={(e) => {
              handleAddFilter('short_type', e)
            }}
          >
            <Option value='summer_camp'>Summer Camp</Option>
            <Option value='winter_camp'>Winter Camp</Option>
            <Option value='easter_camp'>Easter Camp</Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle name='age'>
          <InputNumber
            className='select-index select-age-responsive short-term__age'
            min={1}
            placeholder='Age'
            default={1}
            size='large'
            style={{ width: '10%' }}
            onChange={(e) => Number(e) > 0 ? handleAddFilter('age', Number(e)) : handleRemoveFilter('age')}
          />
        </Form.Item>
        <SearchActivities
          placeholder='Program Speciality'
          className='select-index'
          showSearch
          allowClear
          style={{ width: '20%' }}
          size='large'
          noStyle name='activities'
          onChange={(e) => {
            handleAddFilter('activities', e)
          }}
          filters={{ program: 'short_term' }}
          displayOnlyAtSearch
        />
        <SelectReligionsFormItem
          noStyle name='religion'
          className='select-index'
          showSearch
          allowClear
          placeholder='Religion'
          style={{ width: '15%' }}
          size='large'
          loading={isFetching}
          onChange={(e) => {
            handleAddFilter('religion', e)
          }}
          filters={{ program: 'short_term' }}
        />
        <Form.Item noStyle name='place'>
          <Select
            className='select-index'
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              debounceFilter('searchPlace', search)
            }}
            placeholder='Location (search by city, country or school)'
            style={{ width: '20%' }}
            size='large'
            allowClear
            loading={isFetching}
            onChange={(e) => {
              handleAddFilter('place', e)
            }}
          >
            {allPlaces?.map((item) => <Option key={item.type + item.value} value={item.value + ':' + item.type}>{item.label}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item noStyle name='lang'>
          <Select
            className='select-index'
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              setSearchlang(!!search)
            }}
            placeholder='Language'
            style={{ width: '10%' }}
            size='large'
            allowClear
            loading={isLoadingLanguage}
            onChange={(e) => {
              handleAddFilter('lang', Number(e))
            }}
          >
            {searchlang && languages?.map((item) => <Option key={item?.id} value={item?.id}>{item?.name}</Option>)}
          </Select>
        </Form.Item>
        <div className='button-without-border' style={{ width: '3%' }}>
          <Button icon={<CloseOutlined />} block size='large' style={{ backgroundColor: '#D4D4D4' }} onClick={handleClearFilters} />
        </div>
        <div className='button-without-border-start' style={{ width: '7%' }}>
          <Button onClick={handleOnSubmitSearch} type='primary' block size='large'>Search</Button>
        </div>
      </Input.Group>
    </Form>
  )
}

export { ShortTermForm }
