import React, { useEffect, useState } from 'react'
import { Col, Row, Statistic, notification } from 'antd'
import { useGetCurrencyToCurrency } from '../../../bussines/hooks/currencies/useGetCurrencyToCurrency'
import { useAuth } from '../../../providers/auth/auth-context-provider'

export function QuoteOverviewTotals ({ totals, divisa, agent, exchangeVal, agentDivisa, className, soloTotals, course }) {
  const [currency, setCurrency] = useState('MXN')
  const { data: newdata, getCurrencyToCurrency, isLoading } = useGetCurrencyToCurrency()
  const { userLocalCurrency } = useAuth()

  useEffect(() => {
    getCurrencyToCurrency(userLocalCurrency, currency)
  }, [currency])

  useEffect(() => {
    if (totals) {
      totals?.forEach(total => {
        if (total.total !== '-') {
          setCurrency(total?.divisa)
          console.log('🚀 ~ file: QuoteOverviewTotals.js:20 ~ useEffect ~ total?.divisa:', total?.divisa)
        }
      })
    }
  }, [totals])

  useEffect(() => {
    if (!isLoading && newdata) {
      console.log('🚀 ~ file: QuoterForm.js:144 ~ useEffect ~ newdata:', newdata)
      if (!newdata.data) {
        notification.error({
          message: 'Exchange Value Not found'
        })
      }
    }
  }, [isLoading, newdata])

  return (
    <Row justify='space-between'>
      <Col className='quote-overview__totals' md={12}>
        <p>Costo Total</p>
        {course?.length
          ? (
            <p className='fw-4'>Curso de {course?.length}</p>
            )
          : (
            <p className='fw-4'>Curso de {course?.weeks} semanas</p>
            )}
      </Col>
      <Col md={12}>
        <Row justify='end'>
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {totals?.map((total, index) => (
              <React.Fragment key={index}>
                {total?.total !== '-' && (
                  <div className='quote-overview__prices price_1'>
                    <Statistic prefix='$' value={`${total.total}`} precision={2} /> {total?.divisa}
                  </div>
                )}
              </React.Fragment>
            ))}
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {totals?.map((total, index) => (
              <React.Fragment key={index}>
                {total?.total !== '-' && (
                  <div className='quote-overview__prices price_2'>
                    <span style={{ fontSize: '0.7rem', fontWeight: '500' }}>Aprox.</span> <Statistic prefix='$' value={`${newdata ? (total.total * newdata.data?.value) : total.total}`} precision={2} suffix={userLocalCurrency} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
