import { Link } from "react-router-dom";

export function HomeSectionK() {
  return (
    <div>
      <div class='col d-flex justify-center align-center' style={{background: '#2460B7',height: '100px'}}>
        <Link
          to={{}}
          state={{}}
        >
          <span className='align-icon-txt byp-color-white byp-text-lg'>Privacy Notice</span>
        </Link>
      </div>
    </div>
  );
}
