import React, { useEffect } from 'react'
import { Statistic, Switch } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import { calculateHsCost } from '../utils/calculateCost'

function FieldHighSeason ({ forLodging = false, forTransport = false }) {
  const { service, formConfig, setFormConfig } = useQuoter()
  let value = 0
  let highSeasons = []
  if (forLodging) {
    value = service.getLodgingAmountHighSeason()
    highSeasons = service.getLodgingHighSeasonValues()
  } else if (forTransport) {
    value = service.getTransportAmountHighSeason()
  } else {
    value = service.getHighSeasonFeesAmount()
  }

  const handleAddHSFee = (value, { id, cost, frequency }, hsId) => {
    let PeakSeasonFees = formConfig.PeakSeasonFees || []
    const nExtras = []
    if (value) {
      PeakSeasonFees.push({ id, cost, frequency, hsId })
    } else {
      formConfig.PeakSeasonFees?.map((extra) => (
        extra.id !== id ? nExtras.push(extra) : null
      ))
      PeakSeasonFees = nExtras
    }
    setFormConfig({
      ...formConfig,
      PeakSeasonFees
    })
  }

  useEffect(() => {
    const loadInitialData = async () => {
      const peakSeasonFees = formConfig.PeakSeasonFees || []
      try {
        const hsDetails = highSeasons?.details
        if (hsDetails) {
          for (const hs of hsDetails) {
            const fees = hs?.fees
            for (const fee of fees) {
              const { id, cost, frequency } = fee
              peakSeasonFees.push({ id, cost, frequency, hsId: hs.id })
              await service.isLodgingPSFeeSelected(fee.id)
            }
          }
          setFormConfig({
            ...formConfig,
            PeakSeasonFees: peakSeasonFees
          })
        }
      } catch (error) {
        console.error('Error loading initial data:', error)
      }
    }
    loadInitialData()
  }, [])

  return (
    <>
      {
        forTransport && (
          <RowQuoter
            colA={<p>Peak Season Total Suplement</p>}
            colB={
              <Statistic
                prefix=''
                precision={2}
                value={`${value}`}
                suffix={`${service.getCurrency()}`}
              />
            }
          />
        )
      }
      {
        highSeasons?.details?.map((hs) => hs.fees?.map((fee) => (
          <RowQuoter
            key={'ps_fee_' + fee.id}
            colA={
              <p>{'High Season Cost: ' + fee.name}:
                {!fee?.mandatory && (
                  <Switch
                    onChange={(val) => handleAddHSFee(val, fee, hs.id)}
                    checked={service.isLodgingPSFeeSelected(fee.id)}
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                  />
                )}
              </p>
            }
            colB={
              <Statistic
                prefix=''
                precision={2}
                value={`${service.isLodgingPSFeeSelected(fee.id) ? calculateHsCost(fee.cost, fee.frequency, hs.weeks, hs.daysOff) : 0}`}
                suffix={`${service.getCurrency()}`}
              />
            }
          />
        )))
      }
    </>
  )
}

export { FieldHighSeason }
