import { useMutation } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'
import state from '../../utils/state-control'
import { useState } from 'react'

function useGetExchangeValuesByCurrency () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.getExchangeValuesByCurrency, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const getExchangeValuesByCurrency = (currency, curreciesForAutoExchange) => {
    mutation.mutate({ currency, curreciesForAutoExchange })
  }

  return { ...mutation, state, currentState, getExchangeValuesByCurrency }
}

export { useGetExchangeValuesByCurrency }
