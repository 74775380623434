import React, { useState, useEffect, useMemo } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useSearch } from '../../../../providers/search/search-context-provider'
import debounce from 'lodash/debounce'
import { useGetPlaces } from '../../../../bussines/hooks/quoter/useGetPlaces'
import { CloseOutlined } from '@ant-design/icons'
// import { useGetCoursesByFilter } from '../../../../bussines/hooks/quoter/useGetCoursesByFilter'
import { SelectGradesFormItem } from '../../../../common/components/helper-form/SelectGradesFormItem'
import { SelectReligionsFormItem } from '../../../../common/components/helper-form/SelectReligionsFormItem'
import { SelectTypeOfInstitutionsFormItem } from '../../../../common/components/helper-form/SelectTypeOfInstitutionsFormItem'
import { SearchCourseType } from '../../../../common/components/quoter-search/SearchCourseType'
import { SearchActivities } from '../../../../common/components/quoter-search/SearchActivities'

const { Option } = Select

const HighSchoolForm = ({ program }) => {
  const { changeSearch } = useSearch()

  const [searchFilter, setSearchFilter] = useState({
    program
  })
  const [allPlaces, setAllPlaces] = useState([])

  const [form] = Form.useForm()
  const { data: places, isFetching } = useGetPlaces(
    searchFilter?.searchPlace && { search: searchFilter?.searchPlace ?? '', lang: searchFilter?.lang ?? '', program }
  )

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (countFilter) {
      changeSearch('LANG_FORM', {
        ...searchFilter
      })
    }
  }

  const handleClearFilters = () => {
    const nFilter = {
      program
    }
    setSearchFilter(nFilter)
    form.resetFields()
    changeSearch('LANG_FORM', {
      ...nFilter
    })
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilter = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilter)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilter = {
      ...searchFilter,
      [key]: value
    }
    if (key === 'place' && typeof value == 'string') {
      const place = value.split(':')
      nFilter.place = place[0]
      nFilter.place_type = place[1]
    } else if (key === 'place') {
      nFilter.place = 'undefined'
      nFilter.place_type = 'undefined'
    }
    if (key === 'activities') {
      nFilter.activities = value
    }
    if (key === 'religion') {
      nFilter.religion = value
    }
    if (key === 'grade') {
      nFilter.grade = value
    }
    if (key === 'type_course') {
      nFilter.type_course = value
    }
    if (key === 'type_institute') {
      nFilter.type_institute = value
    }
    if (key === 'type') {
      nFilter.type = value
      nFilter.place_type = 'type'
    }
    if (key === 'gender') {
      nFilter.gender = value
    }
  
    setSearchFilter(nFilter)
  }

  useEffect(() => {
    if (places) {
      const formatedCampus = places.campus?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'campus'
        }
      })
      const formatedCountries = places.countries?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'country'
        }
      })
      const formatedInstitutions = places.institutions?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'institution'
        }
      })
      const formatedCities = places.cities?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'city'
        }
      })
      setAllPlaces([
        ...formatedCampus,
        ...formatedCountries,
        ...formatedInstitutions,
        ...formatedCities
      ])
    }
  }, [places])

  return (
    <Form form={form}>
      <Input.Group compact className='high-school-form-responsive'>
        <SelectGradesFormItem
          placeholder='Grade'
          className='select-index'
          showSearch
          style={{ width: '11%' }}
          size='large'
          allowClear
          onChange={(e) => {
            handleAddFilter('grade', e)
          }}
          noStyle name='grade'
          filters={{ program }}
        />
        <SearchActivities
          placeholder='Activities'
          className='select-index'
          showSearch
          allowClear
          style={{ width: '15%' }}
          size='large'
          noStyle name='activities'
          onChange={(e) => {
            handleAddFilter('activities', e)
          }}
          filters={{ program }}
          displayOnlyAtSearch
        />
        <SelectReligionsFormItem
          noStyle name='religion'
          className='select-index'
          showSearch
          allowClear
          placeholder='Religion'
          style={{ width: '12%' }}
          size='large'
          loading={isFetching}
          onChange={(e) => {
            handleAddFilter('religion', e)
          }}
          filters={{ program }}
        />
        <Form.Item noStyle name='place'>
          <Select
            placeholder='Location (search by city, country or school)'
            className='select-index'
            showSearch
            allowClear
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              debounceFilter('searchPlace', search)
            }}
            style={{ width: '15%' }}
            size='large'
            loading={isFetching}
            onChange={(e) => {
              handleAddFilter('place', e)
            }}
          >
            {allPlaces?.map((item) => <Option key={item.type + item.value} value={item.value + ':' + item.type}>{item.label}</Option>)}
          </Select>
        </Form.Item>
        <SelectTypeOfInstitutionsFormItem
          placeholder='Type of Institute'
          name='type'
          className='select-index'
          showSearch
          noStyle
          style={{ width: '12%' }}
          size='large'
          allowClear
          onChange={(e) => {
            handleAddFilter('type_institute', e)
          }}
          filters={{ program }}
        />
        <SearchCourseType
          placeholder='Program Length'
          noStyle name='type_course'
          className='select-index'
          showSearch
          style={{ width: '11%' }}
          size='large'
          allowClear
          onChange={(e) => {
            handleAddFilter('type_course', e)
          }}
          filters={{ program }}
        />
        <Form.Item noStyle name='gender'>
          <Select
            placeholder='Gender'
            className='select-index'
            showSearch
            allowClear
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            style={{ width: '10%' }}
            size='large'
            loading={isFetching}
            onChange={(e) => {
              handleAddFilter('gender', e)
            }}
          >
            <Option key='gender_only_boys' value='Only boys'>Only boys</Option>
            <Option key='gender_only_girls' value='Only girls'>Only girls</Option>
            <Option key='gender_mix' value='mix'>Mixed</Option>
          </Select>
        </Form.Item>
        {/*
          <div className='button-without-border' style={{ width: '5%' }}>
            <ButtonModalFilter />
          </div>
        */}
        <div className='button-without-border' style={{ width: '3%' }}>
          <Button icon={<CloseOutlined />} block size='large' style={{ backgroundColor: '#D4D4D4' }} onClick={handleClearFilters} />
        </div>
        <div className='button-without-border-start' style={{ width: '7%' }}>
          <Button onClick={handleOnSubmitSearch} type='primary' block size='large'>Search</Button>
        </div>
      </Input.Group>
    </Form>
  )
}

export { HighSchoolForm }
