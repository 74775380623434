import { Button, Table } from 'antd'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { IconEdit } from '../../../../common/assets/svg/icons'

const { Column } = Table

function TableDocuments ({ data, isLoading, setDocumentId, deleteDocument, canDelete = true, type, ...props }) {
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={data ?? []}
        className='byp-table-dashboard'
        title={() => 'Added documents'}
        rowKey='id'
        /* pagination={{ defaultPageSize: 2, showSizeChanger: false, pageSizeOptions: ['10', '20', '30'] }} */
      >
        <Column
          title='File name' dataIndex='name' key='name'
          render={(_, record) => (
            <a href={record.src} target='_blank' rel='noreferrer'><b>{record.name}</b></a>
          )}
          className='byp-mw-150'
        />
        <Column
          title={type === 'Institution' ? 'School' : 'School'}
          dataIndex='sedes'
          key='sede'
          render={(_, record) => (
            <div style={{ width: '100%', maxHeight: '8rem', overflow: 'auto' }}>
              {
                record.sedes?.map((sede) => (
                  <p
                    key={sede.id}
                    style={{ marginBottom: 0, fontSize: 'small' }}
                  >
                    * {sede.name}
                  </p>
                ))
              }
            </div>
          )}
        />
        <Column
          title='Expires' dataIndex='expirate_at' key='expirate_at'
        />
        <Column
          title='Actions'
          dataIndex='actions'
          key='actions'
          render={(_, record) => (
            <div style={{ display: 'flex' }}>
              <ConfirmModal onOk={deleteDocument} isLoading={isLoading} id={record.id} hasPermision={canDelete} />
              <Button
                size='small'
                className='byp-btn-edit'
                onClick={
                  () => {
                    setDocumentId(record.id)
                  }
                }
              ><IconEdit color='var(--blue-200)' />
              </Button>
            </div>
          )}
        />
      </Table>
    </div>
  )
}

export { TableDocuments }
