import { StepBaseTemplate } from '../../StepBaseTemplate'
import { HeaderStep } from '../../HeaderStep'
import { FormTitle } from '../../texts/FormTitle'
import React from 'react'
import { InstitutionFormExtras } from '../../forms/InstitutionFormExtras'
import FooterStep from '../../../../../common/components/steps/FooterStep'

export const STEP_INSTITUTION_EXTRA = 2

function InstitutionExtrasStep () {
  return (
    <StepBaseTemplate
      justify='space-around'
      footer={<FooterStep />}
    >
      <div style={{ margin: '18px 5%' }}>
        <HeaderStep />
        <FormTitle
          title='We want to know more about your institution'
          text=''
        />
        <InstitutionFormExtras currentStep={STEP_INSTITUTION_EXTRA} />
      </div>
    </StepBaseTemplate>
  )
}

export { InstitutionExtrasStep }
