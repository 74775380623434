import { userRoles } from '../../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../../common/components/PageProtected'
import { MainTheme } from '../../../../../common/theme/MainTheme'
import { ProgramAnaliticsInfo } from './ProgramAnaliticsInfo'

const URL_PROGRAM_ANALITCIS_PAGE = '/dashboard/analitics/program'

function ProgramAnaliticsPage () {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_AGENCY,
        userRoles.TYPE_AGENCY_OFFICE_MANAGER,
        userRoles.TYPE_AGENCY_MANAGER,
        userRoles.TYPE_AGENCY_COMMERCIAL_MANAGER,
        userRoles.TYPE_AGENCY_EDITOR
        // userRoles.TYPE_INSTITUTION
      ]}
    >
      <MainTheme
        showMenu
      >
        <ProgramAnaliticsInfo />
      </MainTheme>
    </PageProtected>
  )
}

export { ProgramAnaliticsPage, URL_PROGRAM_ANALITCIS_PAGE }
