const programs = ['language', 'graduate', 'academic_years', 'boarding_schools']
const types = [
  'bachelor',
  'postgraduate',
  'master',
  'diploma'
]
const esTypes = {
  bachelor: 'Licenciaturas',
  postgraduate: 'Posgrados',
  master: 'Maestrías',
  diploma: 'Diplomas y certificados'
}
const subtypes = ['diploma', 'advanced_diploma', 'certificate', 'graduate_diploma']

const validateProgramName = (programName) => {
  if (!programName) return programName
  if (programs.includes(programName)) {
    return {
      program: programName,
      type: null
    }
  }

  if (types.includes(programName) || subtypes.includes(programName)) {
    const values = {
      program: 'graduate'
    }
    const type = getProgramType(programName)
    if (type) {
      values.type = type
    }
    const subtype = getProgramSubType(programName)
    if (subtype) {
      values.subtype = subtype
    }
    return values
  }
  console.log('🚀 ~ validateProgramName ~ programName:', programName)
  return programName
  // throw Error('program name invalid')
}

const getProgram = (program) => {
  if (types.includes(program) || subtypes.includes(program)) {
    return 'graduate'
  }
  return program
}

const getProgramType = (program) => {
  if (types.includes(program)) {
    return types[types.indexOf(program)]
  }
  if (subtypes.includes(program)) {
    return 'diploma'
  }
  return null
}
const getProgramSubType = (program) => {
  if (subtypes.includes(program) && !program === 'graduate_diploma') {
    return subtypes[subtypes.indexOf(program)]
  }
  return null
}

const esProgramType = (programType) => {
  const es = esTypes[programType]
  return es ?? programType
}

export { validateProgramName, getProgram, getProgramType, getProgramSubType, esProgramType }
