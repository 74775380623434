import { Button, Switch, Form, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useChangeStatusUser } from '../../../../../bussines/hooks/user/useChangeStatusUser'
import { useGetUserAndInvitation } from '../../../../../bussines/hooks/user/useGetUserAndInvitation'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
// import ConfirmModal from '../../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../../common/theme/icons/EditIcon'
import { SelectUserModal } from '../../../users/SelectUserModal'
import { URL_CREATE_USERS_PAGE } from '../../../users/CrudUserPage'
import { crypt } from '../../../../../bussines/utils/basicEncript'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import ConfirmModal from '../../../../../common/components/ConfirmModal'
import { useDeleteUser } from '../../../../../bussines/hooks/user/useDeleteUser'

const UserInfo = ({ user, refetch, isLoadingUser, showActions = true, noEdit }) => {
  const [form] = Form.useForm()
  const [mdl, setMdl] = useState(false)
  const [validUsers, setValidUsers] = useState([])
  const [userId, setUserId] = useState()
  const { user: _user } = useAuth()

  const navigate = useNavigate()

  const { changeStatus, isLoading, data: resp, error } = useChangeStatusUser()
  const { deleteUser, isLoading: isDeletingUser, data: respDeleted } = useDeleteUser()
  const { data } = useGetUserAndInvitation({})

  const handleChange = (val, id) => {
    // console.log('🚀 ~ file: UserInfo.js:21 ~ handleChange ~ val', val)
    if (val) {
      changeStatus(id)
    } else {
      const validUsers = data?.users?.filter(
        (item) => item.status === 'Active' && item.id !== id
      )
      setValidUsers(validUsers)
      setMdl(true)
      setUserId(id)
    }
  }

  const closeModal = () => {
    setMdl(false)
  }

  const handleSubmit = () => {
    changeStatus(userId, form.getFieldValue('user'))
    setMdl(false)
  }

  useEffect(() => {
    if (!isLoading && resp) {
      refetch()
      notification.success({
        message: 'User status updated'
      })
    }
    if (!isLoading && error) {
      // refetch()
      notification.error({
        message: 'Something went wrong...'
      })
    }
  }, [isLoading, resp])

  useEffect(() => {
    if (!isDeletingUser && respDeleted) {
      refetch()
    }
  }, [isDeletingUser, isLoading, refetch, respDeleted])

  if (isLoadingUser) {
    return <BasicSpinner />
  }

  const editProfile = () => {
    navigate(URL_CREATE_USERS_PAGE + '/' + crypt('qyp2023', user.id + ''))
  }

  return (
    <div className='display-card'>
      <div className='display-card-bottom'>
        <div className='card-user__item d-flex align-center card-first-item'>
          <div className='display-image'>
            <img alt='' src={user?.profile_photo_url} />
          </div>
        </div>
        <div className='display-information'>
          <h4 className='byp-text-sm byp-title byp-fw-bold'>INFORMATION</h4>
          <div className='d-flex user-information'>
            <div>
              <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
                {user?.roles?.map((item) => (
                  <span key={item}>{item} </span>
                ))}
              </p>
              <p className='byp-text-md byp-title'>{user?.name}</p>
              <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
                Working since
              </p>
              <p className='byp-text-md byp-title'>{user?.created_at}</p>
            </div>
            <div>
              <p className='byp-text-sm byp-color-gray-100 byp-m-0'>Email</p>
              <p className='byp-text-md byp-title'>{user?.email}</p>
              <p className='byp-text-sm byp-color-gray-100 byp-m-0'>Office</p>
              <p className='byp-text-md byp-title'>
                {user?.sedes?.map((sede) => (
                  <span key={sede}>{sede}</span>
                ))}
              </p>
            </div>
          </div>
        </div>
        <div className={showActions ? 'card-user__item' : 'card-user__item'}>
          <h4 className='byp-text-sm byp-title byp-fw-bold'>ALL TIME</h4>
          <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
            Quotes created
          </p>
          <p className='byp-text-md byp-title'>{user?.quotes}</p>
          <p className='byp-text-sm byp-color-gray-100 byp-m-0'>
            Sended quotes
          </p>
          <p className='byp-text-md byp-title'>{user?.quotes_send}</p>
        </div>
        <div
          style={{ alignItems: 'center', display: 'flex' }}
          className='card-user__item--last'
        >
          {_user?.type !== 'Education group' &&
            <Button
              onClick={editProfile}
              type='button'
              className='ant-btn ant-btn-default byp-btn-back '
            >
              Edit profile
            </Button>}
        </div>
        {showActions && (
          <div className='card-user__item--last card-user__item--last-btn d-flex align-center card-last-item'>
            <div>
              <div>
                <Switch
                  style={{ alignItems: 'center', display: 'flex' }}
                  checkedChildren='ACTIVE'
                  unCheckedChildren='INACTIVE'
                  onClick={(value) => handleChange(value, user.id)}
                  checked={
                    user?.status === 'Active' ||
                    user?.status === 'Pending' ||
                    user?.status === 'Created'
                  }
                />
              </div>
              <div
                className='d-flex byp-mt-1'
                style={{ justifyContent: 'space-between' }}
              >
                <ConfirmModal
                  onOk={deleteUser}
                  isLoading={isDeletingUser}
                  id={user?.id}
                />
              </div>
            </div>
            <SelectUserModal
              setMdl={setMdl}
              mdl={mdl}
              clsMdl={closeModal}
              handleSubmit={handleSubmit}
              validUsers={validUsers}
              form={form}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default UserInfo
