import { Form, Modal, notification } from 'antd'
import React, { useEffect } from 'react'
import { crypt } from '../../../bussines/utils/basicEncript'
import { usePackage } from '../../../providers/package/package-course-context'
// import { URL_QUOTE_PREVIEW } from '../../quote-preview'
import { URL_VENDOR_PAGE } from '../../about/VendorPage'
import { showErrorModal } from '../../../utils/propsForModal'

function PackageFormConfigured ({ children }) {
  const {
    service,
    form, handleFinishForm, course, errorPost,
    isSuccessPost, isErrorPost, quote,
    setFormConfig, formConfig,
    isLoadingOffer, prices
  } = usePackage()
  const [modal, contextHolder] = Modal.useModal()

  useEffect(() => {
    if (isSuccessPost) {
      const studentIdEncripted = crypt('qyp2023', quote?.student_id)
      let url = URL_VENDOR_PAGE.replace(':studentId', studentIdEncripted)
      const userIdEncripted = crypt('qyp2023', '' + quote?.user_id)
      url = url.replace(':agentId', userIdEncripted)
      window.open(url)
    }
    if (isErrorPost) {
      const errMsg = errorPost?.response?.data?.message
      showErrorModal(modal, errMsg)
      // modal.error({
      //   title: 'Something went wrong',
      //   content: errMsg ?? 'Please, try again or contact us'
      // })
    }
  }, [isSuccessPost, isErrorPost])

  useEffect(() => {
    if (course && !isLoadingOffer) {
      const loadInitialData = async () => {
        // if (!formConfig.additionals) {
        //   setFormConfig({
        //     ...formConfig,
        //     additionals: []
        //   })
        // }

        try {
          if (course?.offer?.start_at && course?.offer?.end_at) {
            const lenghtDate = new Date(course?.offer?.end_at) - new Date(course?.offer?.start_at)
            if (!course?.offer?.min_weeks && !course?.offer?.max_weeks) {
              const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
              setFormConfig({
                ...formConfig,
                weeks: differenceWeeks,
                courseStart: course?.offer?.start_at,
                courseEnd: course?.offer?.end_at
              })
            }
          }

          const additionalsCosts = []
          const addttions = service?.getAdditions()
          if (addttions) {
            for (const item of addttions) {
              if (item.mandatory) {
                additionalsCosts.push({ ...item })
              }
            }
          }

          setFormConfig(prevFormConfig => ({
            ...prevFormConfig,
            additionals: additionalsCosts
          }))
        } catch (error) {
          console.error('Error loading initial data:', error)
        }
      }
      loadInitialData()
    }
  }, [course, isLoadingOffer, prices])

  return (
    <Form
      form={form}
      layout='horizontal'
      name='basic'
      onFinish={handleFinishForm}
      autoComplete='off'
      scrollToFirstError
      requiredMark={false}
      initialValues={{
        // weeks: service.getMinWeeks()
      }}
      onFinishFailed={
        () => notification.info({
          message: 'Please, fill all the required data',
          placement: 'topLeft'
        })
      }
    >
      {contextHolder}
      {children}
    </Form>
  )
}

export { PackageFormConfigured }
