import React from 'react'
import {
  Tag
} from 'antd'
import { getFrequencyLabel } from '../../../../../utils/frequencyUtils'

const CoursePricesView = ({ data, showJson, ...props }) => {
  return (
    <div {...props}>
      {!showJson && (
        <>
          {data?.costs?.map((cost) => {
            const min = cost.rules?.find((rule) => rule.name === 'min_frequency')
            const max = cost.rules?.find((rule) => rule.name === 'max_frequency')
            return (
              <React.Fragment key={cost.id}>
                <Tag>
                  <span className='byp-fw-bold'>{cost.name}</span>
                  <span> {cost.cost} {data.divisa} </span> <span>{getFrequencyLabel(cost.frequency)}</span>
                  {min && max && (
                    <span> From {min.pivot.value} to {max.pivot.value} Weeks </span>
                  )}
                </Tag>
              </React.Fragment>
            )
          })}
        </>
      )}
      {showJson && (
        <pre style={{ maxHeight: '40vh', overflow: 'auto' }}>{JSON.stringify(data ?? {}, null, 2)}</pre>
      )}
    </div>
  )
}

export { CoursePricesView }
