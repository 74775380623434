import React, { useEffect } from 'react'
import { Row, Col, Space } from 'antd'
import {
  CreatedIcon,
  SendedIcon,
  VisitedIcon,
  ApplicationsIcon
} from '../../../../quote-preview/assets/imgs/Icons'
import { useGetQuotesInfo } from '../../../../../bussines/hooks/agency/analytics/users/useGetQuotesInfo'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'
import Progress from 'antd/es/progress'
import { useNavigate } from 'react-router-dom'

const itemCardExtraStyles = {
  padding: '0.5rem 1.5rem',
  height: '100%'
}
const TotalQuotes = ({ time, user, setIsActiveTotalQuote, setActiveCategory, activeCategory, setOrderby, isMyReports, isAgency, studentID,isEducationGroup }) => {
  const { data, isLoading, error } = useGetQuotesInfo(user, { ...{ ...time, studentID }, studentID })
  useEffect(() => {
    if (!isLoading && data) {
      // console.log('🚀 ~ file: TotalQuotes.js:16 ~ useEffect ~ data', data)
    }
  }, [data, isLoading, error])

  const navigate = useNavigate()

  const activeTotal = (category) => {
    setActiveCategory(category)
    setIsActiveTotalQuote(true)
    if (activeCategory === category) {
      setActiveCategory('')
      setIsActiveTotalQuote(false)
    }
    // if (setOrderby) {
    //   setOrderby()
    // }

    if (isAgency&&!isEducationGroup) {
      navigate('/dashboard/reports', { state: { category, user: user } })
    }
  }

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]} className='total-quote-user'>
      <Col
        className={`total-quote ${isMyReports ? 'user-analysis-total' : ''}`}
      >
        <div className={`quote-list-item-card ${activeCategory === 'created' ? 'active' : ''}`} style={itemCardExtraStyles} onClick={() => activeTotal('created')}>
          <CreatedIcon style={{ margin: '5px 0' }} />
          <div className='d-flex align-center user-info byp-mb-1' style={{ justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.created?.count ?? 0}</h5>
            <Space wrap>
              <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.created?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
            </Space>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Created</p>
        </div>
      </Col>
      <Col
        className={`total-quote ${isMyReports ? 'user-analysis-total' : ''}`}
      >
        <div className={`quote-list-item-card ${activeCategory === 'sended' ? 'active' : ''}`} style={itemCardExtraStyles} onClick={() => activeTotal('sended')}>
          <SendedIcon style={{ color: '#686868', margin: '5px 0' }} />
          <div className='d-flex align-center user-info byp-mb-1' style={{ justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.sended?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.sended?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Sent by email</p>
        </div>
      </Col>
      <Col
        className={`total-quote ${isMyReports ? 'user-analysis-total' : ''}`}
      >
        <div className={`quote-list-item-card ${activeCategory === 'seen' ? 'active' : ''}`} style={itemCardExtraStyles} onClick={() => activeTotal('seen')}>
          <VisitedIcon className='byp-mb-1' style={{ margin: '5px 0' }} />
          <div className='d-flex align-center user-info byp-mb-1' style={{ justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.visited?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.visited?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Seen <i className='byp-text-sm'>(mail and link)</i></p>
        </div>
      </Col>
      <Col
        className={`total-quote ${isMyReports ? 'user-analysis-total' : ''}`}
      >
        <div className={`quote-list-item-card ${activeCategory === 'notseen' ? 'active' : ''}`} style={itemCardExtraStyles} onClick={() => activeTotal('notseen')}>
          <VisitedIcon className='byp-mb-1' style={{ margin: '5px 0' }} />
          <div className='d-flex align-center user-info byp-mb-1' style={{ justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.notseen?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.notseen?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Not Seen <i className='byp-text-sm'>(mail and link)</i></p>
        </div>
      </Col>
      <Col
        className={`total-quote ${isMyReports ? 'user-analysis-total' : ''}`}
      >
        <div className={`quote-list-item-card ${activeCategory === 'applications' ? 'active' : ''}`} style={itemCardExtraStyles} onClick={() => activeTotal('applications')}>
          <ApplicationsIcon className='byp-mb-1' style={{ margin: '5px 0' }} />
          <div className='d-flex align-center user-info byp-mb-1' style={{ justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.applications?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.applications?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Applications</p>
        </div>
      </Col>
    </Row>
  )
}

export default TotalQuotes
