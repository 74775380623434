import { useStepByStep } from '../../../providers/stepbystep/step-service-provider'
import { AgencyProfileStep, STEP_PROFILE } from './steps/agency-profile/AgencyProfileStep'
import { AgencyTemplateStep, STEP_TEMPLATE } from './steps/agency-template/AgencyTemplateStep'
import { AgencyPaymentsStep, STEP_PAYMENTS } from './steps/agency-payments/AgencyPaymentsStep'
import { AgencyMiscellaneousStep, STEP_MISCELLANEOUS } from './steps/agency-miscellaneous/AgencyMiscellaneousStep'
import { AgencyBranchesStep, STEP_INVITATION_BRANCHES } from './steps/agency-branches/AgencyBranchesStep'
import { AgencyUsersStep, STEP_INVITATION_USERS } from './steps/agency-users/AgencyUsersStep'
import { AgencyFinishStep, STEP_FINISH } from './steps/finish-step/AgencyFinishStep'
import React from 'react'
import { ExceptionStepController } from './ExceptionStepController'
// import { NotFoundPage } from '../../../common/components/NotFoundPage'
import { PageNotFound } from '../../../pages/errors/PageNotFound'
import { FirstStep, STEP_CONTINUE_REGISTRATION } from './steps/first-step/FirstStep'
import { AgencyPartnerStep, STEP_AGENCY_PARTNER } from './steps/agency-partner/AgencyPartnerStep'

function AgencyStepRouter () {
  const { step, isLoading } = useStepByStep()

  if (isLoading) {
    return <p>Cargando datos</p>
  }

  let template = null

  switch (step) {
    case STEP_CONTINUE_REGISTRATION:
      template = (<FirstStep />)
      break
    case STEP_PROFILE:
      template = (<AgencyProfileStep />)
      break
    // case STEP_TEMPLATE:
    //   template = (<AgencyTemplateStep />)
    //   break
    // case STEP_PAYMENTS:
    //   template = (<AgencyPaymentsStep />)
    //   break
    // case STEP_MISCELLANEOUS:
    //   template = (<AgencyMiscellaneousStep />)
    //   break
    // case STEP_INVITATION_BRANCHES:
    //   template = (<AgencyBranchesStep />)
    //   break
    // case STEP_INVITATION_USERS:
    //   template = (<AgencyUsersStep />)
    //   break
    // case STEP_AGENCY_PARTNER:
    //   template = <AgencyPartnerStep />
    //   break
    case STEP_FINISH:
      template = <AgencyFinishStep />
      break
    default:
      template = <PageNotFound />
      break
  }
  return <ExceptionStepController usePostMethod={useStepByStep}>{template}</ExceptionStepController>
}

export { AgencyStepRouter }
