import { Form, Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetAllCampus } from '../../../bussines/hooks/school/useGetAllCampus'
import { useAuth } from '../../../providers/auth/auth-context-provider'
const { Option } = Select
function SelectCampusFormItem ({ name = 'sede', onChange, single, allowClear, showNameSede = false, haveOptionAll = false, showInstitution = false, haveOptionAllCampus = false, filters, ...props }) {
  const { isLoading, data } = useGetAllCampus(filters)
  const form = Form.useFormInstance()
  const { user: _user } = useAuth()

  useEffect(() => {
    if (!isLoading && data) {
      // form?.setFieldsValue({ [name]: 'all' })
      // let val
      // const firtCampus = data.campus[0]
      // if (!firtCampus) {
      //   form?.setFieldsValue({ [name]: val })
      //   return
      // }
      // if (showNameSede) {
      //   val = firtCampus?.id + '-' + firtCampus?.name
      // } else {
      //   val = firtCampus?.id
      // }
      // form?.setFieldsValue({ [name]: val })
      // if (onChange) onChange(val)
    }
  }, [isLoading, data])

  return (
    <Form.Item {...props} name={name}>
      <Select
        {...props}
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        loading={isLoading}
        mode={single ? undefined : 'multiple'}
        className='byp-m-0'
        onChange={(val) => {
          if (!single) {
            if (val?.includes('all')) {
              const ids = data?.campus?.map((vampus) => vampus.id)
              console.log('🚀 ~ file: SelectCampusFormItem.js:42 ~ SelectCampusFormItem ~ ids:', ids)
              if (onChange) onChange(ids)
              form.setFieldsValue({
                [name]: ids
              })
            } else {
              if (onChange) onChange(val)
            }
          } else {
            if (onChange) onChange(val)
          }
          // setCampus(val)
        }}
        allowClear={allowClear}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        defaultActiveFirstOption
      >
        {/* {(!haveOptionAll && !showInstitution) && <Option value={null}>All</Option>} */}
        {haveOptionAll && <Option value='all'>All</Option>}
        {haveOptionAllCampus && <Option value=''>All the campus</Option>}
        {showInstitution && <Option value='main'>{_user?.institution_name}</Option>}

        {!showNameSede && data?.campus?.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}

        {showNameSede && data?.campus?.map(item => (
          <Option key={item.id + '-' + item.name} value={item.id + '-' + item.name}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectCampusFormItem }
