import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useGetRolesBySede } from '../../../../../bussines/hooks/user/useGetRolesBySede'
import { useGetUsersByRoleAndSede } from '../../../../../bussines/hooks/user/useGetUsersByRoleAndSede'
import { SelectBranchesFormItem } from '../../../../../common/components/helper-form/SelectBranchesFormItem'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import { SelectCampusFormItem } from '../../../../../common/components/helper-form/SelectCampusFormItem'

const { Option } = Select
export function UserSelects ({ user, setUser, sede, setSede, role, setRole, setNameSede, nameSede, roleType, selectAnotherUser = true, notShowSelects = false }) {
  const { data: roles, isLoading, error } = useGetRolesBySede(sede)
  const { data: users, isLoading: loadingUsers, error: errorAtUsers } = useGetUsersByRoleAndSede(sede, role)
  const { user: _user } = useAuth()

  useEffect(() => {
    if (!loadingUsers && users) {
      // console.log('🚀 ~ file: UserSelects.js:16 ~ useEffect ~ users', users)
    }
  }, [users, loadingUsers, errorAtUsers])

  useEffect(() => {
    if (!isLoading && roles) {
      console.log('🚀 ~ file: roleselects.js:16 ~ useEffect ~ roles', roles)
    }
  }, [roles, isLoading, error])

  const handleSede = (val) => {
    const sedeVal = val?.split('-')
    setSede(sedeVal[0])
    setNameSede(sedeVal[1])
  }

  return (
    <div className='d-flex selects-responsives-column' style={{ gap: '0.5rem' }}> 
      {_user?.type === 'Agency' && (
        <SelectBranchesFormItem
          className={notShowSelects ? 'byp-d-none' : 'user-analysis__select'}
          placeholder=''
          style={{ width: 200 }}
          size='small'
          value={(sede && nameSede) ? sede + '-' + nameSede : ''}
          isDisabled={roleType?.includes('agency-office-manager')}
          onChange={(val) => {
            handleSede(val)
            setRole()
            setUser()
          }}
          showNameSede
        />
      )}
      {_user?.type === 'Institution' && (
        <SelectCampusFormItem
          className={notShowSelects ? 'byp-d-none' : 'user-analysis__select'}
          placeholder=''
          single
          style={{ width: 200 }}
          size='small'
          value={(sede && nameSede) ? nameSede : ''}
          onChange={(val) => {
            handleSede(val)
            setRole()
            setUser()
          }}
          showNameSede
        />
      )}
      {/* {_user?.type === 'Education group' && (
        <SelectOfficesFormItem
          className={notShowSelects ? 'byp-d-none' : 'user-analysis__select'}
          placeholder=''
          single
          style={{ width: 200 }}
          size='small'
          value={(sede && nameSede) ? nameSede : ''}
          onChange={(val) => {
            handleSede(val)
            setRole()
            setUser()
          }}
          showNameSede
        />
      )} */}
      <Select
        className={notShowSelects ? 'byp-d-none' : 'user-analysis__select'}
        placeholder=''
        style={{ width: 120 }}
        size='small'
        value={role}
        onChange={(val) => {
          setRole(val)
          setUser()
        }}
      >
        {roles?.map((role) => (
          <Option key={role.name} value={role.name}>{role.label}</Option>
        ))}
      </Select>
      <Select
        className={notShowSelects ? 'byp-d-none' : 'user-analysis__select'}
        placeholder=''
        style={{ width: 120 }}
        size='small'
        value={user}
        onChange={(val) => {
          setUser(val)
        }}
        disabled={!selectAnotherUser}
      >
        {users?.map((user) => (
          <Option key={user.id} value={user.id}>{user.name ?? user.id}</Option>
        ))}
      </Select>
    </div>
  )
}
