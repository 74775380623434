import { Button, Table, Switch } from 'antd'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { useEffect, useState } from 'react'
// import { DiscountTableFilters } from './Filters/DiscountTableFilters'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { ErrorBoundary } from '../../../../providers/validation/ErrorBoundary'
import { FiltersFormUploadPromotions } from '../../packages/FiltersFormUploadPromotions'

const { Column } = Table

function TableDiscountsAgency ({ data, isLoading, refetch, setDiscountId, deleteDiscount, program, setPage, updateDiscount, isUpdatingStatus, searchFilter, setSearchFilter }) {
  const [discounts, setDiscounts] = useState()

  useEffect(() => {
    setDiscounts(data?.data)
    console.log('🚀 ~ file: TableDiscountsAgency.js:16 ~ useEffect ~ data:', data)
  }, [data])

  const handleExpireDate = (date) => {
    if (date) {
      const fecha = new Date(date)
      const mes = fecha.getMonth() + 1
      const dia = fecha.getDate()

      return `${dia}/${mes}/${fecha.getFullYear()}`
    }

    return '-'
  }

  const handleLabelExpireDate = (date, status) => {
    if (date) {
      const fechaCaducidad = new Date(date)
      const fechaActual = new Date()

      if (fechaActual > fechaCaducidad) return 'FINISHED'
      return status
    }
  }

  const handleChangeStatus = (e, id) => {
    updateDiscount({ status: e }, id)
  }

  return (
    <ErrorBoundary>
      {/* <DiscountTableFilters program={program} setData={setDiscounts} data={data} /> */}
      <FiltersFormUploadPromotions program={program} setData={setDiscounts} data={data} searchFilter={searchFilter} setSearchFilter={setSearchFilter} />
      <Table
        loading={isLoading}
        dataSource={discounts ?? []}
        className='byp-table-dashboard'
        title={() => 'Added promos'}
        rowKey='id'
        pagination={{
          current: data?.current_page,
          size: data?.last_page,
          total: data?.total,
          pageSize: data?.per_page,
          onChange: (val) => setPage(val)
        }}
      >
        <Column
          title='Name' dataIndex='name' key='name'
          render={(_, record) => (
            <a>{record.name}</a>
          )}
        />
        <Column
          title='School'
          dataIndex='sedes'
          key='sedes'
          responsive={['lg']}
          render={(_, record) => (
            record.sedes?.map((item) => (
              <p key={item.id} className='byp-m-0'>{item.name}</p>
            ))
          )}
        />
        <Column
          title='Expire date'
          dataIndex='expiration-date'
          key='expiration-date'
          responsive={['lg']}
          render={(_, record) => (
            <p key={record.id + 'item'} className='byp-m-0'>{handleExpireDate(record?.end)}</p>
          )}
        />
        {/* <Column
          title='Programa'
          dataIndex='program'
          key='program'
          responsive={['lg']}
          render={(_, record) => (
            record.category?.name
          )}
        /> */}
        <Column
          title='Status'
          dataIndex='status-label'
          key='status-label'
          responsive={['sm']}
          render={(_, record) => (
            (
              <div className={'discount-status discount-status__' + handleLabelExpireDate(record?.end, record?.status)}>
                {handleLabelExpireDate(record?.end, record?.status?.toUpperCase())}
              </div>
            )
          )}
        />
        <Column
          title='Actions'
          dataIndex='status'
          key='status'
          render={(_, record) => (
            (
              <div>
                <Switch
                  style={{ alignItems: 'center', display: 'flex' }}
                  checkedChildren='ACTIVE'
                  unCheckedChildren='OFF'
                  loading={isUpdatingStatus}
                  onChange={(e) => handleChangeStatus(e, record?.id)}
                  checked={handleLabelExpireDate(record?.end, record?.status) !== 'FINISHED' && (record.status === 'Active' || record?.status === 'Created')}
                />
              </div>
            )
          )}
        />
        <Column
          title=''
          key='action'
          render={(_, record) => (
            <div className='dicount-actions-btns'>
              <ConfirmModal onOk={deleteDiscount} isLoading={isLoading} id={record.id} />
              <Button
                className='byp-btn-edit'
                loading={isLoading}
                size='small'
                onClick={
                  () => {
                    setDiscountId(record.id)
                  }
                }
                icon={<EditIcon />}
              />
            </div>
          )}
        />
      </Table>
    </ErrorBoundary>
  )
}

export { TableDiscountsAgency }
