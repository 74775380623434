import React from 'react'
import endImg from '../../../common/assets/images/bg-end.png'
import bypLogo from '../../../common/assets/images/byp_logo_div.png'

const endBgStyle = () => ({
  backgroundImage: `url(${endImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '700px',
  marginTop: '5rem'
})

export function HomeSectionJ () {
  return (
    <div className='d-flex align-items-center justify-content-center px-5 py-2' style={endBgStyle()}>
      <div className='cold home-logo-j' style={{ textAlign: 'center', marginTop: '26rem' }}>
        <img src={bypLogo} style={{ width: '250px', height: 'auto' }} alt='' />
      </div>
    </div>
  )
}
