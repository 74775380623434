import React from 'react'
import { StepProvider } from '../../providers/stepbystep/step-service-provider'
import { PageProtected } from '../../common/components/PageProtected'
import { AgencyStepRouter } from './components/AgencyStepRouter'
import { useGetStep } from '../../bussines/hooks/agency/useGetStep'
import { usePostStepAgency } from '../../bussines/hooks/agency/usePostStepAgency'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ProfileEnterpriseProvider } from '../../providers/enterprise/profile-enterprise-provider'
import { useGetAgencyProfile } from '../../bussines/hooks/agency/useGetAgencyProfile'
import { useUpdateAgencyProfile } from '../../bussines/hooks/agency/useUpdateAgencyProfile'

const URL_STEP_BY_STEP_PAGE_AGENCY = '/step-register/agency'

function AgencyStepByStepRegister () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <StepProvider useGetStep={useGetStep} usePostSteps={usePostStepAgency} useGetCompanyProfile={useGetAgencyProfile}>
        <ProfileEnterpriseProvider useGetCompanyProfile={useGetAgencyProfile} usePostUpdateCompanyProfile={useUpdateAgencyProfile}>
          <AgencyStepRouter />
        </ProfileEnterpriseProvider>
      </StepProvider>
    </PageProtected>
  )
}

export { AgencyStepByStepRegister, URL_STEP_BY_STEP_PAGE_AGENCY }
