import { useQuery } from 'react-query'
import GeneralAnalysisRepository from '../../../../repositories/GeneralAnalysisRepository'

function useGetSchoolsAverage ({ user, office, ...filters }) {
  const allFilters = { user, office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetSchoolsAverage', filtersQuery],
    () => GeneralAnalysisRepository.getSchoolsAverage(filtersQuery),
    {
      enabled: (!!user || !!office) && (!!filters.institution || !!filters.campus) && !!filters.program
    }
  )
}

function useGetProgramAverage ({ user, office, ...filters }) {
  const allFilters = { user, office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetProgramAverage', filtersQuery],
    () => GeneralAnalysisRepository.getProgramAverage(filtersQuery),
    {
      enabled: !!filters.program
      // enabled: (!!office || !!user || !!filters.country) && !!filters.program
    }
  )
}

function useGetOfficeAverage ({ office, ...filters }) {
  const allFilters = { office, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetOfficeAverage', filtersQuery],
    () => GeneralAnalysisRepository.getOfficeAverage(filtersQuery),
    {
      enabled: !!office && !!filters.program,
      retry: 1
    }
  )
}

function useGetProgramProgramAverage ({ user, program, ...filters }) {
  const allFilters = { user, program, ...filters }
  const filtersQuery = Object.keys(allFilters).map((key) => key + '=' + allFilters[key]).join('&')
  return useQuery(
    ['useGetProgramProgramAverage', filtersQuery],
    () => GeneralAnalysisRepository.getProgramAverage(filtersQuery),
    {
      enabled: (!!program || !!user)
    }
  )
}

export { useGetSchoolsAverage, useGetProgramAverage, useGetOfficeAverage, useGetProgramProgramAverage }
