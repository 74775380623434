import { Col, DatePicker, Form, InputNumber, Row } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import moment from 'moment'
import { format, subDays } from 'date-fns'
import { useEffect, useState } from 'react'

function SelectStartAndDuration () {
  const { service, formConfig, setFormConfig, form, course } = useQuoter()

  const disabledDate = (current) => {
    // Can not select days before today, today and mondays only
    let filteredDates
    const day = moment(current).isoWeekday()
    const datString = format(moment(current).toDate(), 'Y-LL-dd')
    const isHoliday = service.isHoliday(datString)
    const startDay = course?.offer?.start_day
    const startDates = course?.offer?.start_dates

    if (startDay === 'none') {
      const today = new Date()
      filteredDates = startDates?.filter(d => new Date(d.date) >= today)
      const specificDatesList = filteredDates?.map(dateObj => dateObj?.date)
      const isSpecific = specificDatesList?.some(date => moment(date).isSame(current, 'day'))
      return !isSpecific
    }

    let disabled
    disabled = day > service.getDayStartCourse() || day < service.getDayStartCourse()
    if (!disabled && isHoliday) {
      const nextDay = service.enableNextDay(datString)
      addEnabledDate(nextDay)
      return true
    }
    disabled = (current && disabled) || current < moment().endOf('day')

    const program = course?.offer?.program?.name
    if (startDates && (program === 'language' || program === 'summer_camps') && startDates?.length > 0) {
      const today = new Date()
      filteredDates = startDates?.filter(d => new Date(d.date) >= today)
    }

    if (filteredDates && (program === 'language' || program === 'summer_camps') && filteredDates?.length > 0) {
      const specificDates = filteredDates?.map(dateObj => dateObj?.date)
      const isSpecificDate = specificDates?.some(date => moment(date).isSame(current, 'day'))
      if (isSpecificDate) {
        return false
      }
      if (day === 1) {
        disabled = true
      }
    }

    if (startDay && (program === 'language') && !(current < moment().endOf('day'))) {
      moment.locale('en')
      const dayName = moment(current).locale('en').format('dddd')
      if (dayName.toLowerCase() === startDay.toLowerCase()) {
        return false
      }
    }

    return (disabled || isHoliday) && !service.isEnabledDate(datString)
  }

  const addEnabledDate = (date) => {
    const enabledDates = formConfig?.enabledDates ?? []
    const found = enabledDates.find((d) => d === date)
    if (found) {
      // console.log('La fecha ' + date + '  ya se había agregado')
      return true
    }
    enabledDates.push(date)
    setFormConfig({
      ...formConfig,
      enabledDates
    })
  }

  const onChange = (date) => {
    console.log('🚀 ~ file: onChange ~')
    const lodgingDate = subDays(new Date(date), 2)
    form.setFieldsValue({ lodgingStart: moment(lodgingDate) })
    setFormConfig({
      ...formConfig,
      courseStart: date,
      lodgingStart: moment(lodgingDate)
    })
    service?.searchPrices(date)
    setStartCourse(date)
  }

  const changeWeeks = (nWeeks) => {
    const courseCosts = formConfig.courseHSFees ?? []
    const hs = service.getHighSeasonFees()
    if (hs) {
      for (const item of hs) {
        for (const fee of item.fees) {
          const alreadyIncluded = courseCosts.find((item) => item.id === fee.id)
          if (fee.mandatory && !alreadyIncluded) {
            courseCosts.push({
              id: fee.id,
              cost: undefined,
              frequency: undefined
            })
          }
        }
      }
      setFormConfig(prevFormConfig => ({
        ...prevFormConfig,
        courseHSFees: courseCosts,
        weeks: nWeeks
      }))
    } else {
      setFormConfig({
        ...formConfig,
        weeks: nWeeks
      })
    }
  }

  const [startCourse, setStartCourse] = useState(null)
  useEffect(() => {
    if (formConfig.courseStart !== startCourse) {
      const program = course?.offer?.program?.name
      const startDates = course?.offer?.start_dates
      if (startDates && (program === 'language' || program === 'summer_camps') && startDates?.length > 0) {
        form.setFieldsValue({
          startEvent: moment(startDates[0]?.date)
        })
        setStartCourse(startDates[0]?.date)
        onChange(startDates[0]?.date)
      }
    }
  }, [course, service])

  return (
    <Row gutter={12} className='w-100' style={{ marginTop: '30px' }}>
      <Col xs={8} md={10} className='antd-date' style={{ alignItems: 'baseline' }}>
        <Form.Item
          name='startEvent'
          rules={[{
            required: true,
            message: 'This field is required'
          }]}
          label='Start'
        >
          <DatePicker
            disabledDate={disabledDate}
            // onChange={onChange}
            onChange={(value, valueDate) => {
              const nDate = new Date(valueDate)
              const dateMoment = moment(nDate)
              console.log('🚀 ~ file: SelectStartAndDuration.js:121 ~ SelectStartAndDuration ~ dateMoment:', dateMoment)
              onChange(dateMoment)
            }}
            format='DD MMMM, YYYY'
          />
        </Form.Item>
        <div>
          {
            service.getEndString() &&
            <i>Ends: {service.getEndString()}</i>
          }
        </div>
      </Col>
      <Col xs={8} md={8} className='antd-date'>
        <Form.Item
          name='weeks'
          rules={[{
            required: true,
            message: 'This field is required'
          }]}
          label='Duration'
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder='1'
            // onChange={(nWeeks) => {
            //   setFormConfig({
            //     ...formConfig,
            //     weeks: nWeeks
            //   })
            // }}
            onChange={changeWeeks}
            min={service.getMinWeeks()}
            max={service.getMaxWeeks()}
            addonAfter='Weeks'
          />
        </Form.Item>
      </Col>
      <Col className='byp-centered byp-border-left'>
        {service.getIsHighSeason() && <b>High Season</b>}
      </Col>
      <hr className='byp-hr' />
    </Row>
  )
}

export { SelectStartAndDuration }
