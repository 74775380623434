import { Col, Divider, Form, Row, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../providers/auth/auth-context-provider'
import ProgramAverage from '../components/ProgramAverage'
import TopUsers from '../components/TopUsers'
import MostPopularPrograms from '../general/MostPopularPrograms'
import TimeFilters from '../TimeFilters'
import TotalQuotes from '../../reports/components/TotalQuotes'
import MostPopularCourses from '../components/MostPopularCourses'
import MostPopularCities from '../general/MostPopularCities'
import MostPopularDatesAndTimes from '../general/MostPopularDatesAndTimes'
import { SelectCampusByCountryAndCity } from '../../../../../common/components/helper-form/SelectCampusByCountryAndCity'
import { SelectSchools } from '../../../../../common/components/helper-form/SelectInstitutions'
import { useGetInstitutions } from '../../../../../bussines/hooks/agency/useGetInstitutions'

const SchoolAnaliticsInfo = () => {
  const { user: _user } = useAuth()
  const { data } = useGetInstitutions()

  const [time, setTime] = useState({})
  const [institution, setInstitution] = useState()
  const [isDeleted, setIsDeleted] = useState(false)
  const [campus, setCampus] = useState()
  const [country, setCountry] = useState()
  const [city, setCity] = useState()
  const [nameSede, setNameSede] = useState()

  useEffect(() => {
    if (institution) {
      const item = data.find(item => {
        return item.institution_id === institution
      })
      if (item?.institution?.deleted_at && item?.status === 'Deleted') {
        setIsDeleted(true)
      } else {
        setIsDeleted(false)
      }
    } else {
      setIsDeleted(false)
    }
  }, [institution])

  return (
    <>
      <Row className='byp-dashboard-content'>
        <Col span={24}>
          <Row>
            <Col span={20} className='d-flex align-center'>
              <h3 className='byp-title'>Institution analysis</h3>
              {nameSede && <span className='byp-title byp-text-lg' style={{ marginLeft: '0.5rem' }}>({nameSede})</span>}
            </Col>
          </Row>

          <Row gutter={16} className='byp-mb-1'>
            <Col className='two-thirds-responsive'>
              {_user?.role_names?.includes('super-admin') && (
                <Form initialValues={{ institution: null }}>
                  <Row>
                    <Col xs={18} md={8}>
                      <SelectSchools
                        name='institution'
                        // label='School'
                        showAll
                        className='user-analysis__select'
                        size='small'
                        onChange={(val) => {
                          console.log(val)
                          if (val) setInstitution(val)
                          else setInstitution()
                        }}
                      />
                    </Col>
                    <Col>
                      {isDeleted && (
                        <Tag
                          style={{ float: 'right', fontSize: '10px', marginLeft: '0.5rem' }}
                          className='byp-bg-red byp-color-white byp-fw-bold'
                        >
                          Institution Deleted
                        </Tag>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
              {!_user?.role_names?.includes('super-admin') && (
                <SelectCampusByCountryAndCity
                  className='user-analysis__select'
                  size='small'
                  showNameSede
                  setCampus={setCampus}
                  setCampusName={setNameSede}
                  setCountry={setCountry}
                  setCity={setCity}
                />
              )}
            </Col>

            <Col className='time-filter'>
              <TimeFilters setTime={setTime} />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className='two-thirds-responsive'>
              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Total quotes</h4>
              <TotalQuotes
                filters={{
                  ...time,
                  campus,
                  country,
                  city,
                  institution,
                  onlyInstitutions: true
                }}
                noClick
              />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most Popular Programs</h4>
              <MostPopularPrograms time={{ ...time, country, city, institution }} campus={campus} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>
                Most popular courses
              </h4>
              <MostPopularCourses time={{ ...time, campus, country, city }} institution={institution} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Most popular cities</h4>
              <MostPopularCities time={{ ...time, campus, country, city, institution }} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

              <h4 className='byp-title-4 byp-mb-1 byp-black byp-fw-bold'>Popular dates and times</h4>
              <MostPopularDatesAndTimes time={{ ...time, campus, country, city, institution }} />
              <Divider style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }} />

            </Col>

            <Col className='one-third-responsive'>
              <div className='user-average'>
                <h4 className='byp-title-4 byp-mb-1 byp-title byp-fw-bold'>Program Average</h4>
                <ProgramAverage time={{ ...time, country, city, institution }} campus={campus} firstOption />
              </div>
            </Col>

            <Col span={24}>
              <h4 className='byp-title-4 quote-overview--color-blue byp-mb-1'>Top users</h4>
              <TopUsers time={{ ...time, country, city, institution }} campus={campus} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export { SchoolAnaliticsInfo }
