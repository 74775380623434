import React, { useEffect } from 'react'
import { Row, Col, Space, Progress } from 'antd'
import {
  CreatedIcon,
  SendedIcon,
  VisitedIcon,
  ApplicationsIcon
} from '../../../../../quote-preview/assets/imgs/Icons'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'
import { useGetQuotesStatisticsByOffice } from '../../../../../../bussines/hooks/agency/analytics/office/useGetQuotesStatisticsByOffice'

const OfficeTotalQuotes = ({ time, office, setIsActiveTotalQuote, setActiveCategory, activeCategory }) => {
  const { data, isLoading, error } = useGetQuotesStatisticsByOffice(office, time)
  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: TotalQuotes.js:16 ~ useEffect ~ data', data)
    }
    if (!isLoading && error) {
      console.log('🚀 ~ file: TotalQuotes.js:16 ~ useEffect ~ error', error)
    }
  }, [data, isLoading, error])

  const activeTotal = (category) => {
    setActiveCategory(category)
    setIsActiveTotalQuote(true)
    if (activeCategory === category) {
      setActiveCategory('')
      setIsActiveTotalQuote(false)
    }
  }

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]} className='total-quote-user'>
      <Col
        className='total-quote user-analysis-total'
      >
        <div className={`quote-list-item-card ${activeCategory === 'created' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('created')}>
          <CreatedIcon style={{margin:'5px 0'}}/>
          <div className='d-flex align-center user-info byp-mb-1' style={{ justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.created?.count}</h5>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.created?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Created</p>
        </div>
      </Col>
      <Col
        className='total-quote user-analysis-total'
      >
        <div className={`quote-list-item-card ${activeCategory === 'sended' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('sended')}>
          <SendedIcon style={{margin:'5px 0'}}/>
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.sended?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.sended?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Sent by email</p>
        </div>
      </Col>
      <Col
        className='total-quote user-analysis-total'
      >
        <div className={`quote-list-item-card ${activeCategory === 'seen' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('seen')}>
          <VisitedIcon style={{margin:'5px 0'}}/>
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.visited?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.visited?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Seen <i className='byp-text-sm'>(mail and link)</i></p>
        </div>
      </Col>
      <Col
        className='total-quote user-analysis-total'
      >
        <div className={`quote-list-item-card ${activeCategory === 'notseen' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('notseen')}>
          <VisitedIcon style={{margin:'5px 0'}}/>
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.notseen?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.notseen?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Not Seen <i className='byp-text-sm'>(mail and link)</i></p>
        </div>
      </Col>
      <Col
        className='total-quote user-analysis-total'
      >
        <div className={`quote-list-item-card ${activeCategory === 'applications' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('applications')}>
          <ApplicationsIcon style={{margin:'5px 0'}}/>
          <div className='d-flex align-center user-info'>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.applications?.count}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.applications?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100'>Applications</p>
        </div>
      </Col>
    </Row>
  )
}

export default OfficeTotalQuotes
