import { Section1HeaderClientQuotes } from './Section1HeaderClientQuotes'
import { Section2HeaderClientQuotes } from './Section2HeaderClientQuotes'

function HeaderClientQuotes ({ agencys, studentId }) {
  return (
    <div class='header-client-quotes'>
      <Section1HeaderClientQuotes agencys={agencys} />
      <Section2HeaderClientQuotes studentId={studentId} />
    </div>
  )
}

export { HeaderClientQuotes }
