import { useQuery } from 'react-query'
import LanguageRepository from '../../repositories/LanguageRepository'

function useGetLanguages () {
  return useQuery(['useGetLanguages'], LanguageRepository.languages, {
    retry: 2
  })
}

function useGetLanguagesShort () {
  return useQuery(['useGetLanguages'], LanguageRepository.languagesShort, {
    retry: 2
  })
}

export { useGetLanguages, useGetLanguagesShort }
