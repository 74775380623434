import { Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetAllRegions } from '../../../bussines/hooks/places/useGetAllRegions'
const { Option } = Select

function SelectRegionsFormItem ({ setRegion, name, placeholder, limit = null, countries = null, global = false, globalLabel = 'All', form, allowClear = true, ...props }) {
  const { isLoading, data: regions } = useGetAllRegions()
  const [, setOptionsSelected] = useState([])

  useEffect(() => {
    setOptionsSelected(countries || [])
  }, [countries])

  const handleChange = value => {
    if (value.includes('global') && props?.mode === 'multiple') {
      const allRegions = regions?.map((item) => item.region)
      form?.setFieldsValue({ [name]: allRegions })
    }
    setOptionsSelected(value)
    if (setRegion) setRegion(value)
  }

  return (
    <>
      <Form.Item name={name} {...props} style={{ marginBottom: 0 }} >
        <Select
          {...props}
          loading={isLoading}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={placeholder ?? 'Select an option'}
          onChange={handleChange}
          allowClear={allowClear}
          maxTagCount='responsive'
        >
          {global && <Option key='global' value='global'> {globalLabel} </Option>}
          {regions?.map(item => (
            <Option
              key={item.region}
              value={item.region}
            >
              {`${item.region !== 'Americas' ? item.region : 'Americas'}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export { SelectRegionsFormItem }
