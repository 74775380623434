import { Button, Col, Collapse, Form, Modal, notification, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetFeeCosts } from '../../../../bussines/hooks/agency/insurances/useGetFeeCosts'
import { usePostFeePrices } from '../../../../bussines/hooks/agency/insurances/usePostFeePrices'
import stateControl from '../../../../bussines/utils/state-control'
import { SelectFeeByCategory } from '../../../../common/components/helper-form/SelectFeeByCategory'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { showErrorModal } from '../../../../utils/propsForModal'
import { FormAdditional } from '../components/FormAdditional'
import { FormConceptPack } from '../components/FormConceptPack'
const { Panel } = Collapse

let readOnly = true
const FormPricesServices = () => {
  const { user: _user } = useAuth()
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()
  const location = useLocation()
  const { feeId } = location.state ?? { feeId: undefined }

  const [insuranceId, setInsuranceId] = useState(feeId)
  const { data: insurance, isLoading, refetch } = useGetFeeCosts(insuranceId)
  const { savePrices, isLoading: isSavingPrices, currentState, error: errorSaving } = usePostFeePrices()

  const onFinish = (values, aprove = false) => {
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    values.aprove = aprove
    savePrices(insuranceId, values)
  }

  useEffect(() => {
    if (insurance && !isLoading) {
      if (insurance.costs?.length === 0) {
        insurance.costs = [{
          concept: null
        }]
      }
      // if (insurance.additions?.length === 0) {
      //   insurance.additions = [{
      //     name: null
      //   }]
      // }
      const costs = insurance.costs?.map((item) => ({
        ...item,
        name: item.concept
      }))
      const additions = insurance.additions?.map((item) => ({
        aditional_information: item.name
      }))
      form.setFieldsValue({
        costs,
        additions
      })
      console.log('🚀 ~ file: FormInsurancePrices.js:13 ~ useEffect ~ insurance', insurance)
    }
  }, [insurance, isLoading])

  useEffect(() => {
    if (!isSavingPrices && currentState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'Prices data saved successfully'
      })
      refetch()
    }
  }, [isSavingPrices, currentState])

  useEffect(() => {
    if (!isLoading && errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
  }, [errorSaving, modal, isLoading])

  useEffect(() => {
    const roles = _user?.role_names ?? []
    if (roles.includes('agency-admin') || roles.includes('agency-editor')) {
      readOnly = false
    }
  }, [_user])

  return (
    <Row className='byp-dashboard-content'>
      {contextHolder}
      <Col xs={24} md={24}>
        <h3 className='byp-title'>Service's prices</h3>
        <Form
          layout='horizontal'
          scrollToFirstError
          name='UserForm'
          form={form}
          initialValues={{
            service: insuranceId
          }}
          labelCol={{
            span: 6
          }}
          labelAlign='left'
          requiredMark={false}
          onFinish={onFinish}
        >
          <SelectFeeByCategory
            onChange={(id) => setInsuranceId(id)}
            label='Service name'
            size='small'
            type='service'
            name='service'
          />

          {insurance && (
            <Collapse defaultActiveKey={['1']}>
              <Panel header={insurance?.name} key={1}>
                <h6 className='w-100'> Currency <span className='byp-ml-1 byp-fw-bold'>{insurance?.currency?.code}</span> </h6>
                <h6 className='w-100 mt-3'> <span className='byp-fw-bold'>Prices </span> </h6>

                <FormConceptPack
                  name='costs'
                  divisa={insurance?.currency?.code}
                />

                <h6 className='w-100 mt-3'> <span className='byp-fw-bold'>Additional information </span> </h6>

                <FormAdditional
                  name='additions'
                  divisa={insurance?.currency?.code}
                />

                <Row className='w-100 byp-mb-1'>
                  <Col span={24}>
                    <Button
                      className='byp-ml-1 float-right byp-btn-blue-100'
                      loading={isSavingPrices}
                      onClick={() => onFinish(form.getFieldsValue(), true)}
                    >
                      Aprove
                    </Button>
                    <Button
                      className='float-right byp-btn-blue-200'
                      htmlType='sumbit'
                      loading={isSavingPrices}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          )}
        </Form>

      </Col>
    </Row>
  )
}

export { FormPricesServices }
