import React, { useEffect, useState } from 'react'
import { PublicThemeNone } from '../../../common/theme/PublicThemeNone'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Divider, Layout, Row, Avatar } from 'antd'
import { URL_QUOTER_PAGE } from '../../quoter'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'
import { QuoteOverview } from './QuoteOverview'
import { URL_ABOUT_TERMS_AND_CONDITIONS_PAGE } from '../../about/TermsAndConditionsPage'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import whatsapp from '../../../common/assets/images/whatsapp.png'
// import userPhoto from '../../../common/assets/images/Foto_Nash.png'
// import { useGetCountServices } from '../../../bussines/hooks/packages/useGetHomePackageOffers'
// import { URL_CONFIRMATION_QUOTE_PAGE } from '../../confirmation-quote'
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
// import avion from '../../../common/assets/images/avion.png'
// import { MdEmail } from 'react-icons/md'
// import { ImAirplane } from 'react-icons/im'

const data = []
const Quote = ({ quote, preview = false, quoteUuid }) => {
  const [text, setText] = useState()
  const [urlImage, setUrlImage] = useState()
  const [link, setLink] = useState()

  const navigate = useNavigate()
  // const { data } = useGetCountServices()
  const { user: _user } = useAuth()

  function addHttpsIfNotExist (url) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  const handleDownloadConfirm = () => {
    // navigate(URL_CONFIRMATION_QUOTE_PAGE + '?id=' + quoteUuid)
  }

  function obtenerNombreDiaSemana (fecha) {
    const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
    return diasSemana[fecha.getDay()]
  }

  function obtenerNombreMes (fecha) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return meses[fecha.getMonth()]
  }

  function convertirFecha (fechaStr) {
    const fecha = new Date(fechaStr)

    const nombreDiaSemana = obtenerNombreDiaSemana(fecha)
    const nombreMes = obtenerNombreMes(fecha)

    const dia = fecha.getDate()
    const año = fecha.getFullYear()

    const resultado = `${nombreDiaSemana} ${dia} de ${nombreMes}, ${año}`

    return resultado
  }

  useEffect(() => {
    if (_user && _user?.publicity && _user?.publicity?.length > 0) {
      if (_user?.publicity[0]?.title) setText(_user?.publicity[0]?.title)
      if (_user?.publicity[0]?.src) setUrlImage(_user?.publicity[0]?.src)
      if (_user?.publicity[0]?.link) setLink(addHttpsIfNotExist(_user?.publicity[0]?.link))
    }
  }, [_user])

  return (
    <PublicThemeNone>

      <Row style={{ marginLeft: '3.13rem', marginRight: '30px' }}>

        <Col span={24}>
          <button className='quote-apply__btn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px', marginBlockEnd: '10px' }} onClick={() => navigate(URL_QUOTER_PAGE)}>
            {/* <ArrowRightAltIcon style={{ transform: 'rotate(180deg)' }} /> */}
            Volver
          </button>
        </Col>
        <Col span={24}>
          <Row justify='space-between' gutter={[16, 16]}>
            <Col md={8} lg={11}>
              <Row>
                <Col span={24}>
                  <h2 className='quote-summary__titles'>Resumen de tu viaje</h2>
                  <p>Te compartimos el resumen de tu viaje de estudios en el extranjero.</p>
                </Col>
                <Divider />

                <Col span={24}>
                  <h4 className='quote-summary__subtitles byp-mt-1' style={{ marginBottom: '.75rem' }}>Duración del curso</h4>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <p className='quote-summary__info'><span>Inicio de Clases</span> {convertirFecha(quote?.course?.start)}</p>
                    </Col>
                    <Col span={12}>
                      <p className='quote-summary__info'><span>Fin de Clases</span> {convertirFecha(quote?.course?.end)}</p>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <h4 className='quote-summary__subtitles '>Tipo de Curso</h4>
                </Col>
                <Col span={12}>
                  <p className='quote-summary__info'> <span>Idiomas</span> {quote?.course?.language?.name}</p>
                </Col>

                {quote?.lodging && (
                  <>
                    <Col span={24}>
                      <h4 className='quote-summary__subtitles'>Tipo de Alojamiento</h4>
                      <h5 className='quote-summary__subtitle-2'>{quote?.lodging?.name}</h5>
                    </Col>
                    <Col span={24}>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <p className='quote-summary__info-reverse'><span>Llegada </span>{convertirFecha(quote?.lodging?.start)}</p>
                        </Col>
                        <Col span={12}>
                          <p className='quote-summary__info-reverse'><span>Salida </span>{convertirFecha(quote?.lodging?.end)}</p>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
                <Divider />

                <Col span={24}>
                  <Row gutter={[16, 16]}>
                    <Col className='byp-mt-1' span={12}>
                      <Button
                        onClick={handleDownloadConfirm}
                        className='confirmation__btn-quote br-05'
                        block
                      >Descargar Confirmación
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Link
                        to={URL_ABOUT_TERMS_AND_CONDITIONS_PAGE}
                        state={{}}
                        style={{ textAlign: 'center' }}
                      >
                        <span className='align-icon-txt justyfy-center'>Términos y condiciones</span>
                      </Link>
                    </Col>
                    <Col span={24} className='byp-mt-1'>
                      <h2 className='quote-summary__titles'>Tu asesor es</h2>
                      <Row gutter={[18, 18]} style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={5}>
                          {/* <Avatar src={userPhoto} style={{ width: '100%', height: 'auto' }} /> */}
                        </Col>
                        <Col span={12}>
                          <h5 style={{ fontWeight: '600' }}>Nashiely Uriza</h5>
                          <div className='quote-summary__info-adviser'>
                            <img src={whatsapp} style={{ width: '20px', height: '20px' }} alt='' />
                            <p>33 1092 2610</p>
                          </div>
                          <div className='quote-summary__info-adviser'>
                            {/* <MdEmail style={{ width: '20px', height: '20px' }} /><p>admin@bookyourprogram.com</p> */}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Col>

            <Col md={16} lg={12}>
              <ErrorBoundary>
                <Row gutter={[16, 16]} justify='center'>
                  <Col span={22}>
                    <QuoteOverview
                      course={quote.course} campus={quote.provider.campus} cost={quote.cost}
                      campusCosts={quote.campus_costs} intake={quote.intake}
                      expirationDate={quote?.expiration_date} returnDate={quote.return_date}
                      arrivalDate={quote.arrival_date} quoteId={quote.uuid} preview={preview}
                      agency={quote?.agency} lodging={quote.lodging} agent={quote.agent}
                      discounts={quote.discounts} logo={quote.provider.logo}
                    />
                  </Col>
                </Row>
              </ErrorBoundary>
            </Col>
          </Row>
        </Col>
        <Col span={24} className='byp-mt-1'>
          {/** BANNER PUBLICIDAD **/}
          <Layout style={{ padding: '0' }} className='layout-home'>
            <a href={link}>
              <div className='big-home__info' style={{ height: '152px' }}>
                {/* <img src={urlImage ?? avion} alt='Avion' /> */}
                <Row className='big-home__info-text'>
                  <Col sm={2} lg={1} className='byp-centered'>
                    {/* <ImAirplane className='big-home__imairplane' /> */}
                  </Col>
                  <Col>
                    <h4 style={{ color: '#FFF' }}>{text ?? 'Descubre el mundo, y aprende en el proceso'}</h4>
                  </Col>
                </Row>
                <Row className='big-home__info-text'>
                  <Col sm={2} lg={1} />
                  <Col>
                    <p style={{ color: '#FFF' }}>
                      {data?.toLocaleString()} diferentes cursos
                    </p>
                  </Col>
                </Row>
              </div>
            </a>
          </Layout>
        </Col>
      </Row>
    </PublicThemeNone>
  )
}

export default Quote
