import React, { useState, useEffect } from 'react'

const RandomImage = () => {
  const [randomIndex, setRandomIndex] = useState(0)
  const [usedIndices, setUsedIndices] = useState([])

  const images = [
    require('../common/assets/images/FotosCarrete/FotoCarrete_1.png'),
    require('../common/assets/images/FotosCarrete/FotoCarrete_2.png'),
    require('../common/assets/images/FotosCarrete/FotoCarrete_3.png'),
    require('../common/assets/images/FotosCarrete/FotoCarrete_4.png'),
    require('../common/assets/images/FotosCarrete/FotoCarrete_5.png'),
    require('../common/assets/images/FotosCarrete/FotoCarrete_6.png')
  ]

  useEffect(() => {
    generateRandomIndex()
  }, [])

  const generateRandomIndex = () => {
    let randomNum
    do {
      randomNum = Math.floor(Math.random() * images.length)
    } while (usedIndices.includes(randomNum))

    setUsedIndices([...usedIndices, randomNum])
    setRandomIndex(randomNum)
  }

  return (
    <div className='image-container' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={images[randomIndex]} alt='background' className='img-background' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
  )
}

export default RandomImage
