import { useQuery } from 'react-query'
import CourseRepository from '../../repositories/CourseRepository'

/**
 * useGetCoursesByFilter
 * @param {Object} filter
 * @returns {UseQueryResult}
 */
function useGetCoursesByFilter (filter) {
  const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetCoursesByFilter', queryString],
    () => CourseRepository.getCoursesByFilter(queryString), {
      retry: 1,
      enabled: !!filter.program
    })
}

function useGetCoursesByFilterAdvanceSearch (filter) {
  return useQuery(['useGetCoursesByFilterAdvanceSearch', filter],
    () => CourseRepository.getCoursesByFilterAdvanceSearch(filter), {
      retry: 2,
      enabled: !!filter.program
    })
}

/**
 * useGetCoursesLocationsByFilter
 * @param {Object} filter
 * @returns {UseQueryResult}
 */
function useGetCoursesLocationsByFilter (filter) {
  const queryString = Object.keys(filter).map((key) => key + '=' + filter[key]).join('&')
  return useQuery(['useGetCoursesLocationsByFilter', queryString],
    () => CourseRepository.getCoursesLocationsByFilter(queryString), {
      retry: 1,
      enabled: !!filter.program
    })
}

function useGetCoursesLocationsByFilterAdvanceSearch (filter) {
  return useQuery(['useGetCoursesLocationsByFilterAdvanceSearch', filter],
    () => CourseRepository.getCoursesLocationsByFilterAdvanceSearch(filter), {
      retry: 1,
      enabled: !!filter
    })
}

export { useGetCoursesByFilter, useGetCoursesLocationsByFilter, useGetCoursesByFilterAdvanceSearch, useGetCoursesLocationsByFilterAdvanceSearch }
