import { Form, Select } from 'antd'
const { Option } = Select
const SelectReligionFormItem = ({ name, label, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: 'This field is required' }]}
      {...rest}
    >
      <Select
        className='w-100'
        {...rest}
      >
        <Option key='Secular'>Secular</Option>
        <Option key='Catholic'>Catholic</Option>
        <Option key='Christian'>Christian</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectReligionFormItem }
