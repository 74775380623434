import { Row, Col, Divider } from 'antd'
import { useGetTopUsers } from '../../../../../../bussines/hooks/agency/analytics/general/useGetTopUsers'
import { useEffect, useState } from 'react'
import { SelectBranchesFormItem } from '../../../../../../common/components/helper-form/SelectBranchesFormItem'
import { BasicSpinner } from '../../../../../../common/components/BasicSpinner'

function UserCard ({ user }) {
  return (
    <div style={{ backgroundColor: 'white', borderRadius: '20px', padding: '10px 0', margin: '0 10px' }}>
      <Row>
        <Col span={8} className='d-flex justify-center' style={{ paddingLeft: '1rem' }}>
          <img src={user.profile_photo_url} alt='' style={{ maxWidth: '110px', height: '110px', borderRadius: '50%' }} />
        </Col>
        <Col span={16} style={{ paddingLeft: '1rem' }}>
          <Row style={{ color: 'var(--blue-100)', fontWeight: 'bold' }}>
            {user.name}
          </Row>
          <Row style={{ color: 'var(--blue-200)', fontWeight: 'bold', fontSize: '12px' }}>
            {user.company_name}, {user.sede}
          </Row>
          <Row style={{ padding: '10px 10px' }}>
            <Col span={11}>
              <Col style={{ fontWeight: 'bold', fontSize: '12px', color: '#8D8D8D' }}>
                Quotes created
              </Col>
              <Col style={{ color: 'var(--blue-200)', fontWeight: 'bold' }}>
                {user.quotes_count}
              </Col>
            </Col>
            <Col>
              <Divider type='vertical' style={{ padding: '25px 0', background: ' #8D8D8D' }} />
            </Col>
            <Col span={11}>
              <Col style={{ fontWeight: 'bold', fontSize: '12px', color: '#8D8D8D' }}>
                Quotes sended
              </Col>
              <Col style={{ color: 'var(--blue-200)', fontWeight: 'bold' }}>
                {user.quotes_send}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

function TopConsultanst ({ filters }) {
  const [office, setOffice] = useState(null)
  const { data, isLoading } = useGetTopUsers(10, office ? { ...filters, office } : { ...filters })

  useEffect(() => {
    console.log('🚀 ~ file: TopConsultanst.js:52 ~ useEffect ~ data:', data)
  }, [data, isLoading])

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '25px', padding: '20px 10px' }}>
      <Row>
        <h4 className='byp-title-4 byp-mb-1 byp-fw-bold' style={{ color: 'var(--blue-200' }}>
          Top 10 consultants
        </h4>
      </Row>
      <Row gutter={20} style={{ paddingBottom: '20px' }}>
        <Col md={24} lg={18}>
          <SelectBranchesFormItem
            filters={{ agency: filters?.agency }}
            className='user-analysis__select'
            size='small'
            label='Office'
            showOptionAll
            value={office}
            onChange={(val) => {
              if (val) {
                setOffice(val)
              } else {
                setOffice(null)
              }
            }}
          />
        </Col>
      </Row>
      {isLoading && <BasicSpinner />}
      {!isLoading && (
        <div style={{ backgroundColor: '#F7F7F7', borderRadius: '20px' }}>
          <div style={{ height: '15px' }} />
          <div style={{ rowGap: '10px', display: 'flex', flexDirection: 'column' }}>
            {data?.map((user) => (
              <UserCard key={user.id} user={user} />
            ))}
          </div>
          <div style={{ height: '15px' }} />
        </div>
      )}
    </div>
  )
}

export { TopConsultanst }
