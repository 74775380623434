import { useMutation } from 'react-query'
import state from '../../../utils/state-control'
import { useState } from 'react'
import DiscountRepository from '../../../repositories/DiscountRepository'

function useSaveDiscountPackageValues () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)

  const mutation = useMutation(DiscountRepository.savePackageValues, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  const savePackageValues = (discountId, data) => {
    mutation.mutate({ discountId, data })
  }

  return { ...mutation, state, currentState, savePackageValues }
}

export { useSaveDiscountPackageValues }
