import { Link } from 'react-router-dom'
import { RiseOutlined } from '@ant-design/icons'
import { DesktopIcon } from '../icons/DesktopIcon'
import { SchoolProfileIcon } from '../icons/SchoolProfileIcon'
import { getItem } from './components/get-menu-item'
import { IconAnalyticsSharp } from '../../assets/svg/icons'
import { URL_GENERAL_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/general/GeneralAnalysisPage'
import { URL_USER_ANALYSIS_PAGE } from '../../../pages/dashboards/agency/analitics/user/UserAnalysisPage'
import { URL_EDIT_GROUP_EDUCATION } from '../../../pages/group-education/EditGroupEducationProfilePage'
import { URL_CREATE_USERS_PAGE } from '../../../pages/dashboards/users/CrudUserPage'
import { UsersIcon } from '../icons/UsersIcon'
import { URL_USER_AGENCIES_PAGE } from '../../../pages/dashboards/Institution/analitics/AgenciesAnaliticsPage'
import { URL_SCHOOL_ANALITCIS_GROUP_EDUCATIVE_PAGE } from '../../../pages/dashboards/agency/analitics/school-educative-group/school-educative-group-page'

function GroupEducationMenuItems (programs) {
  return [
    getItem(
      <Link to='/dashboard' state={{ menuItemSelected: 1 }}>Desktop</Link>,
      '1',
      <DesktopIcon />
    ),
    getItem(
      <Link
        to={URL_EDIT_GROUP_EDUCATION}
        state={{ menuItemSelected: 'ge-profile' }}
      >Group Education Profile
      </Link>,
      'ge-profile',
      <SchoolProfileIcon />
    ),
    getItem(
      'Analytics',
      'analitic',
      <IconAnalyticsSharp />,
      [
        getItem(
          <Link
            to={URL_GENERAL_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'general-analysis', openSub: 'analitic' }}
          >General analysis
          </Link>,
          'general-analysis'
        ),
        getItem(
          <Link
            to={URL_SCHOOL_ANALITCIS_GROUP_EDUCATIVE_PAGE}
            state={{ menuItemSelected: 'school-analysis', openSub: 'analitic' }}
          >School analysis
          </Link>,
          'school-analysis'
        ),
        // getItem(
        //   <Link
        //     to={URL_PROGRAM_ANALITCIS_PAGE}
        //     state={{ menuItemSelected: 'program-analysis', openSub: 'analitic' }}
        //   >Program analysis
        //   </Link>,
        //   'program-analysis'
        // ),
        getItem(
          <Link
            to={URL_USER_AGENCIES_PAGE + '/agencies'}
            state={{ menuItemSelected: 'agencies-analysis', openSub: 'analitic' }}
          >Agencies' analysis
          </Link>,
          'agencies-analysis'
        ),
        getItem(
          <Link
            to={URL_USER_ANALYSIS_PAGE}
            state={{ menuItemSelected: 'user-analysis', openSub: 'analitic' }}
          >User analysis
          </Link>,
          'user-analysis'
        )
      ]
    ),
    // getItem(
    //   <Link to={URL_REPORTS_UNI_PAGE} state={{ menuItemSelected: 'user-reports' }}>Reports</Link>,
    //   'user-reports',
    //   <RiseOutlined color='var(--blue-200)' />
    // )
    getItem(
      <Link to={URL_CREATE_USERS_PAGE} state={{ menuItemSelected: 'users' }}>Users</Link>,
      'users', <UsersIcon />
    )
  ]
}

export default GroupEducationMenuItems
