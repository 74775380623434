import React, { useEffect } from 'react'
import { Row, Col, Progress, Space } from 'antd'
import {
  CreatedIcon,
  SendedIcon,
  VisitedIcon,
  ApplicationsIcon
} from '../../../../quote-preview/assets/imgs/Icons'
import { useGetTotalQuotes } from '../../../../../bussines/hooks/agency/analytics/users/useGetQuotesInfo'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

const TotalQuotes = ({ setIsActiveTotalQuote, setActiveCategory, activeCategory, setOrderby, filters, setIsLoading, noClick }) => {
  const { data, isLoading, error } = useGetTotalQuotes(filters)
  useEffect(() => {
    if (setIsLoading) setIsLoading(isLoading)
    if (!isLoading && data) {
      // console.log('🚀 ~ file: TotalQuotes.js:16 ~ useEffect ~ data', data)
    }
  }, [data, isLoading, error])

  const activeTotal = (category) => {
    setActiveCategory(category)
    setIsActiveTotalQuote(true)
    if (activeCategory === category) {
      setActiveCategory('')
      setIsActiveTotalQuote(false)
    }
    if (setOrderby) {
      setOrderby()
    }
  }

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={[8, 8]} className='total-quote-user'>
      <Col
        className={noClick ? 'total-quote' : 'total-quote user-analysis-total'}
      >
        <div className={`quote-list-item-card quote-list-card ${activeCategory === 'created' ? 'active' : ''}`}  onClick={() => activeTotal('created')}>
          <CreatedIcon style={{margin:'5px 0'}}/>
          <div style={{ display:'flex',justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.created?.count ?? 0}</h5>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.created?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
            <p className='margin-0 byp-text-md byp-color-gray-100' 
          style={{ height: '35px', display: 'flex',alignItems: 'center' }}>Created</p>
        </div>
      </Col>
      <Col
        className={noClick ? 'total-quote' : 'total-quote user-analysis-total'}
      >
        <div className={`quote-list-item-card quote-list-card ${activeCategory === 'sended' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('sended')}>
          <SendedIcon style={{ color: '#686868',margin:'5px 0'}} />
          <div style={{ display:'flex',justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.sended?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.sended?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
            
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100' 
          style={{ height: '35px',display: 'flex',alignItems: 'center' }}>Sent by email </p>
        </div>
      </Col>
      <Col
        className={noClick ? 'total-quote' : 'total-quote user-analysis-total'}
      >
        <div className={`quote-list-item-card quote-list-card ${activeCategory === 'seen' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('seen')}>
          <VisitedIcon style={{margin:'5px 0'}}/>
          <div style={{ display:'flex',justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.visited?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.visited?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <Col style={{ lineHeight: '15px', height: '35px' }}>
            <p className='margin-0 byp-text-md byp-color-gray-100'>Seen </p>
            <i className='byp-text-sm'>(mail and link)</i>
          </Col>
        </div>
      </Col>
      <Col
        className={noClick ? 'total-quote' : 'total-quote user-analysis-total'}
      >
        <div className={`quote-list-item-card quote-list-card ${activeCategory === 'notseen' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('notseen')}>
          <VisitedIcon style={{margin:'5px 0'}}/>
          <div style={{ display:'flex',justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.notseen?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.notseen?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <Col style={{ lineHeight: '15px', height: '35px' }}>
            <p className='margin-0 byp-text-md byp-color-gray-100' >Not Seen</p>
            <i className='byp-text-sm'>(mail and link)</i>
          </Col>
        </div>
      </Col>
      <Col
        className={noClick ? 'total-quote' : 'total-quote user-analysis-total'}
      >
        <div className={`quote-list-item-card quote-list-card ${activeCategory === 'applications' ? 'active' : ''}`} style={{ padding: '0.5rem 1.5rem', height: '100%' }} onClick={() => activeTotal('applications')}>
          <ApplicationsIcon style={{margin:'5px 0'}}/>
          <div style={{ display:'flex',justifyContent: 'space-around' }}>
            <h5 className='byp-title-1 margin-0 quote-overview--color-blue'>{data?.applications?.count ?? 0}</h5>
            <div>
              <Space wrap>
                <Progress trailColor='white' type='circle' width={45} strokeWidth={8} strokeColor='#6495ed' percent={data?.applications?.percentage} format={(percent) => percent === 100 ? '100%' : percent + '%'} />
              </Space>
            </div>
          </div>
          <p className='margin-0 byp-text-md byp-color-gray-100' 
          style={{ height: '35px',display: 'flex',alignItems: 'center' }}>Applications</p>
        </div>
      </Col>
    </Row>
  )
}

export default TotalQuotes
