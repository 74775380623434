import { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, InputNumber, Select, Modal, Collapse, notification } from 'antd'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { usePostCourses } from '../../../../bussines/hooks/school/usePostCourses'
import utilsState from '../../../../bussines/utils/state-control'
import { TableCourses } from '../Tables/TableCourses'
import TextArea from 'antd/lib/input/TextArea'
import { SelectLanguageFormItem } from '../../../../common/components/helper-form/SelectLanguageFormItem'
import { useFindCourse } from '../../../../bussines/hooks/school/useFindCourse'
import { usePostUpdateCourse, usePostUpdateStatusCourse } from '../../../../bussines/hooks/school/course/usePostUpdateCourse'
import { useGetCourses } from '../../../../bussines/hooks/school/useGetCourses'
import { SelectWeekDay } from '../../../../common/components/helper-form/SelectWeekDay'
import { useDeleteService } from '../../../../bussines/hooks/school/useDeleteService'
import { useNavigate } from 'react-router-dom'
import { URL_COURSEPRICES_CREATE_PAGE } from '../CoursePrices/CreateCoursePricesPage'
import { showErrorModal } from '../../../../utils/propsForModal'
import { DatesFormItem } from '../../../../common/components/helper-form/DatesFormItem'
import { BasicRadioGroupFormItem } from '../../../../common/components/helper-form/BasicRadioGroupFormItem'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { programTypes } from '../../../../utils/programTypes'
import { useGetMyServiceCategories } from '../../../../bussines/hooks/school/useGetMyServiceCategories'
import { SelectStudyAreasFormItem } from '../../../../common/components/helper-form/SelectStudyAreasFormItem'
import { SelectCourseTypeFormItem } from '../../../../common/components/helper-form/SelectCourseTypeFormItem'
import { startOfMonth } from 'date-fns'
import moment from 'moment'
import { CourseLengthFormItem } from '../../../../common/components/helper-form/CourseLengthFormItem'
import { IntakeDatesListFormItem } from '../../../../common/components/helper-form/IntakeDatesListFormItem'
import { URL_COURSE_CREATE_PAGE } from './CreateCoursePage'
import { formatStringDate } from '../../../../utils/formatStringDate'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'
import { programIntakes } from '../../../../languageConfiguration/infoCourseTranslate'
import { stringDateToMoment } from '../../../../utils/formatMoment'

const { Panel } = Collapse

const types = programTypes

const rules = [
  { required: true, message: 'This field is required' }
]

const { Option } = Select
function FormCreateCourses ({ program, type }) {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [showLang, setShowLang] = useState(false)
  const [page, setPage] = useState(1)
  const [courseID, setCourseID] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [programName, setProgramName] = useState(program)
  const [changeType, setChangeType] = useState(type)
  const [searchFilter, setSearchFilter] = useState({})
  const [subTypes, setSubtypes] = useState()
  const [language, setLanguage] = useState(null)

  const { saveCourses, isLoading: isSaving, currentState, error: errorSaving } = usePostCourses()
  const { updateCourse, isLoading: isUpdating, currentState: updateState, error: errorUpdating } = usePostUpdateCourse()
  const { updateCourse: updateStatusCourse, isLoading: isUpdatingStatus, currentState: updateStateStatus, error: errorUpdatingStatus } = usePostUpdateStatusCourse()
  const { deleteService, isLoading: isDeleting, currentState: deleteState } = useDeleteService()
  const { data: course, isLoading: isLoadingCourse } = useFindCourse(courseID)
  const { isLoading, data: courses, refetch } = useGetCourses(program && { program, type: changeType ?? null, page, ...searchFilter })
  const [modal, contextHolder] = Modal.useModal()
  const specificDays = Form.useWatch('specific_days', form)
  const { data } = useGetMyServiceCategories()

  const onFinish = (values) => {
    if (values.intake_date) {
      const nIntakeDates = values.intake_date?.map(
        (intake) => {
          const nDate = startOfMonth(new Date(intake.intake_date))
          return formatStringDate(nDate, 'LLLL/dd/yyyy')
        }
      )
      values.intake_date = nIntakeDates
    }
    if (values.start_day === 'none' && !values.especific_start_dates?.length > 0) {
      return notification.warning({
        message: 'The specific dates are required when the start day is None'
      })
    }
    if (values.especific_start_dates?.length > 0) {
      values.especific_start_dates = values.especific_start_dates.map(d => {
        const fDate = formatStringDate(d.date, 'yyyy-LL-dd')
        return { ...d, date: fDate }
      })
    }
    if (!isUpdateMode) {
      saveCourses(values)
    } else {
      updateCourse(courseID, values)
    }
  }

  const onContinue = () => {
    navigate(URL_COURSEPRICES_CREATE_PAGE.replace(':program', program), { state: { menuItemSelected: program + '_courses_prices', openSub: program, openSub2: program + '_courses' } })
  }

  const getProgram = () => {
    if (data && program && form) {
      const found = data?.find((item) => item.name === program)
      form.setFieldsValue({ category: found.id })
    }
  }

  const handleOnChange = (val) => {
    if (!val) {
      setChangeType(null)
      setSubtypes(undefined)
      navigate(
        URL_COURSE_CREATE_PAGE.replace(':program', program),
        {
          state: {
            openSub: program,
            openSub2: program + '_courses'
          }
        }
      )
    } else {
      setChangeType(val)
      const found = types.find((item) => (item.value === val))
      setSubtypes(found?.subtypes)
      navigate(
        URL_COURSE_CREATE_PAGE.replace(':program', program) + '?type=' + val,
        {
          state: {
            menuItemSelected: program + '_courses_create' + val,
            openSub: program,
            openSub2: program + '_courses',
            openSub3: program + '_courses_submenu_' + val
          }
        }
      )
    }
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const translations = course?.translations ?? []
      const langTranslations = translations.filter(translation => translation.to === lang)
      // Set the new values
      const nameTraslated = langTranslations.find(translation => translation.attribute === 'label')
      const descTraslated = langTranslations.find(translation => translation.attribute === 'description')
      form.setFieldsValue({
        name: nameTraslated?.translation,
        description: descTraslated?.translation
      })
      setLanguage(lang)
    } else {
      form.setFieldsValue({
        name: course?.name,
        description: course?.description
      })
      setLanguage(null)
    }
  }

  useEffect(() => {
    if (form) form.setFieldsValue({ type })
    handleOnChange(type)
  }, [type])

  useEffect(() => {
    if (errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
    if (errorUpdating?.response.data) {
      showErrorModal(modal, errorUpdating.response.data.message)
    }
    if (errorUpdatingStatus?.response.data) {
      showErrorModal(modal, errorUpdatingStatus.response.data.message)
    }
  }, [errorSaving, errorUpdating, errorUpdatingStatus, modal])

  useEffect(() => {
    if (!isSaving && currentState === utilsState.STATE_SUCCESS) {
      form.resetFields()
      refetch()
      getProgram()
      notification.success({
        message: 'Course saved'
      })
    }
  }, [isSaving])

  useEffect(() => {
    if (!isLoadingCourse && course?.id) {
      const values = { ...course }
      if (course.intake_date && Array.isArray(course.intake_date)) {
        const intakes = course.intake_date?.map(
          (intake) => ({ intake_date: moment(intake) })
        )
        values.intake_date = intakes
      } else {
        values.intake_date = [{}]
      }
      values.translation = null
      values.especific_start_dates = course.especific_start_dates?.map(d => {
        return {
          id: d.id,
          date: stringDateToMoment(d.date)
        }
      })
      form.setFieldsValue(values)
      setIsUpdateMode(true)
      if (course?.type) handleOnChange(course?.type)
      if (course.category === 1) {
        setShowLang(true)
      } else {
        setShowLang(false)
      }
    }
  }, [isLoadingCourse, course])

  useEffect(() => {
    if (!isUpdating && updateState === utilsState.STATE_SUCCESS) {
      // console.log('UPDATED! ')
      form.resetFields()
      notification.success({
        message: 'Course updated'
      })
      setIsUpdateMode(false)
      setCourseID()
      refetch()
      getProgram()
    }
  }, [isUpdating])

  useEffect(() => {
    if (!isUpdatingStatus && updateStateStatus === utilsState.STATE_SUCCESS) {
      setIsUpdateMode(false)
      setCourseID()
      refetch()
      getProgram()
    }
  }, [isUpdatingStatus])

  useEffect(() => {
    if (!isDeleting && deleteState === utilsState.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Course deleted'
      })
    }
  }, [isDeleting])

  const onUpdate = ({ category }) => {
    if (category !== undefined) {
      if (category === 1) {
        setShowLang(true)
      } else {
        setShowLang(false)
      }
    }
  }

  function da () {
    if (programName === 'graduate') {
      return (
        <div>
          <Form.Item
            name='type'
            className='margin-0'
          >
            <Select
              className='graduate_show_type'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch
              size='small'
              onChange={handleOnChange}
              placeholder='All'
            >
              <Option value={null}>All</Option>
              {types?.map((item) => <Option key={item.key} value={item.value}>{item.label}</Option>)}
            </Select>
          </Form.Item>

        </div>
      )
    }
  }

  return (
    <>
      {contextHolder}
      <Form
        layout='horizontal'
        scrollToFirstError
        name='FormCreateCourses'
        onFinish={onFinish}
        form={form}
        initialValues={{
          min_capacity: 1,
          max_capacity: 1,
          hourly: 'Matutino',
          start_day: 'Monday',
          lessons_frequency: 'weeks',
          modality: programName !== 'graduate' && 'Presential',
          type,
          language: (programName === 'language' || programName === 'summer_camps') ? 40 : [40],
          time: 'months',
          intake_date: [{}]
        }}
        onValuesChange={onUpdate}
        requiredMark={false}
        labelCol={{
          span: 8
        }}
        labelAlign='left'
        labelWrap
      // wrapperCol={{
      //   span: 16
      // }}
      // onFinishFailed={onFinishFailed}
      >
        <div className='byp-dashboard-content'>
          <Row gutter={10}>
            <Col span={12}>
              <Row gutter={10}>
                <Col span={6}>
                  <h5 className='byp-title'>
                    Create your courses
                  </h5>
                </Col>
                <Col span={programName !== 'graduate' ? 2 : 2}>
                  <></>
                </Col>
                <Col span={16}>
                  <Row gutter={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%' }}>
                    <Col span={programName !== 'graduate' ? 4 : 4} className='byp-text-sm byp-fw-bold'>
                      <span>You're at:</span>
                    </Col>
                    <Col span={programName !== 'graduate' ? 6 : 8}>
                      <SelectSchoolProgramsFormItem
                        name='category'
                        rules={rules}
                        setProgramName={setProgramName}
                        program={program}
                        form={form}
                        className='graduate_show_category'
                        disabled
                      />
                    </Col>
                    <Col span={programName !== 'graduate' ? 0 : 7}>
                      {da()}
                    </Col>
                    {/* Select Translation component */}
                    <Col span={programName !== 'graduate' ? 6 : 5}>
                      {/* Only displayed when a course is being edited */}
                      {courseID && (
                        <SelectAvailableTranslationsFormItem
                          name='translation'
                          formItemProps={{
                            noStyle: true
                          }}
                          selectProps={{
                            size: 'small',
                            className: 'w-100',
                            onChange: (lang) => handleChangeTranslateLanguage(lang),
                            allowClear: true
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={8} className='byp-mt-1'>
                {
                  (language === null && (showLang || programName === 'language' || programName === 'summer_camps')) &&
                    <Col xs={24} md={24}>
                      <SelectLanguageFormItem label='language' name='language' showSearch rules={rules} />
                    </Col>
                }
                {
                  (language === null && subTypes) && (
                    <Col xs={24} md={22}>
                      <Form.Item
                        name='sub_type'
                        label='Program sub-type'
                      >
                        <Select
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          showSearch
                          size='small'
                        >
                          {subTypes?.map((item) => <Option key={item.key} value={item.value}>{item.label}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  )
                }
              </Row>
              <Row gutter={8} className='byp-mt-1'>
                <h6 className='byp-title byp-fw-bold'>Name and description</h6>
                <Col span={24}>
                  <Form.Item
                    name='name'
                    rules={rules}
                    label='Course name'
                  >
                    <Input
                      placeholder='Name'
                      onChange={(val) => {
                        // Do something with the val.target.value && some
                        // const lang = form.getFieldValue('translation') ?? 'eng'
                        // const translation = translations.filter(translation => translation.lang === lang)
                        // if (translation) {
                        //   const nValues = translations.map(translation => {
                        //     if (translation.lang === lang) {
                        //       translation.text = val.target.value
                        //     }
                        //     return translation
                        //   })
                        //   setTranslations(nValues)
                        // } else {
                        //   const nValues = [...translations, {
                        //     lang,
                        //     text: val.target.value
                        //   }]
                        //   console.log('🚀 ~ FormCreateCourses ~ nValues:', nValues)
                        //   setTranslations()
                        // }
                      }}
                    />
                  </Form.Item>
                </Col>
                {
                  language === null && (program === 'academic_years' || program === 'boarding_schools') && (
                    <Col span={24}>
                      <SelectCourseTypeFormItem name='course_type' label='Course type' />
                    </Col>
                  )
                }
                <Col span={24}>
                  <Form.Item name='description' label='Description'>
                    <TextArea placeholder='Course description' showCount maxLength={600} />
                  </Form.Item>
                </Col>
              </Row>

              {// Number of students
                (language === null && (programName === 'language' || programName === 'summer_camps')) && (
                  <Row>
                    <Col xs={12} md={12} lg={12}>Minimum number of students per class: </Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='min_capacity'
                        rules={rules}
                      >
                        <Input type='number' min={1} />
                      </Form.Item>
                    </Col>

                    <Col xs={12} md={12} lg={12}>Maximum number of students per class: </Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='max_capacity'
                      >
                        <Input type='number' min={1} />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }

              <Row>
                {// Class schedule & start day
                  (language === null && (programName === 'language' || programName === 'summer_camps')) && (
                    <>
                      <Col xs={24} md={12} lg={12}>Class schedule: </Col>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item name='hourly'>
                          <Select className='byp-mb-1 w-100' defaultValue='Matutino'>
                            <Option key='m' value='Matutino'>Morning</Option>
                            <Option key='v' value='Vespertino'>Evening</Option>
                            <Option key='mv' value='Matutino y Vespertino'>Morning and Evening</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} lg={12}>Course start day: </Col>
                      <Col xs={24} md={12} lg={12}>
                        <SelectWeekDay
                          className='w-100'
                          name='start_day'
                          onChange={(value) => {
                            if (value === 'none') {
                              form.setFieldsValue({
                                specific_days: true
                              })
                            }
                          }}
                        />
                      </Col>
                    </>
                  )
                }
                {// Course lang
                  (language === null && (programName === 'graduate' || programName === 'academic_years' || programName === 'boarding_schools')) && (
                    <>
                      {/* <Col xs={12} md={12} lg={12}>Course language </Col> */}
                      <Col span={24}>
                        <SelectLanguageFormItem
                          mode={programName === 'graduate' && 'multiple'}
                          name='language'
                          showSearch rules={rules}
                          label='Course language'
                        />
                      </Col>
                    </>
                  )
                }
                {(language === null && programName !== 'graduate') && ( // Minimum age to
                  <Row>
                    <Col xs={12} md={12} lg={12}>Minimum age to aply: </Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='min_age'
                      >
                        <Input type='number' min={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                {(language === null && (programName === 'graduate' || programName === 'academic_years' || programName === 'boarding_schools')) && (
                  <CourseLengthFormItem />
                )}

                {(language === null && (programName === 'academic_years' || programName === 'boarding_schools')) && (
                  <>
                    <Col span={24}>
                      <h6 className='byp-title'><b>Program intake</b></h6>
                    </Col>
                    <Col span={24}>
                      <IntakeDatesListFormItem name='intake_date' form={form} />
                    </Col>
                  </>
                )}
                {// Study method, area, Intakes, Season
                  (language === null && (programName === 'graduate')) && (
                    <>
                      <Col xs={12} md={12} lg={12}>Study method </Col>
                      <Col xs={12} md={12} lg={12}>
                        <Form.Item
                          name='study_method'
                        >
                          <Select>
                            <Option value='full time'>Full time</Option>
                            <Option value='part time'>Part time</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={12} lg={12}>Study area </Col>
                      <Col xs={12} md={12} lg={12}>
                        <SelectStudyAreasFormItem name='study_area' mode='multiple' />
                      </Col>
                      <Col span={24}><h6 className='byp-title byp-fw-bold'>Program Intakes</h6></Col>
                      <Col xs={12} md={12} lg={12}>Season: </Col>
                      <Col xs={12} md={12} lg={12}>
                        <Form.Item
                          name='season'
                        >
                          <Select
                            mode='multiple'
                          >
                            <Option value='Spring'>{programIntakes[language]?.Spring ?? 'Spring'}</Option>
                            <Option value='Summer'>{programIntakes[language]?.Summer ?? 'Summer'}</Option>
                            <Option value='Fall'>{programIntakes[language]?.Fall ?? 'Fall'}</Option>
                            <Option value='Winter'>{programIntakes[language]?.Winter ?? 'Winter'}</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </>
                  )
                }
                {language === null && (
                  <BasicRadioGroupFormItem
                    name='specific_days'
                    label='Do you have specific dates for the start of the course?'
                  />
                )}
                {specificDays && (
                  <Collapse defaultActiveKey={['1']} className='w-100'>
                    <Panel header='Specific start dates' key='1'>
                      <DatesFormItem name='especific_start_dates' />
                    </Panel>
                  </Collapse>
                )}
              </Row>

              {(language === null) && (
                <>
                  <Row>
                    <h6 className='byp-title byp-fw-bold'>Campus where the course is offered</h6>
                    <Col span={24}>
                      <SelectCampusByProgramFormItem
                        program={program}
                        mode='multiple'
                        name='campus' rules={rules}
                        maxTagCount='responsive'
                        placeholder='Campus where the course is offered'
                        form={form}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {// number of lessons
                (language === null && (programName === 'language' || programName === 'summer_camps')) && (
                  <>
                    <Row>
                      <h6 className='byp-title w-100 byp-fw-bold'>Lessons</h6>
                      <Col span={24}>
                        <Row gutter={[16, 16]}>
                          <Col span={7}>Number of lessons</Col>
                          <Col span={4}>
                            <Form.Item name='lessons'>
                              <InputNumber min={1} />
                            </Form.Item>
                          </Col>
                          <Col span={3} style={{ textAlign: 'center' }}>Per</Col>
                          <Col span={10}>
                            <Form.Item name='lessons_frequency'>
                              <Select>
                                <Option key={1} value='weeks'>Weeks</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} lg={24}>
                        <Form.Item name='lessons_duration' label='Lesson duration'>
                          <InputNumber addonAfter='minutes' min={0} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} md={24}>
                        <Form.Item
                          name='modality'
                          label='Modality of the course'
                        >
                          <Select>
                            <Option key={1} value='Presential'>Presential</Option>
                            <Option key={2} value='Online'>Online</Option>
                            <Option key={3} value='Hybrid'>Hybrid</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                (language === null && programName === 'graduate') && (
                  <Row>
                    <h6 className='byp-title w-100 byp-fw-bold'>Extras</h6>
                    <Col xs={12} md={12} lg={12}>Course code</Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='example'
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              {
                (language === null && (programName === 'graduate' || programName === 'academic_years' || programName === 'boarding_schools')) && (
                  <Row>
                    <Col span={24}><h6 className='byp-title'><b>Lessons</b></h6></Col>
                    <Col xs={12} md={12} lg={12}>Course link</Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='link'
                      >
                        <Input addonBefore='https://' />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={12} lg={12}>Modality of the course</Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='modality'
                      >
                        <Select
                          placeholder='Choose the program'
                        >
                          <Option value='Presential'>Presential</Option>
                          <Option value='Online'>Online</Option>
                          <Option value='Hybrid'>Hybrid</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              {
                (language === null && programName === 'graduate') && (
                  <Row>
                    <Col xs={12} md={12} lg={12}>Minimum age to apply</Col>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Item
                        name='min_age'
                      >
                        <InputNumber min={1} style={{ width: '100%' }} placeholder={17} />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col span={24}>
                  <Button
                    className='byp-btn-blue-200 float-right float-right byp-mt-1'
                    htmlType='submit'
                    loading={isSaving || isLoadingCourse || isUpdating}
                  >
                    {isUpdateMode ? 'Update' : 'Save course'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <TableCourses
                isLoading={isLoading}
                isLoadingCourse={isLoadingCourse}
                isDeleting={isDeleting}
                data={courses}
                setCourseID={setCourseID}
                refetch={refetch}
                deleteService={deleteService}
                program={program}
                type={changeType}
                setPage={setPage}
                setSearchFilter={setSearchFilter}
                searchFilter={searchFilter}
                updateStatusCourse={updateStatusCourse}
                isUpdatingStatus={isUpdatingStatus}
              />
            </Col>
          </Row>
        </div>

        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              <Button
                className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
                loading={isSaving || isUpdating}
                onClick={onContinue}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  )
}

export { FormCreateCourses }
