import { useMutation } from 'react-query'
import PackagesRepository from '../../repositories/PackagesRepository'
import { useState } from 'react'
import stateControl from '../../utils/state-control'

function useSavePackagePrices () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(PackagesRepository.savePrices, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const savePrices = (id, data) => {
    mutation.mutate({ id, data })
  }

  return { ...mutation, stateControl, currentState, savePrices }
}

export { useSavePackagePrices }
