import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
// import { TopBarMenuCustom } from '../../agency/components/TopBarMenuCustom'
import { FormShortTermPackages } from './FormShortTermPackages'

const URL_SHORT_TERM_PRICES_PAGE = '/dashboard/short_term_course/prices'

function ShortTermPricesPage (props) {
  return (
    <PageProtected
      permissionTypes={[
        userRoles.TYPE_INSTITUTION
      ]}
    >
      <MainTheme
        showMenu
      >
        <FormShortTermPackages />
      </MainTheme>
    </PageProtected>
  )
}

export { ShortTermPricesPage, URL_SHORT_TERM_PRICES_PAGE }
