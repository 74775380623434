import { Col, Collapse, Row, Form } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { AgencyCostSections } from './AgencyCostSections'
import { CampusPricesSection } from './CampusPricesSection'
import { ClientInfoSection } from './ClientInfo'
import FieldCourseCosts from './FieldCourseCosts'
import { FieldCourseGoods } from './FieldCourseGoods'
import { FieldPayTuition } from './FieldPayTuition'
import { FieldSchoolMaterials } from './FieldSchoolMaterials'
import { LodgingSection } from './LodgingSection'
import { OtherSection } from './OtherSection'
import { ProgramIntake } from './ProgramIntake'
import { TransportSection } from './TransportSection'
import { MedicalSection } from './MedicalSection'
import { ServiceSection } from './ServiceSection'
import moment from 'moment'
import { useEffect, useState } from 'react'
const { Panel } = Collapse

function QuoterHighEducationForm () {
  const {
    service, course, form, setFormConfig, formConfig,
    isLoadingBaseQuote, baseQuote
  } = useQuoter()
  const [, setYear] = useState()

  const agencyServices = Form.useWatch('services_agency', form)

  useEffect(() => {
    if (!isLoadingBaseQuote && baseQuote?.id) {
      form.setFieldsValue({
        intake_date: baseQuote.intake,
        intake: baseQuote.intake,
        transport: baseQuote.transport,
        transport_way: baseQuote.transport_ways,
        health_insurance: baseQuote.insurance,
        lodging: baseQuote.lodging,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        extra_nigth: baseQuote.extra_nigth,
        expiration: moment(baseQuote.expiration) ?? null,
        student_nationality: baseQuote.student_nationality,
        student_location: baseQuote.student_location,
        materials: baseQuote.materials,
        material: baseQuote.material,
        services_agency: baseQuote.additional_services.map((item) => ({ service_agency: item.id })),
        medical: baseQuote.agency_insurance.length > 0 ? baseQuote.agency_insurance[0].id : null,

        client_name: baseQuote.client_name,
        message: baseQuote.message
      })
      // if (baseQuote.program === 'academic_years') {
      // } else {
      //   values.weeks = baseQuote.weeks
      //   values.startEvent = moment(baseQuote.arrival_date)
      //   values.lodgingStart = moment(baseQuote.lodging_start) ?? null
      //   values.lodgingWeeks = baseQuote.lodging_properties?.weeks
      // }

      console.log('🚀 ~ file: QuoterHighEducationForm.js:29 ~ useEffect ~ baseQuote', baseQuote)

      const agencyServices = []
      if (service.isAgency()) {
        baseQuote.agency_services?.map((item) => (
          agencyServices.push({ id: item.id, cost: service.getAgencyServiceCost(item.id) })
        ))
      }

      const bases = course?.offer?.base_prices || []
      const extras = course?.offer?.extras || []
      const allFees = [...bases, ...extras]
      const includedFees = []
      allFees?.map(({ id, cost }) => (
        baseQuote.fees.includes(id) ? includedFees.push({ id, cost }) : null
      ))
      setFormConfig({
        ...formConfig,
        courseCosts: baseQuote.course_costs,
        intake_date: baseQuote.intake,
        materials: baseQuote.materials,
        material: baseQuote.material,
        courseStart: moment(baseQuote.arrival_date),
        weeks: baseQuote.weeks,
        lodging: baseQuote.include_lodging,
        lodgingId: baseQuote.lodging,
        lodgingStart: moment(baseQuote.lodging_start) ?? null,
        lodgingWeeks: baseQuote.lodging_properties?.weeks,
        lodgingExtraStart: moment(baseQuote.lodging_extra_start),
        lodgingExtraNights: baseQuote.lodging_properties?.extra_nights_duration,
        lodgingExtras: baseQuote.lodging_extras,
        extra_nigth: baseQuote.extra_nigth,
        transport: baseQuote.include_transport,
        transportId: baseQuote.transport,
        transportWaySelected: baseQuote.transport_ways,
        transportMinorCosts: baseQuote.transport_minor_costs,
        fees: includedFees,
        insurance: baseQuote.include_insurance,
        healthInsuranceId: baseQuote.insurance,
        agencyServices,
        serviceAgency: baseQuote.additional_services?.length > 0,
        medical: baseQuote.agency_insurance?.length > 0
        // medicalId:
      })
    }
  }, [isLoadingBaseQuote, baseQuote])

  useEffect(() => {
    setYear(service.getPricesYear()?.year)
  }, [service.getPricesYear()?.year])

  return (
    <>
      <div className={service.getCourseProgram()?.name === 'graduate' ? 'program-intake-high-education' : ''}>
        <ProgramIntake />
      </div>
      <Row>
        <Col md={12}><h5 className='byp-title byp-mr-1'>Summary</h5></Col>
        <Col md={12} style={{ textAlign: 'center' }}><h5 className='byp-title byp-ml-1'>Cost</h5></Col>
      </Row>
      <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7', '8']} expandIconPosition='right'>
        <Panel header='Program Fees' key='1'>
          <FieldPayTuition form={form} />
          <CampusPricesSection />
          <FieldCourseCosts />
          {service.getSchoolMaterial() && <FieldSchoolMaterials />}
          <FieldCourseGoods />
        </Panel>

        {service.getCourseProgram()?.name !== 'boarding_schools' && (
          <>
            {
              service.hasLodging() && !service.isOnline() &&
                <Panel header='Accommodation fees' key='2'>
                  <LodgingSection />
                </Panel>
            }

            {
              service.hasTransports() && !service.isOnline() &&
                <Panel header='Airport Pick Up / Drop off' key='3'>
                  <TransportSection />
                </Panel>
            }
          </>
        )}

        {(service.getArrayHealtInsurance()?.length > 0 || course?.offer?.extras?.length > 0) && (
          <Panel header='Extras' key='4'>
            <OtherSection />
          </Panel>
        )}

        {
          service.isAgency() &&
            <Panel header='Agency costs' key='5'>
              <AgencyCostSections />
            </Panel>
        }
        {
          (service.getServiceAgency()?.length > 0 && service.isAgency()) &&
            <Panel header='Additional services' key='8'>
              <ServiceSection services_agency={agencyServices} />
            </Panel>
        }

        {
          (service.getMedicalInsurance()?.length > 0 && service.isAgency()) &&
            <Panel header='Medical Insurance' key='7'>
              <MedicalSection />
            </Panel>
        }

        <Panel header='Customize this quote' key='6'>
          <ClientInfoSection />
        </Panel>
      </Collapse>
    </>
  )
}

export { QuoterHighEducationForm }
