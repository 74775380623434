import { Form, Select } from 'antd'
import React from 'react'
import { useGetCurrencies } from '../../../bussines/hooks/agency/useGetCurrencies'
const { Option } = Select
function SelectCurrencyFormItem ({ setDivisa, ...props }) {
  const { isLoading, data: currencies } = useGetCurrencies()
  const { size } = props
  const onChangeDivisa = (id) => {
    const found = currencies?.find((currency) => {
      return currency.id === id
    })
    if (!found || !setDivisa) return
    setDivisa(found?.code)
  }
  return (
    <Form.Item {...props}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        style={{ width: '100%' }} placeholder={props.placeholder} loading={isLoading}
        onChange={onChangeDivisa}
        size={size ?? 'middle'}
        disabled={props.disabled}
      >
        {currencies?.map(item => (
          <Option key={item.id} value={item.id}>{`(${item.code}) - ${item.name}`}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectCurrencyFormItem }
