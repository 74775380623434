import React from 'react'
import { Col, Form, Row, Input, Select, InputNumber, Switch, Button } from 'antd'
import { PrefixLabelSelector } from '../../Institution/components/PrefixLabelFormItem'
import { AddAndRemoveListButtons } from '../../Institution/components/AddAndRemoveListButtons'
import { PlusOutlined } from '@ant-design/icons'

const { Option } = Select

export const FormConcept = ({ name, divisa, form, listName }) => {
  const array = Form.useWatch([listName, ...name], form) || []
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />
  return (
    <Form.List
      name={name}
      initialValue={[
        {}
      ]}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} gutter={16}>
              <Col span={5}>
                <Form.Item
                  name={[name, 'name']}
                >
                  <Input placeholder='Concept' />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name={[name, 'cost']}
                >
                  <InputNumber addonBefore={prefixSelector} placeholder='00.00' min={0} controls={false} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name={[name, 'frequency']}
                >
                  <Select placeholder='' size='small'>
                    <Option key='unic'>Single payment</Option>
                    <Option key='weeks'>Weekly</Option>
                    <Option key='semester'>One semester</Option>
                    <Option key='year'>One year payment</Option>

                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                />
              </Col>
              <Col span={6} style={{ paddingTop: '5px' }}>
                <Row style={{ columnGap: '10px' }}>
                  Mandatory
                  <Form.Item
                    name={[name, 'mandatory']}
                  >
                    <Switch checkedChildren='YES' unCheckedChildren='NO' checked={array[name]?.mandatory} />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          )
          )}

          {
            fields.length === 0 && (
              <Button
                className='byp-btn-list-function-more byp-bg-blue-50'
                onClick={() => add()}
                icon={<PlusOutlined />}
              />
            )
          }

        </>
      )}
    </Form.List>
  )
}
