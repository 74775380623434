import { userRoles } from '../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../common/components/PageProtected'
import { MainTheme } from '../../../common/theme/MainTheme'
import { FormExchange } from './components/FormExchange'

const URL_CURRENCIES_PAGE = '/dashboard/currencies'

function CurrenciesPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <FormExchange />
      </MainTheme>
    </PageProtected>
  )
}

export { CurrenciesPage, URL_CURRENCIES_PAGE }
