import api from '../../services/api'

class InsuranceRepository {
  async insuranceList (filtersQuery) {
    const response = await api.get('/fees/insurance/list?' + filtersQuery)
    return response.data
  }

  async create ({ data }) {
    const response = await api.post('/fees/insurance', data)
    return response.data
  }

  async savePrices ({ id, data }) {
    const response = await api.post(`/fees/${id}/prices/save`, data)
    return response.data
  }

  async find (id) {
    const response = await api.get(`/fees/${id}/show`)
    return response.data
  }

  async getCosts (id, year) {
    const response = await api.get(`/fees/${id}/prices?year=${year}`)
    return response.data
  }

  async getFeeYears (fee, filtersQuery) {
    const response = await api.get(`/fees/${fee}/years` + filtersQuery)
    return response.data
  }
}

export default new InsuranceRepository()
