import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import utilsState from '../../../../bussines/utils/state-control'
import { useFindLodging } from '../../../../bussines/hooks/school/lodging/useFindLodging'
import { useGetLodgings } from '../../../../bussines/hooks/school/lodging/useGetLodgings'
import { usePostSaveLodging } from '../../../../bussines/hooks/school/lodging/usePostSaveLodging'
import { usePostUpdateLodging } from '../../../../bussines/hooks/school/lodging/usePostUpdateLodging'
import { TableLodgins } from '../Tables/TableLodgins'
import { SelectSchoolProgramsFormItem } from '../../../../common/components/helper-form/SelectSchoolProgramsFormItem'
import { SelectWeekDay } from '../../../../common/components/helper-form/SelectWeekDay'
import { useDeleteFee } from '../../../../bussines/hooks/fee/useDeleteFee'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { URL_LODGINGPRICES_CREATE_PAGE } from '../LodgingPrices/CreateLodgingPricesPage'
import TextArea from 'antd/lib/input/TextArea'
import { showErrorModal } from '../../../../utils/propsForModal'
import { useNavigate } from 'react-router-dom'
import { SelectAvailableTranslationsFormItem } from '../../../../common/components/helper-form/SelectAvailableTranslationsFormItem'

const { Option } = Select

function FormCreateLodging ({ program }) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [lodgingID, setLodgingID] = useState()
  const [programId, setProgramID] = useState()
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [programName, setProgramName] = useState(program)
  const [page, setPage] = useState(1)
  const [searchFilter, setSearchFilter] = useState()

  const { isLoading, data, refetch, isFetching } = useGetLodgings(program && { program, page, ...searchFilter })
  const { data: lodging, isLoading: isLoadingLodging } = useFindLodging(lodgingID)
  const { saveLodging, isLoading: isSaving, currentState: savingState, error: errorSaving } = usePostSaveLodging()
  const { updateLodging, isLoading: isUpdating, currentState, error: errorUpdating } = usePostUpdateLodging()
  const { deleteFee, isLoading: isDeleting, currentState: deleteState } = useDeleteFee()

  const [modal, contextHolder] = Modal.useModal()

  const onFinish = (values) => {
    if (isUpdateMode) {
      updateLodging(lodging?.id, values)
    } else {
      saveLodging(values)
    }
  }

  const onDelete = (id) => {
    deleteFee(id)
  }

  const onUpdate = ({ category }) => {
    if (category !== undefined) {
      setProgramID(category)
    }
  }

  /**
   * Handle the SelectAvailableTranslationsFormItem value
   * @param {?String} lang
   */
  const handleChangeTranslateLanguage = (lang) => {
    if (lang) {
      const translations = lodging?.translations ?? []
      const langTranslations = translations.filter(translation => translation.to === lang)
      // Set the new values
      const nameTraslated = langTranslations.find(translation => translation.attribute === 'name')
      const descTraslated = langTranslations.find(translation => translation.attribute === 'description')
      form.setFieldsValue({
        name: nameTraslated?.translation,
        description: descTraslated?.translation
      })
    } else {
      form.setFieldsValue(lodging)
    }
  }

  useEffect(() => {
    if (!isUpdating && currentState === utilsState.STATE_SUCCESS) {
      form.resetFields()
      refetch()
      setIsUpdateMode(false)
      setLodgingID()
    }
  }, [isUpdating])

  useEffect(() => {
    if (!isDeleting && deleteState === utilsState.STATE_SUCCESS) {
      refetch()
    }
  }, [isDeleting])

  useEffect(() => {
    if (!isSaving && savingState === utilsState.STATE_SUCCESS) {
      refetch()
      form.resetFields()
    }
  }, [isSaving])

  useEffect(() => {
    if (!isLoadingLodging && lodging) {
      form.setFieldsValue({
        ...lodging,
        translation: null
      })
      setIsUpdateMode(true)
      setProgramID(lodging.category)
    }
  }, [isLoadingLodging, lodging])

  useEffect(() => {
    if (errorSaving?.response.data) {
      showErrorModal(modal, errorSaving.response.data.message)
    }
    if (errorUpdating?.response.data) {
      showErrorModal(modal, errorUpdating.response.data.message)
    }
  }, [errorSaving, errorUpdating, modal])

  const onContinue = (values) => {
    navigate(URL_LODGINGPRICES_CREATE_PAGE.replace(':program', program), { state: { menuItemSelected: program + '_lodgings_prices', openSub: program, openSub2: program + '_lodgings' } })
  }

  return (
    <Form
      layout='vertical'
      scrollToFirstError
      name='FormCreateQuote'
      onFinish={onFinish}
      form={form}
      onValuesChange={onUpdate}
    >
      {contextHolder}
      <div className='byp-dashboard-content'>
        <Row gutter={16} className='byp-mt-1'>
          {/* Form */}
          <Col xs={24} md={12}>
            {/* Header */}
            <Row gutter={24}>
              <Col span={8}>
                <h5 className='byp-title'>
                  Create your accommodations
                </h5>
              </Col>
              <Col xs={20} lg={14}>
                <Row gutter={[5]} style={{ display: 'flex', alignItems: 'center' }}>
                  <Col xs={8} md={8} className='byp-text-sm byp-fw-bold'>You're at:</Col>
                  <Col xs={8} md={8}>
                    <SelectSchoolProgramsFormItem
                      name='category'
                      rules={[{ required: true, message: 'This field is required' }]}
                      setProgramName={setProgramName}
                      setProgramID={setProgramID}
                      program={program}
                      form={form}
                      disabled
                      className='graduate_show_category'
                    />
                  </Col>
                  <Col xs={8}>
                    <SelectAvailableTranslationsFormItem
                      name='translation'
                      formItemProps={{
                        noStyle: true,
                        className: 'margin-0'
                      }}
                      selectProps={{
                        size: 'small',
                        className: 'w-100 margin-0',
                        onChange: (lang) => handleChangeTranslateLanguage(lang),
                        allowClear: true
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16} className='byp-mt-1'>
              <Col xs={12} md={8}>Program type:</Col>
              <Col xs={12} md={14}>
                <SelectSchoolProgramsFormItem
                  name='category'
                  rules={[{ required: true, message: 'This field is required' }]}
                  setProgramName={setProgramName}
                  setProgramID={setProgramID}
                  program={program}
                  form={form}
                  size='small'
                />
              </Col>
              <Col xs={12} md={8}>Accommodation type</Col>
              <Col xs={12} md={14}>
                <Form.Item
                  name='type'
                >
                  <Select size='small'>
                    <Option key={0} value='Home stay'>Homestay</Option>
                    <Option key={1} value='Residence'>Residence</Option>
                    <Option key={2} value='Studio'>Studio</Option>
                    <Option key={3} value='Hotel'>Hotel</Option>
                    <Option key={4} value='Other'>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {
              programName !== 'academic_years' && (
                <>
                  <h6><b style={{ color: '#2D2F66' }}>Arrive, departure</b></h6>
                  <Row>
                    <Col xs={24} md={8}>Arriving </Col>
                    <Col xs={24} md={14}>
                      <SelectWeekDay
                        name='arrival_day'
                        rules={[{ required: true, message: 'This field is required' }]}
                        size='small'
                        isAccommodation
                      />
                    </Col>
                    <Col xs={24} md={8}>Departing </Col>
                    <Col xs={24} md={14}>
                      <SelectWeekDay
                        name='departure_day'
                        rules={[{ required: true, message: 'This field is required' }]}
                        size='small'
                        isAccommodation
                      />
                    </Col>
                  </Row>
                </>
              )
            }
            <h6><b style={{ color: '#2D2F66' }}>Accommodation info</b></h6>
            <Row>
              <Col xs={24} md={8}>Name:</Col>
              <Col xs={24} md={14}>
                <Form.Item
                  name='name'
                >

                  {programName !== 'academic_years'
                    ? <Input placeholder='Twin Room | Half Board' />
                    : <Input placeholder='Homestay | Residence' />}
                </Form.Item>
              </Col>
              {programName !== 'academic_years' && (
                <Col xs={24} md={8}>Travel time</Col>
              )}
              {programName !== 'academic_years' && (
                <Col xs={24} md={14}>
                  <Form.Item
                    name='distance_to_school'
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {programName !== 'academic_years' && <Col xs={24} md={8}>Bathroom type</Col>}
              {programName !== 'academic_years' && (
                <Col xs={24} md={14}>
                  <Form.Item
                    name='bathroom'
                  >
                    <Select>
                      <Option key={0} value='Private bathroom'>Private bathroom</Option>
                      <Option key={1} value='Semi-private bathroom'>Semi-private bathroom</Option>
                      <Option key={2} value='Shared bathroom'>Shared bathroom</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} md={8}>Description</Col>
              <Col span={14}>
                <Form.Item name='description'/* label='Description' */>
                  <TextArea maxLength={600} rows={5} />
                </Form.Item>
              </Col>
            </Row>
            <h6><b style={{ color: '#2D2F66' }}>Campus where the accommodation is offered</b></h6>
            <SelectCampusByProgramFormItem
              name='sedes'
              program={programId}
              mode='multiple'
              rules={[{ required: true, message: 'This field is required' }]}
            />
            <div className='byp-mt-1'>
              <Button
                type='primary'
                className='float-right'
                htmlType='submit'
                loading={isSaving || isUpdating}
              >
                {isUpdateMode ? 'Update' : 'Save accommodation'}
              </Button>
            </div>
          </Col>
          {/* Table */}
          <Col xs={24} md={12}>
            <TableLodgins
              data={data?.data}
              metaData={data?.meta}
              isLoading={isLoading || isFetching}
              isDeleting={isDeleting}
              onDelete={onDelete}
              refetch={refetch}
              setLodgingID={setLodgingID}
              program={program}
              setPage={setPage}
              setSearchFilter={setSearchFilter}
            />
          </Col>
        </Row>
      </div>
      <div className='byp-dashboard-footer'>
        <Row
          gutter={12}
          className='byp-form-footer'
        >
          <Col span={24}>

            <Button
              className='byp-btn-blue-100-outline float-right float-right byp-mt-1'
              htmlType='submit'
              onClick={onContinue}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export { FormCreateLodging }
