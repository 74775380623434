import React, { useEffect } from 'react'
import { Row, Col, notification } from 'antd'
import { useGetPopularSchools } from '../../../../../bussines/hooks/agency/analytics/general/useGetPopularSchools'
import { BasicSpinner } from '../../../../../common/components/BasicSpinner'

const MostPopularSchools = ({ time, user, office, region, subregion, countrie, agency, campus }) => {
  const { data, isLoading, error } = useGetPopularSchools({ ...time, limit: 4, user, office, agency_region: region, agency_subregion: subregion, agency_country: countrie, agency_id: agency, campus })

  useEffect(() => {
    if (!isLoading) {
      if (data) {
        // console.log('🚀 ~ file: MostPopularSchools.js:11 ~ useEffect ~ data', data)
      } else if (error) {
        notification.error({
          message: 'Something went wrong. Popular Schools data can not be updated. Please try later'
        })
        // console.log('🚀 ~ file: MostPopularSchools.js:13 ~ useEffect ~ error', error)
      }
    }
  }, [isLoading, data, error])

  if (isLoading) {
    return <BasicSpinner />
  }

  return (
    <Row gutter={12}>
      {data?.map((item) => (
        <Col
          xs={24}
          sm={12}
          md={12}
          xl={6}
          key={'schools_' + item.id}
        >
          <div style={{ padding: '5px 0' }}>
            <div className='quote-list-item-card' style={{ padding: '0.75rem 0.75rem', height: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem', justifyContent: 'space-between', alignItems: 'center' }}>
              <p className='margin-0 byp-color-gray-100 byp-text-sm'>{item.quotes_count} - Quotes created</p>

              <div style={{ width: '5rem', height: '5rem', objectFit: 'cover', overflow: 'hidden' }} className='d-flex justify-center'>
                <img src={item.profile_photo_url} alt={item.name} />
              </div>

              <div style={{ textAlign: 'center', height: '50px',alignItems: 'center', display:'flex' }}>
                <p className='margin-0 byp-color-gray-100' >{item.name}</p>
              </div>

            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default MostPopularSchools
