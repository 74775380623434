import { Button, Col, DatePicker, Form, Input, Row, Select, Switch, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
// import { SelectCurrencyFormItem } from '../../../common/components/helper-form/SelectCurrencyFormItem'
// import { SelectProgramsFormItem } from '../../../common/components/helper-form/SelectProgramsFormItem'
import { SelectLanguageFormItem } from '../../../../common/components/helper-form/SelectLanguageFormItem'
// import { SelectGenderFormItem } from '../../../../common/components/helper-form/SelectGenderFormItem'
// import { SelectReligionFormItem } from '../../../../common/components/helper-form/SelecReligionFormItem'
import { SelectSportsFormItem } from '../../../../common/components/helper-form/SelectSportsFormItem'
import { SelectExtracurricularFormItem } from '../../../../common/components/helper-form/SelectExtracurricularFormItem'
import { useEffect, useState } from 'react'
import { AddAndRemoveListButtons } from '../components/AddAndRemoveListButtons'
import { SelectBranchesAll } from '../../agency/components/SelectBranchesAll'
import { usePostCourses } from '../../../../bussines/hooks/school/usePostCourses'
// import { YearsFormList } from '../components/YearsFormList'
// import { TablePackages } from '../../agency/Tables/TablePackages'
// import { useGetPackages } from '../../../../bussines/hooks/packages/useGetPackages'
import { formatYearsList, formatYearsListToMoment, momentToDateformat } from '../../../../utils/formatMoment'
import { useFindShort } from '../../../../bussines/hooks/packages/useFindPackage'
import moment from 'moment'
import { usePostUpdateCourse } from '../../../../bussines/hooks/school/course/usePostUpdateCourse'
import { useDeleteService } from '../../../../bussines/hooks/school/useDeleteService'
import { useGetInstitutionByProgram } from '../../../../bussines/hooks/agency/analytics/program/useGetInstitutionsByProgram'
import { Option } from 'antd/lib/mentions'
// import { SelectProgramsFormItem } from '../../../../common/components/helper-form/SelectProgramsFormItem'
import { useGetCampusByInstitutionAndProgram } from '../../../../bussines/hooks/agency/analytics/program/useGetCampusByInstitutionAndProgram'
import { useLocation } from 'react-router-dom'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import { useGetShort } from '../../../../bussines/hooks/short/useFindShort'
import { TableShort } from '../../agency/Tables/TableShort'

function FormShortTerm () {
  const { user: _user } = useAuth()
  const location = useLocation()
  const { packageID } = location.state ?? { packageID: undefined }
  const [lenght, setLenght] = useState('')
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [confirmLenght, setConfirmLenght] = useState(false)
  const [haveSpecificStartDates, setHaveSpecificStartDates] = useState(false)
  const [haveUnlimited, setHaveUnlimited] = useState(false)
  const [packageId, setPackageId] = useState(packageID)
  const [paginationData, setPaginationData] = useState({})
  const [filters, setFilters] = useState({ page: 1 })
  const [haveSports, setHaveSports] = useState(false)
  const [haveExtra, setHaveExtra] = useState(false)
  const { saveCourses, isLoading: isSaving, state, currentState, error } = usePostCourses()
  const { updateCourse, isLoading: isUpdating, error: updateError, currentState: updateState } = usePostUpdateCourse()
  const { deleteService, isLoading: isDeleting, currentState: deleteState } = useDeleteService()
  const { data: packages, isLoading: isLoadingPackages, isError, error: getPackagesError, refetch } = useGetShort(filters)
  const { data: packageItem, isLoading: searchingPackage, isError: packageNotFound, error: findPackageError } = useFindShort(packageId)

  const [form] = Form.useForm()
  const sports = Form.useWatch('includeSports', form)
  const extracurriculars = Form.useWatch('includeExtracurriculars', form)

  const [program, setProgram] = useState()
  const [institution, setInstitution] = useState()
  const [campus, setCampus] = useState()
  const [institutions, setInstitutions] = useState([])
  const [campusData, setCampusData] = useState([])
  const [isAgency, setIsAgency] = useState(false)

  const { data, isLoading } = useGetInstitutionByProgram(program)
  const { data: campusDataApi, isLoading: isLoadingCampus } = useGetCampusByInstitutionAndProgram(program, institution)

  useEffect(() => {
    setCampusData(handleUniqueArr(campusDataApi))
  }, [campusDataApi])

  useEffect(() => {
    setInstitutions(handleUniqueArr(data))
  }, [data])

  const handleUniqueArr = (array) => {
    return array?.filter((item, index) => {
      return array.findIndex(obj => obj.id === item.id) === index
    })
  }

  const handleLenght = (date, dateString, type) => {
    console.log(date, dateString, type)
    if (type === 'start') {
      setStart(date)
    } else if (type === 'end') {
      setEnd(date)
    }
  }

  const disabledDateStart = (current) => {
    return (current < moment().startOf('day'))
  }

  const disabledDateEnd = (current) => {
    return (current < moment().startOf('day') || current < start)
  }

  useEffect(() => {
    setHaveExtra(extracurriculars)
    setHaveSports(sports)
  }, [extracurriculars, sports])

  const handleOnFinish = (values) => {
    values.service_type = 'short'
    values.start_at = momentToDateformat(values.start_at)
    values.end_at = momentToDateformat(values.end_at)
    values.years = formatYearsList(values.years)
    values.religion = form.getFieldValue('religion') ?? 'Secular'
    values.gender = form.getFieldValue('gender') ?? 'Mixed'
    values.comments = form.getFieldValue('comments')
    values.start_dates = values.start_dates?.map((item) => {
      return { date: momentToDateformat(item.date) }
    })

    if (_user.type === 'Institution') {
      values.program = 'short_term'
    }
    // startDates.map((item) => {
    //   return { date: momentToDateformat(item.date) }})
    console.log('🚀 ~ file: FormPackages.js:40 ~ handleOnFinish ~ values:', values)
    packageId ? updateCourse(packageId, values) : saveCourses(values)
    setPackageId()
  }

  useEffect(() => {
    if (!isSaving && currentState === state.STATE_SUCCESS) {
      notification.success({
        message: 'Data saved'
      })
      refetch()
      form.resetFields()
    } else if (!isSaving && currentState === state.STATE_ERROR) {
      notification.error({
        message: error?.response?.data?.message ?? 'Error :c'
      })
    }
  }, [isSaving])

  useEffect(() => {
    if (!isUpdating && updateState === state.STATE_SUCCESS) {
      notification.success({
        message: 'Data updated'
      })
      refetch()
      form.resetFields()
      setPackageId()
    } else if (!isUpdating && updateState === state.STATE_ERROR) {
      notification.error({
        message: updateError?.response?.data?.message ?? 'Error :c'
      })
    }
  }, [isUpdating])

  useEffect(() => {
    if (!isDeleting && deleteState === state.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Package deleted'
      })
    }
  }, [isDeleting])

  useEffect(() => {
    if (start && end) {
      const lenghtDate = end - start
      const differenceWeeks = Math.ceil(lenghtDate / (1000 * 60 * 60 * 24 * 7))
      setLenght(differenceWeeks + ' weeks')
    }
  }, [start, end])

  useEffect(() => {
    if (!isLoadingPackages && isError) {
      notification.error({
        message: getPackagesError.response?.data?.message
      })
    }
    if (!isLoadingPackages && packages) {
      setPaginationData({
        page: packages.meta?.current_page,
        total: packages.meta?.total,
        pageSize: packages.meta?.per_page,
        size: packages.meta?.last_page,
        current: packages.meta?.current_page,
        onChange: (val) => setFilters({ ...filters, page: val })
      })
    }
  }, [isLoadingPackages])

  useEffect(() => {
    if (!searchingPackage && packageItem) {
      const formatedYears = formatYearsListToMoment(packageItem.years)
      packageItem.start_at = moment(packageItem.start_at)
      packageItem.end_at = moment(packageItem.end_at)
      packageItem.start_dates = packageItem.start_dates?.map((item) => {
        return { date: moment(item.start_date) }
      })
      const newValues = {
        ...packageItem,
        name: packageItem.label,
        ...packageItem.properties,
        years: formatedYears,
        program: packageItem.category?.name,
        unlimited: !!packageItem.properties?.spots,
        offices: packageItem.properties?.offices ?? [],
        religion: packageItem.properties.religion,
        gender: packageItem.properties.gender,
        comments: packageItem.comments,
        camp_name: packageItem.properties.camp_type
      }
      setHaveSpecificStartDates(newValues.start_dates?.length > 0)
      setConfirmLenght(newValues.start_dates?.length > 0 || newValues.max_weeks)
      setStart(newValues.start_at)
      setEnd(newValues.end_at)
      if (packageItem?.properties?.sports?.length > 0) {
        setHaveSports(true)
        newValues.includeSports = true
      }
      if (packageItem?.properties?.extracurriculars?.length > 0) {
        setHaveExtra(true)
        newValues.includeExtracurriculars = true
      }

      if (packageItem.properties.spots > 0) {
        setHaveUnlimited(false)
      } else {
        setHaveUnlimited(true)
      }
      setProgram(newValues.program)
      setInstitution(newValues.institution_id)
      setCampus(newValues.campus_id)
      console.log('🚀 ~ file: FormPackages.js:113 ~ useEffect ~ newValues:', newValues)
      form.setFieldsValue(newValues)
    } else if (!searchingPackage && packageNotFound) {
      notification.error({
        message: findPackageError.response?.data?.message
      })
      setPackageId()
    }
  }, [searchingPackage, packageItem, updateState])

  useEffect(() => {
    if (_user.type === 'Institution') {
      setIsAgency(false)
      setProgram('short_term')
    } else if (_user.type === 'Agency') {
      setIsAgency(true)
    }
  }, [_user])

  return (
    <Form form={form} onFinish={handleOnFinish} requiredMark={false}>
      <div className='byp-dashboard-content'>
        <Row gutter={20}>
          <Col span={12}>
            <h4 style={{ paddingBottom: '30px' }}>Upload your short term</h4>
            {/* <SelectCurrencyFormItem
              label='Currency'
              labelCol={{ span: 5 }}
              labelAlign='left'
              size='small'
              name='divisa_id'
            /> */}

            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>  Name and description </b></p>

            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label='Camp type'
                  labelAlign='left'
                  name='camp_name'
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Select size='small'>
                    <Option value='summer_camp'>Summer Camp</Option>
                    <Option value='winter_camp'>Winter Camp</Option>
                    <Option value='easter_camp'>Easter Camp</Option>
                    <Option value='other'>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <SelectLanguageFormItem
                  placeholder=''
                  label='Language'
                  name='language_id'
                  showSearch
                  size='small'
                  rules={[{ required: true, message: 'This field is required' }]}
                />
              </Col>
            </Row>
            <Form.Item
              label='Camp name'
              labelCol={{ span: 5 }}
              labelAlign='left'
              name='name'
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Description'
              labelCol={{ span: 5 }}
              labelAlign='left'
              name='description'
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <TextArea
                maxLength={600}
                showCount
              />
            </Form.Item>

            {/* <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>Age range:</b></p>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label='Minimum'
                  name='min_age'
                >
                  <Input
                    size='small'
                    type='number'
                    min='5'
                    max='55'
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Maximum'
                  name='max_age'
                >
                  <Input
                    size='small'
                    type='number'
                    min='5'
                    max='55'
                  />
                </Form.Item>
              </Col>
            </Row>
            <SelectGenderFormItem labelAlign='left' labelCol={{ span: 3 }} size='small' name='gender' label='Gender' changeNameMix defaultValue='mix' />
            <SelectReligionFormItem labelAlign='left' labelCol={{ span: 3 }} size='small' name='religion' label='Religion' defaultValue='Secular' /> */}

            {/* <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>    Program Specialty </b></p> */}
            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>Camp Specialty</b></p>
            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>    Sports programs </b></p>
              <Form.Item name='includeSports'>
                <Switch checked={haveSports} />
              </Form.Item>
            </Row>
            {sports && (
              <Row style={{ display: 'flex' }}>
                <SelectSportsFormItem maxTagCount='responsive' size='small' name='sports' mode='multiple' style={{ width: '100%' }} />
              </Row>
            )}

            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>  Curricular program </b></p>
              <Form.Item name='includeExtracurriculars'>
                <Switch checked={haveExtra} />
              </Form.Item>
            </Row>
            {extracurriculars && (
              <Row style={{ display: 'flex' }}>
                <SelectExtracurricularFormItem maxTagCount='responsive' size='small' name='extracurriculars' mode='multiple' style={{ width: '100%' }} />
              </Row>
            )}

            {isAgency && (
              <>
                <SelectBranchesAll
                  mode='multiple'
                  name='offices'
                  label='Offices'
                  form={form}
                />

                <Form.Item
                  name='institution_id' label='Institution' labelCol={{ span: 4 }} labelAlign='left' size='small'
                >
                  <Select
                    placeholder=''
                    style={{ width: '100%' }}
                    value={institution}
                    loading={isLoading}
                    onChange={(val) => {
                      setInstitution(val)
                      setCampus('undefined')
                    }}
                  >
                    {institutions?.map(institution => (
                      <Option key={institution.id} value={institution.id}>{institution.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name='campus_id' label='School' labelCol={{ span: 4 }} labelAlign='left' size='small'
                >
                  <Select
                    placeholder=''
                    style={{ width: '100%' }}
                    size='small'
                    value={campus}
                    loading={isLoading}
                    onChange={(val) => {
                      setCampus(val)
                    }}
                  >
                    {campusData?.map(campus => (
                      <Option key={campus.id} value={campus.id}>{campus.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>Time and availity</b></p>
            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>Please confirm if you package had a specific length or start dates</b></p>
              <Form.Item>
                <Switch onChange={setConfirmLenght} checked={confirmLenght} />
              </Form.Item>
            </Row>

            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label='Start'
                  labelAlign='left'
                  labelCol={{ span: 8 }}
                  name='start_at'
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <DatePicker
                    format='DD/MM/YYYY'
                    size='small'
                    onChange={
                      (e, value) => {
                        handleLenght(e, value, 'start')
                      }
                    }
                    disabledDate={disabledDateStart}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='End'
                  labelAlign='left'
                  labelCol={{ span: 8 }}
                  name='end_at'
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <DatePicker
                    format='DD/MM/YYYY'
                    size='small'
                    onChange={(e, value) => handleLenght(e, value, 'end')}
                    disabledDate={disabledDateEnd}
                  />
                </Form.Item>
              </Col>
            </Row>

            {!confirmLenght && (
              <>
                <Form.Item
                  label='Lenght'
                  labelAlign='left'
                  labelCol={{ span: 4 }}
                >
                  <Input size='small' value={lenght} disabled style={{ color: '#000' }} />
                </Form.Item>
              </>
            )}
            {confirmLenght && (
              <>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label='Minimum Weeks'
                      labelAlign='left'
                      name='min_weeks'
                    >
                      <Input size='small' type='number' />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => prevValues.min_weeks !== currentValues.min_weeks}
                    >
                      {({ getFieldValue }) =>
                        <Form.Item
                          label='Maximum Weeks'
                          labelAlign='left'
                          name='max_weeks'
                        >
                          <Input
                            size='small'
                            type='number'
                            min={
                              getFieldValue('min_weeks') > 0 ? parseInt(getFieldValue('min_weeks')) : 0
                            }
                          />
                        </Form.Item>}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
                  <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>Specific start date</b></p>
                  <Form.Item>
                    <Switch onChange={setHaveSpecificStartDates} checked={haveSpecificStartDates} />
                  </Form.Item>
                </Row>
                {
                  haveSpecificStartDates && (
                    <div>
                      <Form.List
                        name='start_dates'
                      // initialValue={[{}]}
                      >
                        {(fields, { add, remove }) => (
                          <div className={fields.length === 0 ? '' : 'white-background'}>
                            {fields.map(({ key, name, ...restField }, index) => (
                              <Row key={key} gutter={10}>
                                <Col>
                                  <Form.Item
                                    label='Start'
                                    name={[name, 'date']}
                                  >
                                    <DatePicker format='MMM DD' size='small' />
                                  </Form.Item>
                                </Col>
                                <Col style={{ display: 'flex', gap: '20px' }}>
                                  <AddAndRemoveListButtons
                                    fields={fields}
                                    index={index}
                                    add={add}
                                    remove={remove}
                                    name={name}
                                  />
                                </Col>
                              </Row>
                            ))}
                            {
                              fields.length === 0 && (
                                <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()}>
                                  Add
                                </Button>
                              )
                            }
                          </div>
                        )}
                      </Form.List>
                    </div>
                  )
                }
              </>
            )}

            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>    Available spots </b></p>

            <Row style={{ gap: '20px', display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontSize: '.9rem', color: 'var(--blue-200)' }}><b>Unlimited</b></p>
              <Form.Item name='unlimited'>
                <Switch onChange={setHaveUnlimited} checked={haveUnlimited} />
              </Form.Item>
            </Row>
            {
              !haveUnlimited && (
                <Form.Item
                  label='Spots'
                  labelAlign='left'
                  labelCol={{ span: 4 }}
                  name='spots'
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <Input />
                </Form.Item>
              )
            }
            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>Additional comments</b></p>
            <Form.Item
              name='comments'
              label='Comments'
              labelAlign='left'
              labelCol={{ span: 4 }}
            >
              <TextArea
                maxLength={600}
                showCount
              />
            </Form.Item>
            {/* <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)', lineHeight: '0px' }}><b>Publication dates</b></p>
            <p style={{ lineHeight: '3px', fontSize: '13px' }}>(dates on which prices are publicated)</p>
            <YearsFormList
              rules={[{ required: true, message: 'This field is required' }]}
              name='years'
            /> */}

            <Form.Item
              name='school_link'
              label='School link'
              labelAlign='left'
              labelCol={{ span: 4 }}
            >
              <Input
                addonBefore='http://'
              />
            </Form.Item>

            <p style={{ fontSize: '1.25rem', color: 'var(--blue-200)' }}><b>Campus where the short term course is offered</b></p>
            <SelectCampusByProgramFormItem
              program={program}
              // label='School'
              labelCol={{ span: 4 }}
              labelAlign='left'
              size='small'
              name='campus'
              maxTagCount='responsive'
              form={form}
              mode='multiple'
              rules={[{ required: true, message: 'This field is required' }]}
            />
            <div style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}>
              {/* {packageId && !searchingPackage && (
                <Button
                  type='primary'
                  style={{ marginRight: '1rem' }}
                  onClick={() => {
                    form.resetFields()
                    setPackageId()
                  }}
                >
                  Cancel
                </Button>
              )} */}
              <Button type='primary' htmlType='submit' loading={isSaving || isUpdating || isLoadingCampus}>
                {packageId ? 'Update' : 'Save'}
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <TableShort
              packages={packages?.data ?? []}
              setId={setPackageId}
              isLoading={isLoadingPackages}
              isDeleting={isDeleting || searchingPackage}
              deletePackage={deleteService}
              setFilters={setFilters}
              paginationData={paginationData}
            />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export { FormShortTerm }
