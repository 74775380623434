import { useEffect, useState } from 'react'
import { Form, Upload, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { LoadingOutlined } from '@ant-design/icons'
import { headersForUpload, isJpgOrPng, isLt2M } from '../../../../utils/uploadImageUtils'

function beforeUpload (file) {
  const _isJpgOrPng = isJpgOrPng(file)
  if (!_isJpgOrPng) {
    message.error('Only jpeg or png formats!')
  }
  const _isLt2M = isLt2M(file)
  if (!_isLt2M) {
    message.error('Image must be smaller than 2MB!')
  }
  return _isJpgOrPng && _isLt2M
}

const UploadSliderFormItem = ({ name, maxCount, action, setGallery, setUrl, setId, url }) => {
  // const form = Form.useFormInstance()
  const [loading, setLoading] = useState(false)
  const [imgName, setImgName] = useState()

  const handleChange = ({ file, fileList }) => {
    if (file.status === 'uploading') {
      setLoading(true)
    } else if (file.status === 'done') {
      // console.log('🚀 ~ file: UploadSliderFormItem.js:25 ~ handleChange ~ file:', name, file)
      if (setUrl) {
        setUrl(currentUrl => {
          const newUrl = [...currentUrl]
          newUrl[name] = file.response.url
          return newUrl
        })
      }
      if (setId) {
        setId(currentId => {
          const newId = [...currentId]
          newId[name] = file.response.id
          return newId
        })
      }
      setImgName(file.name)
      message.success(`${file.name} file uploaded successfully`)
      setLoading(false)
      if (setGallery) {
        const formatedList = fileList.filter(img => img.response?.id).map(img => ({ url: img.response.url }))
        setGallery(formatedList)
      }
    } else if (file.status === 'removed') {
      if (setGallery) {
        const formatedList = fileList.filter(img => img.response?.id).map(img => ({ url: img.response.url }))
        setGallery(formatedList)
      }
    } else {
      message.error(`${file.status} file upload failed.`)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (url?.length === 0) {
      setImgName('')
    }
  }, [url])

  return (
    <Form.Item name={[name, 'image']}>
      <ImgCrop
        rotate
        aspect={25 / 9}
      >
        <Upload
          name='image'
          className='byp-upload-hide-list w-100 avatar-uploader dashboard-media-uploader button-select-picture'
          listType='picture'
          action={action}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          headers={headersForUpload()}
          maxCount={maxCount ?? 5}
          showUploadList={false}
        >
          <>
            {loading ? <LoadingOutlined /> : null}
            <span className='btn-upload'>{(!loading && imgName) ? 'Uploaded pic:' : 'Select picture'}</span>
            <span className='txt-upload'>{(!loading && imgName) && imgName}</span>
          </>
        </Upload>
      </ImgCrop>
    </Form.Item>
  )
}

export { UploadSliderFormItem }
