import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import Form from 'antd/lib/form/Form'
import stateControl from '../../../../../bussines/utils/state-control'
import { InstitutionProfileFieldsContainer } from '../../steps/intitution-profile/InstitutionProfileFieldsContainer'
import { useProfileEnterprise } from '../../../../../providers/enterprise/profile-enterprise-provider'
import LottieCargando from '../../../../../lotties/LottieCargando'

function EditInstitutionProfile () {
  const [modal, context] = Modal.useModal()
  const {
    form,
    loadingProfile,
    initValues,
    isLoadingUpdateEnterprise,
    currentStateUpdateEnterprise,
    updateEnterprise,
    refetch,
    openNotification,
    errorUpdateCompanyProfile
  } = useProfileEnterprise()

  useEffect(() => {
    if (!isLoadingUpdateEnterprise && currentStateUpdateEnterprise === stateControl.STATE_SUCCESS) {
      openNotification('The profile has been updated')
      refetch()
    } else if (!isLoadingUpdateEnterprise && errorUpdateCompanyProfile) {
      errorUpdateCompanyProfile.response?.data?.message.includes('Integrity constraint violation: 1062')?
      modal.error({
        title: 'Ups!',
        content: "The Company's name already exist, try with other one"
      })
      :
      modal.error({
        title: 'Ups!',
        content: errorUpdateCompanyProfile.response?.data?.message
      })
    }
  }, [isLoadingUpdateEnterprise])

  const onFinish = (values) => {
    console.log('🚀 ~ file: EditInstitutionProfile.js ~ line 41 ~ onFinish ~ values', values)
    updateEnterprise(values)
  }

  const handleErrors = () => {
    openNotification('Incomplete data', 'Plese, fill all the data')
  }

  if (!initValues) {
    return <LottieCargando />
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      footer={
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button
            onClick={() => window.history.back()}
            className='byp-link'
          >
            Return
          </Button>
          <Button
            className='byp-btn-blue-200'
            size='large'
            onClick={() => form.submit()}
            loading={loadingProfile || isLoadingUpdateEnterprise}
            disabled={loadingProfile}
          >Update
          </Button>
        </div>
      }
    >
      <div style={{ margin: '18px 5%' }}>
        {context}

        <h3 style={{ paddingTop: '40px' }}> Edit your School profile </h3>

        <p>
          Let's get started, enter your institution's data so that you have the opportunity to offer
          the right information to your agencies, as well as customize quotes and searches
        </p>

        <Form
          form={form}
          layout='vertical'
          scrollToFirstError
          name='UpdateAgency'
          onFinish={onFinish}
          initialValues={initValues}
          onFinishFailed={handleErrors}
          style={{ marginTop: '30px' }}
          requiredMark={false}
        >
          <InstitutionProfileFieldsContainer form={form} mode='update' />
        </Form>
      </div>
    </StepBaseTemplate>
  )
}

export { EditInstitutionProfile }
