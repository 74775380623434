import { LinkOutlined } from '@ant-design/icons'
import { Button, Modal, Input } from 'antd'
import { useState } from 'react'
import { IconBookBookmark } from '../../../common/assets/svg/icons'
import GoogleTranslateButton from '../../home/components/GoogleTranslateButton'
// import { MenuLanguages } from '../../../languageConfiguration/MenuLanguagues'

function Section2HeaderClientQuotes ({ studentId }) {
  const navegadorInfo = navigator.userAgent
  const navegador = navegadorInfo.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  function PasteText () {
    navigator.clipboard.writeText(window.location?.href + '/public')
  }

  return (
    <div class='header-section2'>
      <Modal open={isModalOpen} visible={isModalOpen} footer='' onCancel={handleCancel}>
        <p style={{ color: 'var(--blue-200)', fontWeight: '700' }}>Share quote's list</p>
        <p style={{ fontSize: '24px', fontWeight: '550' }}>All quotes</p>
        <p><></></p>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <LinkOutlined style={{ fontSize: '20px' }} />
          <Input
            type='text' value={window.location?.href + '/public'}
            suffix={
              <Button onClick={PasteText} style={{ color: 'var(--blue-100)', border: 'none', marginRight: '-10px' }}>COPY</Button>
            }
          />
        </div>
      </Modal>
      <Button style={{ borderColor: 'var(--blue-100)', borderWidth: '1px', color: 'var(--blue-100)', marginTop: '-8px' }} onClick={showModal}> Share page</Button>
      <div className='client-page-component'>
        <IconBookBookmark color='var(--blue-200)' />
        Client {studentId} quotes
      </div>
      {/* <MenuLanguages i18n={i18n} language={language} setLanguage={setLanguage} /> */}
      {/* {navegador[1] !== 'Safari' && <GoogleTranslateButton className='google_translate_container-quote' />} */}
    </div>
  )
}
export { Section2HeaderClientQuotes }
