import { Button, Col, Form, Input, InputNumber, Row, Switch } from 'antd'
import { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { SelectTimeFrequencyFormItem } from '../../../../common/components/helper-form/SelectTimeFrequencyFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { PrefixLabelSelector } from './PrefixLabelFormItem'

const LodgingExtraCostsFormItem = ({ name, cleanName, childName, divisa = 'USD', form, program, showMandatory }) => {
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />
  const arrayFees = cleanName && childName ? childName[cleanName] : null

  const [frequencies, setFrequencies] = useState({ all: true })
  useEffect(() => {
    if (program === 'graduate' || program === 'academic_years') {
      setFrequencies({ unic: true, semester: true, year: true, week: true })
    } else {
      setFrequencies({ all: true })
    }
  }, [program])

  return (
    <Form.List
      name={name}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Row key={key} gutter={16}>
              <Col span={8}>
                <Form.Item
                  name={[name, 'name']}
                >
                  <Input
                    className=''
                    placeholder='Concept'
                    readOnly={arrayFees ? arrayFees[name]?.name === 'Accommodation Fee' : null}
                  />
                </Form.Item>
              </Col>
              <Col span={6} style={{ width: '100%' }}>
                <Col>
                  <Form.Item
                    name={[name, 'cost']}
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <InputNumber placeholder='00.00' addonBefore={prefixSelector} min={0} controls={false} />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={10}>
                <SelectTimeFrequencyFormItem name={[name, 'frequency']} frequencies={frequencies} size='small' />
              </Col>
              {showMandatory && (
                <Col span={12}>
                  <Row gutter={16}>
                    <Col>
                      <Form.Item
                        name={[name, 'mandatory']}
                        label='Mandatory'
                      >
                        <Switch
                          checkedChildren='YES'
                          unCheckedChildren='NO'
                          checked={arrayFees[name]?.mandatory}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <Row>
                  <AddAndRemoveListButtons
                    fields={fields}
                    index={index}
                    add={add}
                    remove={remove}
                    name={name}
                    // notDelete={arrayFees[name]?.name === 'Accommodation Fee'}
                  />
                </Row>
              </Col>
            </Row>
          ))}
          {
          fields.length === 0 && (
            <Form.Item>
              <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          )
          }
        </>
      )}
    </Form.List>
  )
}

export { LodgingExtraCostsFormItem }
