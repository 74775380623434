import {
  // parseISO,
  format,
  isDate,
  parseISO
} from 'date-fns'
import { enUS } from 'date-fns/locale'

const formatStringDate = (dateString, formatDate, locale = enUS) => {
  try {
    if (isDate(dateString)) {
      return format(dateString, formatDate, { locale })
    }
    if (!dateString) return null

    // Try to parseISO
    let parsed
    parsed = parseISO(dateString)
    if (isNaN(parsed.getTime())) parsed = dateString

    const date = new Date(parsed)
    return format(date, formatDate, { locale })
  } catch (error) {
    console.log(error)
    return dateString
  }
}

const convertMxToUsDateFormat = (dateString) => {
  const splits = dateString.split('-')
  const day = splits[0]
  const mont = splits[1]
  const year = splits[2]

  const usFormat = mont + '-' + day + '-' + year
  console.log('usFormat', usFormat)
  return usFormat
}

export { formatStringDate, convertMxToUsDateFormat }
