import { Col, DatePicker, Form, InputNumber, Row, Tag } from 'antd'
import { useQuoter } from '../../../providers/quoter/quoter-course-context'
import { RowQuoter } from './RowQuoter'
import moment from 'moment'

import 'moment/locale/es-mx'

function SelectLodgingStartAndDuration ({ program }) {
  const { service, formConfig, setFormConfig } = useQuoter()
  const form = Form.useFormInstance()
  const startEventSelected = Form.useWatch('startEvent', form)

  const disabledDate = (current) => {
    // If enable all dates variable, only disable past dates
    const enableAll = formConfig.enableAllDates ?? false
    if (enableAll) {
      const getDayStartLodgingH = service.getDayStartLodging()
      const dayH = moment(current).isoWeekday()
      const disabledH = dayH !== getDayStartLodgingH
      return disabledH || (current < moment().endOf('day'))
    }

    // Disable custom dates
    const startDate = moment(startEventSelected)
    const day = moment(current).isoWeekday()
    const getDayStartLodging = service.getDayStartLodging()
    const disabled = day !== getDayStartLodging
    return disabled || (current.diff(startDate, 'days') >= 0 || current.diff(startDate, 'days') < -7)
  }

  const handleOnChangeDuration = (nWeeks) => {
    setFormConfig({
      ...formConfig,
      lodgingWeeks: nWeeks
    })
    // lodgingExtraStart: service.getLodgingEnd()
  }

  return (
    <>
      {
        service.includeLodging() && (
          <RowQuoter
            colA={
              <Row gutter={12} style={{ paddingTop: '20px' }}>
                <Col xs={8} md={9} style={{ paddingTop: '30px !important' }}>
                  <Form.Item
                    label='Start'
                    name='lodgingStart' rules={[{
                      required: true,
                      message: 'Start date required'
                    }]}
                  >
                    <DatePicker
                      disabledDate={program !== 'graduate' ? disabledDate : null}
                      onChange={(start) => {
                        setFormConfig({
                          ...formConfig,
                          lodgingStart: start
                        })
                      }}
                      format='DD MMMM, YYYY'
                    />
                  </Form.Item>
                  <p style={{ marginBottom: '0', marginTop: '.5rem' }}><i>End: {service.getLodgingEndString()}</i></p>
                </Col>
                <Col xs={8} md={9} style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Form.Item
                    name='lodgingWeeks'
                    rules={[{
                      required: true,
                      message: 'This field is required'
                    }]}
                    label='Duration'
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder='1'
                      min={0}
                      onChange={handleOnChangeDuration}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} md={6} style={{ display: 'flex',alignItems: 'flex-start' }}>
                  <Tag style={{ padding: '.44rem' }}>Weeks</Tag>
                </Col>
              </Row>
            }
            // colB={
            //   service.getLodgingIsHighSeason() && <b>Temporada alta</b>
            // }
          />
        )
      }
    </>
  )
}

export { SelectLodgingStartAndDuration }
