import React, { useState, useEffect, useMemo } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useSearch } from '../../../../providers/search/search-context-provider'
import debounce from 'lodash/debounce'
import { useGetPlaces } from '../../../../bussines/hooks/quoter/useGetPlaces'
import { CloseOutlined } from '@ant-design/icons'
import { SelectStudyAreasByProgramFormItem } from '../../../../common/components/helper-form/SelectStudyAreasByProgramFormItem'
import { SelectTypeOfInstitutionsFormItem } from '../../../../common/components/helper-form/SelectTypeOfInstitutionsFormItem'
import { SearchCourseDuration } from '../../../../common/components/quoter-search/SearchCourseDuration'

const { Option } = Select

const HighEducationForm = ({ type, subType }) => {
  const { changeSearch } = useSearch()
  // const { data } = useGetCoursesByFilter({
  //   program: 'graduate',
  //   type,
  //   subtype: subType ?? null
  // })
  const [searchFilter, setSearchFilter] = useState({
    program: 'graduate',
    type,
    subtype: subType ?? null
  })
  const [allPlaces, setAllPlaces] = useState([])
  const [form] = Form.useForm()
  // const { isLoading: isLoadingLanguage } = useGetLanguages()
  const { data: places, isFetching } = useGetPlaces(
    searchFilter?.searchPlace && {
      search: searchFilter?.searchPlace ?? '',
      lang: searchFilter?.lang ?? '',
      program: 'graduate',
      type: type ?? null,
      subtype: subType ?? null
    }
  )

  const handleOnSubmitSearch = () => {
    const countFilter = Object.keys(searchFilter).length
    if (isNaN(searchFilter?.sede)) {
      delete searchFilter.sede
    }
    if (countFilter) {
      changeSearch('LANG_FORM', {
        ...searchFilter
      })
    }
  }

  const handleClearFilters = () => {
    const nFilter = {
      program: 'graduate',
      type,
      subtype: subType ?? null
    }
    setSearchFilter(nFilter)
    form.resetFields()
    changeSearch('LANG_FORM', {
      ...nFilter
    })
  }

  const debounceFilter = useMemo(() => {
    return debounce((key, value) => {
      const nFilter = {
        ...searchFilter,
        [key]: value
      }
      setSearchFilter(nFilter)
    }, 500)
  })

  const handleAddFilter = (key, value) => {
    const nFilter = {
      ...searchFilter,
      [key]: value
    }
    if (key === 'place' && typeof value === 'string') {
      const place = value.split(':')
      nFilter.place = place[0]
      nFilter.place_type = place[1]
    } else if (key === 'place') {
      nFilter.place = 'undefined'
      nFilter.place_type = 'undefined'
    }
    if (key === 'duration') {
      const duration = value?.split(':')
      if (duration) {
        nFilter.duration = Number(duration[0])
        nFilter.time = duration[1]
      } else {
        nFilter.duration = undefined
        nFilter.time = undefined
      }
    }
    if (key === 'type') {
      nFilter.type = value
      nFilter.place_type = 'type'
    }
    setSearchFilter(nFilter)
  }

  useEffect(() => {
    if (places) {
      const formatedCampus = places.campus?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'campus'
        }
      })
      const formatedCountries = places.countries?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'country'
        }
      })
      const formatedInstitutions = places.institutions?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'institution'
        }
      })
      const formatedCities = places.cities?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          type: 'city'
        }
      })
      setAllPlaces([
        ...formatedCampus,
        ...formatedCountries,
        ...formatedInstitutions,
        ...formatedCities
      ])
    }
  }, [places])

  return (
    <Form form={form}>
      <Input.Group compact className='high-education-form-responsive'>
        {type === 'diploma' && (
          <Form.Item noStyle name='subtype'>
            <Select
              className='select-index'
              filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
              placeholder='Type'
              style={{ width: '10%' }}
              size='large'
              allowClear
              loading={isFetching}
              onChange={(e) => {
                handleAddFilter('subtype', e)
              }}
            >
              <Option key='certificate' value='certificate'>Certificate</Option>
              <Option key='diploma' value='diploma'>Diploma</Option>
              <Option key='advanced_diploma' value='advanced_diploma'>Advanced diploma</Option>
            </Select>
          </Form.Item>
        )}
        <SelectStudyAreasByProgramFormItem
          className='select-index'
          name='area'
          noStyle
          onChange={(e) => {
            handleAddFilter('area', e)
          }}
          showSearch
          placeholder='Area'
          style={{ width: type === 'diploma' ? '15%' : '20%' }}
          size='large'
          allowClear
          filters={{ program: 'graduate', subtype: subType }}
        />
        <Form.Item noStyle name='place'>
          <Select
            className='select-index'
            showSearch
            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
            onSearch={(search) => {
              debounceFilter('searchPlace', search)
            }}
            placeholder='Location (search by city, country or school)'
            style={{ width: type === 'diploma' ? '15%' : '20%' }}
            size='large'
            allowClear
            loading={isFetching}
            onChange={(e) => {
              handleAddFilter('place', e)
            }}
          >
            {allPlaces?.map((item) => <Option key={item.type + item.value} value={item.value + ':' + item.type}>{item.label}</Option>)}
          </Select>
        </Form.Item>
        <SearchCourseDuration
          className='select-index'
          showSearch
          placeholder='Length'
          style={{ width: '16%' }}
          size='large'
          allowClear
          loading={isFetching}
          onChange={(e) => {
            handleAddFilter('duration', e)
          }}
          noStyle name='duration'
          filters={{ program: 'graduate', subtype: subType, type }}
        />
        <SelectTypeOfInstitutionsFormItem
          name='type'
          className='select-index'
          showSearch
          noStyle
          style={{ width: '17%' }}
          placeholder='Type of Institute'
          size='large'
          allowClear
          onChange={(e) => {
            handleAddFilter('type_institute', e)
          }}
          filters={{ program: 'graduate', subtype: subType, type }}
        />
        <Form.Item
          name='search_name_descripcion'
          className='select-index'
          style={{ width: '17%', borderRadius: 0 }}
          onChange={(e) => {
            handleAddFilter('search_name_descripcion', e.target?.value ?? e)
          }}
          filters={{ program: 'graduate', subtype: subType }}
        >
          <Input className='select-index' style={{ borderRadius: 0 }} type='text' placeholder='Subject' />
        </Form.Item>
        <div className='button-without-border' style={{ width: '3%' }}>
          <Button icon={<CloseOutlined />} block size='large' style={{ backgroundColor: '#D4D4D4' }} onClick={handleClearFilters} />
        </div>
        <div className='button-without-border-start' style={{ width: '7%' }}>
          <Button onClick={handleOnSubmitSearch} type='primary' block size='large'>Search</Button>
        </div>
      </Input.Group>
    </Form>
  )
}

export { HighEducationForm }
