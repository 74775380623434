import React from 'react'
import { useNavigate } from 'react-router-dom'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { Button, Col, Layout, Row, Space } from 'antd'
import FormAplication from './Components/FormAplication'

const URL_APLICACION_PAGE = '/aplicacion/:id/:applicationId'
const URL_APLICACION_SHOW_PAGE = '/aplicacion/:id/:applicationId/show'

const AplicacionPage = () => {
  const navigate = useNavigate()

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <div className='byp-align-centered application-form_container'>
          <Space direction='vertical' className='space-content' size={[0, 50]}>
            <Layout style={{ background: 'transparent' }}>
              <Row>
                <Col>
                  <Button className='quote-byp__btn br-06' onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> <span className='quotation__header-text'>Volver</span>
                  </Button>
                </Col>
              </Row>
            </Layout>
            <Layout style={{ background: 'transparent' }}>
              <FormAplication />
            </Layout>
          </Space>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { AplicacionPage, URL_APLICACION_PAGE, URL_APLICACION_SHOW_PAGE }
