import { Col, Row } from 'antd'
import React from 'react'

export function TrendsGeneral({ data, dataByCompany, typeCompany }) {
  // const [cardsData, setCardsData] = useState(data)
  // useEffect(() => {
  //   if (typeCompany) {
  //     const foundData = dataByCompany?.find((dataItem) => dataItem.enterprise_type === typeCompany)
  //     if (foundData) {
  //       setCardsData(foundData.programs)
  //     }
  //   }
  // }, [typeCompany])

  function GetBackground(program) {
    switch (program) {
      case 'language':
        return {backgroundColor: '#2460B7'}
      case 'graduate':        
        return {backgroundColor: '#6495ED'}
      case 'academic_years':
        return {backgroundColor: '#FFCC00'}
      case 'boarding_schools':
        return { backgroundColor: '#AC85DE'}
      default:
        return { backgroundColor: '#F765A3' }
    }
  }

  return (
    <Row gutter={[12, 12]} className='trends-general byp-mb-1'>
      {data?.map((itemData) => (
        <Col key={itemData.name} span={24}>
          {console.log('cosas de los name')}
          {console.log(itemData.name)}
          <div className='trends-general__card' style={GetBackground(itemData.name)}>
            <p className='trends-general__subtitle'>{itemData.quotes} - Quotes created</p>
            <p className='trends-general__title' style={{ wordBreak: 'keep-all' }}>{itemData.label}</p>
          </div>
        </Col>
      ))}
    </Row>
  )
}
