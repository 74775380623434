import { MainTheme } from '../../common/theme/MainTheme'
import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { InstitutionsComponents } from './InstitutionsComponents'

const URL_INSTITUTIONS_FOR_AGENCY = '/institutions-for-agency'

function InstitutionsForAgencyPage () {
  return (

    <PageProtected permissionTypes={[userRoles.TYPE_AGENCY, userRoles.TYPE_AGENCY_EDITOR]}>
      <MainTheme activeCarousel>
        <InstitutionsComponents />
      </MainTheme>
    </PageProtected>

  )
}

export { InstitutionsForAgencyPage, URL_INSTITUTIONS_FOR_AGENCY }
