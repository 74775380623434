import { useMutation } from 'react-query'
import state from './../../../utils/state-control'
import { useState } from 'react'
import CourseRepository from '../../../repositories/CourseRepository'


function usePostUnPublishCourse() {
    const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
    const mutation = useMutation(CourseRepository.unPublishCourse, {
        onSuccess: d => {
            setCurrentState(state.STATE_SUCCESS)
        },
        onError: (e) => {
            setCurrentState(state.STATE_ERROR)
        }
    })

    /**
     * nextStep
     * @param step
     * @param data
     */
    const unPublishCourse = (sede, course, year, user) => {
        mutation.mutate({ sede, course, year, user })
    }

    return { ...mutation, state, currentState, unPublishCourse }
}

export { usePostUnPublishCourse }