import { useMutation } from 'react-query'
import state from './../../../utils/state-control'
import { useState } from 'react'
import CourseRepository from '../../../repositories/CourseRepository'

function usePostPublishOffers () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(CourseRepository.publishOffers, {
    onSuccess: d => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * publishOffers
   * @param data
   */
  const publishOffers = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, state, currentState, publishOffers }
}

export { usePostPublishOffers }
