import { SelectReligionFormItem } from '../../../../common/components/helper-form/SelecReligionFormItem'
import { SelectExtracurricularFormItem } from '../../../../common/components/helper-form/SelectExtracurricularFormItem'
import { SelectGenderFormItem } from '../../../../common/components/helper-form/SelectGenderFormItem'
import { SelectSportsFormItem } from '../../../../common/components/helper-form/SelectSportsFormItem'

const HighSchoolsFields = () => {
  return (
    <>
      <SelectGenderFormItem name='gender' label='Gender' />
      <SelectReligionFormItem name='religion' label='Religion' />
      <h6 className='byp-title'>The school offers this sports: </h6>
      <SelectSportsFormItem name='sports' mode='multiple' />
      <h6 className='byp-title'>The school offers this extracurricular clases: </h6>
      <SelectExtracurricularFormItem name='extracurriculars' mode='multiple' />
    </>
  )
}

export { HighSchoolsFields }
