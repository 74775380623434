import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGetActivities } from '../../../bussines/hooks/quoter/useGetActivities'
const { Option } = Select

// Return institutions types that has offers
const SearchActivities = ({ name, className, noStyle, onChange, showAll, displayOnlyAtSearch = false, ...rest }) => {
  const [search, setSearch] = useState(false)
  const { data, isLoading, error } = useGetActivities()

  useEffect(() => {
    if (data) {
      // console.log('🚀 ~ file: SearchActivities.js:10 ~ useEffect ~ data', data)
    }
    if (!isLoading && error) {
      console.log('🚀 ~ file: SearchActivities.js:10 ~ useEffect ~ error', error)
    }
  }, [data, isLoading, error])

  return (
    <Form.Item
      name={name}
      noStyle={noStyle}
    >
      <Select
        className={className}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        size={rest?.size}
        onChange={onChange}
        placeholder={rest.placeholder ?? ''}
        {...rest}
        onSearch={(search) => {
          setSearch(!!search)
        }}
      >
        {((displayOnlyAtSearch && search) || (!displayOnlyAtSearch)) && data?.map((item) => (
          <Option key={item} value={item}>{item}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SearchActivities }
