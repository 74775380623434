import { Form, Select } from 'antd'
import React from 'react'
import { useGetCampusOfFee } from '../../../bussines/hooks/school/useGetCampusOfFee'
const { Option } = Select
function SelectSedesOfFee ({ onChange, mode, campus, fee, filter, ...props }) {
  const { isLoading, data } = useGetCampusOfFee(campus, fee, filter)
  return (
    <Form.Item {...props}>
      <Select
        style={{ width: '100%' }}
        placeholder={props.placeholder ?? ''}
        loading={isLoading}
        mode={mode}
        className='byp-m-0'
        onChange={onChange}
      >
        {data?.map(item => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectSedesOfFee }
