import React from 'react'
import { Button } from 'antd'
import { useAuth } from '../../../providers/auth/auth-context-provider'
import { useStepByStep } from '../../../providers/stepbystep/step-service-provider'

export default function FooterStep () {
  const { logout } = useAuth()
  const { step, backStep, handleOnSubmit, isLoadingPost } = useStepByStep()

  return (
    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
      <Button
        onClick={logout}
        className='byp-link'
      >
        Salir
      </Button>
      <div id='action-btns'>
        <Button
          style={{ marginRight: '1rem' }}
          size='large'
          onClick={backStep}
          disabled={step <= 1}
        >Atras
        </Button>
        <Button
          className='byp-btn-blue-200'
          size='large'
          onClick={handleOnSubmit}
          loading={isLoadingPost}
          disabled={isLoadingPost}
        >Next
        </Button>
      </div>
    </div>
  )
}
