import { Avatar, Button, Col, Modal, Row, Table, Tag, Typography, notification } from 'antd'
import { tagByRolClass } from '../../../../utils/tagStatusClass'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from '../../../../providers/validation/ErrorBoundary'
import { AllUsersFilters } from './AllUsersFilters'
import { showErrorModal } from '../../../../utils/propsForModal'
import { SelectAprovalStatusFormItem, SelectStatusFormItem, SelectValidationEmailStatus } from '../../../../common/components/helper-form/SelectStatusFormItem'
import { SelectPlanFormItem } from '../../../../common/components/helper-form/SelectPlanFormItem'
import { IconPasswordReset } from '../../../../common/assets/svg/icons'
import ResetPasswordModal from './ResetPasswordModal'
import { usePostAssignPlan } from '../../../../bussines/hooks/plan/usePostAssignPlan'
import { useGetAllCompanies } from '../../../../bussines/hooks/admin/useGetAllCompanies'
import { usePutValidateEmail } from '../../../../bussines/hooks/admin/usePutValidateEmail'
import { usePutAproveCompany } from '../../../../bussines/hooks/admin/usePutAproveCompany'
import { Tarjet } from './Tarjet'
import TotalQuotes from '../../agency/reports/components/TotalQuotes'
import moment from 'moment'
import { useForceDeleteCompany } from '../../../../bussines/hooks/admin/useForceDeleteCompany'

const { Text } = Typography

function AccountsTable ({ isDeleting, type }) {
  const [page, setPage] = useState(1)
  const [company, setCompany] = useState()
  const [modal, context] = Modal.useModal()
  const [filters, setFilters] = useState({})
  const [formSearch, setFormSearch] = useState()
  const [searchActive, setSearchActive] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [idCompany, setIdCompany] = useState(null)
  const { data, isLoading, error, refetch } = useGetAllCompanies({ page, ...filters })
  const { validateEmail, isLoading: isValidatingEmail, data: resp, stateControl, currentState, error: erroAtValidate } = usePutValidateEmail()
  const { aproveCompany, isLoading: isChanginStatus, data: statusResp, currentState: statusState, error: erroAtChangeStatus } = usePutAproveCompany()
  const { forceDeleteCompany, isLoading: isForceDeleted, data: statusRespForce, currentState: statusStateForce, error: erroAtForceDelete } = useForceDeleteCompany()
  const { assignPlan, isLoading: isAssigning, error: errorAtAssignPlan, currentState: AssignState } = usePostAssignPlan()

  useEffect(() => {
    if (!isLoading && error) {
      showErrorModal(modal)
    }
  }, [error, isLoading])
  // Effect for validate email
  useEffect(() => {
    if (!isValidatingEmail && resp && currentState === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Email validated'
      })
    } else if (!isValidatingEmail && erroAtValidate) {
      showErrorModal(modal)
    }
  }, [resp, isValidatingEmail, erroAtValidate])
  // Effect for aprove company
  useEffect(() => {
    if (!isChanginStatus && statusResp && statusState === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Status updated'
      })
    } else if (!isChanginStatus && erroAtChangeStatus) {
      showErrorModal(modal)
    }
  }, [statusResp, isChanginStatus, erroAtChangeStatus])

  useEffect(() => {
    if (!isForceDeleted && statusRespForce && statusStateForce === stateControl.STATE_SUCCESS) {
      refetch()
      notification.success({
        message: 'Company Deleted'
      })
    } else if (!isForceDeleted && erroAtForceDelete) {
      showErrorModal(modal)
    }
  }, [statusRespForce, isForceDeleted, erroAtForceDelete])
  // Effect for change plan
  useEffect(() => {
    if (!isAssigning && AssignState === stateControl.STATE_SUCCESS) {
      refetch()
    } else if (!isAssigning && errorAtAssignPlan) {
      showErrorModal(modal)
    }
  }, [AssignState, isAssigning, errorAtAssignPlan])
  // Effect for type default
  useEffect(() => {
    setFilters({
      page: 1,
      company_type: type
    })
  }, [type])

  useEffect(() => {
    console.log('🚀 ~ file: AccountsTable.js:75 ~ AccountsTable ~ filters:', filters)
  }, [filters])

  const handleTitle = (type) => {
    if (type === 'Agency') {
      return 'All Agencies'
    } else if (type === 'Institution') {
      return 'All Institutions'
    } else if (type === 'Education group') {
      return 'All Educational groups'
    }
  }

  function getRemainingDays (date) {
    const targetDate = moment(date).startOf('day')
    const now = moment().startOf('day')
    const difference = now.diff(targetDate, 'days')

    return difference >= 0 ? 30 - difference : 30
  }

  const showModal = (id) => {
    setIdCompany(id)
    setIsModalOpen(true)
  }

  const handleOk = () => {
    forceDeleteCompany({ company: idCompany, type })
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  // Columns for companies data
  const columns = [
    {
      title: 'Name',
      render: ({ id, name, profile_photo_url: url, type }) => (
        <>
          <a
            onClick={() => {
              setFilters({ company: id, company_type: type, page: 1 })
              setCompany(name ?? '')
            }}
          >
            <Avatar src={url} />
            <Text className='byp-fw-bold'> {name}</Text>
          </a>
        </>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },

    { // Plan
      title: 'Plan',
      render: ({ id, plan, type }) => (
        <SelectPlanFormItem
          style={{ minWidth: '6rem' }}
          size='small'
          noStyle
          loading={isAssigning}
          value={plan?.plan_id ?? 1}
          onChange={(plan) => assignPlan(plan, id, type)}
        />
      )
    },
    { // Payment date
      title: 'Payment date', dataIndex: 'payment_date'
    },
    { // Main Contact
      title: 'Main Contact', dataIndex: 'main_contact'
    },
    { // Email validation
      title: 'Status',
      render: ({ id, type, email_verified_at: verified, main_contact: admin }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SelectValidationEmailStatus
            className='w-100'
            noStyle
            value={verified ? 'Aproved' : 'Pending'}
            size='small'
            loading={isValidatingEmail}
            disabled={isValidatingEmail || !!verified}
            onChange={(status) => validateEmail({ company: id, type, status, user: admin })}
          />
        </div>
      )
    },
    { // Actions
      title: 'Owner user',
      render: ({ id, status, type, deleted_at: deletedAt }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <ConfirmModal onOk={() => notification.info({ message: "We're working on this :3" })} isLoading={isDeleting} id={id} /> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!deletedAt && (
              <SelectAprovalStatusFormItem
                className='w-100'
                noStyle
                style={{ minWidth: '9rem' }}
                value={status === 'Active' ? 'Aproved' : status}
                size='small'
                disabled={status === 'Pending'}
                loading={isChanginStatus}
                onChange={(status) => {
                  aproveCompany({ company: id, type, status })
                }}
              />
            )}
            {deletedAt && (
              <Button style={{ padding: '0 3rem' }} onClick={e => aproveCompany({ company: id, type, status: 'Recover' })}>
                Recover
              </Button>
            )}
          </div>
        </div>
      )
    },
    { // Actions
      title: 'Remaining days',
      render: (item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {item.deleted_at && (
            <div style={{ display: 'flex', alignItems: 'center', color: '#E42A36', fontSize: '1rem' }} onClick={e => showModal(item.id)}>
              {getRemainingDays(item.deleted_at)} days
            </div>
          )}
        </div>
      )
    }
  ]
  // Column for users data
  const userColumns = [
    {
      title: 'Name',
      render: ({ id, name, profile_photo_url: url }) => (
        <>
          <Avatar src={url} />
          <Text className='byp-fw-bold'> {name}</Text>
        </>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },

    {
      title: 'Role',
      render: ({ role }) => (
        <Tag className={tagByRolClass(role)} style={{ textTransform: 'uppercase', fontSize: '.6rem' }}>
          {/* {(role.includes('Administrator') || role.includes('Admin')) && 'Admin'}
          {(!role.includes('Administrator') && !role.includes('Admin')) && role} */}
          {role}
        </Tag>
      )
    },
    // {
    //   title: 'Plan',
    //   render: ({ company }) => (
    //     <SelectPlanFormItem
    //       style={{ minWidth: '6rem' }}
    //       size='small'
    //       noStyle
    //       loading={isAssigning}
    //       value={company?.plan?.plan_id ?? 1}
    //       onChange={(plan) => assignPlan(plan, company?.id, company?.type)}
    //     />
    //   )
    // },
    { // Reset Password
      title: 'Password',
      render: ({ email }) => (
        <ResetPasswordModal
          email={email}
          label='Reset password'
          className='byp-blue-100 byp-btn-sm byp-bg-white byp-fw-bold d-flex align-center all-users__confirm'
          type='link'
          icon={<IconPasswordReset />}
        />
      )
    },
    { // Validated email
      title: 'Validated email',
      render: ({ email_verified_at: verified, company, id }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SelectValidationEmailStatus
            className='w-100'
            noStyle
            value={verified ? 'Aproved' : 'Pending'}
            size='small'
            loading={isValidatingEmail}
            disabled={isValidatingEmail || !!verified}
            onChange={(status) => validateEmail({ company: company?.id, type: company?.type, status, user: id })}
          />
        </div>
      )
    },
    {
      title: 'Actions',
      render: ({ id, status, company }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ConfirmModal onOk={() => notification.info({ message: "We're working on this :3" })} isLoading={isDeleting} id={id} />
          {filters.company && (
            <SelectStatusFormItem
              className='w-100'
              noStyle
              value={status}
              size='small'
              loading={isValidatingEmail}
              disabled
              // onChange={(status) => aproveCompany({ company: company?.id, type: company?.type, status })}
            />
          )}
        </div>
      )
    }
  ]

  return (
    <ErrorBoundary>
      <Modal title='' visible={isModalOpen} onOk={e => handleOk()} onCancel={handleCancel}>
        Are you sure you want to remove it?
      </Modal>
      {filters.company &&
        <Row>
          <Col className='d-flex' style={{ gap: '1rem' }}>
            <Button
              onClick={() => {
                setFilters({
                  page: 1,
                  company_type: type
                })
                formSearch.resetFields()
                setSearchActive(false)
              }}
              className='byp-btn-blue-200 byp-mb-1'
            >Go back
            </Button>
            <h3 className='byp-title'>All users from: {filters.company && company}</h3>
          </Col>
        </Row>}
      {!filters.company && <h3 className='byp-title byp-mb-1'>{handleTitle(type)}</h3>}
      {filters.company && <Tarjet type={type} company={filters.company} searchFilter={filters} />}
      {filters.company &&
        (
          <div className='byp-mb-1'>
            <TotalQuotes noClick filters={{ company: filters.company, company_type: type, country: filters.country, city: filters.city, user: filters.name, status: filters.status_type }} />
          </div>
        )}
      <AllUsersFilters setSearchFilter={setFilters} searchFilter={filters} setPage={setPage} type={type} company={company} setFormSearch={setFormSearch} setSearchActive={setSearchActive} searchActive={searchActive} />
      {context}
      <Table
        columns={filters.company ? userColumns : columns}
        rowKey={(record) => `${record.id}${record.type}`}
        dataSource={data?.data ?? []}
        className='byp-table-dashboard'
        loading={isLoading}
        pagination={{
          page: data?.meta?.currentPage,
          total: data?.meta?.total,
          pageSize: data?.meta?.per_page,
          size: data?.meta?.last_page,
          current: data?.meta?.current_page,
          onChange: (val) => {
            setFilters({ ...filters, page: val })
          }
        }}
      />
    </ErrorBoundary>
  )
}

export { AccountsTable }
