import { Checkbox, Button, Form, Modal } from 'antd'
import { SelectUsersFormItem } from '../../../common/components/helper-form/SelectUsersFormItem'
import { useState } from 'react'




const SelectUserModal = ({ mdl, setMdl, clsMdl, handleSubmit, validUsers, form, isLoading }) => {

  const [user, setUser] = useState('user')
  
  const onChange = (value) => {

      setUser(validUsers[0].name)
  }

  return (
    

    <>
      <Modal
        title='Inactivate user'
        visible={mdl}
        footer=''
        onCancel={clsMdl}
        className='apply-modal'
      >
      
          
          <h4 >Choose a new user</h4 >
          
          <p >User:</p >
        <Form form={form} layout='vertical' requiredMark={false} onFinish={handleSubmit}>

          <SelectUsersFormItem 
          placeholder='-find user-'
          data={validUsers} name='user'  
          onChange = {onChange}/>




          <Form.Item name="remember" valuePropName="checked" 
                rules={[
                  {
                      validator: async (_, checked) => {
                          if (!checked) {
                              return Promise.reject(
                                  new Error("This element is required"),
                              );
                          }
                      },
                  },
              
                 ]}
        
          >
            <Checkbox className='checkbox-apply' >{'Do you agree that the new user now will have access to all the information of '+user} </Checkbox>
          </Form.Item>

          <Button
            className='byp-btn-blue-100'
            htmlType='submit'
            loading={isLoading}
            style={{width: '100%'}}
            // disabled={isLoading}
          >

            Send information
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export {
  SelectUserModal
}
