import { useQuery } from 'react-query'
import { NationalityRepository } from '../../repositories/NationalityRepository'

function useGetSubregionsByRegion (region) {
  return useQuery(['useGetSubregionsByRegion', region],
    () => NationalityRepository.subregionsByRegion(region), {
      retry: 2,
      enabled: !!region
    })
}

function useGetSubregionsByRegions (regions) {
  return useQuery(['useGetSubregionsByRegions', regions],
    () => NationalityRepository.subregionsByRegions(regions), {
      retry: 2,
      enabled: !!regions
    })
}

export { useGetSubregionsByRegion, useGetSubregionsByRegions }
