import React from 'react'
import { Card, Typography, Button, Space, Avatar } from 'antd'
import { AntDesignOutlined } from '@ant-design/icons'
import { EmailIcon, WhatsapIcon } from '../assets/imgs/Icons'

const { Title } = Typography

export const QuoteAgent = ({ agent, className }) => {
  return (
    <Card bordered={false} className={className}>
      <Space direction='vertical' size='small' style={{ display: 'flex' }} align='center'>
        <Avatar
          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
          icon={<AntDesignOutlined />}
          src={agent.profilePhotoUrl}
        />
        <Title level={5}>Attended by</Title>
        <div style={{ textAlign: 'center' }}>
          <Title level={2} className='byp-color-blue-200' style={{ marginBottom: '0' }}>{agent.name}</Title>
          <span>{agent.sede?.name}</span>
        </div>
        <Space size='middle' align='center'>
          <a className='social-media' href={`mailto: ${agent.email}`}>
            <EmailIcon />
          </a>
          <a className='social-media' href={`whatsapp://send?abid=${agent.whatsapp}&text=Hello!`}>
            <WhatsapIcon />
          </a>
        </Space>

        <Button className='byp-btn-blue-100-outline'>
          <a href={agent.calendly} target='_blank' rel='noopener noreferrer'>
            Schedule an appointment
          </a>
        </Button>
      </Space>
    </Card>
  )
}
