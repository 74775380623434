import { useEffect, useState } from 'react'
import '../../../../common/assets/css/main-theme.less'
import logo from '../../../../common/assets/images/logo.png'

function PDFQuotePreview ({ gallery, cityGallery, headerFooter }) {
  const [carrouselGallery, setCarrouselGallery] = useState([])
  const [carrouselImg, setCarrouselImg] = useState([])
  const [isPrincipal, setIsPrincipal] = useState(true)
  const [imgIndex, setImgIndex] = useState(0)

  useEffect(() => {
    setCarrouselGallery(gallery)
    setCarrouselImg(cityGallery)
  }, [gallery, cityGallery])

  const putImg = (index) => {
    setIsPrincipal(false)
    setImgIndex(index)
  }

  return (
    <div className='preview-pdf-main'>
      <p>Customization - PDF</p>
      <div className='pdf-preview-container'>
        <div
          className='pdf-preview-area'
          style={{ backgroundImage: `url(${isPrincipal ? carrouselGallery[0]?.url : carrouselImg[imgIndex]?.url})` }}
        >
          {
            carrouselGallery[0] && isPrincipal &&
              <div className='logo-preview'>
                <img src={logo} />
              </div>
          }
        </div>
      </div>
      <div className='carrousel-selection'>
        {carrouselGallery[0] && <span onClick={() => setIsPrincipal(true)} />}
        {/* {carrouselImg.map((img, index) => (
          <span key={index} onClick={() => putImg(index)} />
        ))} */}
      </div>
    </div>
  )
}

export { PDFQuotePreview }
