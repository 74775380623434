import { Col, Row, Form, Select } from 'antd'
import { useState } from 'react'
import { useGetShort } from '../../../../bussines/hooks/short/useFindShort'
import { useGetShortYears } from '../../../../bussines/hooks/packages/useFindPackage'
import { SelectCampusByProgramFormItem } from '../../../../common/components/helper-form/SelectCampusByProgramFormItem'
import { useLocation } from 'react-router-dom'

export function SelectPackageFormItem ({ form = null, ...props }) {
  const { Option } = Select
  const location = useLocation()
  const { campusId: campus } = location.state ?? { campusId: undefined }
  // const [packageId, setPackageId] = useState()
  const [campusId, setCampusId] = useState(campus)
  const { data: packages, isLoading } = useGetShort({ campus: campusId })
  const { data: years, isLoading: isLoadingYears } = useGetShortYears(campusId)

  const handleChange = (value) => {
    setCampusId(value)
    if (form) {
      form.resetFields([
        'package', 'year'
      ])
    }
  }
  return (
    <Row gutter={16}>
      <Col>
        <Row>
          <label style={{ marginTop: 6, marginRight: 6 }}>Campus: </label>
          <SelectCampusByProgramFormItem
            program='short_term'
            // label='School'
            labelCol={{ span: 4 }}
            labelAlign='left'
            size='small'
            name='campus'
            maxTagCount='responsive'
            style={{ width: '200px' }}
            onChange={handleChange}
          />
        </Row>
      </Col>
      <Col>
        <Row>
          <label style={{ marginTop: 6, marginRight: 6 }}>Camp name: </label>
          <Form.Item name='package'>
            {/* onChange={setPackageId} */}
            <Select className='byp-m-0' placeholder='Camp name' size='small' loading={isLoading} style={{ width: '200px' }} onChange={e => { if (form) form.resetFields(['year']) }}>
              {packages?.data?.map((packageItem) => (
                <Option key={packageItem.id} value={packageItem.id}>{packageItem.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
      </Col>
      <Col>
        <Row>
          <label style={{ marginTop: 6, marginRight: 6 }}>Year: </label>
          <Form.Item name='year'>
            <Select className='byp-m-0' placeholder='2023' size='small' style={{ width: '200px' }} loading={isLoadingYears}>
              {years?.map((year) => (
                <Option key={year.id} value={year.id}>{year.year}</Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
      </Col>
      {/* <Col>
        <Row>
          <label style={{ marginTop: 6, marginRight: 6 }}>Currency: </label>
          <Form.Item name='currency'>
            <Select className='byp-m-0' placeholder='Currency' size='small' disabled>
              <Option value='CAD'>(CAD) Canadian</Option>
            </Select>
          </Form.Item>
        </Row>
      </Col> */}
    </Row>
  )
}
