import React from 'react'
import { Typography } from 'antd'
const { Text } = Typography

const config = (message = 'Please try again, if this error persiste, contact us at admin@quoteyourprogram.com') => ({
  title: 'Something went wrong!',
  content: (
    <Text className='w-100'>
      {message}
    </Text>
  )
})

const showErrorModal = (modal, message = null) => {
  modal.error(config(message))
}

export {
  config,
  showErrorModal
}
