import { Modal, Switch, Table } from 'antd'
import React, { useEffect } from 'react'
import { useGetDataCountryCities, usePutSavePage } from '../../../../bussines/hooks/tables-page/useGetDataTablePage'
import { Link } from 'react-router-dom'
import { URL_EDIT_PAGE_COUNTRY_PAGE, URL_EDIT_PAGE_CITY_PAGE } from '../../edit-page/EditPageCountryPage'

function TableCountryCities () {
  const { data, refetch } = useGetDataCountryCities()
  const [modal, contextHolder] = Modal.useModal()
  const { updatePage, isLoading: isLoadingUpdate, error: errorUpdate, data: dataUpdate } = usePutSavePage()

  const handleDateFormat = (date) => {
    const fechaHora = new Date(date)

    const dia = fechaHora.getUTCDate()
    const mes = fechaHora.getUTCMonth() + 1
    const año = fechaHora.getUTCFullYear()
    const horas = fechaHora.getUTCHours()
    const minutos = fechaHora.getUTCMinutes()

    const fechaFormateada = `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${año} a las ${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`

    return fechaFormateada
  }

  useEffect(() => {
    if (!isLoadingUpdate && dataUpdate) {
      modal.success({
        title: 'Succes',
        content: 'Data saved succesfully'
      })
    }
    if (!isLoadingUpdate && errorUpdate) {
      modal.error({
        title: 'Something went wrong',
        content: errorUpdate.response?.data?.message
      })
    }
  }, [isLoadingUpdate, dataUpdate, errorUpdate])

  const handleLink = (record) => {
    let link
    if (record.iso2) {
      link = URL_EDIT_PAGE_COUNTRY_PAGE.replace(':code', record.iso2)
    } else {
      link = URL_EDIT_PAGE_CITY_PAGE.replace(':code', record.country.iso2)
      link = link.replace(':city', record.id)
    }
    return link
  }

  const handleStatus = (status) => {
    let res
    if (status === 'Published') {
      res = 'Publicado'
    } else if (status === 'Deleted') {
      res = 'Borrado'
    }
    return res
  }

  const onChange = (checked, pageId) => {
    if (!pageId) {
      modal.error({
        title: 'Not page found'
      })
    } else {
      const status = checked ? 'Published' : 'Deleted'
      updatePage({
        page: pageId,
        status
      })
      refetch()
    }
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <a className='table__link'>
          <Link to={handleLink(record)}>{record.name}</Link>
        </a>
      )
    },
    // {
    //   title: 'Description',
    //   // dataIndex: 'age',
    //   key: 'description',
    //   render: (_, record) => (
    //     <span>{record?.page?.body ? record?.page?.body : '-'}</span>
    //   )
    // },
    // {
    //   title: 'Estatus de fotos',
    //   // dataIndex: 'points',
    //   key: 'fotos',
    //   render: (_, record) => (
    //     <span>{record?.page?.images?.length > 0 ? record?.page?.images?.length : '-'}</span>
    //   )
    // },
    {
      title: 'Estado',
      key: 'tags',
      render: (_, record) => (
        // <span className={record?.status}>{record?.status}</span>
        <span className={record?.page?.status ? record?.page?.status + '-table' : 'pendiente-table'}>{record?.page?.status ? handleStatus(record?.page?.status) : 'Pendiente'}</span>
      )
    },
    {
      title: 'Fecha',
      // dataIndex: 'points',
      key: 'fecha',
      render: (_, record) => (
        <span>{record?.page?.updated_at ? handleDateFormat(record?.page?.updated_at) : '-'}</span>
      )
    },
    {
      title: 'Activar',
      key: 'activar',
      render: (_, record) => (
        <Switch onChange={e => onChange(e, record?.page?.id)} checked={record?.page?.status === 'Published'} />
      )
    }
  ]
  return (
    <>
      {contextHolder}
      <h3 className='byp-title'>Paises</h3>
      <Table
        columns={columns}
        // dataSource={data}
        dataSource={data?.map((item, index) => ({ ...item, key: index }))}
        size='small'
        className='table-pages'
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'even-row' : 'odd-row'}
        expandable={{
          expandedRowRender: (record, index) => (
            <>
              {record?.ciudades && (
                <Table
                  key={index}
                  columns={columns}
                  dataSource={record.ciudades}
                  pagination={false}
                  showHeader={false}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'even-row' : 'odd-row'}
                  scroll={{ x: 'max-content' }}
                />
              )}
            </>
          )
          // onExpand: (_, record) => handleExpand(record)
        }}
      />
    </>
  )
}

export { TableCountryCities }
