import { Form, Select } from 'antd'
import React from 'react'
import { useGetAllServiceCategories } from '../../../bussines/hooks/school/useGetAllServiceCategories'

const { Option } = Select

// This allows the user to choose one of the programs from the dabase
// If you want choose the programs and the subtypes as programs, use the component SelectProgramsFormItem
function SelectServicesCategoriesFormItem ({ permitedIds = [], showSearch, loading, isListField, fieldKey, onChange, ...props }) {
  const { isLoading, data } = useGetAllServiceCategories()
  const handleOnChange = (val) => {
    const { onChange, setProgramId, setProgramName } = props
    if (onChange) onChange(val)
    if (setProgramId) setProgramId(val)
    if (setProgramName) {
      const found = data.find((item) => item.id === val)
      setProgramName(found.name)
    }
  }
  return (
    <Form.Item {...props}>
      <Select
        {...props}
        loading={isLoading}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        placeholder={props.placeholder ?? ''}
        onChange={handleOnChange}
      >
        {data?.map(item => (
          <Option disabled={permitedIds.length && !permitedIds.includes(item.id)} key={item.id} value={item.id}>{`${item.label}`}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectServicesCategoriesFormItem }
