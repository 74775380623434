import { Table } from "antd"
import Column from "antd/lib/table/Column"
import { useGetDiscounts } from "../../../bussines/hooks/school/discounts/useGetDiscounts"
import { useEffect, useState } from "react"
import { useAuth } from "../../../providers/auth/auth-context-provider"


function TableDiscountsPackages({discounts, isLoading,  refetch}) {
    const [page, setPage] = useState(1)
    const { user: _user } = useAuth()

    const handleLabelExpireDate = (date, status, style) => {
        if (date) {
            const fechaCaducidad = new Date(date)
            const fechaActual = new Date()

            if (fechaActual > fechaCaducidad)
                return style ? 'toaprove' : 'TO APROVE'

            return status
        }
    }

    return (
        <div>
            <Table
                className="byp-table-dashboard"
                dataSource={discounts}
                refetch={refetch}
            >
                <Column
                    title='Name'
                    render={(index) => {
                        return (
                            <div>{index.name}</div>
                        )
                    }}
                />

                <Column
                    title='Office'
                    render={(record) => {
                        return (
                            <div style={{ maxHeight: '6rem', overflow: 'auto' }}>
                                {record.branches? (record.branches.map((item) => (
                                    <div key={item.id}>{item.name}</div>
                                ))):
                                (record.sedes.map((item) => (
                                    <div key={item.id}>{item.name}</div>
                                )))
                                }
                            </div>)
                    }}
                />
                <Column
                    title='Status'
                    render={(record) => {
                        return (
                            <div className={'discount-status discount-status__' + handleLabelExpireDate(record?.end, record?.status, true)}>
                                {handleLabelExpireDate(record?.end, record?.status?.toUpperCase())}
                            </div>
                        )
                    }}
                />
                <Column
                    title='Expiration date'
                    render={(record) => {
                        return (
                            <div>
                                {record.end}
                            </div>
                        )
                    }}
                />
            </Table>
        </div>
    )
}
export { TableDiscountsPackages }