import React from 'react'
import { useParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import TopBarMenu from '../components/TopBarMenu'
import { FormCreateTransport } from './FormCreateTransport'

const URL_UPLOAD_TRANSPORTS_PAGE = '/dashboard/upload/transports/:program'

function UploadTransportsPage () {
  const { program } = useParams()
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <TopBarMenu program={program} key={'top_menu_' + program} />
        <FormCreateTransport program={program} />
      </MainTheme>
    </PageProtected>
  )
}

export { UploadTransportsPage, URL_UPLOAD_TRANSPORTS_PAGE }
