import React, { useEffect, useState } from 'react'
import { ClientPageComponent } from './components/ClientPageComponent'
import { useParams } from 'react-router-dom'
import { decrypt } from '../../bussines/utils/basicEncript'

const URL_CLIENT_PAGE = '/client_quotes/:id/:agent/public'

function ClientPage () {
  const { id: cryptStudentId, agent: cryptAgentId } = useParams()
  const [studentId, setStudentId] = useState()
  const [agentId, setAgentId] = useState()

  useEffect(() => {
    if (cryptStudentId) {
      setStudentId(decrypt('qyp2023', cryptStudentId))
    }
    if (cryptAgentId) {
      setAgentId(decrypt('qyp2023', cryptAgentId))
    }
  }, [cryptStudentId, cryptAgentId])

  return (
    <ClientPageComponent id={studentId} agent={agentId} />
  )
}

export { ClientPage, URL_CLIENT_PAGE }
