import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetAgenExchangeValues (agentId) {
  return useQuery(['useGetAgenExchangeValues', agentId], () => AgencyRepository.getAgentExchangeValues(agentId), {
    retry: 2,
    enabled: !!agentId
  })
}
export { useGetAgenExchangeValues }
