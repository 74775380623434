import { useLocation } from 'react-router-dom'
import './../../../common/assets/css/google-select-theme.less'
import React, { useEffect } from 'react'

const googleTranslateElementInit = () => {
  window.google.translate.TranslateElement({
    pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL
  }, 'google_translate_element')
}

let scriptAdded = false
let locationGlobal = ''
const GoogleTranslateButton = ({ className }) => {
  // const [readyToAdd, setReadyToAdd] = useState(false)
  // const [count, setCount] = useState(0)
  const location = useLocation()
  useEffect(() => {
    if (!scriptAdded || location.pathname !== locationGlobal) {
      locationGlobal = location.pathname
      const addScript = document.createElement('script')
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      )
      document.body.appendChild(addScript)
      window.googleTranslateElementInit = googleTranslateElementInit
      scriptAdded = true // Actualiza la variable global para indicar que el script se ha agregado
    }
  }, [])

  useEffect(() => {
    const observer = new MutationObserver ((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const selectElement = document.querySelector(
            '#google_translate_element select'
          )
          if (selectElement) {
            const options = selectElement.options
            for (let i = 0; i < 1; i++) {
              if (options[i] ?? false) {
                options[i].textContent = 'Translate'
              }
            }
          }
        }
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    return () => {
      observer.disconnect()
    }
  }, [])
  // useEffect(() => {
  //   // in some cases, the google translate script adds a style to the opening html tag.
  //   // this added style disables scrolling.
  //   // the next 3 lines removes this added style in order to re-enable scrolling.
  //   if (window.document.scrollingElement.hasAttribute('style')) {
  //     window.document.scrollingElement.setAttribute('style', '')
  //   }
  // }, [])
  return (
    <div id='google_translate_element' className={className} />
    // <div>
    //   <label className={className}>Translate</label>
    // </div>
  )
}

export default GoogleTranslateButton
