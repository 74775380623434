import React from 'react'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { TopBarMenuCustom } from '../components/TopBarMenuCustom'
const URL_UPLOAD_PRICES_PACKAGES_PAGE = '/dashboard/institution/create/package/price'
// TODO: DELETE THIS
function CreatePricesPackagePage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >

        <TopBarMenuCustom />
      </MainTheme>
    </PageProtected>
  )
}

export { CreatePricesPackagePage, URL_UPLOAD_PRICES_PACKAGES_PAGE }
