import { Button, Table } from 'antd'
import ConfirmModal from '../../../../common/components/ConfirmModal'
import { EditIcon } from '../../../../common/theme/icons/EditIcon'
import { ServicesTableFilter } from './Filters/ServicesTableFilter'
import { useEffect, useState } from 'react'
import LottieCargando from '../../../../lotties/LottieCargando'


const { Column } = Table
function TableServices ({ services, setId, isLoading, deleteFee, canDelete }) {

  const [service, setServices] = useState(services)

  useEffect(() => {
    if (services) {
      setServices(services)
    }
  }, [services])

  if(isLoading){
    return <LottieCargando></LottieCargando>
  }
  return (
    <div>
    <ServicesTableFilter  services={services} setServices={setServices} />
      <Table
        loading={isLoading}
        dataSource={service}
        className='byp-table-dashboard'
        title={() => 'Added services'}
        rowKey='id'
        style={{ paddingLeft:'12px' }}
      >
        <Column
          title='Name' dataIndex='name' key='name'
          render={(_, record) => (
            <a>{record.name}</a>
          )}
        />
        <Column
          title='Program' dataIndex='program' key='program'
        />
        <Column
          title='Office'
          dataIndex='offices'
          key='offices'
          responsive={['lg']}
          render={(_, record) => (
            record.offices?.map((item) => (
              <li key={item.id}>{item.name}</li>
            ))
          )}
        />

        <Column
          title='Actions'
          key='action'
          render={(_, record) => (
            <>
              <ConfirmModal onOk={deleteFee} isLoading={isLoading} id={record.id} hasPermision={canDelete} />
              <Button
                className='byp-btn-edit'
                // loading={isLoading}
                size='small'
                onClick={
                  () => {
                    setId(record.id)
                  }
                }
                icon={<EditIcon />}
              />
            </>
          )}
        />
      </Table>
    </div>
  )
}

export { TableServices }
