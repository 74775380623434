import { Card, Typography } from 'antd'
import React from 'react'
const { Paragraph } = Typography
const BasePricesDiscountView = ({ data, showJson, ...props }) => {
  return (
    <Card {...props}>
      {/* Fees */}
      {!showJson && data?.fees?.map((fee) => (
        <React.Fragment key={fee.id}>
          {fee.name} {fee.discount} {fee.discount_type}
          <Paragraph ellipsis={{ rows: 3, expandable: false }}>
            {fee.discount_description}
          </Paragraph>
        </React.Fragment>
      ))}
      {/* Extras */}
      {!showJson && data?.extras?.map((fee) => (
        <React.Fragment key={fee.id}>
          {fee.name} {fee.discount} {fee.discount_type}
          <Paragraph ellipsis={{ rows: 3, expandable: false }}>
            {fee.discount_description}
          </Paragraph>
        </React.Fragment>
      ))}
      {showJson && (
        <pre style={{ maxHeight: '40vh', overflow: 'auto' }}>{JSON.stringify(data ?? {}, null, 2)}</pre>
      )}
    </Card>
  )
}

export { BasePricesDiscountView }
