import { Table, Button, Tag, Switch, Form, Modal } from 'antd'
import { tagStatusClass } from './../../../utils/tagStatusClass'
import ConfirmModal from '../../../common/components/ConfirmModal'
import { EditIcon } from '../../../common/theme/icons/EditIcon'
import { useChangeStatusUser } from '../../../bussines/hooks/user/useChangeStatusUser'
import { useEffect, useState } from 'react'
import { SelectUserModal } from './SelectUserModal'
import { ErrorBoundary } from '../../../providers/validation/ErrorBoundary'

function TableUsers ({
  data, sedeLabel, setId, isLoading,
  deleteUser, deleteInvitation, isDeleting,
  refetch, readOnly, canDelete,
  setPage, paginationData
}) {
  const [form] = Form.useForm()
  const { changeStatus, isLoading: isChangingStatus, data: resp } = useChangeStatusUser()
  const [mdl, setMdl] = useState(false)
  const [userId, setUserId] = useState()
  const [validUsers, setValidUsers] = useState([])
  const [modal, context] = Modal.useModal()
  const [countUsersAdmin, setCountUsersAdmin] = useState(0)

  const handleChange = (val, id) => {
    const userToDelete = data.filter(user => user.id === id)

    if ((countUsersAdmin === 1 && !val) && (userToDelete[0].role === 'Institution Administrator' || userToDelete[0].role === 'Agency Administrator' || userToDelete[0]?.role === 'Education Group Admin')) {
      return modal.error({
        title: 'Ups!',
        content: 'There must be another agency administrator to delete this user'
      })
    }
    if (readOnly) {
      return modal.error({
        title: 'Ups!',
        content: 'You do not have permission'
      })
    }
    if (val) {
      changeStatus(id)
    } else {
      const validUsers = data?.filter((item) => (item.status === 'Active' && item.id !== id))
      setValidUsers(validUsers)
      setMdl(true)
      setUserId(id)
    }
  }

  const closeModal = () => {
    setMdl(false)
  }

  const handleSubmit = () => {
    changeStatus(userId, form.getFieldValue('user'))
    setMdl(false)
  }

  useEffect(() => {
    if (!isChangingStatus && resp) {
      console.log('🚀 ~ file: TableUsers.js:38 ~ useEffect ~ resp', resp)
      refetch()
    }
  }, [resp, isChangingStatus])

  useEffect(() => {
    setCountUsersAdmin(0)
    if (data && data.length > 1) {
      data.forEach((userFind) => {
        if ((userFind.role === 'Institution Administrator' || userFind.role === 'Agency Administrator' || userFind?.role === 'Education Group Admin') && userFind.status === 'Active') {
          setCountUsersAdmin(currentCount => {
            return currentCount + 1
          })
        }
      })
    }
  }, [data])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: sedeLabel,
      dataIndex: 'sede'
    },
    {
      title: 'Role',
      dataIndex: 'role'
    },
    {
      title: 'Status',
      render: ({ status }) => (
        <Tag className={tagStatusClass(status)} style={{ textTransform: 'uppercase' }}>
          {status}
        </Tag>
      )
    },
    {
      title: 'Actions',
      render: ({ id, type, status }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {status !== 'Deleted' && (
            <>
              <Button
                size='small'
                className='byp-btn-edit'
                onClick={() => { setId(id, type) }}
                disabled={isLoading || status === 'Deleted'}
                icon={<EditIcon />}
              />
              <Switch
                checked={status === 'Active'}
                size='small'
                style={{ alignItems: 'center', display: 'flex' }}
                checkedChildren='ACTIVE'
                loading={isLoading || status === 'Deleted'}
                disabled={status === 'In Process'}
                onClick={
                  (value) => (handleChange(value, id))
                }
              />
            </>
          )}
          {(status === 'Inactive' || type === 'invitation') && !readOnly && (
            <ConfirmModal onOk={type === 'user' ? deleteUser : deleteInvitation} isLoading={isDeleting} id={id} hasPermision={canDelete} />
          )}
        </div>
      )
    }
  ]
  return (
    <ErrorBoundary>
      {context}
      <SelectUserModal
        setMdl={setMdl}
        mdl={mdl}
        clsMdl={closeModal}
        handleSubmit={handleSubmit}
        validUsers={validUsers}
        form={form}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rowKey='id'
        dataSource={data ?? []}
        className='byp-table-dashboard'
        loading={isLoading}
        // pagination={{
        //   current: paginationData.page,
        //   total: paginationData.total,
        //   pageSize: paginationData.perPage
        // }}
        pagination={paginationData}
      />
    </ErrorBoundary>
  )
}

export { TableUsers }
