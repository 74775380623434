import React from 'react'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../providers/validation/ErrorBoundary'
import { ValidateEnterpriseProfile } from '../../common/components/ValidateEnterpriseProfile'
import { MainTheme } from '../../common/theme/MainTheme'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { QuoteApplyContent } from './QuoteApplyContent'

const URL_QUOTE_APPLY_PREVIEW = '/quote-apply/:id'

const QuotePreviewApply = () => {
  const { id } = useParams()

  return (
    <ErrorBoundary>
      <ValidateEnterpriseProfile permissionTypes={[userRoles.TYPE_ANY]}>
        <MainTheme>
          <QuoteApplyContent quoteUuid={id} />
        </MainTheme>
      </ValidateEnterpriseProfile>
    </ErrorBoundary>
  )
}

export { QuotePreviewApply, URL_QUOTE_APPLY_PREVIEW }
