import { Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { InfoIcon } from '../../../quote-preview/assets/imgs/Icons'
import { Line } from 'react-chartjs-2'

export const ChartTotalQuotes = ({ data, typeCompany, dataByCompany }) => {
  const [chartData, setChartData] = useState()
  const [summary, setSummary] = useState()

  function getColor(program){
    switch(program){
      case 'Languages':
        return '#2460B7'
        case 'High Education':
        return '#6495ED'
        case 'High Schools':
        return '#FFCC00'
        case 'Boarding Schools':
        return '#AC85DE'
        case 'Summer Camps':
        return '#F765A3'
        case 'Other':
        return '#34A853'
    }
  }
  const prepareData = (data) => {
    let sum = 0

    const colors= data.map((object)=>getColor(object.program))
    // Get the months 
    const labels = []
    const exampleResults = data[0] ?? []
    const labelsTest = exampleResults?.months?.map((item) => item)
    const dsw = data?.map((programData, index) => {
      labels.push('gato')
      const mappedData = labelsTest.map((month) => {
        const found = programData.data.find((itemData) => itemData.date === month)
        if (found) {
          sum += found.quotes
          return found.quotes
        }
        return 0
      })
      return {
        label: programData.program,
        data: mappedData,
        fill: false,
        backgroundColor: colors[index] ?? 'rgba(45, 47, 102)',
        borderColor: colors[index] ?? 'rgba(45, 47, 102)',
        tension: 0.1
      }
    })
    setSummary(sum)
    setChartData({
      labels: labelsTest,
      datasets: dsw
    })
  }

  useEffect(() => {
    if (data && !typeCompany) {
      prepareData(data)
    } else if (typeCompany) {
      const foundData = dataByCompany?.find((dataItem) => dataItem.enterprise_type === typeCompany)
      prepareData(foundData?.history ?? [])
    }
  }, [data, typeCompany])

  const config = {
    type: 'line',
    data: chartData,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 30,
          font: {
            family: 'Nunito'
          }
        }
      }
    },
    cutout: 67,
    aspectRatio:3/1
  }

  return (
    <div className='chart__total-quotes'>
      <Row justify='space-between'>
        <Col span={12}>
          <p>TOTAL QUOTES {typeCompany}</p>
          <p>{summary}</p>
          <p>PER PROGRAM</p>
        </Col>
        <Col span={12}>
          <Row justify='end'>
            <InfoIcon />
          </Row>
        </Col>
        <Col span={24}>
          <div className='d-flex justify-center byp-mt-1 byp-mb-1'>
            <div style={{ height: '2px', background: '#F4F4F4', width: '100%', margin: '0 50px' }} />
          </div>
        </Col>
        <Col span={24}>
          {chartData && <Line options={config} data={chartData} />}
        </Col>
      </Row>
    </div>
  )
}
