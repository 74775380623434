import { useState } from 'react'
import { useMutation } from 'react-query'
import AgencyRepository from '../../../repositories/AgencyRepository'
import state from '../../../utils/state-control'

function useUpdateMiscellanous () {
  const [currentState, setCurrentState] = useState(state.STATE_INITIAL)
  const mutation = useMutation(AgencyRepository.updateMiscellaneous, {
    onSuccess: (d) => {
      setCurrentState(state.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(state.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const updateMiscellaneous = (id, data) => {
    mutation.mutate({ id, data })
  }

  return { ...mutation, state, currentState, updateMiscellaneous }
}

export { useUpdateMiscellanous }
