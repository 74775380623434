import * as React from 'react'

export const ArtIcon = (props) => {
  return (
    <svg
      width={11}
      height={10}
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.985.142c.707.238-1.365 1.407-.07 2.191C5.643 2.774 7.232.78 8.03.331c.679-.378 1.869-.455 2.47.861.764 1.666.099 6-2.652 7.742-2.78 1.75-6.28.861-7.49-1.862C-1.05 3.902 2.542-.69 4.985.142zm.847 4.515c.51 1.148 3.29-.35 2.653-1.96-.413-1.043-3.136.875-2.653 1.96z'
        fill='#2460B7'
      />
    </svg>
  )
}

export const FoodIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4669)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M10.5 1.75a.583.583 0 01.58.515l.003.068v9.334a.583.583 0 01-1.162.068l-.005-.068V8.75h-.583a.583.583 0 01-.58-.515l-.003-.068v-3.5c0-1.29.875-2.917 1.75-2.917zm-3.5 0a.583.583 0 01.58.515l.003.068V5.25a2.335 2.335 0 01-1.75 2.26v4.157a.583.583 0 01-1.162.068l-.005-.068V7.51A2.334 2.334 0 012.92 5.37l-.002-.12V2.333a.583.583 0 011.162-.068l.004.068V5.25a1.167 1.167 0 00.583 1.01V2.333a.583.583 0 011.163-.068l.004.068V6.26a1.167 1.167 0 00.58-.911l.003-.099V2.333A.583.583 0 017 1.75z'
          fill='#2460B7'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4669'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const ToursIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4001)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M7.875 3.208a1.17 1.17 0 001.167-1.166A1.17 1.17 0 007.875.875a1.17 1.17 0 00-1.167 1.167 1.17 1.17 0 001.167 1.166zM5.717 5.192l-1.634 8.225h1.225l1.05-4.667 1.225 1.167v3.5H8.75V9.042L7.525 7.875l.35-1.75a4.271 4.271 0 003.208 1.458V6.417c-1.108 0-2.041-.584-2.508-1.4l-.583-.934C7.758 3.733 7.408 3.5 7 3.5c-.175 0-.292.058-.467.058L3.5 4.842v2.741h1.167V5.6l1.05-.408z'
          fill='#2460B7'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4001'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EntertainmentIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4675)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M8.476.846l-.63.63.933.933a.745.745 0 01.193.508.7.7 0 01-.193.501l-2.07 2.106.583.63 2.117-2.106c.31-.344.46-.723.46-1.131 0-.409-.15-.794-.46-1.138L8.476.846zM6.154 2.024l-.63.63.356.327c.128.128.192.303.192.52 0 .215-.064.39-.192.518l-.356.327.63.63.327-.356c.309-.344.466-.717.466-1.12 0-.42-.157-.799-.466-1.149l-.327-.327zm6.096.928c-.403 0-.776.157-1.12.466l-3.284 3.29.63.584 3.255-3.244a.715.715 0 011.038 0l.356.356.6-.63-.326-.356c-.344-.309-.73-.466-1.15-.466zM4.083 4.667l-2.917 8.166 8.167-2.916-5.25-5.25zm7 1.785c-.408 0-.781.157-1.132.466l-.927.928.63.63.928-.928a.687.687 0 01.501-.221c.193 0 .368.076.514.221l.944.928.613-.6-.933-.958c-.345-.309-.73-.466-1.138-.466z'
          fill='#2460B7'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4675'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const SportsIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_4681)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M11.608 2.888c.236.28.43.583.583.901h-.001a1.4 1.4 0 01-1.701.218l1.119-1.12zm-.495-.495L9.994 3.51a1.4 1.4 0 01.216-1.7l.001-.001c.325.154.627.35.902.583zm.732 5.612a4.205 4.205 0 00.606-3.517 2.102 2.102 0 01-2.467.024l-.817.815 2.678 2.677zM9.489 4.017a2.101 2.101 0 01.024-2.468 4.204 4.204 0 00-3.517.607l2.676 2.677.817-.816zM5.455 2.606l-.024.024c-.502.5-.868 1.12-1.065 1.8.323.098.657.214.989.347.724.291 1.495.69 2.134 1.24l.689-.69-2.723-2.721zM9.582 9.63a4.181 4.181 0 001.788-1.06l.025-.026-2.723-2.722-.686.686c.567.648.972 1.433 1.265 2.17.126.32.236.642.33.952zm-.308 1.799A1.19 1.19 0 017.8 12.588c-1.228-.277-3.172-.865-4.332-2.025-1.157-1.157-1.755-3.109-2.04-4.345a1.287 1.287 0 01-.032-.291 1.192 1.192 0 011.459-1.162 13.64 13.64 0 012.24.662c.787.316 1.582.749 2.172 1.338.59.59 1.02 1.386 1.333 2.173.314.79.518 1.597.647 2.233.018.087.027.174.027.259zM4.798 7.803a.35.35 0 00-.495.495l1.4 1.4a.35.35 0 10.495-.495l-1.4-1.4z'
          fill='#2460B7'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_4681'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PlacesIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_1606)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M7 0L.933 3.267v.466h12.133v-.466L7 0zM1.866 4.667v4.666l-.933 1.494v1.306h12.133v-1.306l-.933-1.494V4.667H1.866zm1.867.933h.933v5.133h-.933V5.6zm2.8 0h.933v5.133h-.933V5.6zm2.8 0h.933v5.133h-.933V5.6z'
          fill='#2460B7'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_1606'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const NatureIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_480_1612)'>
        <path fill='#fff' fillOpacity={0.01} d='M0 0H14V14H0z' />
        <path
          d='M9.894 7h.046c.473 0 .747-.53.479-.916L7.454 1.855a.583.583 0 00-.956 0l-2.94 4.23A.585.585 0 004.043 7h.023L2.374 9.602a.58.58 0 00.49.898h2.964v1.178c0 .636.519 1.155 1.155 1.155s1.155-.519 1.155-1.155V10.5h3.004a.58.58 0 00.484-.904L9.894 7z'
          fill='#2460B7'
        />
      </g>
      <defs>
        <clipPath id='clip0_480_1612'>
          <path fill='#fff' d='M0 0H14V14H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EmailIcon = (props) => {
  return (
    <svg
      width={12}
      height={10}
      viewBox='0 0 12 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.107.875h-9.75a.375.375 0 00-.375.375v7.5c0 .207.168.375.375.375h9.75a.375.375 0 00.375-.375v-7.5a.375.375 0 00-.375-.375zm-.946 1.276L6.463 5.028a.252.252 0 01-.31 0L2.454 2.151A.084.084 0 012.506 2h7.603a.084.084 0 01.052.151z'
        fill='#fff'
      />
    </svg>
  )
}

export const WhatsapIcon = (props) => {
  return (
    <svg
      width={11}
      height={10}
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.312 2.42C7.872.2 4.932-.46 2.652.92c-2.22 1.38-2.94 4.38-1.5 6.6l.12.18-.48 1.8 1.8-.48.18.12c.78.42 1.62.66 2.46.66.9 0 1.8-.24 2.58-.72 2.22-1.44 2.88-4.38 1.5-6.66zm-1.26 4.62c-.24.36-.54.6-.96.66-.24 0-.54.12-1.74-.36-1.02-.48-1.86-1.26-2.46-2.16-.36-.42-.54-.96-.6-1.5 0-.48.18-.9.48-1.2.12-.12.24-.18.36-.18h.3c.12 0 .24 0 .3.24.12.3.42 1.02.42 1.08.06.06.06.18 0 .24.06.12 0 .24-.06.3s-.12.18-.18.24c-.12.06-.18.18-.12.3.24.36.54.72.84 1.02.36.3.72.54 1.14.72.12.06.24.06.3-.06s.36-.42.48-.54c.12-.12.18-.12.3-.06l.96.48c.12.06.24.12.3.18.06.18.06.42-.06.6z'
        fill='#fff'
      />
    </svg>
  )
}

export function TikTokIcon(props) {
  return (
    <svg
      width={16}
      height={10}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" fill="white"></path>
    </svg>
  )
}

export const TwitterIcon = (props) => {
  return (
    <svg
      width={11}
      height={8}
      viewBox='0 0 11 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.108.98a4.007 4.007 0 01-1.151.31c.417-.249.73-.64.879-1.102-.392.232-.82.396-1.268.482A1.993 1.993 0 007.108.04 1.998 1.998 0 005.11 2.038c0 .155.018.31.049.458A5.679 5.679 0 011.039.404C.858.71.764 1.06.766 1.414a2 2 0 00.89 1.665 2.016 2.016 0 01-.903-.255v.025c0 .971.686 1.776 1.602 1.962a2.116 2.116 0 01-.904.037c.253.792.99 1.368 1.868 1.386a4.005 4.005 0 01-2.48.855c-.168 0-.323-.006-.484-.025a5.659 5.659 0 003.07.897c3.675 0 5.686-3.045 5.686-5.688 0-.086 0-.173-.006-.26.389-.284.73-.637 1.002-1.033z'
        fill='#fff'
      />
    </svg>
  )
}

export const CoinsIcon = (props) => {
  return (
    <svg
      width={20}
      height={21}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.688 7.164V7c0-1.141-.844-2.149-2.383-2.844C11.008 3.57 9.297 3.25 7.5 3.25c-1.797 0-3.508.32-4.805.906C1.156 4.85.313 5.859.313 7v3.124c0 1.141.843 2.149 2.382 2.844.836.361 1.716.61 2.618.742v.164c0 1.141.843 2.149 2.382 2.844 1.297.586 3.008.907 4.805.907 1.797 0 3.508-.32 4.805-.907 1.539-.695 2.383-1.703 2.383-2.844V10.75c0-1.726-1.977-3.117-5-3.585zm3.124 3.585c0 .61-1.703 1.758-4.828 1.868 1.102-.664 1.704-1.532 1.704-2.493V9.063c2.023.367 3.124 1.18 3.124 1.688zM5.938 11.906v-1.242c.52.056 1.041.085 1.563.085s1.044-.029 1.563-.085v1.242a12.09 12.09 0 01-1.563.093 12.09 12.09 0 01-1.563-.093zm6.875-2.32v.538c0 .375-.648.954-1.874 1.368V10.32a8.26 8.26 0 001.367-.477c.174-.075.344-.16.508-.258zM7.5 5.124c3.438 0 5.313 1.242 5.313 1.875 0 .632-1.876 1.875-5.313 1.875-3.438 0-5.313-1.243-5.313-1.875 0-.633 1.876-1.875 5.313-1.875zm-5.313 5v-.54c.164.097.334.183.508.258.441.199.899.358 1.368.477v1.172c-1.227-.414-1.875-.993-1.875-1.368zm5 3.75H7.5c.281 0 .563-.008.844-.024.226.078.469.156.719.219v1.172c-1.227-.414-1.876-.993-1.876-1.368zm3.75 1.78v-1.241c.52.056 1.041.085 1.563.085s1.044-.029 1.563-.085v1.242a13.078 13.078 0 01-3.126 0zm5-.413V14.07a8.263 8.263 0 001.368-.477c.174-.075.344-.16.508-.258v.54c0 .374-.649.953-1.875 1.367z'
        fill='#1A171E'
      />
    </svg>
  )
}

export function CalendlyIcon (props) {
  return (
    <svg
      viewBox='7.4 0 344.6 360'
      width={18}
      height={21}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g fill='#fff'>
        <path d='M313.8 360H45.5c-21 0-38.1-17.1-38.1-38.1V53.5c0-21 17.1-38.1 38.1-38.1h268.3c21 0 38.1 17.1 38.1 38.1v268.3c.1 21.1-17 38.2-38.1 38.2zM45.5 36.5c-9.4 0-17 7.6-17 17v268.3c0 9.4 7.6 17 17 17h268.3c9.4 0 17-7.6 17-17V53.5c0-9.4-7.6-17-17-17z' />
        <path d='M256.6 72.4c-4.5 0-8.1-3.6-8.1-8.1V8.1c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c0 4.5-3.6 8.2-8.1 8.2zm-154.7 0c-4.5 0-8.1-3.6-8.1-8.1V8.1c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c.1 4.5-3.6 8.2-8.1 8.2zm87.5 181.4c-33.6 0-60.9-27.3-60.9-60.9s27.3-60.9 60.9-60.9c15.2 0 29.7 5.6 40.9 15.8 1.4 1.2 1.5 3.4.2 4.7-1.2 1.4-3.4 1.5-4.7.2-10-9.1-22.9-14.1-36.4-14.1-29.9 0-54.2 24.3-54.2 54.2s24.3 54.2 54.2 54.2c13.5 0 26.4-5 36.4-14.1 1.4-1.2 3.5-1.1 4.7.2 1.2 1.4 1.1 3.5-.2 4.7-11.2 10.4-25.7 16-40.9 16z' />
      </g>
    </svg>
  )
}

export function FacebookIcon (props) {
  return (
    <svg
      width={8}
      height={13}
      viewBox='0 0 8 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.647 7.21l.316-2.058H4.988V3.816c0-.563.275-1.112 1.16-1.112h.898V.951S6.231.813 5.452.813c-1.626 0-2.69.986-2.69 2.77v1.57H.954V7.21h1.808v4.976h2.226V7.212h1.659z'
        fill='#fff'
      />
    </svg>
  )
}

export function InstagramIcon (props) {
  return (
    <svg
      width={10}
      height={9}
      viewBox='0 0 10 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 3.02c-.816 0-1.481.664-1.481 1.48 0 .815.665 1.48 1.48 1.48.816 0 1.482-.665 1.482-1.48 0-.816-.666-1.48-1.481-1.48zM9.44 4.5c0-.613.006-1.221-.028-1.833-.034-.711-.197-1.342-.716-1.862C8.175.285 7.545.123 6.834.09 6.22.054 5.613.059 5 .059c-.613 0-1.22-.005-1.833.03-.71.034-1.342.196-1.862.716-.52.521-.682 1.151-.716 1.862C.555 3.28.56 3.887.56 4.5c0 .612-.005 1.22.03 1.833.034.71.196 1.342.716 1.861.521.521 1.151.683 1.862.717.613.034 1.22.029 1.833.029s1.22.005 1.833-.029c.71-.034 1.342-.197 1.862-.717s.682-1.15.716-1.861c.036-.612.029-1.22.029-1.833zM5 6.778A2.275 2.275 0 012.72 4.5 2.275 2.275 0 015 2.22 2.275 2.275 0 017.278 4.5 2.275 2.275 0 015 6.778zM7.37 2.66a.531.531 0 110-1.063.531.531 0 010 1.063z'
        fill='#fff'
      />
    </svg>
  )
}

export function YoutubeIcon (props) {
  return (
    <svg
      width={12}
      height={9}
      viewBox='0 0 12 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.03 1.97a1.316 1.316 0 00-.927-.93C9.283.82 6 .82 6 .82s-3.284 0-4.103.22a1.314 1.314 0 00-.928.929C.75 2.789.75 4.5.75 4.5s0 1.71.22 2.53c.12.452.476.809.927.93.82.22 4.103.22 4.103.22s3.284 0 4.103-.22c.452-.121.807-.478.928-.93.219-.82.219-2.53.219-2.53s0-1.71-.22-2.53zm-6.073 4.1V2.93l2.719 1.558L4.957 6.07z'
        fill='#F7F7F7'
      />
    </svg>
  )
}

export function PhoneIcon (props) {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.244 7.57L7.21 5.722a.5.5 0 00-.695.021L5.319 6.974c-.288-.055-.867-.236-1.463-.83-.596-.596-.776-1.177-.83-1.463l1.23-1.197a.5.5 0 00.021-.696L2.43.756a.5.5 0 00-.696-.043l-1.085.93a.5.5 0 00-.145.325c-.007.125-.15 3.086 2.146 5.383C4.653 9.354 7.162 9.5 7.853 9.5c.1 0 .163-.003.18-.004a.496.496 0 00.323-.146l.93-1.085a.498.498 0 00-.042-.695z"
        fill="#fff"
      />
    </svg>
  )
}

export function LinkedinIcon (props) {
  return (
    <svg
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.715 3.485h1.857v.925c.267-.533.953-1.01 1.984-1.01C9.53 3.4 10 4.458 10 6.402V10H8V6.844c0-1.107-.268-1.73-.949-1.73-.944 0-1.337.672-1.337 1.73V10h-2V3.485zm-3.43 6.43h2V3.4h-2v6.515zm2.286-8.64a1.265 1.265 0 01-.376.901 1.295 1.295 0 01-1.819 0A1.274 1.274 0 010 1.275C0 .937.135.613.377.374a1.292 1.292 0 011.818 0c.241.239.377.563.377.901z"
        fill="#fff"
      />
    </svg>
  )
}

export function OtherSocialIcon (props) {
  return (
    <svg
      width={10}
      height={12}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.125 9.375a1.875 1.875 0 11-3.623-.68L3.34 7.308a1.875 1.875 0 110-2.616L5.5 3.305a1.88 1.88 0 11.408.628L3.75 5.32a1.89 1.89 0 010 1.36l2.16 1.387a1.875 1.875 0 013.216 1.308z"
        fill="#fff"
      />
    </svg>
  )
}

export function CreatedIcon (props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.258 3.867c.325-.325.325-.867 0-1.175l-1.95-1.95c-.308-.325-.85-.325-1.175 0L10.6 2.267l3.125 3.125M.5 12.375V15.5h3.125l9.217-9.225L9.717 3.15.5 12.375z"
        fill="#686868"
      />
    </svg>
  )
} 

export function SendedIcon (props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.319.681a.625.625 0 00-.656-.144l-13.75 5a.625.625 0 000 1.17L6.28 8.85l3.963-3.975.881.881-3.981 3.981 2.15 5.37a.625.625 0 00.581.393.625.625 0 00.575-.412l5-13.75A.624.624 0 0015.32.68z"
        fill="currentColor"
      />
    </svg>
  )
}

export function VisitedIcon (props) {
  return (
    <svg
      width={17}
      height={19}
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.166 14c.467 0 .834.367.834.833a.825.825 0 01-.834.834.825.825 0 01-.833-.834c0-.466.367-.833.833-.833zm0-2.5c-2.275 0-4.216 1.383-5 3.333.784 1.95 2.725 3.334 5 3.334s4.217-1.384 5-3.334c-.783-1.95-2.725-3.333-5-3.333zm0 5.417a2.083 2.083 0 110-4.167 2.083 2.083 0 010 4.167zm-6.55-1.459l-.241-.625.241-.616c1.075-2.667 3.634-4.384 6.55-4.384a7.16 7.16 0 012.5.467V5.667l-5-5H2A1.66 1.66 0 00.333 2.333v13.334A1.667 1.667 0 002 17.333h3.75a7.703 7.703 0 01-1.134-1.875zM7.833 1.917L12.416 6.5H7.833V1.917z"
        fill="#686868"
      />
    </svg>
  )
}

export function ApplicationsIcon (props) {
  return (
    <svg
      width={15}
      height={17}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.458 6.492l.525.108 3.475 1.733c.584.192.967.767.917 1.384v.216l-.75 5.1c-.05.359-.208.692-.5.925-.258.25-.6.375-.958.375H6.433c-.408 0-.783-.15-1.058-.441L.383 10.917l.75-.834c.2-.208.517-.325.817-.308h.242L5.5 10.5V1.75a1.667 1.667 0 113.333 0v4.742h.625z"
        fill="#686868"
      />
    </svg>
  )
}

export function UnionIcon (props) {
  return (
    <svg
      width={14}
      height={12}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.125 11.25s-.875 0-.875-.875.875-3.5 4.375-3.5S14 9.5 14 10.375s-.875.875-.875.875h-7zM9.625 6a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zm-5.061 5.25a1.958 1.958 0 01-.189-.875c0-1.185.595-2.406 1.694-3.255a5.534 5.534 0 00-1.694-.245C.875 6.875 0 9.5 0 10.375s.875.875.875.875h3.689zm.92-5.89a2.188 2.188 0 11-3.093-3.094 2.188 2.188 0 013.093 3.093z"
        fill="#2D2F66"
      />
    </svg>
  )
}

export function DatesIcon (props) {
  return (
    <svg
      width={15}
      height={18}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 10.667a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.807.807 0 01-.594.24zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.594c0-.236.08-.434.24-.594.16-.16.358-.239.594-.239s.434.08.594.24c.16.16.239.357.239.593s-.08.434-.24.594a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.804.804 0 01-.594.24zM7.5 14a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.358.24.594s-.08.434-.24.593A.807.807 0 017.5 14zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.593c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.239.358.239.594s-.08.434-.24.593a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.358.24.594s-.08.434-.24.593a.804.804 0 01-.594.24zm-9.166 3.333c-.459 0-.851-.163-1.178-.489A1.607 1.607 0 010 15.667V4c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49H2.5V.667h1.667v1.666h6.666V.667H12.5v1.666h.833c.459 0 .851.164 1.178.49.326.326.489.719.489 1.177v11.667c0 .458-.163.85-.49 1.177-.326.326-.718.49-1.177.49H1.667zm0-1.666h11.666V7.333H1.667v8.334z"
        fill="currentColor"
      />
    </svg>
  )
}

export function HomeLeftIcon (props) {
  return (
    <svg
      width={21}
      height={19}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.96 7.791h11.72a.196.196 0 00.195-.195V6.229a.196.196 0 00-.195-.196H7.96a.196.196 0 00-.194.196v1.367c0 .107.088.195.195.195zm-.194 4.98c0 .108.088.196.195.196H19.68a.196.196 0 00.195-.196v-1.367a.196.196 0 00-.195-.195H7.96a.196.196 0 00-.194.195v1.367zM20.07.906H.93a.196.196 0 00-.196.196v1.367c0 .107.088.195.196.195h19.14a.196.196 0 00.196-.195V1.102a.196.196 0 00-.196-.196zm0 15.43H.93a.196.196 0 00-.196.195v1.367c0 .108.088.196.196.196h19.14a.196.196 0 00.196-.196v-1.367a.196.196 0 00-.196-.195zm-18.593-3.66l3.815-3.005a.216.216 0 000-.34L1.477 6.325a.217.217 0 00-.352.168v6.013a.218.218 0 00.352.171z"
        fill="#2D2F66"
      />
    </svg>
  )
}

export function LanguageIcon (props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.167.833H2.5C1.583.833.833 1.583.833 2.5v10l2.5-2.5H7.5v-.833a3.338 3.338 0 013.334-3.334V2.5c0-.917-.75-1.667-1.667-1.667zm0 2.5h-1.25A6.927 6.927 0 016.4 6.05l-.017.017 1.05 1.041-.308.842-1.292-1.283L3.75 8.75l-.575-.608 2.109-2.075a7.15 7.15 0 01-1.234-1.9h.825c.258.5.575.975.958 1.4a6.409 6.409 0 001.309-2.234H2.5V2.5h2.917v-.833h.833V2.5h2.917v.833zM17.5 7.5h-6.666c-.917 0-1.667.75-1.667 1.667V15c0 .917.75 1.667 1.667 1.667h5.833l2.5 2.5v-10c0-.917-.75-1.667-1.667-1.667zm-1.142 8.333l-.708-1.875h-2.966l-.7 1.875h-1.25l2.808-7.5h1.25l2.816 7.5h-1.25zM14.168 10l1.017 2.708h-2.025L14.166 10z"
        fill="#686868"
      />
    </svg>
  )
}

export function ArrowUpIcons (props) {
  return (
    <svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.983 7l-.816-.817 4.316-4.345L6.816 4.17 9.85 1.167H8.333V0h3.5v3.5h-1.167V1.983l-3.85 3.85L4.483 3.5.983 7z"
        fill="#6495ED"
      />
    </svg>
  )
}

export function ArrowDownIcons (props) {
  return (
    <svg
      width={13}
      height={7}
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.517 0l.817.817-4.317 4.345L5.684 2.83 2.65 5.833h1.517V7h-3.5V3.5h1.166v1.517l3.85-3.85L8.018 3.5l3.5-3.5z"
        fill="#E42A36"
      />
    </svg>
  )
}

export function LocationIcon (props) {
  return (
    <svg
      width={12}
      height={18}
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 8.583a2.083 2.083 0 110-4.166 2.083 2.083 0 010 4.166zM6 .667A5.833 5.833 0 00.167 6.5C.167 10.875 6 17.333 6 17.333s5.833-6.458 5.833-10.833A5.833 5.833 0 006 .667z"
        fill="#686868"
      />
    </svg>
  )
}

export function CalendarIcon (props) {
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 10.667a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594C7.567 9.08 7.764 9 8 9s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.807.807 0 01-.594.24zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.594c0-.236.08-.434.24-.594.16-.16.358-.239.594-.239s.434.08.594.24c.16.16.239.357.239.593s-.08.434-.24.594a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239s.434.08.594.24c.16.16.24.357.24.593s-.08.434-.24.594a.804.804 0 01-.594.24zM8 14a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.357.24.594 0 .236-.08.434-.24.593A.807.807 0 018 14zm-3.333 0a.807.807 0 01-.595-.24.806.806 0 01-.239-.593c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.239.357.239.594 0 .236-.08.434-.24.593a.807.807 0 01-.593.24zm6.666 0a.804.804 0 01-.593-.24.804.804 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.593-.24s.434.08.594.24c.16.16.24.357.24.594 0 .236-.08.434-.24.593a.804.804 0 01-.594.24zm-9.166 3.333c-.459 0-.851-.163-1.178-.489A1.607 1.607 0 01.5 15.667V4c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49H3V.667h1.667v1.666h6.666V.667H13v1.666h.833c.459 0 .851.164 1.178.49.326.326.489.719.489 1.177v11.667c0 .458-.163.85-.49 1.177-.326.326-.718.49-1.177.49H2.167zm0-1.666h11.666V7.333H2.167v8.334z"
        fill="#686868"
      />
    </svg>
  )
}

export function InstituionIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#1A171E'
        d='M14 6.667h-1.333v-4h.666V1.334H2.667v1.333h.666v4H2a.667.667 0 00-.667.667v6h13.334v-6A.667.667 0 0014 6.667zM9.333 12V9.334H6.667V12h-2V2.667h6.666V12h-2z'
      ></path>
      <path
        fill='#1A171E'
        d='M6 4h1.333v1.333H6V4zm2.667 0H10v1.333H8.667V4zM6 6.667h1.333V8H6V6.667zm2.667 0H10V8H8.667V6.667z'
      ></path>
    </svg>
  )
}

export function EducationIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='13'
      fill='none'
      viewBox='0 0 16 13'
    >
      <g clipPath='url(#clip0_2667_62734)'>
        <path
          fill='#1A171E'
          d='M1.2 0A1.2 1.2 0 000 1.2v10.4a1.2 1.2 0 001.2 1.2h2.4v-2a1.2 1.2 0 012.4 0v2h2.248A1.662 1.662 0 018 11.922c0-1.172.645-2.195 1.6-2.73V1.2A1.2 1.2 0 008.4 0H1.2zm.4 6c0-.22.18-.4.4-.4h.8c.22 0 .4.18.4.4v.8c0 .22-.18.4-.4.4H2c-.22 0-.4-.18-.4-.4V6zm2.8-.4h.8c.22 0 .4.18.4.4v.8c0 .22-.18.4-.4.4h-.8c-.22 0-.4-.18-.4-.4V6c0-.22.18-.4.4-.4zm2 .4c0-.22.18-.4.4-.4h.8c.22 0 .4.18.4.4v.8c0 .22-.18.4-.4.4h-.8c-.22 0-.4-.18-.4-.4V6zM2 2.4h.8c.22 0 .4.18.4.4v.8c0 .22-.18.4-.4.4H2c-.22 0-.4-.18-.4-.4v-.8c0-.22.18-.4.4-.4zm2 .4c0-.22.18-.4.4-.4h.8c.22 0 .4.18.4.4v.8c0 .22-.18.4-.4.4h-.8c-.22 0-.4-.18-.4-.4v-.8zm2.8-.4h.8c.22 0 .4.18.4.4v.8c0 .22-.18.4-.4.4h-.8c-.22 0-.4-.18-.4-.4v-.8c0-.22.18-.4.4-.4zm7.6 4.4a2 2 0 10-3.999-.001A2 2 0 0014.4 6.8zm-5.6 5.128c0 .482.39.872.872.872h5.455c.483 0 .873-.39.873-.873A2.328 2.328 0 0013.672 9.6h-2.545A2.328 2.328 0 008.8 11.928z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_2667_62734'>
          <path fill='#fff' d='M0 0H16V12.8H0z'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export function AgencyIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill="#1A171E"
        d="M14 5.5h-3.5V2A1.5 1.5 0 009 .5H3A1.5 1.5 0 001.5 2v13a.5.5 0 00.5.5h3.25a.25.25 0 00.25-.25v-2.236c0-.269.207-.5.476-.513A.502.502 0 016.5 13v2.25a.25.25 0 00.25.25H15a.5.5 0 00.5-.5V7A1.5 1.5 0 0014 5.5zM3.565 13.496a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm2.5 7.5a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm2.5 10a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.99.5.5 0 01.13.99zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm0-2.5a.5.5 0 11-.13-.992.5.5 0 01.13.992zm5.81 11.004H10.5v-8H14a.5.5 0 01.5.5v7.375a.125.125 0 01-.125.125z"
      ></path>
      <path
        fill="#1A171E"
        d="M13 12.5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1zm-2 5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1zm0-2.5a.5.5 0 100 1 .5.5 0 000-1z"
      ></path>
    </svg>
  )
}

export function ServicesIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill="#1A171E"
        d="M6 2a1.5 1.5 0 00-1.5 1.5v10a.5.5 0 00.5.5h1.5v-2.5A.5.5 0 017 11h4a.5.5 0 01.5.5V14H13a.5.5 0 00.5-.5v-5A1.5 1.5 0 0012 7h-.5V3.5A1.5 1.5 0 0010 2H6zm1.5 2.5a.5.5 0 11-1 0 .5.5 0 011 0zm0 2.5a.5.5 0 11-1 0 .5.5 0 011 0zm0 2.5a.5.5 0 11-1 0 .5.5 0 011 0zm2-5a.5.5 0 11-1 0 .5.5 0 011 0zm0 2.5a.5.5 0 11-1 0 .5.5 0 011 0zm0 2.5a.5.5 0 11-1 0 .5.5 0 011 0zm2 0a.5.5 0 11-1 0 .5.5 0 011 0zm-1 4.5v-2h-.997v2h.997zm-1.997 0v-2H7.5v2h1.003z"
      ></path>
    </svg>
  )
}

export function InfoIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#D4D4D4"></circle>
      <rect
        width="4"
        height="10.667"
        x="10"
        y="9.334"
        fill="#fff"
        rx="2"
      ></rect>
      <rect width="4" height="4" x="10" y="4" fill="#fff" rx="2"></rect>
    </svg>
  )
}

export function ClosedIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <path
        fill="#D4D4D4"
        d="M13.667.375C6.354.375.542 6.188.542 13.5c0 7.313 5.812 13.125 13.125 13.125 7.312 0 13.125-5.813 13.125-13.125 0-7.313-5.813-13.125-13.125-13.125zm5.062 19.688L13.667 15l-5.063 5.063-1.5-1.5 5.063-5.063-5.063-5.063 1.5-1.5L13.667 12l5.062-5.063 1.5 1.5-5.062 5.063 5.062 5.063-1.5 1.5z"
      ></path>
    </svg>
  )
}

export function UpIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        fill="#fff"
        d="M15.44.465a.75.75 0 00-.405-.405.75.75 0 00-.285-.06H11a.75.75 0 100 1.5h1.943L8.75 5.692 6.282 3.217a.75.75 0 00-1.065 0l-4.5 4.5a.75.75 0 00.245 1.23.75.75 0 00.82-.164L5.75 4.808l2.467 2.474a.75.75 0 001.065 0L14 2.558V4.5a.75.75 0 101.5 0V.75a.75.75 0 00-.06-.285z"
      ></path>
    </svg>
  )
}

export function DownIcon () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        fill="#fff"
        d="M15.379 9.535a.75.75 0 01-.405.405.75.75 0 01-.285.06h-3.75a.75.75 0 110-1.5h1.942L8.69 4.308 6.22 6.782a.75.75 0 01-1.065 0l-4.5-4.5A.75.75 0 01.9 1.053a.75.75 0 01.821.165L5.69 5.192l2.467-2.475a.75.75 0 011.065 0l4.718 4.725V5.5a.75.75 0 111.5 0v3.75a.75.75 0 01-.06.285z"
      ></path>
    </svg>
  )
}

export function IconsPages () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='10'
      fill='none'
      viewBox='0 0 12 10'
    >
      <path
        fill='currentColor'
        d='M0 1.5A1.5 1.5 0 011.5 0h3.995a1.5 1.5 0 011.5 1.5V2h3.555c.734.026 1.45.547 1.45 1.51v3.03a1.5 1.5 0 01-1.5 1.5H9.113l-.146.746c-.162.828-.814 1.216-1.467 1.213L1.5 10A1.5 1.5 0 010 8.5v-7zm6.995 6.999c0 .605.874.69.99.095l.892-4.58v-.002l.15-.783.006-.031.012-.054.01-.044.03-.1h-2.09v5.499zM2.485 2.99a.5.5 0 100-1 .5.5 0 000 1zm.01 1.5a.5.5 0 100 1h1.99a.501.501 0 100-1h-1.99zm0 2.51a.5.5 0 100 1h1.99a.5.5 0 00.262-.925l-.022-.013a.49.49 0 00-.129-.05l-.01-.002A.475.475 0 004.508 7H2.494h.001z'
      />
    </svg>
  )
}

export function RecordVoiceOverIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='#1A171E'
        d='M5.333 8.035c1.33 0 2.334-1.004 2.334-2.334S6.663 3.368 5.333 3.368 3 4.371 3 5.701c0 1.33 1.003 2.334 2.333 2.334zM6 8.667H4.667A3.337 3.337 0 001.333 12v.667h8V12A3.337 3.337 0 006 8.667zm6.243-6.91L11.3 2.7A4.641 4.641 0 0112.667 6 4.641 4.641 0 0111.3 9.3l.943.943A5.963 5.963 0 0014 6a5.963 5.963 0 00-1.757-4.243z'
      />
      <path
        fill='#1A171E'
        d='M10.357 3.643l-.943.944C9.792 4.963 10 5.465 10 6s-.208 1.037-.586 1.413l.943.944c.629-.629.976-1.466.976-2.357 0-.891-.347-1.728-.976-2.357z'
      />
    </svg>
  )
}

export function EditIcon () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path
        fill='currentColor'
        d='M13.807 4.693a.664.664 0 000-.94l-1.56-1.56a.664.664 0 00-.94 0l-1.227 1.22 2.5 2.5M2 11.5V14h2.5l7.373-7.38-2.5-2.5L2 11.5z'
      />
    </svg>
  )
}
