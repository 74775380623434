import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import { useWatch } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useDownloadExchangeHistoryByCurrency } from "../../../../bussines/hooks/currencies/useDownloadExchangeHistoryByCurrency";
import { useGetMyExchangeValuesByCurrency } from "../../../../bussines/hooks/currencies/useGetMyExchangeValuesByCurrency";
import { useSaveExchangeValues } from "../../../../bussines/hooks/currencies/useSaveExchangeValues";
import { SelectCurrencyFormItem } from "../../../../common/components/helper-form/SelectCurrencyFormItem";
import { useAuth } from "../../../../providers/auth/auth-context-provider";
import { ClientSideAutoExhcangeButton } from "./ClientSideAutoExhcangeButton";
import { DetailsPopOver } from "./DetailsPopOver";
import { CheckboxAutoExchange } from "./CheckboxAutoExchange";

let readOnly = true;

export function FormExchange() {
  const [form] = Form.useForm();
  const [modal, contex] = Modal.useModal();
  const { user } = useAuth();

  const [currency, setCurrency] = useState();
  const [restCurrencyList, setRestCurrencyList] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoadingAutoExchanges, setIsLoading] = useState(false);

  const { data, isLoading, refetch } =
    useGetMyExchangeValuesByCurrency(currency);
  const {
    downloadHistory,
    data: responseFile,
    isLoading: isDowloading,
  } = useDownloadExchangeHistoryByCurrency();
  const {
    saveExchangeValues,
    isLoading: isSaving,
    data: resp,
    error,
  } = useSaveExchangeValues();

  const currenciesList = useWatch("currencies", form);

  useEffect(() => {
    if (!isLoading && data?.exchanges) {
      console.log("🚀 ~ file: FormExchange.js:31 ~ useEffect ~ data:", data);
      if (!showAll) {
        setRestCurrencyList(data?.exchanges?.slice(15));
        const firsCurrencies = data?.exchanges?.slice(0, 15);
        // setShowAll(false)
        form.setFieldsValue({
          currencies: firsCurrencies,
        });
      } else {
        form.setFieldsValue({
          currencies: data?.exchanges,
        });
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (!isSaving && resp) {
      refetch();
      modal.success({
        title: "Data saved",
        content: "The exchange values has been saved successfully",
      });
    }
  }, [isSaving, resp]);

  useEffect(() => {
    if (error && !isSaving) {
      modal.error({
        title: "Ups!",
        content: error.response.data.message,
      });
    }
  }, [isSaving, error]);

  useEffect(() => {
    if (responseFile) {
      const url = window.URL.createObjectURL(new Blob([responseFile]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    }
  }, [responseFile]);

  useEffect(() => {
    const roles = user?.role_names ?? [];
    if (user?.type === "Agency") {
      if (roles.includes("agency-admin") || roles.includes("agency-editor")) {
        readOnly = false;
      }
      if (
        roles.includes("agency-office-manager") ||
        roles.includes("agency-consultant") ||
        roles.includes("agency-manager")
      ) {
        readOnly = true;
      }
    } else {
      readOnly = false;
    }
  }, [user]);

  const handleLoadMore = () => {
    if (!showAll) {
      setShowAll(true);
      const firstCurrencues = form.getFieldValue("currencies");
      form.setFieldsValue({
        currencies: [...firstCurrencues, ...restCurrencyList],
      });
    }
  };

  const handleFinish = (values) => {
    if (readOnly) {
      return modal.error({
        title: "Ups!",
        content: "You do not have permission",
      });
    }
    saveExchangeValues(values);
  };

  const handleDownload = () => {
    downloadHistory(currency);
  };

  return (
    <Form form={form} onFinish={handleFinish} style={{ minHeight: "80vh" }}>
      {data?.exchanges && (
        <>
          {/* <AutoExchangeButton currency={currency} refetch={refetch} setIsLoading={setIsLoading} /> */}
          <ClientSideAutoExhcangeButton
            currency={currency}
            refetch={refetch}
            setIsLoading={setIsLoading}
            currencyList={data?.exchanges ?? []}
            setShowAll={setShowAll}
          />
          <Button
            className="byp-btn-blue-200"
            style={{ float: "right" }}
            onClick={handleDownload}
            loading={isDowloading}
          >
            {" "}
            Download history
          </Button>
        </>
      )}
      <h5 className="byp-title byp-mb-1">Record exchange rates</h5>
      <Row>
        <Col span={24}>
          {data?.exchanges && (
            <CheckboxAutoExchange
              status={data?.currency_auto_exchangue}
              loading={isLoading}
              refetch={refetch}
              readOnly={readOnly}
              currency={currency}
            />
          )}
        </Col>
        <Col span={12}>
          <SelectCurrencyFormItem
            name="currency"
            label="base Currency"
            size="medium"
            setDivisa={setCurrency}
          />
        </Col>
        <Col span={12}>
          <h6
            className="byp-ml-1"
            style={{ marginTop: ".6rem", float: "right" }}
          >
            {data?.date}
            <DetailsPopOver
              date={data?.date}
              user={data?.user?.name}
              office={data?.user?.office}
            />
          </h6>
        </Col>
      </Row>
      {contex}
      <Form.List name="currencies">
        {(fields, { add, remove }) => (
          <>
            <Row>
              {fields.map(({ key, name, ...restField }, index) => (
                <Col xs={12} md={8} key={key}>
                  <Form.Item
                    name={[name, "exchange_value"]}
                    style={{ marginBottom: 0 }}
                  >
                    <InputNumber
                      addonBefore={currenciesList[name].code}
                      disabled={currenciesList[name].code === currency}
                      readOnly={readOnly}
                    />
                  </Form.Item>
                  <p>
                    <i style={{ color: "gray" }}>
                      * {currenciesList[name].name}
                    </i>
                  </p>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Form.List>
      {!showAll && data?.exchanges && (
        <div className="byp-centered w-100">
          <Button
            loading={isSaving}
            className="byp-btn-blue-100-outline"
            onClick={handleLoadMore}
          >
            {" "}
            Load more
          </Button>
        </div>
      )}
      {data?.exchanges && (
        <Button
          htmlType="submit"
          loading={isSaving || isLoadingAutoExchanges}
          className="byp-btn-blue-100"
          style={{ float: "right" }}
        >
          Save
        </Button>
      )}
    </Form>
  );
}
