import React from 'react'
import Icon from '@ant-design/icons'

const StatusQuoteSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 54 54' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path d='M45.515 27.3427H26.6572V8.48485C26.6572 8.25282 26.4674 8.06298 26.2354 8.06298H24.8643C22.0972 8.06015 19.3568 8.60373 16.8003 9.66252C14.2438 10.7213 11.9216 12.2745 9.9668 14.2329C8.04217 16.1516 6.50902 18.4262 5.45273 20.9302C4.35586 23.53 3.79688 26.288 3.79688 29.1356C3.79688 31.9833 4.35586 34.736 5.45273 37.3358C6.5127 39.8407 8.03145 42.0977 9.9668 44.0331C11.9021 45.9684 14.1539 47.4872 16.6641 48.5472C19.2593 49.6482 22.0504 50.2132 24.8695 50.2083C27.6366 50.2111 30.377 49.6675 32.9335 48.6087C35.4899 47.55 37.8122 45.9968 39.767 44.0384C41.7023 42.103 43.2211 39.8513 44.2811 37.3411C45.3821 34.7458 45.9471 31.9548 45.9422 29.1356V27.7645C45.9369 27.5325 45.7471 27.3427 45.515 27.3427ZM50.1504 24.4159L50.0133 22.9288C49.565 18.0772 47.4135 13.4999 43.9436 10.0458C40.4769 6.58201 35.9086 4.43871 31.0289 3.98661L29.5365 3.8495C29.2887 3.82841 29.0777 4.01825 29.0777 4.2661V24.5003C29.0777 24.7323 29.2676 24.9222 29.4996 24.9222L49.7285 24.8694C49.9764 24.8641 50.1715 24.6585 50.1504 24.4159Z' fill='currentColor' />
  </svg>
)
const StatusQuoteIcon = (props) => <Icon component={StatusQuoteSvg} {...props} />

const PublishHideQuoteSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 59 59' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 5.36353H38.8367V12.2782H0V5.36353ZM0 19.1929H38.8367V26.1076H0V19.1929ZM0 33.0223H24.7143V39.937H0V33.0223ZM54.0148 23.6598L38.8332 38.4953L34.2716 34.0318L29.2793 38.924L38.8332 48.2726L59 28.5554L54.0148 23.6598Z' fill='#2D2F66' />
  </svg>
)
const PublishHideQuoteIcon = (props) => <Icon component={PublishHideQuoteSvg} {...props} />

export {
  StatusQuoteIcon,
  PublishHideQuoteIcon
}
