import { Button, Col, Row } from 'antd'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { FormUploadDocuments } from './FormUploadDocuments'

const URL_UPLOAD_DOCUMENTS_PAGE = '/dashboard/custom/quote/docs'

function UploadDocumentsPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content'>
          <FormUploadDocuments />
        </div>
        <div className='byp-dashboard-footer'>
          <Row
            gutter={12}
            className='byp-form-footer'
          >
            <Col span={24}>
              <Button
                className='byp-btn-blue-100 float-right byp-mt-1 byp-ml-1'
                htmlType='submit'
                // onClick={onAprove}
              >Next
              </Button>
            </Col>
          </Row>
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { UploadDocumentsPage, URL_UPLOAD_DOCUMENTS_PAGE }
