// import { HeaderStep } from '../../HeaderStep'
import { FormTitle } from '../../texts/FormTitle'
import { AgencyFormConfigured } from '../../forms/AgencyFormConfigured'
import React from 'react'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import FooterStep from '../../../../../common/components/steps/FooterStep'

export const STEP_PROFILE = 1

function AgencyProfileStep () {
  return (
    <StepBaseTemplate
      justify='space-around'
      footer={<FooterStep />}
    >
      <div style={{ margin: '18px 5%' }}>
        {/* <HeaderStep />   */}
        <FormTitle
          title='Edit your agency profile '
          text="Let's get started, enter your agency's details so that you have the opportunity to offer Additional costs to your clients, as well as customize quotes and searches."
        />
        <AgencyFormConfigured />
      </div>
    </StepBaseTemplate>
  )
}

export { AgencyProfileStep }
