import { PageProtected } from '../../common/components/PageProtected'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { VendorPageComponent } from './components/VendorPageComponent'

const URL_VENDOR_PAGE = '/client_quotes/:studentId/:agentId'

function VendorPage () {
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <VendorPageComponent />
    </PageProtected>
  )
}

export { VendorPage, URL_VENDOR_PAGE }
