import React from 'react'
import icon_services from '../../../common/assets/images/icon_services.png'
import service_companies from '../../../common/assets/images/service_companies.png'


export function HomeSectionE() {
  return (
    <div className='mb-responsive-lg'>
      <div className="d-flex align-items-center px-0 py-0 my-5 home-sectioncde-home">
        <div className="col-md-8 p-0" style={{ top: "4rem" }}>
          <div
            className="card box-shadow-0 border-0 align-items-center home-card-responseive"
            style={{ background: "#CDDFFF", width: "100%" }}
          >
            <div className="card-content home-sectione-card" style={{ width: "30rem", marginTop:'2rem', marginBottom:'2rem' }}>
              <div className='home-sectione-title'>
                <img src={icon_services} alt="" className="mb-1" style={{width:'70px',height: '70px'}}></img>
                <div>
                  <h5 style={{marginTop:'1rem'}}>
                    <b>Service Companies</b>
                  </h5>
                  <p className='byp-sub-black'>
                    Offer your services through QYP's network of agencies, and thus,
                    increase your sales and partnerships.
                  </p>
                </div>
              </div>
              <ul class="byp-blue-200 byp-text-cont-blue">
                <li>
                  Ideal for activity companies, accommodations, translations,
                  financial companies, immigration agents and more.
                </li>
                <li>Expand your network of strategic alliances</li>
                <li>Increase your sales</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="d-md-block col-12 col-md-4 p-0"
          style={{ margin: "-10rem", top: "4rem" }}
        >
          <div class="card card-transparent box-shadow-0 border-0">
            <div className='home-img'>
              <img src={service_companies} alt="" className="byp-poster" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}