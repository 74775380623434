import { useQuery } from 'react-query'
import AgencyRepository from '../../repositories/AgencyRepository'

function useGetMyExchangeValuesByCurrency (currency) {
  return useQuery(['useGetMyExchangeValuesByCurrency', currency], () => AgencyRepository.getMyExchangeValuesByCurrency(currency), {
    retry: 2,
    enabled: !!currency
  })
}
export { useGetMyExchangeValuesByCurrency }
