import React from 'react'
import { RowQuoter } from './RowQuoter'
import { usePackage } from '../../../providers/package/package-course-context'

function IncludesSection () {
  const {
    service
  } = usePackage()

  return (
    <>
      {
        service.getIncludes()?.map(item => (
          <RowQuoter
            key={item.id}
            colA={
              <>
                {item.name}
              </>
            }
            colB={
              <>
                -
              </>
            }
          />
        ))
      }
    </>
  )
}

export default IncludesSection
