import { Form, Select } from 'antd'
import { useEffect } from 'react'
import { useGetInstitutions } from '../../../bussines/hooks/agency/useGetInstitutions'

const { Option } = Select

/**
 * Return Select for Main Campus
 */
function SelectInstitutions ({ name, type, showAll = false, ...props }) {
  const { data } = useGetInstitutions({})
  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select {...props}>
        {showAll && <Option key={0} value={null}>All institutions </Option>}
        {data?.map((institution) => (
          <Option key={institution.id} value={institution.id}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

/**
 * Return Select for Main Campus
 */
function SelectInstitutionsAdvance ({ name, type, showAll = false, isAdvance = false, countries = [], onChange, ...props }) {
  const { data, isLoading } = useGetInstitutions(isAdvance ? { countries, isadvance: true } : {})
  useEffect(() => {
    if (!isLoading && data) {
      console.log('🚀 ~ file: SelectInstitutions.js:34 ~ useEffect ~ data:', data, countries)
    }
  }, [isLoading])
  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select {...props} onChange={onChange} loading={isLoading}>
        {showAll && <Option key={0} value={null}>All institutions </Option>}
        {data?.map((institution) => (
          <Option key={institution.id} value={institution.id + '@' + institution.name}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

/**
 * Return Select for Institutions
 */
function SelectSchools ({ name, type, showAll = false, firstOption = false, haveOptionAll = false, isReports, filters, ...props }) {
  const { data } = useGetInstitutions(filters)

  useEffect(() => {
    if (firstOption && data?.length > 0) {
      const { onChange } = props
      onChange(data[0].institution_id)
    }
  }, [data])

  return (
    <Form.Item
      name={name}
      {...props}
    >
      <Select {...props}>
        {isReports && <Option value={null}>All the schools</Option>}
        {showAll && <Option key={0} value={null}>All institutions</Option>}
        {haveOptionAll && (
          <Option value='all'>All</Option>
        )}
        {data?.map((institution) => (
          <Option key={institution.id} value={institution.institution_id}>{institution.name}</Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export { SelectInstitutions, SelectSchools, SelectInstitutionsAdvance }
