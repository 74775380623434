import { isSameOrAfter } from './isSameOrAfter'
import { isSameOrBefore } from './isSameOrBefore'

/**
 * The High Season is still valid and continue throug the course dates (all dates are in the peak season)
 * @param {string} startDate
 * @param {string} endDate
 * @param {*} highSeason
 * @returns {boolean}
 */
function datesAreInPeackSeason (startDate, endDate, highSeason) {
  /**
   * P -------E----------A-------K----- S--
   *       start-------------end
   */
  if (
    isSameOrBefore(new Date(highSeason.hs_start), new Date(startDate)) &&
    isSameOrAfter(new Date(highSeason.hs_end), new Date(endDate))
  ) {
    return true
  }
  return false
}

/**
 * Only the first dates are ins peak season
 * @param {string} startDate
 * @param {string} endDate
 * @param {*} highSeason
 * @returns {boolean}
 */
function datesStartsInPeackSeason (startDate, endDate, highSeason) {
  /**
   * P -------E------A-----K-----S-
   *                          -s-t-a-r-t--------e-n-d-
   */
  if (isSameOrAfter(new Date(highSeason.hs_end), new Date(startDate)) &&
      isSameOrBefore(new Date(highSeason.hs_end), new Date(endDate))
  ) {
    return true
  }
  return false
}

export { datesAreInPeackSeason, datesStartsInPeackSeason }
