import { Col, Form, Row } from 'antd'
import { SelectCityByIsoFormItem } from './SelectCityByIsoFormItem'
import { SelectAsyncNationalitiesFormItem } from './SelectAsyncNationalitiesFormItem'
import { useState } from 'react'
import { SelectCampusFormItem } from './SelectCampusFormItem'
import { useForm } from 'antd/lib/form/Form'

const SelectCampusByCountryAndCity = ({ setCountry, setCity, setCampus, setCampusName, ...props }) => {
  const [iso3, setIso3] = useState()
  const [city, setCityId] = useState()
  const [form] = useForm()

  const onChange = (val, type) => {
    if (setCountry && type === 'country') {
      setCountry(val)
      if (setCampus) setCampus()
      if (setCity) setCity()
      setCityId()
      form.setFieldsValue({ city: null, sede: null })
    }
    if (type === 'city') {
      if (setCity) setCity(val)
      if (setCampus) setCampus()
      setCityId(val)
      form.setFieldsValue({ sede: null })
    }
  }

  const handleSede = (val) => {
    if (!val) {
      if (setCampus) setCampus()
      if (setCampusName) setCampusName()
      return
    }
    const sedeVal = val.split('-')
    if (setCampus) setCampus(sedeVal[0])
    if (setCampusName) setCampusName(sedeVal[1])
  }

  return (
    <Form form={form} initialValues={{ country: null, city: null, sede: null }}>
      <Row className='selects-responsives-column'>
        <Col lg={6}>
          <SelectAsyncNationalitiesFormItem
            name='country'
            showAll
            onChange={(country) => onChange(country, 'country')}
            setIso3={setIso3}
            showSearch
            rules={[{ required: true, message: 'This field is required' }]}
            filters={{
              whereHasCampus: true
            }}
            {...props}
          />
        </Col>
        <Col lg={6}>
          <SelectCityByIsoFormItem
            name='city'
            onChange={(city) => onChange(city, 'city')}
            showSearch
            showAll
            iso={iso3}
            filters={{
              whereHasCampus: true
            }}
            {...props}
          />
        </Col>
        <Col lg={6}>
          <SelectCampusFormItem
            single
            filters={{
              city,
              country: iso3
            }}
            onChange={(val) => handleSede(val)}
            showNameSede
            {...props}
          />
        </Col>
      </Row>
    </Form>
  )
}

export { SelectCampusByCountryAndCity }
