import { Form, Input } from 'antd'

const PasswordFormItem = () => {
  return (
    <>
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Please insert your password!'
          },
          {
            pattern:
            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
            message:
              '?The password must include a minimum of 8 characters and include at least: 1 number, one uppercase, 1 lowercase, and one special character.'
          }
        ]}
        hasFeedback
      >
        <Input.Password size='large' placeholder='Password' />
      </Form.Item>

      <Form.Item
        name='confirm'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!'
          },
          ({ getFieldValue }) => ({
            validator (_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              )
            }
          })
        ]}
      >
        <Input.Password size='large' placeholder='Confirm password' />
      </Form.Item>
    </>
  )
}

export { PasswordFormItem }
