import { useMutation, useQuery } from 'react-query'
import ShortTermRepository from '../../repositories/ShortTermRepository'
import stateControl from '../../utils/state-control'
import { useState } from 'react'

function useGetShort (filters = {}) {
  return useQuery(['useGetShort', filters],
    () => ShortTermRepository.short(filters), {
      retry: 1
    })
}

function useGetCampusShort () {
  return useQuery(['useGetCampusPackage'],
    () => ShortTermRepository.shortCampus(), {
      retry: 1
    })
}

function useGetShortPrices (packageId, year, campusId) {
  const filters = { year, campusId }
  return useQuery(['useGetShortPrices', filters, packageId],
    () => ShortTermRepository.getPrices(packageId, filters), {
      retry: 1,
      enabled: !!packageId && !!year && !!campusId
    })
}

function useSaveShortPrices () {
  const [currentState, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(ShortTermRepository.savePrices, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * nextStep
   * @param step
   * @param data
   */
  const savePrices = (id, data) => {
    mutation.mutate({ id, data })
  }

  return { ...mutation, stateControl, currentState, savePrices }
}

export { useGetShort, useGetCampusShort, useGetShortPrices, useSaveShortPrices }
