import React, { Suspense, useEffect, useState } from 'react'
import header from './../../../common/assets/images/banner-hero.png'
import { IconBussiness, IconBookBookmark } from '../../../common/assets/svg/icons'
import { CourseCompleteDisplayed } from './CourseCompletedDisplayed'
import { FooterBlue } from './FooterBlue'
import { useGetAgencyByStudentId } from '../../../bussines/hooks/agency/useGetAgencyByStudentId'
import { useGetQuotesByStudent, useGetQuotesByStudentInactive } from '../../../bussines/hooks/agency/useGetQuotesByStudent'
import { WhatsAppOutlined } from '@ant-design/icons'
import { Pagination } from 'antd'
import LottieCargando from '../../../lotties/LottieCargando'

function WelcomeUser ({ textDisplayed }) {
  return (
    <div>
      <p style={{ fontSize: '3rem', paddingTop: '10px', color: 'white', fontWeight: 'bold' }}>
        {textDisplayed && 'Hello, ' + textDisplayed}
      </p>
    </div>
  )
}

function ClientPageComponent ({ id, agent }) {
  // const navegadorInfo = navigator.userAgent
  // const navegador = navegadorInfo.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

  const [page, setPage] = useState(1)

  const { data: quotes, isLoading } = useGetQuotesByStudent({ studentId: id, agentId: agent, page })
  const { data: quotesInactive } = useGetQuotesByStudentInactive({ studentId: id, agentId: agent })
  const { data: agencys } = useGetAgencyByStudentId({ student_id: id, agent_id: agent })

  const [arrayIsActive, setArrayIsActive] = useState([])
  const [arrayIsInactive, setArrayIsInactive] = useState([])
  const [name, setName] = useState('')

  function getIsActive (endDate, startDate) {
    const currentDate = new Date()
    const start = new Date(startDate)
    const end = new Date(endDate)

    return currentDate >= start && currentDate <= end
  }

  useEffect(() => {
    const active = []
    if (!isLoading && quotes) {
      const quotesData = quotes?.data
      quotesData?.forEach(quote => {
        if (getIsActive(quote.expiration, quote.created_at)) {
          active.push(quote)
        }
      })

      setName(quotesData[0]?.custom_name)
    }

    setArrayIsActive(active)
  }, [quotes, isLoading])

  useEffect(() => {
    const inactive = []
    if (quotesInactive) {
      const quotesData = quotesInactive?.data
      quotesData?.forEach(quote => {
        if (!getIsActive(quote.expiration, quote.created_at)) {
          inactive.push(quote)
        }
      })

      setName(quotesData[0]?.custom_name)
    }

    setArrayIsInactive(inactive)
  }, [quotesInactive])

  return (
    <Suspense fallback='Cargando traducciones...'>
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', gap: '1.5vw', alignItems: 'center', paddingLeft: '2vw', paddingTop: '5px' }}>
            <label class='qyp-label-main'>BETA</label>
            <div style={{ display: 'flex', color: 'var(--blue-200)', fontWeight: 'bold', alignItems: 'center', gap: '5px', flexDirection: 'row', whiteSpace: 'nowrap' }}>
              <IconBussiness />
              {agencys?.data.name}
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'end', paddingRight: '5vw', gap: '2vw', alignItems: 'center' }}>

            <div className='client-page-component'>
              <IconBookBookmark color='var(--blue-200)' />
              My quotes
            </div>
            {/* <MenuLanguages i18n={i18n} language={language} setLanguage={setLanguage}/> */}
            {/* {navegador[1] !== 'Safari' && <GoogleTranslateButton className='google_translate_container-quote' />} */}
          </div>
        </div>
        <img src={header} style={{ width: '100%', height: '15rem', objectFit: 'cover' }} alt='' />
        {isLoading && <LottieCargando />}
        {!isLoading && (
          <div className='client-quotes-section-quotes'>
            <WelcomeUser textDisplayed={name} />
            {arrayIsActive?.map((quote) => (
              <CourseCompleteDisplayed key={quote.uuid} quote={quote} isActive />
            ))}
            <div style={{ textAlign: 'end' }}>
              <Pagination
                className='byp-w-100'
                defaultCurrent={1}
                current={quotes?.meta?.current_page ?? 1}
                // size='small'
                pageSize={quotes?.meta?.per_page ?? 20}
                total={quotes?.meta?.total ?? 0}
                onChange={setPage}
                hideOnSinglePage
                showQuickJumper={false}
                showSizeChanger={false}
              />
            </div>
            {arrayIsInactive?.map((quote) => (
              <CourseCompleteDisplayed key={quote.uuid} quote={quote} />
            ))}
          </div>
        )}
        {
          agencys?.data?.whatsapp &&
            <div className='whatsapp-container'>
              <a href={'https://wa.me/' + agencys?.data?.whatsapp}>
                <div className='whatsapp-icon'>
                  <WhatsAppOutlined />
                </div>
              </a>
            </div>
        }
        <FooterBlue agency={agencys?.data} />
      </div>
    </Suspense>
  )
}
export { ClientPageComponent }
