import { Form, Select } from 'antd'
const { Option } = Select

/**
 * Select the BYP available languages
 * @param {Object} props
 * {
 *    name: Name of the form item,
 *    formItemProps: props for ANT desing Form.Item component,
 *    selectProps: props for ANT desing Select component
 * }
 * @returns Form Item Selector
 */
const SelectAvailableTranslationsFormItem = ({ name, formItemProps, selectProps }) => {
  return (
    <Form.Item
      name={name}
      {...formItemProps}
    >
      <Select
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...selectProps}
      >
        <Option value={null}>English (Default)</Option>
        <Option value='es'>Español</Option>
        <Option value='pt'>Portugues</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectAvailableTranslationsFormItem }
