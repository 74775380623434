import { Col, Divider, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../providers/auth/auth-context-provider'
import TimeFilters from '../analitics/TimeFilters'
import TotalQuotes from '../analitics/user/TotalQuotes'
import { TableQuotes } from '../../../about/components/TableQuotes'
import UserInfo from '../analitics/user/UserInfo'
import { useFindUser } from '../../../../bussines/hooks/user/useFindUser'
import TopUsers from '../analitics/components/TopUsers'
import { SearchOutlined } from '@ant-design/icons'

const ReportsInfo = () => {
  const { user: _user } = useAuth()

  const [time, setTime] = useState({})
  const [user, setUser] = useState()
  const [activeCategory, setActiveCategory] = useState('created')
  const [orderby, setOrderby] = useState()
  const [isActiveTotalQuote, setIsActiveTotalQuote] = useState(true)
  const { data: userData, isLoading, refetch } = useFindUser(user)

  const [search, setSearch] = useState()
  const [studentID, setStudentID] = useState()

  const handleSearch = () => {
    setStudentID(search)
    setTime({
      studentID: search,
      ...time
    })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setStudentID(search)
      setTime({
        studentID: search,
        ...time
      })
    }
  }

  const handleClear = () => {
    setStudentID('')
  }

  useEffect(() => {
    setUser(_user?.id)
  }, [_user])

  return (
    <>
      <Row className='byp-dashboard-content'>
        <Col span={24}>
          <Row justify='center' className='d-flex align-center'>
            <Col sm={24} lg={8}>
              <h3 className='byp-title'>Reports</h3>
              <TimeFilters setTime={setTime} />
            </Col>
            <Col sm={24} lg={16}>
              <UserInfo
                user={userData}
                refetch={refetch}
                isLoadingUser={isLoading}
                showActions={false}
              />
            </Col>
          </Row>
          <Divider
            style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
          />
          <h4 className='title-reports-uni byp-fw-bold'>
            Total quotes <span>(percentages based on agency totals)</span>
          </h4>

          <TotalQuotes
            time={time}
            user={user}
            setActiveCategory={setActiveCategory}
            setIsActiveTotalQuote={setIsActiveTotalQuote}
            activeCategory={activeCategory}
            setOrderby={setOrderby}
            studentID={studentID}
            isMyReports
          />
          <Divider
            style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
          />
          <Col span={24}>
            <Form.Item
              label='Search ID'
            >
              <Input
                addonAfter={<SearchOutlined onClick={handleSearch} style={{ cursor: 'pointer' }} />}
                placeholder='Search only by student ID'
                onChange={(event) => {
                  setSearch(event.target.value)
                  if (!event.target.value) {
                    handleClear()
                  }
                }}
                onKeyDown={handleKeyPress}
                allowClear
                className='reports-info__student'
              />
            </Form.Item>
          </Col>

          {isActiveTotalQuote && (
            <TableQuotes
              category={activeCategory}
              user={user}
              time={time}
              setOrderby={setOrderby}
              orderby={orderby}
              studentID={studentID}
            />
          )}

          <Col span={24}>
            <Divider
              style={{ margin: '1rem 0', backgroundColor: '#CDDFFF' }}
            />
            <h4 className='byp-title-4 quote-overview--color-blue byp-mb-1'>
              Top users
            </h4>
            <TopUsers time={time} showButton={false} />
          </Col>
        </Col>
      </Row>
    </>
  )
}

export { ReportsInfo }
