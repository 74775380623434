import { Button, Col, Form, Input, InputNumber, Row, Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PrefixLabelSelector } from './PrefixLabelFormItem'
import { AddAndRemoveListButtons } from './AddAndRemoveListButtons'
import { SelectTimeFrequencyFormItem } from '../../../../common/components/helper-form/SelectTimeFrequencyFormItem'
import { useEffect, useState } from 'react'

function ConceptCostFrequencyListFormItem ({ name, parentName, parentIndex, divisa = 'USD', form, program }) {
  const prefixSelector = <PrefixLabelSelector divisa={divisa} />
  const arrayFees = Form.useWatch(name, form) || []
  const patentArray = Form.useWatch(parentName, form) || []
  const parentItem = patentArray[parentIndex] ?? undefined
  const arrayFeesB = parentItem?.fees
  // console.log('arrayFeesB', arrayFeesB)
  const listName = name
  const [frequencies, setFrequencies] = useState({ all: true })

  useEffect(() => {
    if (program === 'graduate') {
      setFrequencies({ unic: true, semester: true, year: true })
    } else {
      setFrequencies({ all: true })
    }
  }, [program])

  return (
    <div className='w-100'>
      <Form.List
        name={name}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={16}>
                <Col span={6}>
                  <Form.Item
                    name={[name, 'name']}
                  >
                    <Input
                      className=''
                      placeholder='Concept'
                      readOnly={arrayFees[name]?.name === 'Registration Fee' && listName === 'fees'}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ width: '100%' }}>
                  <Col>
                    <Form.Item
                      name={[name, 'cost']}
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <InputNumber placeholder='00.00' addonBefore={prefixSelector} min={0} controls={false} />
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={6}>
                  <SelectTimeFrequencyFormItem
                    rules={[{ required: true, message: 'This field is required' }]}
                    name={[name, 'frequency']} frequencies={frequencies}
                  />
                </Col>
                <AddAndRemoveListButtons
                  fields={fields}
                  index={index}
                  add={add}
                  remove={remove}
                  name={name}
                  notDelete={arrayFees[name]?.name === 'Registration Fee' && listName === 'fees' && key === 0}
                />
                <Col span={4} style={{ paddingTop: '5px' }}>
                  <Row style={{ columnGap: '10px' }}>
                    Mandatory
                    {arrayFees[name] && (
                      <Form.Item
                        name={[name, 'mandatory']}
                      >
                        <Switch
                          checkedChildren='YES'
                          unCheckedChildren='NO'
                          checked={arrayFees[name]?.mandatory}
                        />
                      </Form.Item>
                    )}
                    {arrayFeesB && (
                      <Form.Item
                        name={[name, 'mandatory']}
                      >
                        <Switch
                          checkedChildren='YES'
                          unCheckedChildren='NO'
                          checked={arrayFeesB[name]?.mandatory}
                        />
                      </Form.Item>
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
            {
            fields.length === 0 && (
              <Form.Item>
                <Button className='byp-btn-blue-100-outline float-right' onClick={() => add()} icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            )
            }
          </>
        )}
      </Form.List>
    </div>
  )
}

export { ConceptCostFrequencyListFormItem }
