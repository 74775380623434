import { useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Unauthorized } from '../errors/Unauthorized'
import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { QuoteCourseProvider } from '../../providers/quoter/quoter-course-context'
import { QuoterFormConfigured } from './components/QuoterFormConfigured'
import { Button, Col, Row } from 'antd'
import { URL_QUOTER_PAGE } from '../quoter'
import FormContent from './FormContent'
import { QuoteFormDetails } from './components/QuoteFormDetails'

const URL_QUOTE_COURSE_PAGE = '/quote-course/:courseId/:campusId'
const URL_QUOTE_COURSE_PREVIEW_PAGE = '/quote-course/:courseId/:campusId/:mode'
const QuoterCoursePage = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const baseQuoteId = params.get('quote') ?? undefined
  const { courseId, campusId, yearId, mode } = useParams()
  const location = useLocation()
  const { quoteId } = location.state ?? { quoteId: baseQuoteId }
  const [hasPermision, setHasPermision] = useState(true)

  if (!hasPermision) {
    return <Unauthorized />
  }
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme>
        <QuoteCourseProvider courseId={courseId} campusId={campusId} yearId={yearId} quoteId={quoteId} mode={mode}>
          <QuoterFormConfigured>
            <Button className='byp-mb-1 byp-btn-blue-100 quote-course-btn' onClick={() => navigate(URL_QUOTER_PAGE)}>
              Go Back
            </Button>
            <Row gutter={16}>
              <Col xs={24} lg={16}>
                <FormContent setHasPermision={setHasPermision} />
              </Col>
              <Col xs={24} lg={8}>
                <QuoteFormDetails />
              </Col>
            </Row>
          </QuoterFormConfigured>
        </QuoteCourseProvider>
      </MainTheme>
    </PageProtected>
  )
}
export { QuoterCoursePage, URL_QUOTE_COURSE_PAGE, URL_QUOTE_COURSE_PREVIEW_PAGE }
