import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { userRoles } from '../../../../bussines/settings/validate-user-access'
import { PageProtected } from '../../../../common/components/PageProtected'
import { MainTheme } from '../../../../common/theme/MainTheme'
import { ShortInfoContainer } from './ShortInfoContainer'

const URL_APPROVE_SHORT_PAGE = '/dashboard/short_term_course/approve'

function ApproveShortPage () {
  const [params] = useSearchParams()
  const category = params.get('program') ?? 'language'
  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY]}>
      <MainTheme
        showMenu
      >
        <div className='byp-dashboard-content byp-align-centered'>
          <ShortInfoContainer
            category={category}
          />
        </div>
      </MainTheme>
    </PageProtected>
  )
}

export { ApproveShortPage, URL_APPROVE_SHORT_PAGE }
