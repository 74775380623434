import { API_URL } from '../config'
import api from './api'

class UserService {
  static async login ({ email, password, remember }) {
    const response = await api.post('/login', { email, password, remember })
    return response.data
  }

  register (username, email, password) {
    return api.post(API_URL + '/signup', {
      username,
      email,
      password
    })
  }

  static async forgotPassword ({ email }) {
    const response = await api.post('/forgot-password', { email })
    return response.data
  }

  static async resetPassword ({ email, password, confirmation, token }) {
    const response = await api.post('/reset-password', { email, password, password_confirmation: confirmation, token })
    return response.data
  }
}

export default UserService
