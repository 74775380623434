import { useQuery } from 'react-query'
import { PlaceRepository } from '../../repositories/PlaceRepository'

function useGetPlaces (placeFilter = {}) {
  const queryString = Object.keys(placeFilter).map((key) => key + '=' + placeFilter[key]).join('&')
  return useQuery(['useGetPlaces', queryString],
    () => PlaceRepository.search(queryString), {
      retry: 2,
      enabled: !!queryString
    })
}

export { useGetPlaces }
